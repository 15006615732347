import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import {
  IConfigurationPaymentMethod,
  IGetConfigurationPaymentMethods,
} from '../../../context/types/paymentMethodTypes';

interface IUsePaymentMethods {
  paymentMethods: IConfigurationPaymentMethod[];
  total: number;
  isLoading: boolean;
  error: unknown;
}

export const useConfigurationPaymentMethods = () => {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const {
    data: paymentMethodsData,
    error,
    isLoading,
  } = useFetch<IGetConfigurationPaymentMethods>('v1/payment-methods', '', undefined, undefined, onError);

  return {
    error,
    isLoading: isLoading,
    paymentMethods: paymentMethodsData?.paymentMethods ?? [],
    total: paymentMethodsData?.total ?? 0,
  };
};

export function usePaymentMethods(): IUsePaymentMethods {
  const { paymentMethods, error, isLoading, total } = useConfigurationPaymentMethods();

  return {
    paymentMethods: !error && paymentMethods ? paymentMethods : [],
    total: total,
    isLoading: isLoading,
    error,
  };
}
