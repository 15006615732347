import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Title = styled(Typography).attrs({
  variant: 'headline8',
})`
  margin-top: ${({ theme }) => theme.spacings.s};
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  margin-top: ${({ theme }) => theme.spacings.xs2};
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
`;

export const InformationsContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.xs};
  border-width: ${({ theme }) => theme.borders.width.xs};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  margin-top: ${({ theme }) => theme.spacings.xs1};
`;
