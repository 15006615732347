import { gql } from '@apollo/client';

export const getCurrenciesQuery = gql`
  query getAllCurrencies {
    getAllCurrencies {
      currencies {
        id
        code
        symbol
        name
      }
    }
  }
`;
