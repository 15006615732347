import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 982px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const PriorityContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  min-width: 100%;
`;

export const TitleSectionDocument = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`;

export const LabelDocument = styled(Typography)`
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacings.xs5};
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const ContainerFile = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.s} ${({ theme }) => theme.spacings.m};
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.secondary[70]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  align-items: center;
  width: 100%;
`;

export const ContentFile = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
`;

export const FileName = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 600 !important;
  size: 16px;
`;

export const ActionsFile = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
`;

export const SmallImageContainer = styled.div`
  width: 45px;
  height: 45px;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[80]};
  display: flex;
  justify-content: center;
  align-items: center;
`;
