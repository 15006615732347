import { useState } from 'react';
import { ReceiptFile } from '@containers/Expenses/context/types';
import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { DropzoneFile } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types';
import { bufferToBase64 } from '@shared/helpers/files';

import * as SC from './styled';

interface OdometerProps {
  odometerFile?: ReceiptFile;
  onChange(file: ReceiptFile[]): void;
}

export const OdometerUpload = ({ odometerFile, onChange }: OdometerProps) => {
  const [currentImage, setCurrentImage] = useState<ReceiptFile>(odometerFile);
  const onDropzoneChange = (selectedReceipts: DropzoneFile[]) => {
    const receipts: ReceiptFile[] = selectedReceipts.map(receipt => {
      const { data, file } = receipt;

      const previewUrl = URL.createObjectURL(file);

      return {
        base64: data instanceof ArrayBuffer ? bufferToBase64(data, file.type) : data,
        contentType: file.type,
        file,
        previewUrl,
      };
    });
    onChange?.(receipts);
  };

  if (currentImage?.contentType || currentImage?.file?.type) {
    return (
      <SC.OdometerImageContainer>
        <SC.FileName variant="caption">
          {odometerFile?.file?.name || currentImage?.file?.name || currentImage?.fileName}{' '}
        </SC.FileName>
        <IconButton
          size="small"
          variant="line"
          icon="IconTrash"
          onClick={() => {
            setCurrentImage(null);
            onChange([null]);
          }}
        />
      </SC.OdometerImageContainer>
    );
  }

  return (
    <SC.CustomDropzone
      onChange={data => {
        onDropzoneChange(data);
        setCurrentImage(data[0]);
      }}
      multiple={false}
    />
  );
};
