import { IAnalyticsData, IAnalyticsRequest } from '@containers/Analytics/context/types/analytics';
import { EAnalyticsType } from '@containers/Analytics/context/types/filters';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useAnalyticsDownloadFile } from '@shared/hooks/useAnalyticsDownloadFile';

export function useAnalyticsData(type: EAnalyticsType, translatePathBase: string) {
  const { mutation } = useMutate('v1/analytics');
  const getDownloadFile = useAnalyticsDownloadFile({ translatePath: `${translatePathBase}.fileName` });

  function getPath() {
    switch (type) {
      case EAnalyticsType.EXPENSES_OVERVIEW:
        return 'expenses/overview';
      case EAnalyticsType.EXPENSES_BY_CATEGORY:
        return 'expenses/category';
      case EAnalyticsType.EXPENSES_BY_USER:
        return 'expense/user';
      case EAnalyticsType.CORPORATE_CARD_OVERVIEW:
        return 'corporate-card/overview';
      case EAnalyticsType.CORPORATE_CARD_BY_USER:
        return 'corporate-card/pending/user';
      case EAnalyticsType.REPORT_OVERVIEW:
        return 'reports/overview';
      case EAnalyticsType.REPORT_PENDING_APPROVALS:
        return 'reports/pending/approval/approver';
      case EAnalyticsType.REPORT_STATUS_BY_USER:
        return 'reports/user';
      case EAnalyticsType.ADVANCES_OVERVIEW:
        return 'advances/overview';
      case EAnalyticsType.ADVANCES_STATUS_BY_USER:
        return 'advances/users';
      case EAnalyticsType.LOGS_BY_POLICY:
        return 'logs/policy';
      case EAnalyticsType.LOGS_BY_APPROVAL:
        return 'logs/approval';
      case EAnalyticsType.LOGS_BY_AREA:
        return 'logs/area';
      case EAnalyticsType.LOGS_BY_COSTCENTER:
        return 'logs/cost-center';
      case EAnalyticsType.LOGS_BY_PROJECT:
        return 'logs/project';
      case EAnalyticsType.LOGS_BY_USER:
        return 'logs/user';
      default:
        return null;
    }
  }

  async function getAnalyticsData({ config, exportType, filters, isPending, selectedFields }: IAnalyticsRequest) {
    const requestData = {};

    selectedFields && (requestData['selectedFields'] = selectedFields);
    config && (requestData['config'] = config);
    requestData['filters'] = filters ?? [];
    isPending && (requestData['isPending'] = isPending);
    exportType && (requestData['exportType'] = exportType);

    if (type === EAnalyticsType.CORPORATE_CARD_PENDING_OVERVIEW || type === EAnalyticsType.EXPENSES_PENDING_OVERVIEW) {
      requestData['isPending'] = true;
      type === EAnalyticsType.CORPORATE_CARD_PENDING_OVERVIEW
        ? (type = EAnalyticsType.CORPORATE_CARD_OVERVIEW)
        : (type = EAnalyticsType.EXPENSES_OVERVIEW);
    }

    try {
      const response: IAnalyticsData = await mutation.trigger({
        path: getPath(),
        options: {
          method: 'POST',
          body: JSON.stringify(requestData),
        },
      });

      if (response?.base64Export) {
        getDownloadFile(response?.base64Export, exportType);
      }

      return response;
    } catch (err) {
      showToast({
        message: err.message,
        type: 'error',
      });
    }
  }

  return {
    getAnalyticsData,
    analyticsData: mutation.data,
    isLoading: mutation.isMutating,
    fileBase64: mutation.data?.base64Export,
    error: mutation.error,
    total: mutation.data?.total ?? 0,
  };
}
