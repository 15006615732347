import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { downloadFile } from '@shared/helpers/files';

import * as SC from './styled';

export interface ImportInfoContainerProps {
  newImportAction(): void;
  newImportLabel: string;
  // TODO: Update this type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  page: 'clients' | 'costCenters' | 'projects';
}

export const ImportInfoContainer = ({ newImportAction, newImportLabel, data, page }: ImportInfoContainerProps) => {
  const { t } = useTranslation();
  const success = data?.result?.total - data?.result?.errors;
  const error = data?.result?.errors;

  const handleDownload = async () => {
    const fileData = page === 'clients' || page === 'projects' ? data.validation : data.validationResults;
    const file = new Blob([createTxtFile(fileData)], { type: 'text/plain' });
    downloadFile(file, 'lista-de-erros');
  };

  const createTxtFile = data => {
    let txt: string = '';

    data?.forEach(error => {
      let message;
      if (page === 'clients' || page === 'projects') {
        message = error.messages.reduce((accumulator: string[], currentMessage) => accumulator + '\n' + currentMessage);
      } else {
        message = error.messages
          .map(item => item.message)
          .reduce((accumulator: string[], currentMessage) => accumulator + '\n' + currentMessage);
      }

      txt += t('organisms.importInfoContainer.error.row', { line: error.row }) + '\n' + message + '\n';
    });

    return txt;
  };

  return (
    <SC.Container>
      <SC.Icon size="small" icon="IconX" variant="error" />
      <div>
        <SC.Title variant="headline8">{t('organisms.ImportInfoContainer.errorProcessing')}</SC.Title>
        {Boolean(success) && (
          <SC.SubTitle variant="body4">
            {t(`organisms.ImportInfoContainer.peopleInfoSuccess.${page}.${success > 1 ? 'plural' : 'singular'}`, {
              quantity: success,
            })}
          </SC.SubTitle>
        )}
        {Boolean(error) && (
          <SC.SubTitle variant="body4">
            {t(`organisms.ImportInfoContainer.peopleInfoError.${page}.${error > 1 ? 'plural' : 'singular'}`, {
              quantity: error,
            })}
          </SC.SubTitle>
        )}
      </div>
      <div>
        <SC.Title variant="headline8">
          {t(`organisms.ImportInfoContainer.errorImporting`, { quantity: error })}
        </SC.Title>
        <SC.SubTitle variant="body4">{t('organisms.ImportInfoContainer.errorDetails')}</SC.SubTitle>
      </div>
      <SC.DownloadFileButton fileName="Lista-de-erros.txt" onClick={handleDownload}></SC.DownloadFileButton>
      <Button variant="primary" size="large" type="submit" onClick={newImportAction}>
        {newImportLabel}
        <Icons name="IconPlus" fill="transparent" />
      </Button>
    </SC.Container>
  );
};
