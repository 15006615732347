import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface ExpenseCommentProps {
  hasCustomFields: boolean;
}

export const ExpenseCollapseSummary = styled.div`
  align-items: center;
  display: grid;
  gap: ${props => props.theme.spacings.l};
  grid-template-columns: minmax(155px, max-content) minmax(106px, max-content) 114px;
`;

export const ExpenseCollapseSummaryValueHighlight = styled(Typography)``;

export const ExpenseCollapseSummaryLabelValueGroup = styled.div``;

export const ExpenseCollapseSummaryLabel = styled(Typography)`
  font-size: 14px;
`;

export const ExpenseCollapseSummaryValue = styled(Typography)`
  font-size: 14px;
`;

export const ExpenseCollapseDetailsContainer = styled.div`
  margin-top: ${props => props.theme.spacings.s};
`;

export const ExpenseCollapseDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 283px auto;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light.light2};
  padding: ${props => props.theme.spacings.xs4} ${props => props.theme.spacings.xs};
`;

export const ExpenseCollapseDetailsValue = styled(Typography)`
  font-weight: 700 !important;
`;

export const ExpenseCollapseDetailsCommentLabel = styled(Typography)`
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light.light2};
  padding: ${props => props.theme.spacings.xs4} ${props => props.theme.spacings.xs};
`;

export const ExpenseCollapseDetailsCommentValue = styled(Typography)`
  font-weight: 700 !important;
  padding: ${props => props.theme.spacings.xs4} ${props => props.theme.spacings.m};
`;

export const ExpenseCollapseDetailsComment = styled.div<ExpenseCommentProps>`
  margin: ${props => props.theme.spacings.s} 0 ${props => (props.hasCustomFields ? props.theme.spacings.s : 0)};
`;

export const Container = styled.div`
  padding: ${props => props.theme.spacings.xs};
  width: 760px;
`;

export const ExpenseShowButtonContainer = styled.div`
  margin-top: ${props => props.theme.spacings.xs};
`;

export const ValueEmpty = styled(Typography)`
  color: ${props => props.theme.colors.neutral[60]};
  text-align: center;
`;
