import { useState } from 'react';
import { SelectField, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { expensesCustomizationsBaseURL } from '@shared/constant';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useDelegatePermissions } from '@shared/hooks/permissions/systemPermissions/useDelegatePermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { useSelectsOptions } from '@shared/hooks/useSelectsOptions';

import { AccordionHeader } from './AccordionHeader/AccordionHeader';
import { AddDelegation } from './AddDelegation/AddDelegation';
import { DelegationGrid } from './DelegationGrid/DelegationGrid';
import * as SC from './styled';

export type TTargetDelegation = 'expense' | 'approval';

interface IProps {
  userId?: string;
}

export function DelegationPreview({ userId }: IProps) {
  const getCurrentUrlBase = useCurrentUrlBase();
  const urlBase = getCurrentUrlBase();
  const t = useTranslate('delegation.configuration');
  const [selectedPersonId, setSelectedPersonId] = useState<string>(userId);
  const { userOptions, isLoadingOptions } = useSelectsOptions({ hasUsers: true });
  const [openAddDelegation, setOpenAddDelegation] = useState(false);
  const [targetDelegation, setTargetDelegation] = useState<TTargetDelegation>(null);
  const delegatePermissions = useDelegatePermissions();
  const { checkLicenseFeature } = useLicenseFeatures();
  const content = (
    <>
      <SC.Container>
        {urlBase === expensesCustomizationsBaseURL && (
          <SC.FieldContainer>
            <SC.DescriptionLabel variant="headline9">{t('descriptionFieldPeople')}</SC.DescriptionLabel>
            {isLoadingOptions ? (
              <Skeleton width={'100%'} height={60} variant="rounded" />
            ) : (
              <SelectField
                value={selectedPersonId}
                label={t('labelPeople')}
                onSelectChange={(event, selected) => handlePerson?.(selected?.value || event)}
                options={userOptions.options}
                searchable
              />
            )}
          </SC.FieldContainer>
        )}

        {delegatePermissions.settingsExpenses && (
          <SC.AccordionSection
            variant="default"
            className="accordion-delegation"
            customHeader={
              <AccordionHeader
                title={t('expenseDelegation.title')}
                caption={t('expenseDelegation.caption')}
                selectedPersonId={selectedPersonId}
                onClick={() => addClick('expense')}
              />
            }>
            <SC.Content>
              <DelegationGrid
                key="delegation-grid-expense"
                target="expense"
                selectedPersonId={selectedPersonId}
                userOptions={userOptions?.options ?? []}
              />
            </SC.Content>
          </SC.AccordionSection>
        )}

        {delegatePermissions.settingsApproval && (
          <SC.AccordionSection
            variant="default"
            className="accordion-delegation"
            customHeader={
              <AccordionHeader
                title={
                  checkLicenseFeature('ADVANCED_ADVANCE')
                    ? t('approvalDelegation.legacy.title')
                    : t('approvalDelegation.title')
                }
                caption={
                  checkLicenseFeature('ADVANCED_ADVANCE')
                    ? t('approvalDelegation.legacy.caption')
                    : t('approvalDelegation.caption')
                }
                selectedPersonId={selectedPersonId}
                onClick={() => addClick('approval')}
              />
            }>
            <SC.Content>
              <DelegationGrid
                key="delegation-grid-approval"
                target="approval"
                selectedPersonId={selectedPersonId}
                userOptions={userOptions?.options ?? []}
              />
            </SC.Content>
          </SC.AccordionSection>
        )}
      </SC.Container>
      {openAddDelegation && (
        <AddDelegation
          target={targetDelegation}
          open={openAddDelegation}
          onClose={() => setOpenAddDelegation(false)}
          userId={selectedPersonId}
        />
      )}
    </>
  );

  function handlePerson(value: string) {
    setSelectedPersonId(value);
  }

  function addClick(target: TTargetDelegation) {
    setTargetDelegation(target);
    setOpenAddDelegation(true);
  }

  if (urlBase !== expensesCustomizationsBaseURL) {
    return content;
  }

  return <SectionContainer title={t('title')}>{content}</SectionContainer>;
}
