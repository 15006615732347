import { useMemo } from 'react';

import currencies from './currencies.json';

export function useCurrencyCode(currencyCode: string) {
  const currency = useMemo(() => {
    const info = currencies[currencyCode] || currencies['BRL'];

    return {
      symbol: info.symbol,
      nativeSymbol: info.symbol_native,
      name: info.name,
      decimalDigits: info.decimal_digits,
    };
  }, [currencyCode]);

  return currency;
}
