import { EExpenseType, IExpenseInput } from '@containers/Expenses/context/types';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { IReport } from '@containers/Reports/context/types';
import { useCheckExpense } from '@pages/ExpenseRegister/hooks/useCheckExpense';
import { useExpenseMutate } from '@pages/ExpenseRegister/hooks/useExpenseMutate';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';
import { useTracking } from '@shared/hooks/useTracking';
import { TAccessScreen } from '@shared/types';
import { useLocation } from 'react-router-dom';

interface IUseExpenseRegisterPage {
  isLoading: boolean;
  hasExpense: boolean;
  onContinueClick(data: IExpenseInput): void;
}

export function useExpenseRegisterPage(reportId: IReport['id'], expense: IExpense): IUseExpenseRegisterPage {
  const location = useLocation();
  const expenseType = useCheckExpenseType();
  const accessScreen: TAccessScreen = location?.state?.isReportDetails ? 'reportDetails' : 'expenseDetails';
  const { expenseId, hasExpense } = useCheckExpense();
  const trackingEvent = useTracking();

  const { expenseCreate, expenseUpdate, isLoading } = useExpenseMutate({
    reportId,
    expenseId: expenseId,
    accessScreen,
  });

  function onContinueClick(data: IExpenseInput) {
    const expenseData = { ...data };
    const isEdit = hasExpense || expenseData.id;
    const expenseIdData = expenseData.id || expenseId;

    delete expenseData.id;

    if (expenseData.customFields) {
      Object.keys(expenseData.customFields)?.forEach(field => {
        if (typeof expenseData.customFields[field] === 'number') {
          expenseData.customFields[field] = String(expenseData.customFields[field]);
        }
      });
    }

    if (expenseData.attachments?.length) {
      expenseData.attachments = expenseData.attachments.map(receipt => {
        const currentReceipt = receipt;

        currentReceipt.url && delete currentReceipt.url;
        currentReceipt.externId && delete currentReceipt.externId;
        return currentReceipt;
      });
    }

    if (expenseType === EExpenseType.GPS) {
      delete expense.route.previewImage;
      delete expense.route.imagePath;
      delete expense.route.waypointsNumber;
      delete expense.route.overviewPolyline;
      expenseData.route = { ...expense.route };
    }

    if (isEdit) {
      trackingEvent('expense_update_clicked');
      expenseUpdate({
        expenseId: expenseIdData,
        expenseData,
        reportId,
      });
    } else {
      trackingEvent('expense_create_clicked');
      expenseCreate({ expenseData, reportId });
    }
  }

  return {
    isLoading,
    hasExpense,
    onContinueClick,
  };
}
