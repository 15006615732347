import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export const useSubsidiaries = (shouldFetch: boolean = true) => {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const { data, error, isLoading } = useFetch<{ id: string; name: string }[]>(
    `v1/subsidiaries/get-for-selection`,
    '',
    undefined,
    undefined,
    onError,
    shouldFetch,
  );

  return {
    error,
    isLoading: isLoading,
    subsidiaries: data ?? [],
  };
};
