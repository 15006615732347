import { Skeleton } from '@mui/material';

import { ExpenseActionsContainer } from './styled';

export const ExpenseActionsSkeleton = () => {
  return (
    <ExpenseActionsContainer>
      <Skeleton variant="circular" width={50} height={50} />
      <Skeleton variant="circular" width={50} height={50} />
      <Skeleton variant="circular" width={50} height={50} />
    </ExpenseActionsContainer>
  );
};
