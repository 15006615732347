import { useEffect } from 'react';
import { AdvancePolicyLimit } from '@containers/Policies/context/types/advances';
import { useSaveAdvanceLimit } from '@containers/Policies/hooks/advances/useSaveAdvanceLimit';
import { TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { Grid } from '@mui/material';
import { EFieldType, FieldFactory } from '@organisms/FieldFactory';
import { showToast } from '@shared/helpers/toast';
import { useAllCurrencyCodes } from '@shared/hooks/currency/useAllCurrencyCodes';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface Props {
  open: boolean;
  onClose: () => void;
  policyId: string;
  limit?: AdvancePolicyLimit;
}

export const AdvanceLimitRegisterModal = ({ open, onClose, policyId, limit }: Props) => {
  const t = useTranslate('policy.register.advances.limits');

  const { handleSubmit, control, reset } = useForm<AdvancePolicyLimit>({
    mode: 'all',
  });

  const { currencies } = useAllCurrencyCodes();

  const { saveAdvanceLimit, isLoading } = useSaveAdvanceLimit({ policyId });

  useEffect(() => {
    reset(limit);
  }, [limit, reset]);

  async function onSubmit(data: AdvancePolicyLimit) {
    try {
      await saveAdvanceLimit(data);

      onClose?.();

      showToast({
        message: t('feedbacks.advanceLimitSavedSuccessfully'),
        type: 'success',
      });

      reset?.();
    } catch (err) {
      showToast({
        message: err.message || t('feedbacks.errorSavingAdvanceLimit'),
        type: 'error',
      });
    }
  }

  return (
    <DefaultModal
      open={open}
      headerTitle={!limit ? t('modal.title.create') : t('modal.title.edit')}
      labelButtonAction={!limit ? t('modal.actions.add') : t('modal.actions.save')}
      headerSubtitle={t('modal.caption')}
      onActionClick={handleSubmit(onSubmit)}
      onCloseClick={onClose}
      onCancelClick={onClose}
      isLoading={isLoading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="currencyCode"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <FieldFactory
                  {...field}
                  fieldType={EFieldType.OPTIONS}
                  allowsEditing
                  // @ts-expect-error component type is invalid
                  value={field.value}
                  error={fieldState.invalid}
                  label={t('modal.fields.currency')}
                  options={currencies?.map(value => ({ label: value, value }))}
                  onChange={item => onChange(item)}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="value"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <NumericFormat
                  onBlur={field.onBlur}
                  name={field.name}
                  value={field.value}
                  customInput={TextField}
                  label={t('modal.fields.value')}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$"
                  decimalScale={2}
                  fixedDecimalScale
                  onFocus={event => event.target.select()}
                  onValueChange={({ floatValue }) => onChange(floatValue)}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  InputProps={{
                    inputProps: { maxLength: 10 },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </DefaultModal>
  );
};
