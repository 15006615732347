import { useEffect, useState } from 'react';
import { IProjectInput } from '@containers/Projects/context/types/projects';
import { IConfigurationProject } from '@containers/Projects/context/types/projectTypes';
import { useTransferListSet } from '@containers/Projects/hooks/useTransferListSet';
import { TransferList, TransferListDataType } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { useEmployeesSelectionList } from '@shared/hooks/employees/useEmployeesSelectionList';
import { useFormContext } from 'react-hook-form';

import * as SC from './styled';

interface AssignUsersProps {
  project: IConfigurationProject;
}

export const AssignUsers = ({ project }: AssignUsersProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<IProjectInput>();
  const { employees: users, total, isLoading } = useEmployeesSelectionList();
  const leftList = useTransferListSet({ data: users, ids: project?.employees || null });
  const rightList = useTransferListSet({
    data: users,
    ids: project?.employees || [],
    isRightList: true,
  });
  const [rightListData, setRightListData] = useState<TransferListDataType>([]);
  const [leftListData, setLeftListData] = useState<TransferListDataType>(leftList);

  useEffect(() => {
    setLeftListData(leftList);
  }, [leftList]);

  useEffect(() => {
    setRightListData(rightList);
  }, [rightList]);

  useEffect(() => {
    const data = rightListData?.length ? [...rightListData?.map(item => item._id)] : [];
    setValue('employees', data);
  }, [rightListData]);

  const handleFilter = ({ result, update }) => {
    update(current =>
      current.map(item => {
        if (!item.name.toLowerCase().includes(result.toLowerCase())) {
          item.hidden = true;
        } else {
          item.hidden = false;
        }

        return item;
      }),
    );
  };

  const handleCheck = ({ allChecked, data, update }) => {
    if (data?.length) {
      update([
        ...data?.map(row => ({
          ...row,
          checked: allChecked !== undefined ? allChecked : row?.checked,
        })),
      ]);
    }
  };

  return (
    <SC.Container>
      <SC.Title variant="headline8">{t('organisms.registerProject.assignUsersGrid.title')}</SC.Title>
      <SC.SubTitle variant="body4">{t('organisms.registerProject.assignUsersGrid.subTitle')}</SC.SubTitle>
      <SC.Line />
      <TransferList
        columns={[{ Header: t('organisms.registerProject.assignUsersGrid.header.name'), accessor: 'name' }]}
        leftList={{
          data: leftListData,
          total: leftListData?.length,
          loading: isLoading,
          title: t('organisms.registerProject.assignUsersGrid.allPeople', {
            selected: rightListData ? total - rightListData?.length : 0,
          }),
          onCheck: ({ allChecked, data }) => handleCheck({ allChecked, data, update: setLeftListData }),
          onSearch: result => handleFilter({ result, update: setLeftListData }),
        }}
        rightList={{
          data: rightListData,
          total: rightListData?.length,
          loading: isLoading,
          title: t('organisms.registerProject.assignUsersGrid.selectedForProject', { selected: rightListData?.length }),
          onCheck: ({ allChecked, data }) => handleCheck({ allChecked, data, update: setRightListData }),
          onSearch: result => handleFilter({ result, update: setRightListData }),
        }}
        onTransfer={({ leftList, rightList }) => {
          setLeftListData([...leftList.data]);
          setRightListData([...rightList.data]);
        }}
      />
    </SC.Container>
  );
};
