import { reportListRowPrintItems } from '../../../../../shared/constant';
import { ReportCardSkeleton } from '../ReportCard/ReportCardSkeleton';
import * as SC from './styled';

export interface ReportsListRowSkeletonProps {
  numberReports?: number;
}

export const ReportsListRowSkeleton = ({ numberReports = reportListRowPrintItems }: ReportsListRowSkeletonProps) => {
  return (
    <SC.Container>
      {new Array(numberReports).fill({}).map((_, key) => (
        <SC.ReportCardContainer key={key}>
          <ReportCardSkeleton />
        </SC.ReportCardContainer>
      ))}
    </SC.Container>
  );
};
