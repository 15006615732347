import { useMutate } from '@shared/hooks/service/useMutate';

import { IReport } from '../context/types';

type SubmitReportResponse = {
  message: string;
  reports: IReport[];
};

export function useSubmitReport() {
  const { mutation } = useMutate<SubmitReportResponse>('v1/reports/submit', () => {});

  async function submit(reportId: number, allowSplit: boolean) {
    const result = await mutation.trigger({
      options: {
        method: 'POST',
        body: JSON.stringify({ reportId: String(reportId), allowSplit }),
      },
    });

    return result;
  }

  return { submit, isLoading: mutation.isMutating, error: mutation.error, data: mutation.data };
}
