import { Dispatch, SetStateAction } from 'react';
import { Drawer } from '@atoms/Drawer';
import { IconButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import contractInformation from '../../../../../../assets/images/conditionalPolicyInfo.png';
import * as SC from './styled';

interface IProps {
  openDrawer: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

export function PolicyConditionsInfo({ openDrawer, setOpenDrawer }: IProps) {
  const t = useTranslate('policy.register.conditions.infos');
  return (
    <SC.DrawerContainer>
      <Drawer
        isOpen={openDrawer}
        drawerWidth={670}
        renderDrawer={
          <SC.Container>
            <SC.Header>
              <SC.HeaderContent>
                <div>
                  <Typography variant="headline6">{t('title')}</Typography>
                  <Typography variant="body3">{t('caption')}</Typography>
                </div>
                <IconButton size="small" variant="line" icon="IconX" onClick={() => setOpenDrawer(false)} />
              </SC.HeaderContent>
            </SC.Header>
            <SC.Body>
              <Typography variant="body3">{t('Paragraph1')}</Typography>
              <Typography variant="body3">{t('Paragraph2')}</Typography>
              <div>
                <Typography variant="body3" style={{ fontWeight: '700' }}>
                  {t('subTitle')}
                </Typography>
                <img src={contractInformation} alt={t('title')} />
              </div>
            </SC.Body>
          </SC.Container>
        }
      />
      {openDrawer && <SC.Backdrop onClick={() => setOpenDrawer(false)} />}
    </SC.DrawerContainer>
  );
}
