import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';

import { HeaderBody, HeaderContainer, ProgressBar, Title } from './styled';

export interface SettingHeaderProps {
  title: string;
  steps: string[];
  activeStep: number;
}

export const SettingHeader = ({ title, steps, activeStep }: SettingHeaderProps) => {
  const progressBarValue = activeStep >= 0 ? ((activeStep + 1) / steps.length) * 100 : 0;

  return (
    <HeaderContainer>
      <HeaderBody>
        <Title variant="body3">{title}</Title>

        <Stepper steps={steps} activeStep={activeStep} disableClick />
      </HeaderBody>

      <ProgressBar variant="determinate" value={progressBarValue} />
    </HeaderContainer>
  );
};
