import React, { useMemo } from 'react';
import { EExpenseType, EOrderBy, IExpenses } from '@containers/Expenses/context/types/expenses';
import { EReportStatus } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { GridPagination, IRowsPerPageOption } from '@molecules/GridPagination';
import { IExpensesAllowedActions } from '@shared/hooks/permissions/systemPermissions/useExpensesPermissions';

import { ActionsMultipleSelectedExpenses, AllowedMultipleActions } from './ActionsMultipleSelectedExpenses';
import { useBodyGridRows } from './hooks/useBodyGridRow';
import { useBodyGridRowSkeleton } from './hooks/useBodyGridRowSkeleton';
import { EAccessor, useHeaderGridColumns } from './hooks/useHeaderGridColumns';
import { useHeaderGridColumnsSkeleton } from './hooks/useHeaderGridColumnsSkeleton';
import * as SC from './styled';

export interface IGridPagination {
  totalItems: number;
  rowsPerPage?: number;
  selectedPage: number;
  rowsPerPageOptions?: IRowsPerPageOption[];
  onPageClick(page: number): void;
  onRowsPerPageClick(itemsPerPage: number): void;
}

export interface ExpenseGridProps {
  expenses: IExpenses[];
  totalItems?: number;
  allowedActions?: IExpensesAllowedActions;
  allowedMultipleActions?: AllowedMultipleActions;
  isLoading: boolean;
  pagination?: IGridPagination;
  selectedItems: number[];
  isSelectedAll: boolean;
  showMultipleActions?: boolean;
  onOrderByClick?(accessor: EAccessor, order: EOrderBy): void;
  onSelectAllClick(): void;
  onSelectExpense?(id: IExpenses['id']): void;
  onDeleteExpense?(ids: IExpenses['id'][]): void;
  onUnlinkExpense?(ids: IExpenses['id'][]): void;
  onViewExpense?(id: IExpenses['id'], expenseType: EExpenseType): void;
  onEditExpense?(id: IExpenses['id'], expenseType: EExpenseType): void;
}

export const ExpenseGrid = ({
  expenses,
  totalItems,
  allowedActions,
  allowedMultipleActions,
  isLoading,
  pagination,
  selectedItems,
  isSelectedAll,
  showMultipleActions = true,
  onDeleteExpense,
  onOrderByClick,
  onSelectAllClick,
  onSelectExpense,
  onViewExpense,
  onEditExpense,
  onUnlinkExpense,
}: ExpenseGridProps) => {
  const headerGridColumns = useHeaderGridColumns({
    isSelectedAll,
    showMultipleActions,
    onOrderByClick,
    onSelectAllClick,
  });
  const bodyGridRows = useBodyGridRows({
    expenses,
    allowedActions,
    selectedExpenses: selectedItems,
    showMultipleActions,
    onSelectExpense,
    onDeleteExpense,
    onUnlinkExpense,
    onViewExpense,
    onEditExpense,
  });
  const bodyGridRowSkeleton = useBodyGridRowSkeleton({ rowsPerPage: pagination?.rowsPerPage || 3 });
  const headerGridColumnsSkeleton = useHeaderGridColumnsSkeleton();
  const hasMultipleActions = selectedItems?.length > 0;
  const { t } = useTranslation();

  const disableMultiple: boolean = useMemo(
    () =>
      selectedItems.some(id =>
        Boolean(
          expenses.find(
            expense => expense.id === id && expense?.report && expense?.report?.status !== EReportStatus.OPEN,
          ),
        ),
      ),
    [selectedItems],
  );

  const expenseGridProps: {
    // TODO: Check this type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customPagination?(data: any): React.ReactNode;
    pageSizeOptions?: {
      label: string;
      value: number;
    }[];
  } = {};
  if (pagination) {
    expenseGridProps.customPagination = data => (
      <GridPagination {...pagination} setPageSize={data.setPageSize} isLoading={isLoading} />
    );
  } else {
    expenseGridProps.pageSizeOptions = [
      { label: `5 ${t('molecules.gridPagination.items')}`, value: 5 },
      { label: `10 ${t('molecules.gridPagination.items')}`, value: 10 },
      { label: `15 ${t('molecules.gridPagination.items')}`, value: 15 },
      { label: `20 ${t('molecules.gridPagination.items')}`, value: 20 },
      { label: `50 ${t('molecules.gridPagination.items')}`, value: 50 },
      { label: `100 ${t('molecules.gridPagination.items')}`, value: 100 },
      { label: `200 ${t('molecules.gridPagination.items')}`, value: 200 },
    ];
  }

  return (
    <SC.ExpenseGridContainer>
      <SC.GridContainer>
        {hasMultipleActions && (
          <ActionsMultipleSelectedExpenses
            allowedMultipleActions={allowedMultipleActions}
            disabledMultipleActions={{
              delete: disableMultiple,
            }}
            selectedItems={selectedItems}
            totalItems={totalItems}
            onDelete={onDeleteExpense}
            onUnlink={onUnlinkExpense}
          />
        )}

        <SC.Grid
          columns={isLoading ? headerGridColumnsSkeleton : headerGridColumns}
          data={isLoading ? bodyGridRowSkeleton : bodyGridRows}
          hasPagination
          {...expenseGridProps}
        />
      </SC.GridContainer>
    </SC.ExpenseGridContainer>
  );
};
