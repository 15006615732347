import React from 'react';
import { useTranslation } from '@locale/Translator';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';

import { EOrderBy } from '../../../../context/types/expenses';

export enum EAccessor {
  SELECTION = 'selection',
  CATEGORY = 'category',
  REFERENCE_ID = 'id',
  VALUE = 'value',
  DATE = 'date',
  PLACE = 'place',
  RECEIPT = 'receipt',
  ACTIONS = 'actions',
}

export interface HeaderGridColumn {
  Header: React.ReactNode;
  accessor: string | number;
  disableSortBy?: boolean;
  sticky?: string;
}

interface UseHeaderGridColumnProps {
  onOrderByClick(accessor: EAccessor, order: EOrderBy): void;
  onSelectAllClick(): void;
  isSelectedAll: boolean;
  showMultipleActions?: boolean;
}

export const useHeaderGridColumns = ({
  onSelectAllClick,
  isSelectedAll,
}: UseHeaderGridColumnProps): HeaderGridColumn[] => {
  const { t } = useTranslation();

  return Object.values(EAccessor).map(accessor => ({
    Header: (
      <ColumnHeader
        isSelectedAll={accessor == EAccessor.SELECTION}
        onSelectAllClick={onSelectAllClick}
        selectAllChecked={isSelectedAll}
        description={accessor == 'id' ? 'ID' : t(`organisms.expenseGrid.${accessor}`)}
      />
    ),
    accessor,
    disableSortBy: true,
    sticky: accessor == EAccessor.ACTIONS && 'right',
  }));
};
