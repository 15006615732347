import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  padding: ${props => `${props.theme.spacings.m} ${props.theme.spacings.xs} ${props.theme.spacings.xs} 0`};
  width: 100%;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacings.xs};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 288px;
`;

export const SectionTitle = styled(Typography)`
  color: ${props => props.theme.colors.secondary[50]};
`;

export const SectionSubtitle = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
  margin-top: ${props => props.theme.spacings.xs2};
`;
