import { useTranslation } from '@locale/Translator';
import { BreadcrumbPage } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { clientsBaseURL } from '@shared/constant';

import * as SC from './styled';

export const ClientRegisterFileHeader = () => {
  const { t } = useTranslation();

  return (
    <SC.Header>
      <BreadcrumbPage
        items={[
          { label: t('organisms.registerClient.bulkImport.breadcrumbs.manageCompany'), to: '/settings' },
          {
            label: t('organisms.registerClient.bulkImport.breadcrumbs.clients'),
            to: clientsBaseURL,
          },
          {
            label: t('organisms.registerClient.bulkImport.breadcrumbs.addByFile'),
            to: '',
          },
        ]}
      />
    </SC.Header>
  );
};
