import { useState } from 'react';
import { IConfigurationPaymentMethod } from '@containers/Customizations/context/types/paymentMethodTypes';
import { useDeletePaymentMethods } from '@containers/Customizations/hooks/PaymentMethods/rest/useDeletePaymentMethods';
import { usePaymentMethodSwitchActive } from '@containers/Customizations/hooks/PaymentMethods/rest/usePaymentMethodSwitchActive';
import { IActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { IBatchActionsActions } from '@organisms/Grid/BatchActions/BatchActions';
import { useSelectionRows } from '@organisms/Grid/BatchActions/hooks/useSelectionRows';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { TPaymentMethodConfirmationActions } from '../../PaymentMethodConfirmationActionsModal/PaymentMethodConfirmationActionsModal';
import { useCheckDefaultOptions } from './useCheckDefaultOptions';

enum EActions {
  EDIT = 'edit',
  ACTIVE = 'active',
  DELETE = 'delete',
}

enum EIcons {
  EDIT = 'IconPencil',
  ACTIVE = 'IconToggleLeft',
  DELETE = 'IconTrash',
}

export function usePaymentMethodsActionsGrid(paymentMethods: IConfigurationPaymentMethod[]) {
  const t = useTranslate('organisms.customizationsPaymentMethods.grid');
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [confirmationType, setConfirmationType] = useState<TPaymentMethodConfirmationActions>();
  const [isBatchAction, setIsBatchAction] = useState<boolean>(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<IConfigurationPaymentMethod>();
  const [paymentMethodsSelectedIds, setPaymentMethodsSelectedIds] = useState<IConfigurationPaymentMethod['id'][]>();
  const [paymentMethodSelectedActive, setPaymentMethodSelectedActive] = useState<boolean>();
  const { isSelectedAll, onSelectItemClick, selectedItems, toggleSelectAll, unSelectAllClick } =
    useSelectionRows<IConfigurationPaymentMethod>(paymentMethods);
  const { containsDefaultOption, getSelectedPaymentMethods, isDefaultOption } = useCheckDefaultOptions({
    paymentMethods,
    selectedItems,
  });
  const { _setPaymentMethodActive, listIdsLoading, isLoading: isLoadingActive } = usePaymentMethodSwitchActive();
  const { deletePaymentMethods, isLoading: isLoadingDelete } = useDeletePaymentMethods(paymentMethods);

  function onShowConfirmationActionsModal(): void {
    setShowConfirmationModal(_open => !_open);
  }

  async function onConfirmationActionModal() {
    if (confirmationType === 'deactivation') {
      await _setPaymentMethodActive(paymentMethodsSelectedIds, paymentMethodSelectedActive ?? false);
    } else {
      await deletePaymentMethods(paymentMethodsSelectedIds);
      unSelectAllClick();
    }
    onShowConfirmationActionsModal();
  }

  function onShowEditModal(): void {
    setShowEditModal(_open => !_open);
  }

  function onActionClick(_actionKey: EActions, _id?: IConfigurationPaymentMethod['id']) {
    const _paymentMethodsSelectedIds: IConfigurationPaymentMethod['id'][] = _id ? [_id] : selectedItems;
    const _isBatchAction: boolean = _paymentMethodsSelectedIds?.length > 1;
    const _currentPaymentMethod: IConfigurationPaymentMethod = !_isBatchAction
      ? getSelectedPaymentMethods(_paymentMethodsSelectedIds[0])
      : null;

    setPaymentMethodSelectedActive(_currentPaymentMethod ? !_currentPaymentMethod.active : null);
    setPaymentMethodsSelectedIds(_paymentMethodsSelectedIds);
    _currentPaymentMethod && setPaymentMethodSelected(_currentPaymentMethod);
    setIsBatchAction(_paymentMethodsSelectedIds?.length > 1);

    switch (_actionKey) {
      case EActions.ACTIVE:
        setConfirmationType('deactivation');
        if (
          (_paymentMethodsSelectedIds?.length === 1 && _currentPaymentMethod.active) ||
          _paymentMethodsSelectedIds?.length > 1
        ) {
          onShowConfirmationActionsModal();
        } else {
          _setPaymentMethodActive([_currentPaymentMethod.id], !_currentPaymentMethod.active);
        }
        break;

      case EActions.DELETE:
        setConfirmationType('exclusion');
        onShowConfirmationActionsModal();
        break;

      default:
        setConfirmationType(null);
        onShowEditModal();
        break;
    }
  }

  function getActionsRow(_id: IConfigurationPaymentMethod['id']): IActionsRow[] {
    return (
      paymentMethods?.length &&
      Object.keys(EActions).map(key => ({
        id: _id,
        key: EActions[key],
        allowed: EActions[key] === EActions.DELETE ? !isDefaultOption(_id) : true,
        iconName: EIcons[key],
        name: t(
          `body.actions.${
            EActions[key] === EActions.ACTIVE && getSelectedPaymentMethods(_id)?.active ? 'deactivate' : EActions[key]
          }`,
        ),
        onClick: (_key, id) => onActionClick(_key as EActions, id as IConfigurationPaymentMethod['id']),
      }))
    );
  }

  function getTooltipBatchActions(key: EActions): string {
    switch (key) {
      case EActions.DELETE:
        return (containsDefaultOption() && t('header.batchActions.tooltips.delete')) || '';
      case EActions.ACTIVE:
        return (!getBatchActionsDeactivationEnabled() && t('header.batchActions.tooltips.active')) || '';
      default:
        return '';
    }
  }

  function getBatchActionDisabled(key: EActions): boolean {
    switch (key) {
      case EActions.DELETE:
        return containsDefaultOption();
      case EActions.ACTIVE:
        return !getBatchActionsDeactivationEnabled();
      default:
        return false;
    }
  }

  function getBatchActions(): IBatchActionsActions[] {
    const batchActions: IBatchActionsActions[] = [];

    paymentMethods?.length &&
      Object.keys(EActions).forEach(key => {
        batchActions.push({
          key: EActions[key],
          allowed: EActions[key] !== EActions.EDIT,
          disabled: getBatchActionDisabled(EActions[key]),
          iconName: EIcons[key],
          name: t(`header.batchActions.${EActions[key] === EActions.ACTIVE ? 'deactivate' : EActions[key]}`),
          tooltipMessage: getTooltipBatchActions(EActions[key]),
          onClick: _key => onActionClick(_key as EActions),
        });
      });

    return batchActions;
  }

  function getBatchActionsDeactivationEnabled() {
    const paymentMethodActiveList = selectedItems?.filter(selectedId =>
      paymentMethods?.some(paymentMethod => paymentMethod.id === selectedId && paymentMethod.active),
    );

    return paymentMethodActiveList?.length > 0;
  }

  return {
    paymentMethodSelected,
    showEditModal,
    showConfirmationModal,
    confirmationType,
    isSelectedAll,
    selectedItems,
    isBatchAction,
    isLoadingAction: isLoadingActive || isLoadingDelete,
    listIdsLoading: listIdsLoading,
    getActionsRow,
    unSelectAllClick,
    getBatchActions,
    toggleSelectAll,
    onSelectItemClick,
    onShowConfirmationActionsModal,
    onShowEditModal,
    isDefaultOption,
    onConfirmationActionModal,
  };
}
