import { useEffect, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { AdvanceRegisterForm } from '@containers/Advances/components/organisms/AdvanceRegisterForm/AdvanceRegisterForm';
import { useAdvancesStatus } from '@containers/Advances/components/organisms/AdvancesGrid/hooks/useAdvancesStatus';
import { JustificationModalAdvanceReproval } from '@containers/Advances/components/organisms/JustificationModalAdvanceReproval';
import { EAdvanceStatus } from '@containers/Advances/context/types/advance';
import { IAdvanceInput } from '@containers/Advances/context/types/formFields';
import { useAdvanceById } from '@containers/Advances/hooks/dataSources/useAdvanceById';
import { useAdvanceRegisterFields } from '@containers/Advances/hooks/dataSources/useAdvanceRegisterFields';
import { useSaveAdvance } from '@containers/Advances/hooks/dataSources/useSaveAdvance';
import { useStatusSwitchBatchAdvances } from '@containers/Advances/hooks/useStatusSwitchBatchAdvances';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { BasePage } from '@organisms/BasePage/BasePage';
import {
  defaultCurrencyCode,
  expensesAccountingBaseURL,
  expensesAdvancesBaseURL,
  expensesApprovalsBaseURL,
} from '@shared/constant';
import { useDateFormatter } from '@shared/hooks';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AdvanceRegisterFooterPage } from './AdvanceRegisterFooterPage/AdvanceRegisterFooterPage';
import * as SC from './styled';

export function AdvanceRegister() {
  const navigate = useNavigate();
  const t = useTranslate('pages.advanceRegister');
  const { getAdvancesStatusColor, getAdvancesStatusLabel } = useAdvancesStatus();
  const currentPath = useCurrentPath();
  const { id } = useParams<{ id: string }>();
  const { getAdvanceById, advance, isLoading } = useAdvanceById();
  const [toView] = useState(currentPath.includes('details'));
  const { handleSave, isLoading: saveIsLoading } = useSaveAdvance();
  const { state: navigateState } = useLocation();
  const { getFormatDateUTC } = useDateFormatter();
  const advanceForm = useForm<IAdvanceInput>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: {
      currencyCode: defaultCurrencyCode,
      advanceDate: getFormatDateUTC(new Date()),
      reportId: navigateState?.linkedReport?.id || null,
    },
  });
  const [navigateStateReport] = useState(navigateState);
  const { setStatusBatchAdvances, isLoading: statusIsLoading, error: statusError } = useStatusSwitchBatchAdvances();
  const [justificationIsOpen, setJustificationIsOpen] = useState(false);
  const [statusSelected, setStatusSelected] = useState<EAdvanceStatus>(null);
  const getCurrentBasePage = useCurrentUrlBase();
  const { fields, isLoading: fieldsIsLoading, fieldsDeserialized } = useAdvanceRegisterFields();

  async function onSubmit(data: IAdvanceInput) {
    data.approverId = Number(data.approverId);
    await handleSave(data, Number(id));
  }

  function onClickHandleStatus(_status: EAdvanceStatus) {
    setStatusSelected(_status);

    if (_status === EAdvanceStatus.REPROVED) {
      setJustificationIsOpen(true);
      return;
    }
    handleStatus('', _status);
  }

  async function handleStatus(message: string, _status: EAdvanceStatus) {
    const currentBasePage = getCurrentBasePage();

    try {
      const response = await setStatusBatchAdvances([Number(id)], _status, message);

      setJustificationIsOpen(false);

      if (!response?.data?.updateAdvancesStatus?.error && !statusError) {
        switch (currentBasePage) {
          case expensesAccountingBaseURL:
            navigate(expensesAccountingBaseURL);
            break;
          case expensesApprovalsBaseURL:
            navigate(expensesApprovalsBaseURL);
            break;
          default:
            navigate(expensesAdvancesBaseURL);
            break;
        }
      }
    } catch (err) {}
  }

  useEffect(() => {
    id && getAdvanceById(Number(id));
  }, [id]);

  useEffect(() => {
    if (isLoading || !id) return;

    if (!isLoading && advance)
      return advanceForm.reset({
        advanceDate: advance.advanceDate ?? undefined,
        approverId: fieldsDeserialized?.approver?.active && advance.approver?.id ? advance.approver?.id : undefined,
        clientId: fieldsDeserialized?.client?.active && advance.client?.id ? advance.client?.id : undefined,
        costCenterId:
          fieldsDeserialized?.costCenter?.active && advance.costCenter?.id ? advance.costCenter?.id : undefined,
        currencyCode: advance.currencyCode ?? undefined,
        description: advance.description ?? undefined,
        paymentMethodId: advance.paymentMethod?.id ?? undefined,
        projectId: fieldsDeserialized?.project?.active && advance.project?.id ? advance.project?.id : undefined,
        reportId: fieldsDeserialized?.report?.active && advance.report?.id ? advance.report?.id : undefined,
        value: Number(advance.value) ?? undefined,
      });
  }, [isLoading, fieldsIsLoading]);

  return (
    <>
      {' '}
      <FormProvider {...advanceForm}>
        <form onSubmit={advanceForm.handleSubmit(onSubmit)}>
          <BasePage
            headerPage={{
              showDelegateInfo: true,
              title: (
                <SC.ContentTitle>
                  <div>{t(`title.${toView ? 'toView' : id ? 'edit' : 'create'}`)} </div>
                  {advance && !isLoading && (
                    <StatusTag showStatusIcons variant={getAdvancesStatusColor(advance?.status)}>
                      {getAdvancesStatusLabel(advance?.status)}
                    </StatusTag>
                  )}
                </SC.ContentTitle>
              ),
              breadcrumbItems: [
                {
                  to: expensesAdvancesBaseURL,
                  label: t('advances'),
                },
                {
                  to: '',
                  label: t(`title.${toView ? 'toView' : id ? 'edit' : 'create'}`),
                },
              ],
            }}
            footerPage={
              <AdvanceRegisterFooterPage
                status={advance?.status}
                isLoading={isLoading || saveIsLoading || statusIsLoading}
                id={id}
                toView={toView}
                handleStatus={onClickHandleStatus}
              />
            }>
            <SC.Container>
              <SC.DescriptionContainer>
                <Typography variant="body3">{t(`${toView ? 'toViewDescription' : 'description'}`)}</Typography>
              </SC.DescriptionContainer>
              <SC.Content>
                <AdvanceRegisterForm
                  advanceReport={{
                    name: advance?.report?.name || navigateStateReport?.linkedReport?.name || null,
                    description: advance?.report?.description || navigateStateReport?.linkedReport?.description || null,
                    id: advance?.report?.id || navigateStateReport?.linkedReport?.id || null,
                    status: advance?.report?.status || navigateStateReport?.linkedReport?.status || null,
                  }}
                  advanceId={advance?.id}
                  isLoading={isLoading || fieldsIsLoading}
                  advanceStatus={advance?.status}
                  user={advance?.user?.name}
                  fields={fields}
                />
              </SC.Content>
            </SC.Container>
          </BasePage>
        </form>
      </FormProvider>
      {justificationIsOpen && (
        <JustificationModalAdvanceReproval
          isOpen={justificationIsOpen}
          isLoading={statusIsLoading}
          onActionClick={msg => handleStatus(msg, statusSelected)}
          onCloseClick={() => setJustificationIsOpen(false)}
          advancesNumber={1}
        />
      )}
    </>
  );
}
