import { Skeleton } from '@mui/material';
import { skeletonCategorySwitchCardPrintItems } from '@shared/constant';

import * as SC from './styled';

export interface CategoryListSwitchCardSkeletonProps {
  printItems?: number;
}

export const CategoryListSwitchCardSkeleton = ({
  printItems = skeletonCategorySwitchCardPrintItems,
}: CategoryListSwitchCardSkeletonProps) => {
  return (
    <SC.Container>
      {new Array(printItems).fill({}).map((_, key) => (
        <Skeleton key={key} height={56} width={'100%'} variant="rounded" />
      ))}
    </SC.Container>
  );
};
