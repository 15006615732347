import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { StatusTag } from '@atoms/StatusTag';
import { IReportsSummary } from '@containers/Expenses/context/types/reports';
import { IReport } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { ActionsFeedback } from '@molecules/ActionsFeedback';
import { useReportDetailsDriver } from '@shared/hooks/navigation/useReportDetailsDriver';

import { getReportStatusColorVariant, getReportStatusTranslator } from '../../../../Reports/common';
import * as SC from './styled';

export interface ReportCardProps extends IReportsSummary {
  onOpenReportClick?(id: number): void;
}

export const ReportCard = ({
  id,
  referenceId,
  status,
  name,
  violationNumber,
  attachmentsNumber,
  commentsNumber,
  onOpenReportClick,
}: ReportCardProps) => {
  const { t } = useTranslation();
  const reportStatusColorVariant = getReportStatusColorVariant({ status });
  const reportStatusTranslator = t(getReportStatusTranslator(status));
  const navigateReportDetails = useReportDetailsDriver();

  function openReport(id: IReport['id']) {
    navigateReportDetails(id);
    onOpenReportClick?.(id);
  }

  return (
    <BorderedWrapping>
      <SC.ReportCardContainer onClick={() => openReport(id)}>
        <SC.ReportCardHeader>
          <ShapeIcon
            icon={Boolean(violationNumber) ? 'IconAlertCircle' : 'IconFolder'}
            variant={reportStatusColorVariant}
            size="medium"
          />
          <ActionsFeedback
            showAttachments={Boolean(attachmentsNumber)}
            showMessages={Boolean(commentsNumber)}
            numberOfActions={violationNumber}
          />
        </SC.ReportCardHeader>
        <SC.ReportCardBody>
          <SC.ReportCodeLabelContainer>
            <SC.ReportCodeLabel variant="body3">{referenceId}</SC.ReportCodeLabel>
          </SC.ReportCodeLabelContainer>
          {Boolean(name) ? (
            <SC.ReportTitleLabel variant="headline8">{name}</SC.ReportTitleLabel>
          ) : (
            <SC.ReportTitleLabelEmpty variant="headline8">{t('report.unnamedReport')}</SC.ReportTitleLabelEmpty>
          )}
        </SC.ReportCardBody>
        <SC.ReportCardFooter>
          <StatusTag variant={reportStatusColorVariant}>{reportStatusTranslator}</StatusTag>
        </SC.ReportCardFooter>
      </SC.ReportCardContainer>
    </BorderedWrapping>
  );
};
