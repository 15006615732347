import { useEffect, useState } from 'react';
import {
  EReportExpirationDateConditionalTypeSettings,
  EReportExpirationDateTypeOfFrequencySettings,
  IReportExpirationDateSettings,
} from '@containers/Customizations/context/types/settings/expenseReportSettingsTypes';
import { SelectOption } from '@shared/helpers/selectOptions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { PeriodFrequency } from './PeriodFrequency/PeriodFrequency';
import { SetDueDateSection } from './SetDueDateSection/SetDueDateSection';
import * as SC from './styled';

interface IProps {
  expirationDate: IReportExpirationDateSettings;
  isLoading: boolean;
  onChange(data: IProps['expirationDate']): void;
}
interface IFrequencyData {
  type: EReportExpirationDateTypeOfFrequencySettings;
  conditionalType: EReportExpirationDateConditionalTypeSettings;
  value: number[];
}

export function SetDueDate({ expirationDate, isLoading, onChange }: IProps) {
  const t = useTranslate('organisms.generalReportSettings.expirationDate.configurationModal.fields');
  const [currentTypeOfFrequency, setCurrentTypeOfFrequency] = useState<EReportExpirationDateTypeOfFrequencySettings>(
    expirationDate.typeOfFrequency,
  );
  const [typeOfFrequencyOptions] = useState<SelectOption[]>(getOfFrequencyOptions());
  const [frequencyData, setFrequencyData] = useState<IFrequencyData[]>(getInitialFrequencyData());

  function getOfFrequencyOptions(): SelectOption[] {
    return Object.keys(EReportExpirationDateConditionalTypeSettings).map(key => ({
      label: t(EReportExpirationDateConditionalTypeSettings[key]),
      value: key,
    }));
  }

  function getInitialFrequencyData(): IFrequencyData[] {
    return Object.keys(EReportExpirationDateTypeOfFrequencySettings).map(key => ({
      type: EReportExpirationDateTypeOfFrequencySettings[key],
      conditionalType: expirationDate?.typeOfFrequency === key ? expirationDate?.conditionalType : null,
      value: expirationDate?.typeOfFrequency === key ? expirationDate?.value : null,
    }));
  }

  function isChecked(typeOfFrequency: EReportExpirationDateTypeOfFrequencySettings): boolean {
    return typeOfFrequency === currentTypeOfFrequency;
  }

  function getLabelPeriodFrequency(typeOfFrequency: EReportExpirationDateTypeOfFrequencySettings): string {
    switch (typeOfFrequency) {
      case EReportExpirationDateTypeOfFrequencySettings.DAY_OF_THE_WEEK:
        return t('theNext');
      case EReportExpirationDateTypeOfFrequencySettings.DAYS_OF_MONTH:
        return t('everyDay');
      default:
        return '';
    }
  }

  function getFrequencyValue(typeOfFrequency: EReportExpirationDateTypeOfFrequencySettings): number[] {
    return frequencyData?.find(_frequency => _frequency.value?.length && _frequency.type === typeOfFrequency)?.value;
  }

  function getConditionalTypeValue(
    typeOfFrequency: EReportExpirationDateTypeOfFrequencySettings,
  ): EReportExpirationDateConditionalTypeSettings {
    return frequencyData?.find(_frequency => _frequency.value?.length && _frequency.type === typeOfFrequency)
      ?.conditionalType;
  }

  function getTypeOfFrequencyIndex(typeOfFrequency: EReportExpirationDateTypeOfFrequencySettings): number {
    return frequencyData?.findIndex(_frequency => _frequency.type === typeOfFrequency);
  }

  function onFrequencyChange(typeOfFrequency: EReportExpirationDateTypeOfFrequencySettings, value: number[]) {
    const _frequencyData: IFrequencyData[] = frequencyData;
    _frequencyData[getTypeOfFrequencyIndex(typeOfFrequency)].value = value;

    setFrequencyData(_frequencyData);
    onChange({ ...expirationDate, value });
  }

  function onConditionalTypeChange(
    typeOfFrequency: EReportExpirationDateTypeOfFrequencySettings,
    conditionalType: EReportExpirationDateConditionalTypeSettings,
  ) {
    const _frequencyData: IFrequencyData[] = frequencyData;
    _frequencyData[getTypeOfFrequencyIndex(typeOfFrequency)].conditionalType = conditionalType;

    setFrequencyData(_frequencyData);
    onChange({ ...expirationDate, conditionalType });
  }

  useEffect(() => {
    expirationDate?.typeOfFrequency && setCurrentTypeOfFrequency(expirationDate.typeOfFrequency);
  }, [expirationDate?.typeOfFrequency]);

  function getCriterionSection(typeOfFrequency: EReportExpirationDateTypeOfFrequencySettings) {
    return (
      <SetDueDateSection
        isDisabled={!isChecked(typeOfFrequency)}
        isChecked={isChecked(typeOfFrequency)}
        isLoading={isLoading}
        onClick={() =>
          onChange({
            ...expirationDate,
            typeOfFrequency,
            value: getFrequencyValue(typeOfFrequency),
            conditionalType: getConditionalTypeValue(typeOfFrequency),
          })
        }
        fields={[
          {
            label: getLabelPeriodFrequency(typeOfFrequency),
            field: (
              <PeriodFrequency
                isDisabled={!isChecked(typeOfFrequency)}
                type={typeOfFrequency}
                value={getFrequencyValue(typeOfFrequency)}
                onChange={value => onFrequencyChange(typeOfFrequency, value)}
              />
            ),
          },
          {
            label: t(
              typeOfFrequency === EReportExpirationDateTypeOfFrequencySettings.DAYS_OF_MONTH
                ? 'ofTheMonthAfter'
                : 'daysAfter',
            ),
            field: (
              <SC.Select
                disabled={!isChecked(typeOfFrequency)}
                label={t('expirationDateType')}
                options={typeOfFrequencyOptions}
                value={getConditionalTypeValue(typeOfFrequency)}
                onSelectChange={(_, data) => onConditionalTypeChange(typeOfFrequency, data?.value)}
              />
            ),
          },
        ]}
      />
    );
  }

  return (
    <SC.Container>
      {getCriterionSection(EReportExpirationDateTypeOfFrequencySettings.DAYS)}
      {getCriterionSection(EReportExpirationDateTypeOfFrequencySettings.DAY_OF_THE_WEEK)}
      {getCriterionSection(EReportExpirationDateTypeOfFrequencySettings.DAYS_OF_MONTH)}
    </SC.Container>
  );
}
