import type { LocaleKeys } from 'src/i18n/locales/localeKeys';

import { useTranslation } from './Translator';

/** Turns an object into a path collection
 * @example
type Example = Path<{ a: { b: ''; c: '' }; d: '' }>;
// Example: 'a.b' | 'a.c' | 'd'
*/
export type Path<TObject extends object, TKey extends keyof TObject = keyof TObject> = TKey extends string
  ? TObject[TKey] extends string
    ? TKey
    : TObject[TKey] extends object
    ? `${TKey}.${Path<TObject[TKey]>}`
    : never
  : never;

export default function useTranslate() {
  const { t } = useTranslation();
  return function translate(path: Path<LocaleKeys['translations']>, variables?: object) {
    return t(path, variables);
  };
}
