export interface IReportsSummary {
  id: number;
  referenceId: string;
  status: EReportStatus;
  name: string;
  description: string;
  violationNumber: number;
  attachmentsNumber: number;
  commentsNumber: number;
}

export enum EReportStatus {
  OPEN = 'OPEN',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REPROVED = 'REPROVED',
  REIMBURSED = 'REIMBURSED',
  AWAITING_FINANCIAL_PROCESSING = 'AWAITING_FINANCIAL_PROCESSING',
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
  PROCESSING_PAYMENT_ERROR = 'PROCESSING_PAYMENT_ERROR',
}

export interface IReportsResponse {
  reports: IReportsSummary[];
  currentPage: number;
  limit: number;
  pages: number;
  total: number;
}

export interface IGetRequestResponse {
  getReports: IReportsResponse;
}

export interface ISummaryReport {
  id?: number;
  referenceId?: string;
  name?: string;
  description?: string;
}
