import { ActionsButton } from '@molecules/ActionsButton/ActionsButton';
import { costCentersBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

export function AddCostCentersButton() {
  const t = useTranslate('costCenters.addButton');
  const navigate = useNavigate();

  function onImportClick(key: string) {
    if (key === 'addManually') {
      navigate(`${costCentersBaseURL}/register`);
    } else {
      navigate(`${costCentersBaseURL}/register/file`);
    }
  }

  return (
    <ActionsButton
      actionName={t('buttonLabel')}
      actionIcon="IconPlus"
      actionsList={[
        {
          key: 'addManually',
          name: t('options.addManually'),
          onClick: onImportClick,
          icon: 'IconFile',
        },
        {
          key: 'addByFile',
          name: t('options.addByFile'),
          onClick: onImportClick,
          icon: 'IconFileImport',
        },
      ]}
    />
  );
}
