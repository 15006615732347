import { useState } from 'react';
import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import {
  EReportExpirationDateTypeSettings,
  IReportExpirationDateSettings,
} from '@containers/Customizations/context/types/settings/expenseReportSettingsTypes';
import { SelectField, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { SelectOption } from '@shared/helpers/selectOptions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from '../styled';
import { SetDueDateModal } from './SetDueDateModal/SetDueDateModal';
import { LinkExpirationDateType } from './styled';

interface IProps {
  expirationDate: IReportExpirationDateSettings;
  tooltipText: string;
  isLoading: boolean;
  updateIsLoading: boolean;
  handleChange(key: string, data: IProps['expirationDate']): void;
}

export function ReportSettingsExpirationDate({
  expirationDate,
  tooltipText,
  isLoading,
  updateIsLoading,
  handleChange,
}: IProps) {
  const t = useTranslate('organisms.generalReportSettings.expirationDate');
  const [expirationDateTypeOptions] = useState<SelectOption[]>(getOptions());
  const [showModal, setShowModal] = useState<boolean>(false);

  function getOptions(): SelectOption[] {
    return Object.keys(EReportExpirationDateTypeSettings).map(key => ({
      label: t(EReportExpirationDateTypeSettings[key]),
      value: key,
    }));
  }

  function _handleChange(key: string, value: boolean | EReportExpirationDateTypeSettings) {
    const currentExpirationDate: IProps['expirationDate'] = { ...expirationDate };
    currentExpirationDate[key] = value;

    if (currentExpirationDate.expirationDateType === EReportExpirationDateTypeSettings.MANUAL.toUpperCase()) {
      currentExpirationDate.typeOfFrequency = null;
      currentExpirationDate.value = null;
      currentExpirationDate.conditionalType = null;
    }

    handleChange('expirationDate', currentExpirationDate);
  }

  return (
    <SC.Container>
      <ToggleSettings
        toggleKey={`allowAdd`}
        checked={expirationDate?.allowAdd}
        onChange={_handleChange}
        label={t(`allowAdd`)}
        isLoading={isLoading}
        tooltipText={tooltipText}
      />
      {expirationDate?.allowAdd && (
        <SC.Content>
          <SC.ConditionalTypeFiledContainer>
            {isLoading ? (
              <Skeleton variant="rounded" width={360} height={60} />
            ) : (
              <>
                <SelectField
                  label={t('expirationCriteria')}
                  options={expirationDateTypeOptions}
                  value={expirationDate?.expirationDateType}
                  onSelectChange={(_, data) => _handleChange('expirationDateType', data?.value)}
                />
                {expirationDate?.expirationDateType === EReportExpirationDateTypeSettings.AUTOMATIC.toUpperCase() && (
                  <LinkExpirationDateType variant="default" onClick={() => setShowModal(true)}>
                    {t('configureAutomaticExpiryData')}
                  </LinkExpirationDateType>
                )}
              </>
            )}
          </SC.ConditionalTypeFiledContainer>
        </SC.Content>
      )}
      {showModal && (
        <SetDueDateModal
          open={showModal}
          expirationDate={expirationDate}
          isLoading={isLoading}
          updateIsLoading={updateIsLoading}
          onClose={() => setShowModal(false)}
          onChange={data => {
            handleChange('expirationDate', data);
            setShowModal(false);
          }}
        />
      )}
    </SC.Container>
  );
}
