import { IConfigurationApprovalSettings } from '@containers/Customizations/context/types/settings/expenseApprovalSettingsTypes';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

interface IUseApprovalSettings {
  settings: IConfigurationApprovalSettings;
  isLoading: boolean;
  error: unknown;
}

export function useExpenseApprovalSettings(): IUseApprovalSettings {
  const { data, error, isLoading } = useFetch<IConfigurationApprovalSettings>('v1/settings/expense/approval');

  error && showToast({ message: error?.message, type: 'error' });

  return {
    settings: data,
    isLoading: isLoading,
    error,
  };
}
