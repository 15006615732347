import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { ESizeVariant } from '../variants';
import * as SC from './styled';

export interface FileItemInterface {
  name?: string;
  image?: string;
  progress?: number;
  error?: boolean;
}

export interface ReceiptMainImageProps extends FileItemInterface {
  onDeleteClick?: React.MouseEventHandler<HTMLDivElement>;
  onExpandClick?(): void;
  onImageClick?(name: string): void;
  size: ESizeVariant;
  mime: string;
}

export const ReceiptMainImage = ({ image, onDeleteClick, onExpandClick, size, mime = '' }: ReceiptMainImageProps) => {
  return (
    <SC.Container>
      {(onExpandClick || onDeleteClick) && (
        <SC.ButtonContainer onClick={onExpandClick ?? onDeleteClick}>
          <SC.TrashIcon icon={onExpandClick ? 'IconArrowsDiagonal' : 'IconTrash'} />
        </SC.ButtonContainer>
      )}

      {!mime.includes('application/pdf') ? (
        <TransformWrapper initialScale={1}>
          {({ zoomIn, zoomOut }) => (
            <>
              <SC.ZoomContainer>
                <IconButton size="small" variant="filled" icon="Add" onClick={() => zoomIn()} />
                <IconButton size="small" variant="filled" icon="IconMinus" onClick={() => zoomOut()} />
              </SC.ZoomContainer>
              <TransformComponent wrapperClass="image-wrapper">
                <SC.ImageContainer src={image} size={size} crossOrigin="anonymous" />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      ) : (
        <>
          <SC.FileFrame src={image} />
        </>
      )}
    </SC.Container>
  );
};
