import { ShapeIcon } from '@atoms/ShapeIcon';
import { EAccountingPendingStatus } from '@containers/Financial/hooks/dataSources/useAccountingPendingExpensesStatus';
import { useTranslation } from '@locale/Translator';
import { CardFilterSkeleton } from '@organisms/CardFilter/CardFilterSkeleton';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';

import { ColorVariant } from 'src/themes/types/Variants';

import * as SC from '../styled';

interface IAccountingPendingCardFilterProps {
  isMain?: boolean;
  isLoading?: boolean;
  quantityItems?: number;
  totalValue?: number;
  isSelected: boolean;
  status: EAccountingPendingStatus;
  title: string;
}

export const AccountingPendingCardFilter = ({
  isMain,
  isLoading,
  quantityItems = 0,
  totalValue = 0,
  isSelected = false,
  title,
  status,
}: IAccountingPendingCardFilterProps) => {
  const { t } = useTranslation();
  const { getValueWithCurrency } = useValueFormatter();

  if (isLoading) {
    return <CardFilterSkeleton main={isMain} />;
  }

  function getIconNameForStatus(status: EAccountingPendingStatus) {
    switch (status) {
      case EAccountingPendingStatus.PENDING_ACCOUNTING:
        return 'IconReceipt2';
      case EAccountingPendingStatus.OUT_OF_POLITICS:
        return 'IconAdjustmentsOff';
      case EAccountingPendingStatus.CORPORATE_CARD_EXPENSES:
        return 'IconCreditCard';
    }
  }

  function validateVariantIcon(isSelected: boolean): ColorVariant {
    return isSelected ? 'secondary' : 'neutral';
  }

  return (
    <SC.Container main={isMain} isSelected={isSelected} data-testid="accounting-pending-card-filter">
      <SC.StatusDescriptionContainer isSelected={isSelected}>
        <SC.ReportsStatusTitle>
          <SC.ReportsStatusText variant="body4" weight={600}>
            {title}
          </SC.ReportsStatusText>
        </SC.ReportsStatusTitle>
        <SC.IconFolderContainer isSelected={isSelected}>
          {<ShapeIcon size="xsmall" icon={getIconNameForStatus(status)} variant={validateVariantIcon(isSelected)} />}
        </SC.IconFolderContainer>
      </SC.StatusDescriptionContainer>
      <SC.SubtitleBox>
        <SC.ReportsExpensesText variant="headline8" weight={700} isSelected={isSelected}>
          {`${quantityItems} ${t(`organisms.accountingPending.statusCard.title`)}`}
        </SC.ReportsExpensesText>
        <SC.CircleDivider />
        <SC.ReportsValueText variant="body4" weight={600} isSelected={isSelected}>
          {getValueWithCurrency({ value: totalValue || 0, currencyPrefix: 'R$' })}
        </SC.ReportsValueText>
      </SC.SubtitleBox>
    </SC.Container>
  );
};
