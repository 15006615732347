import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface ContainerProps {
  viewOnly?: boolean;
}

export const Container = styled.div<ContainerProps>(({ viewOnly }) => ({
  width: '100%',
  ...(viewOnly && {
    width: '350px',
  }),
}));

export const WaypointContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs,
  alignItems: 'center',
}));

export const DistanceRoutesContainer = styled.div(({ theme }) => ({
  paddingTop: theme.spacings.xs,
}));

export const IconTrash = styled(IconButton)(({ theme }) => ({
  paddingLeft: theme.spacings.xs3,
}));

export const AddButtonContainerSkeleton = styled.div(({ theme }) => ({
  paddingTop: theme.spacings.xs,
  display: 'flex',
  gap: theme.spacings.xs,
  alignItems: 'center',
}));

export const ExpandButtonContainer = styled.div<ContainerProps>(({ viewOnly }) => ({
  marginLeft: 0,

  ...(viewOnly && {
    marginLeft: '10px',
  }),
}));

export const ContainerGpsFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const ContainerImageGps = styled.div`
  width: 100%;
  padding: auto;
  margin-top: ${({ theme }) => theme.spacings.xs};
  margin-bottom: -30px;
`;

export const ContentImageGps = styled.div`
  width: fit-content;
  margin: auto;
`;
