import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getMileageByPolicyId } from '../context/queries/policies';
import { IMileagePolicySettingsResponse } from '../context/types/policies';

type Props = {
  id: number;
};

export const useMileageByPolicyId = ({ id }: Props) => {
  const {
    loading,
    data: policiesData,
    refetch,
  } = useQuery<IMileagePolicySettingsResponse>(getMileageByPolicyId, {
    variables: {
      policyId: id,
    },
    skip: !id,
    fetchPolicy: 'no-cache',
  });

  return {
    isLoading: loading,
    mileagePolicy: policiesData?.getPolicyMileageByPolicyId,
    refetch,
  };
};
