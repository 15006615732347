import { useMemo } from 'react';
import { PolicyOCRConfig } from '@containers/Policies/context/types/ocr';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

type OCRCheck = {
  label: string;
  key: keyof PolicyOCRConfig['readFields'];
};

export function usePolicyOCRChecks() {
  const t = useTranslate('policy.register.ocr.sections.validations.checks');

  return useMemo<OCRCheck[]>(() => {
    const keys: Array<keyof PolicyOCRConfig['readFields']> = [
      'establishmentName',
      'address',
      'registrationNumber',
      'invoiceDate',
      'invoiceKey',
      'totalInvoiceValue',
      'invoiceItemsDescription',
      'invoiceItemsValue',
      'invoiceItemsQuantity',
      'invoiceItemsTax',
      'invoiceItemsMeasurementUnit',
      'invoiceTotalItemsValue',
    ];

    return keys.map(key => ({
      key,
      label: t(key),
    }));
  }, []);
}
