import { ICustomField } from '@containers/Expenses/context/types';

interface FormatExpenseFieldsProps {
  category: {
    description: string;
  };
  costCenter: {
    name: string;
  };
  client: {
    name: string;
  };
  project: {
    name: string;
  };
  quantity: number;
  comments: string;
  customFields?: Omit<ICustomField, 'defaultValue' | 'options' | 'required' | 'active' | 'type'>[];
}

export interface ExpenseFields {
  label: string;
  value: string;
  hasLabelTranslation: boolean;
  key?: string;
}

export const formatExpenseFields = (expenseFields: FormatExpenseFieldsProps): ExpenseFields[] => {
  const emptyValue: string = '-';

  function getValueField(field: string) {
    if (expenseFields[field] && typeof expenseFields[field] === 'object') {
      return expenseFields?.[field]?.['description'] || expenseFields?.[field]?.['name'] || emptyValue;
    }
    return expenseFields?.[field] || emptyValue;
  }

  const fields = Object.keys(expenseFields)?.reduce((result, field) => {
    if (field === 'customFields' && expenseFields?.customFields?.length > 0)
      return [
        ...result,
        ...expenseFields?.customFields?.map(customField => ({
          label: customField?.label?.[0].toUpperCase() + customField?.label?.substring(1),
          value: customField?.value ?? emptyValue,
          key: 'customField',
        })),
      ];
    else
      return [
        ...result,
        {
          label: field,
          value: getValueField(field),
          hasLabelTranslation: true,
          key: field,
        },
      ];
  }, []);

  return fields;
};
