import { useState } from 'react';
import { TextArea } from '@atoms/TextArea';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

interface IProps {
  isOpen: boolean;
  isLoading?: boolean;
  advancesNumber: number;
  onActionClick(justificationText: string): void;
  onCloseClick(): void;
}

export const JustificationModalAdvanceReproval = ({
  isOpen,
  isLoading,
  advancesNumber,
  onActionClick,
  onCloseClick,
}: IProps) => {
  const t = useTranslate('organisms.justificationModalAdvanceReproval');
  const [justificationText, setJustificationText] = useState('');
  const formOfDescription = advancesNumber > 1 ? 'pluralForm' : 'singularForm';

  function onClose() {
    onCloseClick();
    setJustificationText('');
  }

  function onAction() {
    onActionClick(justificationText);
    setJustificationText('');
  }

  return (
    <SC.Container>
      <DangerActionModal
        isLoading={isLoading}
        headerIconLabel={t('attention')}
        headerTitle={t(`${formOfDescription}.popupTitle`, {
          advancesSelected: advancesNumber,
        })}
        headerSubtitle={t(`${formOfDescription}.popupSubtitle`)}
        actionDisabled={justificationText?.length < 3}
        labelButtonAction={
          <SC.LabelButtonContent>{t(`${formOfDescription}.confirmDisapproval`)}</SC.LabelButtonContent>
        }
        onActionClick={onAction}
        onCancelClick={onClose}
        onCloseClick={onClose}
        open={isOpen}>
        <SC.TextAreaLabel variant="body3">{t(`${formOfDescription}.justifyTheDisapproval`)}</SC.TextAreaLabel>
        <TextArea
          label={t(`enterTheJustification`)}
          onChange={(_, value) => setJustificationText(value)}
          value={justificationText}
          resize="none"
          rows={5}
        />
      </DangerActionModal>
    </SC.Container>
  );
};
