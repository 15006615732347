import { useMemo } from 'react';
import { DataType } from '@flash-tecnologia/hros-web-ui-v2/dist/components/TransferList/types';

import { ISubsidiary } from '../context/types/subsidiaries';

interface useTransferListProps {
  data: {
    id: string;
    name: string;
    subsidiaryId: string;
  }[];
  ids: string[];
  isRightList?: boolean;
  subsidiaries?: ISubsidiary[];
}

export const useTransferListSet = ({
  data,
  ids,
  subsidiaries,
  isRightList = false,
}: useTransferListProps): DataType => {
  return useMemo(() => {
    let list = data;
    if (isRightList) {
      list = list.filter(item => ids?.includes(item.id.toString()));
    }
    return list.map(item => {
      return {
        name:
          item.subsidiaryId && subsidiaries?.length
            ? `${item.name} - [${subsidiaries?.find(subsidiary => subsidiary.id === item.subsidiaryId).name}]`
            : item.name,
        _id: item.id.toString(),
        checked: false,
        hidden: isRightList ? false : ids?.includes(item.id),
      };
    });
  }, [data, JSON.stringify(subsidiaries)]);
};
