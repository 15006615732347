import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getClients } from '../context/queries/clients';
import { IClientsSummary, IClientsSummaryResponse } from '../context/types/clients';

interface UseClients {
  clients: IClientsSummary[];
  isLoading: boolean;
  error: unknown;
}

export const useAllClients = (skip: boolean = false): UseClients => {
  const { loading, data, error } = useQuery<IClientsSummaryResponse>(getClients, { skip });

  return {
    clients: data?.getClients?.clients || [],
    isLoading: loading,
    error,
  };
};
