import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getCostCenters, getCostCentersSummary } from '../context/queries/costCenters';
import { ICostCentersResponse } from '../context/types/costCenters';

interface IProps {
  skip?: boolean;
  summaryData?: boolean;
}

export const useAllCostCenters = ({ summaryData, skip }: IProps) => {
  const { loading, data, error } = useQuery<ICostCentersResponse>(
    summaryData ? getCostCentersSummary : getCostCenters,
    { skip },
  );

  return {
    costCenters: data?.getCostCenters?.costCenters || [],
    totalCostCenters: data?.getCostCenters?.total || 0,
    isLoading: loading,
    error,
  };
};
