import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import { ICostCenterCompany } from '../../context/types/costCenters';

export const useByIdCostCenters = (costCenterId: ICostCenterCompany['id']) => {
  const path = costCenterId ? `v1/cost-centers/${costCenterId}` : null;

  const { data, error, isLoading } = useFetch<ICostCenterCompany>(
    path,
    undefined,
    undefined,
    undefined,
    () => !!costCenterId && showToast({ message: `${error.message}`, type: 'error' }),
    !!costCenterId,
  );

  return {
    error,
    isLoading: isLoading,
    costCenter: data as ICostCenterCompany,
  };
};
