import React from 'react';
import { Skeleton } from '@mui/material';

import * as SC from '../styled';
import { EAccessor } from './useHeaderGridColumns';

export interface UseBodyGridRowSkeletonProps {
  rowsPerPage: number;
}
export type TGridRow = Record<EAccessor, React.ReactNode>;
const skeletonText = <Skeleton variant="text" height={22} width={90} />;

export const useBodyGridRowSkeleton = ({ rowsPerPage = 3 }: UseBodyGridRowSkeletonProps): TGridRow[] => {
  return new Array(rowsPerPage).fill({}).map(() => {
    return {
      selection: <Skeleton variant="rounded" height={20} width={20} />,
      category: (
        <SC.TdContent>
          <Skeleton variant="circular" height={40} width={40} />
          <SC.CategoryInfosContainer>
            {skeletonText}
            <SC.ActionsFeedbackContainer>
              <Skeleton variant="text" height={20} width={100} />
            </SC.ActionsFeedbackContainer>
          </SC.CategoryInfosContainer>
        </SC.TdContent>
      ),
      id: skeletonText,
      date: skeletonText,
      value: skeletonText,
      place: <Skeleton variant="text" height={22} width={150} />,
      receipt: <Skeleton variant="rounded" height={55} width={55} />,
      actions: <Skeleton variant="circular" height={40} width={40} />,
    };
  });
};
