import { useEffect, useState } from 'react';
import { Loader } from '@atoms/Loader';
import { AssignUsers } from '@containers/Projects/components/organisms/AssignUsers';
import { ProjectRegisterGeneralData } from '@containers/Projects/components/organisms/ProjectRegisterGeneralData/ProjectRegisterGeneralData';
import { useProjectById } from '@containers/Projects/hooks/rest/useProjectById';
import { useProjectEmployees } from '@containers/Projects/hooks/rest/useProjectEmployees';
import { useSaveProject } from '@containers/Projects/hooks/rest/useSaveProject';
import { ProjectFormData, projectFormSchema } from '@containers/Projects/validators/projectFormSchema';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { projectsBaseURL } from '@shared/constant';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ProjectRegisterFooterPage } from './ProjectRegisterFooterPage/ProjectRegisterFooterPage';
import { ProjectRegisterProgressHeader } from './ProjectRegisterProgressHeader';
import * as SC from './styled';

type Params = {
  projectId: string;
};

export const ProjectRegister = () => {
  const { projectId: id } = useParams<Params>();
  const [projectId, setProjectId] = useState(id);
  const isEditing = !!projectId;
  const { t } = useTranslation();
  const { project, isLoading: isLoadingProject } = useProjectById(id);
  const navigate = useNavigate();
  //const saveProject = useSaveProject();
  const { handleSave, data: updatedProject, isLoading: isSaving } = useSaveProject();
  const [step, setStep] = useState(0);
  const { handle: handleProjectEmployees } = useProjectEmployees();

  const projectForm = useForm<ProjectFormData>({
    resolver: yupResolver(projectFormSchema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      externalCode: '',
      active: true,
    },
  });

  useEffect(() => {
    updatedProject && setProjectId(updatedProject.id);
  }, [isSaving]);

  useEffect(() => {
    if (isLoadingProject || !isEditing) return;

    if (project) {
      const { client, ...rest } = project;

      return projectForm.reset({
        clientId: client?.id,
        ...rest,
        employees: rest?.employees ?? [],
      });
    }

    return navigate(projectsBaseURL, { replace: true });
  }, [isLoadingProject]);

  async function onSubmit(data: ProjectFormData) {
    if (step === 0) {
      const _project = { ...data };
      delete _project.employees;

      try {
        await handleSave(_project, project?.id);
        setStep(1);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await handleProjectEmployees({
          projectId: projectId,

          currentProjectEmployees: project?.employees ?? [],
          newAProjectEmployees: data?.employees,
        });

        navigate(projectsBaseURL);
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <>
      <ProjectRegisterProgressHeader step={step} isEditing={isEditing} />
      <FormProvider {...projectForm}>
        <form onSubmit={projectForm.handleSubmit(onSubmit)}>
          <Trans i18nKey="translations">
            <SC.PageContainer>
              <SC.PageTitleContainer>
                <Typography variant="headline6">
                  {!isEditing
                    ? t(`pages.projectRegister.manualAddProject.${step}`)
                    : t('pages.projectRegister.editProject')}
                </Typography>
              </SC.PageTitleContainer>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={3} sx={{ mb: 3 }}>
                  <SC.Instruction variant="body3">
                    {t(`pages.projectRegister.registerInstructions.${step}`)}
                  </SC.Instruction>
                </Grid>

                <Grid item xs={12} md={12} lg={10} xl={8}>
                  {step === 0 ? (
                    <ProjectRegisterGeneralData isLoading={isLoadingProject} />
                  ) : (
                    <AssignUsers project={project} />
                  )}
                </Grid>
              </Grid>
            </SC.PageContainer>

            <ProjectRegisterFooterPage isLoading={isSaving} />

            <Loader show={isSaving} description={`${t('pages.projectRegister.feedbacks.savingProject')}...`} />
          </Trans>
        </form>
      </FormProvider>
    </>
  );
};
