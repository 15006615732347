import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const GridContent = styled.div`
  position: relative;
  display: inline-grid;
`;
