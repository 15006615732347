import { gql } from '@apollo/client';

export const getReportStatusSummary = gql`
  query GetSummaryForFinance {
    getSummaryForFinance {
      reports {
        open {
          count
          totalValue
        }
        submitted {
          count
          totalValue
        }
        approved {
          count
          totalValue
        }
        reproved {
          count
          totalValue
        }
      }
    }
  }
`;

export const getReportsForFinance = gql`
  query GetReportsForFinance($page: Int!, $filters: ReportForFinanceFilter) {
    getReportsForFinance(page: $page, filters: $filters) {
      reports {
        status
        user {
          name
        }
        id
        referenceId
        name
        violationNumber
        commentsNumber
        value
        currencyPrefix
        createdAt
      }
      total
      currentPage
      pages
      limit
    }
  }
`;

export const updateReportListStatus = gql`
  mutation UpdateReportListStatus(
    $reports: [Int!]!
    $status: ReportStatusActions!
    $message: String
    $expirationDate: DateTime
  ) {
    updateReportListStatus(reports: $reports, status: $status, message: $message, expirationDate: $expirationDate) {
      reports {
        id
        message
        error
      }
    }
  }
`;

export const getExcelBase64ReportsForFinance = gql`
  query GetExcelBase64ReportsForFinance($filters: ReportForFinanceFilter) {
    getExcelBase64ReportsForFinance(filters: $filters)
  }
`;
