import { useEffect, useState } from 'react';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { useEstablishments } from '@containers/Expenses/hooks/useEstablishments';
import { useKmPolicies } from '@containers/Expenses/hooks/useKmPolicies';
import { useDebounce } from 'use-debounce';

import { Option } from '../Autocomplete';
import { useAddingLocationEstablishment } from './useAddingLocationEstablishment';
import { useCheckShouldSearchFromGoogleMaps } from './useCheckShouldSearchFromGoogleMaps';
import { useEstablishmentsGoogleMaps } from './useEstablishmentsGoogleMaps';
import { useFilterOptions } from './useFilterOptions';
import { useOptionsEstablishments } from './useOptionsEstablishments';

export interface UseDataManagementEstablishmentsProps {
  establishmentExpense: IEstablishment;
  onSelectChange(establishment: IEstablishment): void;
}

export interface UseDataManagementEstablishments {
  options: Option[];
  selectedEstablishment: IEstablishment;
  isLoading: boolean;
  hasEstablishments: boolean;
  shouldSearchFromGoogleMaps: boolean;
  onSelectedEstablishmentClick(option: Option): void;
  onInputValueChange(value: string, empty: boolean): void;
}

export function useDataManagementEstablishments({
  establishmentExpense,
  onSelectChange,
}: UseDataManagementEstablishmentsProps): UseDataManagementEstablishments {
  const { establishments, isLoading: establishmentsIsLoading } = useEstablishments({ page: 1, limit: 250 });
  const [selectedEstablishment, setSelectedEstablishment] = useState<IEstablishment>(establishmentExpense);
  const [inputValueSearch, setInputValueSearch] = useState<string>('');
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [inputValue] = useDebounce(inputValueSearch, 250);
  const { allowSearchNewPlaces } = useKmPolicies();
  const shouldSearchFromGoogleMaps = useCheckShouldSearchFromGoogleMaps({
    isEmpty,
    inputValue,
    selectedEstablishment,
    allowSearchNewPlaces,
  });
  const checkOptions = useFilterOptions({ inputValue, establishments, selectedEstablishment });
  const { establishmentsGoogleMaps, establishmentsGoogleMapsIsLoading } = useEstablishmentsGoogleMaps({
    inputValue,
    allowSearch: shouldSearchFromGoogleMaps,
  });
  useAddingLocationEstablishment({
    selectedEstablishment,
    establishmentExpense,
    setSelectedEstablishment,
    onSelectChange,
  });
  const options: Option[] = useOptionsEstablishments(
    shouldSearchFromGoogleMaps ? establishmentsGoogleMaps : establishments,
  );

  function onSelectedEstablishmentClick(option: Option): void {
    const establishmentsOption: IEstablishment = establishments.find(
      establishment => establishment?.placeId == option?.value,
    );

    const locationOption: IEstablishment = establishmentsGoogleMaps.find(
      establishment => establishment?.placeId == option?.value,
    );

    setSelectedEstablishment(establishmentsOption || locationOption);
    onSelectChange?.(establishmentsOption || locationOption);
  }

  function onInputValueChange(value: string): void {
    setInputValueSearch(value);
  }

  useEffect(() => {
    setIsEmpty(checkOptions?.length == 0);
  }, [inputValue]);

  useEffect(() => {
    setSelectedEstablishment(establishmentExpense || null);
  }, [establishmentExpense]);

  return {
    options,
    hasEstablishments: establishments && establishments.length > 0,
    selectedEstablishment,
    isLoading: establishmentsIsLoading || establishmentsGoogleMapsIsLoading,
    shouldSearchFromGoogleMaps,
    onSelectedEstablishmentClick,
    onInputValueChange,
  };
}
