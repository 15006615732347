import { EAdvancesStatusColorVariant, EAdvanceStatus } from '@containers/Advances/context/types/advance';
import { useAdvancesForAccountingSummaryFilters } from '@containers/Financial/hooks/dataSources/useAdvancesForAccountingSummaryFilters';
import { useTranslation } from '@locale/Translator';
import { CardFilter } from '@organisms/CardFilter';
import { CardFiltersGroup } from '@organisms/CardFiltersGroup';

interface IProps {
  isLoading?: boolean;
  selectedStatus: EAdvanceStatus[];
  onSelectedStatusClick(status: EAdvanceStatus): void;
}

export const AdvancesCardFiltersGroup = ({ isLoading, selectedStatus, onSelectedStatusClick }: IProps) => {
  const { t } = useTranslation();
  const { advancesSummary, isLoading: advancesSummaryIsLoading } = useAdvancesForAccountingSummaryFilters();

  function getCards() {
    const cards: React.ReactNode[] = [];

    Object.keys(EAdvanceStatus).forEach((currentStatus, key) => {
      if (
        currentStatus != EAdvanceStatus.FINISHED &&
        currentStatus != EAdvanceStatus.PENDING &&
        currentStatus != EAdvanceStatus.REPROVED &&
        currentStatus != EAdvanceStatus.AWAITING_FINANCIAL_PROCESSING &&
        currentStatus != EAdvanceStatus.PROCESSING_PAYMENT &&
        currentStatus != EAdvanceStatus.PROCESSING_PAYMENT_ERROR
      )
        cards.push(
          <CardFilter
            key={key}
            isMain={true}
            isLoading={isLoading || advancesSummaryIsLoading}
            showQuantityItems={false}
            variant={EAdvancesStatusColorVariant[EAdvanceStatus[currentStatus]]}
            totalValue={advancesSummary?.find(advance => advance.status === EAdvanceStatus[currentStatus])?.value}
            isSelected={selectedStatus?.includes(EAdvanceStatus[currentStatus])}
            onSelectedStatusClick={() => onSelectedStatusClick(EAdvanceStatus[currentStatus])}
            title={t(`advancesAccounting.statusCard.label.${currentStatus.toLowerCase()}`)}
          />,
        );
    });

    return cards;
  }

  return <CardFiltersGroup>{getCards()}</CardFiltersGroup>;
};
