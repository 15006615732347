import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { ESizeVariant } from '@containers/Expenses/components/molecules/ReceiptsImages/variants';
import { DownloadButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface DropzoneContainerProps extends SizeProps {
  hasFile: boolean;
}

interface SizeProps {
  size: ESizeVariant;
}

export const Container = styled(BorderedWrapping)`
  width: 100%;
  max-width: 647px;
  padding: ${props => props.theme.spacings.xs};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
`;

export const DropzoneContainer = styled.form<DropzoneContainerProps>`
  width: 100%;
  margin-top: ${props => props.theme.spacings.s};
  max-width: 535px;
  .dropzone-main-container {
    top: 0;
    left: 0;
    max-width: 523px;
    width: 100%;
    display: ${props => (props.hasFile ? 'inherit' : 'flex')};
    flex-flow: wrap-reverse;

    .dropzone {
      width: 100%;
      opacity: ${props => (props.hasFile ? 0 : 1)};
      z-index: 1;

      .dropzone-children-container {
        display: ${props => (props.hasFile ? 'none' : 'inherit')};
      }
    }

    .dropzone-padding {
      padding: ${props => (props.hasFile ? 0 : props.theme.spacings.m)};
    }
  }
`;

export const FileDownload = styled(DownloadButton)`
  width: 315px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: ${props => props.theme.spacings.xs2} ${props => props.theme.spacings.xs};
  border: ${props => props.theme.borders.width.xs2} solid rgba(74, 78, 87, 0.2);
  border-radius: ${props => props.theme.borders.radius.s};
  margin-top: ${props => props.theme.spacings.s};

  svg {
    width: 50px;
  }

  &:hover {
    opacity: 0.75;
  }
`;
