import { useEffect, useState } from 'react';
import { useAdvancesForApproval } from '@containers/Approvals/hooks/useApprovalAdvances';
import { useAdvancesForAccounting } from '@containers/Financial/hooks/dataSources/useAdvancesForAccounting';
import { expensesAccountingBaseURL, expensesAdvancesBaseURL, expensesApprovalsBaseURL } from '@shared/constant';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';

import { useAllAdvances } from './useAllAdvances';

export function useAdvances<T>(filters: T) {
  const currentPath = useCurrentPath();
  const [shouldFetchAdvancesForAccounting, setShouldFetchAdvancesForAccounting] = useState(false);
  const { getAdvances: getAllAdvances, advancesData: allAdvancesData } = useAllAdvances();
  const { getAdvances: getAdvancesForApproval, advancesData: advancesForApprovalData } = useAdvancesForApproval();
  const { advancesData: advancesForAccountingData } = useAdvancesForAccounting({
    ...filters,
    shouldFetch: shouldFetchAdvancesForAccounting,
  });
  const [advancesData, setAdvancesData] = useState({
    advances: [],
    total: 0,
    isLoading: false,
    error: null,
  });

  async function getAdvances() {
    if (currentPath.includes('reports/details') || currentPath.includes(expensesAdvancesBaseURL)) {
      await getAllAdvances({ ...filters });
      return;
    } else if (currentPath.includes(expensesAccountingBaseURL)) {
      setShouldFetchAdvancesForAccounting(true);
      return;
    } else if (currentPath.includes(expensesApprovalsBaseURL)) {
      await getAdvancesForApproval({ ...filters });
      return;
    }

    await getAllAdvances({ ...filters });
  }

  useEffect(() => {
    setAdvancesData(allAdvancesData);
  }, [JSON.stringify(allAdvancesData)]);

  useEffect(() => {
    setAdvancesData(advancesForApprovalData);
  }, [JSON.stringify(advancesForApprovalData)]);

  useEffect(() => {
    setAdvancesData(advancesForAccountingData);
  }, [JSON.stringify(advancesForAccountingData)]);

  useEffect(() => {
    getAdvances();
  }, [JSON.stringify(filters), currentPath]);

  return advancesData;
}
