/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { TSystemFeatures } from '@shared/types/systemFeatures';

export interface IFieldControl {
  active: boolean;
  required: boolean;
  allowsEditing: boolean;
  fieldOrder: number;
  licenseFeature?: TSystemFeatures;
}

export interface IConfigurationExpenseSettings {
  reimbursableExpenses: boolean;
  notifyPendingExpenses: boolean;
  expirationExpenses: boolean;
  manualExpenseEntry: boolean;
  daysExpirationExpenses: number;
  allowLaterDate: boolean;
  allowEarlierDate: boolean;
  formSettings: IFormExpenseSettings;
}

export interface IFormExpenseSettings {
  quantity: IFieldControl;
  category: IFieldControl;
  address: IFieldControl;
  receiptFile: IFieldControl;
  currency: IFieldControl;
  paymentType: IFieldControl;
  value: IFieldControl;
  date: IFieldControl;
  description: IFieldControl;
  invoice: IFieldControl;
  invoiceKey: IFieldControl;
  project: IFieldControl;
  costCenter: IFieldControl;
  client: IFieldControl;
  billable: IFieldControl;
}

export enum ELicenseFeaturesExpenseSettings {
  quantity = 'BASIC_EXPENSE',
  category = 'BASIC_EXPENSE',
  address = 'BASIC_EXPENSE',
  receiptFile = 'BASIC_EXPENSE',
  currency = 'BASIC_EXPENSE',
  paymentType = 'INTERMEDIATE_PAYMENT_METHOD',
  value = 'BASIC_EXPENSE',
  date = 'BASIC_EXPENSE',
  description = 'BASIC_EXPENSE',
  invoice = 'BASIC_EXPENSE',
  invoiceKey = 'BASIC_EXPENSE',
  client = 'INTERMEDIATE_CLIENT',
  project = 'INTERMEDIATE_PROJECT',
  costCenter = 'INTERMEDIATE_COST_CENTER',
  billable = 'BASIC_EXPENSE',
  reimbursableExpenses = 'INTERMEDIATE_EXPENSE',
  notifyPendingExpenses = 'BASIC_EXPENSE',
  expirationExpenses = 'BASIC_EXPENSE',
  manualExpenseEntry = 'BASIC_EXPENSE',
  daysExpirationExpenses = 'BASIC_EXPENSE',
  allowLaterDate = 'BASIC_EXPENSE',
  allowEarlierDate = 'BASIC_EXPENSE',
}
