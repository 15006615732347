import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BorderedWrappingContainer, Description, InfoContainer } from './styled';

export const LinkedReportSkeleton = () => {
  const { t } = useTranslation();

  return (
    <BorderedWrappingContainer>
      <InfoContainer>
        <Skeleton variant="text" width={155} height={35} />
        <Description variant="body4">{t('molecules.linkedReport.report')}</Description>
      </InfoContainer>
    </BorderedWrappingContainer>
  );
};
