import { ReactNode } from 'react';
import { Icons, IconTypes, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';

import * as SC from './styled';

export interface IRowDescriptionProps {
  description: string;
  tooltip?: string;
  caption?: {
    description?: string;
    icon?: IconTypes;
    tooltip?: string;
    tooltipIcon?: IconTypes;
    color?: string;
    custom?: ReactNode;
  };
}

export function TextColumn({ description, tooltip, caption }: IRowDescriptionProps) {
  const { colors } = useTheme();

  function getTooltip(msg: string, color: string, size: number = 16, iconCustom: IconTypes = null) {
    if (!msg) return <></>;

    return (
      <Tooltip arrow title={msg} placement="top-end">
        <div>
          <Icons name={iconCustom || 'IconInfoCircle'} size={size} color={color} fill="transparent" />
        </div>
      </Tooltip>
    );
  }

  return (
    <SC.ContainerInfos>
      <SC.ContainerDescription>
        <SC.Description variant="body3">{description}</SC.Description>
        {getTooltip(tooltip, colors.neutral[40])}
      </SC.ContainerDescription>
      {caption?.custom
        ? caption?.custom
        : caption?.description && (
            <SC.ContainerCaption>
              {caption.icon ? (
                <Icons name={caption.icon} size={16} color={colors.neutral[60]} />
              ) : (
                caption.tooltip && getTooltip(caption.tooltip, colors.neutral[50], 15)
              )}
              <SC.Caption variant="body4" style={{ color: caption.color || colors.neutral[50] }}>
                {caption?.description}
              </SC.Caption>
              {caption.icon && getTooltip(caption.tooltip, colors.neutral[50], 15, caption.tooltipIcon)}
            </SC.ContainerCaption>
          )}
    </SC.ContainerInfos>
  );
}
