import { showToast } from '@shared/helpers/toast';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';
import { t } from 'i18next';

import {
  activateDeactivateCategoryLimitsMutation,
  addCategoryLimitsMutation,
  deleteCategoryLimitByPolicyLimitIdMutation,
  deleteCategoryLimitListMutation,
  getCategoryLimitByPolicyIdQuery,
  updateCategoryLimitsMutation,
} from '../context/queries';
import { ICategoryLimitPolicy } from '../context/types/limits';
import {
  IActivateDeactivateLimit,
  ICreateLimitPolicy,
  IDeleteLimit,
  IDeleteLimitList,
  IUpdateLimitPolicy,
} from '../context/types/policies';

export const useCategoryLimitPolicyMutate = () => {
  const [createLimitMutate, createData] = useMutation<ICreateLimitPolicy>(addCategoryLimitsMutation);
  const [updateLimitMutate, updateData] = useMutation<IUpdateLimitPolicy>(updateCategoryLimitsMutation);
  const [activateDeactivateLimitMutate, activateData] = useMutation<IActivateDeactivateLimit>(
    activateDeactivateCategoryLimitsMutation,
  );
  const [deleteCategoryLimitByPolicyLimitIdMutate, deleteCategoryLimitByPolicyLimitIdData] = useMutation<IDeleteLimit>(
    deleteCategoryLimitByPolicyLimitIdMutation,
  );
  const [deleteCategoryLimitListMutate, deleteCategoryLimitListData] = useMutation<IDeleteLimitList>(
    deleteCategoryLimitListMutation,
  );

  const createLimit = async (limit: ICategoryLimitPolicy, policyId: number) => {
    try {
      await createLimitMutate({
        variables: {
          newCategoryLimitPolicySetting: limit,
        },
        refetchQueries: [
          {
            query: getCategoryLimitByPolicyIdQuery,
            variables: {
              policyId,
            },
          },
        ],
      });
      return Promise.resolve();
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
      return Promise.reject();
    }
  };

  const updateLimit = async (limit: ICategoryLimitPolicy, policyId: number) => {
    try {
      await updateLimitMutate({
        variables: {
          updateLimitPolicySetting: limit,
        },
        refetchQueries: [
          {
            query: getCategoryLimitByPolicyIdQuery,
            variables: {
              policyId,
            },
          },
        ],
      });
      showToast({ message: t('molecules.addLimitToCategoryModal.updateSuccessMessage'), type: 'success' });
      return Promise.resolve();
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
      return Promise.reject();
    }
  };

  const activateDeactivateLimit = async ({
    policyId,
    policyLimitId,
    active,
  }: {
    policyId: number;
    policyLimitId: number[];
    active: boolean;
  }) => {
    try {
      activateDeactivateLimitMutate({
        variables: {
          policyId,
          policyLimitId,
          active,
        },
        refetchQueries: [
          {
            query: getCategoryLimitByPolicyIdQuery,
            variables: {
              policyId,
            },
          },
        ],
      });
      return Promise.resolve();
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
      return Promise.reject();
    }
  };

  const deleteLimit = async (policyLimitIds: number[], policyId: number) => {
    try {
      if (policyLimitIds?.length == 1) {
        await deleteCategoryLimitByPolicyLimitIdMutate({
          variables: {
            policyLimitId: policyLimitIds[0],
          },
          refetchQueries: [
            {
              query: getCategoryLimitByPolicyIdQuery,
              variables: {
                policyId,
              },
            },
          ],
        });
      } else {
        await deleteCategoryLimitListMutate({
          variables: {
            policyLimitIds,
          },
          refetchQueries: [
            {
              query: getCategoryLimitByPolicyIdQuery,
              variables: {
                policyId,
              },
            },
          ],
        });
      }
      return Promise.resolve();
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
      return Promise.reject();
    }
  };

  return {
    isLoading:
      createData.loading ||
      updateData.loading ||
      activateData.loading ||
      deleteCategoryLimitByPolicyLimitIdData.loading ||
      deleteCategoryLimitListData.loading,
    limits: createData ?? [],
    createLimit,
    updateLimit,
    activateDeactivateLimit,
    deleteLimit,
  };
};
