import { gql } from '@apollo/client';

export const getTipsQuery = gql`
  query getTips {
    getTips
  }
`;

export const createTipMutation = gql`
  mutation createTip($tip: Tip!) {
    createTip(tip: $tip)
  }
`;
