import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import {
  EExpenseReportCostCenterValueSettings,
  EReportAllowReimbursementToExpenseOnlySettings,
  IReportAllowReimbursementToExpenseOnlySettings,
} from '@containers/Customizations/context/types/settings/expenseReportSettingsTypes';
import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from '../styled';

const baseKey: string = 'allowReimbursementToContainReimbursementExpenseOnly';

interface IProps {
  expenseOnly: IReportAllowReimbursementToExpenseOnlySettings;
  isLoading: boolean;
  tooltipText: string;
  handleChange(key: string, data: IProps['expenseOnly']): void;
}

export enum EExpenseOnlySettingsLicenseFeatures {
  ALLOW_PROJECT = 'INTERMEDIATE_PROJECT',
  ALLOW_PAYMENT_METHOD = 'BASIC_PAYMENT_METHOD',
  ALLOW_COST_CENTER = 'INTERMEDIATE_COST_CENTER',
  ALLOW_CATEGORY = 'INTERMEDIATE_CATEGORIES',
  ALLOW_BY_EXPENSE = 'INTERMEDIATE_EXPENSE',
}

export function ReportSettingsExpenseOnly({ expenseOnly, isLoading, tooltipText, handleChange }: IProps) {
  const t = useTranslate(`organisms.generalReportSettings.${baseKey}`);

  function _handleChange(key: string, value: unknown) {
    const currentExpenseOnly: IProps['expenseOnly'] = { ...expenseOnly };
    currentExpenseOnly[key] = value;

    if (key === `allow` && !value) {
      currentExpenseOnly.allowCostCenter = false;
      currentExpenseOnly.allowPaymentMethod = false;
      currentExpenseOnly.allowProject = false;
      currentExpenseOnly.allowCategory = false;
      currentExpenseOnly.allowByExpense = false;
    }

    handleChange(baseKey, currentExpenseOnly);
  }

  const options = Object.keys(EExpenseReportCostCenterValueSettings).map(key => ({
    label: t(key?.toLowerCase()),
    value: key,
  }));

  return (
    <SC.Container>
      <ToggleSettings
        toggleKey={`allow`}
        checked={expenseOnly?.allow}
        onChange={_handleChange}
        label={t(`allow`)}
        isLoading={isLoading}
        tooltipText={tooltipText}
      />
      {expenseOnly?.allow && (
        <>
          {Object.keys(EReportAllowReimbursementToExpenseOnlySettings).map(key => (
            <PermissionsChecker key={key} licenseFeature={EExpenseOnlySettingsLicenseFeatures[key]}>
              <SC.Content>
                <ToggleSettings
                  toggleKey={EReportAllowReimbursementToExpenseOnlySettings[key]}
                  checked={expenseOnly[EReportAllowReimbursementToExpenseOnlySettings[key]]}
                  onChange={_handleChange}
                  label={t(EReportAllowReimbursementToExpenseOnlySettings[key])}
                  isLoading={isLoading}
                  tooltipText={key === 'ALLOW_COST_CENTER' && t('costCenterTooltip')}
                />
                {key === 'ALLOW_COST_CENTER' && expenseOnly.allowCostCenter && (
                  <SC.ExpenseOnlySettingsContentContainer>
                    <SelectField
                      options={options}
                      label={t('costCenterSelectLabel')}
                      onSelectChange={(_, data) => _handleChange('costCenterValue', data.value)}
                      value={expenseOnly.costCenterValue}
                    />
                  </SC.ExpenseOnlySettingsContentContainer>
                )}
              </SC.Content>
            </PermissionsChecker>
          ))}
        </>
      )}
    </SC.Container>
  );
}
