import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const InfoMandatoryDescription = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
`;

export const InfoMandatoryDescriptionAlert = styled(Typography)`
  color: ${props => props.theme.colors.feedback.error[40]};
`;

export const MandatoryInfoContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs5};
`;
