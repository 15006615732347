import { ReactNode } from 'react';
import { useValueFormatter } from '@shared/hooks';

import * as SC from './styled';

export type SubscriptionInfoItem = {
  included: boolean;
  name: string;
  value?: number;
};

type Props = {
  title: string;
  description?: string;
  items?: SubscriptionInfoItem[];
  footer?: ReactNode;
  price?: {
    value: number;
    caption?: string;
    highligted?: boolean;
  };
};

export function SubscriptionInformation({ description, items, title, footer, price }: Props) {
  const { getValueWithCurrency } = useValueFormatter();

  return (
    <SC.Container>
      <SC.Body>
        <SC.InfoContainer>
          <SC.Title>{title}</SC.Title>

          {description && <SC.Description>{description}</SC.Description>}
        </SC.InfoContainer>

        {price && (
          <SC.ValueContainer>
            <SC.ValueText variant="body2" highlighted={price.highligted || false}>
              {getValueWithCurrency({
                value: price.value,
                currencyPrefix: 'R$',
              })}
            </SC.ValueText>
            <SC.ValueCaption>{price.caption}</SC.ValueCaption>
          </SC.ValueContainer>
        )}
      </SC.Body>

      {!!items?.length && (
        <SC.ItemsWrapper>
          {items?.map(({ included, name, value }) => (
            <SC.ItemContainer key={name}>
              {included ? <SC.IncludedIcon /> : <SC.NotIncludedIcon />}
              <SC.ItemText>{name}</SC.ItemText>

              {value && (
                <>
                  <SC.ValueSeparator />
                  <SC.ItemText>{getValueWithCurrency({ value })}</SC.ItemText>
                </>
              )}
            </SC.ItemContainer>
          ))}
        </SC.ItemsWrapper>
      )}

      {footer && <SC.FooterText>{footer}</SC.FooterText>}
    </SC.Container>
  );
}
