import React from 'react';
import { useTranslation } from '@locale/Translator';

import * as SC from '../styled';

export enum EAccessor {
  DATE = 'date',
  USER = 'user',
  ACTIONS = 'actions',
}

export interface HeaderGridColumn {
  Header: React.ReactNode;
  accessor: string | number;
  disableSortBy?: boolean;
}

export const useHeaderGridColumns = (): HeaderGridColumn[] => {
  const { t } = useTranslation();

  return Object.values(EAccessor).map(accessor => ({
    Header: <SC.DescriptionHeaderColumn>{t(`report.reportHistoryGrid.${accessor}`)}</SC.DescriptionHeaderColumn>,
    accessor,
    disableSortBy: true,
  }));
};
