import { useCallback } from 'react';
import { getSummaryReports } from '@containers/Expenses/context/queries';
import { showToast } from '@shared/helpers/toast';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';
import { useTracking } from '@shared/hooks/useTracking';
import { useTranslation } from 'react-i18next';

import { createReportMutation, deleteReportMutation, getReportsQuery, updateReportMutation } from '../context/queries';
import {
  ICreateReportMutation,
  ICreateReportMutationResponse,
  IDeleteReportMutation,
  IDeleteReportMutationResponse,
  IUpdateReportMutation,
  IUpdateReportMutationResponse,
} from '../context/types';
import { serializeCreateReport } from '../utils/reportSerializers';

interface IReportMutationProps {
  name: string;
  description: string;
  approver?: number;
}

export const useReportMutate = () => {
  const trackingEvent = useTracking();

  const refetchQueries = useCallback(() => {
    return [{ query: getReportsQuery }, { query: getSummaryReports }, 'GetReports'];
  }, []);

  const [createReportMutate, { data: createData, loading: createDataLoading }] = useMutation<
    ICreateReportMutationResponse,
    ICreateReportMutation
  >(createReportMutation, {
    refetchQueries,
  });

  const [updateReportById, { data: updateData, loading: updateDataLoading }] = useMutation<
    IUpdateReportMutationResponse,
    IUpdateReportMutation
  >(updateReportMutation, {
    refetchQueries,
  });

  const [deleteReportMutate, deleteData] = useMutation<IDeleteReportMutationResponse, IDeleteReportMutation>(
    deleteReportMutation,
    {
      refetchQueries,
    },
  );
  const { t } = useTranslation();

  const createReport = async report => {
    try {
      const response = await createReportMutate({
        variables: {
          report: serializeCreateReport(report),
        },
      });

      trackingEvent('report_create_success', { reportId: String(response.data.createReport.id) });

      showToast({ message: t('molecules.reportToast.createSuccessMessage'), type: 'success' });

      return Promise.resolve(response.data.createReport);
    } catch (error) {
      showToast({ message: error.message, type: 'error' });

      trackingEvent('report_create_error');
      return Promise.reject();
    }
  };

  const updateReport = async (reportId: number, report: IReportMutationProps) => {
    try {
      const response = await updateReportById({
        variables: {
          reportId,
          report,
        },
      });

      trackingEvent('report_update_success', { reportId: String(reportId) });

      showToast({ message: t('molecules.reportToast.updateSuccessMessage'), type: 'success' });

      return Promise.resolve(response.data.updateReportById);
    } catch (error) {
      showToast({ message: error.message, type: 'error' });

      trackingEvent('report_update_error', { reportId: String(reportId) });

      return Promise.reject();
    }
  };

  const deleteReport = async reportId => {
    try {
      await deleteReportMutate({
        variables: {
          reportId,
        },
        refetchQueries: [getReportsQuery],
      });
      showToast({ message: t('molecules.reportToast.deleteSuccessMessage'), type: 'success' });
      return Promise.resolve();
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
      return Promise.reject();
    }
  };

  return {
    isLoading: createDataLoading || updateDataLoading,
    report: createData?.createReport || updateData?.updateReportById || deleteData.data?.deleteReportById,
    createReport,
    updateReport,
    deleteReport,
  };
};
