import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  overflow-x: hidden;
  padding-bottom: 2px;
`;

export const HeaderBody = styled.div`
  display: grid;
  gap: 30px;
  min-width: 80vw;
  padding: 25px 24px;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Title = styled(Typography)`
  &.typography--variant-body3 {
    font-weight: 700;
  }
  align-items: center;
  color: ${props => props.theme.colors.primary};
`;

export const ProgressBar = styled(LinearProgress)`
  background: ${props => props.theme.colors.neutral.light.light2};
  height: 2px;
  overflow: visible;

  .MuiLinearProgress-bar1Determinate {
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
    height: 4px;
  }
`;
