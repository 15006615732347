import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getAllExpenses } from '../context/queries';
import { IExpenses, IExpensesFilters, IGetExpensesResponse, IPaginationSettings } from '../context/types/expenses';

interface UseExpenses {
  expenses: IExpenses[];
  expensesIsLoading: boolean;
  currentPage: number;
  expensesTotalItems: number;
  expensesError: unknown;
}
export interface IUseExpensesProps {
  limit?: number;
  page?: number;
  filters: IExpensesFilters;
  enabled?: boolean;
}

export interface IGetExpensesRequest extends IPaginationSettings, IExpensesFilters {}

export const useExpenses = ({ limit = 20, page = 1, filters, enabled }: IUseExpensesProps): UseExpenses => {
  const {
    loading,
    data,
    error: expensesError,
    networkStatus,
  } = useQuery<IGetExpensesResponse, IGetExpensesRequest>(getAllExpenses, {
    variables: {
      limit,
      page,
      reportId: filters?.reportId,
      onlyWithViolations: filters?.onlyWithViolations,
      onlyUnattached: filters?.onlyUnattached,
      hasPending: filters?.hasPending,
      orderBy: filters?.orderBy,
      order: filters?.order,
    },
    skip: enabled === false,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  return {
    expenses: data?.getExpenses?.expenses || [],
    expensesIsLoading: loading || networkStatus === NetworkStatus.loading,
    currentPage: data?.getExpenses?.currentPage,
    expensesTotalItems: data?.getExpenses?.total || 0,
    expensesError,
  };
};
