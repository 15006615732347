import React from 'react';
import { TUserPendingExpense } from '@containers/Financial/context/types/users';
import { LinkButton, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';
import { TUserExpenses } from './types';

interface IPaginationComponentProps {
  user: TUserPendingExpense;
  currencyUserExpenses: TUserExpenses;
  isSubRowLoading: boolean;
  setCurrencyUserExpenses: (expenses: TUserExpenses) => void;
  getStoreUserExpenses: (userId: number) => { limitPerPage: number; totalItems?: number };
}

const PaginationComponent: React.FC<IPaginationComponentProps> = ({
  user,
  currencyUserExpenses,
  isSubRowLoading,
  setCurrencyUserExpenses,
  getStoreUserExpenses,
}) => {
  const t = useTranslate('organisms.accountingPending.expensesTable');
  const currentExpenses = getStoreUserExpenses(user.id);
  const isCurrentUser = user.id === currencyUserExpenses?.userId;
  const isLoading = isCurrentUser && isSubRowLoading;
  const itemsPerPage =
    currentExpenses.limitPerPage > currentExpenses.totalItems
      ? currentExpenses.totalItems
      : currentExpenses.limitPerPage;
  const showMoreButton = currentExpenses && currentExpenses.limitPerPage <= currentExpenses?.totalItems;

  return (
    <SC.ContentPagination>
      {isLoading ? <Spinner size={24} variant="primary" /> : itemsPerPage} {t('subRows.paginationOf')}{' '}
      {currentExpenses.totalItems}
      {showMoreButton && (
        <LinkButton
          variant="default"
          disabled={isLoading}
          onClick={() => {
            setCurrencyUserExpenses({
              ...currencyUserExpenses,
              userId: user.id,
              limitPerPage: currentExpenses.limitPerPage + 10,
              shouldFetch: true,
            });
          }}>
          {t('subRows.loadMore')}
        </LinkButton>
      )}
    </SC.ContentPagination>
  );
};

export default PaginationComponent;
