import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CheckboxListOptions } from '@atoms/CheckboxList';
import { FilterType } from '@atoms/FilterFactory';
import { IAnalyticsPagination } from '@containers/Analytics/components/organisms/AnalyticsSections/AnalyticsSection';
import {
  AnalyticsFilterField,
  EAnalyticsFilterFieldKey,
  EAnalyticsFilterLabel,
  EAnalyticsType,
  IAnalyticsFilterFieldInput,
  IAnalyticsFilterFieldOption,
  IAnalyticsFilterFieldOptionsResponse,
  IAnalyticsFilterFieldType,
  IAnalyticsFilterValue,
} from '@containers/Analytics/context/types/filters';
import { useFilters } from '@containers/Analytics/hooks';
import { useCategoriesOptionsFilters } from '@containers/Analytics/hooks/useCategoriesOptionsFilters';
import { useTranslation } from '@locale/Translator';
import { IDrawerFilter, IFilterValues } from '@molecules/FiltersDrawer';
import { useDateFormatter } from '@shared/hooks';
import { cloneDeep } from 'lodash';

import { useFilterAnalyticsFieldsLicenseFeatures } from './useFilterAnalyticsFieldsLicenseFeatures';

interface IUseAnalyticsFiltersManager {
  filters: IDrawerFilter[];
  filtersSelected: IAnalyticsFilterFieldInput[];
  filtersIsLoading: boolean;
  applyAllFilters(_filters: IFilterValues[]): void;
}

export function useAnalyticsFiltersManager(
  type: EAnalyticsType,
  pagination: IAnalyticsPagination,
  setPagination: Dispatch<SetStateAction<IAnalyticsPagination>>,
): IUseAnalyticsFiltersManager {
  const { t } = useTranslation();
  const { getAllCategories, serializeCategoriesOptions } = useCategoriesOptionsFilters();
  const [filters, setFilters] = useState<IDrawerFilter[]>([]);
  const [filtersSelected, setFiltersSelected] = useState<IAnalyticsFilterFieldInput[]>(getInitialValues());
  const [currentFieldsOptions, setCurrentFieldsOptions] = useState<AnalyticsFilterField[]>();
  const { filterFields, filterFieldOptions } = useFilters(type, currentFieldsOptions);
  const [filtersIsLoading, setFiltersIsLoading] = useState(false);
  const { getFieldsApplyingLicenseFeatures } = useFilterAnalyticsFieldsLicenseFeatures();
  const { getFormatDateUTC } = useDateFormatter();

  function getInitialDateValue(fieldKey: EAnalyticsFilterFieldKey, subtractDays: number = 30) {
    const currentDate = new Date();
    return {
      key: fieldKey,
      value: {
        startDate: String(new Date(new Date().setDate(currentDate.getDate() - subtractDays)).toISOString()),
        endDate: String(currentDate.toISOString()),
      },
    };
  }

  function getInitialValues() {
    const initialFilters: IAnalyticsFilterFieldInput[] = [];

    switch (type) {
      case EAnalyticsType.EXPENSES_OVERVIEW:
      case EAnalyticsType.EXPENSES_BY_CATEGORY:
      case EAnalyticsType.EXPENSES_BY_USER:
      case EAnalyticsType.CORPORATE_CARD_BY_USER:
      case EAnalyticsType.CORPORATE_CARD_OVERVIEW:
        initialFilters.push(getInitialDateValue(EAnalyticsFilterFieldKey.EXPENSE_DATE));
        break;
      case EAnalyticsType.REPORT_OVERVIEW:
      case EAnalyticsType.REPORT_PENDING_APPROVALS:
      case EAnalyticsType.REPORT_STATUS_BY_USER:
        initialFilters.push(getInitialDateValue(EAnalyticsFilterFieldKey.REPORT_CREATION_DATE));
        break;
      case EAnalyticsType.ADVANCES_OVERVIEW:
      case EAnalyticsType.ADVANCES_STATUS_BY_USER:
        initialFilters.push(getInitialDateValue(EAnalyticsFilterFieldKey.ADVANCE_CREATION_DATE));
        break;
      default:
        return initialFilters;
    }

    return initialFilters;
  }

  async function getCategoriesOptions() {
    const _allCategories = await getAllCategories();
    return serializeCategoriesOptions(_allCategories?.data?.getAllLegacyCategories?.categories);
  }

  async function getAnalyticsFilters() {
    const _filters = await getFilterFields();
    setFilters(_filters);
  }

  function serializeFieldsOptions(fields: AnalyticsFilterField[]) {
    const _fields: AnalyticsFilterField[] = [];

    fields?.map(_field => {
      if (_field?.type === IAnalyticsFilterFieldType.MULTIPLE || _field?.type === IAnalyticsFilterFieldType.LIST) {
        _fields.push(_field);
      }
    });

    return _fields;
  }

  async function getFilterFields() {
    setFiltersIsLoading(true);
    const currentCategories = await getCategoriesOptions();
    const responseFilterFields = await filterFields.getFilterFields({
      variables: { type },
    });
    const currentFilterFields = responseFilterFields?.data?.getAnalyticsFilterFields;

    let fieldsOptions = getFieldsApplyingLicenseFeatures(currentFilterFields);
    fieldsOptions = serializeFieldsOptions(fieldsOptions);
    let currentFiltersOptions = { data: {} } as IAnalyticsFilterFieldOptionsResponse;
    if (fieldsOptions?.length) {
      setCurrentFieldsOptions(fieldsOptions);
      currentFiltersOptions = (await filterFieldOptions.getFilterFieldOptions())?.data;
    }

    return getStandardizeResponse(currentFiltersOptions, currentFilterFields, currentCategories);
  }

  function applyFilter(value: IFilterValues['value'], type: IAnalyticsFilterFieldType, key: EAnalyticsFilterFieldKey) {
    setPagination({ ...pagination, page: 1 });
    setFiltersSelected(filtersSelected => {
      let _filtersSelected: IAnalyticsFilterFieldInput[] = cloneDeep(filtersSelected);
      const currentFilter: IAnalyticsFilterFieldInput = getFillInByType(type, value, key);

      _filtersSelected = getIncludeCurrentFilterValue(key, _filtersSelected || [], currentFilter);
      return _filtersSelected ?? [];
    });
  }

  function applyAllFilters(_filtersValues: IFilterValues[]) {
    const _selectedFilters: IAnalyticsFilterFieldInput[] = [];

    _filtersValues.forEach(_filterItem => {
      const currentDataFilter = filters?.find(filterItem => filterItem.key === _filterItem.key);
      const currentFilterType: IAnalyticsFilterFieldType =
        IAnalyticsFilterFieldType[currentDataFilter?.type?.toUpperCase()];

      const currentFilter =
        currentFilterType &&
        getFillInByType(
          IAnalyticsFilterFieldType[currentDataFilter?.type?.toUpperCase()],
          _filterItem.value,
          EAnalyticsFilterFieldKey[_filterItem.key],
        );

      if (currentFilter && currentFilterType) {
        _selectedFilters.push(currentFilter);
      }
    });

    setFiltersSelected(_selectedFilters?.filter(filter => filter?.value));
  }

  function getFillInByType(
    type: IAnalyticsFilterFieldType,
    value: IFilterValues['value'],
    key: EAnalyticsFilterFieldKey,
  ): IAnalyticsFilterFieldInput {
    const currentFilter: IAnalyticsFilterFieldInput = { key, value: null };

    switch (type) {
      case IAnalyticsFilterFieldType.MULTIPLE:
      case IAnalyticsFilterFieldType.LIST:
        currentFilter.value = value?.length ? { options: value as IAnalyticsFilterValue['options'] } : null;
        break;

      case IAnalyticsFilterFieldType.SEARCH:
        currentFilter.value = { text: value?.length > 3 ? (value as IAnalyticsFilterValue['text']) : '' };
        break;

      case IAnalyticsFilterFieldType.BOOLEAN:
        currentFilter.value = {
          bool: Boolean(value?.length) || (Array.isArray(value) && value?.includes('on')) || value === true,
        };
        break;

      case IAnalyticsFilterFieldType.PERIOD:
        currentFilter.value =
          value?.startDate || value?.from
            ? {
                startDate: (value?.from && getFormatDateUTC(value?.from)) || getFormatDateUTC(value?.startDate),
                endDate: (value?.to && getFormatDateUTC(value?.to)) || getFormatDateUTC(value?.endDate),
              }
            : null;
        break;

      case IAnalyticsFilterFieldType.RANGE:
      case IAnalyticsFilterFieldType.CURRENCY_RANGE:
        currentFilter.value = value?.length
          ? {
              min: value[0],
              max: value[1],
            }
          : null;
        break;
    }

    return currentFilter;
  }

  function getIncludeCurrentFilterValue(
    key: EAnalyticsFilterFieldKey,
    _filtersSelected: IAnalyticsFilterFieldInput[] = [],
    currentFilter: IAnalyticsFilterFieldInput,
  ) {
    const index: number = _filtersSelected?.findIndex(_filter => _filter.key === key);

    if (index !== -1) {
      if (currentFilter.value) {
        _filtersSelected[index].value = currentFilter.value;
      } else {
        _filtersSelected.splice(index, 1);
      }
    } else {
      currentFilter.value && _filtersSelected.push(currentFilter);
    }

    return _filtersSelected;
  }

  function getStandardizeResponse(
    _options: IAnalyticsFilterFieldOptionsResponse,
    _fields: AnalyticsFilterField[],
    _categories: IAnalyticsFilterFieldOption[],
  ): IDrawerFilter[] {
    const _filters: IDrawerFilter[] = _fields?.map(_field => {
      const currentOptions =
        _field?.key === EAnalyticsFilterFieldKey.EXPENSE_CATEGORY ? _categories : _options?.[_field?.key] || null;

      const _filtersItems: IDrawerFilter = {
        label: t(`analytics.filters.label.${EAnalyticsFilterLabel[_field?.key]}`),
        type: FilterType[_field?.type],
        key: _field?.key,
        onChange: data => {
          applyFilter(data, _field?.type, _field?.key);
        },
        onClick: data => {
          applyFilter(data, _field?.type, _field?.key);
        },
      };

      if (currentOptions) {
        _filtersItems.options = serializeOptions(currentOptions);
      }
      if (_field.type === IAnalyticsFilterFieldType.BOOLEAN) {
        _filtersItems.options = serializeOptions([
          { label: t(`analytics.filters.label.${EAnalyticsFilterLabel[_field?.key]}`), id: 'on' },
        ]);
      }

      return _filtersItems;
    });

    setFiltersIsLoading(false);

    return _filters;
  }

  function serializeOptions(_currentOptions: IAnalyticsFilterFieldOption[]): CheckboxListOptions[] {
    return _currentOptions?.map(_option => ({
      label: _option.label,
      value: _option.id,
    }));
  }

  useEffect(() => {
    getAnalyticsFilters();
  }, [type]);

  return {
    filters,
    filtersSelected,
    filtersIsLoading: filtersIsLoading,
    applyAllFilters,
  };
}
