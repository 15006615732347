import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getExpenseFields } from '../context/queries';
import { IExpenseFields, IGetExpenseFormFieldsResponse } from '../context/types';

interface UseExpenseFormFields {
  fields: IExpenseFields;
  isLoading: boolean;
  error: unknown;
}

export const useExpenseFormFields = (): UseExpenseFormFields => {
  const { loading, data, error } = useQuery<IGetExpenseFormFieldsResponse>(getExpenseFields, {
    fetchPolicy: 'no-cache',
  });

  return {
    fields: data?.getExpenseFormFields || ({} as IExpenseFields),
    isLoading: loading,
    error,
  };
};
