import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { StatusTag } from '@atoms/StatusTag';
import { useTranslation } from '@locale/Translator';
import { ActionsFeedback } from '@molecules/ActionsFeedback';

import * as SC from './styled';

export const ReportCardEmpty = () => {
  const { t } = useTranslation();

  return (
    <BorderedWrapping>
      <SC.ReportCardContainer $isEmpty={true}>
        <SC.ReportCardHeader>
          <ShapeIcon icon="IconFolder" variant="neutral" size="medium" />
          <ActionsFeedback showAttachments={true} showMessages={true} />
        </SC.ReportCardHeader>
        <SC.ReportCardBody>
          <SC.ReportCodeLabelContainer>
            <SC.ReportCodeLabel variant="body3">ID</SC.ReportCodeLabel>
          </SC.ReportCodeLabelContainer>
          <SC.ReportTitleLabel variant="headline8">{t('report.reportName')}</SC.ReportTitleLabel>
        </SC.ReportCardBody>
        <SC.ReportCardFooter>
          <StatusTag variant="neutral">Status</StatusTag>
        </SC.ReportCardFooter>
      </SC.ReportCardContainer>
    </BorderedWrapping>
  );
};
