import React from 'react';

import { Container } from './styled';

export interface BorderedWrappingProps {
  /**
   * Sets the border thickness
   */
  borderWidth?: number | string;

  /**
   * Set the edge radius
   */
  borderRadius?: number | string;

  /**
   * Set the border color
   */
  color?: string;

  /**
   * Content to be displayed
   */

  children: React.ReactNode;

  /**
   * Allow other props such as className
   */

  className?: string;
}

export const BorderedWrapping = ({ borderWidth, borderRadius, color, children, ...props }: BorderedWrappingProps) => {
  return (
    <Container borderWidth={borderWidth} borderRadius={borderRadius} color={color} {...props}>
      {children}
    </Container>
  );
};
