import { useState } from 'react';
import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { PaymentMethodModal } from '../PaymentMethodModal/PaymentMethodModal';
import * as SC from './styled';

export function AddPaymentMethod() {
  const t = useTranslate('organisms.customizationsPaymentMethods.addPaymentMethod');
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <SC.Container>
      <Button variant="primary" size="medium" onClick={() => setShowModal(_show => !_show)}>
        <SC.ButtonContent>
          <div>{t('buttonAdd')}</div>
          <SC.IconPlus name="IconPlus" fill="transparent" size={20} />
        </SC.ButtonContent>
      </Button>
      {showModal && <PaymentMethodModal open={showModal} onCloseModal={() => setShowModal(_show => !_show)} />}
    </SC.Container>
  );
}
