import { ApolloError } from '@apollo/client';
import { showToast } from '@shared/helpers/toast';

export function getErrorToast(error: ApolloError) {
  if (error?.message) {
    showToast({
      message: error?.message,
      type: 'error',
    });
  }
}
