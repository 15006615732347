import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { SectionContainer } from '../../../../../../components/organisms/SectionContainer/SectionContainer';
import { Container } from '../styled';
import { AddCustomField } from './AddCustomField/AddCustomField';
import { CustomFieldsGrid } from './CustomFieldsGrid/CustomFieldsGrid';

export function CustomFields() {
  const t = useTranslate('organisms.customizationsCustomFields');

  return (
    <SectionContainer title={t('title')} caption={t('caption')}>
      <Container>
        <AddCustomField />
        <CustomFieldsGrid />
      </Container>
    </SectionContainer>
  );
}
