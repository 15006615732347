import { TUserPendingExpense } from '@containers/Financial/context/types/users';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import qs from 'query-string';

type TDetailedExpense = {
  expenseId: number;
  categoryName: string;
  establishmentCompanyName: string;
  convertedAmount: number;
  paymentMethod: string | null;
  receipt: string | null;
  reportId: number;
  reportReferenceCode: string | null;
  reportName: string | null;
};

type TFetchResponse = {
  expenses: TDetailedExpense[];
  currentPage: number;
  pages: number;
  limit: number;
  total: number;
};

interface IFilterExpenseDetailedByEmployee {
  search?: string;
  userId?: TUserPendingExpense['id'];
  page?: number;
  pageSize?: number;
}

interface IExpenseDetailedByEmployee {
  shouldFetch?: boolean;
  filters?: IFilterExpenseDetailedByEmployee;
}

export function useExpensePendingByUser({ filters, shouldFetch = false }: IExpenseDetailedByEmployee) {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });
  const params = qs.stringify(filters, { arrayFormat: 'bracket' });
  const { data, error, isLoading, mutate } = useFetch<TFetchResponse>(
    filters.userId ? `v1/analytics/expense/user/expense-detailed?${params}` : null,
    undefined,
    undefined,
    undefined,
    onError,
    shouldFetch,
  );

  return {
    response: {
      expenses: data?.expenses || [],
      currentPage: data?.currentPage,
      pages: data?.pages,
      limit: data?.limit,
      total: data?.total,
    },
    isLoading: isLoading,
    error,
    refetch: () => mutate(),
  };
}
