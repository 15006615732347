import { ICostCenter } from '@containers/CostCenters/context/types/costCenters';

import { IProjectsSummary } from '../../../Projects/context/types/projects';
import { IClientsSummary } from './clients';
import { IEstablishment } from './establishments';
import { ReceiptFile } from './expenseForm';
import { EExpenseStatus, EExpenseType, ICategory, IExpenseCard } from './expenses';
import { IFileResponse } from './files';
import { IPaymentMethodsSummary } from './paymentMethods';

export interface IExpense {
  id: number;
  referenceId: string;
  type: EExpenseType;
  refundable: boolean;
  billable: boolean;
  description: string;
  value: number;
  valueInCompanyCurrency: IConvertedCurrency;
  currencyPrefix: string;
  currencyCode: string;
  quantity: number;
  status: EExpenseStatus;
  category: ICategory;
  establishment: IEstablishment;
  costCenter: ICostCenter;
  cardId: number;
  paymentMethod: IPaymentMethodsSummary;
  comments: IExpenseComment[];
  attachments: IFileResponse[];
  attachmentsNumber: number;
  report: IReportExpense;
  route: IRoute;
  invoiceKey: string;
  invoice: string;
  customFields: IExpenseCustomField[];
  client: IClientsSummary;
  project: IProjectsSummary;
  expenseDate: string;
  createdAt: string;
  card?: IExpenseCard;
  odometer?: IOdometer;
  ocrExpenseStatus?: EOcrStatus;
  violationNumber: number;
}

export enum EOcrStatus {
  IGNORE = 0,
  WAITING = 1,
  PROCESSING = 2,
  COMPLETE = 3,
  PARTIAL = 4,
  FAIL = 5,
}

export interface IOdometer {
  start: IOdometerValue;
  end: IOdometerValue;
  ratePerKm: number;
  distance: number;
}

export interface IOdometerValue {
  value: number;
  attachment: ReceiptFile;
}

export interface IConvertedCurrency {
  rate: number;
  value: number;
}

export interface IExpenseComment {
  id: number;
  message: string;
  user: IExpenseUser;
  createdAt: string;
}

export interface IExpenseUser {
  id: number;
  name: string;
  cpf: string;
  email: string;
}

export interface IReportExpense {
  id: number;
  name: string;
  description: string;
}

export interface IRoute {
  origin?: string;
  destination?: string;
  distance?: number;
  ratePerKm?: number;
  waypoints: IEstablishment[];
  waypointsNumber?: number;
  overviewPolyline?: string;
  imagePath?: string;
  previewImage?: ReceiptFile[];
}

export interface IWaypoint {
  place: string;
  address: string;
  lat: number;
  lng: number;
}

export interface IExpenseCustomField {
  id: string;
  value: string;
}

export interface IGetExpenseByIdResponse {
  getExpenseById: IExpense;
}
