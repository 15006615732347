import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacings.xs1};
`;

export const GridFiltersContainer = styled.div`
  margin: ${({ theme }) => theme.spacings.s} 0;
`;

export const GridContainer = styled.div`
  width: 100%;
`;

export const ModalContainer = styled.div`
  text-align: center;
`;

export const TitleModalConfirmContainer = styled.div`
  width: 420px;
`;

export const SubtitleModalConfirmContainer = styled.div`
  width: 420px;
`;
