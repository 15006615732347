import { useTranslation } from '@locale/Translator';
import { PageHeader } from '@organisms/BasePage/PageHeader/PageHeader';
import { BulkImportDetails } from '@organisms/BulkImportDetails';
import { ClientRegisterFileHeader } from '@pages/BulkImportClients/ClientRegisterFileHeader';
import { CostCenterFooterPage } from '@pages/BulkImportCostCenters/CostCenterFooterPage/CostCenterFooterPage';
import { costCentersBaseURL } from '@shared/constant';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

export const BulkImportCostCentersDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm();

  return (
    <>
      <ClientRegisterFileHeader />
      <FormProvider {...methods}>
        <SC.Container>
          <PageHeader title={t('organisms.BulkImportDetails.header.costCenters')} />
          <BulkImportDetails
            newImportAction={() => navigate(`${costCentersBaseURL}/register/file`)}
            page="costCenters"
          />
        </SC.Container>
        <CostCenterFooterPage action={() => navigate(costCentersBaseURL)} />
      </FormProvider>
    </>
  );
};
