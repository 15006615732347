import styled from 'styled-components';

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const LinkContent = styled.div`
  white-space: nowrap;
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs5};
  grid-template-columns: min-content min-content;
`;
