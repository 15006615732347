import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { BulkImportClients } from '@pages/BulkImportClients';
import { BulkImportClientsDetails } from '@pages/BulkImportClientsDetails';
import { ClientRegister } from '@pages/ClientRegister';
import { Clients } from '@pages/Clients';
import { ImportingContextProvider } from '@shared/contexts/BulkImportContext';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function ClientsRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['INTERMEDIATE_CLIENT']}
          routePermissions={[EAuthZPermission.CONFIGURATION_CLIENTS]}
        />
      ),
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Clientes" moduleName="Clientes" />,
      children: [
        {
          path: Router.getClientsRegister(),
          element: <ClientRegister />,
        },
        {
          path: Router.getClientsRegister(':id'),
          element: <ClientRegister />,
        },
        {
          element: <ImportingContextProvider />,
          children: [
            {
              path: Router.getClientsRegisterFile(),
              element: <BulkImportClients />,
            },
            {
              path: Router.getClients(),
              element: <Clients />,
            },
            {
              path: Router.getClientsRegisterFileDetails(),
              element: <BulkImportClientsDetails />,
            },
          ],
        },
      ],
    },
  ];
}
