import { useState } from 'react';
import emptyImage from '@assets/images/empty_state.png';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { IExpenses } from '@containers/Expenses/context/types/expenses';
import { useAttachExpenseToReport } from '@containers/Reports/hooks/useAttachExpenseToReport';
import { Checkbox, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useTracking } from '@shared/hooks/useTracking';
import { EAccessScreen, TAccessScreen } from '@shared/types';
import { useTranslation } from 'react-i18next';

import { AttachExpenseModalSkeleton } from './AttachExpenseModalSkeleton';
import * as SC from './styled';

interface AttachExpenseModalProps {
  isLoading: boolean;
  expenses: IExpenses[];
  onClose(): void;
  isOpen: boolean;
  reportId: number;
  accessScreen?: TAccessScreen;
}

export const AttachExpenseModal = ({ isLoading, expenses, onClose, isOpen, reportId }: AttachExpenseModalProps) => {
  const { t } = useTranslation();
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const { attachExpenseToReport, loading } = useAttachExpenseToReport();
  const { getValueWithCurrency } = useValueFormatter();
  const trackingEvent = useTracking();

  const onSelectExpenseClick = (itemId: number) => {
    const isIdSelected = selectedExpenses.includes(itemId);

    if (isIdSelected) {
      setSelectedExpenses(selectedExpenses.filter(id => id !== itemId));
    } else {
      setSelectedExpenses([...selectedExpenses, itemId]);
    }
  };

  const handleAttachExpenseToReport = async () => {
    trackingEvent('expense_attach_clicked', {
      reportId: String(reportId),
      expenses: selectedExpenses.toString(),
    });

    await attachExpenseToReport({ reportId, expenseIds: selectedExpenses, accessScreen: EAccessScreen.REPORT_DETAILS });
    onClose();
  };

  return (
    <DefaultModal
      open={isOpen}
      isLoading={loading}
      onCloseClick={onClose}
      onCancelClick={onClose}
      onActionClick={handleAttachExpenseToReport}
      headerTitle={t('organisms.attachExpenseModal.title')}
      headerSubtitle={t('organisms.attachExpenseModal.subtitle')}
      labelButtonAction={
        <>
          {t('organisms.attachExpenseModal.add')} <SC.PlusIcon />
        </>
      }>
      {isLoading ? (
        <AttachExpenseModalSkeleton />
      ) : (
        <SC.Container>
          <SC.ExpensesContainer>
            {expenses?.length > 0 ? (
              expenses.map((expense, index) => {
                return (
                  <SC.ExpenseItem key={index}>
                    <Checkbox
                      onChange={() => onSelectExpenseClick(expense.id)}
                      checked={selectedExpenses.find(id => id == expense.id)}
                    />
                    <SC.ExpenseNameContainer>
                      <ShapeIcon icon="IconReceipt" size="small" variant="secondary" />
                      <Typography variant="body3">{expense.category?.description || '-'}</Typography>
                    </SC.ExpenseNameContainer>
                    <Typography variant="body3">
                      {getValueWithCurrency({ value: expense.value, currencyPrefix: expense.currencyPrefix })}
                    </Typography>
                    <Typography variant="body3">{expense.establishment?.place || '-'}</Typography>
                  </SC.ExpenseItem>
                );
              })
            ) : (
              <SC.EmptyContainer>
                <SC.Image src={emptyImage} />
                <SC.DescriptionEmpty variant="body3">
                  {t('organisms.attachExpenseModal.emptyExpenses')}
                </SC.DescriptionEmpty>
              </SC.EmptyContainer>
            )}
          </SC.ExpensesContainer>
        </SC.Container>
      )}
    </DefaultModal>
  );
};
