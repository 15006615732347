import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import { IConfigurationAdvancesSetting } from '@containers/Customizations/context/types/advances';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

interface IProps {
  advancesSettings: IConfigurationAdvancesSetting;
  isLoading: boolean;
  handleChange(settingKey: string, value: unknown): void;
}

export function AdvancesExportPreferences({ advancesSettings, isLoading, handleChange }: IProps) {
  const t = useTranslate('advancesSettings.exportPDF');

  function _handleChange(key: string, value: unknown) {
    handleChange(key, value);
  }

  return (
    <SectionContainer title={t('title')}>
      <SC.Container>
        <Typography variant="body3">{t('caption')}</Typography>
        <ToggleSettings
          toggleKey={'allowIncludeBankDetailsPdf'}
          checked={advancesSettings?.allowIncludeBankDetailsPdf}
          onChange={_handleChange}
          label={t(`fields.allowIncludeBankDetailsPdf`)}
          isLoading={isLoading}
        />
        <ToggleSettings
          toggleKey={'allowIncludeSignaturePdf'}
          checked={advancesSettings?.allowIncludeSignaturePdf}
          onChange={_handleChange}
          label={t(`fields.allowIncludeSignaturePdf`)}
          isLoading={isLoading}
        />
        <ToggleSettings
          toggleKey={'allowIncludeReportHistoryPdf'}
          checked={advancesSettings?.allowIncludeReportHistoryPdf}
          onChange={_handleChange}
          label={t(`fields.allowIncludeReportHistoryPdf`)}
          isLoading={isLoading}
        />
      </SC.Container>
    </SectionContainer>
  );
}
