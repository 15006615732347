import { useCallback, useMemo } from 'react';
import { SubscriptionInfoItem } from '@containers/Subscription/components/molecules/SubscriptionInformation/SubscriptionInformation';
import { TravelContractInformation } from '@containers/Subscription/context/types/contractInformation';
import { useValueFormatter } from '@shared/hooks';

export function useTravelConditions(travelContract: TravelContractInformation | undefined) {
  const { getValueWithCurrency } = useValueFormatter();

  const formatValue = useCallback((value: number) => {
    if (!value) {
      return 'Gratuito';
    }

    return getValueWithCurrency({ value });
  }, []);

  const travelCommercialConditions = useMemo(() => {
    const items: SubscriptionInfoItem[] = [];

    const conditions = travelContract?.conditions || {};

    items.push({
      included: true,
      name: `Reserva online de passagens e hoteis – ${formatValue(conditions.onlineBookingValue)} por reserva`,
    });

    items.push({
      included: true,
      name: `Atendimento offline para reservas de passagens e hotéis – ${formatValue(
        conditions.bookingAttendanceValue,
      )} por reserva`,
    });

    items.push({
      included: true,
      name: 'Atendimento offline para emergências 24hs, apoio com embarque e desembarque nos aeroportos, entre outros – Valores disponíveis no contrato',
    });

    items.push({
      included: true,
      name: 'Cobertura nacional e internacional',
    });

    return items;
  }, [formatValue, travelContract?.conditions]);

  return { travelCommercialConditions };
}
