import { useTranslation } from '@locale/Translator';
import { DefaultModal } from '@molecules/Modal/DefaultModal';

import * as SC from './styled';

interface ConfirmReportApprovalModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  reportsNumber: number;
  onActionClick(): void;
  onCloseClick(): void;
}

export const ConfirmReportApprovalModal = ({
  isOpen,
  isLoading,
  reportsNumber,
  onActionClick,
  onCloseClick,
}: ConfirmReportApprovalModalProps) => {
  const { t } = useTranslation();
  const formOfDescription = reportsNumber > 1 ? 'pluralForm' : 'singularForm';

  return (
    <SC.Container>
      <DefaultModal
        open={isOpen}
        isLoading={isLoading}
        onActionClick={onActionClick}
        onCancelClick={onCloseClick}
        onCloseClick={onCloseClick}
        headerTextAlign={'center'}
        headerTitle={
          <SC.TitleModalConfirmContainer>
            {t(`organisms.confirmReportApprovalModal.${formOfDescription}.popupTitle`, {
              reportsSelected: reportsNumber,
            })}
          </SC.TitleModalConfirmContainer>
        }
        headerSubtitle={
          <SC.SubtitleModalConfirmContainer>
            {t(`organisms.confirmReportApprovalModal.${formOfDescription}.popupSubtitle`)}
          </SC.SubtitleModalConfirmContainer>
        }
        headerIconLabel={t('organisms.confirmReportApprovalModal.attention')}
        labelButtonAction={t('organisms.confirmReportApprovalModal.conclude')}
        headerIcon="IconThumbUp"
      />
    </SC.Container>
  );
};
