import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 982px;
`;

export const PriorityContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  min-width: 100%;
`;
