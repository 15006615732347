import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ActionsButtonsFooterContainer = styled.div`
  position: relative;
  display: grid;
  gap: ${props => props.theme.spacings.xs};
  grid-template-columns: min-content min-content;
`;

export const ButtonContent = styled.div`
  display: grid;
  gap: ${props => props.theme.spacings.xs4};
  grid-template-columns: 1fr 1fr;
  white-space: nowrap;
  height: 21px;
`;

export const ContainerModals = styled.div`
  position: absolute;
`;
