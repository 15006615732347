import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { IUserSelection } from '@containers/Expenses/context/types/users';
import { IWithdraw } from '@containers/Withdraw/context/types/types';

export interface IReport {
  id: number;
  referenceId: string;
  companyId: number;
  userId: number;
  name: string;
  description: string;
  status: EReportStatus;
  createdAt: string;
  expensesViolations?: IExpenseViolations[];
  violationNumber: number;
  remainingReimbursement: number;
  reimbursableValue: number;
  nonReimbursableValue: number;
  withdrawValue: number;
  advancesValue: number;
  activePeriod?: IReportPeriod;
  value: number;
  commentsNumber: number;
  attachmentsNumber: number;
  currencyPrefix: string;
  approver?: ReportUser;
  dateLimit: string;
  user?: IUser;
  reproved: IReproved;
  expirationDate: string;
  withdraw?: IWithdraw[];
}

export interface IReproved {
  message: string;
}

export interface IUser {
  id?: number;
  name?: string;
  cpf?: string;
  email?: string;
}
export interface IExpenseViolations {
  expenseId: IExpense['id'];
  violations: IViolation[];
}

export interface IViolation {
  id: number;
  description: string;
}

export interface IReportViolation {
  expenseId: number;
  violations: IViolation[];
}

export enum EReportStatus {
  OPEN = 'OPEN',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REPROVED = 'REPROVED',
  REIMBURSED = 'REIMBURSED',
  AWAITING_FINANCIAL_PROCESSING = 'AWAITING_FINANCIAL_PROCESSING',
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
  PROCESSING_PAYMENT_ERROR = 'PROCESSING_PAYMENT_ERROR',
}

export interface IReportFilters {
  status?: EReportStatus[];
  search?: string;
}

export interface ReportUser {
  id: number;
  name?: string;
  email?: string;
}

export interface IReportPeriod {
  startDate: string;
  endDate: string;
}

export interface IGetReportByIdResponse {
  getReportById: IReport;
}

export interface IReportHistory {
  id: number;
  createdAt: string;
  description: string;
  user: ReportUser;
}

export interface IReportHistoryByIdResponse {
  history: IReportHistory[];
  currentPage: number;
  pages: number;
  total: number;
}

export interface IGetReportHistoryByIdResponse {
  getReportHistoryById: IReportHistoryByIdResponse;
}

export interface IGetReportByIdProps {
  id: number;
}

export enum EOrderBy {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IPaginationSettings {
  limit: number;
  page: number;
}

export interface IReportsRequest extends IPaginationSettings {
  search?: string;
  status?: EReportStatus[];
}

export interface IReportGrid {
  id: number;
  name: string;
  activePeriod: IReportPeriod;
  value: number;
  status: EReportStatus;
}

export interface IGetReportByIdResponse {
  getReportById: IReport;
}

export type ICreateReportMutation = {
  report: {
    name: string;
    description: string;
    referenceId: string;
    approverId?: number;
  };
};

export type ICreateReportMutationResponse = {
  createReport: IReport;
};

export type IUpdateReportMutation = {
  report: Pick<IReport, 'name' | 'description'>;
  reportId: number;
};

export type IUpdateReportMutationResponse = {
  updateReportById: IReport;
};
export interface IReportDelete {
  id: number;
  message: string;
  error: boolean;
}

export type IDeleteReportMutation = {
  reportId: Pick<IReport, 'id'>;
};

export type IDeleteReportMutationResponse = {
  deleteReportById: IReportDelete;
};

export type IDetachExpensesFromReportRequest = {
  reportId: number;
  expenseIds: number[];
  commentary?: string;
};

export type DetachExpensesResponse = {
  expenseId: number;
  reportId: number;
  message: string;
  error: boolean;
};

export type IDetachExpensesFromReportResponse = {
  detachExpensesFromReport: {
    detachExpenseFromReportResponses: DetachExpensesResponse[];
  };
};

export type IAttachExpenseToReportMutation = {
  expenseIds: number[];
  reportId: number;
};

export type IAttachExpenseToReportMutationResponse = {
  attachExpensesToReport: IReport;
};
export interface IPeriodDate {
  endDate?: string;
  startDate?: string;
}
export interface IReportStartDate extends IPeriodDate {}

export interface IReportEndDate extends IPeriodDate {}

export interface IReportCreationDate extends IPeriodDate {}

export interface IGetRequestResponse {
  getReports: {
    reports: IReport[];
    currentPage: number;
    limit: number;
    pages: number;
    total: number;
  };
}

export interface IDetachAdvancesFromReportRequest {
  reportId: number;
  advanceIds: number[];
}

export interface DetachAdvancesResponse {
  message: string[];
  success: boolean;
}

export interface IDetachAdvancesFromReportResponse {
  detachAdvanceFromReport: DetachAdvancesResponse;
}

export enum EReportDateFilterOption {
  PERIOD = 'PERIOD',
  EXPIRED = 'EXPIRED',
  CREATED = 'CREATED',
  SUBMITTED = 'SUBMITTED',
  FIRST_APPROVAL = 'FIRST_APPROVAL',
  LAST_APPROVAL = 'LAST_APPROVAL',
  SENT_FOR_REFUND = 'SENT_FOR_REFUND',
  REFUND = 'REFUND',
  STATUS_CHANGE = 'STATUS_CHANGE',
}

export enum EReportViewType {
  USER = 'USER',
  APPROVAL = 'APPROVAL',
  FINANCIAL = 'FINANCIAL',
}

export interface IFiltersRequestReports {
  search?: string;
  status?: EReportStatus[];
  page?: number;
  limit?: number;
  awaitingImmediateApproverDecision?: boolean;
  requesterId?: number;
  periodType?: EReportDateFilterOption;
  startDate?: string;
  endDate?: string;
}

export enum EReportPdfExportType {
  NORMAL = 'NORMAL',
  CATEGORY = 'CATEGORY',
}

export interface IReportSummaries {
  id: number;
  name: string;
  referenceId: string;
  activePeriod: {
    startDate: string;
    endDate: string;
  };
  status: EReportStatus;
  value: number;
  expirationDate: string;
  user: {
    id: number;
    name: string;
    email: string;
  };
}
export interface IExportPdfReports {
  reportIds: IReport['id'][];
  pdfType: EReportPdfExportType;
  status?: EReportStatus[];
  search?: string;
  awaitingImmediateApproverDecision?: boolean;
}

export interface IReportSummariesFilter {
  status?: EReportStatus[];
  search?: string;
  startDate?: string;
  endDate?: string;
  requesterId?: IUserSelection['id'];
  filterType?: EReportDateFilterOption;
  awaitingImmediateApproverDecision?: boolean;
  periodType?: EReportDateFilterOption;
}
