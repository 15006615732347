import { useContext, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { useClients } from '@containers/Clients/hooks/rest/useClients';
import { clientsBaseURL } from '@shared/constant';
import { ImportingContext } from '@shared/contexts/BulkImportContext';
import { EImportingActions } from '@shared/contexts/BulkImportContext/actions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router';

import { FeedbackBox } from '../../../../../components/molecules/FeedbackBox';
import { ClientsGrid } from '../ClientsGrid/ClientsGrid';
import * as SC from './styled';

export function ClientsPreview() {
  const t = useTranslate('clients.clientsPreview');
  const navigate = useNavigate();
  const { state, dispatch } = useContext(ImportingContext);

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { clients, total, isLoading, refetch } = useClients({
    searchTerm,
    page,
  });

  return (
    <SC.Container>
      {state.hasTriedToImport && (
        <FeedbackBox
          onClose={() =>
            dispatch({
              type: EImportingActions.HAS_TRIED_TO_IMPORT,
              payload: {
                hasTriedToImport: false,
              },
            })
          }
          state={state}
          refetch={refetch}
          onClick={() => navigate(`${clientsBaseURL}/register/file/details`)}
          page="clients"
        />
      )}
      <SC.ContainerCounter>
        <SC.CounterLabel variant="headline8">{t('clientsCounter.label')}</SC.CounterLabel>
        <StatusTag variant="neutral">
          {t(`clientsCounter.items.${total === 1 ? 'singular' : 'plural'}`, { items: total })}
        </StatusTag>
      </SC.ContainerCounter>
      <ClientsGrid
        clients={clients}
        total={total}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
    </SC.Container>
  );
}
