import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs};
  max-width: 650px;
`;

export const ContainerActive = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs3};
`;

export const TitleActive = styled(Typography)`
  font-weight: 600 !important;
`;
