import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface Props {
  invert?: boolean;
  disabled?: boolean;
}

export const DropdownItemContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  flex-direction: ${({ invert }) => (invert ? 'row' : 'row-reverse')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const Description = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const Icon = styled(Icons).attrs({
  size: 22,
  fill: 'transparent',
})<Props>`
  display: flex;
  align-items: center;
  margin-right: ${props => (!props.invert && props.theme.spacings.xs4) || 0};
  margin-left: ${props => (props.invert && props.theme.spacings.xs4) || 0};
`;
