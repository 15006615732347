import * as SC from './styled';

export interface CollapseProps {
  /**
   * Collapse content
   */
  children: React.ReactNode;

  /**
   * if "true", display the count
   */
  isOpen: boolean;
}

export const Collapse = ({ children, isOpen }: CollapseProps) => {
  return (
    <>
      <SC.CollapseComponent in={isOpen}>{children}</SC.CollapseComponent>
    </>
  );
};
