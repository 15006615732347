import { useMemo } from 'react';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';

import { Option } from '../Autocomplete';

export function useSerializeOption(place: IEstablishment): Option {
  return useMemo(() => {
    if (place?.placeId || place?.place)
      return {
        value: place.placeId || `${place.lat}-${place.lng}`,
        label: place.place,
        caption: place.address,
        icon: 'IconMapPin',
      };
  }, [place]);
}
