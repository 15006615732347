import { useCostCenterFileRegister } from '@containers/CostCenters/hooks/rest/useCostCenterFileRegister';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { FooterPage } from '@molecules/FooterPage';
import { costCentersBaseURL } from '@shared/constant';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

interface ICostCenterFooterPageProps {
  action?(): void;
}

export const CostCenterFooterPage = ({ action }: ICostCenterFooterPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSubmit } = useFormContext();
  const { importCostCenters } = useCostCenterFileRegister();

  return (
    <FooterPage>
      <SC.ButtonsContainer>
        <LinkButton onClick={() => navigate(costCentersBaseURL)} variant="default">
          {t('organisms.registerCostCenter.bulkImport.footer.cancel')}
        </LinkButton>
        <Button
          variant="primary"
          size="medium"
          type="submit"
          onClick={action ? action : handleSubmit(importCostCenters)}>
          {t('organisms.registerCostCenter.bulkImport.footer.continue')}
          <Icons name="IconArrowRight" fill="transparent" />
        </Button>
      </SC.ButtonsContainer>
    </FooterPage>
  );
};
