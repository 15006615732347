import { useMemo } from 'react';
import { EReportStatus, IReportSummaries } from '@containers/Reports/context/types';

export interface UseAllowedActions {
  approve: boolean;
  reprove: boolean;
  exportPdf: boolean;
  exportPdfByCategory: boolean;
}

interface UseAllowedActionsProps {
  reports: IReportSummaries[];
  selectedItems: IReportSummaries['id'][];
  isExporting: boolean;
}

export function useBulkActionsAllowed({
  reports,
  selectedItems,
  isExporting,
}: UseAllowedActionsProps): UseAllowedActions {
  const reportsSelected: IReportSummaries[] = useMemo(
    () => reports.filter(item => selectedItems.includes(item.id)),
    [selectedItems],
  );

  const allowableAction: boolean = useMemo(
    () => reportsSelected.some(item => item.status != EReportStatus.APPROVED),
    [reportsSelected],
  );

  return {
    approve: allowableAction,
    reprove: allowableAction,
    exportPdf: !isExporting,
    exportPdfByCategory: !isExporting,
  };
}
