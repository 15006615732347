import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { IconButtonArea } from '@atoms/IconButtonArea';
import { IPolicy } from '@containers/Policies/context/types/types';
import { Box } from '@mui/material';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useParams } from 'react-router-dom';

import { PolicyAdvances } from '../PolicyAdvances/PolicyAdvances';
import { PolicyCategories } from '../PolicyCategories/PolicyCategories';
import { PolicyConditions } from '../PolicyConditions/PolicyConditions';
import { PolicyConditionsInfo } from '../PolicyConditions/PolicyConditionsInfo/PolicyConditionsInfo';
import { PolicyMileage } from '../PolicyMileage/PolicyMileage';
import { PolicyOCR } from '../PolicyOCR/PolicyOCR';
import * as SC from './styled';

interface IProps {
  policyId: IPolicy['id'];
  setCategoriesPolicySelected?: Dispatch<SetStateAction<string[]>>;
}

export function PolicyProperties({ policyId, setCategoriesPolicySelected }: IProps) {
  const { id } = useParams();
  const { checkLicenseFeature } = useLicenseFeatures();
  const t = useTranslate('policy.register.steps.policyProperties');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showRules, setShowRules] = useState({
    category: Boolean(id) || !checkLicenseFeature('INTERMEDIATE_EXPENSE'),
    mileage: Boolean(id),
    advances: Boolean(id),
    receiptReading: Boolean(id),
  });
  const activeRules = useMemo(() => Object.values(showRules).filter(rule => rule), [showRules]);
  const showSummaryBoxDivider = useMemo(
    () =>
      activeRules.length === 0 ||
      (checkLicenseFeature('INTERMEDIATE_EXPENSE') && activeRules.length < 3) ||
      (checkLicenseFeature('ADVANCED_EXPENSE') && activeRules.length < 4),
    [activeRules.length],
  );

  return (
    <SC.Container>
      <SectionContainer
        title={t('policyConditions.title')}
        caption={
          <>
            {t('policyConditions.caption')}
            <br />
            <SC.KnowMoreButton onClick={() => setOpenDrawer(true)} variant="default">
              {t('policyConditions.knowMore')}
            </SC.KnowMoreButton>
          </>
        }
        showDivider>
        <PolicyConditions />
      </SectionContainer>
      <SectionContainer title={t('policyRules.title')} showDivider>
        <SC.ContainerRulesDetails>
          {showRules.category && (
            <PolicyCategories
              setCategoriesPolicySelected={setCategoriesPolicySelected}
              policyId={policyId}
              onCloseRule={() => setShowRules({ ...showRules, category: false })}
            />
          )}

          {showRules.mileage && checkLicenseFeature('INTERMEDIATE_EXPENSE') && (
            <>
              <Box mt={4}>
                {showRules.category && <SC.Divider />}
                <PolicyMileage policyId={policyId} onCloseRule={() => setShowRules({ ...showRules, mileage: false })} />
              </Box>
            </>
          )}

          {showRules.advances && checkLicenseFeature('ADVANCED_ADVANCE') && (
            <Box mt={4}>
              {(showRules.category || showRules.mileage) && <SC.Divider />}
              <PolicyAdvances policyId={policyId} onCloseRule={() => setShowRules({ ...showRules, advances: false })} />
            </Box>
          )}

          {showRules.receiptReading && checkLicenseFeature('ADVANCED_EXPENSE') && (
            <Box mt={4}>
              {(showRules.category || showRules.mileage || showRules.advances) && <SC.Divider />}
              <PolicyOCR
                policyId={policyId}
                onCloseRule={() => setShowRules({ ...showRules, receiptReading: false })}
              />
            </Box>
          )}
        </SC.ContainerRulesDetails>

        {showSummaryBoxDivider && <SC.Divider />}

        <SC.ContainerRules>
          {!showRules.category && (
            <IconButtonArea
              fullWidth
              onClick={() => setShowRules({ ...showRules, category: true })}
              icon="IconCategory2">
              {t('policyRules.rules.category')}
            </IconButtonArea>
          )}
          {!showRules.mileage && checkLicenseFeature('INTERMEDIATE_EXPENSE') && (
            <IconButtonArea onClick={() => setShowRules({ ...showRules, mileage: true })} icon="IconMapPin">
              {t('policyRules.rules.mileage')}
            </IconButtonArea>
          )}
          {!showRules.advances &&
            checkLicenseFeature('ADVANCED_EXPENSE') &&
            checkLicenseFeature('ADVANCED_USER_REGISTRATION') && (
              <IconButtonArea onClick={() => setShowRules({ ...showRules, advances: true })} icon="IconCurrencyDollar">
                {t('policyRules.rules.advances')}
              </IconButtonArea>
            )}
          {!showRules.receiptReading && checkLicenseFeature('INTERMEDIATE_EXPENSE') && (
            <IconButtonArea onClick={() => setShowRules({ ...showRules, receiptReading: true })} icon="IconZoomCheck">
              {t('policyRules.rules.receiptReading')}
            </IconButtonArea>
          )}
        </SC.ContainerRules>
      </SectionContainer>
      <PolicyConditionsInfo openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
    </SC.Container>
  );
}
