import {
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  NoInfer,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery as useLazyQueryApolloClient,
} from '@apollo/client';

import { useHeaderDelegateOptions } from '../useHeaderDelegateOptions';

interface ILazyQueryHookOptions<TData, TVariables> extends LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> {
  delegationAllowed?: boolean;
}

export function useLazyQuery<TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: ILazyQueryHookOptions<TData, TVariables>,
): LazyQueryResultTuple<TData, TVariables> {
  const { headers } = useHeaderDelegateOptions(options?.context?.headers, options?.delegationAllowed);

  return useLazyQueryApolloClient(query, {
    ...options,
    context: {
      ...options?.context,
      headers,
    },
  });
}
