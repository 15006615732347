import { useMemo } from 'react';
import { EExpenseType, IExpenseHistory } from '@containers/Expenses/context/types/expenses';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { GridPagination } from '@molecules/GridPagination';
import { usePagination } from '@molecules/GridPagination/kooks/usePagination';
import { useTranslation } from 'react-i18next';

import { useBodyGridRows } from './hooks/UseBodyGridRow';
import { useBodyGridRowSkeleton } from './hooks/UseBodyGridRowSkeleton';
import { useHeaderGridColumns } from './hooks/UseHeaderGridColumns';
import { useHeaderGridColumnsSkeleton } from './hooks/UseHeaderGridColumnsSkeleton';
import { ExpenseHistoryGridContainer, Grid, GridContainer } from './styled';

export interface ExpenseHistoryGridProps {
  isLoading: boolean;
  expenseType: EExpenseType;
  historic: IExpenseHistory[];
  expenseHistoryTotal: number;
  onHistoryPageClick?(page: number): void;
}

export const ExpenseHistoryGrid = ({
  historic,
  expenseType,
  isLoading,
  expenseHistoryTotal,
  onHistoryPageClick,
}: ExpenseHistoryGridProps) => {
  const headerGridColumns = useHeaderGridColumns();
  const headerGridColumnsSkeleton = useHeaderGridColumnsSkeleton();
  const bodyGridRows = useBodyGridRows({ historic });
  const bodyGridRowSkeleton = useBodyGridRowSkeleton({ rowsNumber: 5 });

  const { onPageClick, onRowsPerPageClick, selectedPage, rowsPerPage } = usePagination({
    currentPage: 1,
  });
  const { t } = useTranslation();

  function onSelectPage(page: number): void {
    onPageClick(page);
    onHistoryPageClick && onHistoryPageClick(page);
  }

  const hasHistoryPagination = expenseHistoryTotal > 5;

  const paginatedRows = useMemo(
    () => bodyGridRows.slice((selectedPage - 1) * rowsPerPage, selectedPage * rowsPerPage),
    [selectedPage, rowsPerPage, bodyGridRows],
  );

  return (
    <ExpenseHistoryGridContainer>
      {expenseType == EExpenseType.MANUAL && (
        <Typography variant="headline8">{t('organisms.expenseHistoryGrid.title')}</Typography>
      )}
      <GridContainer>
        <Grid
          data={!isLoading ? paginatedRows : bodyGridRowSkeleton}
          columns={!isLoading ? headerGridColumns : headerGridColumnsSkeleton}
        />
      </GridContainer>
      {hasHistoryPagination && (
        <GridPagination
          isLoading={isLoading}
          onPageClick={onSelectPage}
          onRowsPerPageClick={onRowsPerPageClick}
          selectedPage={selectedPage}
          totalItems={expenseHistoryTotal}
          rowsPerPage={rowsPerPage}
          showRowsPerPageOptions={false}
        />
      )}
    </ExpenseHistoryGridContainer>
  );
};
