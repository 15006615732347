import { IReportPeriod } from '@containers/Reports/context/types/report';
import { useTranslation } from '@locale/Translator';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';

import { ReportInfosTable } from '../ReportInfosTable/ReportInfosTable';

interface ReportPeriodProps {
  activePeriod: IReportPeriod;
}

export const ReportPeriod = ({ activePeriod }: ReportPeriodProps) => {
  const { t } = useTranslation();
  const { getDateOnlyFormatter } = useDateFormatter();

  function getPeriod(): string {
    return `${getDateOnlyFormatter(activePeriod?.startDate)} ${t('report.to')} ${getDateOnlyFormatter(
      activePeriod?.endDate,
    )}`;
  }

  return <ReportInfosTable mainValueDescription={t('report.period')} mainValue={getPeriod()} />;
};
