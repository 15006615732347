import { useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { useTranslation } from '@locale/Translator';

import { AdvancesGrid } from '../AdvancesGrid';
import * as SC from './styled';

export function AdvancesPreview() {
  const translation = useTranslation();
  const t = (key: string, options?: object): string => translation.t(`organisms.advances.listPreview.${key}`, options);
  const [amountAdvances, setAmountAdvances] = useState<number>(0);

  return (
    <SC.Container>
      <SC.ContainerCounter>
        <SC.CounterLabel variant="headline8">{t('counterData.label')}</SC.CounterLabel>
        <StatusTag variant="neutral">
          {t(`counterData.items.${amountAdvances === 1 ? 'singular' : 'plural'}`, { items: amountAdvances })}
        </StatusTag>
      </SC.ContainerCounter>
      <AdvancesGrid setAmountAdvances={setAmountAdvances} />
    </SC.Container>
  );
}
