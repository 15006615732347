import React from 'react';

import * as SC from './styled';

interface BulkImportProps {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

export const BulkImportFiles = ({ title, subtitle, children }: BulkImportProps) => {
  return (
    <SC.Container>
      <SC.LeftContainer>
        <SC.SectionTitle variant="headline7">{title}</SC.SectionTitle>
        <SC.SectionSubtitle variant="body3">{subtitle}</SC.SectionSubtitle>
      </SC.LeftContainer>
      {children}
    </SC.Container>
  );
};
