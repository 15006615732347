import { useState } from 'react';
import { useCustomFields } from '@containers/Customizations/hooks/CustomFields/useCustomFields';
import { Button, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { CustomFieldModal } from '../CustomFieldModal/CustomFieldModal';
import * as SC from './styled';

const MAX_CUSTOM_FIELDS = 10;

export function AddCustomField() {
  const { customFields, isLoading } = useCustomFields();

  const t = useTranslate('organisms.customizationsCustomFields.addCustomField');
  const [showModal, setShowModal] = useState<boolean>(false);

  const maximumReached = (customFields?.length || 0) >= MAX_CUSTOM_FIELDS;

  const ButtonElement = (
    <Button
      variant="primary"
      size="medium"
      disabled={maximumReached}
      loading={isLoading}
      onClick={() => setShowModal(_show => !_show)}>
      <SC.ButtonContent>
        <div>{t('buttonAdd')}</div>
        <SC.IconPlus name="IconPlus" fill="transparent" size={20} />
      </SC.ButtonContent>
    </Button>
  );

  return (
    <SC.Container>
      {maximumReached ? (
        <div style={{ width: 310 }}>
          <Tooltip arrow title={t('disabledTooltip')} placement="top">
            <div>{ButtonElement}</div>
          </Tooltip>
        </div>
      ) : (
        ButtonElement
      )}

      {showModal && <CustomFieldModal open={showModal} onCloseModal={() => setShowModal(_show => !_show)} />}
    </SC.Container>
  );
}
