import SetupOnboardingImage from '@assets/images/setup_introduction.png';
import { Button, LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { translate } from '@locale/Translator';

import { ActionsContainer, Description, Image, SetupBody, SetupContainer, UserName } from './styled';

interface SetupOnboardProps {
  userName: string;
  onClickConfigureNow(): void;
  onClickSkipConfigure(): void;
}

export const SetupIntroduction = ({ userName, onClickConfigureNow, onClickSkipConfigure }: SetupOnboardProps) => {
  return (
    <SetupContainer>
      <SetupBody>
        <UserName variant="body3">
          {translate({
            prefix: 'molecules.setupIntroduction',
            path: 'hello',
            options: { userName },
          })}
        </UserName>

        <Typography variant="headline5">
          {translate({ prefix: 'molecules.setupIntroduction', path: 'title' })}
        </Typography>

        <Description variant="body3">
          {translate({ prefix: 'molecules.setupIntroduction', path: 'description' })}
        </Description>

        <ActionsContainer>
          <LinkButton variant="default" onClick={onClickSkipConfigure}>
            {translate({ prefix: 'molecules.setupIntroduction', path: 'skipConfiguration' })}
          </LinkButton>

          <Button variant="primary" onClick={onClickConfigureNow} size="large">
            {translate({ prefix: 'molecules.setupIntroduction', path: 'configureNow' })}
          </Button>
        </ActionsContainer>
      </SetupBody>

      <Image src={SetupOnboardingImage} />
    </SetupContainer>
  );
};
