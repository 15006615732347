import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

interface ReportDescriptionProps {
  description?: string;
}

export const ReportInfosDescription = ({ description }: ReportDescriptionProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SC.ReportDescriptionTitleContainer>
        <SC.TitleTable>{t('report.finality')}</SC.TitleTable>
      </SC.ReportDescriptionTitleContainer>
      <SC.ReportDescription>
        <SC.DescriptionTable>{description || '-'} </SC.DescriptionTable>
      </SC.ReportDescription>
    </>
  );
};
