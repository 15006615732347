import { AddSubsidiaryButton } from '@containers/Subsidiaries/components/molecules/AddSubsidiaryButton/AddSubsidiaryButton';
import { SubsidiariesPreview } from '@containers/Subsidiaries/components/organisms/SubsidiariesPreview/SubsidiariesPreview';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function SubsidiariesList() {
  const t = useTranslate('subsidiaries.page');

  return (
    <BasePage
      headerPage={{
        title: t('title'),
        caption: t('caption'),
        actions: <AddSubsidiaryButton />,
        breadcrumbItems: [
          {
            to: '/settings',
            label: t('breadcrumb.manageCompany'),
          },
          {
            to: '',
            label: t('title'),
          },
        ],
      }}>
      <SubsidiariesPreview />
    </BasePage>
  );
}
