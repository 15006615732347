import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const StyledContractModal = styled(DefaultModal)`
  .MuiDialog-paper {
    width: 90% !important;
    max-width: 1366px !important;
  }

  .contract-modal {
    &-container {
      max-width: 100%;
    }

    &-body {
      height: 130vh;
      max-height: 130vh;
    }
  }
`;

export const ContainerPDF = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
`;

export const SelectContent = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacings.xs2};
`;
export const LinkContent = styled.div`
  width: fit-content;
  margin-left: auto;
`;

export const LinkLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PlusIcon = styled(Icons).attrs({
  name: 'IconPlus',
  size: 16,
})`
  margin-left: ${props => props.theme.spacings.xs4};
`;

export const SummaryReportOptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FolderIcon = styled(Icons).attrs({ name: 'IconFolder', size: 24, fill: 'transparent' })`
  margin-right: ${props => props.theme.spacings.xs4};
`;

export const DescriptionOption = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600;
`;
