import { Loader, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  padding: theme.spacings.xs,
  backgroundColor: theme.colors.neutral[95],
  borderRadius: theme.borders.radius.m,
}));

export const Content = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '100%',
});

export const InfoContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gap: theme.spacings.xs5,
  gridTemplateRows: 'min-content min-content',
  width: '50%',

  ':first-child': {
    borderRight: `${theme.borders.width.xs2} solid ${theme.colors.neutral[80]}`,
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[40],
  fontWeight: ' 600!important',
  textAlign: 'center',
}));

export const Value = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[40],
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacings.xs4,
}));

export const LoaderDistance = styled(Loader)({
  marginTop: '-2px',
});
