import React from 'react';

import { DrawerContainer, DrawerMain, DrawerShow } from './styled';

export interface DrawerProps {
  isOpen?: boolean;
  children?: React.ReactNode;
  drawerWidth: string | number;
  drawerMarginTop?: string | number;
  drawerHeight?: string | number;
  renderDrawer?: React.ReactNode;
}

export const Drawer = ({ isOpen, renderDrawer, drawerWidth, drawerMarginTop, drawerHeight, children }: DrawerProps) => {
  return (
    <DrawerContainer>
      <DrawerMain open={isOpen} width={drawerWidth}>
        {children}
      </DrawerMain>

      <DrawerShow
        width={drawerWidth}
        margin-top={drawerMarginTop}
        height={drawerHeight}
        variant="persistent"
        anchor="right"
        open={isOpen}>
        {renderDrawer}
      </DrawerShow>
    </DrawerContainer>
  );
};
