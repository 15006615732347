import { EReportStatus, IReport } from '@containers/Reports/context/types';
import {
  expensesAccountingBaseURL,
  expensesAdvancesBaseURL,
  expensesApprovalsBaseURL,
  expensesBaseUrl,
} from '@shared/constant';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { useLicenseFeatures } from '../licenseFeatures/useLicenseFeatures';
import { usePlatformPermissions } from './usePlatformPermissions';

export interface IReportAllowedActions {
  displayApprovalActions?: boolean;
  view?: boolean;
  delete?: boolean;
  edit?: boolean;
  approve?: boolean;
  reprove?: boolean;
  reopen?: boolean;
  repay?: boolean;
  submit?: boolean;
  export?: boolean;
  exportByCategory?: boolean;
  reportDetailShowApproval?: boolean;
  reportDetailShowRequester?: boolean;
}

export interface IUseReportsPermissions {
  getReportActions(_statusReport: IReport['status']): IReportAllowedActions;
  getMultiReportsActions(_statusReport: IReport['status']): IReportAllowedActions;
}

export function useReportsPermissions(): IUseReportsPermissions {
  const getCurrentUrlBase = useCurrentUrlBase();
  const { checkPlatformPermission } = usePlatformPermissions();
  const { checkLicenseFeature } = useLicenseFeatures();

  function getReportActions(_statusReport: IReport['status']): IReportAllowedActions {
    let allowedActions: IReportAllowedActions = {
      displayApprovalActions: true,
      delete: false,
      approve: false,
      reprove: false,
      reopen: false,
      repay: false,
      edit: false,
      submit: false,
      export: false,
      exportByCategory: false,
      reportDetailShowApproval: false,
      reportDetailShowRequester: false,
      view: checkPlatformPermission(EAuthZPermission.EXPENSES_REPORTS_VIEW),
    };

    switch (getCurrentUrlBase()) {
      case expensesAccountingBaseURL:
        const refundAllowed = checkPlatformPermission(EAuthZPermission.REPORT_REFUND_ACTION);
        const repayAllowed = refundAllowed && _statusReport == EReportStatus.APPROVED;
        const reproveAllowed =
          refundAllowed &&
          (_statusReport == EReportStatus.APPROVED ||
            _statusReport == EReportStatus.AWAITING_FINANCIAL_PROCESSING ||
            _statusReport == EReportStatus.PROCESSING_PAYMENT_ERROR);

        allowedActions = {
          ...allowedActions,
          displayApprovalActions: !checkPlatformPermission(EAuthZPermission.REPORT_REFUND_ACTION),
          reprove: reproveAllowed,
          repay: repayAllowed,
          export: true,
          exportByCategory: true,
          reportDetailShowApproval: checkLicenseFeature('INTERMEDIATE_APPROVALS'),
          reportDetailShowRequester: true,
        };
        break;

      case expensesApprovalsBaseURL:
        const actionsAllowedApprovals: boolean =
          checkPlatformPermission(EAuthZPermission.REPORT_APPROVE_ACTION) && _statusReport === EReportStatus.SUBMITTED;

        allowedActions = {
          ...allowedActions,
          displayApprovalActions: !checkPlatformPermission(EAuthZPermission.REPORT_APPROVE_ACTION),
          approve: actionsAllowedApprovals,
          reprove: actionsAllowedApprovals,
          export: true,
          exportByCategory: true,
          reportDetailShowApproval: checkLicenseFeature('INTERMEDIATE_APPROVALS'),
          reportDetailShowRequester: true,
        };
        break;

      case expensesBaseUrl:
      case expensesAdvancesBaseURL:
        const expenseIsEdit: boolean =
          checkPlatformPermission(EAuthZPermission.EXPENSES_REPORTS_ACTION) ||
          checkPlatformPermission(EAuthZPermission.EXPENSES_MILEAGE_ACTION);

        if (expenseIsEdit) {
          allowedActions = {
            ...allowedActions,
            export: true,
            reopen: _statusReport === EReportStatus.REPROVED || _statusReport === EReportStatus.SUBMITTED,
            submit: _statusReport === EReportStatus.OPEN,
            delete: expenseIsEdit,
            edit: expenseIsEdit && _statusReport === EReportStatus.OPEN,
            reportDetailShowApproval: checkLicenseFeature('INTERMEDIATE_APPROVALS'),
          };
        }
        break;
    }

    return allowedActions;
  }

  function getMultiReportsActions(_statusReport: IReport['status']): IReportAllowedActions {
    return getReportActions(_statusReport);
  }

  return {
    getReportActions,
    getMultiReportsActions,
  };
}
