import { ApolloError } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getCategoryLimitByPolicyIdQuery } from '../context/queries/policies';
import { ILimitGrid } from '../context/types/limits';
import { IGetCategoryLimitByPolicyId, IPolicy } from '../context/types/policies';

interface IProps {
  policyId: IPolicy['id'];
  skip?: boolean;
}

interface IUseCategoriesLimitByPolicyId {
  categoriesLimitByPolicy: ILimitGrid[];
  isLoading: boolean;
  error: ApolloError;
}

export function useCategoriesLimitByPolicyId({ policyId, skip = false }: IProps): IUseCategoriesLimitByPolicyId {
  const {
    loading: isLoading,
    data,
    error,
  } = useQuery<IGetCategoryLimitByPolicyId>(getCategoryLimitByPolicyIdQuery, {
    variables: {
      policyId,
    },
    skip: skip || !Boolean(policyId),
  });
  return {
    categoriesLimitByPolicy: data?.getCategoryLimits?.categoryLimitPolicySettings ?? [],
    isLoading,
    error,
  };
}
