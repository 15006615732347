import { gql } from '@apollo/client';

export const getCategoriesQuery = gql`
  query GetAllCategories {
    getAllLegacyCategories {
      categories {
        id
        active
        description
        financeReferenceId
        iconUrl
        readOnly
      }
    }
  }
`;

export const createCategoryMutation = gql`
  mutation CreateCategory($category: CategoryProps!) {
    createCategory(category: $category) {
      id
      active
      description
      financeReferenceId
      iconUrl
      readOnly
    }
  }
`;

export const updateCategoryMutation = gql`
  mutation UpdateCategory($category: CategoryProps!) {
    updateCategory(category: $category) {
      id
      active
      description
      financeReferenceId
      iconUrl
      readOnly
    }
  }
`;

export const deleteCategoryByIdMutation = gql`
  mutation DeleteCategory($categoryId: Int!) {
    deleteCategory(categoryId: $categoryId) {
      error
      message
    }
  }
`;

export const disableAllCategoriesMutation = gql`
  mutation DisableAllCategories {
    disableAllCategories {
      categories {
        active
      }
    }
  }
`;

export const enableAllCategoriesMutation = gql`
  mutation ActiveAllCategories {
    activeAllCategories {
      categories {
        active
      }
    }
  }
`;
