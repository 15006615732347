import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import { BreadcrumbPage } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { expensesCustomizationsBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

interface IProps {
  step: number;
  isEditing: boolean;
}

export const RegisterPolicyProgress = ({ step = 0, isEditing }: IProps) => {
  const t = useTranslate('policy.register');

  return (
    <SC.Header>
      <BreadcrumbPage
        items={[
          {
            to: expensesCustomizationsBaseURL,
            label: t('breadcrumb.customizations'),
          },
          {
            to: '',
            label: t(`title.${!isEditing ? 'add' : 'edit'}`),
          },
        ]}
      />
      <Stepper activeStep={step} steps={[t('steps.basicInformation.title'), t('steps.policyProperties.title')]} />
    </SC.Header>
  );
};
