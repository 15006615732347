import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

export type TAdvancesConfirmationActions = 'exclusion' | 'approve' | 'reprove' | 'unlink';

type TProps = {
  open: boolean;
  isBatchAction?: boolean;
  isLoading?: boolean;
  onActionClick(): void;
  onClose: () => void;
};

export function WithdrawConfirmationActionsModal({
  open,
  isBatchAction = false,
  isLoading,
  onActionClick,
  onClose,
}: TProps) {
  const t = useTranslate('withdraw.modal.detachConfirmation');

  function getTranslateType(): string {
    return isBatchAction ? 'plural' : 'singular';
  }

  return (
    <SC.Container>
      <DangerActionModal
        open={open}
        isLoading={isLoading}
        headerIconLabel={t('iconDescription')}
        headerTitle={t(`title.${getTranslateType()}`)}
        headerSubtitle={t(`message.${getTranslateType()}`)}
        labelButtonAction={
          <SC.LabelButtonContent>
            <p>{t(`actions.confirm.${getTranslateType()}`)}</p>
            {<Icons name="IconUnlink" fill="transparent" size={24} />}
          </SC.LabelButtonContent>
        }
        onActionClick={onActionClick}
        onCancelClick={onClose}
      />
    </SC.Container>
  );
}
