import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Drawer } from '@atoms/Drawer';
import { StatusTag } from '@atoms/StatusTag';
import { usePoliciesApplied } from '@containers/Policies/hooks/dataSources/usePoliciesApplied';
import { usePolicyById } from '@containers/Policies/hooks/dataSources/usePolicyById';
import { IconButton, Icons, Loader, Skeleton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { ImagesModal } from '@molecules/ImagesModal';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useTheme } from 'styled-components';

import * as SC from './styled';

interface IProps {
  openDrawer: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

export function PolicyDrawer({ openDrawer, setOpenDrawer }: IProps) {
  const t = useTranslate('policy.policyDetails.drawer');
  const { colors } = useTheme();
  const { policies, isLoading } = usePoliciesApplied(openDrawer);
  const [policyId, setPolicyId] = useState<string>();
  const [openDocument, setOpenDocument] = useState(false);
  const { policy, isLoading: policyIsLoading, error: policyError } = usePolicyById(policyId);
  const [undocumentedPolicies, setUndocumentedPolicies] = useState<string[]>([]);

  useEffect(() => {
    if (!policyIsLoading && policy?.id && !policy?.document?.base64 && !policyError)
      setUndocumentedPolicies([...undocumentedPolicies, policy?.id]);
  }, [JSON.stringify(policy)]);

  return (
    <SC.DrawerContainer>
      <Drawer
        isOpen={openDrawer}
        drawerWidth={670}
        renderDrawer={
          <SC.Container>
            <SC.Header>
              <SC.HeaderContent>
                <div>
                  <Typography variant="headline6">{t('title')}</Typography>
                  <Typography variant="body3">{t('caption')}</Typography>
                </div>
                <IconButton size="small" variant="line" icon="IconX" onClick={() => setOpenDrawer(false)} />
              </SC.HeaderContent>
            </SC.Header>
            <SC.Body>
              {isLoading ? (
                new Array(3).fill({}).map((_, index) => <Skeleton key={index} width={'100%'} height={102} />)
              ) : policies?.length ? (
                policies?.map(policy => (
                  <SC.PolicyContainer key={policy.id}>
                    <SC.PolicyInfo>
                      <SC.PolicyTitleContainer>
                        <SC.PolicyTitle variant="headline8">{policy.name}</SC.PolicyTitle>
                        <StatusTag showStatusIcons={true} variant={policy.enabled ? 'success' : 'neutral'}>
                          {policy.enabled ? t('active') : t('inactive')}
                        </StatusTag>
                      </SC.PolicyTitleContainer>
                      <Typography variant="body4" color={colors.neutral[40]}>
                        {policy.description}
                      </Typography>
                      {undocumentedPolicies.some(id => id === policy.id) && (
                        <SC.PolicyUndocumentedText variant="caption">
                          <Icons name="IconFileOff" size={14} />
                          {t('undocumented')}
                        </SC.PolicyUndocumentedText>
                      )}
                    </SC.PolicyInfo>
                    <div>
                      {policyIsLoading && policy.id === policyId ? (
                        <SC.ButtonLoading>
                          <Loader size="extraSmall" variant="primary" />
                        </SC.ButtonLoading>
                      ) : (
                        <IconButton
                          disabled={
                            (policyIsLoading && policy.id !== policyId) ||
                            undocumentedPolicies.some(id => id === policy.id)
                          }
                          size="small"
                          variant="line"
                          icon={!undocumentedPolicies.some(id => id === policy.id) ? 'IconArrowRight' : 'IconFileOff'}
                          onClick={() => {
                            setPolicyId(policy.id);
                            setOpenDocument(true);
                          }}
                        />
                      )}
                    </div>
                  </SC.PolicyContainer>
                ))
              ) : (
                <SC.EmptyContainer>
                  <Icons name="IconShieldOff" size={56} color={colors.neutral[80]} />
                  <SC.EmptyTitle variant="headline8" color={colors.neutral[50]}>
                    {t('emptyPolicies')}
                  </SC.EmptyTitle>
                </SC.EmptyContainer>
              )}
            </SC.Body>
          </SC.Container>
        }
      />
      {openDrawer && <SC.Backdrop onClick={() => setOpenDrawer(false)} />}
      {openDocument && !policyIsLoading && policy?.document?.base64 && (
        <ImagesModal
          onCloseModalClick={() => setOpenDocument(false)}
          images={[
            {
              src: policy?.document?.base64,
              mime: policy?.document?.type,
            },
          ]}
          isLoading={policyIsLoading}
        />
      )}
    </SC.DrawerContainer>
  );
}
