import { ICostCenterCompany } from '@containers/CostCenters/context/types/costCenters';
import { CostCenterForm } from '@containers/CostCenters/validators/CostCenterFormSchema';
import { costCentersBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';

export function useSaveCostCenter() {
  const { mutation } = useMutate('v1/cost-centers');
  const t = useTranslate('pages.costCenterRegister.feedbacks');
  const navigate = useNavigate();

  async function handleSave(costCenter: CostCenterForm, id: ICostCenterCompany['id']) {
    const isEdit = Boolean(id);

    try {
      const response = await mutation.trigger({
        path: `${isEdit ? id : ''}`,
        options: {
          method: isEdit ? 'PATCH' : 'POST',
          body: JSON.stringify(costCenter),
        },
      });

      const success: boolean = !response.error;

      if (success) {
        mutate(`v1/cost-centers/${response.id}`);

        showToast({
          message: t(isEdit ? 'costCenterEditSuccess' : 'costCenterCreatedSuccess'),
          type: 'success',
        });
        navigate(costCentersBaseURL, { replace: true });
      } else {
        showToast({
          message: t('costCenterCreatedAndEditError'),
          type: 'error',
        });
      }

      return success;
    } catch (_error) {
      showToast({
        message: _error?.message ?? t('costCenterCreatedAndEditError'),
        type: 'error',
      });
    }
  }

  return {
    handleSave,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
