import { Grid } from '@mui/material';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useTheme } from 'styled-components';

import { EFlowStep } from '../CustomApprovalFlowRegister';
import * as SC from './styled';

interface IProps {
  approvalFlowId?: string;
  step?: EFlowStep;
  children?: React.ReactNode;
}

export function CustomApprovalFlowRegisterBasePage({ approvalFlowId, step, children }: IProps) {
  const t = useTranslate('approvalFlows.pageRegister.infos');
  const currentPath = useCurrentPath();
  const hasAdvance = currentPath.includes('advances');
  const { spacings } = useTheme();
  const isBasicInformation = step === EFlowStep.BASIC_INFORMATION;

  return (
    <Grid container key={`approval-flow-${approvalFlowId}`} gap={spacings.m} width={'100%'}>
      <Grid item gap={spacings.xs} lg={12} xl={3} maxWidth={'389px'}>
        <SC.ContainerInfos>
          <SC.InfoTitle variant="headline7">
            {t(isBasicInformation ? 'basicInformation.title' : 'approvalType.title')}
          </SC.InfoTitle>
          <SC.InfoDescription variant="body3">
            {t(isBasicInformation ? 'basicInformation.description' : 'approvalType.description')}
          </SC.InfoDescription>
          {!isBasicInformation && (
            <SC.InfoListContainer>
              <SC.InfoListTitle variant="headline8">{t('approvalType.important.title')}</SC.InfoListTitle>
              <SC.ContainerItemsList>
                <li>
                  <SC.InfoListDescription variant="body4">
                    {t(`approvalType.important.item1.${hasAdvance ? 'advance' : 'report'}`)}
                  </SC.InfoListDescription>
                </li>
                <li>
                  <SC.InfoListDescription variant="body4">{t('approvalType.important.item2')}</SC.InfoListDescription>
                </li>
                <li>
                  <SC.InfoListDescription variant="body4">
                    {t(`approvalType.important.item3.${hasAdvance ? 'advance' : 'report'}`)}
                  </SC.InfoListDescription>
                </li>
              </SC.ContainerItemsList>
            </SC.InfoListContainer>
          )}
        </SC.ContainerInfos>
      </Grid>
      <Grid item gap={spacings.xs} lg={12} xl={8}>
        {children}
      </Grid>
    </Grid>
  );
}
