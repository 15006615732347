import styled from 'styled-components';

type IContainerProps = {
  hasActionsFooter: boolean;
};

export const Container = styled.div<IContainerProps>`
  width: 100%;
  position: relative;
  height: ${({ hasActionsFooter }) => (hasActionsFooter ? 'calc(100vh - 155px)' : '100%')};
  overflow: auto;

  .MuiPopper-root {
    z-index: 999;
  }
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacings.m};
  display: flex;
  grid-row-gap: ${({ theme }) => theme.spacings.m};
  flex-direction: column;
`;
export const Header = styled.div`
  width: 100%;
  height: min-content;
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.m};
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.spacings.xs2};
  background-color: ${props => props.theme.colors.neutral[100]};
  width: fill-available !important;
  position: fixed;
  bottom: 0;
  z-index: 1300;
  box-shadow: 20px 4px 26px rgba(0, 0, 0, 0.1);
`;

styled.div`
  .select-field-popper-custom {
    z-index: 999;
  }
`;
