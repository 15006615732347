import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
  width: 100%;
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacings.xs} ${({ theme }) => theme.spacings.m};
  border-bottom: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: 0 ${({ theme }) => theme.spacings.m};
`;

export const ApproversContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.borders.width.xs2};
`;

export const TagContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.borders.width.xs2};
  width: 100%;
  max-width: 220px;
`;
