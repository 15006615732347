import { useEffect, useState } from 'react';
import {
  ELicenseFeaturesExpenseSettings,
  IConfigurationExpenseSettings,
} from '@containers/Customizations/context/types/settings/expenseSettingsTypes';
import { useGeneralExpenseSettings } from '@containers/Customizations/hooks/settings/expense/useExpenseSettings';
import { useUpdateExpenseSettings } from '@containers/Customizations/hooks/settings/expense/useExpenseSettingsMutate';
import { useTranslation } from '@locale/Translator';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { useDebounce } from 'use-debounce';

import { ToggleSettings } from '../../../molecules/ToggleSettings/ToggleSettings';
import { GeneralSettingsSkeleton } from './GeneralSettingsFieldsSkeleton';
import * as SC from './styled';

export enum EGeneralSettings {
  EXPIRATION_EXPENSES = 'expirationExpenses',
  DAYS_EXPIRATION_EXPENSES = 'daysExpirationExpenses',
  MANUAL_EXPENSE_ENTRY = 'manualExpenseEntry',
  ALLOW_LATER_DATE = 'allowLaterDate',
  ALLOW_EARLIER_DATE = 'allowEarlierDate',
}

export const GeneralSettingsFields = () => {
  const { t } = useTranslation();
  const { checkPlatformPermission } = usePlatformPermissions();
  const allowedActions: boolean = checkPlatformPermission(EAuthZPermission.CUSTOMIZATION_EXPENSE_ACTION);
  const { expenseSettings, isLoading } = useGeneralExpenseSettings();
  const { updateExpenseSetting } = useUpdateExpenseSettings();
  const [currentSettings, setCurrentSettings] = useState<IConfigurationExpenseSettings>();

  const [textInputFilterChange, setTextInputFilterChange] = useState<{ settingKey: EGeneralSettings; value: number }>();
  const [inputValueChange] = useDebounce(textInputFilterChange, 750);

  useEffect(() => {
    inputValueChange !== undefined && onChangeValue(inputValueChange.settingKey, inputValueChange.value);
  }, [inputValueChange]);

  async function onChangeValue(field: EGeneralSettings, value: boolean | number) {
    try {
      const fields = { ...currentSettings, [field]: value };
      setCurrentSettings(fields);
      await updateExpenseSetting(field, value);
    } catch (error) {
      setCurrentSettings(currentSettings);
    }
  }

  async function onChangeSettingTextValue(field: EGeneralSettings, value: number) {
    setTextInputFilterChange({ settingKey: field, value });
    const fields = { ...currentSettings, [field]: value };
    setCurrentSettings(fields);
  }

  useEffect(() => {
    setCurrentSettings(expenseSettings);
  }, [expenseSettings]);

  if (isLoading && !currentSettings) {
    return <GeneralSettingsSkeleton />;
  }

  return (
    <SC.Container>
      {Object.values(EGeneralSettings).map((field, key) => (
        <PermissionsChecker key={key} licenseFeature={ELicenseFeaturesExpenseSettings[field]}>
          {field !== EGeneralSettings.DAYS_EXPIRATION_EXPENSES ? (
            <ToggleSettings
              disabled={!allowedActions}
              checked={currentSettings?.[field]}
              toggleKey={field}
              label={t(`molecules.generalSettings.fieldsLabel.${field}`)}
              onChange={onChangeValue}
            />
          ) : (
            currentSettings?.expirationExpenses && (
              <>
                <div></div>
                <SC.FieldDays
                  type="number"
                  disabled={!allowedActions}
                  label={t(`molecules.generalSettings.fieldsLabel.${field}`)}
                  value={currentSettings?.[field]}
                  onChange={e => onChangeSettingTextValue(field, Number(e?.target?.value))}
                />
              </>
            )
          )}
        </PermissionsChecker>
      ))}
    </SC.Container>
  );
};
