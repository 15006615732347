import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import styled from 'styled-components';

export const MileageRateRowContainer = styled.div`
  border: ${props => props.theme.borders.width.xs2} solid ${props => props.theme.colors.neutral[80]};
  border-radius: ${props => props.theme.borders.radius.m};
  padding: ${props => props.theme.spacings.xs2};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RowColumn = styled(Box)``;

export const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;

  .MuiButtonBase-root {
    margin-left: ${props => props.theme.spacings.xs};
  }
`;

export const ColumnTitle = styled(Typography).attrs({
  variant: 'body4',
})`
  font-weight: 600;
  margin-bottom: ${props => props.theme.spacings.xs5};
`;
