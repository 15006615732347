import { useSearchParams } from 'react-router-dom';

export function useSelectedTab<T extends string>(tabs: Readonly<Array<{ id: T }>>) {
  const [searchParams, setSearchParams] = useSearchParams();

  /** Selected tab from search params. Strips double quotes */
  const selectedTab = searchParams.get('tab')?.replaceAll('"', '');
  const selectedTabIndex = Math.max(
    tabs.findIndex(t => t.id === selectedTab),
    0, // Handles unavailable tabs
  );

  function onTabChange(index: number) {
    setSearchParams({ tab: tabs[index]?.id ?? tabs[0].id });
  }

  return {
    selectedTabIndex,
    onTabChange,
  };
}
