import { expensesAccountingBaseURL, expensesApprovalsBaseURL } from '@shared/constant';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { useLicenseFeatures } from '../licenseFeatures/useLicenseFeatures';
import { usePlatformPermissions } from './usePlatformPermissions';

export function useAdvancesPermissions() {
  const getCurrentUrlBase = useCurrentUrlBase();
  const { checkPlatformPermission } = usePlatformPermissions();
  const { checkLicenseFeature } = useLicenseFeatures();
  const advancesAllowedInFeatures = checkLicenseFeature('ADVANCED_ADVANCE');

  function getPermissions() {
    const permissions = {
      edit: false,
      link: false,
      unlink: false,
      view: checkPlatformPermission(EAuthZPermission.ADVANCES_REPORTS_VIEW) && advancesAllowedInFeatures,
      hasAccounting: false,
      hasApprover: false,
    };

    switch (getCurrentUrlBase()) {
      case expensesAccountingBaseURL:
        return { ...permissions, hasAccounting: advancesAllowedInFeatures };
      case expensesApprovalsBaseURL:
        return { ...permissions, hasApprover: advancesAllowedInFeatures };
      default:
        const hasActions =
          checkPlatformPermission(EAuthZPermission.ADVANCES_REPORTS_ACTION) && advancesAllowedInFeatures;
        return {
          ...permissions,
          edit: hasActions,
          link: hasActions,
          unlink: hasActions,
        };
    }
  }

  return getPermissions();
}
