import { useState } from 'react';
import { ICompanyLicenseAcceptanceTerm } from '@containers/Subscription/context/types/contracts';

import { useCheckSignedContracts } from './useCheckSignedContracts';
import { useTermsInLS } from './useTermsInLS';

interface UseAcceptedContacts {
  termsSigned: boolean;
  checkSignedContracts(acceptanceTerms: ICompanyLicenseAcceptanceTerm[]): boolean;
}

export function useAcceptedContacts(): UseAcceptedContacts {
  const checkSignedContracts = useCheckSignedContracts();
  const { getTermsFromLS } = useTermsInLS();
  const [acceptanceTerms] = useState<ICompanyLicenseAcceptanceTerm[]>(getTermsFromLS() || []);
  const [termsSigned] = useState<boolean>(checkSignedContracts(acceptanceTerms));

  return {
    termsSigned,
    checkSignedContracts,
  };
}
