import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs};
  width: 100%;
`;

export const ContainerItems = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs};
  width: 100%;
  grid-auto-rows: max-content;
  padding-right: ${({ theme }) => theme.spacings.xs};
`;
