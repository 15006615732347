import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

import { IExpenseRefFields } from './hooks/useFieldTypeConfig';

interface IFieldContainerProps {
  fieldName: string;
  hidden?: boolean;
}

interface IFieldsContainer {
  isLoading: boolean;
  hidden?: false;
}

export const MainContainer = styled.div`
  max-width: 100%;
`;
export const FieldsContainer = styled.div<IFieldsContainer>`
  display: grid;
  grid-template-columns: ${props => (props.isLoading ? '1fr' : '1fr 1fr')};
  gap: ${props => props.theme.spacings.xs};

  .MuiTextField-root {
    width: 100%;
    label {
      z-index: 0;
    }
  }
  .text-field-custom-theme div.MuiFilledInput-root {
    border-width: 1px;
  }
  .common-select .select-field-custom-theme {
    div.MuiFilledInput-root {
      border-width: 1px;
    }
    label {
      z-index: 0;

      &.Mui-disabled {
        z-index: 1;
      }
    }
  }
`;

export const FieldContainer = styled.div<IFieldContainerProps>`
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
  position: relative;
  grid-column: ${props =>
    props.fieldName == IExpenseRefFields.value ? '2' : props.fieldName == IExpenseRefFields.currency ? '1' : '1 / 3'};
  grid-row-start: ${props =>
    props.fieldName == IExpenseRefFields.value || props.fieldName == IExpenseRefFields.currency ? '1' : 'auto'};

  .helperText {
    position: absolute;
  }
`;

export const ErrorDescription = styled(Typography)`
  color: ${props => props.theme.colors.feedback.error[40]};
  position: absolute;
`;
