import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

interface IProps {
  open: boolean;
  target: 'delete' | 'disable';
  isLoading: boolean;
  quantityOfItems: number;
  onActionClick: () => void;
  onClose: () => void;
}

export function ActionConfirmationModal({ target, open, isLoading, quantityOfItems, onActionClick, onClose }: IProps) {
  const t = useTranslate('delegation.configuration.feedbacks');
  const translatePathAmount = quantityOfItems > 1 ? 'plural' : 'singular';

  return (
    <DangerActionModal
      open={open}
      isLoading={isLoading}
      headerIconLabel={t('attention')}
      headerTitle={t(`${target}Modal.title.${translatePathAmount}`)}
      headerSubtitle={t(`${target}Modal.description.${translatePathAmount}`)}
      labelButtonAction={
        <SC.LabelButtonContent>
          <p>{t(`${target}Modal.confirm.${translatePathAmount}`)}</p>
          {target === 'delete' && <Icons name="IconTrash" fill="transparent" size={24} />}
        </SC.LabelButtonContent>
      }
      onActionClick={onActionClick}
      onCancelClick={onClose}
    />
  );
}
