import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  NoInfer,
  OperationVariables,
  TypedDocumentNode,
  useMutation as useMutationApolloClient,
} from '@apollo/client';

import { useHeaderDelegateOptions } from '../useHeaderDelegateOptions';

interface IMutationHookOptions<TData, TVariables, TContext, TCache extends ApolloCache<unknown>>
  extends MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache> {
  delegationAllowed?: boolean;
}

export function useMutation<
  TData = unknown,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: IMutationHookOptions<TData, TVariables, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  const { headers } = useHeaderDelegateOptions(options?.context?.['headers'], options?.delegationAllowed);

  return useMutationApolloClient(mutation, {
    ...options,
    context: {
      ...options?.context,
      headers,
    },
  });
}
