import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { SubscriptionConfirmationPage } from '@pages/SubscriptionConfirmation';
import { RouteObject } from 'react-router-dom';

import { Router } from '../Router';
import { RoutesGuardSubscription } from '../RoutesGuardSubscription';

export function SubscriptionRoutes(): RouteObject[] {
  return [
    {
      element: <RoutesGuardSubscription />,
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Assinatura" moduleName="Assinatura" />,
      children: [
        {
          path: Router.getSubscription(),
          element: <SubscriptionConfirmationPage />,
        },
      ],
    },
  ];
}
