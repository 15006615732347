import { useMemo } from 'react';
import { useTranslation } from '@locale/Translator';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';

import { DetailedValues, ReportInfosTable } from '../ReportInfosTable';
interface ReportAmountReceivableProps {
  currencyPrefix: string;
  valueToReceive: number;
  reimbursableValue: number;
  advancesValue: number;
  withdrawValue: number;
}

export const ReportAmountReceivable = ({
  currencyPrefix,
  valueToReceive,
  reimbursableValue,
  advancesValue,
  withdrawValue,
}: ReportAmountReceivableProps) => {
  const { t } = useTranslation();
  const { getValueWithCurrency } = useValueFormatter();
  currencyPrefix = currencyPrefix || 'R$';
  const empty: string = getValueWithCurrency({ value: 0, currencyPrefix });

  const detailedValues: DetailedValues[] = useMemo(() => {
    const detailed: DetailedValues[] = [];

    detailed.push({
      description: t('report.reimbursableExpenses'),
      value: reimbursableValue ? getValueWithCurrency({ value: reimbursableValue, currencyPrefix }) : empty,
    });

    if (withdrawValue)
      detailed.push({
        description: t('report.withdrawals'),
        value: withdrawValue ? `- ${getValueWithCurrency({ value: withdrawValue, currencyPrefix })}` : empty,
      });

    detailed.push({
      description: t('report.advances'),
      value: advancesValue ? `- ${getValueWithCurrency({ value: advancesValue, currencyPrefix })}` : empty,
    });

    return detailed;
  }, [reimbursableValue, withdrawValue, currencyPrefix]);

  return (
    <ReportInfosTable
      mainValueDescription={t('report.reimbursableValue')}
      mainValue={getValueWithCurrency({ value: valueToReceive || 0, currencyPrefix })}
      detailedValues={detailedValues}
    />
  );
};
