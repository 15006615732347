import { useState } from 'react';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { EDaysOfTheWeek } from '@shared/types';

export interface IDayFrequencyOption {
  label: string;
  value: number;
}

export function useDayFrequencyOptions() {
  const t = useTranslate('organisms.generalReportSettings.expirationDate.configurationModal.fields');
  const [daysOfTheWeekOptions] = useState<IDayFrequencyOption[]>(
    getOptions(Object.keys(EDaysOfTheWeek).splice(5, Object.keys(EDaysOfTheWeek)?.length)),
  );
  const [daysOfMonthOptions] = useState<IDayFrequencyOption[]>(getDaysOfTheMonthOptions());

  function getDaysOfTheMonthOptions(): IDayFrequencyOption[] {
    const options: IDayFrequencyOption[] = [];
    for (let day = 1; day <= 30; day++) {
      options.push({
        label: String(day),
        value: day,
      });
    }
    return options;
  }

  function getOptions(keys: string[]): IDayFrequencyOption[] {
    return keys.map((key, index) => ({
      label: t(key.toLowerCase()),
      value: index + 1,
    }));
  }

  return {
    daysOfTheWeekOptions,
    daysOfMonthOptions,
  };
}
