import { useHandleSubmitReport } from '@containers/Reports/components/organisms/ReportDetailsPreview/hooks/useHandleSubmitReport';
import { useReportActionsManager } from '@containers/Reports/components/organisms/ReportDetailsPreview/hooks/useReportsActionManager';
import { EReportStatus } from '@containers/Reports/context/types';
import { Button, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { useReportsPermissions } from '@shared/hooks/permissions/systemPermissions/useReportsPermissions';
import { useNavigate, useParams } from 'react-router-dom';

import { SubmitConfirmationModals } from '../SubmitConfirmationModals/SubmitConfirmationModals';
import * as SC from './styled';

interface ActionsFooterPageProps {
  reportStatus: EReportStatus;
}

export const ActionsFooterPage = ({ reportStatus }: ActionsFooterPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getReportActions } = useReportsPermissions();
  const { reopen: allowedToReopen, submit: allowedToSend } = getReportActions(reportStatus);
  const { id } = useParams<{ id: string }>();
  const {
    isLoadingMutate,
    reopenConfirmIsOpen,
    onClosePopup,
    handleReportStatusUpdate,
    confirmReportStatusUpdatePopup,
  } = useReportActionsManager({ isReportDetails: true, reportId: Number(id) });

  const submitReport = useHandleSubmitReport({ reportId: Number(id) });

  const showAction: boolean = allowedToReopen || allowedToSend;

  function onActionClick() {
    if (allowedToSend) {
      return submitReport.handleSubmit(false);
    }

    return handleReportStatusUpdate(EReportStatus.OPEN);
  }

  return (
    <>
      <SC.Container>
        <div>
          <LinkButton variant="default" onClick={() => navigate(-1)}>
            {t('pages.reportDetails.comeBack')}
          </LinkButton>
        </div>
        <SC.ActionsButtonsFooterContainer>
          {showAction && (
            <Button
              variant="primary"
              size="medium"
              type="submit"
              loading={isLoadingMutate || submitReport.isSubmitting}
              onClick={onActionClick}>
              <SC.ButtonContent>
                {t(`pages.reportDetails.${allowedToSend ? 'sendReport' : 'reopenReport'}`)}
              </SC.ButtonContent>
            </Button>
          )}
        </SC.ActionsButtonsFooterContainer>
      </SC.Container>
      <SC.ContainerModals>
        <DangerActionModal
          headerTitle={t(`organisms.reportDetailsPreview.feedback.reopenReport.singularForm.modalTitle`)}
          headerSubtitle={t(`organisms.reportDetailsPreview.feedback.reopenReport.singularForm.modalMsg`)}
          open={reopenConfirmIsOpen}
          isLoading={isLoadingMutate}
          onCloseClick={onClosePopup}
          onActionClick={confirmReportStatusUpdatePopup}
        />

        <SubmitConfirmationModals
          splittedReports={submitReport.splittedReports}
          submitAlertMessage={submitReport.submitAlertMessage}
          isLoading={submitReport.isSubmitting}
          onAlertConfirm={() => submitReport.handleSubmit(true)}
          onAlertClose={() => submitReport.onCancelAction()}
        />
      </SC.ContainerModals>
    </>
  );
};
