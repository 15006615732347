import styled from 'styled-components';

export const ActionsButtonsFooterContainer = styled.div`
  display: grid;
  gap: ${props => props.theme.spacings.xs};
  grid-template-columns: min-content min-content;

  .button-custom-theme-secondary.MuiPaper-root.MuiButtonBase-root.size-medium {
    height: 48px !important;
  }
`;

export const ButtonContent = styled.div`
  display: grid;
  gap: ${props => props.theme.spacings.xs4};
  grid-template-columns: 1fr 1fr;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DescriptionButton = styled.div`
  width: max-content;
`;
