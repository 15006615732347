import { useState } from 'react';
import { IconButtonArea } from '@atoms/IconButtonArea';
import { IReport } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { expensesAdvancesBaseURL } from '@shared/constant';
import { useDelegationAllowed } from '@shared/hooks/permissions/delegation/useDelegationAllowed';
import { useAdvancesPermissions } from '@shared/hooks/permissions/systemPermissions/useAdvancesPermissions';
import { useNavigate } from 'react-router-dom';

import { AttachAdvancesModal } from '../AttachAdvancesModal';
import * as SC from './styled';

interface IProps {
  linkedReport: {
    name: IReport['name'];
    id: IReport['id'];
    description: IReport['description'];
    status: IReport['status'];
  };
}

export function AdvancesEmptyAction({ linkedReport }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAttachModal, setShowAttachModal] = useState(false);
  const { edit: editingIsAllowed, link: linkingIsAllowed } = useAdvancesPermissions();
  const { delegationEnabled } = useDelegationAllowed();

  return (
    <SC.Container>
      {editingIsAllowed && !delegationEnabled && (
        <IconButtonArea
          icon="IconCurrencyDollar"
          onClick={() => navigate(`${expensesAdvancesBaseURL}/register`, { state: { linkedReport } })}>
          {t('pages.reportDetails.addAdvance')}
        </IconButtonArea>
      )}
      {linkingIsAllowed && (
        <IconButtonArea icon="IconFile" onClick={() => setShowAttachModal(true)}>
          {t('pages.reportDetails.attachAdvance')}
        </IconButtonArea>
      )}
      {showAttachModal && (
        <AttachAdvancesModal
          isOpen={showAttachModal}
          onClose={() => setShowAttachModal(false)}
          reportId={linkedReport?.id}
        />
      )}
    </SC.Container>
  );
}
