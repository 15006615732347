import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export interface DotProps {
  isActive: boolean;
}

export const ContainerNavigation = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerNavigationButtons = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs2};
`;

export const ContainerNavigationDots = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs3};
  margin: 0 auto;
`;

export const Dot = styled.div<DotProps>`
  border-radius: ${props => props.theme.borders.radius.circular};
  height: 8px;
  width: 8px;
  background-color: ${props => (props.isActive ? props.theme.colors.primary : props.theme.colors.secondary[95])};
`;

export const Slider = styled(Swiper)`
  margin: ${props => props.theme.spacings.xs} 0;
`;

export const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacings.s};
`;
