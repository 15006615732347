import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Backdrop = styled.div`
  background-color: ${props => props.theme.colors.neutral[60]};
  opacity: 0.8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
`;

export const DrawerContainer = styled.div`
  position: absolute;
  z-index: 10000;
  overflow: hidden;
  height: 1px;
`;

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
  width: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacings.xs} ${({ theme }) => theme.spacings.m};
  border-bottom: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: 0 ${({ theme }) => theme.spacings.m} ${({ theme }) => theme.spacings.m} ${({ theme }) => theme.spacings.m};
  overflow-y: auto;
`;

export const PolicyContainer = styled.div`
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.xs};
  align-items: center;
`;

export const PolicyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const PolicyTitleContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;

export const PolicyTitle = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 380px;
`;

export const ButtonLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[80]};
  width: 42px;
  height: 42px;
`;

export const PolicyUndocumentedText = styled(Typography)`
  display: flex;
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;

export const EmptyContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.xs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  justify-content: center;
  align-items: center;
`;

export const EmptyTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[60]};
`;
