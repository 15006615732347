import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export interface Container {
  isSelected: boolean;
}

export const Container = styled.div<Container>`
  width: 100%;
  position: relative;
`;

export const Image = styled.img`
  width: 150px;
  margin: 0 auto;
`;

export const NoOptionsImage = styled.img`
  object-fit: contain;
  height: 150px;
  width: 150px;
  margin: 0 auto ${props => props.theme.spacings.xs2} auto;
`;

export const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 600;
  display: flex;
  align-items: center;
  position: absolute;
  margin: ${({ theme }) => theme.spacings.xs5} 0 0 ${({ theme }) => theme.spacings.xs2};
`;

export const Icon = styled(Icons)`
  color: ${props => props.theme.colors.feedback.error[50]};
`;
