import { Skeleton } from '@mui/material';

import { EGeneralSettings } from './GeneralSettingsFields';
import * as SC from './styled';

export const GeneralSettingsSkeleton = () => (
  <SC.Container>
    {Object.values(EGeneralSettings).map((_, key) => (
      <div key={key}>
        <SC.ToggleContainer>
          <Skeleton variant="rounded" width={36} />
          <Skeleton variant="rounded" width={200} height={24} />
        </SC.ToggleContainer>
      </div>
    ))}
  </SC.Container>
);
