import { useMemo } from 'react';
import { DefaultContext } from '@apollo/client';
import { useDelegationAllowed } from '@shared/hooks/permissions/delegation/useDelegationAllowed';

export function useHeaderDelegateOptions(
  headers: DefaultContext['headers'] = {},
  delegationAllowedRequest: boolean = false,
) {
  const { delegationAllowed, userDelegateId } = useDelegationAllowed();
  const currencyHeaders = useMemo(
    () => getOptions(headers, delegationAllowedRequest),
    [headers, userDelegateId, delegationAllowed, delegationAllowedRequest],
  );

  function getOptions(headersData: DefaultContext['headers'], _delegationAllowedRequest: boolean) {
    const isInDelegateUser = userDelegateId && (delegationAllowed || _delegationAllowedRequest);
    const _headers = headersData || headers;

    if (isInDelegateUser) {
      _headers['user-delegation-id'] = userDelegateId;
    }

    return _headers;
  }

  return {
    headers: currencyHeaders,
    getOptions,
  };
}
