import { ReactNode } from 'react';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import {
  IExpensesAllowedActions,
  useExpensesPermissions,
} from '@shared/hooks/permissions/systemPermissions/useExpensesPermissions';

import { EExpenseType, IExpenses } from '../../../../context/types/expenses';
import { ExpenseDetailsByCategory } from '../ExpenseDetailsByCategory/ExpenseDetailsByCategory';
import { ExpenseGridActions } from '../ExpenseGridActions';
import { ExpenseReceipts } from '../ExpenseReceipts/ExpenseReceipts';
import * as SC from '../styled';
import { EAccessor } from './useHeaderGridColumns';

export type TGridRow = Record<EAccessor, ReactNode>;

export interface UseBodyGridRowsProps {
  expenses: IExpenses[];
  allowedActions?: IExpensesAllowedActions;
  selectedExpenses: IExpenses['id'][];
  showMultipleActions?: boolean;
  onSelectExpense?(id: IExpenses['id']): void;
  onDeleteExpense?(ids: IExpenses['id'][]): void;
  onUnlinkExpense?(ids: IExpenses['id'][]): void;
  onViewExpense?(id: IExpenses['id'], expenseType: EExpenseType): void;
  onEditExpense?(id: IExpenses['id'], expenseType: EExpenseType): void;
}

export const useBodyGridRows = ({
  expenses,
  selectedExpenses,
  showMultipleActions = true,
  onSelectExpense,
  onDeleteExpense,
  onViewExpense,
  onEditExpense,
  onUnlinkExpense,
}: UseBodyGridRowsProps): TGridRow[] => {
  const { getDateOnlyFormatter } = useDateFormatter();
  const { getValueWithCurrency } = useValueFormatter();
  const { getExpenseActions } = useExpensesPermissions();

  return expenses.map(expense => {
    const images = expense.attachments.length
      ? expense.attachments
      : expense?.route?.imagePath
      ? [{ path: expense.route.imagePath }]
      : [];

    return {
      rowClick: onViewExpense,
      selection: (showMultipleActions && (
        <Checkbox onChange={() => onSelectExpense(expense.id)} checked={selectedExpenses.includes(expense.id)} />
      )) || <></>,
      category: <ExpenseDetailsByCategory expense={expense} />,
      id: <SC.Description>{expense.referenceId}</SC.Description>,
      date: <SC.Description>{getDateOnlyFormatter(expense.expenseDate)}</SC.Description>,
      value: (
        <SC.Description>
          {getValueWithCurrency({ value: expense.value || 0, currencyPrefix: expense.currencyPrefix || 'R$' })}
        </SC.Description>
      ),
      place: <SC.Description>{expense.establishment?.place || '-'}</SC.Description>,
      receipt: (
        <SC.Description>
          <ExpenseReceipts attachments={images} attachmentsNumber={images.length} />
        </SC.Description>
      ),
      actions: (
        <ExpenseGridActions
          id={expense.id}
          expenseType={expense.type}
          onEdit={onEditExpense}
          onDelete={id => onDeleteExpense([id])}
          onView={onViewExpense}
          onUnlink={id => onUnlinkExpense([id])}
          allowedActions={getExpenseActions(expense?.type, expense?.report?.status)}
        />
      ),
    };
  });
};
