import { usePermissionsFlags } from '@flash-hros/auth-helper';

export function useCheckUnleashFlag() {
  const { flags } = usePermissionsFlags.getState();

  function checkFlag(flagName: string): boolean {
    return flags?.some(_flag => _flag.name === flagName) || false;
  }

  return checkFlag;
}
