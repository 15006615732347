import { Icons, Loader, Skeleton, Toggle, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

interface IProps {
  toggleKey?: string;
  disabled?: boolean;
  checked?: boolean;
  label?: string;
  isLoading?: boolean;
  toggleIsLoading?: boolean;
  tooltipText?: string;
  onChange(key: string, value: boolean): void;
}

export function ToggleSettings({
  disabled,
  checked = false,
  label,
  toggleKey,
  isLoading = false,
  toggleIsLoading = false,
  tooltipText,
  onChange,
}: IProps) {
  return !isLoading ? (
    <SC.Container>
      {toggleIsLoading ? (
        <SC.ContainerToggleLoading>
          <Loader size="extraSmall" variant="primary" />
        </SC.ContainerToggleLoading>
      ) : (
        <Toggle disabled={disabled} checked={checked} onChange={(_, value) => onChange(toggleKey, value)} />
      )}
      <SC.ToggleContent>
        {label && <SC.ToggleLabel variant="body3">{label}</SC.ToggleLabel>}
        {tooltipText && (
          <Tooltip arrow title={tooltipText} placement="top-start">
            <div>
              <Icons name="IconInfoCircle" size={16} fill="transparent" />
            </div>
          </Tooltip>
        )}
      </SC.ToggleContent>
    </SC.Container>
  ) : (
    <SC.Container>
      <Skeleton variant="rounded" width={36} height={20} />
      <Skeleton variant="rounded" width="100%" height={20} />
    </SC.Container>
  );
}
