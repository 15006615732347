import { useContext } from 'react';
import { useTranslation } from '@locale/Translator';
import { ImportingContext } from '@shared/contexts/BulkImportContext';

import { ImportInfoContainer, ImportInfoContainerProps } from '../../molecules/ImportInfoContainer';
import * as SC from './styled';

interface BuildImportDetailsProps {
  newImportAction(): void;
  page: ImportInfoContainerProps['page'];
}

export const BulkImportDetails = ({ newImportAction, page }: BuildImportDetailsProps) => {
  const { t } = useTranslation();
  const { state } = useContext(ImportingContext);

  return (
    <SC.Container>
      <SC.LeftContainer>
        <SC.SectionTitle variant="headline7">{t(`organisms.BulkImportDetails.title`)}</SC.SectionTitle>
        <SC.SectionSubtitle variant="body3">{t('organisms.BulkImportDetails.subTitle')}</SC.SectionSubtitle>
      </SC.LeftContainer>
      <ImportInfoContainer
        newImportAction={newImportAction}
        newImportLabel={t('organisms.BulkImportDetails.newImportLabel')}
        data={state.importingResponse}
        page={page}
      />
    </SC.Container>
  );
};
