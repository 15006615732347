import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import { ISummaryApprovalFlow } from '../../types/approvalFlow';
import { useTargetCustomApprovalFlow } from '../useTargetCustomApprovalFlow';

export const useCustomApprovalFlows = () => {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });
  const target = useTargetCustomApprovalFlow();

  const { data, error, isLoading } = useFetch<ISummaryApprovalFlow[]>(
    `v1/approvals/${target}`,
    '',
    undefined,
    undefined,
    onError,
  );

  return {
    error,
    isLoading: isLoading,
    approvalFlows: data ?? [],
  };
};
