import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
  overflow: hidden;
  width: 100%;
`;

export const DescriptionHeader = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[40]};
  white-space: nowrap;
  text-align: left;
`;

export const TheadTable = styled.thead`
  padding: ${({ theme }) => theme.spacings.xs};
  background: ${({ theme }) => theme.colors.neutral[95]};
`;

export const ThTable = styled.th`
  padding: ${({ theme }) => theme.spacings.xs};
`;

export const DescriptionBody = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[40]};
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
`;

export const TdTable = styled.td`
  padding: ${({ theme }) => theme.spacings.xs};
  border-bottom: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const IconGripVertical = styled(Icons)`
  cursor: grab;
  color: ${({ theme }) => theme.colors.neutral[70]}!important;
`;

export const EmptySpace = styled.div`
  height: 75px;
  width: 100%;
`;
