import { useCallback, useState } from 'react';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { useTranslation } from '@locale/Translator';
import { createFilterOptions } from '@mui/material';

import { Option } from '../Autocomplete';

export enum FixedOptions {
  FIXED_MESSAGE = 'fixedMessage',
}

export interface UseAutocompleteHelperProps {
  isLoading: boolean;
  options: Option[];
  value: unknown;
  hasFixedMessageOption: boolean;
  disabledFilter: boolean;
  selectedItem?: IEstablishment | Option;
}

export interface UseAutocompleteHelper {
  isEmpty: boolean;
  optionsCount: number;
  filterOptions: Promise<Option[]>;
  isOptionEqualToValue: boolean;

  getLabelOption(option: Option): string;
}

export function useAutocompleteHelper({
  isLoading,
  options,
  value,
  hasFixedMessageOption,
  disabledFilter,
  selectedItem,
}: UseAutocompleteHelperProps) {
  const { t } = useTranslation();
  const [optionsCount, setOptionsCount] = useState<number>();

  const _filterOptions = createFilterOptions({
    stringify: (option: Option) => option.label || option.caption,
    ignoreAccents: true,
    ignoreCase: true,
    matchFrom: 'any',
    trim: true,
  });

  const filterOptions = useCallback(
    (options, state) => {
      const results = disabledFilter ? options : _filterOptions(options, state);
      setOptionsCount(results?.length);

      if (results?.length > 0 && hasFixedMessageOption) {
        results.push({
          value: FixedOptions.FIXED_MESSAGE,
        });
      }

      return results;
    },
    [options],
  );

  function getLabelOption(option: Option): string {
    const foundOptions = options.find(o => o.value === value || o.value === option?.value);
    const label: string = isLoading
      ? t(`organisms.establishments.loading`)
      : foundOptions?.label || (foundOptions?.value && String(foundOptions?.value)) || '';

    return Boolean(label) ? label : selectedItem && 'place' in selectedItem ? selectedItem?.place : '';
  }

  function isOptionEqualToValue(option: Option, value: unknown): boolean {
    return option?.value === value || option === value;
  }

  return {
    optionsCount,
    getLabelOption,
    filterOptions,
    isOptionEqualToValue,
  };
}
