import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from 'react-i18next';

import * as SC from './styled';
export function NewFeatureTag({ title }: { title: string }) {
  const { t } = useTranslation();
  return (
    <SC.Box>
      {title}
      <SC.StyledTag>
        <Icons name="IconSparkles" size={16} />
        <Typography variant="caption" weight={600}>
          {t('molecules.newFeatureTag.new')}
        </Typography>
      </SC.StyledTag>
    </SC.Box>
  );
}
