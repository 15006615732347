export class Router {
  // Common
  static serializeParams(params: Record<string, string>) {
    return !params ? '' : `?${Object.keys(params)[0]}=${Object.values(params)[0]}`;
  }
  static getBaseName(path?: string) {
    return `/expenseManagement${path ? `/${path}` : ''}`;
  }
  static getSettings() {
    return this.getBaseName('settings');
  }

  // Advances
  static getAdvances() {
    return this.getBaseName('advances');
  }
  static getAdvancesDetails(id: string | number) {
    return `${this.getAdvances()}/details/${id}`;
  }
  static getAdvancesReportsDetails(id: string | number) {
    return `${this.getAdvances()}/reports/details/${id}`;
  }
  static getAdvancesRegister(id?: string | number) {
    return `${this.getAdvances()}/register${id ? `/${id}` : ''}`;
  }
  static getAdvancesExpensesDetails(id: string | number) {
    return `${this.getAdvances()}/expenses/details/${id}`;
  }
  static getAdvancesExpenseRegister(id: string | number) {
    return `${this.getAdvances()}/expenses/register/${id}`;
  }

  // Analytics
  static getAnalytics() {
    return this.getBaseName('analytics');
  }

  // Accounting
  static getAccounting() {
    return this.getBaseName('accounting');
  }
  static getAccountingReports() {
    return `${this.getAccounting()}/reports`;
  }
  static getAccountability() {
    return `${this.getAccounting()}/pending`;
  }
  static getAccountingReportDetails(id: string | number) {
    return `${this.getAccountingReports()}/details/${id}`;
  }
  static getAccountingExpenseDetails(id: string | number) {
    return `${this.getAccounting()}/expenses/details/${id}`;
  }
  static getAccountingOriginDestinationDetails(id: string | number) {
    return `${this.getAccounting()}/expenses/details/originDestination/${id}`;
  }
  static getAccountingOdometerDetails(id: string | number) {
    return `${this.getAccounting()}/expenses/details/odometer/${id}`;
  }
  static getAccountingAdvancedDetails(id: string | number) {
    return `${this.getAccounting()}/advances/details/${id}`;
  }
  static getAccountingAdvances() {
    return `${this.getAccounting()}/advances`;
  }

  // Approvals
  static getApprovals() {
    return this.getBaseName('approvals');
  }
  static getApprovalsReportDetails(id: string | number) {
    return `${this.getApprovals()}/reports/details/${id}`;
  }
  static getApprovalsExpenseDetails(id: string | number) {
    return `${this.getApprovals()}/expenses/details/${id}`;
  }
  static getApprovalsOriginDestinationDetails(id: string | number) {
    return `${this.getApprovals()}/expenses/details/originDestination/${id}`;
  }
  static getApprovalsOdometerDetails(id: string | number) {
    return `${this.getApprovals()}/expenses/details/odometer/${id}`;
  }
  static getApprovalsAdvancedDetails(id: string | number) {
    return `${this.getApprovals()}/advances/details/${id}`;
  }

  // Clients
  static getClients() {
    return `${this.getSettings()}/clients`;
  }
  static getClientsRegister(id?: string | number) {
    return `${this.getClients()}/register${id ? `/${id}` : ''}`;
  }
  static getClientsRegisterFile() {
    return `${this.getClientsRegister('file')}`;
  }
  static getClientsRegisterFileDetails() {
    return `${this.getClientsRegister('file/details')}`;
  }

  // Cost Centers
  static getCostCenters() {
    return `${this.getSettings()}/costCenters`;
  }
  static getCostCentersRegister(id?: string | number) {
    return `${this.getCostCenters()}/register${id ? `/${id}` : ''}`;
  }
  static getCostCentersRegisterFile() {
    return `${this.getCostCentersRegister('file')}`;
  }
  static getCostCentersRegisterFileDetails() {
    return `${this.getCostCentersRegister('file/details')}`;
  }

  // Approval flows
  static getApprovalFlows() {
    return this.getBaseName('approvalFlows');
  }
  static getApprovalFlowsReports() {
    return `${this.getApprovalFlows()}/reports`;
  }
  static getApprovalFlowsReportsRegister(id?: string | number) {
    return `${this.getApprovalFlowsReports()}/register${id ? `/${id}` : ''}`;
  }
  static getApprovalFlowsAdvances() {
    return `${this.getApprovalFlows()}/advances`;
  }
  static getApprovalFlowsAdvancesRegister(id?: string | number) {
    return `${this.getApprovalFlowsAdvances()}/register${id ? `/${id}` : ''}`;
  }

  // Customizations
  static getCustomizations() {
    return this.getBaseName('customizations');
  }

  // Delegation
  static getDelegation() {
    return this.getBaseName(`delegationAction`);
  }

  // Expenses
  static getExpenses() {
    return this.getBaseName(`expenses`);
  }
  static getExpensesDetails(id: string | number) {
    return `${this.getExpenses()}/details/${id}`;
  }
  static getOriginDestinationDetails(id: string | number) {
    return `${this.getExpenses()}/details/originDestination/${id}`;
  }
  static getOdometerDetails(id: string | number) {
    return `${this.getExpenses()}/details/odometer/${id}`;
  }
  static getExpenseRegister(id?: string | number) {
    return `${this.getExpenses()}/register${id ? `/${id}` : ''}`;
  }
  static getOdometerRegister(id?: string | number) {
    return `${this.getExpenseRegister()}/odometer${id ? `/${id}` : ''}`;
  }
  static getOriginAdnDestinationRegister(id?: string | number) {
    return `${this.getExpenseRegister()}/originDestination${id ? `/${id}` : ''}`;
  }
  static getGpsRegister(id: string | number) {
    return `${this.getExpenseRegister()}/gps/${id}`;
  }

  // Policies
  static getPolicyRegister(id?: string | number) {
    return `${this.getCustomizations()}/policy/register${id ? `/${id}` : ''}`;
  }

  // Clients
  static getProjects() {
    return `${this.getSettings()}/projects`;
  }
  static getProjectRegister(id?: string | number) {
    return `${this.getProjects()}/register${id ? `/${id}` : ''}`;
  }
  static getProjectRegisterFile() {
    return `${this.getProjectRegister('file')}`;
  }
  static getProjectRegisterFileDetails() {
    return `${this.getProjectRegister('file/details')}`;
  }

  // Reports
  static getReports() {
    return `${this.getExpenses()}/reports`;
  }
  static getReportDetails(id: string | number) {
    return `${this.getReports()}/details/${id}`;
  }

  // Subsidiaries
  static getSubsidiaries() {
    return this.getBaseName('subsidiaries');
  }
  static getSubsidiariesRegister(id?: string | number) {
    return `${this.getSubsidiaries()}/register${id ? `/${id}` : ''}`;
  }

  // Subscription
  static getSubscription() {
    return this.getBaseName('subscription');
  }
}
