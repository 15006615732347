import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.m};
`;

export const AdvanceIdContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs3};
  align-items: center;
`;

export const OccurrenceContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs4};
  align-items: center;
`;

export const AdvanceInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Occurrence = styled(Typography)`
  color: ${props => props.theme.colors.feedback.error[40]};
`;
