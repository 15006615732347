import styled from 'styled-components';

export const ContainerPopover = styled.div`
  padding: ${({ theme }) => theme.spacings.xs3};
`;

export const ItemMenu = styled.div`
  padding: ${({ theme }) => theme.spacings.xs2};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[95]};
  }
`;
