import { useState } from 'react';
import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import { IConfigurationPaymentMethod } from '@containers/Customizations/context/types/paymentMethodTypes';
import { useCreatePaymentMethod } from '@containers/Customizations/hooks/PaymentMethods/rest/useCreatePaymentMethod';
import { useUpdatePaymentMethod } from '@containers/Customizations/hooks/PaymentMethods/rest/useUpdatePaymentMethod';
import { BoxAdornment, TextField, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { flashCardPaymentMethodExternalCode } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { PaymentMethodConfirmationActionsModal } from '../PaymentMethodConfirmationActionsModal/PaymentMethodConfirmationActionsModal';
import * as SC from './styled';

type TProps = {
  open: boolean;
  isDefaultOption?: boolean;
  paymentMethod?: IConfigurationPaymentMethod;
  onCloseModal: () => void;
};

export function PaymentMethodModal({ open = false, paymentMethod, isDefaultOption, onCloseModal }: TProps) {
  const t = useTranslate('organisms.customizationsPaymentMethods.modal.paymentMethod');
  const [actionType] = useState<'add' | 'edit'>(paymentMethod ? 'edit' : 'add');
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<IConfigurationPaymentMethod>(getSerializeData());
  const [isFlashCard] = useState<boolean>(
    paymentMethod?.externalCode?.toUpperCase() === flashCardPaymentMethodExternalCode,
  );

  const { getCreatePaymentMethod, isLoading: isLoadingCreate } = useCreatePaymentMethod();
  const { getUpdatePaymentMethod, isLoading: isLoadingUpdate } = useUpdatePaymentMethod();
  const [refundableIsLoading, setRefundableIsLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [selectedRefundableValue, setSelectedRefundableValue] = useState<boolean>();

  async function onActionClick() {
    let success: boolean = false;
    if (actionType === 'add') {
      const response = await getCreatePaymentMethod(currentPaymentMethod);
      success = response;
    } else {
      const response = await getUpdatePaymentMethod(currentPaymentMethod);
      success = response;
    }

    success && onCloseModal();
  }

  function getSerializeData() {
    const _paymentMethod = { ...paymentMethod };
    return _paymentMethod;
  }

  function onPaymentMethodChange(key: string, value: boolean | string | number) {
    const _paymentMethod = { ...currentPaymentMethod };
    _paymentMethod[key] = value;

    setSelectedRefundableValue(null);
    setCurrentPaymentMethod(_paymentMethod);
  }

  async function showRefundableConfirmationModal(value: boolean) {
    setSelectedRefundableValue(value);
    setShowConfirmationModal(true);
  }

  function onConfirmationRefundable(value?: boolean) {
    setShowConfirmationModal(false);
    onPaymentMethodChange('refundable', value);
  }

  function fieldsIsValid() {
    if (!isDefaultOption) {
      return currentPaymentMethod?.externalCode?.length && currentPaymentMethod?.name?.length;
    }
    return true;
  }

  return (
    <DefaultModal
      open={open}
      actionDisabled={!fieldsIsValid()}
      labelButtonAction={t(`actionButton.${actionType}`)}
      headerTitle={t(`title.${actionType}`)}
      onActionClick={onActionClick}
      isActionLoading={isLoadingCreate || isLoadingUpdate}
      showTooltipButtonAction={!fieldsIsValid()}
      tooltipButtonAction={t('fields.validationMessage')}
      onCancelClick={onCloseModal}>
      <SC.Container>
        <Tooltip arrow title={isDefaultOption ? t('fields.tooltip.codeFiled') : ''} placement="top-start">
          <div>
            <TextField
              label={t(`fields.code.label`)}
              helperText={t(`fields.code.helperText`)}
              fullWidth
              disabled={isDefaultOption}
              value={currentPaymentMethod?.externalCode?.toUpperCase() ?? ''}
              onChange={data => onPaymentMethodChange('externalCode', data?.target?.value?.toUpperCase())}
            />
          </div>
        </Tooltip>
        <Tooltip arrow title={isDefaultOption ? t('fields.tooltip.paymentMethodNameField') : ''} placement="top-start">
          <div>
            <TextField
              label={t(`fields.paymentMethodName`)}
              fullWidth
              value={currentPaymentMethod?.name ?? ''}
              disabled={isDefaultOption}
              onChange={data => onPaymentMethodChange('name', data?.target?.value)}
            />
          </div>
        </Tooltip>
        {actionType === 'edit' && (
          <>
            <SC.ContainerActive>
              <SC.TitleActive variant="body3">{t(`fields.settings.title`)}</SC.TitleActive>
              <BoxAdornment
                title={t(`fields.settings.label`)}
                description={t(`fields.settings.caption`)}
                leftAdornment={
                  <ToggleSettings
                    disabled={false}
                    checked={currentPaymentMethod?.active ?? false}
                    toggleKey={'active'}
                    onChange={onPaymentMethodChange}
                  />
                }
              />
            </SC.ContainerActive>
            <ToggleSettings
              disabled={isFlashCard}
              checked={currentPaymentMethod?.refundable}
              toggleKey={'refundable'}
              label={t(`fields.refundablePaymentMethod.label`)}
              tooltipText={t(`fields.refundablePaymentMethod.tooltip`)}
              onChange={(_, value) => showRefundableConfirmationModal(value)}
              toggleIsLoading={refundableIsLoading}
            />
            <ToggleSettings
              disabled={false}
              checked={currentPaymentMethod?.mileage ?? false}
              toggleKey={'mileage'}
              label={t(`fields.allowUseOnMileageExpenses`)}
              onChange={onPaymentMethodChange}
            />
          </>
        )}
      </SC.Container>
      {showConfirmationModal && (
        <PaymentMethodConfirmationActionsModal
          open={showConfirmationModal}
          confirmationType={'refundable'}
          customMessage={t('feedbacks.refundable.confirmationMessage')}
          onActionClick={() => onConfirmationRefundable(selectedRefundableValue)}
          onClose={() => {
            setShowConfirmationModal(false);
            setRefundableIsLoading(false);
          }}
        />
      )}
    </DefaultModal>
  );
}
