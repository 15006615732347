import { gql } from '@apollo/client';

export const getPoliciesQuery = gql`
  query GetPolicies {
    getPolicies {
      policies {
        id
        enabled
        description
        name
      }
      total
    }
  }
`;

export const getCategoryLimitByPolicyIdQuery = gql`
  query getCategoryLimits($policyId: Int!) {
    getCategoryLimits(policyId: $policyId) {
      categoryLimitPolicySettings {
        active
        policyId
        currency
        receiptRequired
        receiptRequired
        allowValueGreaterThanLimit
        commentRequired
        criterion
        value
        minimumInvoiceProofValue
        categoryLimitsPolicy {
          policyCategoryId
          categoryDescription
        }
        limitPolicyId
      }
    }
  }
`;

export const addCategoryLimitsMutation = gql`
  mutation addCategoryLimits($newCategoryLimitPolicySetting: NewCategoryLimitPolicySettingInput!) {
    addCategoryLimits(newCategoryLimitPolicySetting: $newCategoryLimitPolicySetting)
  }
`;

export const updateCategoryLimitsMutation = gql`
  mutation updateCategoryLimits($updateLimitPolicySetting: CategoryLimitPolicySettingInput!) {
    updateCategoryLimits(UpdateLimitPolicySetting: $updateLimitPolicySetting)
  }
`;

export const activateDeactivateCategoryLimitsMutation = gql`
  mutation activateDeactivateCategoryLimits($policyId: Int!, $policyLimitId: [Int!]!, $active: Boolean!) {
    activateDeactivateCategoryLimits(policyId: $policyId, policyLimitId: $policyLimitId, active: $active) {
      categoryLimitPolicySettings {
        active
      }
    }
  }
`;

export const deleteCategoryLimitByPolicyLimitIdMutation = gql`
  mutation deleteCategoryLimitByPolicyLimitId($policyLimitId: Int!) {
    deleteCategoryLimitByPolicyLimitId(policyLimitId: $policyLimitId) {
      id
      message
      error
    }
  }
`;

export const deleteCategoryLimitListMutation = gql`
  mutation deleteCategoryLimitList($policyLimitIds: [Int!]!) {
    deleteCategoryLimitList(policyLimitIds: $policyLimitIds) {
      id
      message
      error
    }
  }
`;

export const getMileageByPolicyId = gql`
  query getMileageByPolicyId($policyId: Int!) {
    getPolicyMileageByPolicyId(policyId: $policyId) {
      id
      policyId
      enableSourceDestiny
      enableOdometer
      enableGps
      enablePinGps
      enableRegisteredLocations
      enableMultipleLocations
      distanceUnitType
      policyMileageRatesSettings {
        id
        rate
        startDate
      }
    }
  }
`;

export const updateMileageSettings = gql`
  mutation UpdatePolicyMileage(
    $policyId: Int!
    $policyMileageId: Int!
    $policyMileageSettingsInput: PolicyMileageSettingsInput!
  ) {
    updatePolicyMileage(
      policyId: $policyId
      policyMileageId: $policyMileageId
      policyMileageSettingsInput: $policyMileageSettingsInput
    ) {
      id
    }
  }
`;
