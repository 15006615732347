import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { getPlaceDetails } from '../context/queries/establishment';
import { IGetPlaceDetailsRequest, IGetPlaceDetailsResponse } from '../context/types/establishments';

export const usePlaceDetails = (placeId: string) => {
  const [getPlaceInfos, { loading, data, error }] = useLazyQuery<IGetPlaceDetailsResponse, IGetPlaceDetailsRequest>(
    getPlaceDetails,
    {
      variables: {
        placeId,
      },
    },
  );

  return {
    getPlaceInfos,
    placeDetails: data?.getPlaceDetails,
    isLoading: loading,
    error,
  };
};
