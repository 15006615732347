import { useContext } from 'react';
import { fetcher } from '@services/utils/fetcherClient';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { downloadFile } from '@shared/helpers/files';

interface HookReturn {
  isLoading: boolean;
  /** 
        @param {String} fileName Name of the file to be downloaded myFile.xlsx
        @param {String} path api path to be fetched
    */
  download(fileName: string, path: string): Promise<void>;
  error?: Error;
}

export const useDownloadFile = (): HookReturn => {
  const { companyId, accessToken } = useContext(SessionContext);
  async function download(fileName: string, path: string) {
    const options = {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    };
    const file = await fetcher(path, { companyId, accessToken }, options);
    downloadFile(file, fileName);
  }

  return {
    isLoading: false,
    download,
    error: null,
  };
};
