import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { FooterPage } from '@molecules/FooterPage';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

interface IProps {
  isLoading: boolean;
}

export function ProjectRegisterFooterPage({ isLoading }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <FooterPage>
        <div>
          <LinkButton variant="default" onClick={() => navigate(-1)}>
            {t('pages.projectRegister.actions.cancel')}
          </LinkButton>
        </div>
        <SC.ActionsButtonsFooterContainer>
          <Button variant="primary" size="medium" type="submit" loading={isLoading}>
            {t('pages.projectRegister.actions.continue')}
            <Icons name="IconArrowRight" color="#fff" fill="transparent" />
          </Button>
        </SC.ActionsButtonsFooterContainer>
      </FooterPage>
    </>
  );
}
