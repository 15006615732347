import { useMutate } from './useMutate';

interface HookReturn {
  isLoading: boolean;
  /**
        @param {String} data data to be attached to request form data
        @param {String} path api path to be fetched
        @param {@callback} onError function to handleError
    */
  upload(file: File, onError): Promise<void>;
  error?: Error;
  // TODO: Update this type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export const useUploadFile = (path: string, ignorePath?: boolean): HookReturn => {
  const { mutation } = useMutate(path);
  async function upload(file: File, onError) {
    const formData = new FormData();

    formData.append('file', file);
    try {
      await mutation.trigger(
        {
          path: ignorePath ? '' : 'bulk-import',
          options: {
            method: 'POST',
            body: formData,
            headers: {
              isFile: 'true',
            },
          },
        },
        {
          revalidate: false,
          onError: onError,
        },
      );
    } catch (e) {
      throw e;
    }
  }

  return {
    isLoading: mutation.isMutating,
    upload,
    data: mutation.data,
    error: null,
  };
};
