import { ApolloError, LazyQueryExecFunction } from '@apollo/client';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { getAnalyticsFilterFields, getFiltersOptions } from '../context/queries/filters';
import {
  AnalyticsFilterField,
  EAnalyticsType,
  IAnalyticsFilterFieldOptionsRequest,
  IAnalyticsFilterFieldOptionsResponse,
  IAnalyticsFilterFieldsRequest,
  IAnalyticsFilterFieldsResponse,
} from '../context/types/filters';

interface IUserFilters {
  filterFields: {
    getFilterFields: LazyQueryExecFunction<IAnalyticsFilterFieldsResponse, IAnalyticsFilterFieldsRequest>;
    data: IAnalyticsFilterFieldsResponse['getAnalyticsFilterFields'];
    isLoading: boolean;
    error: ApolloError;
  };
  filterFieldOptions: {
    getFilterFieldOptions: LazyQueryExecFunction<IAnalyticsFilterFieldOptionsResponse, unknown>;
    data: IAnalyticsFilterFieldOptionsResponse['data'];
    isLoading: boolean;
    error: ApolloError;
  };
  isLoading: boolean;
}

export function useFilters(type: EAnalyticsType, currentFields: AnalyticsFilterField[]): IUserFilters {
  const [getFilterFields, { loading: filterFieldsIsLoading, data: filterFields, error: filterFieldsError }] =
    useLazyQuery<IAnalyticsFilterFieldsResponse, IAnalyticsFilterFieldsRequest>(getAnalyticsFilterFields);
  const [
    getFilterFieldOptions,
    { loading: filterFieldOptionsIsLoading, data: filterFieldOptions, error: filterFieldOptionsError },
  ] = useLazyQuery<IAnalyticsFilterFieldOptionsResponse, IAnalyticsFilterFieldOptionsRequest>(
    getFiltersOptions(type, currentFields),
  );

  return {
    filterFields: {
      getFilterFields,
      data: filterFields?.getAnalyticsFilterFields,
      isLoading: filterFieldsIsLoading,
      error: filterFieldsError,
    },
    filterFieldOptions: {
      getFilterFieldOptions,
      data: filterFieldOptions?.data,
      isLoading: filterFieldOptionsIsLoading,
      error: filterFieldOptionsError,
    },
    isLoading: filterFieldOptionsIsLoading || filterFieldsIsLoading,
  };
}
