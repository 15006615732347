import { useMemo } from 'react';
import { useReportApprovalSettings } from '@containers/Financial/hooks/dataSources/useReportApprovalSettings';

import { useLicenseFeatures } from './permissions/licenseFeatures/useLicenseFeatures';

export const useIntegrations = () => {
  const { checkLicenseFeature, features } = useLicenseFeatures();
  const { hasFinancialIntegration } = useReportApprovalSettings();

  const hasIntegration = useMemo(
    () => checkLicenseFeature('INTERMEDIATE_INTEGRATIONS') && hasFinancialIntegration,
    [features?.toString?.(), hasFinancialIntegration],
  );

  return {
    hasFinancialIntegration: hasIntegration,
  };
};
