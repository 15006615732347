import { useEffect, useState } from 'react';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useDebounce } from 'use-debounce';

import { Container } from '../styled';
import * as SC from './styled';

interface IProps {
  prefixValue: string;
  startValue: number;
  isLoading: boolean;
  handleChange(key: string, data: string | number): void;
}

export function ReportSettingsCode({ startValue, prefixValue, isLoading, handleChange }: IProps) {
  const t = useTranslate('organisms.generalReportSettings');
  const [textInputFilterChange, setTextInputFilterChange] = useState<{ settingKey: string; value: number | string }>();
  const [settings, setSettingsChange] = useState<{ startValue: number; prefixValue: string }>();
  const [inputValueChange] = useDebounce(textInputFilterChange, 500);

  useEffect(() => {
    inputValueChange !== undefined && handleChange(inputValueChange.settingKey, inputValueChange.value);
  }, [inputValueChange]);

  useEffect(() => {
    setSettingsChange({ startValue, prefixValue });
  }, [startValue, prefixValue]);

  async function onChangeSettingTextValue(settingKey: string, value: number | string) {
    settings[settingKey] = value;
    setSettingsChange(settings);
    setTextInputFilterChange({ settingKey, value });
  }

  return (
    <Container>
      <SC.Header>
        <SC.Title variant="headline8">{t('codeTitle')}</SC.Title>
        <SC.Caption variant="caption">{t('codeCaption')}</SC.Caption>
      </SC.Header>
      <SC.Body>
        {isLoading || !settings ? (
          <>
            <Skeleton variant="rounded" width={'100%'} height={60} />
            <Skeleton variant="rounded" width={'100%'} height={60} />
          </>
        ) : (
          <>
            <SC.Field
              value={settings.prefixValue}
              defaultValue={settings.prefixValue}
              label={t('prefixValue')}
              onChange={e => onChangeSettingTextValue('prefixValue', e?.target?.value)}
            />
            <SC.Field
              type="number"
              value={settings.startValue}
              defaultValue={settings.startValue}
              label={t('startValue')}
              onChange={e => onChangeSettingTextValue('startValue', parseInt(e?.target?.value))}
            />
          </>
        )}
      </SC.Body>
    </Container>
  );
}
