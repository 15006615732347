import { useEffect, useState } from 'react';
import { ExpenseRegisterForm } from '@containers/Expenses/components/organisms/ExpenseRegisterForm';
import { LaunchMileageExpensePreview } from '@containers/Expenses/components/organisms/LaunchMileageExpensePreview/LaunchMileageExpensePreview';
import { EExpenseType, IExpenseInput } from '@containers/Expenses/context/types';
import { useExpenseByIdForm } from '@containers/Expenses/hooks/useExpenseByIdForm';
import { useTranslation } from '@locale/Translator';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { ExpenseRegisterFooterPage } from './ExpenseRegisterFooterPage/ExpenseRegisterFooterPage';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useCheckExpense } from './hooks/useCheckExpense';
import { useDefaultValues } from './hooks/useDefaultValues';
import { useExpenseRegisterPage } from './hooks/useExpenseRegisterPage';

export const ExpenseRegister = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const expenseType = useCheckExpenseType();
  const defaultValues = useDefaultValues();
  const methods = useForm<IExpenseInput>({
    defaultValues: { ...defaultValues },
  });
  const { expenseId, hasExpense } = useCheckExpense();
  const [reportSelected, setReportSelected] = useState(null);
  const { getExpenseById, expense, isLoading: expenseIsLoading } = useExpenseByIdForm(methods);
  const reportId: number =
    reportSelected?.id || expense?.report?.id || methods.getValues('reportId') || location?.state?.report?.id;
  const { isLoading, onContinueClick } = useExpenseRegisterPage(reportId, expense);
  const breadcrumbItems = useBreadcrumbs({ isEdit: hasExpense, expenseType });

  useEffect(() => {
    hasExpense && getExpenseById(expenseId);
  }, [hasExpense]);

  useTrackingScreen('expense_form_screen', {
    expenseType,
    ...(Boolean(expenseId) && {
      expenseId: String(expenseId),
      reportId: String(reportId),
    }),
  });

  return (
    <BasePage
      headerPage={{
        title: hasExpense ? t('pages.expenseRegister.editExpense') : t('pages.expenseRegister.newExpense'),
        faqType: 'expenses',
        breadcrumbItems,
        showDelegateInfo: true,
      }}
      footerPage={
        <ExpenseRegisterFooterPage
          methods={methods}
          hasExpense={hasExpense}
          isLoading={isLoading || expenseIsLoading}
          onContinueClick={onContinueClick}
        />
      }>
      <FormProvider {...methods}>
        {expenseType == EExpenseType.MANUAL ? (
          <ExpenseRegisterForm
            expenseType={EExpenseType.MANUAL}
            expense={expense}
            expenseIsLoading={expenseIsLoading}
            onSubmitClick={onContinueClick}
            onSelectReportClick={data => setReportSelected(data)}
          />
        ) : (
          <LaunchMileageExpensePreview
            expense={expense}
            expenseIsLoading={expenseIsLoading}
            onSubmitClick={onContinueClick}
            onSelectReportClick={data => setReportSelected(data)}
          />
        )}
      </FormProvider>
    </BasePage>
  );
};
