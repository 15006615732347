import { ApprovalsPreview } from '@containers/Approvals/components/organisms/ApprovalsPreview';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTranslation } from 'react-i18next';

export const Approvals = () => {
  const { t } = useTranslation();

  return (
    <BasePage
      headerPage={{
        title: t('pages.approvals.title'),
      }}>
      <ApprovalsPreview />
    </BasePage>
  );
};
