import { Checkbox as FlamingoCheckbox, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Title = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 20px;
`;

export const Caption = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 16px;
`;

export const CheckContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`;

export const Checkbox = styled(FlamingoCheckbox)`
  margin-right: ${({ theme }) => theme.spacings.m};
`;

export const CheckboxLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 600;
`;

export const SectionContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.s};
  margin-top: ${({ theme }) => theme.spacings.s};
`;

export const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700;
`;

export const SectionCaption = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacings.xs5};
  margin-bottom: ${({ theme }) => theme.spacings.xs3};
`;

export const ToggleContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.s};
  margin-top: ${({ theme }) => theme.spacings.xs5};
`;
