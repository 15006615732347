import { Drawer } from '@atoms/Drawer';
import { Accordion } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface AccordionProps {
  active: boolean;
}

export const DrawerComponent = styled(Drawer)``;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
  height: 100%;
  padding: ${props => props.theme.spacings.m} ${props => props.theme.spacings.l};

  .rdp-table {
    max-width: unset;
    width: 100%;
  }
  .rdp-months {
    width: 100%;

    .rdp-month {
      width: 100%;

      .rdp-caption {
        width: 100%;
      }
    }
  }

  .SliderFilter {
    width: 100%;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  height: 100%;
`;

export const Backdrop = styled.div`
  background-color: ${props => props.theme.colors.neutral[60]};
  opacity: 0.8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
`;

export const AccordionComponent = styled(Accordion)<AccordionProps>`
  border-color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.neutral[90])};
`;

export const AccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 250px);
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button:last-child {
    width: 369px;
  }
`;
