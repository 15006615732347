import { ILimitGrid } from '@containers/Customizations/context/types/limits';
import { IRowsPerPageOption } from '@molecules/GridPagination';
import { useRowsPerPageOptions } from '@molecules/GridPagination/kooks/useRowsPerPageOptions';
import { skeletonPrintGridRows } from '@shared/constant';

import { ActionsMultipleLimitsSelected } from './ActionsMultipleLimitsSelected';
import { useBodyGridRows } from './hooks/useBodyGridRow';
import { useBodyGridRowSkeleton } from './hooks/useBodyGridRowSkeleton';
import { useHeaderGridColumns } from './hooks/useHeaderGridColumns';
import { useHeaderGridColumnsSkeleton } from './hooks/useHeaderGridColumnsSkeleton';
import * as SC from './styled';

export interface IGridPagination {
  totalItems: number;
  rowsPerPage?: number;
  selectedPage: number;
  rowsPerPageOptions?: IRowsPerPageOption[];
  onPageClick(page: number): void;
  onRowsPerPageClick(itemsPerPage: number): void;
}

export interface LimitsGridProps {
  limits: ILimitGrid[];
  isLoading: boolean;
  pagination?: IGridPagination;
  selectedItems: ILimitGrid['id'][];
  isSelectedAll: boolean;
  isEnableSelectedItems: boolean;
  allowedActions: boolean;
  onEditClick?(limit: ILimitGrid): void;
  onDisableClick(id: ILimitGrid['id'][], active: boolean): void;
  onDeleteClick(limitsSelected: ILimitGrid['id'][]): void;
  onSelectAllClick(): void;
  onSelectItemClick(id: ILimitGrid['id']): void;
}

export const LimitsGrid = ({
  limits,
  isLoading,
  pagination,
  selectedItems,
  isSelectedAll,
  isEnableSelectedItems,
  allowedActions,
  onSelectAllClick,
  onSelectItemClick,
  onEditClick,
  onDisableClick,
  onDeleteClick,
}: LimitsGridProps) => {
  const headerGridColumns = useHeaderGridColumns({
    onSelectAllClick,
    isSelectedAll,
  });
  const bodyGridRows = useBodyGridRows({
    limits,
    selectedItems,
    allowedActions,
    onSelectItemClick,
    onEditClick,
    onDisableClick,
    onDeleteClick,
  });
  const bodyGridRowSkeleton = useBodyGridRowSkeleton({ rowsPerPage: pagination?.rowsPerPage || skeletonPrintGridRows });
  const headerGridColumnsSkeleton = useHeaderGridColumnsSkeleton();
  const { rowsPerPageOptions } = useRowsPerPageOptions({});

  return (
    <SC.Container>
      <SC.GridContainer>
        {Boolean(selectedItems?.length) && allowedActions && (
          <ActionsMultipleLimitsSelected
            selectedItems={selectedItems}
            totalItems={limits?.length}
            onDisableClick={onDisableClick}
            onDeleteClick={onDeleteClick}
            enableMultiple={isEnableSelectedItems}
          />
        )}
        <SC.Grid
          columns={isLoading ? headerGridColumnsSkeleton : headerGridColumns}
          data={isLoading ? bodyGridRowSkeleton : bodyGridRows}
          hasPagination
          pageSizeOptions={rowsPerPageOptions}
        />
      </SC.GridContainer>
    </SC.Container>
  );
};
