import { useEffect, useState } from 'react';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { useDebounce } from 'use-debounce';

import { Container } from '../styled';
import * as SC from './styled';

interface IProps {
  prefixValue: string;
  startValue: string;
  isLoading: boolean;
  handleChange(key: string, data: string | number): void;
}

export function AdvancesSettingsCode({ startValue, prefixValue, isLoading, handleChange }: IProps) {
  const translation = useTranslation();
  const t = (key: string): string => translation.t(`advancesSettings.code.${key}`);

  const [textInputFilterChange, setTextInputFilterChange] = useState<{ settingKey: string; value: string }>();
  const [settings, setSettingsChange] = useState<{ startValue: string; prefixValue: string }>();
  const [inputValueChange] = useDebounce(textInputFilterChange, 500);

  useEffect(() => {
    inputValueChange !== undefined && handleChange(inputValueChange.settingKey, inputValueChange.value);
  }, [inputValueChange]);

  useEffect(() => {
    setSettingsChange({ startValue, prefixValue });
  }, [startValue, prefixValue]);

  async function onChangeSettingTextValue(settingKey: string, value: string) {
    settings[settingKey] = value;
    setSettingsChange(settings);
    setTextInputFilterChange({ settingKey, value });
  }

  return (
    <Container>
      <SC.Header>
        <SC.Title variant="headline8">{t('title')}</SC.Title>
        <SC.Caption variant="caption">{t('caption')}</SC.Caption>
      </SC.Header>
      <SC.Body>
        {isLoading || !settings ? (
          <>
            <Skeleton variant="rounded" width={'100%'} height={60} />
            <Skeleton variant="rounded" width={'100%'} height={60} />
          </>
        ) : (
          <>
            <SC.Field
              type="text"
              value={settings.prefixValue}
              defaultValue={prefixValue}
              label={t('fields.prefixValue')}
              onChange={e => onChangeSettingTextValue('prefixValue', e?.target?.value)}
            />
            <SC.Field
              type="number"
              value={settings.startValue ? Number(settings.startValue) : null}
              defaultValue={settings.startValue ? Number(settings.startValue) : null}
              label={t('fields.startValue')}
              onChange={e => onChangeSettingTextValue('startValue', String(parseInt(e?.target?.value)))}
            />
          </>
        )}
      </SC.Body>
    </Container>
  );
}
