import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled(Typography).attrs({
  variant: 'body3',
})`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700 !important;
`;

export const Description = styled(Typography).attrs({
  variant: 'body4',
})`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-top: ${({ theme }) => theme.spacings.xs5};
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;

export const InfoContainer = styled.div``;

export const ValueContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const ItemsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings.xs3};
`;

export const ItemContainer = styled.div`
  margin: ${({ theme }) => theme.spacings.xs4} 0;
  display: flex;
  align-items: center;
`;

export const ValueSeparator = styled.div`
  flex: 1;
  height: 7px;
  border-bottom: 1px dotted ${({ theme }) => theme.colors.neutral[80]};
  margin: 0 ${({ theme }) => theme.spacings.xs5};
`;

export const ItemText = styled(Typography).attrs({
  variant: 'caption',
})`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const IncludedIcon = styled(Icons).attrs(({ theme }) => ({
  name: 'IconCheck',
  size: 16,
  color: theme.colors.feedback.success[40],
  fill: 'transparent',
}))`
  margin-right: ${({ theme }) => theme.spacings.xs4};
`;

export const NotIncludedIcon = styled(Icons).attrs(({ theme }) => ({
  name: 'IconX',
  size: 16,
  color: theme.colors.feedback.error[40],
  fill: 'transparent',
}))`
  margin-right: ${({ theme }) => theme.spacings.xs4};
`;

type ValueTextProps = {
  highlighted: boolean;
};

export const ValueText = styled(Typography).attrs<ValueTextProps>({
  variant: 'body2',
})<ValueTextProps>`
  color: ${({ theme, highlighted }) => (highlighted ? theme.colors.feedback.success[40] : theme.colors.neutral[20])};
  font-weight: 700 !important;
`;

export const ValueCaption = styled(Typography).attrs({
  variant: 'body4',
})`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const FooterText = styled(Typography).attrs({
  variant: 'caption',
})`
  color: ${({ theme }) => theme.colors.neutral[40]};
  line-height: 18px !important;
  display: block;
  margin-top: ${({ theme }) => theme.spacings.xs4};
`;
