/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { EReportStatus } from '@containers/Expenses/context/types';
import { IFinancialReportGrid } from '@containers/Financial/context/types/reports';
import { Icons, Loader, Menu, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { DropdownItem } from '@molecules/DropdownItem';
import { useReportsPermissions } from '@shared/hooks/permissions/systemPermissions/useReportsPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useIntegrations } from '@shared/hooks/useIntegrations';

import * as SC from '../styled';

export interface Action {
  children: React.ReactNode;
  onClick(): void;
}

export enum ActionOptions {
  VIEW = 'view',
  REPAY = 'repay',
  REPROVE = 'reprove',
  EXPORT_PDF = 'exportPdf',
  EXPORT_PDF_BY_CATEGORY = 'exportPdfByCategory',
  CANCEL_INTEGRATION = 'cancelIntegration',
  CANCEL = 'cancel',
}

export enum IconAction {
  VIEW = 'IconEye',
  REPAY = 'IconCheck',
  REPROVE = 'IconThumbDown',
  EXPORT_PDF = 'IconFileExport',
  EXPORT_PDF_BY_CATEGORY = 'IconFileExport',
  CANCEL_INTEGRATION = 'IconX',
}

interface FinancialReportGridActionsProps {
  reportId?: number;
  status: EReportStatus;
  onViewClick?(id: IFinancialReportGrid['id']): void;
  onReproveClick?(id: IFinancialReportGrid['id']): void;
  onRepayClick?(id: IFinancialReportGrid['id']): void;
  onExportPdfClick?(id: IFinancialReportGrid['id']): void;
  onExportPdfByCategoryClick?(id: IFinancialReportGrid['id']): void;
  isLoadingMenu: boolean;
}

export function FinancialReportGridActions({
  reportId,
  status,
  onViewClick,
  onReproveClick,
  onRepayClick,
  onExportPdfClick,
  onExportPdfByCategoryClick,
  isLoadingMenu,
}: FinancialReportGridActionsProps) {
  const t = useTranslate('organisms.financialReportGrid.actionsOptions');
  const { getReportActions } = useReportsPermissions();
  const { hasFinancialIntegration } = useIntegrations();
  const {
    export: allowedToExport,
    exportByCategory: allowedToExportByCategory,
    repay: allowedToRepay,
    reprove: allowedToReprove,
    view: allowedToView,
  } = getReportActions(status);

  function getAction(_action: string): Action {
    const events = {
      view: () => onViewClick?.(reportId),
      reprove: () => onReproveClick?.(reportId),
      repay: () => onRepayClick?.(reportId),
      exportPdf: () => onExportPdfClick?.(reportId),
      exportPdfByCategory: () => onExportPdfByCategoryClick?.(reportId),
    };

    return {
      children: <DropdownItem icon={getIconsAction(_action)} description={t(ActionOptions[_action])} />,
      onClick: () => events[ActionOptions[_action]](_action),
    };
  }

  function checkStatusIntegration() {
    return status === EReportStatus.PROCESSING_PAYMENT_ERROR || status === EReportStatus.AWAITING_FINANCIAL_PROCESSING;
  }

  function getIconsAction(action: string) {
    if (hasFinancialIntegration) {
      switch (action) {
        case 'REPAY':
          return 'IconCloudUpload';
        case 'REPROVE':
          return hasFinancialIntegration && checkStatusIntegration()
            ? IconAction.CANCEL_INTEGRATION
            : IconAction.REPROVE;
        default:
          return IconAction[action];
      }
    }
    return IconAction[action];
  }

  function getLabelAction(action: ActionOptions) {
    if (!hasFinancialIntegration) return action;

    switch (action) {
      case ActionOptions.REPAY:
        return hasFinancialIntegration ? 'integration' : action;
      case ActionOptions.REPROVE:
        return hasFinancialIntegration && checkStatusIntegration() ? 'cancelIntegration' : action;
      default:
        return action;
    }
  }

  function getActions(): Action[] {
    const options: Action[] = [];

    Object.keys(ActionOptions).forEach((action): void => {
      switch (ActionOptions[action]) {
        case ActionOptions.VIEW:
          allowedToView && options.push(getAction(action));
          break;

        case ActionOptions.EXPORT_PDF:
          allowedToExport && options.push(getAction(action));
          break;

        case ActionOptions.EXPORT_PDF_BY_CATEGORY:
          allowedToExportByCategory && options.push(getAction(action));
          break;
      }
    });

    return options;
  }

  if (isLoadingMenu) {
    return <Loader size="small" variant="primary" />;
  }

  return (
    <SC.ContainerActions>
      <div>
        <Tooltip
          arrow
          title={
            (!allowedToRepay || hasFinancialIntegration) &&
            t(
              !allowedToRepay
                ? `tooltip.${hasFinancialIntegration ? 'integration' : 'repay'}`
                : hasFinancialIntegration
                ? 'sendIntegration'
                : '',
            )
          }
          placement="top">
          <div>
            <SC.TagAction
              buttonType="secondary"
              size="small"
              variantType="success"
              disabled={!allowedToRepay}
              onClick={() => onRepayClick?.(reportId)}>
              {t(getLabelAction(ActionOptions.REPAY))}
              <Icons name={getIconsAction('REPAY')} />
            </SC.TagAction>
          </div>
        </Tooltip>
      </div>
      <div>
        <Tooltip
          arrow
          title={
            !allowedToReprove
              ? t(
                  `tooltip.${
                    checkStatusIntegration() || status === EReportStatus.PROCESSING_PAYMENT
                      ? 'cancelIntegration'
                      : 'reprove'
                  }`,
                )
              : hasFinancialIntegration && status !== EReportStatus.APPROVED && t('cancelIntegration')
          }
          placement="top">
          <div>
            <SC.TagAction
              buttonType="secondary"
              size="small"
              variantType="error"
              disabled={!allowedToReprove}
              onClick={() => onReproveClick?.(reportId)}>
              {t(
                getLabelAction(
                  checkStatusIntegration() || status === EReportStatus.PROCESSING_PAYMENT
                    ? ActionOptions.CANCEL
                    : ActionOptions.REPROVE,
                ),
              )}
              <Icons
                name={getIconsAction(
                  checkStatusIntegration() || status === EReportStatus.PROCESSING_PAYMENT
                    ? 'CANCEL_INTEGRATION'
                    : 'REPROVE',
                )}
              />
            </SC.TagAction>
          </div>
        </Tooltip>
      </div>

      <Menu
        type="default"
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        options={getActions()}>
        <SC.IconDots />
      </Menu>
    </SC.ContainerActions>
  );
}
