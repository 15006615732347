import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { TextField, Toggle, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ToggleContainer = styled(BorderedWrapping)`
  align-items: center;
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100%;
  padding: ${props => props.theme.spacings.xs};
`;

export const ToggleLabel = styled(Typography)`
  font-weight: 600 !important;
  color: ${props => props.theme.colors.neutral[30]};
`;

export const ToggleSwitch = styled(Toggle)`
  grid-row: 1 / span 2;
  margin-right: ${props => props.theme.spacings.xs3};
`;

export const ToggleDescription = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
  grid-column: 2;
`;

export const Input = styled(TextField)`
  width: 100%;

  &.text-field-custom-theme div.MuiFilledInput-root {
    border-width: ${props => props.theme.borders.width.xs2};
  }

  &.required label::after {
    content: '*';
    color: ${props => props.theme.colors.feedback.error[40]};
    margin-left: ${props => props.theme.spacings.xs5};
  }
`;

export const InputDescription = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
  margin-left: ${props => props.theme.spacings.xs2};
`;
