import { Icons, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

interface IProps {
  title: string;
  caption: string;
  selectedPersonId: string;
  onClick: () => void;
}

export function AccordionHeader({ title, caption, selectedPersonId, onClick }: IProps) {
  const t = useTranslate('delegation.configuration');

  return (
    <SC.AccordionHeaderContainer>
      <SC.AccordionHeaderContainerDescription>
        <SC.AccordionTitle variant="headline8">{title}</SC.AccordionTitle>
        <SC.AccordionCaption variant="body4">{caption}</SC.AccordionCaption>
      </SC.AccordionHeaderContainerDescription>
      <SC.ContainerButton onClick={e => e.stopPropagation()}>
        <Tooltip arrow title={!selectedPersonId ? t('tooltip.buttonAddSelectUser') : ''} placement="top">
          <div>
            <SC.AddButton
              size="small"
              variant="secondary"
              disabled={!selectedPersonId}
              onClick={e => {
                onClick();
                e.stopPropagation();
              }}>
              <SC.AddButtonContent>{t('toDelegate')}</SC.AddButtonContent>
              <Icons name="Add" />
            </SC.AddButton>
          </div>
        </Tooltip>
      </SC.ContainerButton>
    </SC.AccordionHeaderContainer>
  );
}
