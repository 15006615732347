import { useState } from 'react';
import { IconButtonArea } from '@atoms/IconButtonArea';
import { IReport } from '@containers/Reports/context/types';
import { useWithdrawPermissions } from '@shared/hooks/permissions/systemPermissions/useWithdrawPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { AttachWithdrawModal } from '../AttachWithdrawModal';
import * as SC from './styled';

interface IProps {
  linkedReport: {
    name: IReport['name'];
    id: IReport['id'];
    description: IReport['description'];
    status: IReport['status'];
  };
  reportByIdUpDate(): void;
}

export function WithdrawEmptyAction({ linkedReport, reportByIdUpDate }: IProps) {
  const t = useTranslate('withdraw.grid.body.actions');
  const [showAttachModal, setShowAttachModal] = useState(false);
  const getWithdrawPermissions = useWithdrawPermissions();
  const { unlink: allowedUnlink } = getWithdrawPermissions(linkedReport?.status ?? null);

  return (
    <SC.Container>
      {allowedUnlink && (
        <IconButtonArea icon="IconFile" onClick={() => setShowAttachModal(true)}>
          {t('link')}
        </IconButtonArea>
      )}
      {showAttachModal && (
        <AttachWithdrawModal
          isOpen={showAttachModal}
          onClose={() => setShowAttachModal(false)}
          reportId={linkedReport?.id}
          reportByIdUpDate={reportByIdUpDate}
        />
      )}
    </SC.Container>
  );
}
