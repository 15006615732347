import { useContext, useEffect, useState } from 'react';
import { useManageSelectionReportStatus } from '@containers/Financial/hooks/shared/useManageSelectionReportStatus';
import { useReportStatusSummary } from '@containers/Financial/hooks/useReportStatusSummary';
import { ReportSummariesFilters } from '@containers/Reports/components/organisms/ReportSummariesFilters';
import { EReportStatus, EReportViewType, IReportSummariesFilter } from '@containers/Reports/context/types';
import { useReportsSummaries } from '@containers/Reports/hooks/useReportsSummaries';
import { usePagination } from '@molecules/GridPagination/kooks/usePagination';
import { AccountingContext } from '@shared/contexts/AccountingContext/AccountingContext';

import { ConfirmModalReportRepay } from '../ConfirmModalReportRepay';
import { ExpirationDateModal } from '../ExpirationDateModal';
import { FinancialReportGrid } from '../FinancialReportGrid';
import { useFinancialReportSelectionRows } from '../FinancialReportGrid/hooks/useFinancialReportSelectionRows';
import { JustificationModalReportReproval } from '../JustificationModalReportReproval';
import { ReportStatusCardGroup } from '../ReportStatusCardGroup';
import { useFinancialReportsActionManager } from './hooks/useFinancialReportsActionManager';
import * as SC from './styled';

export const FinancialReportsPreview = () => {
  const { setReportsQuantity } = useContext(AccountingContext);
  const pagination = usePagination({ currentPage: 1, itemsPerPageShow: 20 });
  const [selectedFilters, setSelectedFilters] = useState<IReportSummariesFilter>({
    status: [EReportStatus.APPROVED],
    search: '',
  });
  const { optionsSelectStatus, onSelectedStatusClick } = useManageSelectionReportStatus({
    defaultFilters: selectedFilters?.status,
    onPageClick: pagination.onPageClick,
  });
  const {
    response: { reports, total },
    isLoading: reportsIsLoading,
  } = useReportsSummaries({
    filters: {
      page: pagination.selectedPage,
      limit: pagination.rowsPerPage,
      ...selectedFilters,
    },
    viewType: EReportViewType.FINANCIAL,
  });
  const selectionRow = useFinancialReportSelectionRows({ reports });
  const {
    isLoadingMutate,
    reportsSelected,
    justificationIsOpen,
    repayConfirmIsOpen,
    expirationDateIsOpen,
    exportingItems,
    exportExcelIsLoading,
    onViewClick,
    onReproveClick,
    onRepayClick,
    onClosePopup,
    onActionJustificationPopup,
    onActionRepayConfirmPopup,
    onExportExcelClick,
    checkCancelIntegration,
    onExportPdfUrlClick,
    onExportPdfUrlByCategoryClick,
  } = useFinancialReportsActionManager({ unSelectAllClick: selectionRow.unSelectAllClick, reports });
  const { statusSummary, isLoading } = useReportStatusSummary();

  function onPage(pageSelected: number) {
    selectionRow.unSelectAllClick();
    pagination.onPageClick(pageSelected);
  }

  function onRowsPerPage(items: number) {
    selectionRow.unSelectAllClick();
    pagination.onRowsPerPageClick(items);
  }

  function onSelectedStatus(status: EReportStatus) {
    selectionRow.unSelectAllClick();
    setSelectedFilters({ ...selectedFilters, status: onSelectedStatusClick(status) });
  }

  function getTargetJustificationReproval() {
    return checkCancelIntegration() ? 'CancelIntegration' : 'Reproval';
  }

  useEffect(() => {
    onPage(1);
  }, [selectedFilters.search]);

  useEffect(() => {
    if (reports && !reportsIsLoading) {
      setReportsQuantity(total ?? 0);
    }
  }, [reports, reportsIsLoading]);

  return (
    <SC.Container>
      <ReportStatusCardGroup
        status={statusSummary}
        isLoading={isLoading}
        onSelectedStatusClick={onSelectedStatus}
        selectedStatus={selectedFilters.status}
      />
      <SC.GridContainer>
        <SC.GridFiltersContainer>
          <ReportSummariesFilters
            isLoading={isLoadingMutate}
            onFiltersChange={data => {
              selectionRow.unSelectAllClick();
              setSelectedFilters({ ...data });
            }}
            optionsSelectStatus={optionsSelectStatus}
            viewType={EReportViewType.FINANCIAL}
            selectedFilters={selectedFilters}
            onExportToExcelClick={data => {
              onExportExcelClick(data);
            }}
            exportExcelIsLoading={exportExcelIsLoading}
          />
        </SC.GridFiltersContainer>
        <FinancialReportGrid
          reports={reports}
          isLoading={reportsIsLoading || isLoading}
          {...selectionRow}
          onSelectAllClick={selectionRow.toggleSelectAll}
          pagination={{ ...pagination, totalItems: total, onPageClick: onPage, onRowsPerPageClick: onRowsPerPage }}
          onOrderByClick={() => null}
          onViewClick={onViewClick}
          onReproveClick={onReproveClick}
          onRepayClick={onRepayClick}
          onExportPdfClick={onExportPdfUrlClick}
          onExportPdfByCategoryClick={onExportPdfUrlByCategoryClick}
          exportingItems={exportingItems}
        />
      </SC.GridContainer>
      <JustificationModalReportReproval
        isOpen={justificationIsOpen}
        isLoading={isLoadingMutate}
        onActionClick={onActionJustificationPopup}
        onCloseClick={onClosePopup}
        reportsNumber={reportsSelected?.length}
        target={getTargetJustificationReproval()}
      />
      <SC.ModalContainer>
        <ConfirmModalReportRepay
          isOpen={repayConfirmIsOpen}
          isLoading={isLoadingMutate}
          onActionClick={() => onActionRepayConfirmPopup()}
          onCloseClick={onClosePopup}
          reportsNumber={reportsSelected?.length}
        />
      </SC.ModalContainer>
      <ExpirationDateModal
        isOpen={expirationDateIsOpen}
        isLoading={isLoadingMutate}
        onActionClick={onActionRepayConfirmPopup}
        onCloseClick={onClosePopup}
        reportsNumber={reportsSelected?.length}
      />
    </SC.Container>
  );
};
