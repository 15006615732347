import { createContext, ReactElement, useReducer } from 'react';

import { EActions } from './actions';
import { initialState, reducer } from './reducer/reducer';
import { IContext } from './types';

const Context = createContext<IContext>(undefined);

const ConfigurationContext = (props: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: IContext = {
    currentState: state.currentState,
    dispatchAction: () => {
      dispatch({ type: EActions.REDUCER_FUNCTION });
    },
  };

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export { ConfigurationContext, Context };
