import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const ContainerDescription = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;

export const ContainerCaption = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600 !important;
`;

export const Caption = styled(Typography)`
  font-weight: 600 !important;
`;
