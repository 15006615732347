import { StatusTag } from '@atoms/StatusTag';
import { Icons, IconTypes, LinkButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

interface IProps {
  totalItems: number;
  numberItemsSelected: number;
  actions: IBatchActionsActions[];
}

export interface IBatchActionsActions {
  key: string;
  name: string;
  iconName?: IconTypes;
  disabled?: boolean;
  allowed: boolean;
  tooltipMessage?: string;
  onClick(keyAction: IBatchActionsActions['key']): void;
}

export function BatchActions({ totalItems, numberItemsSelected, actions }: IProps) {
  const { t } = useTranslation();

  function getAction(): React.ReactNode[] {
    const _actions: React.ReactNode[] = [];

    actions &&
      actions?.forEach((action, key) => {
        if (action.allowed)
          _actions.push(
            <div key={key}>
              <Tooltip arrow title={action?.disabled ? action?.tooltipMessage : ''} placement="top">
                <div>
                  <LinkButton disabled={action?.disabled} variant="default">
                    <SC.LinkContent onClick={() => action?.onClick(action?.key)}>
                      <Icons name={action?.iconName} fill="transparent" />
                      {action?.name}
                    </SC.LinkContent>
                  </LinkButton>
                </div>
              </Tooltip>
            </div>,
          );
      });

    return _actions;
  }

  return (
    <SC.Container>
      <StatusTag variant="secondary">
        {t('organisms.batchActionsGrid.selectedItems', { totalItems, selectedItems: numberItemsSelected })}
      </StatusTag>
      {getAction()}
    </SC.Container>
  );
}
