import { Loader } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

export interface LoaderProps {
  loader?: React.ReactNode;
  description?: string;
}

export const AutocompleteLoader = ({ loader, description }: LoaderProps) => {
  const { t } = useTranslation();
  return (
    <SC.Container>
      {Boolean(loader) ? loader : <Loader variant="primary" size="extraSmall" />}
      <SC.Description variant="caption">
        {description || t('organisms.establishments.loader.description')}
      </SC.Description>
    </SC.Container>
  );
};
