import { gql } from '@apollo/client';

export const getProjects = gql`
  query GetProjects {
    getProjects {
      projects {
        id
        name
        referenceId
        client {
          name
        }
        companyId
        costCenterId
        userId
        startDate
        endDate
        active
      }
      total
    }
  }
`;

export const getProjectsSummary = gql`
  query GetProjects {
    getProjects {
      projects {
        id
        name
        referenceId
      }
    }
  }
`;

export const getProjectById = gql`
  query GetProjectById($projectId: Int!) {
    getProjectById(projectId: $projectId) {
      id
      name
      referenceId
      client {
        id
        name
      }
      costCenterId
      userId
      startDate
      endDate
      allowedUsers {
        id
        name
      }
      active
    }
  }
`;

export const createProjectMutation = gql`
  mutation CreateProject($project: ProjectInput!) {
    createProject(project: $project) {
      id
    }
  }
`;

export const updateProjectMutation = gql`
  mutation UpdateProject($project: ProjectInput!, $projectId: Int!) {
    updateProject(projectId: $projectId, project: $project) {
      id
    }
  }
`;

export const deleteProjects = gql`
  mutation DeleteProjects($ids: [Int!]!) {
    deleteProjects(ids: $ids) {
      id
      error
      message
    }
  }
`;

export const activeProjects = gql`
  mutation ActiveProjects($ids: [Int!]!, $active: Boolean!) {
    activeProjects(ids: $ids, active: $active) {
      id
      error
      message
    }
  }
`;

export const getProjectsToGrid = gql`
  query GetProjectsToGrid($page: Int!, $searchName: String) {
    getProjectsToGrid(page: $page, searchName: $searchName) {
      projects {
        id
        name
        referenceId
        client {
          name
        }
        companyId
        costCenterId
        userId
        startDate
        endDate
        active
        allowedUsers {
          id
          name
          cpf
          email
        }
      }
      total
    }
  }
`;

export const addUsersToProject = gql`
  mutation AddUsersToProject($usersIds: [Int!]!, $projectId: Int!) {
    addUsersToProject(usersIds: $usersIds, projectId: $projectId) {
      id
    }
  }
`;

export const removeUsersFromProject = gql`
  mutation RemoveUsersFromProject($usersIds: [Int!]!, $projectId: Int!) {
    removeUsersFromProject(usersIds: $usersIds, projectId: $projectId) {
      id
    }
  }
`;
