import { useEffect, useMemo, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';
import { cloneDeep } from 'lodash';

import { getExpense, getExpenseCustomFieldsLabels } from '../context/queries';
import {
  EExpenseStatus,
  EExpenseType,
  IExpenses,
  IGetExpenseById,
  IGetExpenseCustomFields,
} from '../context/types/expenses';
import { useSignedFileUrl } from './useSignedFileUrl';

export interface IUseExpensesProps {
  id: number;
}

const initialExpenseValue: IExpenses = {
  id: null,
  attachmentsNumber: 2,
  category: {
    description: '',
    id: 2,
  },
  description: '',
  commentsNumber: 0,
  currencyPrefix: 'R$',
  referenceId: '',
  expenseDate: '',
  status: EExpenseStatus.CREATED,
  value: null,
  violationNumber: 2,
  violations: [
    {
      id: 1,
      description: '',
    },
    {
      id: 2,
      description: '',
    },
  ],
  attachments: [],
  establishment: {
    place: '',
    address: '',
    lat: null,
    lng: null,
    placeId: null,
  },
  type: EExpenseType.DISTANCE,
  comments: [
    {
      id: 1,
      createdAt: '',
      message: '',
      user: {
        name: '',
      },
    },
  ],
  history: [
    {
      createdAt: '',
      description: '',
      user: { name: '' },
    },
  ],
  report: {
    name: '',
  },
  client: {
    name: '',
  },
  project: {
    name: '',
  },
  costCenter: {
    name: '',
  },
  customFields: [
    {
      id: '',
      value: '',
    },
  ],
};

export const useExpenseById = ({ id }: IUseExpensesProps) => {
  const [customFields, setCustomFields] = useState([]);
  const { loading, data, error, networkStatus } = useQuery<IGetExpenseById>(getExpense, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const attachments = useMemo(() => {
    if (data?.getExpenseById.type == EExpenseType.ODOMETER) {
      const odometerAttachments = [];
      data?.getExpenseById?.odometer.start?.attachment?.path &&
        odometerAttachments.push(data?.getExpenseById?.odometer.start?.attachment?.path);
      data?.getExpenseById?.odometer.end?.attachment?.path &&
        odometerAttachments.push(data?.getExpenseById?.odometer.end?.attachment?.path);
      return odometerAttachments;
    } else {
      return data?.getExpenseById?.attachments?.map(({ path }) => path);
    }
  }, [data]);

  const { files, isLoading: isLoadingFiles } = useSignedFileUrl(attachments);

  const { data: customFieldsData } = useQuery<IGetExpenseCustomFields>(getExpenseCustomFieldsLabels);

  useEffect(() => {
    if (Boolean(customFieldsData?.getExpenseFormFields?.custom)) {
      const fieldsWithValues = customFieldsData.getExpenseFormFields.custom.map(field => {
        const filteredFieldValue = data?.getExpenseById?.customFields?.find(fieldValue => field.id == fieldValue.id);
        return {
          id: field.id,
          label: field.label,
          value: filteredFieldValue?.value,
        };
      });

      setCustomFields(fieldsWithValues);
    }
  }, [data, customFieldsData]);

  const expense = useMemo(() => {
    const expense = cloneDeep(data?.getExpenseById);

    if (files && expense) {
      if (data?.getExpenseById.type == EExpenseType.ODOMETER) {
        expense.odometer.start.attachment = files[0];
        expense.odometer.end.attachment = files[1];
      } else {
        expense.attachments = files;
      }
    }

    return expense || initialExpenseValue;
  }, [data, files]);

  return {
    expense,
    customFields,
    isLoading: loading || isLoadingFiles || networkStatus === NetworkStatus.loading,
    error,
  };
};
