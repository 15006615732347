import { RegisterCostCenterByFileForm } from '@containers/CostCenters/components/organisms/RegisterCostCenterByFileForm/RegisterCostCenterByFileForm';
import { useTranslation } from '@locale/Translator';
import { BulkImportContainer } from '@molecules/BulkImportContainer';
import { BulkImportFiles } from '@molecules/BulkImportFiles';
import { PageHeader } from '@organisms/BasePage/PageHeader/PageHeader';
import { FormProvider, useForm } from 'react-hook-form';

import { CostCenterFooterPage } from './CostCenterFooterPage';
import { CostCenterRegisterFileHeader } from './CostCenterRegisterFileHeader';

export const BulkImportCostCenter = () => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <>
      <CostCenterRegisterFileHeader />
      <FormProvider {...methods}>
        <BulkImportContainer>
          <PageHeader title={t('pages.costCenterRegister.bulkImport.title')} />
          <BulkImportFiles
            title={t('organisms.registerClient.bulkImport.sendData.title')}
            subtitle={t('organisms.registerClient.bulkImport.sendData.subTitle.costCenters')}>
            <RegisterCostCenterByFileForm />
          </BulkImportFiles>
        </BulkImportContainer>
        <CostCenterFooterPage />
      </FormProvider>
    </>
  );
};
