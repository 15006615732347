export enum EContractType {
  FLASH_APP_SAAS_SERVICE_CONTRACT = 'FLASH_APP_SAAS_SERVICE_CONTRACT',
  FLASH_APP_CONTRACT_CORPORATE_CARD = 'FLASH_APP_CONTRACT_CORPORATE_CARD',
}

export interface ICompanyLicenseAcceptanceTermsActor {
  userName: string;
  cpf: string;
  email: string;
}

export interface ICompanyLicenseAcceptanceTerm {
  version?: string;
  document: EContractType;
  date?: string;
  actor?: ICompanyLicenseAcceptanceTermsActor;
}

export interface IGetContractAcceptancesResponse {
  getContractAcceptances: {
    companyLicenceAcceptanceTerms: ICompanyLicenseAcceptanceTerm[];
  };
}

export interface IGetContractPDFBase64Request {
  contractType: EContractType;
}

export interface IGetContractPDFBase64Response {
  getContractPDFBase64: string;
}

export interface IGetRegisterContractAcceptanceRequest {
  contracts: EContractType[];
}

export interface IGetRegisterContractAcceptanceResponse {
  isSigned: boolean;
  type: EContractType;
}

export type ContractErrorCodes =
  | 'COMPANY_ACCESS_REVOKED'
  | 'EMPLOYEE_INACTIVE'
  | 'SAAS_CONTRACT_NOT_FOUND'
  | 'CORPORATE_CARD_CONTRACT_NOT_FOUND';
