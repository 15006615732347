import { IReportHistory } from '@containers/Reports/context/types/report';
import { GridPagination } from '@molecules/GridPagination';
import { usePagination } from '@molecules/GridPagination/kooks/usePagination';

import { useBodyGridRows } from './hooks/UseBodyGridRow';
import { useBodyGridRowSkeleton } from './hooks/UseBodyGridRowSkeleton';
import { useHeaderGridColumns } from './hooks/UseHeaderGridColumns';
import { useHeaderGridColumnsSkeleton } from './hooks/UseHeaderGridColumnsSkeleton';
import * as SC from './styled';

export interface ReportHistoryGridProps {
  isLoading: boolean;
  historic: IReportHistory[];
  reportHistoryTotal: number;
  onHistoryPageClick(page: number): void;
}

export const ReportHistoryGrid = ({
  historic,
  isLoading,
  reportHistoryTotal,
  onHistoryPageClick,
}: ReportHistoryGridProps) => {
  const headerGridColumns = useHeaderGridColumns();
  const headerGridColumnsSkeleton = useHeaderGridColumnsSkeleton();
  const bodyGridRows = useBodyGridRows({ historic });
  const bodyGridRowSkeleton = useBodyGridRowSkeleton({ rowsNumber: 5 });

  const { onPageClick, onRowsPerPageClick, selectedPage, rowsPerPage } = usePagination({
    currentPage: 1,
  });

  function onSelectPage(page: number): void {
    onPageClick(page);
    onHistoryPageClick?.(page);
  }

  const hasHistoryPagination = reportHistoryTotal > 5;

  return (
    <SC.ReportHistoryGridContainer>
      <SC.GridContainer>
        <SC.Grid
          data={!isLoading ? bodyGridRows : bodyGridRowSkeleton}
          columns={!isLoading ? headerGridColumns : headerGridColumnsSkeleton}
        />
      </SC.GridContainer>
      {hasHistoryPagination && (
        <GridPagination
          isLoading={isLoading}
          onPageClick={onSelectPage}
          onRowsPerPageClick={onRowsPerPageClick}
          selectedPage={selectedPage}
          totalItems={reportHistoryTotal}
          rowsPerPage={rowsPerPage}
          showRowsPerPageOptions={false}
        />
      )}
    </SC.ReportHistoryGridContainer>
  );
};
