import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { useTranslation } from '@locale/Translator';
import { clientsBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useNavigate } from 'react-router-dom';

export function useClientMutation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutation } = useMutate('v1/clients');

  async function update(clientId: string, client: IConfigurationClient) {
    const body = { ...client };
    await mutation.trigger(
      {
        path: `${clientId}`,
        options: {
          method: 'PATCH',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
        onError: error =>
          showToast({
            message: error.message || t('molecules.clientToast.updateErrorMessage'),
            type: 'error',
          }),
        onSuccess: () => showToast({ message: t('molecules.clientToast.updateSuccessMessage'), type: 'success' }),
      },
    );

    navigate(clientsBaseURL);
  }

  async function create(client: IConfigurationClient) {
    const body = { ...client };
    await mutation.trigger(
      {
        path: '',
        options: {
          method: 'POST',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
        onError: error =>
          showToast({
            message: error.message || t('molecules.clientToast.createErrorMessage'),
            type: 'error',
          }),
        onSuccess: () => showToast({ message: t('molecules.clientToast.createSuccessMessage'), type: 'success' }),
      },
    );

    navigate(clientsBaseURL);
  }

  async function handleSave(client: IConfigurationClient, clientId?: string) {
    delete client.id;
    if (clientId) {
      return update(clientId, client);
    }

    create(client);
  }

  return {
    handleSave,
    isLoading: mutation.isMutating,
  };
}
