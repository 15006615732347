import { useEffect, useState } from 'react';
import { ClientInput } from '@containers/Clients/context/types/clients';
import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { useTransferListSet } from '@containers/Clients/hooks/useTransferListSet';
import { TransferList, TransferListDataType } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { useEmployeesSelectionList } from '@shared/hooks/employees/useEmployeesSelectionList';
import { useFormContext } from 'react-hook-form';

import * as SC from './styled';

interface AssignUsersProps {
  client: IConfigurationClient;
  isLoadingClient: boolean;
}

export const AssignUsers = ({ client, isLoadingClient }: AssignUsersProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<ClientInput>();
  const { employees: users, total, isLoading: isLoadingUsers } = useEmployeesSelectionList();
  const leftList = useTransferListSet({ data: users, ids: client?.employees || null });
  const rightList = useTransferListSet({ data: users, ids: client?.employees, isRightList: true });
  const [rightListData, setRightListData] = useState<TransferListDataType>([]);
  const [leftListData, setLeftListData] = useState<TransferListDataType>(leftList);

  useEffect(() => {
    setLeftListData(leftList);
  }, [leftList]);

  useEffect(() => {
    setRightListData(rightList);
  }, [rightList]);

  useEffect(() => {
    const data = rightListData.map(item => item._id);
    setValue('employees', data);
  }, [rightListData]);

  const handleFilter = ({ result, update }) => {
    update(current =>
      current.map(item => {
        if (!item.name.toLowerCase().includes(result.toLowerCase())) {
          item.hidden = true;
        } else {
          item.hidden = false;
        }

        return item;
      }),
    );
  };

  const handleCheck = ({ allChecked, data, update }) => {
    if (data?.length) {
      update([
        ...data?.map(row => ({
          ...row,
          checked: allChecked !== undefined ? allChecked : row?.checked,
        })),
      ]);
    }
  };

  return (
    <>
      <SC.Line />
      <TransferList
        columns={[{ Header: t('clients.registerClient.assignUsersGrid.header.name'), accessor: 'name' }]}
        leftList={{
          data: leftListData,
          total: leftListData?.length,
          loading: isLoadingUsers,
          title: t('clients.registerClient.assignUsersGrid.allPeople', { selected: total - rightListData?.length }),
          onCheck: ({ allChecked, data }) => handleCheck({ allChecked, data, update: setLeftListData }),
          onSearch: result => handleFilter({ result, update: setLeftListData }),
        }}
        rightList={{
          data: rightListData,
          total: rightListData?.length,
          loading: isLoadingClient,
          title: t('clients.registerClient.assignUsersGrid.selectedForClient', { selected: rightListData?.length }),
          onCheck: ({ allChecked, data }) => handleCheck({ allChecked, data, update: setRightListData }),
          onSearch: result => handleFilter({ result, update: setRightListData }),
        }}
        onTransfer={({ leftList, rightList }) => {
          setLeftListData([...leftList.data]);
          setRightListData([...rightList.data]);
        }}
      />
    </>
  );
};
