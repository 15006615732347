import { Tab } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ExpensesPreviewContainer = styled.div``;

export const TabGrid = styled(Tab)`
  .MuiTab-root {
    text-transform: inherit;
  }
`;
