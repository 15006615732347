/**
 * Downloads a PDF based on base64 string
 * @param base64 base64 to download
 * @param filename file to download
 */
export function downloadPDF(base64: string, filename: string) {
  const linkSource = base64.includes(';base64,') ? base64 : `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
}

/**
 * Downloads a PDF
 * @param file blob to download
 * @param filename file to download
 */
export function downloadPdfFile(file: Blob, filename: string) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.pdf`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

/**
 * Downloads a excel file
 * @param base64 base64 file to download
 * @param filename filename
 */
export function downloadExcel(base64: string, filename: string) {
  const linkSource = base64.includes(';base64,')
    ? base64
    : `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
}

/**
 * Downloads a csv file
 * @param base64 base64 file to download
 * @param filename filename
 */
export function downloadCSV(base64: string, filename: string) {
  const linkSource = base64.includes(';base64,') ? base64 : `data:application/octet-stream;base64,${base64}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
}

/**
 * Converts file to base64
 * @param file file to convert
 * @returns base64 data
 * @reference https://stackoverflow.com/a/57272491
 */
export function toDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const res = reader.result;

      if (typeof res === 'string') {
        return resolve(res);
      }

      return resolve(Buffer.from(res).toString('base64'));
    };
    reader.onerror = error => reject(error);
  });
}

/**
 * Converts a arraybuffer to base64
 * @param buffer buffer to convert
 * @returns base64 from buffer
 */
export function bufferToBase64(buffer: ArrayBuffer, mime: string): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return `data:${mime};base64,${window.btoa(binary)}`;
}

/**
 * Converts a base64 string to an object URL preview
 * @param base64 Base64 data string
 */
export async function base64ToObjectURL(base64: string) {
  const blob = await (await fetch(base64)).blob();
  return URL.createObjectURL(blob);
}

/**
 * Add base64 prefix with content type if it doesn't exists
 * If prefix already exists, then it will ignore and just return value with default content type
 *
 * @param base64 - Base64 to add
 * @param contentType - Base64 Content type
 */
export function withBase64Prefix(base64: string, contentType: string) {
  if (base64.includes(';base64,')) {
    return base64;
  }

  return `data:${contentType};base64,${base64}`;
}

interface ResultFile {
  name: string;
  uri: string | ArrayBuffer;
}
export async function readFile(file: File): Promise<ResultFile> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject();
    };

    reader.onload = event => {
      const bstr = event.target.result;
      resolve({
        name: file.name,
        uri: bstr,
      });
    };
    reader.readAsBinaryString(file);
  });
}

export const downloadFile = (file: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
