import { IAdvance } from '@containers/Advances/context/types/advance';
import { IApproversTarget } from '@containers/Reports/context/types/approvers';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export const useApproversAdvanceById = (advanceId: IAdvance['id'], shouldFetch: boolean = false) => {
  const path = advanceId && shouldFetch ? `v1/advances/${advanceId}/approvals` : null;

  const { data, error, isLoading } = useFetch<IApproversTarget[]>(
    path,
    undefined,
    undefined,
    undefined,
    () => showToast({ message: `${error.message}`, type: 'error' }),
    shouldFetch,
  );

  return {
    error,
    isLoading,
    approvers: data || [],
  };
};
