import { EExpenseType } from '@containers/Expenses/context/types';
import { useKmPolicies } from '@containers/Expenses/hooks/useKmPolicies';
import { useManualExpenses } from '@containers/Expenses/hooks/useManualExpenses';
import { Button, MenuOptionType } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { DropdownItem } from '@molecules/DropdownItem';
import { IReportNavigate, useExpenseRegisterDriver } from '@shared/hooks/navigation/useExpenseRegisterDriver';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useCheckKmIsAllowed } from '@shared/hooks/permissions/systemPermissions/useCheckKmIsAllowed';
import {
  IExpensesAllowedActions,
  useExpensesPermissions,
} from '@shared/hooks/permissions/systemPermissions/useExpensesPermissions';

import * as SC from './styled';

interface IProps {
  report: IReportNavigate;
  onLinkExpense: () => void;
}

export const ExpenseActions = ({ report, onLinkExpense }: IProps) => {
  const { t } = useTranslation();
  const { checkLicenseFeature } = useLicenseFeatures();
  const navigateExpenseEdit = useExpenseRegisterDriver();
  const { getExpenseActions } = useExpensesPermissions();
  const { manualExpenseEntry } = useManualExpenses();
  const isKmAllowed = useCheckKmIsAllowed();
  const { allowOdometer, allowOriginDestination } = useKmPolicies();
  const permittedKmExpense = isKmAllowed && (allowOdometer || allowOriginDestination);
  const allowedActions: MenuOptionType[] = getAllowedActions();

  function getAllowedActions(): MenuOptionType[] {
    const _actions: MenuOptionType[] = [];
    const { edit: expenseEditingAllowed, link: expenseLinkAllowed }: IExpensesAllowedActions = getExpenseActions(
      EExpenseType.MANUAL,
      report?.status,
    );
    const { link: kmExpenseLinkAllowed }: IExpensesAllowedActions = getExpenseActions(
      EExpenseType.ORIGIN_DESTINATION,
      report?.status,
    );

    if (manualExpenseEntry && expenseEditingAllowed && checkLicenseFeature('BASIC_REPORTS')) {
      _actions.push({
        children: <DropdownItem description={t('pages.expenses.expenses')} />,
        onClick: () => navigateExpenseEdit({ expenseType: EExpenseType.MANUAL, linkedReport: report }),
      });
    }

    if (permittedKmExpense && checkLicenseFeature('BASIC_MILEAGE')) {
      _actions.push({
        children: <DropdownItem description={t('pages.expenses.mileage')} />,
        onClick: () => navigateExpenseEdit({ expenseType: EExpenseType.ORIGIN_DESTINATION, linkedReport: report }),
      });
    }

    if ((expenseLinkAllowed || kmExpenseLinkAllowed) && checkLicenseFeature('BASIC_REPORTS')) {
      _actions.push({
        children: <DropdownItem description={t('report.linkExpense')} />,
        onClick: onLinkExpense,
      });
    }

    return _actions;
  }

  return allowedActions?.length ? (
    <SC.ActionsButton options={allowedActions}>
      <Button size="small" variant="primary">
        {t('pages.expenses.add')}
        <SC.IconAdd name="Add" />
      </Button>
    </SC.ActionsButton>
  ) : (
    <></>
  );
};
