import { useEffect, useState } from 'react';
import { useTransferListSet } from '@containers/Clients/hooks/useTransferListSet';
import { CostCenterForm } from '@containers/CostCenters/validators/CostCenterFormSchema';
import { TransferList, TransferListDataType } from '@flash-tecnologia/hros-web-ui-v2';
import { useEmployeesSelectionList } from '@shared/hooks/employees/useEmployeesSelectionList';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useFormContext } from 'react-hook-form';

import * as SC from './styled';

interface AssignUsersProps {
  userIds: string[];
  isLoading: boolean;
}

export const AssignUsers = ({ userIds, isLoading }: AssignUsersProps) => {
  const t = useTranslate('pages.costCenterRegister.assignUsersGrid');
  const { setValue } = useFormContext<CostCenterForm>();
  const { employees: users, total, isLoading: isLoadingUsers } = useEmployeesSelectionList();
  const leftList = useTransferListSet({ data: users, ids: userIds || null });
  const rightList = useTransferListSet({ data: users, ids: userIds, isRightList: true });
  const [rightListData, setRightListData] = useState<TransferListDataType>([]);
  const [leftListData, setLeftListData] = useState<TransferListDataType>(leftList);

  useEffect(() => {
    setLeftListData(leftList);
  }, [leftList]);

  useEffect(() => {
    setRightListData(rightList);
  }, [rightList]);

  useEffect(() => {
    const data = rightListData.map(item => item._id);
    setValue('userIds', data);
  }, [rightListData]);

  const handleFilter = ({ result, update }) => {
    update(current =>
      current.map(item => {
        if (!item.name.toLowerCase().includes(result.toLowerCase())) {
          item.hidden = true;
        } else {
          item.hidden = false;
        }

        return item;
      }),
    );
  };

  const handleCheck = ({ allChecked, data, update }) => {
    if (data?.length) {
      update([
        ...data?.map(row => ({
          ...row,
          checked: allChecked !== undefined ? allChecked : row?.checked,
        })),
      ]);
    }
  };

  return (
    <>
      <SC.Line />
      <TransferList
        columns={[{ Header: t('header.name'), accessor: 'name' }]}
        leftList={{
          data: leftListData,
          total: leftListData?.length,
          loading: isLoadingUsers,
          title: t('allPeople', { selected: total - rightListData?.length }),
          onCheck: ({ allChecked, data }) => handleCheck({ allChecked, data, update: setLeftListData }),
          onSearch: result => handleFilter({ result, update: setLeftListData }),
        }}
        rightList={{
          data: rightListData,
          total: rightListData?.length,
          loading: isLoading,
          title: t('selectedForCostCenter', { selected: rightListData?.length }),
          onCheck: ({ allChecked, data }) => handleCheck({ allChecked, data, update: setRightListData }),
          onSearch: result => handleFilter({ result, update: setRightListData }),
        }}
        onTransfer={({ leftList, rightList }) => {
          setLeftListData([...leftList.data]);
          setRightListData([...rightList.data]);
        }}
      />
    </>
  );
};
