import { IPolicy, IPolicyBase } from '@containers/Policies/context/types/types';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { useListPolicies } from './useListPolicies';

interface IResponse {
  delegateUserId: string;
  error: boolean;
  message: string;
}

export function usePolicyMutate() {
  const { mutation } = useMutate('v1/policies');
  const t = useTranslate('policy');
  const { refetch } = useListPolicies(false);

  function getIdsParameter(ids: IPolicyBase['id'][]) {
    return ids.map(id => ({ key: 'policyIds[]', value: id }));
  }

  function checkPlurality(ids: IPolicyBase['id'][]) {
    return ids?.length ? 'singular' : 'plural';
  }

  async function handleSave(policy: IPolicy, id: IPolicy['id']) {
    const type = Boolean(id) ? 'update' : 'create';
    try {
      const response: IPolicy = await mutation.trigger({
        path: type === 'update' ? `${id}` : '',
        options: {
          method: type === 'create' ? 'POST' : 'PUT',
          body: JSON.stringify(policy),
        },
      });

      return response;
    } catch (_error) {
      showToast({
        title: t(`${type}.feedback.error.title`),
        message: _error?.message ?? t(`${type}.feedback.error.message`),
        type: 'error',
      });
    }
  }

  async function conditionalSave(conditions: IPolicy['conditions'], id: IPolicy['id']) {
    try {
      const response: { conditions: IPolicy['conditions'] } = await mutation.trigger({
        path: `${id}/conditions`,
        options: {
          method: 'PUT',
          body: JSON.stringify({ conditions }),
        },
      });

      return response;
    } catch (_error) {
      showToast({
        message: _error?.message,
        type: 'error',
      });
    }
  }

  async function deletePolicy(policyIds: IPolicyBase['id'][]) {
    const plurality = checkPlurality(policyIds);
    try {
      const response: IResponse[] = await mutation.trigger({
        path: '',
        parameters: getIdsParameter(policyIds),
        options: {
          method: 'DELETE',
        },
      });

      showToast({
        message: t(`delete.${plurality}.feedback.success.message`),
        type: 'success',
      });

      refetch();
      return response;
    } catch (_error) {
      showToast({
        title: t(`delete.${plurality}.feedback.error.title`),
        message: _error?.message ?? t(`delete.${plurality}.feedback.error.message`),
        type: 'error',
      });
    }
  }

  async function tootlePolicy(policyIds: IPolicyBase['id'][], active: boolean) {
    const plurality = checkPlurality(policyIds);
    try {
      const response: IResponse[] = await mutation.trigger({
        path: 'toggle-active',
        parameters: [...getIdsParameter(policyIds), { key: 'active', value: String(active) }],
        options: {
          method: 'PATCH',
        },
      });

      refetch();
      return response;
    } catch (_error) {
      showToast({
        title: t(`toggle.${plurality}.feedback.error.title`),
        message: _error?.message ?? t(`toggle.${plurality}.feedback.error.message`),
        type: 'error',
      });
    }
  }

  async function duplicatePolicy(policyId: IPolicyBase['id']) {
    try {
      const response: IResponse[] = await mutation.trigger({
        path: `${policyId}/duplicate`,
        parameters: [{ key: 'policyId', value: String(policyId) }],
        options: {
          method: 'POST',
        },
      });

      showToast({
        message: t(`duplicate.feedback.success.message`),
        type: 'success',
      });

      refetch();
      return response;
    } catch (_error) {
      showToast({
        title: t(`duplicate.feedback.error.title`),
        message: _error?.message ?? t(`duplicate.feedback.error.message`),
        type: 'error',
      });
    }
  }

  async function sendDocument(file: File, policyId: IPolicy['id']) {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response: IPolicy = await mutation.trigger({
        path: `document/${policyId}`,
        options: {
          method: 'POST',
          body: formData,
          headers: {
            isFile: 'true',
          },
        },
      });
      return response;
    } catch (_error) {
      showToast({
        message: _error?.message,
        type: 'error',
      });
    }
  }

  async function deleteDocument(policyId: IPolicy['id']) {
    try {
      const response: IPolicy = await mutation.trigger({
        path: `document/${policyId}`,
        options: {
          method: 'DELETE',
        },
      });

      refetch();
      return response;
    } catch (_error) {
      showToast({
        message: _error?.message,
        type: 'error',
      });
    }
  }

  return {
    handleSave,
    deletePolicy,
    tootlePolicy,
    duplicatePolicy,
    conditionalSave,
    sendDocument,
    deleteDocument,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
