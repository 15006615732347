import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const DataPersonContainer = styled.div`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const DataPersonMailContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;

export const IconMail = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
