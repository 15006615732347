import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import qs from 'query-string';

import { EReportViewType, IFiltersRequestReports, IReportSummaries } from '../context/types';

interface IFetchResponse {
  reports: IReportSummaries[];
  total: number;
  page: number;
  pageSize: number;
  pages: number;
}

interface IProps {
  shouldFetch?: boolean;
  filters?: IFiltersRequestReports;
  viewType: EReportViewType;
}

export function useReportsSummaries({ shouldFetch = true, filters, viewType }: IProps) {
  const params = qs.stringify(filters, { arrayFormat: 'bracket' });
  const { data, error, isLoading, mutate } = useFetch<IFetchResponse>(
    getPath(),
    undefined,
    undefined,
    undefined,
    error => showToast({ message: error?.message, type: 'error' }),
    shouldFetch,
  );

  function getPath() {
    switch (viewType) {
      case EReportViewType.FINANCIAL:
        return `v1/reports/financial/summaries?${params}`;
      case EReportViewType.APPROVAL:
        return `v1/reports/approvals/summaries?${params}`;
      default:
        return `v1/reports/summaries?${params}`;
    }
  }

  return {
    response: {
      reports: data?.reports || [],
      ...data,
    },
    isLoading: isLoading,
    error,
    refetch: () => mutate(),
  };
}
