import { gql } from '@apollo/client';

export const getExpenseSettings = gql`
  query GetExpenseSettings {
    getExpenseSettings {
      quantity {
        active
        required
        allowsEditing
        fieldOrder
      }
      category {
        active
        required
        allowsEditing
        fieldOrder
      }
      address {
        active
        required
        allowsEditing
        fieldOrder
      }
      receiptFile {
        active
        required
        allowsEditing
        fieldOrder
      }
      value {
        active
        required
        allowsEditing
        fieldOrder
      }
      currency {
        active
        required
        allowsEditing
        fieldOrder
      }
      paymentType {
        active
        required
        allowsEditing
        fieldOrder
      }
      date {
        active
        required
        allowsEditing
        fieldOrder
      }
      description {
        active
        required
        allowsEditing
        fieldOrder
      }
      invoice {
        active
        required
        allowsEditing
        fieldOrder
      }
      invoiceKey {
        active
        required
        allowsEditing
        fieldOrder
      }
      client {
        active
        required
        allowsEditing
        fieldOrder
      }
      project {
        active
        required
        allowsEditing
        fieldOrder
      }
      costCenter {
        active
        required
        allowsEditing
        fieldOrder
      }
      billable {
        active
        required
        allowsEditing
        fieldOrder
      }
      reimbursableExpenses
      notifyPendingExpenses
      manualExpenseEntry
      expirationExpenses
      daysExpirationExpenses
      allowLaterDate
      allowEarlierDate
    }
  }
`;

export const updateExpenseSettings = gql`
  mutation UpdateExpenseSettings($expenseSettings: ExpenseSettings!) {
    updateExpenseSettings(expenseSettings: $expenseSettings)
  }
`;
