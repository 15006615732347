import { EAnalyticsType } from '@containers/Analytics/context/types/filters';
import { useTranslation } from '@locale/Translator';

import { AnalyticsGroup } from '../../AnalyticsGroup/AnalyticsGroup';
import { AnalyticsSection } from '../AnalyticsSection';

export function AnalyticsReportsSection() {
  const { t } = useTranslation();
  const translationBase: string = 'analytics.sections.reports';
  return (
    <AnalyticsGroup
      header={{
        title: t(`${translationBase}.title`),
        caption: t(`${translationBase}.caption`),
        icon: 'IconFolder',
      }}>
      <AnalyticsSection
        key={`reports-overview`}
        analyticsType={EAnalyticsType.REPORT_OVERVIEW}
        translatePathBase={'analytics.reportOverview'}
      />
      <AnalyticsSection
        key={`reports-pending-approvals-by-user`}
        analyticsType={EAnalyticsType.REPORT_PENDING_APPROVALS}
        translatePathBase={'analytics.reportsPendingApprovalsByUsers'}
      />
      <AnalyticsSection
        key={`reports-status-by-users`}
        analyticsType={EAnalyticsType.REPORT_STATUS_BY_USER}
        translatePathBase={'analytics.reportStatusByRequester'}
      />
    </AnalyticsGroup>
  );
}
