import { useState } from 'react';
import { ApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import { TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldRegister } from '../../molecules/FieldRegister/FieldRegister';
import * as SC from './styled';

interface IProps {
  isLoading?: boolean;
}

export function AutomaticDisapproval({ isLoading }: IProps) {
  const t = useTranslate('approvalFlows.approvalSources.automaticDisapproval');
  const { control, getValues } = useFormContext<ApprovalFlowForm>();
  const [hasMessage, setHasMessage] = useState<boolean>(Boolean(getValues('customMessage')));

  return (
    <SC.Container>
      <ToggleSettings
        disabled={false}
        checked={hasMessage}
        toggleKey={'customMessage'}
        label={t('toggle')}
        onChange={(_, value) => setHasMessage(value)}
      />
      {hasMessage && (
        <FieldRegister isLoading={isLoading}>
          <Controller
            control={control}
            name="customMessage"
            rules={{
              required: {
                value: !getValues('customMessage') && hasMessage,
                message: t('mandatoryInfo'),
              },
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                inputRef={ref}
                label={t('fieldLabel')}
                fullWidth
                rows={4}
                multiline
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FieldRegister>
      )}
    </SC.Container>
  );
}
