import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export interface LinkedReport {
  id: number;
  name: string;
  description: string;
}

export function useLinkedReport(expenseReport: LinkedReport) {
  const { state } = useLocation();
  const report = useMemo(
    () => ({
      id: state?.report?.id,
      name: state?.report?.name,
      description: state?.report?.description,
    }),
    [],
  );

  return expenseReport || report;
}
