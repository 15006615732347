import { ShapeIcon } from '@atoms/ShapeIcon';
import { IExpenseViolation } from '@containers/Expenses/context/types/expenses';
import { Icons, IconTypes, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import * as SC from './styled';

interface Occurrence extends IExpenseViolation {
  message?: string;
}

export interface OccurrenceBoxProps {
  customIcon?: IconTypes;
  showClose?: boolean;
  occurrences: Occurrence | Occurrence[];
  title: string;
  onClose(): void;
}

export const OccurrencesBox = ({ occurrences, title, showClose = true, customIcon, onClose }: OccurrenceBoxProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <SC.BorderedWrappingContainer color={colors.feedback.error[70]}>
      <SC.BorderedWrappingContentContainer>
        {showClose && (
          <SC.CloseButton about={t('molecules.occurrencesBox.dismiss')} onClick={onClose}>
            <Icons name="IconX" fill="transparent" size={16} color={colors.feedback.error[50]} />
          </SC.CloseButton>
        )}
        <ShapeIcon
          icon={customIcon || 'IconAlertCircle'}
          size="medium"
          variant="error"
          $foregroundColor={colors.feedback.error[50]}
        />
        <SC.OccurrencesContainer>
          <SC.OccurrencesTitle variant="body3">{title}</SC.OccurrencesTitle>
          {Array.isArray(occurrences) ? (
            <SC.OccurrenceList>
              {occurrences.map((occurrence, key) => (
                <SC.OccurrenceItem key={key}>
                  <SC.OccurrencesDescription variant="body3">
                    {occurrence.description || occurrence.message}
                  </SC.OccurrencesDescription>
                </SC.OccurrenceItem>
              ))}
            </SC.OccurrenceList>
          ) : (
            <SC.OccurrencesDescription variant="body3">
              {occurrences.description || occurrences.message}
            </SC.OccurrencesDescription>
          )}
          {showClose && (
            <SC.OccurrencesLinkContainer>
              <LinkButton variant="default" onClick={onClose}>
                {t('molecules.occurrencesBox.dismiss')}
              </LinkButton>
            </SC.OccurrencesLinkContainer>
          )}
        </SC.OccurrencesContainer>
      </SC.BorderedWrappingContentContainer>
    </SC.BorderedWrappingContainer>
  );
};
