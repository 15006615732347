import { useMemo } from 'react';
import { IAdvance } from '@containers/Advances/context/types/advance';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import qs from 'query-string';

import { IAdvanceForAccountingFilters } from '../../context/types/advances';

interface IFetch {
  shouldFetch?: boolean;
}
interface IProps extends IFetch, IAdvanceForAccountingFilters {}

export const useAdvancesForAccounting = (filters: IProps) => {
  const currentDate = useMemo(() => new Date(), [filters]);
  const endDate = useMemo(
    () => String(new Date(currentDate.setFullYear(currentDate.getFullYear() + 1))),
    [filters.status],
  );
  const startDate = useMemo(() => String(new Date(currentDate.setDate(currentDate.getDate() - 730))), [filters.status]);
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const params = useMemo(
    () =>
      qs.stringify(
        {
          ...filters,
          startDate,
          endDate,
        },
        { arrayFormat: 'bracket' },
      ),
    [filters.status],
  );

  const { data, error, isLoading, mutate } = useFetch<{
    limit: number;
    pages: number;
    total: number;
    data: IAdvance[];
  }>(`v1/advances/finance?${params}`, '', undefined, undefined, onError, filters?.shouldFetch);

  return {
    advancesData: {
      error,
      isLoading: isLoading,
      advances: data?.data ?? [],
      total: data?.total ?? 0,
    },
    refetch: mutate,
  };
};
