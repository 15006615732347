import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useCheckUnleashFlag } from '@shared/hooks/permissions/unleash/useCheckUnleashFlag';
import { EAuthZPermission } from '@shared/types/authZPermission';

export function usePayablesApprovalPermission() {
  const { checkPlatformPermission } = usePlatformPermissions();
  const checkFlag = useCheckUnleashFlag();

  const approvalTabEnable = checkFlag('FLASH_OS_COMPANY_PAYABLES_APPROVAL');
  const canApprove = checkPlatformPermission(EAuthZPermission.PAYABLES_APPROVE);

  return {
    canViewPayablesTab: approvalTabEnable && canApprove,
  };
}
