import { ReactNode, useMemo } from 'react';
import { PolicyGrid } from '@containers/Policies/componentes/organisms/PolicyGrid/PolicyGrid';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useDelegatePermissions } from '@shared/hooks/permissions/systemPermissions/useDelegatePermissions';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { TSystemFeatures } from '@shared/types/systemFeatures';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AdvancesPreview } from '../Advances/AdvancesPreview';
import { ApprovalsPreview } from '../ApprovalsPreview';
import { CategoriesPreview } from '../Categories/CategoriesPreview';
import { DelegationPreview } from '../Delegation/DelegationPreview';
import { FormsPreview } from '../Forms/FormsPreview';
import { GeneralSettingsPreview } from '../GeneralSettings/GeneralSettingsPreview';
import { ReportsPreview } from '../Reports/ReportsPreview';
import * as SC from './styled';

export function ExpenseCustomizationTabItem() {
  const translatePathBase: string = 'organisms.customizationsPreview.tabLabel';
  const { t } = useTranslation();

  const delegatePermissions = useDelegatePermissions();
  const { checkPlatformPermission, permissions } = usePlatformPermissions();
  const { checkLicenseFeature, features } = useLicenseFeatures();
  const [searchParams, setSearchParams] = useSearchParams();

  function deleteSearch() {
    if (searchParams.get('customizationTab')) {
      searchParams.delete('customizationTab');
      setSearchParams(searchParams);
    }
  }

  function getDataTab(label: string, component: ReactNode, key: string) {
    return {
      key,
      label,
      component: <SC.TabContainer onClick={() => deleteSearch()}>{component}</SC.TabContainer>,
    };
  }

  function checkViewTab(permission: EAuthZPermission, licenseFeature: TSystemFeatures) {
    return checkPlatformPermission(permission) && checkLicenseFeature(licenseFeature);
  }

  const items = useMemo(() => {
    const items = [];

    if (checkViewTab(EAuthZPermission.EXPENSE_VIEW, 'BASIC_EXPENSE'))
      items.push(getDataTab(t(`${translatePathBase}.generalSettings`), <GeneralSettingsPreview />, 'generalSettings'));

    if (checkViewTab(EAuthZPermission.CUSTOMIZATION_FORMS_VIEW, 'BASIC_EXPENSE'))
      items.push(getDataTab(t(`${translatePathBase}.forms`), <FormsPreview />, 'forms'));

    if (checkViewTab(EAuthZPermission.CUSTOMIZATION_REPORTS_VIEW, 'BASIC_EXPENSE'))
      items.push(getDataTab(t(`${translatePathBase}.reports`), <ReportsPreview />, 'reports'));

    if (checkViewTab(EAuthZPermission.CUSTOMIZATION_CATEGORIES_VIEW, 'BASIC_CATEGORIES'))
      items.push(getDataTab(t(`${translatePathBase}.categories`), <CategoriesPreview />, 'categories'));

    if (checkViewTab(EAuthZPermission.CUSTOMIZATION_POLICY_VIEW, 'BASIC_POLICIES'))
      items.push(getDataTab(t(`${translatePathBase}.policies`), <PolicyGrid />, 'policies'));

    if (checkViewTab(EAuthZPermission.CUSTOMIZATION_APPROVAL_VIEW, 'INTERMEDIATE_APPROVALS'))
      items.push(getDataTab(t(`${translatePathBase}.approvals`), <ApprovalsPreview />, 'approvals'));

    if (checkViewTab(EAuthZPermission.CONFIGURATION_ADVANCES, 'ADVANCED_ADVANCE'))
      items.push(getDataTab(t(`${translatePathBase}.advances`), <AdvancesPreview />, 'advances'));

    if (delegatePermissions.settingsApproval || delegatePermissions.settingsExpenses)
      items.push(getDataTab(t(`${translatePathBase}.delegation`), <DelegationPreview />, 'delegation'));

    return items;
  }, [permissions?.toString?.(), features?.toString?.()]);

  return <SC.Tabs variant="secondary" defaultTab={0} tabItens={items} />;
}
