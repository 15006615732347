import { StatusTag } from '@atoms/StatusTag';
import { Checkbox, Icons, IconTypes, LinkButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

type TProps = {
  onCheckboxClick: () => void;
  selectedLength: number;
  allSelected: boolean;
  totalItems: number;
  actions: {
    allowed: boolean;
    tooltip: string;
    disabled: boolean;
    label: string;
    icon: IconTypes;
    key: string;
    onClick: () => void;
  }[];
};

export function HeaderBulkActions({
  allSelected,
  selectedLength = 0,
  totalItems,
  actions = [],
  onCheckboxClick,
}: TProps) {
  const t = useTranslate('organisms.reportGrid');

  return (
    <tr className="data-grid-table-header-bulk-actions-container" role="row">
      <th className="data-grid-table-header-bulk-actions">
        <Checkbox
          onChange={onCheckboxClick}
          indeterminate={selectedLength > 0}
          checked={allSelected}
          aria-label="column row checkbox"
        />
      </th>
      <th className="data-grid-table-header-bulk-actions">
        <SC.ContainerActions>
          <StatusTag variant="secondary">{t('selectedItems', { totalItems, selectedItems: selectedLength })}</StatusTag>

          {actions.map((action, key) => {
            if (action.allowed != undefined ? action.allowed : true) {
              return (
                <div key={key}>
                  <Tooltip arrow title={action.disabled ? action.tooltip || '' : ''} placement="top">
                    <div>
                      {
                        <LinkButton variant="default" disabled={action.disabled}>
                          <SC.LinkContent onClick={action.onClick}>
                            <Icons name={action.icon} fill="transparent" />
                            {action.label}
                          </SC.LinkContent>
                        </LinkButton>
                      }
                    </div>
                  </Tooltip>
                </div>
              );
            }
          })}
        </SC.ContainerActions>
      </th>
    </tr>
  );
}
