import { dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

const INVALID_DATE = 'Invalid Date';

export const useDateFormatter = () => {
  const { i18n } = useTranslation();
  const emptyDate = '--/--/--';
  const emptyHours = '--:--';
  function getDateOnlyFormatter(date) {
    if (date && !date?.toString()?.includes('0001-01-01')) {
      return dayjs(date, { locale: 'pt-br' })?.format('DD/MM/YYYY') || emptyDate;
    }
    return emptyDate;
  }

  function getFormatDateLocation(date: Date) {
    const currentLanguage: string = i18n.language.toLowerCase();

    return date.toLocaleDateString(currentLanguage, { timeZone: 'UTC' });
  }

  function getDateWithTimeFormatter(date) {
    if (date) {
      const currentLanguage: string = i18n.language.toLowerCase();
      const currentDate = new Date(date);

      return `${currentDate
        .toLocaleString(currentLanguage, {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
        .replace(',', ' - ')}`;
    }

    return `${emptyDate} ${emptyHours}`;
  }

  function getTimeOnlyFormatter(date) {
    if (date) {
      const currentLanguage: string = i18n.language.toLowerCase();
      const currentDate = new Date(date);

      return currentDate.toLocaleTimeString(currentLanguage)?.substring(0, 5);
    }

    return emptyHours;
  }

  function getFormatDateUTC(date: Date | string) {
    if (!date || date === INVALID_DATE) return null;
    const currentDate = new Date(date);
    return currentDate.toISOString();
  }

  function getDateRangeSubtractCurrencyDay(subtractDays: number, referenceDate: Date = new Date()) {
    return {
      endDate: getFormatDateUTC(new Date(referenceDate)),
      startDate: getFormatDateUTC(new Date(referenceDate.setDate(referenceDate.getDate() - subtractDays))),
    };
  }

  return {
    getDateOnlyFormatter,
    getFormatDateLocation,
    getFormatDateUTC,
    getDateWithTimeFormatter,
    getTimeOnlyFormatter,
    getDateRangeSubtractCurrencyDay,
  };
};
