import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getAllReports } from '../context/queries/reports';
import { IPaginationSettings } from '../context/types/expenses';
import { IGetRequestResponse, IReportsSummary } from '../context/types/reports';

interface useReports {
  reports: IReportsSummary[];
  reportsIsLoading: boolean;
  currentPage: number;
  reportsTotalItems: number;
  reportsError: unknown;
}

export interface IUseReportsProps {
  limit: number;
  page: number;
}

export const useReports = ({ limit, page }: IUseReportsProps): useReports => {
  const {
    loading,
    data,
    error: reportsError,
    networkStatus,
  } = useQuery<IGetRequestResponse, IPaginationSettings>(getAllReports, {
    variables: {
      limit,
      page,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  return {
    reports: data?.getReports?.reports || [],
    reportsIsLoading: loading || networkStatus === NetworkStatus.loading,
    currentPage: data?.getReports?.currentPage,
    reportsTotalItems: data?.getReports?.total || 0,
    reportsError,
  };
};
