import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';

import { CustomFields } from './CustomFields/CustomFields';
import { FieldsSettings } from './FieldsSettings';
import { PaymentMethods } from './PaymentMethods/PaymentMethods';
import * as SC from './styled';

export function FormsPreview() {
  return (
    <SC.Container>
      <FieldsSettings />

      <PermissionsChecker licenseFeature="ADVANCED_USER_REGISTRATION">
        <PermissionsChecker licenseFeature="ADVANCED_EXPENSE">
          <CustomFields />
        </PermissionsChecker>
      </PermissionsChecker>

      <PermissionsChecker licenseFeature="INTERMEDIATE_PAYMENT_METHOD">
        <PaymentMethods />
      </PermissionsChecker>
    </SC.Container>
  );
}
