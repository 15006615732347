import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface IContainerProps {
  disabled?: boolean;
}

interface ICircularButtonProps {
  disabled?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
  border: ${({ theme }) => theme.borders.width.m} solid ${({ theme }) => theme.colors.neutral[70]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  padding: ${({ theme }) => theme.spacings.xs3} ${({ theme }) => theme.spacings.xs2};
  background-color: ${({ theme, disabled }) => theme.colors.neutral[!disabled ? 100 : 95]};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.7)};
`;

export const CircularButton = styled.div<ICircularButtonProps>`
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  padding: ${({ theme }) => theme.spacings.xs4};
  background-color: ${({ theme, disabled }) => theme.colors.neutral[!disabled ? 100 : 95]};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.7)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[95]};
  }
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600 !important;
`;

export const Icon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;
