import { EExportAnalyticsType } from '@containers/Analytics/context/types/export';
import { IconButton, Loader, Menu, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from 'react-i18next';

import { useAnalysisContainerActions } from './hooks/useAnalysisContainerActions';
import * as SC from './styled';

interface IProps {
  isLoading?: boolean;
  disabledActions?: boolean;
  onlyShowActions?: EExportAnalyticsType[];
  onExportClick: (analyticsExport: EExportAnalyticsType) => void;
}

export function AnalysisContainerExport({ isLoading, disabledActions, onlyShowActions, onExportClick }: IProps) {
  const { t } = useTranslation();
  const actionsOptions = useAnalysisContainerActions({ onlyShowActions, onExportClick });

  if (isLoading) {
    return (
      <SC.LoaderContainer>
        <Loader variant="primary" size="extraSmall" />
      </SC.LoaderContainer>
    );
  }

  return (
    <Tooltip arrow title={disabledActions ? t(`analytics.actions.loading`) : ''} placement="top-start">
      <div>
        <Menu type="select" options={actionsOptions} disabled={disabledActions}>
          <IconButton size="small" variant="line" icon={'IconDownload'} disabled={disabledActions} />
        </Menu>
      </div>
    </Tooltip>
  );
}
