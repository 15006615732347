import { IConfigurationAdvancesSetting } from '@containers/Customizations/context/types/advances';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export const useAdvancesSettings = () => {
  const { data, error, isLoading } = useFetch<IConfigurationAdvancesSetting>(
    'v1/advances/configuration',
    undefined,
    undefined,
    undefined,
    () => showToast({ message: `${error.message}`, type: 'error' }),
  );

  return {
    error,
    isLoading,
    settings: data as IConfigurationAdvancesSetting,
  };
};
