import { IConfigurationReportSetting } from '@containers/Customizations/context/types/settings/expenseReportSettingsTypes';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

interface UseReportsSettings {
  settings: IConfigurationReportSetting;
  isLoading: boolean;
  error: unknown;
}

export function useExpenseReportSettings(): UseReportsSettings {
  const { data, error, isLoading } = useFetch<IConfigurationReportSetting>('v1/settings/expense/report');

  error && showToast({ message: error?.message, type: 'error' });

  return {
    settings: data,
    isLoading: isLoading,
    error,
  };
}
