import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs,
}));

export const ContainerWaypoints = styled.div(({ theme }) => ({
  width: `400px`,
  padding: theme.spacings.xs,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  borderRadius: theme.borders.radius.s,
  marginBottom: theme.spacings.xs,
}));

export const WaypointContainer = styled.div({
  width: `100%`,
});

export const DistanceContainer = styled.div({
  width: `400px`,
});
