import { ReactElement } from 'react';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';

interface IProps {
  isLoading: boolean;
  children: ReactElement;
}

export function FieldRegister({ isLoading, children }: IProps) {
  if (isLoading) {
    return <Skeleton width={'100%'} height={56} />;
  }

  return children;
}
