import { useMemo } from 'react';
import { SubscriptionInfoItem } from '@containers/Subscription/components/molecules/SubscriptionInformation/SubscriptionInformation';
import { SAASContractInformation } from '@containers/Subscription/context/types/contractInformation';
import { useTranslation } from '@locale/Translator';

export function useSAASContractConditions(saasContract: SAASContractInformation | undefined) {
  const { t } = useTranslation();

  const saasContractItems: SubscriptionInfoItem[] = [
    {
      included: true,
      name: 'Funcionalidades detalhadas no contrato',
    },
    {
      included: true,
      name: 'Manutenções legais, corretivas e preventivas',
    },
    {
      included: true,
      name: 'Suporte técnico (via telefone e/ou e-mail)',
    },
    {
      included: true,
      name: 'Atualizações, updates e upgrades',
    },
  ];

  const saasContractCaption = useMemo(() => {
    const conditions = saasContract?.conditions;

    const items: string[] = [t('organisms.subscriptionDetails.details.caption.periodOfValidity')];

    if (conditions?.gracePeriodInMonths) {
      items.push(
        t('organisms.subscriptionDetails.details.caption.gracePeriodInMonths', {
          period: conditions?.gracePeriodInMonths,
        }),
      );
    }

    if (conditions?.discountPercentage) {
      items.push(
        t('organisms.subscriptionDetails.details.caption.discountPercentage', {
          discount: conditions?.discountPercentage,
        }),
      );
    }

    if (conditions?.discountPeriodInMonths) {
      items.push(
        t('organisms.subscriptionDetails.details.caption.discountPeriodInMonths', {
          period: conditions?.discountPeriodInMonths,
        }),
      );
    }

    if (conditions?.billingType === 'Centralizado na matriz') {
      items.push(t('organisms.subscriptionDetails.details.caption.billingTypeDescription'));

      if (conditions.billingRegistrationNumber) {
        items.push(
          t('organisms.subscriptionDetails.details.caption.billingTypeCompany', {
            registrationNumber: conditions.billingRegistrationNumber,
          }),
        );
      }
    }

    return items;
  }, [saasContract]);

  return {
    saasContractItems,
    saasContractCaption,
  };
}
