import { useEffect, useState } from 'react';
import { ISummaryReport } from '@containers/Expenses/context/types';
import { useSummaryReports } from '@containers/Expenses/hooks/useSummaryReports';
import { EReportStatus, IReport } from '@containers/Reports/context/types';
import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Autocomplete, Option } from '@molecules/AutoComplete';

import { DefaultModal } from '../../../../../components/molecules/Modal/DefaultModal';
import { useSummaryReportList } from './hooks/useSummaryReportList';
import { ModalReportSelectionSkeleton } from './ModalReportSelectionSkeleton';
import * as SC from './styled';

export interface ModalReportSelectionProps {
  selectedReportId: IReport['id'];
  open: boolean;
  actionIsLoading?: boolean;
  onCancelClick(): void;
  onActionClick(): void;
  onSelectReport?(selectedReport: ISummaryReport): void;
  onCreateReport?(): void;
}

export const ModalReportSelection = ({
  actionIsLoading,
  selectedReportId,
  open = true,
  onSelectReport,
  onCancelClick,
  onActionClick,
  onCreateReport,
}: ModalReportSelectionProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<IReport['id']>(selectedReportId);
  const [inputValueSearch, setInputValueSearch] = useState<string>('');

  const { reports, isLoading } = useSummaryReports({
    page: 0,
    limit: 100,
    status: EReportStatus.OPEN,
    onlyWithPendencies: true,
  });

  const options = useSummaryReportList({ reports });
  function onSelect(selected): void {
    const selectedItem = reports?.find(report => report.id == selected?.value);
    onSelectReport?.(selectedItem);
    setSelected(selected?.value);
  }

  function onCancel() {
    setSelected(null);
    onCancelClick?.();
  }

  const reportsNotFoundOption: Option = {
    value: 0,
    label: t('molecules.modalReportSelection.noReportsFound'),
    icon: 'IconAlertCircle',
  };

  useEffect(() => {
    selectedReportId && setSelected(selectedReportId);
  }, [selectedReportId]);

  return (
    <DefaultModal
      open={open}
      actionDisabled={!Boolean(selected)}
      labelButtonAction={t('molecules.modalReportSelection.select')}
      headerTitle={t('molecules.modalReportSelection.selectReport')}
      headerSubtitle={t('molecules.modalReportSelection.selectTheReportYouWantToAddTheExpenseToOrCreateANewReport')}
      onActionClick={onActionClick}
      isActionLoading={actionIsLoading}
      onCancelClick={onCancel}>
      {isLoading ? (
        <ModalReportSelectionSkeleton />
      ) : (
        <SC.Container>
          <SC.SelectContent>
            <Autocomplete
              label={t('molecules.modalReportSelection.report')}
              value={selected}
              onInputChange={setInputValueSearch}
              onSelectChange={selected => onSelect({ ...selected })}
              options={options?.length === 0 ? [reportsNotFoundOption] : options}
              noOptionContent={t('molecules.modalReportSelection.noReportsFound')}
              inputValue={inputValueSearch}
            />
          </SC.SelectContent>
          <SC.LinkContent>
            <LinkButton onClick={onCreateReport} variant="default">
              <SC.LinkLabelContainer>
                {t('molecules.modalReportSelection.createReport')}
                <SC.PlusIcon />
              </SC.LinkLabelContainer>
            </LinkButton>
          </SC.LinkContent>
        </SC.Container>
      )}
    </DefaultModal>
  );
};
