import { IConfigurationCategory } from '@containers/Customizations/context/types/categoryTypes';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export function useCategories(shouldFetch: boolean = true) {
  const { data, error, isLoading, mutate } = useFetch<{ categories: IConfigurationCategory[] }>(
    `v1/categories`,
    undefined,
    undefined,
    undefined,
    error => showToast({ message: error?.message, type: 'error' }),
    shouldFetch,
  );

  return {
    categories: data?.categories ?? [],
    isLoading: isLoading,
    error,
    refetch: () => mutate(),
  };
}
