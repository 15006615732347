import React from 'react';

import * as SC from './styled';

interface IBulkImportContainerProps {
  children: React.ReactNode;
}

export const BulkImportContainer = ({ children }: IBulkImportContainerProps) => {
  return <SC.Container>{children}</SC.Container>;
};
