/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { ReactNode } from 'react';
import { useUsersSelectionList } from '@containers/Expenses/hooks/useUsersSelectionList';
import { EReportViewType, IReportSummariesFilter } from '@containers/Reports/context/types';
import { Loader, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { IDrawerFilter } from '@molecules/FiltersDrawer';
import { GridFilters } from '@molecules/GridFilters';
import { SelectOption, serializeSelectOptions } from '@shared/helpers/selectOptions';

import { useManagerFiltersReport } from './hooks/useManagerFiltersReport';
import * as SC from './styled';

enum EFilters {
  awaitingImmediateApproverDecision = 'awaitingImmediateApproverDecision',
  status = 'status',
  periodDate = 'periodDate',
  createdBy = 'createdBy',
}

enum EFilterType {
  awaitingImmediateApproverDecision = 'list',
  status = 'multiple',
  createdBy = 'list',
  periodDate = 'period',
}
export interface FinancialReportGridFiltersProps {
  exportExcelIsLoading?: boolean;
  isLoading: boolean;
  optionsSelectStatus: SelectOption[];
  selectedFilters: IReportSummariesFilter;
  viewType: EReportViewType;
  maxFiltersShown?: number;
  showMoreFiltersButton?: boolean;
  onExportToExcelClick?(filters: IReportSummariesFilter): void;
  onFiltersChange(filters: IReportSummariesFilter): void;
}

export const ReportSummariesFilters = ({
  optionsSelectStatus,
  selectedFilters,
  exportExcelIsLoading,
  isLoading,
  viewType,
  maxFiltersShown,
  showMoreFiltersButton = false,
  onExportToExcelClick,
  onFiltersChange,
}: FinancialReportGridFiltersProps) => {
  const { t } = useTranslation();
  const { events, applyAllFilters, onClearAllFilters } = useManagerFiltersReport({
    onChange: onFiltersChange,
    selectedFilters,
  });
  const hasPeriodDateFilters = selectedFilters?.startDate && selectedFilters?.endDate;
  const { users, isLoading: isLoadingUser } = useUsersSelectionList();

  function getOptions(filterKey: EFilters) {
    if (filterKey === EFilters.createdBy) {
      return serializeSelectOptions({ options: users });
    }
    if (filterKey === EFilters.status) {
      return optionsSelectStatus;
    }
    if (filterKey === EFilters.awaitingImmediateApproverDecision)
      return [
        {
          label: t('organisms.ApprovalsReportGrid.pendingOptions.true'),
          value: 'true',
        },
        {
          label: t('organisms.ApprovalsReportGrid.pendingOptions.false'),
          value: 'false',
        },
      ];
  }

  function getSelectedOptions(filterKey: string) {
    const type: EFilterType = EFilterType[filterKey];

    switch (type) {
      case 'multiple':
        return selectedFilters[filterKey];
      case 'list':
        return selectedFilters[filterKey] !== undefined ? [String(selectedFilters[filterKey])] : null;
      default:
        return null;
    }
  }

  function getDateRange(filterKey: string) {
    const type: EFilterType = EFilterType[filterKey];
    if (type === 'period') {
      return (
        (selectedFilters?.startDate && {
          from: new Date(selectedFilters?.startDate),
          to: selectedFilters?.endDate ? new Date(selectedFilters?.endDate) : null,
        }) ||
        null
      );
    }
    return null;
  }

  function getFilters() {
    const currentFilters: IDrawerFilter[] = [];

    Object.keys(EFilters).forEach(key => {
      if (
        key !== EFilters.awaitingImmediateApproverDecision ||
        (viewType === EReportViewType.APPROVAL && key === EFilters.awaitingImmediateApproverDecision)
      ) {
        currentFilters.push({
          label: t(`report.filters.label.${key}`),
          onClick: events[EFilters[key]],
          type: EFilterType[key],
          options: getOptions(EFilters[key]),
          selectedOptions: getSelectedOptions(key),
          dateRange: getDateRange(key),
          key,
          value: selectedFilters[key] || null,
        });
      }
    });

    return currentFilters;
  }

  function getActions() {
    const actions: ReactNode[] = [];

    if (viewType === EReportViewType.FINANCIAL)
      actions.push(
        <div key="action-export">
          <Tooltip
            arrow
            title={
              !hasPeriodDateFilters && !exportExcelIsLoading
                ? t(`organisms.financialReportGrid.actionsOptions.tooltip.exportExcel`)
                : ''
            }
            placement="top-start">
            <div>
              <SC.ExportButton
                disabled={exportExcelIsLoading}
                variant="secondary"
                size="small"
                onClick={() => onExportToExcelClick(selectedFilters)}>
                {exportExcelIsLoading && <Loader variant="secondary" size="extraSmall" />}
                {t(`report.actions.exportToExcel`)}
              </SC.ExportButton>
            </div>
          </Tooltip>
        </div>,
      );

    return actions;
  }

  return (
    <SC.Container>
      <GridFilters
        searchDisabled={isLoading}
        isLoading={isLoadingUser}
        showMoreFiltersButton={showMoreFiltersButton}
        labelSearchField={t(`report.filters.label.search`)}
        onSearchChange={events.search}
        filters={getFilters()}
        showSearchField
        onApplyAllFilters={applyAllFilters}
        onClearAllFilters={onClearAllFilters}
        selectedFilters={selectedFilters}
        maxFiltersShown={maxFiltersShown}
        actions={getActions()}
      />
    </SC.Container>
  );
};
