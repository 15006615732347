import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

import { IWaypointIdentifierProps } from './WaypointIdentifier';

interface IIdentificationIconProps {
  isActive: boolean;
  variant: IWaypointIdentifierProps['variant'];
}

export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IdentificationIcon = styled(Icons)<IIdentificationIconProps>(({ theme, isActive, variant }) => ({
  margin: `${theme.spacings.xs3} 0`,
  color: theme.colors.neutral[60],

  '&:first-child': {
    marginTop: '26px',
  },

  ...(isActive && {
    color: theme.colors.primary,
  }),

  ...(variant === 'viewOnly' && {
    border: `${theme.borders.width.s} solid ${theme.colors.primary}`,
    borderRadius: theme.borders.radius.circular,
    margin: `${theme.spacings.xs4} 0`,

    '&:first-child': {
      marginTop: theme.spacings.xs4,
    },
  }),
}));
