import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export function useDelegateRequestingUsers() {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const { data, error, isLoading } = useFetch<[{ id: string; name: string; email: string }]>(
    `v1/user-delegation/requesting-users`,
    '',
    undefined,
    undefined,
    onError,
  );

  return {
    delegateRequestingUsers: data || [],
    isLoading: isLoading,
    error,
  };
}
