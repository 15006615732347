import { gql } from '@apollo/client';

export const getPaymentMethods = gql`
  query GetPaymentMethods {
    getPaymentMethods {
      paymentMethods {
        id
        description
        active
        isCard
      }
    }
  }
`;
