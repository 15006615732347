import React from 'react';
import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

export interface DangerActionModalProps {
  /**
   * if 'true' open the modal
   */
  open: boolean;

  /**
   * if 'true' the button shows loading status
   */
  isLoading?: boolean;

  /**
   * Modal content
   */
  children?: React.ReactNode;

  /**
   * Icon Label header
   *
   */
  headerIconLabel?: string;

  /**
   * Title header
   *
   */
  headerTitle?: string;

  /**
   * Subtitle header
   *
   */
  headerSubtitle?: string;

  /**
   * Text or custom item in header
   *
   */
  headerCustom?: React.ReactNode;

  /**
   * Text or custom item in footer
   *
   */
  footerCustom?: React.ReactNode;

  /**
   * Confirmation button custom text or item
   *
   */
  labelButtonAction?: React.ReactNode;

  /**
   * Icon Action Button
   *
   */
  iconActionButton?: React.ReactNode;

  /**
   * Cancel button custom text or item
   *
   */
  labelButtonCancel?: React.ReactNode;

  /**
   * if 'true' disable button action
   */
  actionDisabled?: boolean;

  /**
   * Cancel event
   *
   */
  onCancelClick?(): void;

  /**
   * Close event
   *
   */
  onCloseClick?(): void;

  /**
   * Action event
   *
   */
  onActionClick?(): void;
}

export const DangerActionModal = ({
  open,
  isLoading,
  children,
  headerIconLabel,
  headerTitle,
  headerSubtitle,
  headerCustom,
  footerCustom,
  labelButtonAction,
  iconActionButton,
  labelButtonCancel,
  actionDisabled,
  onCancelClick,
  onCloseClick,
  onActionClick,
}: DangerActionModalProps) => {
  const { t } = useTranslation();

  return (
    <SC.DangerActionModal open={open} onClose={onCloseClick}>
      <SC.ModalContainer>
        <SC.ModalHeaderContainer>
          {Boolean(headerCustom) ? (
            headerCustom
          ) : (
            <>
              <SC.IconClose onClick={onCloseClick || onCancelClick} />
              <SC.IconHeaderContainer>
                <SC.AlertIcon />
              </SC.IconHeaderContainer>

              {Boolean(headerIconLabel) && <SC.IconLabel>{headerIconLabel}</SC.IconLabel>}
              {Boolean(headerTitle) && <SC.Title>{headerTitle}</SC.Title>}
              {Boolean(headerSubtitle) && <SC.Subtitle>{headerSubtitle}</SC.Subtitle>}
            </>
          )}
        </SC.ModalHeaderContainer>
        {Boolean(children) && <SC.ModalBodyContainer>{children}</SC.ModalBodyContainer>}
        <SC.ModalFooterContainer>
          {Boolean(footerCustom) ? (
            footerCustom
          ) : (
            <>
              <div>
                <LinkButton variant="default" onClick={onCancelClick || onCloseClick}>
                  {labelButtonCancel || t('molecules.defaultModal.cancel')}
                </LinkButton>
              </div>
              <div>
                <SC.ActionButton
                  loading={isLoading}
                  disabled={actionDisabled}
                  onClick={onActionClick}
                  buttonType="primary"
                  variantType="error"
                  size="large"
                  variant="primary">
                  {labelButtonAction || t('molecules.defaultModal.confirm')}
                  {Boolean(iconActionButton) && (
                    <SC.IconActionButtonContainer>{iconActionButton}</SC.IconActionButtonContainer>
                  )}
                </SC.ActionButton>
              </div>
            </>
          )}
        </SC.ModalFooterContainer>
      </SC.ModalContainer>
    </SC.DangerActionModal>
  );
};
