import { SAASContractInformation } from '@containers/Subscription/context/types/contractInformation';

function isNumeric(str: string) {
  return /^\d+$/.test(str);
}

function isEmpty(str: string) {
  const nullStatuses = ['-', '—', 'não informado', 'não se aplica', 'não irá integrar', 'padrão'];

  return nullStatuses.includes(str.trim().toLowerCase());
}

export function valueIsFormatted(value: string): boolean {
  if (value.includes('.')) value.replace('.', ',');

  return value.includes('R$') || value.includes(',');
}

export function parseContractValue(value: unknown): number | string | undefined {
  if (typeof value === 'number') return value;

  if (typeof value === 'string') {
    if (isNumeric(value)) {
      return Number(value);
    }

    if (valueIsFormatted(value)) return value;

    if (isEmpty(value)) return undefined;

    return value;
  }

  return undefined;
}

export function mapERPColumn(erp: string | undefined): SAASContractInformation['conditions']['erpIntegrations'] {
  if (erp) {
    const erps = erp
      .split('<br />')
      .map(item => item.trim())
      .filter(item => !!item);

    const erpDots = '...................................................';

    return erps.map(erp => {
      const [name = '', value = ''] = erp.split(erpDots);

      const price = parseFloat(
        value
          .trim()
          .replace(/[^\d.,]/g, '')
          .replace(',', '.'),
      );

      return {
        name: name.trim(),
        value: isNaN(price) ? undefined : price,
      };
    });
  }

  return [];
}
