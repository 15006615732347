import { useState } from 'react';
import { SettingModal } from '@containers/Settings/components/molecules/SettingModal';
import { Trans } from 'react-i18next';

import { Expenses } from './Expenses';

export const InitialPage = () => {
  const [hasAlreadyConfigured, setHasAlreadyConfigured] = useState(true);

  return (
    <Trans i18nKey={'translations'}>
      {!hasAlreadyConfigured && <SettingModal isOpen={true} onClose={() => setHasAlreadyConfigured(true)} />}

      <Expenses />
    </Trans>
  );
};
