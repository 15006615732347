import { Icons, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

interface IProps {
  establishment: string;
  sumUp?: boolean;
}

export function EstablishmentGrid({ establishment, sumUp = false }: IProps) {
  const separatorIndex = establishment?.indexOf('|');

  if (!sumUp || separatorIndex === -1 || !separatorIndex) {
    return <>{establishment}</>;
  }

  return (
    <Tooltip arrow title={establishment.substring(separatorIndex + 1, establishment.length)} placement="top-start">
      <div>
        <SC.Container>
          <p>{establishment.substring(0, separatorIndex)}</p>
          <SC.IconContainer>
            <Icons name="IconInfoCircle" size={16} fill="transparent" />
          </SC.IconContainer>
        </SC.Container>
      </div>
    </Tooltip>
  );
}
