import { ISubsidiary } from '@containers/Subsidiaries/context/types/subsidiaries';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { mutate } from 'swr';

export const useUpdateSubsidiaryStatus = (subsidiaries: ISubsidiary[]) => {
  const subsidiaryMutate = useMutate('v1/subsidiaries/toggle');
  const { t } = useTranslation();

  async function updateStatus(ids: ISubsidiary['id'][], active: boolean) {
    const body = {
      subsidiaryIds: ids,
      active,
    };

    const response = await subsidiaryMutate.mutation.trigger(
      {
        options: {
          method: 'POST',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
      },
    );

    response?.forEach(subsidiary => {
      if (!subsidiary.updated)
        showToast({
          title: `${t('pages.subsidiaries.feedbacks.subsidiary')} ${
            subsidiaries?.find(_subsidiary => _subsidiary.id == subsidiary.id)?.name ?? ''
          }`,
          message: t('pages.subsidiaries.feedbacks.update.error'),
          type: 'error',
        });
      else
        showToast({
          title: `${t('pages.subsidiaries.feedbacks.subsidiary')} ${
            subsidiaries?.find(_subsidiary => _subsidiary.id == subsidiary.id)?.name ?? ''
          }`,
          message: t('pages.subsidiaries.feedbacks.update.success'),
          type: 'success',
        });
    });

    mutate(key => typeof key === 'string' && key.includes('v1/subsidiaries?page='));
  }
  return {
    updateStatus,
    deletedSubsidiaries: subsidiaryMutate.mutation.data,
    error: subsidiaryMutate.mutation.error,
    isLoading: subsidiaryMutate.mutation.isMutating,
  };
};
