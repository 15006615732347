import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import { IGetClientsList } from '../../context/types/clientTypes';

interface useClientListProps {
  searchTerm?: string;
  page: number;
}

export const useClients = ({ searchTerm, page }: useClientListProps) => {
  const { t } = useTranslation();
  const onError = () => showToast({ message: t('molecules.clientToast.getClientsErrorMessage'), type: 'error' });

  const params = new URLSearchParams({ page: page.toString(), searchTerm });
  const { data, isLoading, mutate } = useFetch<IGetClientsList>(
    `v1/clients?${params}`,
    '',
    undefined,
    undefined,
    onError,
  );

  return {
    isLoading,
    clients: data?.clients || [],
    total: data?.total ?? 0,
    refetch: () => mutate(),
    clientsFetcher: '',
  };
};
