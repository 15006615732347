import { EReportStatus, IReport } from '@containers/Reports/context/types';
import { expensesAccountingBaseURL, expensesApprovalsBaseURL } from '@shared/constant';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';

export function useWithdrawPermissions() {
  const getCurrentUrlBase = useCurrentUrlBase();

  function getPermissions(reportStatus: IReport['status']) {
    const permissions = {
      link: false,
      unlink: false,
      view: true,
      hasAccounting: false,
      hasApprover: false,
    };

    switch (getCurrentUrlBase()) {
      case expensesAccountingBaseURL:
        return {
          ...permissions,
          unlink: reportStatus === EReportStatus.APPROVED,
          hasAccounting: true,
        };
      case expensesApprovalsBaseURL:
        return {
          ...permissions,
          unlink: reportStatus === EReportStatus.SUBMITTED,
          hasApprover: true,
        };
      default:
        const reportStatusIsOpen = reportStatus === EReportStatus.OPEN;
        return {
          ...permissions,
          link: reportStatusIsOpen,
          unlink: reportStatusIsOpen,
        };
    }
  }

  return getPermissions;
}
