import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 'min-content';
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const TagContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;

export const Caption = styled(Typography)`
  font-weight: 600 !important;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const OpenButtonContainer = styled.div`
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const DrawerContainer = styled.div`
  position: absolute;
  z-index: 10000;
  overflow: hidden;
  height: 1px;
`;

export const Backdrop = styled.div`
  background-color: ${props => props.theme.colors.neutral[60]};
  opacity: 0.8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
`;
