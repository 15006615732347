import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface FilterLabelProps {
  active?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.s};

  .MuiSkeleton-rounded {
    border-radius: 50px;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs3};
  align-items: center;
  flex-wrap: nowrap;

  .MuiButtonBase-root {
    align-self: unset !important;

    &.active {
      background-color: ${props => props.theme.colors.secondary[95]} !important;
      border-color: ${props => props.theme.colors.secondary[95]} !important;

      svg {
        color: ${props => props.theme.colors.secondary[50]} !important;
      }
    }
  }
`;

export const FilterBadge = styled(Typography)`
  align-items: center;
  aspect-ratio: 1 / 1;
  background-color: ${props => props.theme.colors.secondary[50]} !important;
  border-radius: ${props => props.theme.borders.radius.circular};
  color: ${props => props.theme.colors.neutral[100]} !important;
  display: flex;
  font-weight: 700 !important;
  justify-content: center;
  width: 24px;
`;

export const FiltersLabel = styled(Typography)<FilterLabelProps>`
  font-weight: 600 !important;
  color: ${props => (props.active ? props.theme.colors.secondary[50] : props.theme.colors.neutral[40])};
  white-space: nowrap;
`;

export const FilterContainer = styled.div`
  width: max-content;
  display: flex;
  gap: ${props => props.theme.spacings.xs3};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${props => props.theme.spacings.s};
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs3};
  align-items: center;
  /* width: 100%; */
`;

export const ActionContainer = styled.div`
  width: min-content;
`;

export const FilterLabel = styled(Typography)`
  white-space: nowrap;
  font-weight: 600 !important;
`;

export const FiltersDrawerContainer = styled.div`
  position: absolute;
`;

export const DrawerContainer = styled.div`
  position: absolute; ;
`;

export const ContainerButtonTag = styled.div`
  .flamingo-ui-button.MuiPaper-root.MuiButtonBase-root {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    font-weight: 600;
    padding: ${({ theme }) => theme.spacings.xs4} ${({ theme }) => theme.spacings.xs3};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.neutral[40]};
    border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[80]};
    border-radius: ${({ theme }) => theme.borders.radius.xl};

    &:hover {
      border-color: transparent;
      background-color: ${({ theme }) => theme.colors.neutral[90]};
    }
  }
`;
