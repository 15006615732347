import { DistanceRoutes } from '@containers/Expenses/components/molecules/DistanceRoutes/DistanceRoutes';
import { ReceiptsImages } from '@containers/Expenses/components/molecules/ReceiptsImages';
import { ESizeVariant } from '@containers/Expenses/components/molecules/ReceiptsImages/variants';
import { IRoute } from '@containers/Expenses/context/types/expenseById';
import { useSignedFileUrl } from '@containers/Expenses/hooks/useSignedFileUrl';
import { FormProvider, useForm } from 'react-hook-form';

import { WaypointField } from '../../WaypointFields/WaypointField/WaypointField';
import * as SC from './styled';

interface IProps {
  isLoading: boolean;
  route: Pick<IRoute, 'distance' | 'imagePath' | 'origin' | 'destination' | 'ratePerKm'>;
}

export function GPSDetails({ route, isLoading }: IProps) {
  const { isLoading: isLoadingImg, files } = useSignedFileUrl([route.imagePath]);

  const methods = useForm({
    values: {
      route: route,
    },
  });

  return (
    <SC.Container>
      <ReceiptsImages
        receiptsImages={files.map(({ path, contentType, base64, url }) => ({
          contentType,
          base64,
          path,
          previewUrl: url,
        }))}
        previewOnly
        size={ESizeVariant.large}
        isLoading={isLoadingImg}
      />

      <SC.DetailsContainer>
        <SC.RouteContainer>
          <FormProvider {...methods}>
            <WaypointField
              index={0}
              currentValue={{ place: route.origin }}
              fieldType="origin"
              disabledDelete
              viewOnly
            />

            <WaypointField
              index={1}
              currentValue={{ place: route.destination }}
              fieldType="destination"
              disabledDelete
              viewOnly
            />
          </FormProvider>
        </SC.RouteContainer>

        <DistanceRoutes distance={route?.distance || 0} isLoading={isLoading} variant="secondary" />
      </SC.DetailsContainer>
    </SC.Container>
  );
}
