import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  gap: ${props => props.theme.spacings.xs};
`;

export const Box = styled(BorderedWrapping)`
  display: grid;
  grid-template-columns: auto 52px;
  padding: ${props => props.theme.spacings.xs};
  width: 423px;
`;

export const DistanceBox = styled(BorderedWrapping)`
  align-items: center;
  display: grid;
  gap: ${props => props.theme.spacings.xs};
  grid-template-columns: 24px auto;
  padding: ${props => props.theme.spacings.xs};
  width: 312px;
`;

export const Title = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
  font-size: 16px !important;
`;

export const Text = styled(Typography)``;

export const TextContainer = styled.div``;

export const Icon = styled(Icons)`
  color: ${props => props.theme.colors.primary};
  fill: transparent;
`;
