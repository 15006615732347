import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface ReportCardContainerProps {
  $isEmpty?: boolean;
}

export const ReportCardContainer = styled.div<ReportCardContainerProps>`
  cursor: pointer;
  padding: ${props => props.theme.spacings.xs};
  width: 285px;
  height: 100%;
  filter: ${props => (props.$isEmpty ? 'grayscale(100%)' : 'none')};
  opacity: ${props => (props.$isEmpty ? 0.3 : 1)};
`;

export const ReportCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ReportCardBody = styled.div`
  padding-top: ${props => props.theme.spacings.xs3};
  padding-bottom: ${props => props.theme.spacings.xs3};
`;

export const ReportCodeLabelContainer = styled.div`
  margin-bottom: ${props => props.theme.spacings.xs4};
`;

export const ReportCodeLabel = styled(Typography)`
  color: ${props => props.theme.colors.neutral.dark.dark5};
  text-transform: uppercase;
`;

export const ReportTitleLabel = styled(Typography)`
  color: ${props => props.theme.colors.neutral.dark.dark3};
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ReportTitleLabelEmpty = styled(Typography)`
  color: ${props => props.theme.colors.neutral.light.light3};
`;

export const ReportCardFooter = styled.div`
  display: flex;
`;
