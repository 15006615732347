import { useState } from 'react';
import { EExpenseType, ISummaryReport } from '@containers/Expenses/context/types';
import { useKmPolicies } from '@containers/Expenses/hooks/useKmPolicies';
import { useManualExpenses } from '@containers/Expenses/hooks/useManualExpenses';
import { NewReportModal } from '@containers/Reports/components/molecules/NewReportModal';
import { useReportMutate } from '@containers/Reports/hooks/useReportMutate';
import { Button, MenuOptionType } from '@flash-tecnologia/hros-web-ui-v2';
import { DropdownItem } from '@molecules/DropdownItem';
import { IReportNavigate, useExpenseRegisterDriver } from '@shared/hooks/navigation/useExpenseRegisterDriver';
import { useCheckKmIsAllowed } from '@shared/hooks/permissions/systemPermissions/useCheckKmIsAllowed';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useTracking } from '@shared/hooks/useTracking';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { useNavigate } from 'react-router-dom';

import { ModalReportSelection } from '../../molecules/ModalReportSelection';
import * as SC from './styled';

export const ExpenseActions = () => {
  const t = useTranslate('pages.sharedExpenses');
  const trackingEvent = useTracking();
  const navigate = useNavigate();
  const [showReportSelection, setShowReportSelection] = useState(false);
  const [showNewReport, setShowNewReport] = useState(false);
  const [reportSelected, setReportSelected] = useState<ISummaryReport>();
  const [expenseType, setExpenseType] = useState<EExpenseType>(EExpenseType.MANUAL);
  const { createReport, isLoading: isLoadingCreateReport } = useReportMutate();
  const { allowOdometer, allowOriginDestination, loading } = useKmPolicies();
  const { manualExpenseEntry } = useManualExpenses();
  const navigateExpenseEdit = useExpenseRegisterDriver(true);
  const isKmAllowed = useCheckKmIsAllowed();
  const { checkPlatformPermission } = usePlatformPermissions();
  const createPayablesEnabled = checkPlatformPermission(EAuthZPermission.EXPENSE_PAYABLES_CREATE_BILL);
  const options = getAddOptions();

  function onNavigateExpenseRegister(_report?: IReportNavigate) {
    navigateExpenseEdit({
      expenseType,
      linkedReport: _report || {
        name: reportSelected?.name,
        id: reportSelected?.id,
        description: reportSelected?.description,
      },
    });
  }

  const handleReportCreation = async report => {
    trackingEvent('report_create_clicked');

    const response = await createReport(report);
    const _report: IReportNavigate = {
      name: response.name,
      id: response.id,
    };
    onNavigateExpenseRegister(_report);
  };

  function onOpenReport() {
    setShowReportSelection(show => !show);
    onNavigateExpenseRegister();
  }

  function onCancel() {
    setShowReportSelection(show => !show);
    setReportSelected(null);
  }

  function onSelectReport(report: ISummaryReport) {
    setReportSelected(report);
  }

  function onExpenseRegisterClick(_expenseType: EExpenseType) {
    trackingEvent('expense_add_clicked', {
      expenseType: _expenseType,
    });

    setExpenseType(_expenseType);
    setShowReportSelection(show => !show);
  }

  function getAddOptions() {
    const permittedKmExpense = isKmAllowed && (allowOdometer || allowOriginDestination);
    const options: MenuOptionType[] = [];

    if (manualExpenseEntry)
      options.push({
        children: <DropdownItem description={t('expenses')} icon="IconReceipt" />,
        onClick: () => onExpenseRegisterClick(EExpenseType.MANUAL),
      });

    if (manualExpenseEntry && permittedKmExpense)
      options.push({
        children: <DropdownItem description={t('mileage')} icon="IconMapPin" />,
        onClick: () =>
          onExpenseRegisterClick(allowOriginDestination ? EExpenseType.ORIGIN_DESTINATION : EExpenseType.ODOMETER),
      });

    if (createPayablesEnabled)
      options.push({
        children: <DropdownItem description={t('addCompanyPayable')} icon="IconBox" />,
        onClick: () => navigate('/expense/company-payables/bills/form'),
      });

    return options;
  }

  return (
    <>
      {(manualExpenseEntry || createPayablesEnabled) &&
        (options?.length >= 1 ? (
          <SC.ActionsButton options={options}>
            <Button loading={loading} size="large" variant="primary">
              {t('add')}
              <SC.IconAdd name="Add" />
            </Button>
          </SC.ActionsButton>
        ) : (
          <Button
            loading={loading}
            size="large"
            variant="primary"
            onClick={() => onExpenseRegisterClick(EExpenseType.MANUAL)}>
            {t('add')}
            <SC.IconAdd name="Add" />
          </Button>
        ))}
      <ModalReportSelection
        selectedReportId={reportSelected?.id}
        open={showReportSelection}
        onActionClick={onOpenReport}
        onCancelClick={onCancel}
        onSelectReport={reportSelected => onSelectReport(reportSelected)}
        onCreateReport={() => setShowNewReport(true)}
      />
      <NewReportModal
        open={showNewReport}
        isLoading={isLoadingCreateReport}
        actionType={'create'}
        onCancelClick={() => setShowNewReport(false)}
        onActionClick={handleReportCreation}
      />
    </>
  );
};
