import { gql } from '@apollo/client';

export const getDistance = gql`
  query GetDistance($waypoints: [WaypointInput!]!) {
    getDistance(waypoints: $waypoints) {
      distance
      waypoints {
        placeId
        place
        address
        lat
        lng
      }
    }
  }
`;

export const getKmRate = gql`
  query GetKmRate($date: String!, $projectId: Int) {
    getKmRate(date: $date, projectId: $projectId)
  }
`;
