import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';

import { getMileageByPolicyId, updateMileageSettings } from '../context/queries';
import { IMileagePolicySettings } from '../context/types/policies';

export interface MileageSettingsMutationProps {
  policyId: number;
  policyMileageId: number;
  policyMileageSettingsInput: Partial<IMileagePolicySettings>;
}

export function useMileageSettingsMutate() {
  const [mileageMutate, { data, loading, error }] = useMutation<unknown, MileageSettingsMutationProps>(
    updateMileageSettings,
    {
      refetchQueries: ['getMileageByPolicyId', getMileageByPolicyId],
    },
  );

  return {
    mileageMutate,
    data,
    isLoading: loading,
    error,
  };
}
