import { useMemo } from 'react';
import {
  EExpenseFieldsLicenseFeatures,
  EExpenseFieldType,
  EExpenseType,
  EFieldType,
  IExpenseFields,
  IExpenseInput,
} from '@containers/Expenses/context/types';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { useTranslation } from '@locale/Translator';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';
import { useCheckIsKmType } from '@shared/hooks/useCheckIsKmType';

import { SelectOption, serializeSelectOptions } from '../../../../../../../shared/helpers/selectOptions';
import { useOrderFields } from './useOrderFields';

export interface UseFieldTypeConfigProps {
  fields: IExpenseFields;
  expense: IExpense;
}

export interface Field {
  ref: string;
  name?: keyof IExpenseInput;
  label?: string;
  type?: string;
  active: boolean;
  allowsEditing?: boolean;
  required: boolean;
  value?: number | string | boolean;
  options?: SelectOption[];
  order?: number;
  licenseFeature?: EExpenseFieldsLicenseFeatures;
}

export enum IExpenseRefFields {
  id = 'id',
  client = 'clientId',
  project = 'projectId',
  category = 'categoryId',
  address = 'establishment',
  receiptFile = 'receiptFile',
  currency = 'currencyCode',
  paymentType = 'paymentMethodId',
  value = 'value',
  date = 'expenseDate',
  description = 'description',
  costCenter = 'costCenterId',
  refundable = 'refundable',
  report = 'reportId',
  invoice = 'invoice',
  custom = 'customFields',
  quantity = 'quantity',
  billable = 'billable',
  invoiceKey = 'invoiceKey',
}

export function useFieldTypeConfig({ fields, expense }: UseFieldTypeConfigProps): Field[] {
  const { t } = useTranslation();
  const { checkLicenseFeature } = useLicenseFeatures();
  const expenseType = useCheckExpenseType();
  const { isKmType } = useCheckIsKmType(expenseType);

  function setDisabledField(field: IExpenseRefFields, allowsEditing: boolean) {
    const hasValue = field === IExpenseRefFields.value || field === IExpenseRefFields.currency;
    const isEdit: boolean = expense && Object.keys(expense).length > 0;

    // it should disabled payment method if intermediate plan not enabled
    if (field == IExpenseRefFields.paymentType && !checkLicenseFeature('INTERMEDIATE_PAYMENT_METHOD')) {
      return false;
    }

    if (isEdit && !!expense.cardId) {
      if (
        field === IExpenseRefFields.value ||
        field === IExpenseRefFields.address ||
        field === IExpenseRefFields.date ||
        field === IExpenseRefFields.currency ||
        field === IExpenseRefFields.paymentType
      ) {
        return false;
      }
    }

    if (field === IExpenseRefFields.category && isKmType) {
      return false;
    }

    if (isKmType && hasValue) {
      return false;
    }

    if (field === IExpenseRefFields.currency && !checkLicenseFeature('ADVANCED_INTERNATIONALIZATION')) {
      return false;
    }

    if (field === IExpenseRefFields.date && expenseType === EExpenseType.GPS) {
      return false;
    }

    return allowsEditing;
  }

  function activeField(field: IExpenseRefFields, active: boolean) {
    if (isKmType && field == IExpenseRefFields.address) {
      return false;
    }

    return active;
  }

  const fieldsData: Field[] = useMemo(() => {
    const currentFields = { ...fields };
    delete currentFields.__typename;

    const formFields = Object.keys(currentFields).reduce((result, field) => {
      if (field == EFieldType.CUSTOM.toLowerCase()) {
        return [
          ...result,
          ...fields[field]?.map(customField => ({
            ref: customField.id,
            name: `customFields.${customField.id}`,
            label: customField.label,
            type: customField.type,
            active: customField.active,
            required: customField.required,
            options: customField.options && serializeSelectOptions({ options: customField.options }),
            order: customField.fieldOrder,
            licenseFeature: EExpenseFieldsLicenseFeatures[field],
            value: null,
            allowsEditing: customField.allowsEditing ?? true,
          })),
        ];
      }

      return [
        ...result,
        {
          ref: field,
          name: IExpenseRefFields[field],
          label: t(`organisms.expenseRegisterForm.fieldLabels.${field}`),
          active: activeField(IExpenseRefFields[field], fields[field].active),
          allowsEditing: setDisabledField(IExpenseRefFields[field], fields[field].allowsEditing),
          required: fields[field].required,
          type: EExpenseFieldType[field],
          value: EExpenseFieldType[field] == EFieldType.FLAG ? false : null,
          order: fields[field].fieldOrder,
          licenseFeature: EExpenseFieldsLicenseFeatures[field],
        },
      ];
    }, []);

    return formFields;
  }, [fields, expense]);

  useOrderFields(fieldsData);

  return fieldsData;
}
