import {
  IConfigurationCategory,
  IConfigurationCategoryMutation,
  ICreateConfigurationCategoryMutation,
  IUpdateConfigurationCategoryMutation,
} from '../context/types/categoryTypes';

export const serializeCreateCategory = (
  category: IConfigurationCategory,
): ICreateConfigurationCategoryMutation['category'] => {
  return {
    name: category.name ?? '',
    active: category.active ?? true,
    externalCode: category.externalCode,
    readOnly: category.readOnly ?? false,
    iconUrl: category.iconUrl ?? '',
  };
};

export const serializeUpdateCategory = (
  category: IConfigurationCategory,
): IUpdateConfigurationCategoryMutation['category'] => {
  return {
    id: category.id,
    name: category.name ?? '',
    active: category.active ?? true,
    externalCode: Boolean(category.externalCode) ? category.externalCode : '',
    readOnly: category.readOnly ?? false,
    iconUrl: category.iconUrl ?? '',
  };
};

export const serializeMutateCategory = (
  category: IConfigurationCategory,
): IConfigurationCategoryMutation['category'] => {
  return {
    name: category.name ?? '',
    active: category.active ?? true,
    externalCode: category.externalCode || '',
    readOnly: category.readOnly ?? false,
    iconUrl: category.iconUrl ?? '',
    contabilityAccount: category.contabilityAccount,
  };
};

export const serializeLoadCategory = (
  category: IConfigurationCategory,
): IUpdateConfigurationCategoryMutation['category'] => {
  return {
    id: category.id,
    name: category.name ?? '',
    active: category.active,
    externalCode: category.externalCode ?? '',
    readOnly: category.readOnly ?? false,
    iconUrl: category.iconUrl ?? '',
  };
};
