import { gql } from '@apollo/client';

export const getAllCurrencies = gql`
  query GetAllCurrencies {
    getAllCurrencies {
      currencies {
        id
        code
        symbol
      }
    }
  }
`;
