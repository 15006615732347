import { useState } from 'react';
import { useMutate } from '@shared/hooks/service/useMutate';

import { IFileResponse } from '../context/types/files';

type UploadFileResponse = {
  key: string;
  url: string;
  externId?: string;
};

export const useExpenseFileUpload = () => {
  const [processingCount, setProcessingCount] = useState(0);
  const { mutation } = useMutate('/v1/files');

  async function upload(file: File): Promise<IFileResponse> {
    try {
      setProcessingCount(count => count + 1);
      const formData = new FormData();
      formData.append('file', file);

      const upload: UploadFileResponse = await mutation.trigger({
        path: `upload`,
        options: {
          method: 'POST',
          body: formData,
          headers: {
            isFile: 'true',
          },
          delegationAllowed: true,
        },
      });

      return {
        path: upload.key,
        contentType: file.type,
        externId: upload.externId,
        url: upload.url,
      };
    } catch (err) {
      throw err;
    } finally {
      setProcessingCount(count => count - 1);
    }
  }

  return { upload, isLoading: processingCount > 0 };
};
