import { IExpenses } from '@containers/Expenses/context/types';
import { IReport } from '@containers/Reports/context/types';
import { useExpenseDetailsDriver } from '@shared/hooks/navigation/useExpenseDetailsDriver';
import { useExpenseRegisterDriver } from '@shared/hooks/navigation/useExpenseRegisterDriver';

import { EActionOptions } from '../types';
import { useDeleteExpense } from './useDeleteExpense';
import { useUnlinkExpense } from './useUnlinkExpense';

export interface UseExpensesGridActionsProps {
  isReportDetails?: boolean;
  reportId?: IReport['id'];
  unSelectAll?(): void;
}

export function useExpensesGridActions({ isReportDetails, reportId, unSelectAll }: UseExpensesGridActionsProps) {
  const navigateExpenseEdit = useExpenseRegisterDriver();
  const navigateExpenseDetails = useExpenseDetailsDriver();
  const {
    unlinkIsLoading,
    expensesToUnlink,
    unlinkConfirmationIsOpen,
    removeConfirmationIsOpen,
    onUnlinkExpense,
    onCancelUnlinkExpenseClick,
    onConfirmUnlinkExpenseClick,
  } = useUnlinkExpense({ reportId, isReportDetails, unSelectAll });
  const {
    deleteIsLoading,
    expensesToDelete,
    deleteConfirmationIsOpen,
    onDeleteExpense,
    onCancelDeleteExpenseClick,
    onConfirmDeleteExpenseClick,
  } = useDeleteExpense({ unSelectAll });
  const selectedActionItems: IExpenses['id'][] = expensesToUnlink?.length
    ? expensesToUnlink
    : expensesToDelete?.length
    ? expensesToDelete
    : [];

  function onExpenseActionClick(key: EActionOptions, expenseId?: IExpenses['id'][], expenseType?: IExpenses['type']) {
    switch (key) {
      case EActionOptions.DELETE:
        onDeleteExpense(expenseId);
        break;
      case EActionOptions.EDIT:
        navigateExpenseEdit({ expenseId: expenseId[0], expenseType });
        break;
      case EActionOptions.UNLINK:
        onUnlinkExpense(expenseId);
        break;
      default:
        navigateExpenseDetails({ expenseId: expenseId[0], expenseType });
        break;
    }
  }

  return {
    actionsIsLoading: deleteIsLoading || unlinkIsLoading,
    selectedActionItems,
    unlinkConfirmationIsOpen,
    removeConfirmationIsOpen,
    deleteConfirmationIsOpen,
    onExpenseActionClick,
    onConfirmUnlinkExpenseClick,
    onCancelUnlinkExpenseClick,
    onCancelDeleteExpenseClick,
    onConfirmDeleteExpenseClick,
  };
}
