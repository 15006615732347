import { Button, Icons, IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';

import * as SC from '../styled';

type TProps = {
  actionName: string;
  actionIcon: IconTypes;
  onClick(): void;
};

export function ActionButtonItem({ actionIcon, actionName, onClick }: TProps) {
  const { colors } = useTheme();

  return (
    <Button size="large" variant="primary" onClick={() => onClick?.()}>
      <SC.ButtonContent>
        {actionName}
        <Icons name={actionIcon ?? 'Add'} color={colors.neutral[100]} />
      </SC.ButtonContent>
    </Button>
  );
}
