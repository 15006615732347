import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { Advances } from '@pages/Advances';
import { AdvanceRegister } from '@pages/Advances/AdvanceRegister/AdvanceRegister';
import { ExpenseDetails } from '@pages/ExpenseDetails';
import { ExpenseRegister } from '@pages/ExpenseRegister';
import { ReportDetails } from '@pages/ReportDetails';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function AdvancesRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['INTERMEDIATE_ADVANCE']}
          routePermissions={[EAuthZPermission.ADVANCES_REPORTS_VIEW]}
        />
      ),
      errorElement: (
        <RouterErrorBoundary title="Erro ao carregar o módulo de Adiantamentos" moduleName="Adiantamentos" />
      ),
      children: [
        {
          path: Router.getAdvancesReportsDetails(':id'),
          element: <ReportDetails />,
        },
      ],
    },
    {
      element: (
        <PermissionsGuard
          routeFeatures={['INTERMEDIATE_ADVANCE']}
          routePermissions={[EAuthZPermission.ADVANCES_REPORTS_ACTION]}
        />
      ),
      errorElement: (
        <RouterErrorBoundary title="Erro ao carregar o módulo de Adiantamentos" moduleName="Adiantamentos" />
      ),
      children: [
        {
          path: Router.getAdvances(),
          element: <Advances />,
        },
        {
          path: Router.getAdvancesRegister(),
          element: <AdvanceRegister />,
        },
        {
          path: Router.getAdvancesRegister(':id'),
          element: <AdvanceRegister />,
        },
        {
          path: Router.getAdvancesDetails(':id'),
          element: <AdvanceRegister />,
        },
        {
          path: Router.getAdvancesReportsDetails(':id'),
          element: <AdvanceRegister />,
        },
        {
          path: Router.getAdvancesExpensesDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          path: Router.getAdvancesExpenseRegister(':expenseId'),
          element: <ExpenseRegister />,
        },
      ],
    },
  ];
}
