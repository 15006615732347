import { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { IExpenseRegisterContext } from './types';

export const ExpenseRegisterContext = createContext<IExpenseRegisterContext>(null);

export function ExpenseRegisterContextProvider() {
  const [ocrIsLoading, setOcrIsLoading] = useState(false);

  return (
    <ExpenseRegisterContext.Provider
      value={{
        ocrIsLoading,
        setOcrIsLoading,
      }}>
      {<Outlet />}
    </ExpenseRegisterContext.Provider>
  );
}
