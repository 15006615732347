import React from 'react';
import { useAreas } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useAreas';
import { useJobPositions } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useJobPositions';
import { useSubsidiaries } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useSubsidiaries';
import { ECriteriaKey } from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import { useAllCategories } from '@containers/Customizations/hooks/useAllCategories';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export interface IOptionsSource {
  value: string | number;
  caption?: string;
  label: React.ReactNode;
}

enum EStatementOptions {
  AND = 'and',
  OR = 'or',
}

enum ECriteriaKeyOptions {
  AREA = 'area',
  ROLE = 'jobPosition',
  CATEGORY = 'category',
  SUBSIDIARY = 'subsidiary',
  REFUNDS = 'totalReportValue',
  ADVANCED = 'totalAdvanceValue',
  VIOLATIONS = 'totalViolations',
  REQUESTER = 'costCenterResponsible',
  RESPONSIBLE_PROJECT = 'responsibleForTheProject',
}

export enum EOperatorOptions {
  EQUALS = 'equals',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
  NOT_EQUALS = 'notEqual',
  LESSER_THAN = 'lesserThan',
  LESSER_THAN_OR_EQUAL = 'lesserThanOrEqual',
}

export enum EOperatorPartialOptions {
  EQUALS = 'equals',
  NOT_EQUALS = 'notEqual',
}

export function useOptionsListCriteria() {
  const t = useTranslate('approvalFlows.approvalCriteria.options');
  const { areas, isLoading: areasIsLoading } = useAreas();
  const { jobPosition, isLoading: jobPositionsIsLoading } = useJobPositions();
  const { selectFieldFormatCategories, isLoadingCategories } = useAllCategories();
  const { subsidiaries, isLoading: subsidiariesIsLoading } = useSubsidiaries();
  const currentPath = useCurrentPath();

  function filterOptionsCriteria(options: IOptionsSource[]) {
    if (currentPath.includes('advances')) {
      return options.filter(
        option =>
          option.value !== ECriteriaKey.REFUNDS &&
          option.value !== ECriteriaKey.CATEGORY &&
          option.value !== ECriteriaKey.VIOLATIONS,
      );
    }

    return options.filter(option => option.value !== 'ADVANCED');
  }

  const statementOptions: IOptionsSource[] = Object.keys(EStatementOptions).map(key => {
    return {
      value: key,
      label: t(`statement.${EStatementOptions[key]}`),
    };
  });

  const criteriaKeyOptions: IOptionsSource[] = Object.keys(ECriteriaKeyOptions).map(key => ({
    value: key,
    label: t(`criterion.${ECriteriaKeyOptions[key]}`),
  }));

  const operatorPartialOptions: IOptionsSource[] = Object.keys(EOperatorPartialOptions).map(key => ({
    value: key,
    label: t(`operator.${EOperatorPartialOptions[key]}`),
  }));

  const operatorOptionsList: IOptionsSource[] = Object.keys(EOperatorOptions).map(key => ({
    value: key,
    label: t(`operator.${EOperatorOptions[key]}`),
  }));

  const areasOptions: IOptionsSource[] = areas?.map(area => ({
    value: area.id,
    label: area.name,
  }));

  const jobPositionOptions: IOptionsSource[] = jobPosition?.map(area => ({
    value: area.id,
    label: area.name,
  }));

  const subsidiariesOptions: IOptionsSource[] = subsidiaries?.map(area => ({
    value: area.id,
    label: area.name,
  }));

  const categoriesOptions: IOptionsSource[] = selectFieldFormatCategories?.map(category => ({
    value: String(category.value),
    label: category.label,
  }));

  return {
    statementOptions,
    criteriaKeyOptions: filterOptionsCriteria(criteriaKeyOptions),
    operatorOptionsList,
    operatorPartialOptions,
    areasOptions,
    jobPositionOptions,
    subsidiariesOptions,
    categoriesOptions,
    isLoading: areasIsLoading || jobPositionsIsLoading || isLoadingCategories || subsidiariesIsLoading,
  };
}
