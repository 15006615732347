import { AnalyticsPagePreview } from '@containers/Analytics/components/organisms/AnalyticsPagePreview';
import { useTranslation } from '@locale/Translator';
import { BasePage } from '@organisms/BasePage/BasePage';

export const Analytics = () => {
  const { t } = useTranslation();

  return (
    <BasePage
      headerPage={{
        title: t('pages.analytics.title'),
      }}>
      <AnalyticsPagePreview />
    </BasePage>
  );
};
