/* eslint-disable @typescript-eslint/ban-types */
import { useTranslation } from '@locale/Translator';

import { LocaleKeys } from 'src/i18n/locales/localeKeys';

export type AnyString = string & {};

export type Paths<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${'' | `.${Paths<T[K]>}`}`;
    }[keyof T]
  : never;

type ObjectByPath<T extends object, U extends string> = U extends `${infer Current}.${infer TNested}`
  ? Current extends keyof T
    ? T[Current] extends object
      ? ObjectByPath<T[Current], TNested>
      : never
    : never
  : U extends keyof T
  ? T[U]
  : T;

type Keys = Paths<LocaleKeys['translations']>;

type RemoveBase<TBase extends string> = Paths<ObjectByPath<LocaleKeys['translations'], TBase>> | AnyString;

export function useTranslate<TBaseKey extends Keys, B extends RemoveBase<TBaseKey>>(
  basePathTranslate?: TBaseKey | AnyString,
) {
  const translation = useTranslation();

  const getTranslation = (key: B, options?: object): string => {
    const fullKey = basePathTranslate ? `${basePathTranslate}.${key}` : key;

    return translation.t(fullKey, options);
  };

  return getTranslation;
}
