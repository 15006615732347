import { useContext, useEffect } from 'react';
import { useTranslation } from '@locale/Translator';
import { costCentersBaseURL } from '@shared/constant';
import { ImportingContext } from '@shared/contexts/BulkImportContext';
import { EImportingActions } from '@shared/contexts/BulkImportContext/actions';
import { showToast } from '@shared/helpers/toast';
import { useDownloadFile } from '@shared/hooks/service/useDownloadFile';
import { useUploadFile } from '@shared/hooks/service/useUploadFile';
import { useNavigate } from 'react-router-dom';

export const useCostCenterFileRegister = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { download, error } = useDownloadFile();
  const { upload, data: uploadData, isLoading: isLoadingUpload } = useUploadFile('v1/cost-centers');
  const { dispatch } = useContext(ImportingContext);

  const onError = () => showToast({ message: t('molecules.costCenterToast.uploadErrorMessage'), type: 'error' });

  async function importCostCenters(data) {
    try {
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO_IS_LOADING,
        payload: {
          importingResponseIsLoading: true,
        },
      });
      dispatch({
        type: EImportingActions.HAS_TRIED_TO_IMPORT,
        payload: {
          hasTriedToImport: true,
        },
      });
      await upload(data.file, onError);
      navigate(costCentersBaseURL);
    } catch {
      showToast({ message: t('molecules.costCenterToast.uploadErrorMessage'), type: 'error' });
    }
  }

  useEffect(() => {
    if (uploadData) {
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO,
        payload: {
          ...uploadData,
        },
      });
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO_IS_LOADING,
        payload: {
          importingResponseIsLoading: false,
        },
      });
    }
  }, [uploadData]);

  return {
    importCostCenters,
    download: () => download('modelo-centro-custo.xlsx', 'v1/cost-centers/get-import-template'),
    error,
    isLoading: isLoadingUpload,
    isLoadingUpload,
  };
};
