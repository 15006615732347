import { attachAdvanceToReport } from '@containers/Advances/context/queries/advance';
import { IReport } from '@containers/Reports/context/types';
import { getErrorToast } from '@shared/helpers/errorToast';
import { showToast } from '@shared/helpers/toast';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useLocation } from 'react-router-dom';

export function useAttachAdvanceToReport(reportName?: IReport['name']) {
  const t = useTranslate('pages.advanceRegister.feedbacks');
  const [attachAdvanceToReportMutate, { loading: attachLoading }] = useMutation<
    { attachAdvanceToReport: { success: boolean; message: string[] } },
    { advanceIds: number[]; reportId: number }
  >(attachAdvanceToReport);
  const { pathname } = useLocation();

  async function handleAttach(advanceIds: number[], reportId: number) {
    const refetchQueries = [];
    if (pathname.includes('advances')) {
      refetchQueries.push('GetAdvances');
    } else {
      refetchQueries.push('GetReportById');
      refetchQueries.push('GetAdvances');
    }
    if (reportId) {
      try {
        const response = await attachAdvanceToReportMutate({
          variables: {
            advanceIds,
            reportId,
          },
          refetchQueries,
        });

        if (response?.data?.attachAdvanceToReport?.success) {
          showToast({ message: t('attach', { reportName }), type: 'success' });
        } else {
          showToast({ message: response?.data?.attachAdvanceToReport?.message?.join(', '), type: 'error' });
        }
      } catch (err) {
        getErrorToast(err);
      }
    }
  }

  return {
    handleAttach,
    isLoading: attachLoading,
  };
}
