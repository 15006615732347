import { ExpenseDetailsPreview } from '@containers/Expenses/components/organisms/ExpenseDetailsPreview/ExpenseDetailsPreview';
import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';
import { useNavigate, useParams } from 'react-router-dom';

import { useExpenseDetailsBreadcrumbs } from './hooks/useExpenseDetailsBreadcrumbs';
import * as SC from './styled';

export const ExpenseDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: expenseId } = useParams();

  useTrackingScreen('expense_details_screen', { expenseId });
  const breadcrumbItems = useExpenseDetailsBreadcrumbs();

  return (
    <BasePage
      headerPage={{
        breadcrumbItems,
      }}
      footerPage={
        <SC.ActionsButtonsFooterContainer>
          <Button variant="primary" size="medium" type="submit" onClick={() => navigate(-1)}>
            <Icons name="IconArrowLeft" fill="transparent" />
            {t('pages.financialReportDetails.comeBack')}
          </Button>
        </SC.ActionsButtonsFooterContainer>
      }>
      <ExpenseDetailsPreview />
    </BasePage>
  );
};
