import { useState } from 'react';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';
import { mutate } from 'swr';

import { updateAdvancesStatusMutation } from '../context/queries/advances';
import { IAdvance, IUpdateAdvanceStatusRequest, IUpdateAdvanceStatusResponse } from '../context/types/advance';

export function useStatusSwitchBatchAdvances() {
  const currentPath = useCurrentPath();
  const translation = useTranslation();
  const [listIdsLoading, setListIdsLoading] = useState<IAdvance['id'][]>([]);
  const t = (key: string): string => translation.t(`pages.advanceRegister.feedbacks.${key}`);
  const [updateStatusMutate, { data, error, loading }] = useMutation<
    IUpdateAdvanceStatusResponse,
    IUpdateAdvanceStatusRequest
  >(updateAdvancesStatusMutation);

  function getRefetch() {
    if (currentPath.includes('reports/details')) return ['GetReportById', 'GetAdvances'];
    else if (currentPath.includes('approvals')) return ['GetAdvancesForApproval'];
    else if (currentPath.includes('accounting')) return [];

    return ['GetAdvances'];
  }

  async function setStatusBatchAdvances(ids: IAdvance['id'][] = [], status: IAdvance['status'], message?: string) {
    try {
      setListIdsLoading([...listIdsLoading, ...ids]);
      const response = await updateStatusMutate({
        variables: {
          advancesIds: ids?.map(id => Number(id)),
          status,
          message,
        },
        refetchQueries: getRefetch(),
      });
      const _listIdsLoading = listIdsLoading;

      if (data?.updateAdvancesStatus?.error || error) {
        showToast({
          message: t('error'),
          type: 'error',
        });
      } else {
        showToast({
          message: `${t('save')}`,
          type: 'success',
        });
      }
      setListIdsLoading(_listIdsLoading);

      if (currentPath.includes('accounting') && !currentPath.includes('accounting/advances/details')) {
        mutate(key => typeof key === 'string' && key.includes('v1/advances/finance?'));
        mutate(key => typeof key === 'string' && key.includes('v1/advances/finance/summary'));
      }

      return response;
    } catch (_error) {
      showToast({
        message: _error.message,
        type: 'error',
      });
      setListIdsLoading([]);
    }
  }

  return {
    setStatusBatchAdvances,
    error,
    isLoading: loading,
    listIdsLoading,
  };
}
