import { EApprovalStatus, IApproversTarget } from '@containers/Reports/context/types/approvers';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { TagApprover } from '../../TagApprover/TagApprover';
import * as SC from './styled';

interface IProps {
  approvers: IApproversTarget[];
}

export function ApproverItemsDrawer({ approvers }: IProps) {
  const t = useTranslate('approversDrawer');

  function getDescription(status: IApproversTarget['status']) {
    switch (status) {
      case EApprovalStatus.APPROVED:
        return t('approved');
      case EApprovalStatus.REJECTED:
        return t('disapproved');
      default:
        return t('pendingApproval');
    }
  }

  return (
    <SC.Container>
      {approvers
        ?.sort((firstItem, secondItem) => firstItem.level - secondItem.level)
        ?.map((approver, index) => (
          <SC.ApproversContent key={`approver-${index}`}>
            <SC.TagContent>
              <TagApprover approver={approver} />
            </SC.TagContent>
            {getDescription(approver.status)}
          </SC.ApproversContent>
        ))}
    </SC.Container>
  );
}
