import { useContext, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { useAllProjects } from '@containers/Projects/hooks/rest/useAllProjects';
import { FeedbackBox } from '@molecules/FeedbackBox';
import { projectsBaseURL } from '@shared/constant';
import { ImportingContext } from '@shared/contexts/BulkImportContext';
import { EImportingActions } from '@shared/contexts/BulkImportContext/actions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { ProjectGrid } from '../ProjectGrid/ProjectGrid';
import * as SC from './styled';

export function ProjectsPreview() {
  const t = useTranslate('projects.projectsPreview');
  const navigate = useNavigate();
  const { state, dispatch } = useContext(ImportingContext);
  const [searchName, setSearchName] = useState<string>('');
  const [page, setPage] = useState(1);
  const { projects, totalProjects, isLoading, refetch } = useAllProjects({
    page,
    searchName,
  });

  function getFilterItemsList() {
    return projects?.filter(_projects => _projects?.name?.toLowerCase()?.includes(searchName?.toLowerCase()));
  }

  return (
    <SC.Container>
      {state.hasTriedToImport && (
        <FeedbackBox
          onClose={() =>
            dispatch({
              type: EImportingActions.HAS_TRIED_TO_IMPORT,
              payload: {
                hasTriedToImport: false,
              },
            })
          }
          state={state}
          refetch={refetch}
          onClick={() => navigate(`${projectsBaseURL}/register/file/details`)}
          page="projects"
        />
      )}
      <SC.ContainerCounter>
        <SC.CounterLabel variant="headline8">{t('projectsCounter.label')}</SC.CounterLabel>
        <StatusTag variant="neutral">
          {t(`projectsCounter.items.${totalProjects === 1 ? 'singular' : 'plural'}`, { items: totalProjects })}
        </StatusTag>
      </SC.ContainerCounter>
      <ProjectGrid
        projects={Boolean(searchName) ? getFilterItemsList() : projects || []}
        total={totalProjects}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        setSearchName={setSearchName}
        searchName={searchName}
      />
    </SC.Container>
  );
}
