import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};

  .data-grid-table-header-subcontainer-text {
    width: 100% !important;
  }
`;

export const LabelButtonContent = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs4};
  align-items: center;
`;

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[80]};
  width: 42px;
  height: 42px;
`;
