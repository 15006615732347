import { useState } from 'react';
import { IGetReportsStatusUpdateRequest, IReportForFinanceFilter } from '@containers/Financial/context/types/reports';
import { useReportApprovalSettings } from '@containers/Financial/hooks/dataSources/useReportApprovalSettings';
import { useReportExportExcel } from '@containers/Financial/hooks/useReportExportExcel';
import { useReportsStatusMutate } from '@containers/Financial/hooks/useReportsStatusMutate';
import {
  EReportPdfExportType,
  EReportStatus,
  IReportSummaries,
  IReportSummariesFilter,
} from '@containers/Reports/context/types';
import { usePdfFileExportReports } from '@containers/Reports/hooks/usePdfFileExportReports';
import { usePdfUrlExportReports } from '@containers/Reports/hooks/usePdfUrlExportReports';
import { useTranslation } from '@locale/Translator';
import { expensesAccountingBaseURL } from '@shared/constant';
import { downloadExcel } from '@shared/helpers/files';
import { showToast } from '@shared/helpers/toast';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';
import { useReportDetailsDriver } from '@shared/hooks/navigation/useReportDetailsDriver';
import { useIntegrations } from '@shared/hooks/useIntegrations';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';

interface useFinancialReportsActionsManagerProps {
  reports?: IReportSummaries[];
  isReportDetails?: boolean;
  unSelectAllClick?(): void;
}

export function useFinancialReportsActionManager({
  reports,
  isReportDetails,
  unSelectAllClick,
}: useFinancialReportsActionsManagerProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [justificationIsOpen, setJustificationIsOpen] = useState(false);
  const [repayConfirmIsOpen, setRepayConfirmIsOpen] = useState(false);
  const [expirationDateIsOpen, setExpirationDateIsOpen] = useState(false);
  const [reportsSelected, setReportsSelected] = useState<IReportSummaries['id'][]>([]);
  const { reportsStatusMutate, isLoading } = useReportsStatusMutate(isReportDetails);
  const { getFormatDateUTC } = useDateFormatter();
  const { exportExcel, isLoading: exportExcelIsLoading } = useReportExportExcel();
  const navigateReportDetails = useReportDetailsDriver();
  const { expirationDateFlag, expirationDateType } = useReportApprovalSettings();
  const { hasFinancialIntegration } = useIntegrations();
  const { getPdfReports, exporting } = usePdfFileExportReports();
  const { getPdfUrlReports } = usePdfUrlExportReports();

  function checkCancelIntegration() {
    return reports
      ?.filter(report => reportsSelected.some(id => String(id) == String(report?.id)))
      ?.some(
        report =>
          report.status === EReportStatus.AWAITING_FINANCIAL_PROCESSING ||
          report.status === EReportStatus.PROCESSING_PAYMENT_ERROR,
      );
  }

  function onViewClick(reportId: IReportSummaries['id']): void {
    navigateReportDetails(reportId);
  }

  function onReproveClick(reportsSelected: IReportSummaries['id'][]): void {
    setReportsSelected(reportsSelected);
    setJustificationIsOpen(true);
  }

  function onRepayClick(reportsSelected: IReportSummaries['id'][]) {
    setRepayConfirmIsOpen(true);
    setReportsSelected(reportsSelected);
  }

  async function handleExport(
    reportIds: IReportSummaries['id'][],
    pdfType: EReportPdfExportType,
    target: 'url' | 'file',
  ) {
    if (target === 'file') {
      showToast({
        message: t(
          `organisms.financialReportGrid.actionsOptions.feedbacks.export.${
            reportIds?.length !== 1 ? 'pluralForm' : 'singularForm'
          }.processing`,
        ),
        type: 'warning',
      });

      getPdfReports({ reportIds, pdfType });
    } else getPdfUrlReports({ reportIds, pdfType });
  }

  async function onExportPdfClick(reportsSelected: IReportSummaries['id'][]) {
    handleExport(reportsSelected, EReportPdfExportType.NORMAL, 'file');
  }

  function onExportPdfByCategoryClick(reportsSelected: IReportSummaries['id'][]) {
    handleExport(reportsSelected, EReportPdfExportType.CATEGORY, 'file');
  }

  async function onExportPdfUrlClick(reportsSelected: IReportSummaries['id'][]) {
    handleExport(reportsSelected, EReportPdfExportType.NORMAL, 'url');
  }

  function onExportPdfUrlByCategoryClick(reportsSelected: IReportSummaries['id'][]) {
    handleExport(reportsSelected, EReportPdfExportType.CATEGORY, 'url');
  }

  async function onExportExcelClick(data: IReportSummariesFilter) {
    const currentDate = new Date();
    const endDate = data.endDate || getFormatDateUTC(currentDate);
    const startDate = data.startDate || getFormatDateUTC(new Date(currentDate.setDate(currentDate.getDate() - 182)));

    const filters: IReportForFinanceFilter = {
      status: data.status,
      createdBy: data.requesterId,
      reportStartDate: {
        endDate,
        startDate,
      },
    };

    try {
      const response = await exportExcel({
        variables: {
          filters,
        },
      });

      downloadExcel(
        response.data.getExcelBase64ReportsForFinance,
        `${t('organisms.financialReportGrid.report')} ${new Date().toISOString()}` + '.xls',
      );
    } catch (error) {
      showToast({
        message: error.message.includes('undefined')
          ? t('organisms.financialReportGrid.actionsOptions.feedbacks.export.notFound')
          : t('organisms.financialReportGrid.actionsOptions.feedbacks.export.singularForm.error'),
        type: 'error',
      });
    }
  }

  function onClosePopup(): void {
    setReportsSelected([]);
    setJustificationIsOpen(false);
    setRepayConfirmIsOpen(false);
    setExpirationDateIsOpen(false);
  }

  async function statusMutate(
    { reports, status, expirationDate, message }: IGetReportsStatusUpdateRequest,
    target: 'disapproved' | 'refunded' | 'integration' | 'cancelIntegration',
  ) {
    const variables = { reports, status };
    expirationDate && (variables['expirationDate'] = expirationDate);
    message && (variables['message'] = message);

    try {
      const response = await reportsStatusMutate({
        variables: { ...variables, status: target === 'cancelIntegration' ? EReportStatus.APPROVED : variables.status },
      });

      const reports = response?.data?.updateReportListStatus?.reports;
      const successItems: boolean = reports.some(report => !report.error);
      Boolean(reports) &&
        reports.forEach(report => {
          report?.error && showToast({ message: report?.message, type: 'error' });
        });
      successItems &&
        showToast({
          message: t(
            `organisms.financialReportsPreview.${
              reports.length > 1 ? 'pluralForm' : 'singularForm'
            }.${target}SuccessMessage`,
          ),
          type: 'success',
        });
      mutate(key => typeof key === 'string' && key.includes('v1/reports/financial'));
      isReportDetails && navigateToFinancialReports();
      unSelectAllClick?.();
    } catch (error) {
      showToast({ message: error?.message, type: 'error' });
    } finally {
      onClosePopup();
    }
  }

  async function onActionJustificationPopup(message: string) {
    await statusMutate(
      { reports: reportsSelected, status: EReportStatus.REPROVED, message },
      checkCancelIntegration() ? 'cancelIntegration' : 'disapproved',
    );
    setJustificationIsOpen(false);
  }

  async function onActionRepayConfirmPopup(expirationDate?: Date) {
    const hasExpirationDate = expirationDateType === 'MANUAL' && expirationDateFlag;
    if (hasExpirationDate && !expirationDate) {
      setExpirationDateIsOpen(true);
    } else {
      await statusMutate(
        {
          reports: reportsSelected,
          status: hasFinancialIntegration ? EReportStatus.AWAITING_FINANCIAL_PROCESSING : EReportStatus.REIMBURSED,
          expirationDate,
        },
        hasFinancialIntegration ? 'integration' : 'refunded',
      );
      setExpirationDateIsOpen(false);
    }
    setRepayConfirmIsOpen(false);
  }

  function navigateToFinancialReports() {
    navigate(expensesAccountingBaseURL);
  }

  return {
    isLoadingMutate: isLoading,
    justificationIsOpen,
    expirationDateIsOpen,
    repayConfirmIsOpen,
    reportsSelected,
    exportingItems: exporting,
    exportExcelIsLoading,
    onReproveClick,
    onRepayClick,
    onExportPdfClick,
    onExportPdfByCategoryClick,
    onClosePopup,
    onActionJustificationPopup,
    onActionRepayConfirmPopup,
    onViewClick,
    onExportExcelClick,
    onExportPdfUrlClick,
    onExportPdfUrlByCategoryClick,
    checkCancelIntegration,
  };
}
