import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface IHighlightedProps {
  highlighted?: boolean;
  isBold?: boolean;
}

export const Container = styled.div(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacings.xs,
}));

export const Description = styled(Typography)<IHighlightedProps>(({ theme, highlighted, isBold }) => ({
  width: '100%',
  color: theme.colors.neutral[30],
  fontWeight: '600!important',

  ...(isBold && {
    fontWeight: '700!important',
  }),

  ...(highlighted && {
    marginLeft: theme.spacings.xs3,
  }),
}));

export const Row = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `283px auto`,
  borderBottom: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]} `,
  padding: `${theme.spacings.xs4} ${theme.spacings.xs}`,
}));
