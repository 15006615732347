import { useEffect, useMemo } from 'react';
import { Axios, setInLS, useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import useSWR from 'swr';

import { IExpensesContracts } from 'src/context/types';

export function useContractsData() {
  const { selectedCompany } = useSelectedCompany();
  const swrKey = useMemo(
    () => (selectedCompany.id ? `expenses-company-data-${selectedCompany.id}` : null),
    [selectedCompany.id],
  );
  const {
    data: response,
    isLoading,
    isValidating,
  } = useSWR<IExpensesContracts>(swrKey, () => getExpensesContracts(selectedCompany.id), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (!isLoading && response?.contracts) {
      setInLS({
        key: `expensesAcceptanceTermsCompanyId-${selectedCompany.id}`,
        value: response.contracts,
      });
      setInLS({
        key: `expensesPlanFeatures-${selectedCompany.id}`,
        value: response.features,
      });
    }
  }, [response, isLoading]);

  return {
    features: response?.features,
    contracts: response?.contracts,
    isLoading,
    isValidating,
    isDisabledExpense: response?.isDisabledExpense,
  };
}

export async function getExpensesContracts(companyId: string) {
  try {
    const { data } = await Axios({
      service: 'expenseManagement',
      method: 'get',
      url: `/api/v1/users/me/contracts?project=expenses`,
      axiosOptions: {
        timeout: 30000, // 30 seconds
        headers: {
          'Company-Id': companyId,
        },
      },
    });

    return { ...data, companyId };
  } catch (error) {
    throw error;
  }
}
