import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { CompanyPayables } from '@flash-hros/company-payables';

const { PayablesConfigurationTab } = CompanyPayables;

export function PayAblesTabItems() {
  return (
    <ModuleErrorBoundary moduleName="Contas a Pagar" title="Erro ao tentar carregar tabs de Despesas com o Fornecedor">
      <PayablesConfigurationTab />
    </ModuleErrorBoundary>
  );
}
