import { useParams } from 'react-router-dom';

export function useCheckExpense() {
  const { expenseId: id } = useParams<{ expenseId: string }>();
  const expenseId: number = Number(id);

  return {
    expenseId,
    hasExpense: Boolean(expenseId),
  };
}
