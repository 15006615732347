import { ICreateReportMutation } from '../context/types';

export const serializeCreateReport = (report: ICreateReportMutation['report']): ICreateReportMutation['report'] => {
  const _report: ICreateReportMutation['report'] = {
    description: report.description ?? '',
    referenceId: report.referenceId,
    name: report.name ?? '',
  };

  if (report.approverId) {
    _report.approverId = report.approverId;
  }

  return _report;
};
