import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

export type TProjectsConfirmationActions = 'exclusion' | 'deactivation';

type TProps = {
  open: boolean;
  isBatchAction?: boolean;
  confirmationType: TProjectsConfirmationActions;
  customMessage?: string;
  isLoading?: boolean;
  onActionClick(): void;
  onClose: () => void;
};

export function CustomApprovalFlowsConfirmationActionsModal({
  open,
  confirmationType,
  isBatchAction = false,
  customMessage,
  isLoading,
  onActionClick,
  onClose,
}: TProps) {
  const t = useTranslate(`approvalFlows.modal.${confirmationType}`);

  function getTranslateType(): string {
    return isBatchAction ? 'plural' : 'singular';
  }

  return (
    <SC.Container>
      <DangerActionModal
        open={open}
        isLoading={isLoading}
        headerIconLabel={t('iconDescription')}
        headerTitle={t(`title.${getTranslateType()}`)}
        headerSubtitle={customMessage ?? t(`message.${getTranslateType()}`)}
        labelButtonAction={
          <SC.LabelButtonContent>
            <p>{t(`actions.confirm.${getTranslateType()}`)}</p>
            {confirmationType === 'exclusion' && <Icons name="IconTrash" fill="transparent" size={24} />}
          </SC.LabelButtonContent>
        }
        onActionClick={onActionClick}
        onCancelClick={onClose}></DangerActionModal>
    </SC.Container>
  );
}
