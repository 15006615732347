import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';

import { Establishments } from '../../organisms/Establishments';
import * as SC from './styled';

interface IProps {
  value: IEstablishment;
  label: string;
  disabledDelete?: boolean;
  onChange(establishment: IEstablishment): void;
  onDelete(): void;
}

export function RouteField({ value, label, disabledDelete = false, onChange, onDelete }: IProps) {
  return (
    <SC.Container>
      <Establishments onSelectChange={data => onChange(data)} value={value} label={label} />
      <div>
        <IconButton disabled={disabledDelete} icon="IconTrash" variant="line" size="small" onClick={onDelete} />
      </div>
    </SC.Container>
  );
}
