import { EAdvanceStatus, IAdvance } from '@containers/Advances/context/types/advance';
import { Button, Icons, LinkButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useAdvancesPermissions } from '@shared/hooks/permissions/systemPermissions/useAdvancesPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useIntegrations } from '@shared/hooks/useIntegrations';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

interface IProps {
  isLoading: boolean;
  id: string;
  toView: boolean;
  status: IAdvance['status'];
  handleStatus: (_status: EAdvanceStatus) => void;
}

export function AdvanceRegisterFooterPage({ isLoading, id, toView, status, handleStatus }: IProps) {
  const t = useTranslate('pages.advanceRegister.actions');
  const navigate = useNavigate();
  const { hasAccounting, hasApprover } = useAdvancesPermissions();
  const displayApprovalActions = hasAccounting || hasApprover;
  const { hasFinancialIntegration } = useIntegrations();

  function hasApprovalActionAllowed() {
    if (hasAccounting) {
      return status === EAdvanceStatus.APPROVED;
    }
    return hasApprover && status === EAdvanceStatus.SUBMITTED;
  }

  return (
    <SC.Container>
      <div>
        <LinkButton variant="default" onClick={() => navigate(-1)}>
          {t('cancel')}
        </LinkButton>
      </div>
      <SC.ActionsButtonsFooterContainer>
        {!toView ? (
          <Button
            variant="primary"
            size="medium"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            style={{ width: 'max-content' }}>
            {t('create')}
            {<Icons name="IconArrowRight" color="#fff" fill="transparent" />}
          </Button>
        ) : status === EAdvanceStatus.PENDING && !hasAccounting && !hasApprover ? (
          <Button
            variant="primary"
            size="medium"
            disabled={!Boolean(id) || isLoading}
            loading={isLoading}
            style={{ width: 'max-content' }}
            onClick={() => handleStatus(EAdvanceStatus.SUBMITTED)}>
            {t('create')}
            <Icons name="IconArrowRight" fill="transparent" />
          </Button>
        ) : displayApprovalActions ? (
          <>
            <div>
              <Tooltip
                arrow
                title={!hasApprovalActionAllowed() ? t(`tooltip.${hasAccounting ? 'accounting' : 'approval'}`) : ''}
                placement="top">
                <div>
                  <Button
                    variant="secondary"
                    size="medium"
                    onClick={() => handleStatus(EAdvanceStatus.REPROVED)}
                    loading={isLoading}
                    disabled={!hasApprovalActionAllowed()}>
                    <SC.ButtonContent>
                      <Icons name="IconThumbDown" fill="transparent" size={20} />
                      <SC.DescriptionButton>{t('reprove')}</SC.DescriptionButton>
                    </SC.ButtonContent>
                  </Button>
                </div>
              </Tooltip>
            </div>
            <div>
              <Tooltip
                arrow
                title={!hasApprovalActionAllowed() ? t(`tooltip.${hasAccounting ? 'accounting' : 'approval'}`) : ''}
                placement="top-start">
                <div>
                  <Button
                    variant="primary"
                    size="medium"
                    loading={isLoading}
                    onClick={() =>
                      handleStatus(
                        hasAccounting
                          ? hasFinancialIntegration
                            ? EAdvanceStatus.AWAITING_FINANCIAL_PROCESSING
                            : EAdvanceStatus.OPEN
                          : EAdvanceStatus.APPROVED,
                      )
                    }
                    disabled={!hasApprovalActionAllowed()}>
                    <Icons name="IconThumbUp" fill="transparent" size={20} />
                    <SC.DescriptionButton>
                      {t(hasAccounting ? (hasFinancialIntegration ? 'submitForIntegration' : 'pay') : 'approve')}
                    </SC.DescriptionButton>
                  </Button>
                </div>
              </Tooltip>
            </div>
          </>
        ) : (
          (status === EAdvanceStatus.REPROVED || status === EAdvanceStatus.SUBMITTED) && (
            <Button
              variant="primary"
              size="medium"
              disabled={!Boolean(id) || isLoading}
              loading={isLoading}
              style={{ width: 'max-content' }}
              onClick={() => handleStatus(EAdvanceStatus.PENDING)}>
              {t('reopen')}
            </Button>
          )
        )}
      </SC.ActionsButtonsFooterContainer>
    </SC.Container>
  );
}
