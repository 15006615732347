import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings.xs4};
`;

export const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const HeaderContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
`;

export const Title = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 20px;
`;

export const Caption = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 16px;
`;
