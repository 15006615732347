import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ConditionalRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  width: 100%;
`;

export const ContainerConditionalFiled = styled.div`
  .select-custom {
    .select-field-custom-theme {
      max-width: 155px;
    }
  }
`;

export const MainLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerCriterionFiled = styled.div`
  width: 100%;
  max-width: 320px;
`;

export const MainLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700 !important;
`;

export const ContainerField = styled.div`
  width: 100%;
`;
