import { FieldRegister } from '@containers/Policies/componentes/molecules/FieldRegister/FieldRegister';
import { AdvancePolicy } from '@containers/Policies/context/types/advances';
import { IPolicy } from '@containers/Policies/context/types/types';
import { useUpdateAdvancePolicySettings } from '@containers/Policies/hooks/advances/useUpdateAdvancePolicySettings';
import { BoxAdornment, Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller } from 'react-hook-form';

interface IProps {
  policyId: IPolicy['id'];
  advancePolicy: AdvancePolicy;
  isLoading: boolean;
}

export function PolicyAdvancesPreferences({ policyId, advancePolicy, isLoading }: IProps) {
  const t = useTranslate('policy.register.advances.settings');

  const { handleUpdate } = useUpdateAdvancePolicySettings(policyId);

  return (
    <Box mt={2}>
      <Box mb={3} width="100%">
        <FieldRegister isLoading={isLoading} height={96}>
          <BoxAdornment
            style={{ width: '100%' }}
            title={t(`simultaneous.title`)}
            description={t(`simultaneous.caption`)}
            leftAdornment={
              <Controller
                name="enableSimultaneousAdvance"
                defaultValue={advancePolicy?.enableSimultaneousAdvance || false}
                render={({ field }) => (
                  <Toggle
                    defaultChecked={field.value}
                    onChange={() => {
                      handleUpdate({ enableSimultaneousAdvance: !field.value });
                      field.onChange(!field.value);
                    }}
                  />
                )}
              />
            }
          />
        </FieldRegister>
      </Box>

      <FieldRegister isLoading={isLoading} height={96}>
        <BoxAdornment
          style={{ width: '100%' }}
          title={t(`residual.title`)}
          description={t(`residual.caption`)}
          leftAdornment={
            <Controller
              name="enableResidualAdvance"
              defaultValue={advancePolicy?.enableResidualAdvance || false}
              render={({ field }) => (
                <Toggle
                  defaultChecked={field.value}
                  onChange={() => {
                    handleUpdate({ enableResidualAdvance: !field.value });
                    field.onChange(!field.value);
                  }}
                />
              )}
            />
          }
        />
      </FieldRegister>
    </Box>
  );
}
