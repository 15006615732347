import { IProjectsWithPolicies } from '@containers/Projects/context/types/projectTypes';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

interface IProps {
  clientId?: string;
  expenseDate?: string;
  shouldFetch?: boolean;
}

interface IResponse {
  projects: IProjectsWithPolicies[];
  total: number;
}

export function useProjectsWithPolicies({ clientId, expenseDate, shouldFetch = true }: IProps) {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  function getParams() {
    let params = '';
    clientId && (params = `?clientId=${clientId}`);
    expenseDate && (params = `${params ? `${params}&` : '?'}expenseDate=${expenseDate}`);

    return params;
  }

  const { data, error, isLoading } = useFetch<IResponse>(
    `v1/projects/policy${getParams()}`,
    '',
    undefined,
    undefined,
    onError,
    shouldFetch,
  );

  return {
    projects: data?.projects || [],
    isLoading: isLoading,
    error,
  };
}
