import { IReport } from '@containers/Reports/context/types';
import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { expensesBaseUrl } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { ButtonContainer } from './styled';

interface Props {
  isLoading: boolean;
  submitAlertMessage: string;
  splittedReports: IReport[];
  onAlertConfirm: () => void;
  onAlertClose: () => void;
}

export const SubmitConfirmationModals = ({
  submitAlertMessage,
  isLoading,
  onAlertConfirm,
  onAlertClose,
  splittedReports,
}: Props) => {
  const t = useTranslate('organisms.reportDetailsPreview.feedback');

  const navigate = useNavigate();

  function goToReports() {
    navigate(`${expensesBaseUrl}/reports`);
  }

  return (
    <>
      <DangerActionModal
        headerIconLabel={t('sendReport.confirmation.attention')}
        headerTitle={t(`sendReport.confirmation.title`)}
        headerSubtitle={submitAlertMessage}
        open={!!submitAlertMessage}
        isLoading={isLoading}
        onCloseClick={onAlertClose}
        onActionClick={onAlertConfirm}
      />

      <DefaultModal
        open={splittedReports.length > 1}
        headerIconLabel={t('sendReport.feedback.success')}
        headerIcon="IconCheck"
        headerTitle={t(`sendReport.feedback.title`)}
        headerSubtitle={t(`sendReport.feedback.message`, {
          referenceId: splittedReports.map(report => report.referenceId).join(', '),
        })}
        headerTextAlign={'center'}
        isActionLoading={isLoading}
        footerCustom={
          <ButtonContainer>
            <Button size="large" variant="primary" onClick={goToReports}>
              {t('sendReport.feedback.goToReports')}
            </Button>
          </ButtonContainer>
        }
        onCloseClick={goToReports}
        onActionClick={goToReports}
      />
    </>
  );
};
