import { IGetAllCustomFields } from '@containers/Customizations/context/types/customFieldTypes';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export const useCustomFields = () => {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const { data, error, isLoading } = useFetch<IGetAllCustomFields>(
    'v1/custom-fields',
    '',
    undefined,
    undefined,
    onError,
  );

  return {
    error,
    isLoading: isLoading,
    customFields: data?.customFields,
  };
};
