export interface ISummaryApprovalFlow {
  id: number;
  type: ECustomApprovalType;
  name: string;
  active: boolean;
  description: string;
  priority: number;
}

export interface ICriteria {
  id?: number;
  key?: ECriteriaKey;
  type?: ECriteriaType;
  approvalFlowId?: number;
  operator?: ECriteriaOperator;
  statement?: ECriteriaStatement;
  value?: string;
}

export interface IApprovalFlow {
  id?: number;
  type: ECustomApprovalType;
  name: string;
  active: boolean;
  description: string;
  priority: number;
  sources: IApprovalFlowSource[];
  criteria: ICriteria[];
  target: ECustomApprovalTarget;
  companyId: number;
  customMessage?: string;
}

export interface IApprovalFlowSource {
  id?: number;
  type: EApprovalSourceType;
  approverId: number;
  approvalFlowId: number;
  configType?: EApprovalSourceType;
}

export enum EApprovalSourceType {
  COST_CENTER_OWNER = 'COST_CENTER_OWNER',
  COST_CENTER_OWNER_WITHOUT_DEFAULT = 'COST_CENTER_OWNER_WITHOUT_DEFAULT',
  IMMEDIATE_MANAGER = 'IMMEDIATE_MANAGER',
  SECOND_LEVEL_MANAGER = 'SECOND_LEVEL_MANAGER',
  THIRD_LEVEL_MANAGER = 'THIRD_LEVEL_MANAGER',
  FOURTH_LEVEL_MANAGER = 'FOURTH_LEVEL_MANAGER',
  FIFTH_LEVEL_MANAGER = 'FIFTH_LEVEL_MANAGER',
  MANUAL = 'MANUAL',
  PROJECT_OWNER = 'PROJECT_OWNER',
  APPROVAL_BY_HIERARCHY = 'APPROVAL_BY_HIERARCHY',
}

export enum ECustomApprovalTarget {
  ADVANCED = 'ADVANCED',
  REPORT = 'REPORT',
}

export enum ECustomApprovalType {
  AUTOMATIC_APPROVAL = 'AUTOMATIC_APPROVAL',
  AUTOMATIC_REFUSAL = 'AUTOMATIC_REFUSAL',
  MANUAL = 'MANUAL',
}

export enum ECriteriaStatement {
  AND = 'AND',
  OR = 'OR',
  WHEN = 'WHEN',
}

export enum ECriteriaOperator {
  EQUALS = 'EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  NOT_EQUALS = 'NOT_EQUALS',
  LESSER_THAN = 'LESSER_THAN',
  LESSER_THAN_OR_EQUAL = 'LESSER_THAN_OR_EQUAL',
}

export enum ECriteriaKey {
  ADVANCED = 'ADVANCED',
  AREA = 'AREA',
  ROLE = 'ROLE',
  CATEGORY = 'CATEGORY',
  SUBSIDIARY = 'SUBSIDIARY',
  REFUNDS = 'REFUNDS',
  VIOLATIONS = 'VIOLATIONS',
  REQUESTER = 'REQUESTER',
  RESPONSIBLE_PROJECT = 'RESPONSIBLE_PROJECT',
}

export enum ECriteriaType {
  NUMERIC = 'NUMERIC',
  TEMPORAL = 'TEMPORAL',
  TEXTUAL = 'TEXTUAL',
}
