import { DataGrid, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ReportHistoryGridContainer = styled.div`
  margin-top: ${props => props.theme.spacings.xs};
`;

export const GridContainer = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacings.s};
`;

export const Grid = styled(DataGrid)`
  display: flex;
`;

export const DescriptionHeaderColumn = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 700 !important;
`;

export const DescriptionBodyColumn = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600 !important;
`;
