import { DefaultModal } from '@molecules/Modal/DefaultModal';
import styled from 'styled-components';

export const ModalContent = styled.div`
  height: calc(100vh - 230px);
  position: relative;
  overflow: hidden;
  width: calc(100vw - 600px);
`;

export const ListTransferContent = styled.div`
  display: contents;
`;

export const ButtonLabel = styled.div`
  width: max-content;
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledContractModal = styled(DefaultModal)`
  .customize-report-modal-body {
    max-height: calc(100vh - 275px) !important;
  }
`;
