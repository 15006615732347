import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useCheckUnleashFlag } from '@shared/hooks/permissions/unleash/useCheckUnleashFlag';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { TSystemFeatures } from '@shared/types/systemFeatures';

interface IProps {
  permission?: EAuthZPermission;
  licenseFeature?: TSystemFeatures;
  unleashFlag?: string;
  hidden?: boolean;
  children?: React.ReactNode;
}

export function PermissionsChecker({ permission, licenseFeature, unleashFlag, hidden = false, children }: IProps) {
  const { checkLicenseFeature } = useLicenseFeatures();
  const checkUnleashFlag = useCheckUnleashFlag();
  const { checkPlatformPermission } = usePlatformPermissions();

  function checkPermissions() {
    let allowed: boolean = true;

    if (permission) {
      allowed = allowed && checkPlatformPermission(permission);
    }
    if (licenseFeature) {
      allowed = allowed && checkLicenseFeature(licenseFeature);
    }
    if (unleashFlag) {
      allowed = allowed && checkUnleashFlag(unleashFlag);
    }
    return allowed;
  }

  return checkPermissions() && !hidden ? <>{children}</> : <div></div>;
}
