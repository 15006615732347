import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Title = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 20px;
`;

export const Caption = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 16px;
`;
