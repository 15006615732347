import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useTranslation } from 'react-i18next';

type PlayerModalProps = {
  open: boolean;
  onClose(): void;
};

export function PlayerModal({ open, onClose }: PlayerModalProps) {
  const { t } = useTranslation();
  const url =
    'https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/accountability/Administrador+-+Liberac%CC%A7a%CC%83o+de+acesso+como+administrador.mp4';

  const context = 'molecules.acceptSubscriptionCheckbox.feedbacks.success';

  return (
    <DefaultModal
      open={open}
      onCloseClick={onClose}
      footerCustom={<></>}
      headerSubtitle={
        <iframe width="553" height="330" src={url} title={t(`${context}.videoTitle`)} allowFullScreen></iframe>
      }></DefaultModal>
  );
}
