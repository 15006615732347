import { useEffect, useMemo, useState } from 'react';
import { LinkButton, NotificationCard } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { IImportingContextState } from '@shared/contexts/BulkImportContext/types';

import * as SC from './styled';

export interface FeedbackBoxProps {
  onClick(): void;
  onClose(): void;
  refetch(): void;
  state: IImportingContextState;
  page: 'clients' | 'costCenters' | 'projects';
}

export const FeedbackBox = ({ onClick, refetch, state, page }: FeedbackBoxProps) => {
  const [open, setOpen] = useState(true);
  const translation = useTranslation();
  const t = (key: string, options?: object): string => translation.t(`molecules.feedbackBox.${key}`, options);

  const formOfDescriptionError = useMemo(
    () => (state.importingResponse?.result?.errors > 1 ? 'plural' : 'singular'),
    [state.importingResponse],
  );
  const formOfDescriptionCompleted = useMemo(
    () => (state.importingResponse.result.total - state.importingResponse.result.errors > 1 ? 'plural' : 'singular'),
    [state.importingResponse],
  );

  const variant = useMemo(
    () =>
      state.importingResponseIsLoading && state.hasTriedToImport
        ? 'info'
        : state.importingResponse.result?.errors > 0
        ? 'error'
        : 'success',
    [state],
  );

  const getFeedbackBoxDescription = () => {
    const description: string[] = [];
    if (variant === 'error') {
      if (state.importingResponse.result.total - state.importingResponse.result.errors > 0) {
        description.push(
          t(`description.${variant}.completed.${page}.${formOfDescriptionCompleted}`, {
            quantity: state.importingResponse.result.total - state.importingResponse.result.errors,
          }),
        );
      }

      description.push(
        t(`description.${variant}.fail.${page}.${formOfDescriptionError}`, {
          quantity: state.importingResponse.result.errors,
        }),
      );
    } else {
      description.push(
        t(`description.${variant}${variant === 'success' ? '.' + page : ''}`, {
          quantity: state.importingResponse.result.total,
        }),
      );
    }

    return description;
  };

  useMemo(() => {
    if (variant === 'info' || variant === 'success') {
      setTimeout(() => {
        setOpen(false);
        refetch();
      }, 8000);
    }
  }, [variant]);

  useEffect(() => {
    setOpen(true);
  }, [variant]);

  if (!open) {
    return <></>;
  }

  return (
    <NotificationCard.Root type={variant} variant="outlined">
      <NotificationCard.Icon iconName={variant === 'error' ? 'IconCircleX' : 'IconAlertCircle'} />
      <SC.FeedbacksContainer>
        <SC.FeedbacksTitle variant="body3">
          {t(`title.${variant}${variant === 'success' ? '' : '.' + page}`)}
        </SC.FeedbacksTitle>
        {getFeedbackBoxDescription().map((item, key) => (
          <SC.FeedbacksDescription key={key} variant="body3">
            {item}
          </SC.FeedbacksDescription>
        ))}
        {variant === 'error' && (
          <SC.FeedbacksLinkContainer>
            <LinkButton variant="default" onClick={onClick}>
              {t(`actionLabel.${variant}`)}
            </LinkButton>
          </SC.FeedbacksLinkContainer>
        )}
      </SC.FeedbacksContainer>
    </NotificationCard.Root>
  );
};
