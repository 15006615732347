import { useState } from 'react';
import { IDelegation } from '@containers/Customizations/context/types/settings/delegation';
import { useDelegateUsersMutate } from '@containers/Customizations/hooks/settings/delegation/useDelegateUsersMutate';
import { IBatchActionsActions } from '@organisms/Grid/BatchActions/BatchActions';
import { useSelectionRows } from '@organisms/Grid/BatchActions/hooks/useSelectionRows';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { SelectData } from '@shared/hooks/useSelectsOptions';

import { TTargetDelegation } from '../../DelegationPreview';

type TConfirmation = 'disable' | 'delete';

export enum EActions {
  DISABLE = 'disable',
  DELETE = 'delete',
}

enum EIcons {
  ACTIVE = 'IconToggleRight',
  DISABLE = 'IconToggleLeft',
  DELETE = 'IconTrash',
}

export function useDelegationActionsGrid(
  delegations: IDelegation[],
  target: TTargetDelegation,
  userOptions: SelectData['options'],
) {
  const t = useTranslate('delegation.configuration.grid');
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [confirmationType, setConfirmationType] = useState<TConfirmation>();
  const [isBatchAction, setIsBatchAction] = useState<boolean>(false);
  const [delegationSelected, setDelegationSelected] = useState<IDelegation>();
  const { isSelectedAll, selectedItems, toggleSelectAll, onSelectItemClick, unSelectAllClick } =
    useSelectionRows<IDelegation>(delegations);
  const {
    deleteDelegations,
    disableDelegations,
    isLoading: isLoadingMutate,
    listIdsLoading,
  } = useDelegateUsersMutate(userOptions);

  function onShowConfirmationActionsModal(): void {
    setShowConfirmationModal(_open => !_open);
  }

  async function onConfirmationActionModal() {
    if (confirmationType === 'disable') {
      await disableDelegations(isBatchAction ? selectedItems : [delegationSelected?.id]);
      selectedItems.length && unSelectAllClick();
    } else {
      await deleteDelegations(isBatchAction ? selectedItems : [delegationSelected?.id]);
      selectedItems.length && unSelectAllClick();
    }
    onShowConfirmationActionsModal();
  }

  function getSelectedDelegation(_id: IDelegation['id']): IDelegation {
    return delegations?.find(_delegation => _delegation?.id === _id);
  }

  function getIconsStatus(_id: IDelegation['id']) {
    return getSelectedDelegation(_id)?.active ? EIcons.DISABLE : EIcons.ACTIVE;
  }

  function onActionClick(_actionKey: EActions, _id?: IDelegation['id']) {
    const _delegationsSelectedIds: IDelegation['id'][] = _id ? [_id] : selectedItems;
    const _isBatchAction: boolean = _delegationsSelectedIds.length > 1;
    const _delegation: IDelegation = !_isBatchAction ? getSelectedDelegation(_delegationsSelectedIds[0]) : null;

    _delegation && setDelegationSelected(_delegation);
    setIsBatchAction(_delegationsSelectedIds.length > 1);

    switch (_actionKey) {
      case EActions.DISABLE:
        setConfirmationType('disable');
        if ((_delegationsSelectedIds.length === 1 && _delegation.active) || _delegationsSelectedIds.length > 1) {
          onShowConfirmationActionsModal();
        } else {
          disableDelegations([_delegation.id]);
        }
        break;

      case EActions.DELETE:
        setConfirmationType('delete');
        onShowConfirmationActionsModal();
        break;

      default:
        setConfirmationType(null);
        break;
    }
  }

  function getActionsRow(_id: IDelegation['id']) {
    const isExpense = target === 'expense';
    const key = isExpense ? EActions.DISABLE : EActions.DELETE;

    return {
      action: key,
      disable: isExpense ? !getSelectedDelegation(_id)?.active : false,
      tooltip: isExpense && !getSelectedDelegation(_id)?.active ? t('header.tooltips.disable') : '',
      onClick: () => onActionClick(key, _id as IDelegation['id']),
    };
  }

  function getTooltipBatchActions(key: EActions): string {
    if (key === EActions.DISABLE) {
      const delegationsActiveList = selectedItems?.filter(selectedId =>
        delegations?.some(delegation => delegation.id === selectedId && !delegation.active),
      );
      return delegationsActiveList?.length > 0 ? t('header.tooltips.disable') : '';
    }
    return '';
  }

  function getBatchActions(): IBatchActionsActions[] {
    const batchActions: IBatchActionsActions[] = [];

    delegations?.length &&
      Object.keys(EActions).forEach(key => {
        batchActions.push({
          key: EActions[key],
          allowed:
            (target === 'expense' && EActions[key] === EActions.DISABLE) ||
            (target === 'approval' && EActions[key] === EActions.DELETE),
          disabled: Boolean(getTooltipBatchActions(EActions[key])),
          iconName: EIcons[key],
          name: t(`header.${EActions[key]}`),
          tooltipMessage: getTooltipBatchActions(EActions[key]),
          onClick: _key => onActionClick(_key as EActions),
        });
      });

    return batchActions;
  }

  return {
    delegationSelected,
    showConfirmationModal,
    confirmationType,
    isSelectedAll,
    selectedItems,
    isBatchAction,
    isLoadingAction: isLoadingMutate,
    listIdsLoading,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    unSelectAllClick,
    onSelectItemClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
    getIconsStatus,
  };
}
