import { Dispatch, SetStateAction } from 'react';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { expensesCustomizationsBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

interface IProps {
  isLoading: boolean;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

export function RegisterPolicyFooter({ isLoading, step = 0, setStep }: IProps) {
  const t = useTranslate('policy.register.footer');
  const navigate = useNavigate();

  return (
    <>
      <div>
        <LinkButton variant="default" onClick={() => navigate(expensesCustomizationsBaseURL)}>
          {t('cancel')}
        </LinkButton>
      </div>
      <SC.ActionsButtonsFooterContainer>
        {step > 0 && (
          <Button variant="secondary" size="medium" loading={isLoading} onClick={() => setStep(step - 1)}>
            <Icons name="IconArrowLeft" fill="transparent" />
            {t('back')}
          </Button>
        )}
        <Button variant="primary" size="medium" type="submit" loading={isLoading}>
          {t(step === 0 ? 'continue' : 'finish')}
          <Icons name="IconArrowRight" color="#fff" fill="transparent" />
        </Button>
      </SC.ActionsButtonsFooterContainer>
    </>
  );
}
