import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Title = styled(Typography)`
  text-align: center;
`;

export const Description = styled(Typography)`
  text-align: center;
  color: ${props => props.theme.colors.neutral[50]};
`;
