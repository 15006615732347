import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

import { ColorVariant, SizeVariant } from '../../../themes/types/Variants';

interface ShapeIconContainerProps {
  variant: ColorVariant;
  size: SizeVariant;
}

interface IconProps {
  variant: ColorVariant;
  size?: number;
  $foregroundColor?: string;
}

export const ShapeIconContainer = styled('div')<ShapeIconContainerProps>(({ variant, size, ...props }) => ({
  display: 'flex',
  width: 'fit-content',
  height: 'fit-content',
  padding: getSize(size).padding,
  borderRadius: props.theme.borders.radius.circular,
  backgroundColor: getVariantColor(props.theme.colors, variant).background,
}));

export const Icon = styled(Icons)<IconProps>(({ variant, theme, $foregroundColor }) => ({
  borderRadius: theme.borders.radius.circular,
  color: $foregroundColor ? $foregroundColor : getVariantColor(theme.colors, variant).foreground,
}));

const getVariantColor = (colors, variant) => {
  const shapeIconColors = {
    error: {
      foreground: colors.feedback.error[40],
      background: colors.feedback.error[90],
    },
    info: {
      foreground: colors.feedback.info[40],
      background: colors.feedback.info[90],
    },
    success: {
      foreground: colors.feedback.success[40],
      background: colors.feedback.success[90],
    },
    secondary: {
      foreground: colors.secondary[40],
      background: colors.secondary[95],
    },
    neutral: {
      foreground: colors.neutral[10],
      background: colors.neutral[90],
    },
  };

  return shapeIconColors[variant];
};

const getSize = size => {
  const currentSize = {
    small: {
      padding: '8px',
    },
    medium: {
      padding: '14px',
    },
    large: {
      padding: '12px',
    },
    xsmall: {
      padding: '4px',
    },
  };

  return currentSize[size];
};
