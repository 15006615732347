import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { config } from '@shared/config';
import { createUploadLink } from 'apollo-upload-client';

import { Authorization } from './Authorization';

const httpLink = (companyId: string, accessToken: string) => {
  return createUploadLink({
    uri: `${config.API_URL}/graphql`,
    fetch: async (input, init) => {
      const authorization = await Authorization.getAccessToken();

      return fetch(input, {
        ...init,
        headers: {
          ...init.headers,
          Authorization: authorization || accessToken,
          'company-id': companyId || null,
        },
      });
    },
  });
};

let expiredToken: boolean = false;
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    if (!expiredToken) {
      expiredToken = graphQLErrors.some(({ extensions }) => {
        const exception = extensions.exception as { name: string; code: string; status: number; message: string };
        return (
          (exception?.status === 401 && exception?.code === 'UNAUTHORIZED_ERROR') ||
          (exception?.name === 'TokenExpiredError' && exception?.message === 'jwt expired')
        );
      });

      expiredToken && Authorization.redirectionPlatformHome();
    }
  }
});

export const apolloClient = (companyId: string, accessToken: string) =>
  new ApolloClient({
    link: from([errorLink, httpLink(companyId, accessToken)]),
    cache: new InMemoryCache({ addTypename: false }),
  });
