import { IConfigurationCategory } from '@containers/Customizations/context/types/categoryTypes';
import { SwitchCard } from '@molecules/SwitchCard';

import { CategoryListSwitchCardSkeleton } from './CategoryListSwitchCardSkeleton';
import * as SC from './styled';

export interface CategoryListSwitchCardProps {
  isLoading: boolean;
  isLoadingMutate: boolean;
  isLoadingAll: boolean;
  currentCategory: IConfigurationCategory;
  categories: IConfigurationCategory[];
  allowedActions: boolean;
  handleActiveCategory(category: IConfigurationCategory): void;
  handleOpenCategory(category: IConfigurationCategory): void;
  deleteCategory(category: IConfigurationCategory): void;
}

export const CategoryListSwitchCard = ({
  categories,
  currentCategory,
  isLoadingMutate,
  isLoadingAll,
  isLoading,
  allowedActions,
  handleActiveCategory,
  handleOpenCategory,
  deleteCategory,
}: CategoryListSwitchCardProps) => {
  if (isLoading) {
    return <CategoryListSwitchCardSkeleton />;
  }

  return (
    <SC.Container>
      {categories?.map((_category, index) => (
        <SwitchCard
          key={index}
          allowedActions={allowedActions}
          isActive={_category.active}
          label={_category.name}
          handleIsActive={() => handleActiveCategory(_category)}
          onEdit={() => handleOpenCategory(_category)}
          onDelete={!_category.readOnly ? () => deleteCategory(_category) : undefined}
          isLoading={isLoadingAll || (isLoadingMutate && currentCategory.id === _category.id)}
        />
      ))}
    </SC.Container>
  );
};
