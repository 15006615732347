import { useMemo, useState } from 'react';
import { EExpenseType, IExpenseInput, ISummaryReport } from '@containers/Expenses/context/types';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { useKmPolicies } from '@containers/Expenses/hooks/useKmPolicies';
import { IReport } from '@containers/Reports/context/types';
import { Tab, TabItens } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { useExpenseRegisterDriver } from '@shared/hooks/navigation/useExpenseRegisterDriver';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { ExpenseRegisterForm } from '../ExpenseRegisterForm';
import * as SC from './styled';
import { useMileageRegisterPermissionChecker } from './useMileageRegisterPermissionChecker';

export interface IProps {
  expense: IExpense;
  expenseIsLoading: boolean;
  onSubmitClick(data: IExpenseInput): void;
  onSelectReportClick(reportSelected: ISummaryReport | IReport): void;
}

type Tab = TabItens & {
  type: EExpenseType;
};

export function LaunchMileageExpensePreview({ expense, expenseIsLoading, onSubmitClick, onSelectReportClick }: IProps) {
  const params = useParams();

  const { t } = useTranslation();
  const location = useLocation();
  const expenseType = useCheckExpenseType();
  const navigateExpenseEdit = useExpenseRegisterDriver();
  const [reportSelected, setReportSelected] = useState(expense?.report || location?.state?.report);
  const { allowOdometer, allowOriginDestination, loading: kmPoliciesLoading } = useKmPolicies();

  const isEdit = useMemo(() => Boolean(params?.expenseId), [params.expenseId]);

  const { userHasPermission } = useMileageRegisterPermissionChecker({
    isEdit,
    expenseType,
    isLoadingPolicies: kmPoliciesLoading,
    isOdometerAllowed: allowOdometer,
    isOriginDestinationAllowed: allowOriginDestination,
  });

  const tabOptions = useMemo((): Tab[] => {
    const options: Tab[] = [];

    if (isEdit && expenseType === EExpenseType.GPS) {
      options.push({
        type: EExpenseType.GPS,
        label: t(`organisms.launchMileageExpensePreview.tabs.gps`),
        iconLabel: 'IconGps',
        component: getExpenseRegisterForm(),
        disabled: true,
      });

      return options;
    }

    if (allowOriginDestination || isEdit) {
      options.push({
        type: EExpenseType.ORIGIN_DESTINATION,
        label: t(`organisms.launchMileageExpensePreview.tabs.originAndDestiny`),
        iconLabel: 'IconRoute',
        component: getExpenseRegisterForm(),
        disabled: expenseIsLoading || isEdit,
      });
    }

    if (allowOdometer || isEdit) {
      options.push({
        type: EExpenseType.ODOMETER,
        label: t(`organisms.launchMileageExpensePreview.tabs.odometer`),
        iconLabel: 'IconGauge',
        component: getExpenseRegisterForm(),
        disabled: expenseIsLoading || isEdit,
      });
    }

    return options;
  }, [allowOdometer, allowOriginDestination, JSON.stringify(expense), expenseType, isEdit]);

  function onNavigateExpenseEdit(expenseType: EExpenseType) {
    navigateExpenseEdit({
      expenseType,
      expenseId: expense?.id,
      linkedReport: reportSelected,
      navigateState: location,
    });
  }

  function onTabChange(index: number) {
    if (isEdit) return;

    const option = tabOptions[index];

    return onNavigateExpenseEdit(option?.type || EExpenseType.ORIGIN_DESTINATION);
  }

  function getExpenseRegisterForm() {
    return (
      <ExpenseRegisterForm
        expenseType={expenseType}
        expense={expense}
        expenseIsLoading={expenseIsLoading || kmPoliciesLoading}
        onSubmitClick={onSubmitClick}
        onSelectReportClick={_report => {
          setReportSelected(_report);
          onSelectReportClick(_report);
        }}
      />
    );
  }

  const getDefaultTab = () => tabOptions.findIndex(option => option.type === expenseType);

  if (userHasPermission === false) {
    return <Navigate to="/expenseManagement/expenses/register" />;
  }

  if (!tabOptions.length) return null;

  return (
    <SC.Container>
      <Tab variant="secondary" onTabChanged={onTabChange} defaultTab={getDefaultTab()} tabItens={tabOptions} />
    </SC.Container>
  );
}
