import { useEffect, useMemo, useState } from 'react';
import { useFinancialReportsActionManager } from '@containers/Financial/components/organisms/Reports/FinancialReportsPreview/hooks/useFinancialReportsActionManager';
import { IReport } from '@containers/Reports/context/types';
import { useReportById } from '@containers/Reports/hooks/useReportById';
import { useReportMutate } from '@containers/Reports/hooks/useReportMutate';
import { WithdrawLinkedReport } from '@containers/Withdraw/components/WithdrawLinkedReport/WithdrawLinkedReport';
import { useWithdrawalsToAttach } from '@containers/Withdraw/hooks/dataSources/useWithdrawalsToAttach';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useAdvancesPermissions } from '@shared/hooks/permissions/systemPermissions/useAdvancesPermissions';
import { useReportsPermissions } from '@shared/hooks/permissions/systemPermissions/useReportsPermissions';
import { useTracking } from '@shared/hooks/useTracking';
import { useParams } from 'react-router-dom';

import { NewReportModal } from '../../molecules/NewReportModal';
import { ReportInfos } from '../ReportInfos';
import { AdvancesLinkedReport } from './AdvancesLinkedReport/AdvancesLinkedReport';
import { ExpensesLinkedReport } from './ExpensesLinkedReport/ExpensesLinkedReport';
import { HistoryReport } from './HistoryReport/HistoryReport';
import { ReportViolationsAndFeedback } from './ReportViolationsAndFeedback/ReportViolationsAndFeedback';
import * as SC from './styled';

export interface IProps {
  report?: IReport;
  isLoading?: boolean;
}

export const ReportDetailsPreview = ({ report, isLoading }: IProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const trackingEvent = useTracking();
  const reportId = Number(id);
  const [currentReport, setCurrentReport] = useState<IReport>(report);
  const [showReportModal, setShowReportModal] = useState(false);
  const [expenseTabName, setExpenseTabName] = useState(String(t('report.expenses')));
  const [advanceTabName, setAdvanceTabName] = useState(String(t('report.advance')));
  const { getReportActions } = useReportsPermissions();
  const { edit: allowedToEdit, delete: allowedToDelete } = getReportActions(currentReport?.status);
  const { updateReport, isLoading: updateReportIsLoading } = useReportMutate();
  const { reportById, reportIsLoading: currentReportIsLoading } = useReportById();
  const { onExportPdfClick } = useFinancialReportsActionManager({});
  const { view: isAllowedAdvances } = useAdvancesPermissions();
  const { withdrawals } = useWithdrawalsToAttach();

  const withdrawalsToMove = useMemo(() => {
    return [...(withdrawals || []), ...(currentReport?.withdraw || [])];
  }, [withdrawals, currentReport?.withdraw]);

  async function getReportById() {
    try {
      const response = await reportById({
        variables: {
          id: reportId,
        },
      });
      setCurrentReport(response.data?.getReportById);
    } catch (error) {
      showToast({ message: error?.message, type: 'error' });
    }
  }

  function handleReportUpdate(report: Pick<IReport, 'name' | 'description'>) {
    trackingEvent('report_update_clicked', { reportId: id });
    updateReport(parseInt(id), report).then(() => setShowReportModal(false));
  }

  function getTabItems() {
    const items = [
      {
        label: expenseTabName,
        component: (
          <ExpensesLinkedReport
            report={currentReport}
            reportIsLoading={currentReportIsLoading || isLoading || updateReportIsLoading}
            actionsIsAllowed={allowedToEdit || allowedToDelete}
            setExpenseTabName={setExpenseTabName}
          />
        ),
      },
      {
        label: t('report.historic'),
        component: <HistoryReport reportId={reportId} />,
      },
    ];

    if (isAllowedAdvances)
      items.splice(1, 0, {
        label: advanceTabName,
        component: (
          <AdvancesLinkedReport
            reportIsLoading={currentReportIsLoading || isLoading || updateReportIsLoading}
            report={currentReport}
            setAdvanceTabName={setAdvanceTabName}
          />
        ),
      });

    if (withdrawalsToMove?.length)
      items.splice(isAllowedAdvances ? 2 : 1, 0, {
        label: `${t('report.withdraw')} (${currentReport?.withdraw?.length || 0})`,
        component: (
          <WithdrawLinkedReport
            isLoading={currentReportIsLoading || isLoading || updateReportIsLoading}
            report={currentReport}
            reportByIdUpDate={getReportById}
          />
        ),
      });

    return items;
  }

  useEffect(() => {
    if (!report && !isLoading) {
      getReportById();
    }
  }, [id]);

  useEffect(() => {
    if (report && !isLoading) {
      setCurrentReport({ ...report, withdraw: report.withdraw || [] });
    }
  }, [report]);

  return (
    <SC.ReportDetailsPreviewContainer>
      <ReportViolationsAndFeedback report={currentReport} />

      <ReportInfos
        {...currentReport}
        isLoading={currentReportIsLoading || updateReportIsLoading || isLoading}
        onEditReport={() => setShowReportModal(true)}
        onExportReport={() => onExportPdfClick([currentReport.id])}
      />
      <SC.TabGrid tabItens={getTabItems()} />

      <NewReportModal
        description={currentReport?.description}
        name={currentReport?.name}
        actionType="update"
        isLoading={isLoading}
        open={showReportModal}
        onActionClick={handleReportUpdate}
        onCancelClick={() => setShowReportModal(false)}
      />
    </SC.ReportDetailsPreviewContainer>
  );
};
