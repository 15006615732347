enum EActions {
  REDUCER_FUNCTION = 'REDUCER_FUNCTION',
}

interface IActions {
  type: EActions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export { IActions, EActions };
