import { IUserSelection } from '@containers/Expenses/context/types/users';
import {
  EReportStatus,
  IReport,
  IReportCreationDate,
  IReportEndDate,
  IReportStartDate,
} from '@containers/Reports/context/types';

export interface IReportForApprovalFilter {
  isPendingApproval?: boolean;
  status?: EReportsMainStatus[];
  search?: string;
  reportCreationDate?: IReportCreationDate;
  reportStartDate?: IReportStartDate;
  submissionDateForApproval?: IReportEndDate;
  createdBy?: IUserSelection['id'][];
  includeReimbursed?: boolean;
}

export enum EReportsMainStatus {
  APPROVED = 'APPROVED',
  SUBMITTED = 'SUBMITTED',
  REPROVED = 'REPROVED',
  OPEN = 'OPEN',
  REIMBURSED = 'REIMBURSED',
}

export enum EReportsStatusColorVariant {
  OPEN = 'secondary',
  REIMBURSED = 'success',
  REPROVED = 'error',
  SUBMITTED = 'neutral',
  APPROVED = 'info',
}

export interface ApprovalsReportUser {
  id?: number;
  name: string;
  cpf?: string;
  email?: string;
}

export interface IApprovalsReportGrid {
  id: number;
  referenceId: string;
  name: string;
  status: EReportsMainStatus;
  currencyPrefix: string;
  value: number;
  createdAt: string;
  violationNumber: number;
  commentsNumber: number;
  user: ApprovalsReportUser;
  message?: string;
  error?: boolean;
}

export interface IGetApprovalsReportResponse {
  getReportForApproval: {
    reports: IApprovalsReportGrid[];
    currentPage: number;
    limit: number;
    pages: number;
    total: number;
  };
}

export interface IGetReportsStatusUpdateRequest {
  reports: IReport['id'][];
  status: EReportStatus;
  message?: string;
}

export interface IGetReportsStatusUpdateResponse {
  updateReportListStatus: {
    reports: IApprovalsReportGrid[];
    total: number;
  };
}

export enum EReportPdfExportType {
  NORMAL = 'NORMAL',
  CATEGORY = 'CATEGORY',
}
