import { useEffect, useState } from 'react';
import { IConfigurationAdvancesSetting } from '@containers/Customizations/context/types/advances';
import { useAdvancesSettings } from '@containers/Customizations/hooks/settings/advances/useAdvancesSettings';
import { useAdvancesSettingsMutate } from '@containers/Customizations/hooks/settings/advances/useAdvancesSettingsMutate';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';

import { AdvancesExportPreferences } from './AdvancesExportPreferences/AdvancesExportPreferences';
import { FieldsSettings } from './FieldsSettings';
import { GeneralAdvancesSettings } from './GeneralAdvancesSettings/GeneralAdvancesSettings';
import * as SC from './styled';

export function AdvancesPreview() {
  const [currentSettings, setCurrentSettings] = useState<IConfigurationAdvancesSetting>();
  const { settings, isLoading } = useAdvancesSettings();
  const { updateAdvancesSetting } = useAdvancesSettingsMutate();

  async function handleChange(key: string, value: unknown) {
    setCurrentSettings({ ...currentSettings, [key]: value });

    try {
      await updateAdvancesSetting({ ...currentSettings, [key]: value });
    } catch (err) {
      setCurrentSettings(settings);
    }
  }

  useEffect(() => {
    if (!isLoading && settings) {
      setCurrentSettings(settings);
    }
  }, [settings, isLoading]);

  return (
    <SC.Container>
      <GeneralAdvancesSettings advancesSettings={currentSettings} handleChange={handleChange} isLoading={isLoading} />
      <FieldsSettings advancesSettings={currentSettings} handleChange={handleChange} isLoading={isLoading} />
      <PermissionsChecker licenseFeature="BASIC_ADVANCE">
        <AdvancesExportPreferences
          advancesSettings={currentSettings}
          handleChange={handleChange}
          isLoading={isLoading}
        />
      </PermissionsChecker>
    </SC.Container>
  );
}
