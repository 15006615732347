import { TTargetDelegation } from '@containers/Customizations/components/organisms/Delegation/DelegationPreview';
import { IDelegationInput } from '@containers/Customizations/context/types/settings/delegation';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { SelectData } from '@shared/hooks/useSelectsOptions';
import { mutate } from 'swr';

interface IResponse {
  delegateUserId: string;
  error: boolean;
  message: string;
}

export function useDelegateUsersSave(target: TTargetDelegation, users: SelectData['options']) {
  const { mutation } = useMutate(target === 'expense' ? 'v1/user-delegation' : 'v1/user-delegation-approval');
  const t = useTranslate('delegation.configuration');

  async function handleSave(delegate: IDelegationInput) {
    try {
      const response: IResponse[] = await mutation.trigger({
        path: '',
        options: {
          method: 'POST',
          body: JSON.stringify(delegate),
        },
      });

      response.forEach(_delegation => {
        if (_delegation.error) {
          showToast({
            message: `${t('labelPeople')}: ${
              users?.find(user => String(user.value) === _delegation.delegateUserId)?.label || ''
            } - ${_delegation.message}`,
            type: 'error',
          });
        }
      });

      const success = response?.some(_delegation => !_delegation.error);

      if (success) {
        showToast({
          message: t(`feedbacks.create.success.${target}`),
          type: 'success',
        });

        mutate(
          key =>
            typeof key === 'string' &&
            key.includes(target === 'expense' ? 'v1/user-delegation?userId' : 'v1/user-delegation-approval?userId'),
        );
      }

      return success;
    } catch (_error) {
      showToast({
        message: _error?.message ?? t(`feedbacks.create.error.${target}`),
        type: 'error',
      });
    }
  }

  return {
    handleSave,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
