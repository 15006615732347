import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { approvalFlowsBaseURL } from '@shared/constant';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { EFlowStep } from '../CustomApprovalFlowRegister';
import * as SC from './styled';

interface IProps {
  step: EFlowStep;
  onBackClick: () => void;
}

export function CustomApprovalFlowRegisterFooterPage({ step, onBackClick }: IProps) {
  const t = useTranslate('approvalFlows.pageRegister.buttons');
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const isApprovalReport = currentPath.includes('reports');
  const { colors } = useTheme();

  return (
    <SC.Container>
      <div>
        <LinkButton
          variant="default"
          onClick={() => navigate(`${approvalFlowsBaseURL}/${isApprovalReport ? 'reports' : 'advances'}`)}>
          {t('cancel')}
        </LinkButton>
      </div>
      <SC.ActionsButtonsFooterContainer>
        {step === EFlowStep.APPROVAL_TYPE && (
          <Button variant="secondary" size="medium" onClick={onBackClick}>
            <Icons name="IconArrowLeft" fill="transparent" />
            {t('back')}
          </Button>
        )}

        <Button variant="primary" size="medium" type="submit">
          {t(step === EFlowStep.BASIC_INFORMATION ? 'continue' : 'save')}
          <Icons name="IconArrowRight" color={colors.neutral[100]} fill="transparent" />
        </Button>
      </SC.ActionsButtonsFooterContainer>
    </SC.Container>
  );
}
