import { NetworkStatus, QueryResult } from '@apollo/client';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { getReportById } from '../context/queries/report';
import { IGetReportByIdProps, IGetReportByIdResponse, IReport } from '../context/types/report';

export interface IUseReportByIdProps {
  reportId: number;
}

interface IUseReportById {
  reportById({ variables: { id } }): Promise<QueryResult<IGetReportByIdResponse, IGetReportByIdProps>>;
  report: IReport;
  reportIsLoading: boolean;
  reportError: unknown;
}

export const useReportById = (): IUseReportById => {
  const [reportById, { loading, data, error: reportError, networkStatus }] = useLazyQuery<
    IGetReportByIdResponse,
    IGetReportByIdProps
  >(getReportById, { notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' });

  return {
    reportById,
    report: data?.getReportById,
    reportIsLoading: loading || networkStatus === NetworkStatus.loading,
    reportError,
  };
};
