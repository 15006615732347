import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  .filters-container {
    width: 100%;

    .typography--variant-body3 {
      width: fit-content;
    }
  }
  .filter-container {
    display: inline-flex;
    gap: ${props => props.theme.spacings.xs3};
    .MuiButtonBase-root {
      white-space: nowrap;
    }
  }
`;

export const ExportButton = styled(Button)`
  white-space: nowrap;
  font-weight: 600 !important;
`;

export const ModalContainer = styled.div`
  text-align: center;
`;
