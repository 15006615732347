import { useContext } from 'react';
import {
  TUsersStatusSummary,
  useAccountingPendingExpensesStatus,
} from '@containers/Financial/hooks/dataSources/useAccountingPendingExpensesStatus';
import { Tab, TabItens as TabItems } from '@flash-tecnologia/hros-web-ui-v2';
import { AccountingContext } from '@shared/contexts/AccountingContext/AccountingContext';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useCheckUnleashFlag } from '@shared/hooks/permissions/unleash/useCheckUnleashFlag';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { AccountingPendingExpenses } from '../Reports/AccountingPendingExpenses/AccoutingPendingExpenses';
import { FinancialReportsPreview } from '../Reports/FinancialReportsPreview';

export function AccountabilityPreview() {
  const t = useTranslate('pages.financialReports.Accountability.tabs');
  const { reportsQuantity } = useContext(AccountingContext);
  const { checkPlatformPermission } = usePlatformPermissions();
  const { data } = useAccountingPendingExpensesStatus({ shouldFetch: true });

  const typedData = data as TUsersStatusSummary;

  const tabItems: TabItems[] = [];
  const accountingView = checkPlatformPermission(EAuthZPermission.ACCOUNTING_VIEW);
  const checkUnleashFlag = useCheckUnleashFlag();

  if (accountingView) {
    tabItems.push({
      component: <FinancialReportsPreview />,
      label: `${t(`pendingAccounts`)} ${Boolean(reportsQuantity) ? '(' + reportsQuantity + ')' : ''} `,
    } as TabItems);

    if (checkUnleashFlag('FLASH_OS_ACCOUNTS_PENDING_EXPENSES'))
      tabItems.push({
        component: <AccountingPendingExpenses />,
        label: `${t('pendingAccountability')} ${
          Boolean(typedData?.pendingAccounting?.count) ? '(' + Number(typedData?.pendingAccounting?.count) + ')' : ''
        }`,
      } as TabItems);
  }

  return <Tab tabItens={tabItems} />;
}
