import '../src/i18n';

import { ApiProvider } from '@atoms/ApiProvider/ApiProvider';
import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary/ModuleErrorBoundary';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { DelegateContextProvider } from '@shared/contexts/DelegateContext/DelegateContext';
import { SectionContextProvider } from '@shared/contexts/SessionContext/sessionContext';
import { Trans } from 'react-i18next';

import AppRouter from './routes';

export default function Root() {
  return (
    <ThemeProvider>
      <ModuleErrorBoundary moduleName="Gestão de despesas">
        <SectionContextProvider>
          <DelegateContextProvider>
            <ApiProvider>
              <Trans i18nKey="translations">
                <AppRouter />
              </Trans>
            </ApiProvider>
          </DelegateContextProvider>
        </SectionContextProvider>
      </ModuleErrorBoundary>
    </ThemeProvider>
  );
}
