import { Avatar as AvatarMui } from '@mui/material';
import { useTheme } from 'styled-components';

import * as SC from './styled';

export interface IAvatarProps {
  src?: string;
  alt: string;
  size?: number;
}

export function Avatar({ alt, src = null, size = 40 }: IAvatarProps) {
  const { colors } = useTheme();

  function getAvatarAlt() {
    const splitAlt = alt?.split(' ');
    return `${splitAlt?.[0][0]}${splitAlt?.[1] ? splitAlt?.[1][0] : ''}`;
  }

  return (
    <AvatarMui
      sx={{
        width: size,
        height: size,
        background: colors.secondary[95],
        color: colors.secondary[50],
        fontWeight: 700,
      }}
      alt={alt}
      src={src}>
      <SC.Description variant="body3"> {getAvatarAlt()}</SC.Description>
    </AvatarMui>
  );
}
