import { ShapeIcon } from '@atoms/ShapeIcon';
import { IconButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Dialog } from '@mui/material';
import styled from 'styled-components';

import { TextAlign } from 'src/themes/types/Variants';

interface HeaderTextAlign {
  align: TextAlign;
}

export const ModalContainer = styled.div`
  background-color: ${props => props.theme.colors.neutral[100]};
  max-width: 100%;
`;

export const ModalDefault = styled(Dialog)`
  &.MuiDialog-root {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    outline: none;

    .MuiDialog-container {
      width: 100%;
      padding: ${props => props.theme.spacings.m};
    }

    .MuiDialog-paper {
      width: auto;
      max-width: 100%;
      border-radius: ${props => props.theme.borders.radius.l};
      margin: 0;
    }

    @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
      width: 100%;
    }
  }
`;

export const ModalHeaderContainer = styled.div`
  position: relative;
  padding: ${props => props.theme.spacings.m} ${props => props.theme.spacings.xl4};
  width: 100%;
  border-bottom: ${props => props.theme.borders.width.xs2} solid ${props => props.theme.colors.neutral[90]};
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    padding: ${props => props.theme.spacings.m} ${props => props.theme.spacings.m};
  }
`;

export const IconClose = styled(IconButton).attrs({
  icon: 'IconX',
  variant: 'line',
  size: 'small',
})`
  position: absolute;
  top: ${props => props.theme.spacings.xs3};
  right: ${props => props.theme.spacings.xs};
`;

export const Title = styled(Typography).attrs({
  variant: 'headline6',
})<HeaderTextAlign>`
  font-weight: 700;
  text-align: ${props => props.align || 'left'};
  color: ${props => props.theme.colors.neutral[10]};

  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    font-size: 22px !important;
    line-height: 28px !important;
  }
`;

export const Subtitle = styled(Typography).attrs({
  variant: 'body3',
})<HeaderTextAlign>`
  font-weight: 400;
  text-align: ${props => props.align || 'left'};
  color: ${props => props.theme.colors.neutral[50]};
  max-width: 535px;
  margin-top: ${props => props.theme.spacings.xs4};
  align-self: ${props => (props.align ? 'center' : 'unset')};
`;

export const ModalBodyContainer = styled.div`
  padding: ${props => props.theme.spacings.m} ${props => props.theme.spacings.xl4};
  max-height: calc(100vh - 385px);
  height: 100%;
  overflow: auto;

  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    padding: ${props => props.theme.spacings.m} ${props => props.theme.spacings.m};
  }
`;

export const ModalFooterContainer = styled.div`
  padding: ${props => props.theme.spacings.xs} ${props => props.theme.spacings.xl4};
  border-top: 1px solid ${props => props.theme.colors.neutral[90]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.neutral[100]};
  border-bottom-left-radius: ${props => props.theme.borders.radius.l};
  border-bottom-right-radius: ${props => props.theme.borders.radius.l};

  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    padding: ${props => props.theme.spacings.m} ${props => props.theme.spacings.m};
  }
`;

export const HeaderIcon = styled(ShapeIcon)`
  fill: ${props => props.theme.colors.secondary[50]};
`;

export const HeaderUpperItem = styled.div``;

export const IconHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > div {
    padding: 20px;
  }
`;

export const IconLabel = styled(Typography).attrs({
  variant: 'body3',
})`
  text-align: center !important;
  font-weight: 700 !important;
  color: ${props => props.theme.colors.secondary[50]};
  margin-top: ${props => props.theme.spacings.xs3};
  margin-bottom: ${props => props.theme.spacings.xs2};
`;
