import { IPaymentMethod } from '@containers/Customizations/context/types/PaymentMethod';
import { IClient, IProject, IUser } from '@containers/Projects/context/types/projects';
import { IReport } from '@containers/Reports/context/types';

export interface IAdvance {
  id: number;
  referenceId: string;
  status: EAdvanceStatus;
  advanceDate: string;
  value: number;
  currencyPrefix: string;
  currencyCode: string;
  violationNumber: number;
  description: string;
  approver: IApprover;
  user: IReportUser;
  report: IReport;
  costCenter: ICostCenter;
  project: IProject;
  client: IClient;
  paymentMethod: IPaymentMethod;
  history: IHistoryRegistry[];
}

export enum EAdvanceStatus {
  APPROVED = 'APPROVED',
  SUBMITTED = 'SUBMITTED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  REPROVED = 'REPROVED',
  FINISHED = 'FINISHED',
  AWAITING_FINANCIAL_PROCESSING = 'AWAITING_FINANCIAL_PROCESSING',
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
  PROCESSING_PAYMENT_ERROR = 'PROCESSING_PAYMENT_ERROR',
}

export enum EAdvancesStatusColorVariant {
  OPEN = 'secondary',
  FINISHED = 'success',
  REPROVED = 'error',
  SUBMITTED = 'neutral',
  APPROVED = 'info',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PENDING = 'error',
}

export interface IAdvancesStatusSummary {
  open: ISummaryQuantityDetails;
  submitted: ISummaryQuantityDetails;
  approved: ISummaryQuantityDetails;
  reproved?: ISummaryQuantityDetails;
  finished?: ISummaryQuantityDetails;
  pending?: ISummaryQuantityDetails;
}

export interface ISummaryQuantityDetails {
  count: number;
  totalValue: number;
}
export interface IApprover {
  id: number;
  name: string;
  email: string;
}

export interface IReportUser extends IApprover {}

export interface ICostCenter {
  id: number;
  active: boolean;
  referenceId: string;
  name: string;
}

export interface ClientContact {
  id: number;
  name: string;
  email: string;
  phone: string;
}

export interface IHistoryRegistry {
  id: number;
  createdAt: number;
  description: string;
  user: IUser;
}

export interface IAdvanceListResponse {
  currentPage: number;
  pages: number;
  limit: number;
  total: number;
  advances: IAdvance[];
}

export interface IAdvanceListFilters {
  reportId?: IReport['id'];
  page?: number;
  limit?: number;
  isOpenToAttach?: boolean;
  status?: EAdvanceStatus[];
  paymentMethod?: number[];
  startDate?: string;
  endDate?: string;
  referenceId?: string;
  search?: string;
}

export interface IGetAdvancesResponse {
  advances: IAdvance[];
  total: number;
  pages: number;
  currentPage: number;
}

export interface IAdvancesResponse {
  getAdvances: IGetAdvancesResponse;
}

export interface IDeleteAdvance {
  id: number;
  message: string;
  error: boolean;
}
export interface IDeleteAdvanceResponse {
  advances: IDeleteAdvance[];
  total: number;
}

export interface IDeleteAdvanceRequest {
  advanceIds: number[];
}
export interface IUpdateAdvanceStatusResponse {
  updateAdvancesStatus: {
    error: boolean;
  };
}

export interface IUpdateAdvanceStatusRequest {
  advancesIds: number[];
  message?: string;
  status: IAdvance['status'];
}
