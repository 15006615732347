import { ShapeIcon } from '@atoms/ShapeIcon';
import { IExpenseAnalytics } from '@containers/Analytics/context/types/expenses';
import { EExpenseType, IExpenses } from '@containers/Expenses/context/types';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { ActionsFeedback } from '@molecules/ActionsFeedback';
import { useCheckIsKmType } from '@shared/hooks/useCheckIsKmType';

import * as SC from './styled';

interface IProps {
  expense: IExpenses | IExpenseAnalytics;
}

export function ExpenseDetailsByCategory({ expense }: IProps) {
  const { isKmType } = useCheckIsKmType(expense?.type);
  const { t } = useTranslation();

  const getIconName = (): IconTypes => {
    if (expense.cardId || expense.card?.id || expense.card?.lastFourDigits?.length === 16) return 'IconCreditCard';
    if (expense.type === EExpenseType.GPS) return 'IconGps';
    if (isKmType) return 'IconMapPin';
    return 'IconReceipt';
  };

  return (
    <SC.TdContent>
      <ShapeIcon icon={getIconName()} size="small" variant={'secondary'} />
      <SC.CategoryInfosContainer>
        <SC.Description>
          {expense.category?.description || t('organisms.expenseGrid.uninformedCategory')}
        </SC.Description>
        <SC.ActionsFeedbackContainer>
          <ActionsFeedback
            numberOfActions={expense.violationNumber}
            size="small"
            invert
            showAttachments={expense.attachmentsNumber > 0}
            showMessages={expense.commentsNumber > 0}
          />
        </SC.ActionsFeedbackContainer>
      </SC.CategoryInfosContainer>
    </SC.TdContent>
  );
}
