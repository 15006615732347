import { useContext, useMemo } from 'react';
import { EPolicyLevel, IPolicy } from '@containers/Policies/context/types/types';
import { useOptionsConditions } from '@containers/Policies/hooks/useOptionsConditions';
import { IconButton, Icons, LinkButton, SelectField, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { FieldRegister } from '../../molecules/FieldRegister/FieldRegister';
import * as SC from './styled';

export function PolicyConditions() {
  const t = useTranslate('policy.register.conditions');
  const { companyName, companyId } = useContext(SessionContext);
  const { control, getValues } = useFormContext<IPolicy>();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'conditions',
  });
  const { options, isLoading } = useOptionsConditions();
  const { checkLicenseFeature } = useLicenseFeatures();
  const isControlPlan = useMemo(() => checkLicenseFeature('ADVANCED_EXPENSE'), []);

  return (
    <SC.Container>
      {fields?.map((condition, index) => (
        <SC.ContainerRow key={condition.id}>
          <SC.ContentRow>
            <SC.Description variant="body3">{t(index === 0 ? 'when' : 'and')}</SC.Description>
            <FieldRegister isLoading={isLoading}>
              <Controller
                name={`conditions.${index}.key`}
                rules={{
                  required: true,
                }}
                control={control}
                defaultValue={null}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <SelectField
                    {...field}
                    inputRef={ref}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    label={`${t('fields.conditionLabel')} ${index + 1}`}
                    disabled={!isControlPlan}
                    onSelectChange={(_, data) =>
                      update(index, { key: data.value, value: data.value === EPolicyLevel.COMPANY ? companyId : '' })
                    }
                    options={options.conditionOptions.map(option => ({
                      ...option,
                      disabled: getValues('conditions')?.some(condition => condition.key === option.value),
                    }))}
                  />
                )}
              />
            </FieldRegister>
            {condition.key && (
              <>
                <SC.Description variant="body3">{t('itIs')}</SC.Description>

                <FieldRegister isLoading={isLoading}>
                  <Controller
                    name={`conditions.${index}.value`}
                    rules={{
                      required: true,
                    }}
                    control={control}
                    defaultValue={''}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <SelectField
                        {...field}
                        inputRef={ref}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        label={
                          getValues(`conditions.${index}.key`) === EPolicyLevel.COMPANY
                            ? companyName
                            : t(`conditionOptions.${condition?.key?.toLocaleLowerCase()}`)
                        }
                        onSelectChange={(_, data) => field.onChange(data.value)}
                        options={options[`${condition.key?.toLocaleLowerCase()}Options`] || []}
                        disabled={getValues(`conditions.${index}.key`) === EPolicyLevel.COMPANY}
                        onChange={() => null}
                        searchable
                      />
                    )}
                  />
                </FieldRegister>
              </>
            )}
          </SC.ContentRow>
          {index !== 0 ? (
            <div>
              {isLoading ? (
                <Skeleton width={42} height={42} variant="circular" />
              ) : (
                <IconButton variant="line" icon="IconTrash" size="small" onClick={() => remove(index)} />
              )}
            </div>
          ) : (
            <SC.ContainerTrash />
          )}
        </SC.ContainerRow>
      ))}

      {!Boolean(getValues('conditions')?.length === options.conditionOptions?.length) && isControlPlan && (
        <LinkButton variant="default" disabled={false} style={{ width: 'fit-content' }}>
          <SC.LinkContent
            onClick={() =>
              append({
                key: null,
                value: '',
              })
            }>
            {t('addConditional')}
            <Icons name="IconPlus" fill="transparent" size={18} />
          </SC.LinkContent>
        </LinkButton>
      )}
    </SC.Container>
  );
}
