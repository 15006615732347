import { gql } from '@apollo/client';

export const getAllExpensesToBind = gql`
  query GetAllExpensesToBind($page: Int!, $limit: Int!, $onlyUnattached: Boolean) {
    getExpenses(page: $page, limit: $limit, onlyUnattached: $onlyUnattached) {
      expenses {
        id
        currencyPrefix
        value
        category {
          description
        }
        establishment {
          place
        }
      }
    }
  }
`;
