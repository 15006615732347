import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { useTranslation } from '@locale/Translator';

import { RegisterClientForm } from '../RegisterClientForm';
import * as SC from './styled';

interface RegisterClientPreviewProps {
  onSubmit(data: IConfigurationClient): void;
  step: number;
}

export const RegisterClientPreview = ({ step, onSubmit }: RegisterClientPreviewProps) => {
  const { t } = useTranslation();

  return (
    <SC.Container>
      <SC.LeftContainer>
        <SC.SectionTitle variant="headline7">
          {t(`clients.registerClient.steps.pageContent.${step}.title`)}
        </SC.SectionTitle>
        <SC.SectionSubtitle variant="body3">
          {t(`clients.registerClient.steps.pageContent.${step}.subTitle`)}
        </SC.SectionSubtitle>
      </SC.LeftContainer>
      <RegisterClientForm onSubmit={onSubmit} step={step} />
    </SC.Container>
  );
};
