import { useState } from 'react';
import { ICostCenterCompany } from '@containers/CostCenters/context/types/costCenters';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { mutate } from 'swr';

interface IResponse {
  id: ICostCenterCompany['id'];
  error: boolean;
  message: string;
}

export function useStatusSwitchBatchCostCenters() {
  const t = useTranslate('pages.costCenterRegister.feedbacks.costCenterStatusSuccess');
  const { mutation } = useMutate('v1/cost-centers');
  const [listIdsLoading, setListIdsLoading] = useState<ICostCenterCompany['id'][]>([]);

  async function toggleCostCenters(toggle: boolean, ids: string[]) {
    const result: IResponse[] = await mutation.trigger({
      path: `toggle-active`,
      options: {
        method: 'PATCH',
        body: JSON.stringify({ active: toggle, ids: ids }),
      },
    });
    return result;
  }

  async function setStatusBatchCostCenters(ids: ICostCenterCompany['id'][] = [], active: boolean) {
    setListIdsLoading([...listIdsLoading, ...ids]);
    try {
      await toggleCostCenters(active, ids);
      const _listIdsLoading = listIdsLoading;

      ids.forEach(id => {
        const index = _listIdsLoading?.findIndex(_id => _id === id);
        _listIdsLoading?.splice(index, 1);
      });

      showToast({
        message: `${t('plural')}`,
        type: 'success',
      });

      mutate(key => typeof key === 'string' && key.includes('v1/cost-centers?page='));
      setListIdsLoading(_listIdsLoading);
    } catch (_error) {
      showToast({
        message: _error.message,
        type: 'error',
      });
      setListIdsLoading([]);
    }
  }

  return {
    setStatusBatchCostCenters,
    error: mutation?.error,
    isLoading: mutation?.isMutating,
    listIdsLoading,
  };
}
