import { useMemo } from 'react';
import { EAdvanceStatus, IAdvance } from '@containers/Advances/context/types/advance';
import { useTranslation } from '@locale/Translator';
import { SelectOption, serializeSelectOptions } from '@shared/helpers/selectOptions';
import { useIntegrations } from '@shared/hooks/useIntegrations';

export const useAdvancesStatus = () => {
  const { t } = useTranslation();
  const { hasFinancialIntegration } = useIntegrations();

  const getAdvancesStatusColor = (status: IAdvance['status']) => {
    switch (status) {
      case EAdvanceStatus.OPEN:
      case EAdvanceStatus.PENDING:
        return 'secondary';
      case EAdvanceStatus.APPROVED:
        return 'info';
      case EAdvanceStatus.FINISHED:
        return 'success';
      case EAdvanceStatus.REPROVED:
        return 'error';
      case EAdvanceStatus.SUBMITTED:
      default:
        return 'neutral';
    }
  };

  const advancedStatusOptions = useMemo(() => {
    const _options = [];
    Object.keys(EAdvanceStatus).forEach(status => {
      if (
        status === EAdvanceStatus.AWAITING_FINANCIAL_PROCESSING ||
        status === EAdvanceStatus.PROCESSING_PAYMENT ||
        status === EAdvanceStatus.PROCESSING_PAYMENT_ERROR
      ) {
        if (hasFinancialIntegration) {
          _options.push({
            id: EAdvanceStatus[status],
            name: t(`organisms.advances.grid.body.status.${EAdvanceStatus[status]?.toLowerCase()}`),
          });
        }
      } else {
        _options.push({
          id: EAdvanceStatus[status],
          name: t(`organisms.advances.grid.body.status.${EAdvanceStatus[status]?.toLowerCase()}`),
        });
      }
    });
    const options: SelectOption[] = serializeSelectOptions({
      options: _options,
    });
    return options;
  }, [hasFinancialIntegration]);

  const getAdvancesStatusLabel = (status: IAdvance['status']) => {
    return t(`organisms.advances.grid.body.status.${status.toLowerCase()}`);
  };

  const getAdvancesShapeIconStatusColor = (status: IAdvance['status']) => {
    const color = getAdvancesStatusColor(status);
    return color === 'secondary' ? 'default' : color;
  };

  return {
    advancedStatusOptions,
    getAdvancesStatusColor,
    getAdvancesStatusLabel,
    getAdvancesShapeIconStatusColor,
  };
};
