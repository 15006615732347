import { useState } from 'react';
import { DateRange } from '@atoms/FilterFactory';
import { EAdvanceStatus } from '@containers/Advances/context/types/advance';
import { expensesApprovalsBaseURL } from '@shared/constant';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';

interface IProps<T> {
  initialFilters: T;
}

export function useAdvancesFilters<T extends { status?: EAdvanceStatus[] }>({ initialFilters }: IProps<T>) {
  const currentUrlBase = useCurrentUrlBase();
  const [filters, setFilters] = useState(initialFilters);

  function onFilterClick(key: string, data: unknown) {
    switch (key) {
      case 'status':
        onSelectedStatus(data as EAdvanceStatus);
        break;

      case 'createdDate':
        const createdDate = data as DateRange;
        setFilters({ ...filters, startDate: createdDate.from, endDate: createdDate.to });
        break;

      default:
        setFilters({ ...filters, [key]: data });
        break;
    }
  }

  function onClearFilters(resetInitialFilters: boolean = false) {
    setFilters(resetInitialFilters ? initialFilters : null);
  }

  function onSelectedStatus(status: EAdvanceStatus | EAdvanceStatus[]) {
    if (Array.isArray(status)) {
      status.length === 0 ? onSelectAllStatus() : setFilters({ ...filters, status });
      return;
    }

    const currentStatus = [...filters?.status];
    const statusIndex = currentStatus.findIndex(_currentStatus => _currentStatus === status);

    if (statusIndex !== -1) {
      currentStatus.splice(statusIndex, 1);
    } else {
      currentStatus.push(status as EAdvanceStatus);
    }

    currentStatus.length === 0 ? onSelectAllStatus() : setFilters({ ...filters, status: currentStatus });
  }

  function onApplyAllFilters(data: T) {
    setFilters(data);
  }

  function onSelectAllStatus() {
    setFilters({
      ...filters,
      status: [currentUrlBase() === expensesApprovalsBaseURL ? EAdvanceStatus.SUBMITTED : EAdvanceStatus.APPROVED],
    });
  }

  return {
    filters: { ...filters, page: 1 },
    onFilterClick,
    onClearFilters,
    onApplyAllFilters,
  };
}
