import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface ITextProps {
  isDisabled?: boolean;
}

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
`;

export const ContentRadio = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled(Typography)<ITextProps>`
  color: ${({ theme, isDisabled }) => theme.colors.neutral[!isDisabled ? 40 : 60]};
  font-weight: 600 !important;
  white-space: nowrap;
`;
