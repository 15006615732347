import { useTranslation } from '@locale/Translator';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useIntegrations } from '@shared/hooks/useIntegrations';

import * as SC from './styled';

interface ConfirmModalReportRepayProps {
  isOpen: boolean;
  isLoading?: boolean;
  reportsNumber: number;
  onActionClick(): void;
  onCloseClick(): void;
}

export const ConfirmModalReportRepay = ({
  isOpen,
  isLoading,
  reportsNumber,
  onActionClick,
  onCloseClick,
}: ConfirmModalReportRepayProps) => {
  const { t } = useTranslation();
  const formOfDescription = reportsNumber > 1 ? 'pluralForm' : 'singularForm';
  const { hasFinancialIntegration } = useIntegrations();

  return (
    <SC.Container>
      <DefaultModal
        open={isOpen}
        isLoading={isLoading}
        onActionClick={onActionClick}
        onCancelClick={onCloseClick}
        onCloseClick={onCloseClick}
        headerTextAlign={'center'}
        headerTitle={
          <SC.TitleModalConfirmContainer>
            {t(
              `organisms.confirmModalReportRepay.${
                hasFinancialIntegration ? 'integration' : 'refund'
              }.${formOfDescription}.popupTitle`,
              {
                reportsSelected: reportsNumber,
              },
            )}
          </SC.TitleModalConfirmContainer>
        }
        headerSubtitle={
          <SC.SubtitleModalConfirmContainer>
            {t(`organisms.confirmModalReportRepay.${formOfDescription}.popupSubtitle`)}
          </SC.SubtitleModalConfirmContainer>
        }
        headerIconLabel={t('organisms.confirmModalReportRepay.attention')}
        labelButtonAction={t('organisms.confirmModalReportRepay.conclude')}
        headerIcon="IconThumbUp"
      />
    </SC.Container>
  );
};
