import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const DescriptionTable = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600 !important;
  color: ${props => props.theme.colors.neutral.dark.dark3};
`;

export const ReportDescription = styled.div`
  padding: ${props => props.theme.spacings.xs4};
  padding-left: ${props => props.theme.spacings.m};
  margin-bottom: ${props => props.theme.spacings.s};
`;

export const ReportDescriptionTitleContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light.light2};
  padding: ${props => props.theme.spacings.xs4};
  padding-left: ${props => props.theme.spacings.xs};
  margin-top: ${props => props.theme.spacings.s};
`;

export const TitleTable = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 700 !important;
  color: ${props => props.theme.colors.neutral.dark.dark3};
`;
