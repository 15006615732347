import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getReportHistoryById } from '../context/queries/report';
import { IGetReportHistoryByIdResponse, IReportHistory } from '../context/types/report';

export interface IUseReportProps {
  reportId: number;
  page: number;
}

interface IUseReportHistoryById {
  reportHistory: IReportHistory[];
  reportHistoryIsLoading: boolean;
  reportHistoryError: unknown;
  reportHistoryCurrentPage: number;
  reportHistoryTotal: number;
}

export const useReportHistoryById = ({ reportId, page }: IUseReportProps): IUseReportHistoryById => {
  const {
    loading,
    data,
    error: reportHistoryError,
    networkStatus,
  } = useQuery<IGetReportHistoryByIdResponse, IUseReportProps>(getReportHistoryById, {
    variables: {
      reportId,
      page,
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    reportHistory: data?.getReportHistoryById.history || [],
    reportHistoryIsLoading: loading || networkStatus === NetworkStatus.loading,
    reportHistoryError,
    reportHistoryCurrentPage: data?.getReportHistoryById.currentPage,
    reportHistoryTotal: data?.getReportHistoryById.total,
  };
};
