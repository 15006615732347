import { refreshAccessToken } from '@flash-tecnologia/hros-web-utility';
import { dispatchLogout } from '@shared/dispatchLogout';
import { showToast } from '@shared/helpers/toast';

export class Authorization {
  static redirectionPlatformHome(msg?: string) {
    this.sessionExpiredMessage(msg);
    window.location.assign('/home');
  }

  static sessionExpiredMessage(msg?: string) {
    showToast({
      message: msg || 'Sessão expirada.',
      type: 'error',
    });
  }

  static platformExpeller(msg?: string) {
    const logout = () =>
      dispatchLogout({ path: `/authentication/login?redirectAfterLogin=${window.location.pathname}` });
    this.sessionExpiredMessage(msg);
    logout();
  }

  static async getAccessToken() {
    const authorization: string = await refreshAccessToken();
    !authorization && this.redirectionPlatformHome();
    return authorization;
  }
}
