import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { external } from '@flash-hros/corporate-card';

export default function CorporateCardCustomizationsTab() {
  const tab = external.CustomizationsTab();
  return {
    ...tab,
    component: (
      <ModuleErrorBoundary
        moduleName="Cartão Corporativo"
        title="Erro ao tentar acessar as Preferências do cartão corporativo">
        {tab.component}
      </ModuleErrorBoundary>
    ),
  };
}
