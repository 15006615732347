import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  display: 'grid',
  gap: theme.spacings.xs,
}));

export const DistanceRoutesContainer = styled.div(() => ({
  paddingTop: 0,
}));

export const SkeletonContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gap: theme.spacings.xs,
  gridTemplateColumns: '1fr 1fr',
}));
