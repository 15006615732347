import { useEffect, useState } from 'react';
import { useSignContract } from '@containers/Subscription/hooks/useSignContract';
import { useContractInformationV2 } from '@containers/Subscription/hooks/v2/useContractInformationV2';

export function useContracts() {
  const { signExpenseContracts, isLoading: isSigning } = useSignContract();

  const [shouldCreateContract, setShouldCreateContract] = useState(false);
  const [isFullPageLoaderEnabled, setIsFullPageLoaderEnabled] = useState(true);

  const contractV2 = useContractInformationV2({
    allowCreate: shouldCreateContract,
  });

  const { corporateCardContract, saasServiceContract, travelContract, isLoading } = contractV2;

  function onContractCreationRequest() {
    if (contractV2.isPending) {
      setShouldCreateContract(true);
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setIsFullPageLoaderEnabled(false);
    }
  }, [isLoading]);

  return {
    contracts: contractV2.contracts,
    signExpenseContracts,
    isSigning,
    saasServiceContract,
    corporateCardContract,
    travelContract,
    onContractCreationRequest,
    isCreatingContracts: contractV2.isPending,
    isLoading: isLoading,
    isFullPageLoaderEnabled,
  };
}
