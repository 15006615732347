import gql from 'graphql-tag';

export const getFileUploadSignedUrl = gql`
  query GetSignedFileUrl($path: String!) {
    getFileUploadSignedUrl(path: $path) {
      path
      url
      contentType
    }
  }
`;

export const getMultipleFileUploadSignerUrls = gql`
  query GetSignedFileUrls($paths: [String!]!, $base64: Boolean) {
    getFilesUploadSignedUrls(paths: $paths, base64: $base64) {
      path
      url
      contentType
      base64
    }
  }
`;

export const getUploadUrl = gql`
  query GetUploadUrl($contentType: String!) {
    getUploadUrl(contentType: $contentType) {
      url
      path
      expires
    }
  }
`;
