export interface SelectOption {
  label: string;
  value: string;
  checked?: boolean;
}

interface CurrentOption {
  symbol: string;
  code: string;
  active?: boolean;
}

export interface Option {
  id?: number | string;
  name?: string;
  description?: string;
  active?: boolean;
}

export interface UseSerializeSelectOptionsProps {
  options: Option[] | string[] | number[] | CurrentOption[];
  empty?: SelectOption[];
  emptyStringLabel?: string;
}

export function serializeSelectOptions({
  options,
  empty,
  emptyStringLabel,
}: UseSerializeSelectOptionsProps): SelectOption[] {
  function getLabel(option) {
    if (option.symbol || option.code) return `${option.symbol} (${option.code})`;
    if (option.name) return option.name;
    if (option.description) return option.description;
    if (option.name === '' || option.description === '') return emptyStringLabel || '';

    return String(option);
  }

  function getValue(option) {
    if (option.code) return option.code;
    if (option.id) return option.id;

    return option;
  }

  if (options?.length)
    return options?.map(option => ({
      label: getLabel(option),
      value: getValue(option),
    }));

  return empty;
}
