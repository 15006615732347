import { useTranslation } from '@locale/Translator';
import { PageHeader } from '@organisms/BasePage/PageHeader/PageHeader';
import { BulkImportDetails } from '@organisms/BulkImportDetails';
import { ClientFooterPage } from '@pages/BulkImportClients/ClientFooterPage/ClientFooterPage';
import { ClientRegisterFileHeader } from '@pages/BulkImportClients/ClientRegisterFileHeader';
import { clientsBaseURL } from '@shared/constant';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

export const BulkImportClientsDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm();

  return (
    <>
      <ClientRegisterFileHeader />
      <FormProvider {...methods}>
        <SC.Container>
          <PageHeader title={t('organisms.BulkImportDetails.header.clients')} />
          <BulkImportDetails newImportAction={() => navigate(`${clientsBaseURL}/register/file`)} page="clients" />
        </SC.Container>
        <ClientFooterPage action={() => navigate(clientsBaseURL)} />
      </FormProvider>
    </>
  );
};
