import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const IconPlus = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[100]};
`;
