import { useEffect, useState } from 'react';
import { ApprovalType } from '@containers/Customizations/context/types/approvals';
import { useExpenseApprovalSettings } from '@containers/Customizations/hooks/settings/approval/useApprovalSettings';
import { useExpenseApprovalSettingsMutate } from '@containers/Customizations/hooks/settings/approval/useApprovalSettingsMutate';
import { Button, Radio, Skeleton, Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { approvalFlowsBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ApprovalFluxCard } from './styles';

export const ApprovalFluxPreview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPlatformPermission } = usePlatformPermissions();
  const allowedActions: boolean = checkPlatformPermission(EAuthZPermission.CUSTOMIZATION_APPROVAL_ACTION);
  const { settings, isLoading } = useExpenseApprovalSettings();
  const { updateApprovalSetting } = useExpenseApprovalSettingsMutate();
  const [approvalType, setApprovalType] = useState<ApprovalType>();

  async function handleUpdateType(newType: ApprovalType) {
    const oldState = approvalType;

    try {
      setApprovalType(newType);
      await updateApprovalSetting('approvalType', newType);
    } catch (err) {
      showToast({
        type: 'error',
        message: t('organisms.approvalsFluxPreview.feedbacks.errorWhileUpdatingType'),
      });

      setApprovalType(oldState);
    }
  }

  useEffect(() => {
    if (settings?.approvalType) {
      setApprovalType(settings.approvalType);
    }
  }, [settings]);

  return (
    <Box width={{ sm: '100%', lg: '70%' }}>
      {isLoading ? (
        <Skeleton height={200} width="100%" />
      ) : (
        <>
          <PermissionsChecker licenseFeature="INTERMEDIATE_APPROVALS">
            <ApprovalFluxCard
              title={t('organisms.approvalsFluxPreview.approvalModes.manual.title')}
              description={t('organisms.approvalsFluxPreview.approvalModes.manual.description')}
              leftAdornment={
                <Tooltip
                  arrow
                  title={
                    !allowedActions ? t('organisms.approvalsFluxPreview.approvalModes.tooltip.actionNotAllowed') : ''
                  }
                  placement="top-start">
                  <div>
                    <Radio size="medium" checked={approvalType === ApprovalType.MANUAL_CHOICE} />
                  </div>
                </Tooltip>
              }
              onClick={() => allowedActions && handleUpdateType(ApprovalType.MANUAL_CHOICE)}
            />
          </PermissionsChecker>

          <PermissionsChecker licenseFeature="BASIC_APPROVALS">
            <ApprovalFluxCard
              title={t('organisms.approvalsFluxPreview.approvalModes.automatic.title')}
              description={t('organisms.approvalsFluxPreview.approvalModes.automatic.description')}
              leftAdornment={
                <Tooltip
                  arrow
                  title={
                    !allowedActions ? t('organisms.approvalsFluxPreview.approvalModes.tooltip.actionNotAllowed') : ''
                  }
                  placement="top-start">
                  <div>
                    <Radio size="medium" checked={approvalType === ApprovalType.AUTOMATIC_APPROVAL} />
                  </div>
                </Tooltip>
              }
              onClick={() => allowedActions && handleUpdateType(ApprovalType.AUTOMATIC_APPROVAL)}
            />
          </PermissionsChecker>

          <PermissionsChecker licenseFeature="APPROVALS_ADVANCED">
            <ApprovalFluxCard
              title={t('organisms.approvalsFluxPreview.approvalModes.custom.title')}
              description={
                <>
                  <Typography variant="body4">
                    {t('organisms.approvalsFluxPreview.approvalModes.custom.description')}
                  </Typography>

                  <div hidden={approvalType !== ApprovalType.CUSTOMIZED_APPROVAL}>
                    <Button
                      size="small"
                      variant="secondary"
                      sx={{ mt: 2, mb: 2 }}
                      onClick={e => {
                        e.stopPropagation();
                        navigate(`${approvalFlowsBaseURL}/reports`);
                      }}>
                      {t('organisms.approvalsFluxPreview.approvalModes.custom.actions.reportApproval')}
                    </Button>

                    <PermissionsChecker licenseFeature="ADVANCED_ADVANCE">
                      <Button
                        size="small"
                        variant="secondary"
                        onClick={e => {
                          e.stopPropagation();
                          navigate(`${approvalFlowsBaseURL}/advances`);
                        }}>
                        {t('organisms.approvalsFluxPreview.approvalModes.custom.actions.advanceApproval')}
                      </Button>
                    </PermissionsChecker>
                  </div>
                </>
              }
              onClick={() => allowedActions && handleUpdateType(ApprovalType.CUSTOMIZED_APPROVAL)}
              leftAdornment={<Radio size="medium" checked={approvalType === ApprovalType.CUSTOMIZED_APPROVAL} />}
            />
          </PermissionsChecker>
        </>
      )}
    </Box>
  );
};
