import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';
import { cloneDeep } from 'lodash';

import { getEstablishments } from '../context/queries/establishment';
import {
  IEstablishmentResponse,
  IGetEstablishmentRequest,
  IGetEstablishmentResponse,
} from '../context/types/establishments';

interface UseEstablishments extends IEstablishmentResponse {
  isLoading: boolean;
  error: unknown;
}

export const useEstablishments = ({ limit, page }: IGetEstablishmentRequest): UseEstablishments => {
  const { loading, data, error } = useQuery<IGetEstablishmentResponse, IGetEstablishmentRequest>(getEstablishments, {
    variables: {
      limit,
      page,
    },
  });

  const response = cloneDeep(data?.getEstablishments);

  const establishments = response?.establishments?.map(_establishment => {
    _establishment.__typename && delete _establishment.__typename;
    return _establishment;
  });

  return {
    establishments: establishments || [],
    isLoading: loading,
    error,
    limit: response?.limit,
    pages: response?.pages,
    total: response?.total,
    currentPage: response?.currentPage,
  };
};
