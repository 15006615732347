import { useTranslation } from '@locale/Translator';
import { IRowsPerPageOption } from '@molecules/GridPagination';

interface UseRowsPerPageOptions {
  maxRows?: number;
  intervalItems?: number;
}

export const useRowsPerPageOptions = ({ maxRows = 20, intervalItems = 5 }: UseRowsPerPageOptions) => {
  const { t } = useTranslation();
  const rowsPerPageOptions: IRowsPerPageOption[] = [];

  for (let value = intervalItems; value <= maxRows; value = value + intervalItems) {
    rowsPerPageOptions.push({ label: `${value} ${t('molecules.gridPagination.items')}`, value: value });
  }

  return {
    rowsPerPageOptions,
  };
};
