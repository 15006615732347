import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const DescriptionContainer = styled.div`
  width: 300px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 980px;
`;

export const ContentTitle = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  align-items: center;
`;
