import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import SubsidiaryRegisterBreadcrumb from '../SubsidiaryRegisterBreadcrumb/SubsidiaryRegisterBreadcrumb';
import * as SC from './styled';

interface SubsidiaryRegisterProps {
  step: number;
  isEditing: boolean;
}

export const SubsidiaryRegisterHeader = ({ isEditing, step }: SubsidiaryRegisterProps) => {
  const t = useTranslate('pages.costCenterRegister');

  return (
    <SC.Header>
      <SubsidiaryRegisterBreadcrumb isEditing={isEditing} />
      <Stepper activeStep={step} steps={[t('steps.header.generalData'), t('steps.header.users')]} />
    </SC.Header>
  );
};
