import { IconTypes, Loader, Menu, MenuOptionType } from '@flash-tecnologia/hros-web-ui-v2';
import { DropdownItem } from '@molecules/DropdownItem';

import * as SC from './styled';

interface IProps {
  actions: IActionsRow[];
  referenceId: string | number;
  isLoading?: boolean;
  disabled?: boolean;
}

export interface IActionsRow {
  id: string | number;
  key: string;
  name: string;
  iconName?: IconTypes;
  disabled?: boolean;
  allowed: boolean;
  onClick(keyAction: IActionsRow['key'], id: IProps['referenceId']): void;
}

export function ActionsRow({ actions, referenceId, isLoading, disabled }: IProps) {
  function getAction(): MenuOptionType[] {
    const _actions: MenuOptionType[] = [];

    actions &&
      actions?.forEach(_action => {
        if (_action.allowed)
          _actions.push({
            children: (
              <DropdownItem
                icon={_action.iconName ?? null}
                description={_action.name ?? ''}
                disabled={_action.disabled}
              />
            ),
            onClick: () => _action?.onClick(_action.key, referenceId),
          });
      });

    return _actions;
  }
  return (
    <Menu
      type="default"
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      options={getAction()}
      disabled={disabled}>
      {isLoading ? (
        <SC.ContainerLoading>
          <Loader size="extraSmall" variant="primary" />
        </SC.ContainerLoading>
      ) : (
        <SC.IconDots
          style={{ opacity: disabled ? 0.5 : 1 }}
          loading
          icon="IconDots"
          size="small"
          variant={disabled ? 'filled' : 'line'}
        />
      )}
    </Menu>
  );
}
