import {
  IConfigurationPaymentMethod,
  IConfigurationPaymentMethodMutation,
} from '@containers/Customizations/context/types/paymentMethodTypes';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function useCreatePaymentMethod() {
  const t = useTranslate('organisms.customizationsPaymentMethods.modal.paymentMethod.feedbacks.add');
  const { mutation } = useMutate('v1/payment-methods', () =>
    showToast({
      message: t('error'),
      type: 'error',
    }),
  );

  async function getCreatePaymentMethod(currentPaymentMethod: IConfigurationPaymentMethod) {
    const body = {
      paymentMethod: {
        externalCode: currentPaymentMethod.externalCode,
        name: currentPaymentMethod.name,
        active: currentPaymentMethod.active,
        type: currentPaymentMethod.type,
        mileage: currentPaymentMethod.mileage,
        refundable: currentPaymentMethod.refundable,
      },
    } as IConfigurationPaymentMethodMutation;

    const response = await mutation.trigger(
      {
        path: '',
        options: {
          method: 'POST',
          body: JSON.stringify(body.paymentMethod),
        },
      },
      {
        onSuccess: () =>
          showToast({
            message: t('success'),
            type: 'success',
          }),
      },
    );

    const success: boolean = response;

    return success;
  }

  return {
    getCreatePaymentMethod,
    success: mutation.error == undefined,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
