import { ErrorBoundary } from '@atoms/ErrorBoundary';

interface IRouterErrorBoundaryProps {
  moduleName?: string;
  title?: string;
}

export function RouterErrorBoundary({ moduleName = 'Base', title }: IRouterErrorBoundaryProps) {
  return <ErrorBoundary moduleName={`Despesas Corporativa / ${moduleName}`} title={title} />;
}
