import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { DelegationAction } from '@pages/DelegationAction/DelegationAction';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function DelegationRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['INTERMEDIATE_APPLICANT_PROFILE']}
          routePermissions={[
            EAuthZPermission.CONFIGURATION_DELEGATION,
            EAuthZPermission.CONFIGURATION_DELEGATION_APPROVAL,
          ]}
        />
      ),
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Delegação" moduleName="Delegação" />,
      children: [
        {
          path: Router.getDelegation(),
          element: <DelegationAction />,
        },
      ],
    },
  ];
}
