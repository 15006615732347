import { IReportsSummary } from '@containers/Expenses/context/types/reports';

import { reportListRowPrintItems } from '../../../../../shared/constant';
import { ReportCard } from '../ReportCard';
import { ReportsListRowEmpty } from './ReportsListRowEmpty';
import { ReportsListRowSkeleton } from './ReportsListRowSkeleton';
import * as SC from './styled';

export interface ReportsListRowProps {
  reports: IReportsSummary[];
  isLoading: boolean;
}

export const ReportsListRow = ({ reports, isLoading }: ReportsListRowProps) => {
  const reportLimited = reports.slice(0, reportListRowPrintItems);

  if (reports?.length == 0 && !isLoading) {
    return <ReportsListRowEmpty />;
  }

  return (
    <SC.Container>
      {isLoading ? (
        <ReportsListRowSkeleton />
      ) : (
        reportLimited.map((report, index) => (
          <SC.ReportCardContainer key={index}>
            <ReportCard {...report} />
          </SC.ReportCardContainer>
        ))
      )}
    </SC.Container>
  );
};
