import * as SC from './styled';

export interface MessageOptionsProps {
  title?: string;
  caption?: string;
  image?: React.ReactNode;
}

export const AutocompleteMessageOptions = ({ title, caption, image }: MessageOptionsProps) => {
  return (
    <SC.Container>
      {image}
      {title && <SC.Title variant="body3">{title}</SC.Title>}
      {caption && <SC.Caption variant="caption">{caption}</SC.Caption>}
    </SC.Container>
  );
};
