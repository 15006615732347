import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { SectionContainer } from '../../../../../../components/organisms/SectionContainer/SectionContainer';
import { Container } from '../styled';
import { AddPaymentMethod } from './AddPaymentMethod/AddPaymentMethod';
import { PaymentMethodsGrid } from './PaymentMethodsGrid/PaymentMethodsGrid';

export function PaymentMethods() {
  const t = useTranslate('organisms.customizationsPaymentMethods');

  return (
    <SectionContainer title={t('title')} caption={t('caption')}>
      <Container>
        <AddPaymentMethod />
        <PaymentMethodsGrid />
      </Container>
    </SectionContainer>
  );
}
