import { useState } from 'react';
import { Switch } from '@atoms/Switch';
import { IConfigurationCategory } from '@containers/Customizations/context/types/categoryTypes';
import { useAllCategories } from '@containers/Customizations/hooks/useCategories';
import { Button, Icons, Loader } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { CategoryFormModal } from './CategoryFormModal';
import { CategoryListSwitchCard } from './CategoryListSwitchCard';
import { useCategoriesActions } from './hooks/useConfigurationCategoriesActions';
import * as SC from './styled';

export interface CategoriesPreviewProps {
  title?: string;
  description?: string;
  categories?: IConfigurationCategory[];
  isLoading?: boolean;
  onOpen?(): void;
  onOpenCategory?(category: IConfigurationCategory): void;
}

export const CategoriesPreview = ({ title, description, onOpen }: CategoriesPreviewProps) => {
  const { t } = useTranslation();
  const { categories, isLoadingCategories } = useAllCategories();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [categoryForExclusion, setCategoryForExclusion] = useState<IConfigurationCategory>(null);
  const { checkPlatformPermission } = usePlatformPermissions();
  const {
    category,
    isDisableAll,
    isLoadingMutate,
    isLoadingAllCategories,
    isOpenMutateModal,
    handleEnableDisabledAll,
    onMutateModalShow,
    onDeleteCategory,
    handleActiveCategory,
    handleOpenCategory,
    handleCancel,
    handleCategory,
    handleSubmitCategory,
  } = useCategoriesActions(categories);

  return (
    <SC.Container>
      <SectionContainer
        title={title || t('organisms.categoriesPreview.title')}
        caption={description || t('organisms.categoriesPreview.description')}
        showDivider>
        <PermissionsChecker permission={EAuthZPermission.CUSTOMIZATION_CATEGORIES_ACTION}>
          <SC.ActionsContainer>
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                onOpen ? onOpen() : onMutateModalShow();
              }}>
              <Icons name="IconPlus" fill="transparent" /> {t('organisms.categoriesPreview.createCategory')}
            </Button>
            <SC.FormControlLabel
              id="input-is-disabled-all"
              control={
                isLoadingAllCategories || isLoadingCategories ? (
                  <SC.LoaderContainer>
                    <Loader size="extraSmall" variant="primary" />
                  </SC.LoaderContainer>
                ) : (
                  <Switch />
                )
              }
              label={t(`organisms.categoriesPreview.${isDisableAll ? 'disableAll' : 'enableAll'}`)}
              onChange={handleEnableDisabledAll}
              checked={isDisableAll}
            />
          </SC.ActionsContainer>
        </PermissionsChecker>

        <CategoryListSwitchCard
          allowedActions={checkPlatformPermission(EAuthZPermission.CUSTOMIZATION_CATEGORIES_ACTION)}
          categories={categories}
          currentCategory={category}
          isLoading={isLoadingCategories && categories?.length === 0}
          isLoadingMutate={isLoadingMutate}
          isLoadingAll={isLoadingAllCategories || isLoadingCategories}
          deleteCategory={_category => {
            setCategoryForExclusion(_category);
            setIsDeleteModalOpen(true);
          }}
          handleActiveCategory={handleActiveCategory}
          handleOpenCategory={_category => {
            handleOpenCategory(_category);
          }}
        />

        <CategoryFormModal
          isOpen={isOpenMutateModal}
          isLoading={isLoadingMutate || isLoadingAllCategories}
          category={category}
          handleCancel={handleCancel}
          handleSubmitCategory={handleSubmitCategory}
          handleCategory={handleCategory}
        />
        <DangerActionModal
          open={isDeleteModalOpen}
          headerIconLabel={t('organisms.categoriesPreview.deleteModal.headerIconLabel')}
          headerTitle={t('organisms.categoriesPreview.deleteModal.headerTitle')}
          headerSubtitle={t('organisms.categoriesPreview.deleteModal.headerSubtitle')}
          isLoading={isLoadingMutate || isLoadingAllCategories || isLoadingCategories}
          onActionClick={() => {
            onDeleteCategory(categoryForExclusion);
            setIsDeleteModalOpen(false);
          }}
          onCloseClick={() => setIsDeleteModalOpen(false)}
          labelButtonAction={
            <>
              {t('organisms.categoriesPreview.deleteModal.confirmDeletion')}{' '}
              <Icons name="IconTrash" size={24} fill="transparent" />
            </>
          }
        />
      </SectionContainer>
    </SC.Container>
  );
};
