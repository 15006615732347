import { EExpenseType } from '@containers/Expenses/context/types';

type Props = {
  isEdit?: boolean;
  expenseType: EExpenseType;
  isOdometerAllowed?: boolean;
  isOriginDestinationAllowed?: boolean;
  isLoadingPolicies?: boolean;
};

/**
 * Checks if user has permission to access mileage register page
 * or he must be redirected to the manual register page
 */
export function useMileageRegisterPermissionChecker({
  isEdit,
  isLoadingPolicies,
  isOdometerAllowed,
  isOriginDestinationAllowed,
  expenseType,
}: Props) {
  const checkUserHasPermission = () => {
    if (isLoadingPolicies) return null;

    if (isEdit || expenseType === EExpenseType.GPS) return true;

    return isOdometerAllowed || isOriginDestinationAllowed;
  };

  return {
    userHasPermission: checkUserHasPermission(),
  };
}
