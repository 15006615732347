import React from 'react';
import { Skeleton } from '@mui/material';

import { ActionsFeedbackContainer, TdContent } from '../components/styled';
import { EAccessor } from './useReportGridHeader';

export interface UseBodyGridRowSkeletonProps {
  rowsPerPage: number;
}
export type TGridRow = Record<EAccessor, React.ReactNode>;
const skeletonText = <Skeleton variant="text" height={22} width={90} />;

export const useBodyGridRowSkeleton = ({ rowsPerPage = 20 }: UseBodyGridRowSkeletonProps): TGridRow[] => {
  return new Array(rowsPerPage).fill({}).map(() => {
    return {
      selection: <Skeleton variant="rounded" height={20} width={20} />,
      name: (
        <TdContent>
          <Skeleton variant="circular" height={40} width={40} />
          {skeletonText}
          <ActionsFeedbackContainer>
            <Skeleton variant="text" height={20} width={100} />
          </ActionsFeedbackContainer>
        </TdContent>
      ),
      id: skeletonText,
      activePeriod: skeletonText,
      value: skeletonText,
      status: <Skeleton variant="text" height={22} width={150} />,
      actions: <Skeleton variant="circular" height={40} width={40} />,
    };
  });
};
