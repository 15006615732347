import styled from 'styled-components';

export const DiffColumnContainer = styled.div`
  min-width: 200px;
`;

export const DiffText = styled.div<{ type: 'previous' | 'current' }>`
  background-color: ${({ theme, type }) =>
    type === 'previous' ? theme.colors.feedback.error[90] : theme.colors.feedback.success[90]};
`;
