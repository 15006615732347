import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getReportStatusSummary } from '../context/queries/reports';
import { IGetReportStatusSummaryResponse, ReportStatusSummary } from '../context/types/reports';

interface useReports {
  statusSummary: ReportStatusSummary;
  error: unknown;
  isLoading: boolean;
}

export function useReportStatusSummary(): useReports {
  const { loading, data, error, networkStatus } = useQuery<IGetReportStatusSummaryResponse>(getReportStatusSummary, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  return {
    statusSummary: data?.getSummaryForFinance?.reports,
    error,
    isLoading: loading || networkStatus === NetworkStatus.loading,
  };
}
