import { ActionsButton } from '@molecules/ActionsButton/ActionsButton';
import { projectsBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

export function AddProjectsButton() {
  const t = useTranslate('projects.addProjects');
  const navigate = useNavigate();

  function onImportClick(key: string) {
    if (key === 'addProjectManually') {
      navigate(`${projectsBaseURL}/register`);
    } else {
      navigate(`${projectsBaseURL}/register/file`);
    }
  }

  return (
    <ActionsButton
      actionName={t('buttonLabel')}
      actionIcon="IconPlus"
      actionsList={[
        {
          key: 'addProjectManually',
          name: t('options.addProjectManually'),
          onClick: onImportClick,
          icon: 'IconFile',
        },
        {
          key: 'addProjectByFile',
          name: t('options.addProjectByFile'),
          onClick: onImportClick,
          icon: 'IconFileImport',
        },
      ]}
    />
  );
}
