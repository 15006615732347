import { useContext } from 'react';
import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { Customizations } from '@pages/Customizations';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function CustomizationsRoutes(): RouteObject[] {
  const { isDisabledExpense } = useContext(SessionContext);

  if (isDisabledExpense) {
    return [
      {
        element: <PermissionsGuard routePermissions={[EAuthZPermission.CUSTOMIZATIONS_VIEW]} />,
        errorElement: (
          <RouterErrorBoundary title="Erro ao carregar o módulo de Configurações" moduleName="Configurações" />
        ),
        children: [
          {
            path: Router.getCustomizations(),
            element: <Customizations />,
          },
        ],
      },
    ];
  }
  return [
    {
      element: (
        <PermissionsGuard routeFeatures={['BASIC_EXPENSE']} routePermissions={[EAuthZPermission.CUSTOMIZATIONS_VIEW]} />
      ),
      errorElement: (
        <RouterErrorBoundary title="Erro ao carregar o módulo de Configurações" moduleName="Configurações" />
      ),
      children: [
        {
          path: Router.getCustomizations(),
          element: <Customizations />,
        },
      ],
    },
  ];
}
