import { useMemo } from 'react';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { useLicenseFeatures } from '../licenseFeatures/useLicenseFeatures';
import { usePlatformPermissions } from './usePlatformPermissions';

interface IDelegationPermissions {
  settingsApproval: boolean;
  settingsExpenses: boolean;
  view: boolean;
}

export function useDelegatePermissions() {
  const { checkPlatformPermission, permissions } = usePlatformPermissions();
  const { checkLicenseFeature, features } = useLicenseFeatures();
  const hasFeatureDelegate = checkLicenseFeature('INTERMEDIATE_APPLICANT_PROFILE');
  const allowed: IDelegationPermissions = useMemo(
    () => ({
      view: hasFeatureDelegate,
      settingsApproval:
        hasFeatureDelegate && checkPlatformPermission(EAuthZPermission.CONFIGURATION_DELEGATION_APPROVAL),
      settingsExpenses: hasFeatureDelegate && checkPlatformPermission(EAuthZPermission.CONFIGURATION_DELEGATION),
    }),
    [permissions?.toString(), features?.toString(), hasFeatureDelegate],
  );

  return allowed;
}
