import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { ReceiptFile } from '@containers/Expenses/context/types';
import { Button, Dropzone, IconButton, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { DropzoneFile } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types';
import { useTranslation } from '@locale/Translator';
import { ImagesModal } from '@molecules/ImagesModal';
import { bufferToBase64 } from '@shared/helpers/files';

import { ReceiptMainImage } from './ReceiptMainImage';
import { ReceiptsImagesSkeleton } from './ReceiptsImagesSkeleton';
import * as SC from './styled';
import { ESizeVariant } from './variants';

export interface ReceiptsImagesProps {
  isLoading?: boolean;
  onChange?(receipts: ReceiptFile[]): void;
  receiptsImages?: ReceiptFile[];
  previewOnly?: boolean;
  size?: ESizeVariant;
  isDisabled?: boolean;
}

export const ReceiptsImages = ({
  isLoading,
  onChange,
  receiptsImages,
  previewOnly = false,
  size,
  isDisabled,
}: ReceiptsImagesProps) => {
  const { t } = useTranslation();
  const [receipts, setReceipts] = useState(receiptsImages || []);
  const [currentReceiptIndex, setCurrentReceiptIndex] = useState(0);
  const hasPrevImage = currentReceiptIndex > 0 && receipts?.length > 1;
  const hasNextImage = currentReceiptIndex < receipts?.length - 1;
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (receiptsImages) {
      setReceipts(receiptsImages);
    }
  }, [receiptsImages]);

  function onDropzoneChange(selectedReceipts: DropzoneFile[]) {
    const amountReceipts: number = selectedReceipts?.length;
    const index: number = amountReceipts ? amountReceipts - 1 : amountReceipts;

    const receipts: ReceiptFile[] = selectedReceipts.map(receipt => {
      const { data, file } = receipt;

      const previewUrl = URL.createObjectURL(file);

      return {
        base64: data instanceof ArrayBuffer ? bufferToBase64(data, file.type) : data,
        contentType: file.type,
        file,
        previewUrl,
      };
    });

    setReceipts(receipts);
    onChange?.(receipts);
    setCurrentReceiptIndex(index);
  }

  function removeCurrentReceipt() {
    const newReceipts = receipts.filter((_, index) => index !== currentReceiptIndex);
    setReceipts(newReceipts);

    setCurrentReceiptIndex(current => {
      const newIndex = current - 1;
      return newIndex < 0 ? 0 : newIndex;
    });

    onChange(newReceipts);
  }

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = async event => {
    const file = event.target?.files[0];

    if (!file) return;

    const previewUrl = URL.createObjectURL(file);

    const newReceipts: ReceiptFile[] = [
      ...receipts,
      {
        file,
        contentType: file.type,
        previewUrl,
      },
    ];

    setReceipts(newReceipts);
    onChange(newReceipts);
    setCurrentReceiptIndex(newReceipts?.length - 1);
  };

  function handleExpand() {
    const receipt = receipts[currentReceiptIndex];

    if (!receipt) return;

    return setIsOpen(true);
  }

  const enableDeleteButton = !previewOnly;

  if (isLoading) return <ReceiptsImagesSkeleton size={size} />;

  if (previewOnly && receipts?.length === 0) {
    return null;
  }

  return (
    <SC.Container size={size} isDisabled={isDisabled}>
      <SC.CurrentReceipt size={size}></SC.CurrentReceipt>
      <SC.ReceiptsContainer size={size}>
        {receipts?.length === 0 ? (
          <SC.DropzoneContainer hasReceipts={Boolean(receipts?.length)} size={size}>
            <Dropzone
              multiple
              title={t('molecules.receiptsImages.receipt')}
              onChange={selectedReceipts => onDropzoneChange(selectedReceipts)}
              accept={['pdf', 'png', 'jpg', 'jpeg']}
            />
          </SC.DropzoneContainer>
        ) : (
          <ReceiptMainImage
            size={size}
            image={receipts?.[currentReceiptIndex || 0].previewUrl}
            onExpandClick={previewOnly ? handleExpand : undefined}
            onDeleteClick={enableDeleteButton ? removeCurrentReceipt : undefined}
            mime={receipts[currentReceiptIndex]?.contentType}
          />
        )}

        {Boolean(receipts?.length) && (
          <SC.BottomContainer>
            {receipts.length > 1 && (
              <SC.PaginationContainer>
                <IconButton
                  icon="IconChevronLeft"
                  variant={hasPrevImage ? 'line' : 'filled'}
                  size="medium"
                  disabled={!hasPrevImage}
                  onClick={() => setCurrentReceiptIndex(prevIndex => prevIndex - 1)}
                />
                <SC.DotsContainer>
                  {receipts.map((_, index) => (
                    <SC.IconDots key={index} className={currentReceiptIndex === index ? 'active' : ''} />
                  ))}
                </SC.DotsContainer>
                <IconButton
                  icon="IconChevronRight"
                  variant={hasNextImage ? 'line' : 'filled'}
                  size="medium"
                  disabled={!hasNextImage}
                  onClick={() => setCurrentReceiptIndex(nextIndex => nextIndex + 1)}
                />
              </SC.PaginationContainer>
            )}
            {!previewOnly && (
              <Button size="medium" variant="secondary" onClick={() => inputRef.current.click()}>
                {t('molecules.receiptsImages.add')}
                <SC.InputContainer>
                  <Icons fill="transparent" name="IconPlus" />
                  <SC.HiddenFileInput ref={inputRef} onChange={handleFileUpload} />
                </SC.InputContainer>
              </Button>
            )}
          </SC.BottomContainer>
        )}
      </SC.ReceiptsContainer>
      {isOpen && (
        <ImagesModal
          images={receipts.map(({ contentType, previewUrl }) => ({ src: previewUrl, mime: contentType }))}
          onCloseModalClick={() => setIsOpen(isOpen => !isOpen)}
          initialIndex={currentReceiptIndex}
        />
      )}
    </SC.Container>
  );
};
