import { useState } from 'react';
import { useClientFileRegister } from '@containers/Clients/hooks/useClientFileRegister';
import { ESizeVariant } from '@containers/Expenses/components/molecules/ReceiptsImages/variants';
import { useTranslation } from '@locale/Translator';
import { BulkImportDropzone } from '@molecules/BulkImportDropzone';
import { Loader } from '@pages/ClientRegister/Loader';
import { showToast } from '@shared/helpers/toast';
import { Controller, useFormContext } from 'react-hook-form';

import { ImportContainer } from '../../../../../components/molecules/ImportContainer';
import * as SC from './styled';

export const RegisterClientByFileForm = () => {
  const { t } = useTranslation();
  const { isLoading, download } = useClientFileRegister();
  const { control } = useFormContext();
  const [hasFile, setHasFile] = useState(false);
  const [fileName, setFileName] = useState('');

  const onDropzoneChange = async (e, onChange) => {
    if (e?.length > 0) {
      onChange(e[0]);
      setHasFile(true);
      setFileName(e[0]?.file?.name);
    } else {
      onChange(null);
      setHasFile(false);
      setFileName('');
      showToast({ message: t('organisms.registerClient.bulkImport.fileModel.warning'), type: 'warning' });
    }
  };

  return (
    <SC.Container>
      <ImportContainer
        title={t('organisms.registerClient.bulkImport.fileModel.title')}
        subTitle={t('organisms.registerClient.bulkImport.fileModel.subTitle')}>
        <SC.FileDownload fileName="Modelo-import-cliente.xlsx" onClick={download} />
      </ImportContainer>
      <ImportContainer
        title={t('organisms.registerClient.bulkImport.sendFile.title')}
        subTitle={t('organisms.registerClient.bulkImport.sendFile.subTitle')}>
        <SC.DropzoneContainer size={ESizeVariant.small} hasFile={hasFile}>
          <Controller
            control={control}
            name={'clients'}
            render={({ field }) => (
              <BulkImportDropzone
                fileName={fileName}
                hasFile={hasFile}
                onDropzoneChange={file => onDropzoneChange(file, field.onChange)}
              />
            )}
          />
        </SC.DropzoneContainer>
      </ImportContainer>
      <Loader show={isLoading} />
    </SC.Container>
  );
};
