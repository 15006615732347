import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { getCategories } from '../context/queries/categories';
import { ICategoriesListFilter, ICategoriesListFilterResponse } from '../context/types/categories';
import { IAnalyticsFilterFieldOption } from '../context/types/filters';

interface UseCategories {
  getAllCategories: LazyQueryExecFunction<ICategoriesListFilterResponse, OperationVariables>;
  serializeCategoriesOptions(allCategories: ICategoriesListFilter[]): IAnalyticsFilterFieldOption[];
  categories: IAnalyticsFilterFieldOption[];
  isLoading: boolean;
  error: unknown;
}

export const useCategoriesOptionsFilters = (): UseCategories => {
  const [getAllCategories, { loading, data, error }] = useLazyQuery<ICategoriesListFilterResponse>(getCategories);

  function serializeCategoriesOptions(allCategories: ICategoriesListFilter[]): IAnalyticsFilterFieldOption[] {
    const _categories: IAnalyticsFilterFieldOption[] = [];

    allCategories?.forEach(_category => {
      if (_category.active)
        _categories.push({
          id: String(_category.id),
          label: _category.description,
        });
    });

    return _categories;
  }

  return {
    getAllCategories,
    serializeCategoriesOptions,
    categories: serializeCategoriesOptions(data?.getAllLegacyCategories?.categories),
    isLoading: loading,
    error,
  };
};
