import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export function useClientById(clientId: string) {
  const { t } = useTranslation();

  const { data, error, isLoading } = useFetch<IConfigurationClient>(
    `v1/clients/${clientId}`,
    undefined,
    undefined,
    undefined,
    () => !!clientId && showToast({ message: t('pages.clientRegister.feedbacks.gettingOne.error'), type: 'error' }),
    !!clientId,
  );

  return {
    client: data,
    isLoading: isLoading,
    error,
  };
}
