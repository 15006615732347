import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { config } from '@shared/config';

import { segmentEvent } from '../helpers/segment';
import { ScreenEventName } from './useTrackingScreen';

export type EventName =
  | ScreenEventName
  // Add expense
  | 'expense_add_clicked'

  // Add expense
  | 'advance_add_clicked'

  // Create expense
  | 'expense_create_clicked'
  | 'expense_create_success'
  | 'expense_create_error'

  // Attach Expense
  | 'expense_attach_clicked'
  | 'expense_attach_success'
  | 'expense_attach_error'

  // Update Expense
  | 'expense_update_clicked'
  | 'expense_update_success'
  | 'expense_update_error'

  // Create Report
  | 'report_create_clicked'
  | 'report_create_success'
  | 'report_create_error'

  // Update Report
  | 'report_update_clicked'
  | 'report_update_success'
  | 'report_update_error'

  // Submit Report
  | 'report_submit_clicked'
  | 'report_submit_success'
  | 'report_submit_error'

  // Refund report
  | 'report_refund_clicked'
  | 'report_refund_success'
  | 'report_refund_error'

  // Reject report
  | 'report_reject_clicked'
  | 'report_reject_success'
  | 'report_reject_error'
  | 'report_reopen_clicked'
  | 'report_reopen_success'
  | 'report_reopen_error'

  // Approve report
  | 'report_approve_clicked'
  | 'report_approve_success'
  | 'report_approve_error';

interface User {
  employeeId: string;
  companyId: string;
}

export const useTracking = () => {
  const setUserProperties = (): User | null => {
    const user = getFromLS('hrosAccessToken');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.analytics?.identify(user?.employeeId, {
      employeeId: user?.employeeId,
      companyId: user?.companyId,
    });

    return user;
  };

  const event = (name: EventName, data?: Record<string, string>) => {
    if (!name) {
      if (config.BUILD_ENV !== 'production') {
        console.error('Invalid event name');
      }

      return;
    }

    const user = setUserProperties();

    const _data = {
      userId: user?.employeeId,
      origin: 'flash-os',
      employeeId: user?.employeeId,
      companyId: user?.companyId,
      route: window.location.pathname,
      ...data,
    };

    segmentEvent({
      track: name,
      params: _data,
      module: 'expense-management',
      businessUnit: 'flash-expense',
    });
  };

  return event;
};
