import { ChangeEvent, useEffect, useState } from 'react';
import { CheckboxListOptions } from '@atoms/CheckboxList';
import { DateRange, FilterFactory, FilterType, Variant } from '@atoms/FilterFactory';
import { IReportForFinanceFilter } from '@containers/Financial/context/types/reports';
import { Button, IconButton, LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

interface DrawerFiltersProps {
  isOpen: boolean;
  filters: IDrawerFilter[];
  onClose(): void;
  onApplyAllFilters(value: IFilterValues[]): void;
  onClearAllFilters(): void;
  selectedFilters: IReportForFinanceFilter | IFilterValues[];
}

export interface ISliderRange {
  max?: number;
  min?: number;
}

export interface IFilterDate {
  endDate?: string;
  startDate?: string;
}

type IEventUpdate = (value: IFilterValues['value'], onUpdate?: (value) => void) => void;

export interface IFilterValues {
  key: string;
  // TODO: Update this type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: string[] | string | DateRange | null | number[] | number | ISliderRange | ChangeEvent | IFilterDate | any;
}

export interface IDrawerFilter {
  label: string;
  onChange?: IEventUpdate;
  onClick?: IEventUpdate;
  type: FilterType;
  multiple?: boolean;
  options?: CheckboxListOptions[];
  selectedOptions?: string[];
  min?: number;
  max?: number;
  variant?: Variant;
  badgeNumber?: string;
  labelKeyReference?: string;
  key?: string;
  value?: string[] | DateRange | null | number[] | string;
  dateRange?: DateRange;
  onSubmit?(value: DateRange): void;
  disabled?: boolean;
}

export const FiltersDrawer = ({
  isOpen,
  filters,
  onClose,
  onApplyAllFilters,
  onClearAllFilters,
  selectedFilters,
}: DrawerFiltersProps) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<IFilterValues[]>([]);

  function cleanFilters() {
    onClearAllFilters();
    setSelectedValues([]);
  }

  function addSelectedValue(data: IFilterValues) {
    setSelectedValues(selectedValues => {
      const _selectedValues = [...selectedValues] || [];
      const index: number = _selectedValues?.findIndex(_filter => _filter.key === data.key);

      if (index !== -1) _selectedValues[index] = data;
      else _selectedValues?.push(data);

      return _selectedValues;
    });
  }

  function serializeFiltersValues(): IFilterValues[] {
    let _filtersSelected: IFilterValues[] = [];

    if (selectedFilters && !Array.isArray(selectedFilters) && Object.keys(selectedFilters)?.length)
      _filtersSelected = Object.keys(selectedFilters).map(key => {
        return {
          key,
          value: selectedFilters[key],
        };
      });
    else _filtersSelected = selectedFilters as IFilterValues[];

    return _filtersSelected;
  }

  function getIsActiveFilter(filter: IDrawerFilter): boolean {
    if (Array.isArray(filter?.value) && filter?.value?.length) return true;

    return (
      Boolean(filter.selectedOptions?.length > 0) ||
      Boolean(filter.value) ||
      Boolean(filter.dateRange?.from) ||
      Boolean(filter.dateRange)
    );
  }

  function getTitle(filter: IDrawerFilter, title: string) {
    if (
      Boolean(filter.selectedOptions?.length) &&
      filter.type !== FilterType.CURRENCY_RANGE &&
      filter.type !== FilterType.RANGE
    )
      return `${title} (${filter.selectedOptions?.length}/${filter.options?.length})`;

    return title;
  }

  useEffect(() => {
    setSelectedValues(serializeFiltersValues());
  }, [selectedFilters]);

  const renderFilters = () => {
    return (
      <SC.FiltersContainer>
        <SC.TopContainer>
          <Typography variant="headline7">{t('molecules.moreFilters.title')}</Typography>
          <IconButton size="small" variant="line" icon="IconX" onClick={onClose} />
        </SC.TopContainer>
        <SC.AccordionsContainer>
          {filters?.map((filter, index) => (
            <SC.AccordionComponent
              variant="default"
              key={filter?.key || index}
              title={getTitle(filter, filter.label)}
              active={getIsActiveFilter(filter)}>
              <FilterFactory
                key={filter?.key || String(index)}
                {...filter}
                type={filter.type}
                multiple={filter?.multiple}
                label={filter.label}
                onClick={value => addSelectedValue({ key: filter?.key, value })}
                options={filter.options}
                selectedOptions={filter.selectedOptions}
                onChange={value => addSelectedValue({ key: filter?.key, value })}
                value={filter.value}
                max={filter.max}
                min={filter.min}
                dateRange={filter.dateRange}
                onSubmit={value => addSelectedValue({ key: filter?.key, value })}
              />
            </SC.AccordionComponent>
          ))}
        </SC.AccordionsContainer>
        <SC.ButtonsContainer>
          <LinkButton variant="default" onClick={cleanFilters}>
            {t('atoms.checkboxList.clean')}
          </LinkButton>
          <Button variant="primary" size="large" onClick={() => onApplyAllFilters(selectedValues)}>
            {t('atoms.checkboxList.apply')}
          </Button>
        </SC.ButtonsContainer>
      </SC.FiltersContainer>
    );
  };

  return (
    <SC.Container>
      <SC.DrawerComponent drawerWidth={'671px'} isOpen={isOpen} drawerHeight={100} renderDrawer={renderFilters()} />
      {isOpen && <SC.Backdrop onClick={onClose} />}
    </SC.Container>
  );
};
