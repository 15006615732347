import { useContext } from 'react';
import { ICompanyLicenseAcceptanceTerm } from '@containers/Subscription/context/types/contracts';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';

export enum ERole {
  ADMIN = 'ADMIN',
  APPROVER = 'APPROVER',
  EMPLOYEE = 'EMPLOYEE',
}

interface UseAuthData {
  employeeId: string;
  companyId: string;
  isAdmin: boolean;
  signedContracts: ICompanyLicenseAcceptanceTerm[];
}

export function useAuthData(): UseAuthData {
  const { companyId, employeeId, isAdmin } = useContext(SessionContext);
  const { expenses } = JSON.parse(getFromLS('auth_store'));

  return {
    employeeId,
    companyId,
    signedContracts: expenses?.contracts || [],
    isAdmin,
  };
}
