import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleDescription = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const FaqButton = styled(Icons)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary[50]};
  }
`;

export const Caption = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 400;
`;

export const ContainerAction = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;
