import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 650px;
  max-height: calc(100vh - 340px);
`;

export const ExpensesContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  margin-bottom: ${props => props.theme.spacings.xs2};
  list-style-type: none;
  overflow-x: none;
  overflow-y: auto;
  height: 100%;
  max-height: 380px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 50%;
    background: ${props => props.theme.colors.neutral[80]};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.neutral[90]};
  }
`;

export const ExpenseItem = styled.li`
  align-items: center;
  border-bottom: ${props => props.theme.borders.width.xs} solid ${props => props.theme.colors.neutral[90]};
  display: grid;
  grid-template-columns: 42px auto minmax(80px, 90px) minmax(auto, 172px);
  gap: ${props => props.theme.spacings.s};
  padding: ${props => props.theme.spacings.xs3} 0;
`;

export const ExpenseNameContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${props => props.theme.spacings.xs2};
`;

export const PlusIcon = styled(Icons).attrs({
  name: 'IconPlus',
  size: 16,
})`
  margin-left: ${props => props.theme.spacings.xs4};
`;

export const EmptyContainer = styled.div`
  width: 100%;
  margin-top: ${props => props.theme.spacings.xs2};
`;

export const DescriptionEmpty = styled(Typography)`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.neutral[50]};
  margin-top: ${props => props.theme.spacings.xs2};
`;

export const Image = styled.img`
  width: 200px;
  margin: 0 auto;
`;
