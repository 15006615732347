import { getAdvances } from '@containers/Advances/context/queries/advance';
import { IAdvanceListFilters, IAdvanceListResponse } from '@containers/Advances/context/types/advance';
import { getErrorToast } from '@shared/helpers/errorToast';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

export function useAllAdvances() {
  const [getAllAdvances, { loading, data, error }] = useLazyQuery<
    { getAdvances: IAdvanceListResponse },
    IAdvanceListFilters
  >(getAdvances, { fetchPolicy: 'no-cache' });

  async function getAdvancesList({
    page,
    limit,
    endDate,
    isOpenToAttach,
    paymentMethod,
    reportId,
    startDate,
    status,
    referenceId,
  }: IAdvanceListFilters) {
    try {
      const response = await getAllAdvances({
        variables: {
          page,
          limit,
          endDate,
          isOpenToAttach,
          paymentMethod,
          reportId,
          startDate,
          status,
          referenceId,
        },
      });
      const advancesData = response?.data?.getAdvances;

      return {
        ...advancesData,
        isLoading: loading,
        error,
      };
    } catch (err) {
      getErrorToast(err);
    }
  }

  return {
    getAdvances: getAdvancesList,
    advancesData: {
      advances: data?.getAdvances?.advances || [],
      total: data?.getAdvances?.total || 0,
      limit: data?.getAdvances?.limit,
      currentPage: data?.getAdvances?.currentPage,
      pages: data?.getAdvances?.pages,
      isLoading: loading,
      error,
    },
  };
}
