import { useProjectFileRegister } from '@containers/Projects/hooks/useProjectFileRegister';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { FooterPage } from '@molecules/FooterPage';
import { projectsBaseURL } from '@shared/constant';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

interface ProjectFooterPageProps {
  action?(): void;
}

export const ProjectFooterPage = ({ action }: ProjectFooterPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSubmit } = useFormContext();
  const { importProjects } = useProjectFileRegister();

  return (
    <FooterPage>
      <SC.ButtonsContainer>
        <LinkButton onClick={() => navigate(projectsBaseURL)} variant="default">
          {t('organisms.registerClient.bulkImport.footer.cancel')}
        </LinkButton>
        <Button variant="primary" size="medium" type="submit" onClick={action ? action : handleSubmit(importProjects)}>
          {t('organisms.registerClient.bulkImport.footer.continue')}
          <Icons name="IconArrowRight" fill="transparent" />
        </Button>
      </SC.ButtonsContainer>
    </FooterPage>
  );
};
