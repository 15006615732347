import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 0 ${props => props.theme.spacings.xs} ${props => props.theme.spacings.xs} ${props => props.theme.spacings.xs};
`;

export const BreadcrumbsContainer = styled.div`
  padding: ${props => props.theme.spacings.m} 0;
`;

export const ActionsButtonsFooterContainer = styled.div`
  position: relative;
  display: grid;
  gap: ${props => props.theme.spacings.xs};
  grid-template-columns: min-content min-content;
`;

export const ButtonContent = styled.div`
  display: grid;
  gap: ${props => props.theme.spacings.xs4};
  grid-template-columns: 1fr 1fr;
`;

export const ContainerModals = styled.div`
  position: absolute;
`;
