import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { searchPlaces } from '../context/queries/establishment';
import { IGetSearchPlacesRequest, IGetSearchPlacesResponse } from '../context/types/establishments';

export const useSearchPlaces = () => {
  const [getSearchPlaces, { loading, data, error }] = useLazyQuery<IGetSearchPlacesResponse, IGetSearchPlacesRequest>(
    searchPlaces,
  );

  return {
    getSearchPlaces,
    places: data?.searchPlaces || [],
    isLoading: loading,
    error,
  };
};
