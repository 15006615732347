import { ReactNode } from 'react';
import { IExpenseAttachment } from '@containers/Expenses/context/types';

import { EExportAnalyticsType } from './export';
import { IAnalyticsFilterFieldInput } from './filters';

export interface IAnalyticsData {
  data: Record<IAnalyticsDataField['key'], ReactNode | IExpenseAttachment[]>[];
  fields: IAnalyticsDataField[];
  selectedFields: string[];
  base64Export: string;
  total: number;
}

export interface IAnalyticsDataField {
  key: string;
  label: string;
  format: EAnalyticsDataType;
}

export enum EAnalyticsDataType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  RECEIPT = 'RECEIPT',
  CURRENCY = 'CURRENCY',
  OLD_EVENT = 'OLD_EVENT',
  CURRENT_EVENT = 'CURRENT_EVENT',
  VIOLATION = 'VIOLATION',
  DATE_TIME_WITH_TIMEZONE = 'DATE_TIME_WITH_TIMEZONE',
  NUMBER = 'NUMBER',
  OPTIONS = 'OPTIONS',
  YES_NO = 'YES_NO',
}

export interface IAnalyticsRequest {
  selectedFields?: string[];
  filters?: IAnalyticsFilterFieldInput[];
  config: {
    page: number;
    limit: number;
  };
  exportType?: EExportAnalyticsType;
  isPending?: boolean;
}
