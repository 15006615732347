import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ContainerAction = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  justify-content: space-between;
  align-items: center;
`;

export const ContainerInfo = styled.div`
  width: 100%;
`;

export const TitleAction = styled(Typography)`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700 !important;
`;

export const CaptionAction = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
