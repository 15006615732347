import { Skeleton } from '@mui/material';
import { reportActionsSkeletonPrintItems } from '@shared/constant';

import * as SC from './styled';

export interface ReportActionsSkeletonProps {
  numberItemsPrint?: number;
}

export const ReportActionsSkeleton = ({
  numberItemsPrint = reportActionsSkeletonPrintItems,
}: ReportActionsSkeletonProps) => {
  return (
    <SC.ReportActionsContainer>
      {new Array(numberItemsPrint).fill({}).map((_, key) => (
        <Skeleton key={key} variant="circular" width={50} height={50} />
      ))}
    </SC.ReportActionsContainer>
  );
};
