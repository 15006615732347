import { ShapeIcon } from '@atoms/ShapeIcon';
import { Button, IconButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Modal } from '@mui/material';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  background-color: ${props => props.theme.colors.neutral[100]};
  border-radius: ${props => props.theme.borders.radius.l};
  max-height: calc(100vh - 15px);
`;

export const DangerActionModal = styled(Modal)`
  &.MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    outline: none;
  }
`;

export const ModalBodyContainer = styled.div`
  padding: ${props => `0 ${props.theme.spacings.xl4} ${props.theme.spacings.m} ${props.theme.spacings.xl4}`};
  max-height: calc(100vh - 450px);
  overflow: auto;
`;

export const ModalHeaderContainer = styled.div`
  position: relative;
  padding: ${props => props.theme.spacings.m} ${props => props.theme.spacings.xl4};
  width: 100%;
  text-align: center;
`;

export const IconClose = styled(IconButton).attrs({
  icon: 'IconX',
  variant: 'line',
  size: 'small',
})`
  position: absolute;
  top: ${props => props.theme.spacings.xs3};
  right: ${props => props.theme.spacings.xs};
`;

export const Title = styled(Typography).attrs({
  variant: 'headline6',
})`
  font-weight: 700 !important;
  color: ${props => props.theme.colors.neutral[10]};
  max-width: 420px;
`;

export const IconHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > div {
    padding: 20px;
  }
`;

export const AlertIcon = styled(ShapeIcon).attrs({
  size: 'large',
  variant: 'error',
  icon: 'IconAlertCircle',
})`
  fill: ${props => props.theme.colors.feedback.error[40]};
`;

export const IconLabel = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 700 !important;
  color: ${props => props.theme.colors.feedback.error[40]};
  margin-top: ${props => props.theme.spacings.xs2};
`;

export const Subtitle = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 400 !important;
  color: ${props => props.theme.colors.neutral[50]};
  margin-top: ${props => props.theme.spacings.xs2};
  max-width: 420px;
`;

export const ModalFooterContainer = styled.div`
  padding: ${props => props.theme.spacings.xs} ${props => props.theme.spacings.xl4};
  border-top: 1px solid ${props => props.theme.colors.neutral[90]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: ${props => props.theme.borders.radius.l};
  border-bottom-right-radius: ${props => props.theme.borders.radius.l};
`;

export const ActionButton = styled(Button)`
  color: ${props => props.theme.colors.feedback.error[40]}!important;
  stroke: ${props => props.theme.colors.feedback.error[40]}!important;
  background: ${props => props.theme.colors.feedback.error[90]};

  &.button-custom-variant-error--primary.MuiPaper-root.MuiButtonBase-root.loading {
    background: linear-gradient(
      90deg,
      ${props => props.theme.colors.feedback.error[90]} 50%,
      ${props => props.theme.colors.feedback.error[70]} 80%
    );
  }

  &:hover {
    box-shadow: 0 0px 10px ${props => props.theme.colors.feedback.error[40]}!important;
  }
  &:disabled {
    color: ${props => props.theme.colors.neutral[70]}!important;
    stroke: ${props => props.theme.colors.feedback.error[70]}!important;
    background-color: ${props => props.theme.colors.neutral[90]}!important;
  }
`;

export const IconActionButtonContainer = styled.div`
  margin-left: ${props => props.theme.spacings.xs4};
`;
