import styled from 'styled-components';

interface IDotted {
  index: number;
}

export const Container = styled.div`
  width: min-content;
  height: min-content;
`;

export const Dotted = styled.div<IDotted>(({ theme }) => ({
  width: '2.5px',
  height: '4px',
  backgroundColor: 'grey',
  marginTop: theme.spacings.xs5,
  marginBottom: theme.spacings.xs5,

  ':first-child': {
    marginTop: 0,
    height: '2px',
  },

  ':last-child': {
    marginBottom: 0,
    height: '2px',
  },
}));

export const Solid = styled.div`
  width: 2.5px;
  height: 16px;
  background-color: red;
`;
