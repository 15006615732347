import { useMemo } from 'react';
import { EReportStatus, IReportSummaries } from '@containers/Reports/context/types';

export interface UseAllowedActions {
  repay: boolean;
  reprove: boolean;
  cancelIntegration: boolean;
  exportPdf: boolean;
  exportPdfByCategory: boolean;
}

interface UseAllowedActionsProps {
  reports: IReportSummaries[];
  selectedItems: IReportSummaries['id'][];
  isExporting: boolean;
}

export function useBulkActionsAllowed({
  reports,
  selectedItems,
  isExporting,
}: UseAllowedActionsProps): UseAllowedActions {
  const reportsSelected: IReportSummaries[] = useMemo(
    () => reports.filter(item => selectedItems.includes(item.id)),
    [selectedItems],
  );

  const allowableAction: boolean = useMemo(
    () => !reportsSelected.some(item => item.status != EReportStatus.APPROVED),
    [reportsSelected],
  );

  const allowableCancelIntegrationAction: boolean = useMemo(
    () =>
      !reportsSelected.some(
        report =>
          report.status !== EReportStatus.AWAITING_FINANCIAL_PROCESSING &&
          report.status !== EReportStatus.PROCESSING_PAYMENT_ERROR,
      ),
    [reportsSelected],
  );

  return {
    repay: allowableAction,
    reprove: allowableAction,
    cancelIntegration: allowableCancelIntegrationAction,
    exportPdf: !isExporting,
    exportPdfByCategory: !isExporting,
  };
}
