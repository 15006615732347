import { useContext } from 'react';
import { ICompanyLicenseAcceptanceTerm } from '@containers/Subscription/context/types/contracts';
import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';

export function useTermsInLS() {
  const { companyId } = useContext(SessionContext);
  const keyLS: string = `expensesAcceptanceTermsCompanyId-${companyId}`;

  function setTermsInLS(
    _acceptanceTerms: ICompanyLicenseAcceptanceTerm[] | ICompanyLicenseAcceptanceTerm['document'][],
  ) {
    setInLS({ key: keyLS, value: _acceptanceTerms });
  }

  function getTermsFromLS() {
    return getFromLS(keyLS);
  }

  return {
    setTermsInLS,
    getTermsFromLS,
  };
}
