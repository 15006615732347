import { AdvancePolicy } from '@containers/Policies/context/types/advances';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export function useAdvancesPolicySettings(policyId: string) {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const { data, error, isLoading } = useFetch<AdvancePolicy>(
    !policyId ? null : `v1/advances/policy/${policyId}`,
    '',
    undefined,
    undefined,
    onError,
  );

  return {
    error,
    isLoading: isLoading && !data,
    policy: data,
  };
}
