import { Button, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  white-space: nowrap;
`;

export const FilterLabel = styled(Typography)`
  white-space: nowrap;
  font-weight: 600 !important;
`;

export const ExportButton = styled(Button)`
  white-space: nowrap;
  font-weight: 600 !important;
`;
