import { gql } from '@apollo/client';

export const getUsersForSelectionQuery = gql`
  query GetUsersForSelection {
    getUsersForSelection {
      userSelections {
        id
        name
      }
    }
  }
`;
