import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

interface IProps {
  isPlural?: boolean;
  label?: string;
}

export default function MandatoryInfo({ isPlural = false, label = '' }: IProps) {
  const t = useTranslate('mandatoryFieldInfo');

  return (
    <SC.MandatoryInfoContainer>
      {label && label}
      <SC.InfoMandatoryDescriptionAlert variant="body4">*</SC.InfoMandatoryDescriptionAlert>
      {!label && (
        <SC.InfoMandatoryDescription variant="body4">{t(isPlural ? 'plural' : 'singular')}</SC.InfoMandatoryDescription>
      )}
    </SC.MandatoryInfoContainer>
  );
}
