import { Skeleton } from '@mui/material';

import * as SC from './styled';

export const RegistrationFormContainerWithReportSkeleton = () => {
  return (
    <>
      <SC.DescriptionContainer>
        <Skeleton variant="circular" height={40} width={40} />
        <Skeleton variant="rounded" height={22} width={250} />
      </SC.DescriptionContainer>
      <SC.ActionsContainer>
        <Skeleton variant="circular" height={40} width={40} />
        <Skeleton variant="circular" height={40} width={40} />
      </SC.ActionsContainer>
    </>
  );
};
