export interface IRequestHandlePolicy {
  name: string;
  description: string;
  enabled: boolean;
}

export interface IPolicyBase {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
  updatedAt: string;
  level: EPolicyLevel;
}

export enum EPolicyLevel {
  USER = 'USER',
  PROJECT = 'PROJECT',
  ROLE = 'ROLE',
  AREA = 'AREA',
  SUBSIDIARY = 'SUBSIDIARY',
  COMPANY = 'COMPANY',
}

export enum EActionGrid {
  SEE_DETAILS = 'seeDetails',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  EXPORT = 'export',
  DELETE = 'delete',
  TOGGLE = 'disable',
}

export enum EActionIcon {
  SEE_DETAILS = 'IconFileDescription',
  EDIT = 'IconPencil',
  DUPLICATE = 'IconCopy',
  EXPORT = 'IconDownload',
  DELETE = 'IconTrash',
  TOGGLE = 'IconToggleLeft',
}

export interface IPolicy {
  id?: string;
  name?: string;
  description?: string;
  enabled?: boolean;
  level?: EPolicyLevel;
  conditions?: IPolicyCondition[];
  updatedAt?: string;
  document?: {
    name?: string;
    type?: string;
    base64?: string;
  };
  file?: File;
}

export interface IPolicyCondition {
  key: EPolicyLevel;
  value: string;
}
