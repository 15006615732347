import { Skeleton } from '@mui/material';

import * as SC from './styled';

interface IProps {
  printFieldsNumber?: number;
  maxWidth?: string;
}

export const ClientFormSkeleton = ({ printFieldsNumber, maxWidth = '100%' }: IProps) => {
  return (
    <>
      {new Array(printFieldsNumber).fill({}).map((_, key) => (
        <SC.FieldContainer key={key} fieldName={''}>
          <Skeleton variant="rounded" height={60} width={maxWidth} />
        </SC.FieldContainer>
      ))}
    </>
  );
};
