import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

import { ColorVariant, SizeVariant } from '../../../themes/types/Variants';

interface StatusTagProps {
  variant: ColorVariant;
  size?: SizeVariant;
}

export const StatusTagContainer = styled('div')<StatusTagProps>(({ variant, ...props }) => ({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  gap: `${props.theme.spacings.xs4}`,
  borderRadius: props.theme.borders.radius.xl,
  padding: `${props.theme.spacings.xs5} ${props.theme.spacings.xs3}`,
  backgroundColor: getVariantColor(props.theme.colors, variant).background,
  color: getVariantColor(props.theme.colors, variant).foreground,
}));

export const StatusTagTitle = styled(Typography)`
  font-weight: 600 !important;
  line-height: 20px;
  white-space: nowrap;
`;

export const StatusIcon = styled.span<StatusTagProps>(({ variant, ...props }) => ({
  aspectRatio: '1 / 1',
  display: 'block',
  borderRadius: props.theme.borders.radius.circular,
  backgroundColor: getVariantColor(props.theme.colors, variant).icon,
  height: 6,
}));

const getVariantColor = (colors, variant) => {
  const tagColors = {
    error: {
      foreground: colors.feedback.error?.[10],
      background: colors.feedback.error?.[90],
      icon: colors.feedback?.error?.[50],
    },
    info: {
      foreground: colors.feedback.info?.[10],
      background: colors.feedback.info?.[90],
      icon: colors.feedback?.info?.[50],
    },
    success: {
      foreground: colors.feedback.success?.[10],
      background: colors.feedback.success?.[90],
      icon: colors.feedback?.success?.[50],
    },
    secondary: {
      foreground: colors.secondary?.[50],
      background: colors.secondary?.[90],
      icon: colors.secondary?.[50],
    },
    neutral: {
      foreground: colors.neutral?.[10],
      background: colors.neutral?.[90],
      icon: colors.neutral?.[50],
    },
  };

  return tagColors[variant];
};
