import { gql } from '@apollo/client';

export const getEstablishments = gql`
  query GetEstablishments($page: Int!, $limit: Int!) {
    getEstablishments(page: $page, limit: $limit) {
      establishments {
        placeId
        place
        address
        lat
        lng
      }
      currentPage
      pages
      limit
      total
    }
  }
`;

export const getPlaceDetails = gql`
  query GetPlaceDetails($placeId: String!) {
    getPlaceDetails(placeId: $placeId) {
      id
      completeAddress
      name
      address
      location {
        lat
        lng
      }
    }
  }
`;

export const searchPlaces = gql`
  query SearchPlaces($place: String!) {
    searchPlaces(place: $place) {
      id
      completeAddress
      name
      address
    }
  }
`;

export const createEstablishment = gql`
  mutation CreateEstablishment($establishment: EstablishmentInput!) {
    createEstablishment(establishment: $establishment) {
      placeId
      place
      address
      lat
      lng
    }
  }
`;
