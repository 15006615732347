import { IPolicy } from '@containers/Policies/context/types/types';
import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { PolicyRuleSection } from '../../molecules/PolicyRuleSection/PolicyRuleSection';
import PolicyOCRSettings from './PolicyOCRSettings/PolicyOCRSettings';

interface IProps {
  policyId: IPolicy['id'];
  onCloseRule(): void;
}

export function PolicyOCR({ policyId, onCloseRule }: IProps) {
  const t = useTranslate('policy.register.ocr');

  return (
    <PolicyRuleSection
      icon="IconZoomCheck"
      title={t('title')}
      caption={t('caption')}
      action={<IconButton onClick={onCloseRule} variant="line" icon="IconX" size="small" />}>
      <Box mt={2}>
        <PolicyOCRSettings policyId={policyId} />
      </Box>
    </PolicyRuleSection>
  );
}
