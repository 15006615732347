import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getSummaryReports } from '../context/queries/reports';
import { IGetRequestResponse, ISummaryReport } from '../context/types/reports';

interface UseSummaryReports {
  reports: ISummaryReport[];
  isLoading: boolean;
  totalItems: number;
  error: unknown;
}

export interface IUseSummaryReports {
  limit: number;
  page: number;
  status?: string;
  onlyWithPendencies?: boolean;
}

export const useSummaryReports = ({
  limit,
  page,
  status,
  onlyWithPendencies = false,
}: IUseSummaryReports): UseSummaryReports => {
  const { loading, data, error, networkStatus } = useQuery<IGetRequestResponse, IUseSummaryReports>(getSummaryReports, {
    variables: {
      limit,
      page,
      status,
      onlyWithPendencies,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  return {
    reports: data?.getReports?.reports || [],
    isLoading: loading || networkStatus === NetworkStatus.loading,
    totalItems: data?.getReports?.total || 0,
    error,
  };
};
