import React from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { useCustomFields } from '@containers/Customizations/hooks/CustomFields/useCustomFields';
import { ActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ColorVariant } from 'src/themes/types/Variants';

import { CustomFieldConfirmationActionsModal } from '../CustomFieldConfirmationActionsModal/CustomFieldConfirmationActionsModal';
import { CustomFieldModal } from '../CustomFieldModal/CustomFieldModal';
import { useCustomFieldsActionsGrid } from './hooks/useCustomFieldsActionsGrid';
import * as SC from './styled';

enum EAccessor {
  CUSTOM_FIELD = 'customField',
  TYPE = 'type',
  STATUS = 'status',
  REQUIRED = 'required',
  ACTIONS = 'actions',
}

export function CustomFieldsGrid() {
  const t = useTranslate('organisms.customizationsCustomFields');

  const { customFields, isLoading } = useCustomFields();

  const {
    showEditModal,
    showConfirmationModal,
    confirmationType,
    isLoadingAction,
    getActionsRow,
    onShowConfirmationActionsModal,
    onShowEditModal,
    onConfirmationActionModal,
    selectedCustomField,
  } = useCustomFieldsActionsGrid(customFields);

  function getColumns(): IGridColumn[] {
    return Object.values(EAccessor).map(accessor => ({
      accessor,
      disableSortBy: true,
      Header: <ColumnHeader isLoading={isLoading} description={t(`grid.header.${accessor}`)} />,
      sticky: accessor === EAccessor.ACTIONS && 'right',
    }));
  }

  function getRows(): Record<EAccessor, React.ReactNode>[] {
    return customFields?.map(field => {
      return {
        customField: field.label,
        type: t(`modal.customField.fields.customFieldType.options.${field.type}`),
        status: getTag('active', field.active),
        required: getTag('connected', field.required),
        actions: (
          <ActionsRow
            referenceId={field.id}
            actions={getActionsRow(field)}
            isLoading={isLoadingAction && selectedCustomField.id === field.id}
          />
        ),
      };
    });
  }

  function getDescriptionTag(type: 'connected' | 'active', isActive: boolean): string {
    return type === 'active'
      ? t(`grid.body.${(isActive && 'active') || 'deactivate'}`)
      : t(`grid.body.${(isActive && 'yes') || 'not'}`);
  }

  function getVariantTag(type: 'connected' | 'active', isActive: boolean): ColorVariant {
    return isActive ? (type === 'active' && 'success') || 'secondary' : 'neutral';
  }

  function getTag(type: 'connected' | 'active', isActive: boolean) {
    return (
      <StatusTag showStatusIcons={type === 'active'} variant={getVariantTag(type, isActive)}>
        {getDescriptionTag(type, isActive)}
      </StatusTag>
    );
  }

  return (
    <SC.Container>
      <Grid
        columns={getColumns()}
        rows={getRows()}
        isLoading={isLoading}
        hasPagination={false}
        totalItems={customFields?.length || 0}
      />

      {showConfirmationModal && (
        <CustomFieldConfirmationActionsModal
          open={showConfirmationModal}
          confirmationType={confirmationType}
          onActionClick={onConfirmationActionModal}
          onClose={onShowConfirmationActionsModal}
          isLoading={isLoadingAction}
        />
      )}

      {showEditModal && (
        <CustomFieldModal open={showEditModal} onCloseModal={onShowEditModal} customField={selectedCustomField} />
      )}
    </SC.Container>
  );
}
