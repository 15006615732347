import { useMemo } from 'react';
import { CustomFieldRegisterForm, ICustomField } from '@containers/Customizations/context/types/customFieldTypes';
import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { EFieldType } from '@organisms/FieldFactory';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Control, Controller } from 'react-hook-form';

type Props = {
  control: Control<CustomFieldRegisterForm>;
  customField?: ICustomField;
};

export default function CustomFieldTypeSelect({ control, customField }: Props) {
  const t = useTranslate('organisms.customizationsCustomFields.modal.customField');
  const actionType = useMemo<'add' | 'edit'>(() => (customField ? 'edit' : 'add'), [customField]);

  const fieldTypes = useMemo(
    () => [EFieldType.TEXT, EFieldType.NUMBER, EFieldType.OPTIONS, EFieldType.DATE, EFieldType.BAR_CODE],
    [],
  );

  return (
    <Box>
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <SelectField
            label={t(`fields.customFieldType.label`)}
            fullWidth
            disabled={actionType === 'edit'}
            options={fieldTypes.map(field => ({
              value: field,
              label: t(`fields.customFieldType.options.${field}`),
            }))}
            value={field.value}
            onSelectChange={(e, selected) => field.onChange(selected)}
          />
        )}
        rules={{ required: true }}
      />
    </Box>
  );
}
