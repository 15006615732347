import { CustomFieldRegisterForm } from '@containers/Customizations/context/types/customFieldTypes';
import { Button, Icons, TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { AddOptionsWrapper, OptionTitle, OptionWrapper } from './styled';

type Props = {
  control: Control<CustomFieldRegisterForm>;
};

const CustomFieldSelectOptions = ({ control }: Props) => {
  const t = useTranslate('organisms.customizationsCustomFields.modal.customField.fields.options');

  const { colors } = useTheme();

  const selectOptions = useFieldArray({ control, name: 'options' });

  return (
    <AddOptionsWrapper>
      <OptionTitle variant="headline8">{t('addOptions')}</OptionTitle>

      {selectOptions.fields.map((option, index) => (
        <OptionWrapper key={option.id}>
          <Controller
            control={control}
            name={`options.${index}.value` as const}
            render={({ field }) => <TextField label={`${t('option')} ${index + 1}`} fullWidth {...field} />}
            rules={{ required: true, minLength: 1 }}
          />

          {selectOptions.fields?.length > 1 && (
            <Icons
              name="IconTrash"
              size={24}
              fill="transparent"
              onClick={() => selectOptions.remove(index)}
              style={{ cursor: 'pointer' }}
            />
          )}
        </OptionWrapper>
      ))}

      <Button
        size="small"
        variant="primary"
        style={{ marginLeft: 'auto' }}
        disabled={false}
        onClick={() => selectOptions.append({ value: '' })}>
        {t('addOption')}
        <Icons name="Add" color={colors.neutral[100]} />
      </Button>
    </AddOptionsWrapper>
  );
};

export default CustomFieldSelectOptions;
