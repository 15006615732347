import * as SC from './styled';

interface IProps {
  isTitleMain?: boolean;
  title: string;
  caption: string;
}

export function MainSummaryData({ isTitleMain, title, caption }: IProps) {
  return (
    <div>
      <SC.Title variant={isTitleMain ? 'headline5' : 'headline8'}>{title}</SC.Title>
      <SC.Caption variant={isTitleMain ? 'body3' : 'body4'}>{caption}</SC.Caption>
    </div>
  );
}
