import { ReactNode, useMemo } from 'react';
import { EExportAnalyticsType } from '@containers/Analytics/context/types/export';
import { EAnalyticsType, IAnalyticsFilterFieldInput } from '@containers/Analytics/context/types/filters';
import { IDrawerFilter, IFilterValues } from '@molecules/FiltersDrawer';
import { Grid, IGridProps } from '@organisms/Grid/Grid';
import { useAnalyticsPermissions } from '@shared/hooks/permissions/systemPermissions/useAnalyticsPermissions';

import { AnalysisContainerExport } from '../../molecules/ActionsAnalyticsSections/AnalysisContainerExport';
import { AnalyticsFilters } from '../../molecules/AnalyticsFilters/AnalyticsFilters';
import * as SC from './styled';

interface IAnalyticsHeader {
  title: string;
  caption?: string;
  icon?: string;
}
interface IFilters {
  filters: IDrawerFilter[];
  filtersIsLoading: boolean;
  filtersSelected: IAnalyticsFilterFieldInput[];
  showMoreFiltersButton?: boolean;
  maxFiltersShown?: number;
  isAllDisabled?: boolean;
  applyAllFilters(_filters: IFilterValues[]): void;
}

interface IProps {
  header: IAnalyticsHeader;
  gridData?: IGridProps;
  filtersData?: IFilters;
  exportIsLoading?: boolean;
  analyticsType: EAnalyticsType;
  customizeReport?: ReactNode;

  onExportClick: (analyticsExport: EExportAnalyticsType) => void;
}

export function AnalysisContainer({
  header,
  gridData,
  exportIsLoading,
  filtersData,
  analyticsType,
  customizeReport,
  onExportClick,
}: IProps) {
  const { getAllowedActionsAnalytics } = useAnalyticsPermissions();
  const { export: allowedToExport, filters: allowedToFilters } = getAllowedActionsAnalytics(analyticsType);
  const actions = useMemo(
    () =>
      allowedToExport
        ? [
            {
              children: (
                <>
                  <AnalysisContainerExport
                    key={`export-${analyticsType}-button`}
                    isLoading={exportIsLoading}
                    onExportClick={onExportClick}
                    disabledActions={gridData?.isLoading}
                  />
                </>
              ),
              onClick: () => null,
            },
          ]
        : [],
    [allowedToExport, exportIsLoading, gridData?.isLoading],
  );

  return (
    <SC.Accordion
      key={analyticsType}
      title={header.title}
      description={header.caption}
      variant="default"
      actions={actions}>
      <SC.ChildrenContainer key={`accordion-${analyticsType}`}>
        {allowedToFilters && (
          <AnalyticsFilters
            filters={filtersData?.filters || []}
            filtersIsLoading={filtersData?.filtersIsLoading}
            showSkeletonFilterItems={3}
            applyAllFilters={filtersData?.applyAllFilters}
            filtersSelected={filtersData?.filtersSelected}
            isAllDisabled={filtersData?.isAllDisabled}
            customizeReport={customizeReport}
            showMoreFiltersButton={filtersData.showMoreFiltersButton ?? true}
          />
        )}
        {Boolean(gridData?.columns) && (
          <SC.GridContainer>
            <Grid {...gridData} />
          </SC.GridContainer>
        )}
      </SC.ChildrenContainer>
    </SC.Accordion>
  );
}
