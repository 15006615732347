/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { IEstablishment } from './establishments';
import { IExpense, IOdometer, IRoute } from './expenseById';
import { EExpenseType } from './expenses';
import { IFileResponse } from './files';

export enum EFieldType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  OPTIONS = 'OPTIONS',
  BAR_CODE = 'BAR_CODE',
  FLAG = 'FLAG',
  ADDRESSFILE = 'ADDRESSFILE',
  CUSTOM = 'CUSTOM',
  ADDRESS = 'ADDRESS',
}

export enum EExpenseFieldType {
  client = 'OPTIONS',
  project = 'OPTIONS',
  quantity = 'NUMBER',
  category = 'OPTIONS',
  address = 'ADDRESS',
  receiptFile = 'FILE',
  currency = 'OPTIONS',
  paymentType = 'OPTIONS',
  value = 'NUMBER',
  date = 'DATE',
  description = 'TEXT',
  costCenter = 'OPTIONS',
  refundable = 'FLAG',
  billable = 'FLAG',
  report = 'TEXT',
  invoice = 'TEXT',
  invoiceKey = 'TEXT',
  custom = 'CUSTOM',
}

export interface IFieldControl {
  active: boolean;
  required: boolean;
  allowsEditing?: boolean;
  fieldOrder?: number;
}

export interface ICustomField {
  id: string;
  label: string;
  type: EExpenseFieldType;
  defaultValue: string;
  options: string[];
  active: boolean;
  required: boolean;
  fieldOrder?: number;
  value?: SyntaxError;
}

export interface IExpenseFields {
  client: IFieldControl;
  project: IFieldControl;
  quantity: IFieldControl;
  category: IFieldControl;
  address: IFieldControl;
  receiptFile: IFieldControl;
  currency: IFieldControl;
  paymentType: IFieldControl;
  value: IFieldControl;
  date: IFieldControl;
  description: IFieldControl;
  costCenter: IFieldControl;
  refundable: IFieldControl;
  billable: IFieldControl;
  report: IFieldControl;
  invoice: IFieldControl;
  custom: ICustomField[];
  __typename: string;
}

export interface IGetExpenseFormFieldsResponse {
  getExpenseFormFields: IExpenseFields;
}

export interface ICustomFieldInput {
  id: string;
  value: string;
}
export interface IExpenseInput {
  id: IExpense['id'];
  type?: EExpenseType;
  clientId?: number;
  projectId?: number;
  categoryId?: number;
  reportId?: number;
  cardId?: number;
  costCenterId?: number;
  refundable?: boolean;
  billable?: boolean;
  currencyCode?: string;
  establishment?: IEstablishment;
  value?: number;
  convertedValue?: number;
  currencyRate?: number;
  defaultCurrencyRate?: number;
  quantity?: number;
  invoiceKey?: string;
  invoice?: string;
  paymentMethodId?: number;
  description?: string;
  expenseDate?: string;
  customFields?: ICustomFieldInput[];
  route?: IRoute;
  attachments: ReceiptFile[];
  odometer?: IOdometer;
}

export interface ReceiptFile extends IFileResponse {
  file?: File;
  previewUrl?: string;
  fileName?: string;
}

export enum EExpenseFieldsLicenseFeatures {
  client = 'INTERMEDIATE_CLIENT',
  project = 'INTERMEDIATE_PROJECT',
  quantity = 'BASIC_EXPENSE',
  category = 'BASIC_CATEGORIES',
  address = 'BASIC_EXPENSE',
  receiptFile = 'BASIC_EXPENSE',
  currency = 'BASIC_EXPENSE',
  paymentType = 'BASIC_EXPENSE',
  value = 'BASIC_EXPENSE',
  date = 'BASIC_EXPENSE',
  description = 'BASIC_EXPENSE',
  comments = 'BASIC_EXPENSE',
  costCenter = 'INTERMEDIATE_COST_CENTER',
  refundable = 'BASIC_EXPENSE',
  billable = 'BASIC_EXPENSE',
  report = 'BASIC_EXPENSE',
  invoice = 'BASIC_EXPENSE',
  invoiceKey = 'BASIC_EXPENSE',
  custom = 'ADVANCED_EXPENSE',
  customField = 'ADVANCED_EXPENSE',
  customFields = 'ADVANCED_EXPENSE',
}
