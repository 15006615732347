import { RegisterClientByFileForm } from '@containers/Clients/components/organisms/RegisterClientByFileForm';
import { useTranslation } from '@locale/Translator';
import { BulkImportContainer } from '@molecules/BulkImportContainer';
import { BulkImportFiles } from '@molecules/BulkImportFiles';
import { PageHeader } from '@organisms/BasePage/PageHeader/PageHeader';
import { FormProvider, useForm } from 'react-hook-form';

import { ClientFooterPage } from './ClientFooterPage/ClientFooterPage';
import { ClientRegisterFileHeader } from './ClientRegisterFileHeader';

export const BulkImportClients = () => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <>
      <ClientRegisterFileHeader />
      <FormProvider {...methods}>
        <BulkImportContainer>
          <PageHeader title={t('pages.clientRegister.title')} />
          <BulkImportFiles
            title={t('organisms.registerClient.bulkImport.sendData.title')}
            subtitle={t('organisms.registerClient.bulkImport.sendData.subTitle.clients')}>
            <RegisterClientByFileForm />
          </BulkImportFiles>
        </BulkImportContainer>
        <ClientFooterPage />
      </FormProvider>
    </>
  );
};
