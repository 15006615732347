import { useCallback, useContext, useMemo } from 'react';
import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { SubscriptionContext } from '../context';
import { useSubscriptionPlan } from './useSubscriptionPlan';

type SliderItem = {
  icon: IconsProps['name'];
  description: string;
};

export function useSubscriptionFeatures() {
  const t = useTranslate();

  const { isFull } = useSubscriptionPlan();

  const { saasServiceContract, travelContract } = useContext(SubscriptionContext);

  const getTravelFeatures = useCallback((isSummarized: boolean) => {
    const items: SliderItem[] = [];

    if (isSummarized) {
      items.push({
        icon: 'IconPlane',
        description: t('molecules.subscriptionFeatures.travel.corporateTravels'),
      });

      return items;
    }

    items.push({
      icon: 'IconReportSearch',
      description: t('molecules.subscriptionFeatures.travel.airfareAndHotelQuotes'),
    });

    items.push({
      icon: 'IconPlane',
      description: t('molecules.subscriptionFeatures.travel.domesticAndInternationalTickets'),
    });

    items.push({
      icon: 'IconBuilding',
      description: t('molecules.subscriptionFeatures.travel.domesticAndInternationalHotelReservations'),
    });

    items.push({
      icon: 'IconChecklist',
      description: t('molecules.subscriptionFeatures.travel.approvalFlow'),
    });

    items.push({
      icon: 'IconShield',
      description: t('molecules.subscriptionFeatures.travel.policyConfiguration'),
    });

    items.push({
      icon: 'IconFileAnalytics',
      description: t('molecules.subscriptionFeatures.travel.detailedReports'),
    });

    return items;
  }, []);

  const getExpenseFeatures = useCallback(() => {
    const items: SliderItem[] = [];

    items.push({
      icon: 'IconCreditCard',
      description: t('molecules.subscriptionFeatures.corporateCard'),
    });

    items.push({
      icon: 'IconReceiptRefund',
      description: t('molecules.subscriptionFeatures.refundRequest'),
    });

    if (!isFull) {
      items.push({
        icon: 'IconChecklist',
        description: t('molecules.subscriptionFeatures.automaticApprovals'),
      });
    } else {
      items.push({
        icon: 'IconAdjustmentsHorizontal',
        description: t('molecules.subscriptionFeatures.companyWideApprovals'),
      });
    }

    items.push({
      icon: 'IconReceipt2',
      description: t('molecules.subscriptionFeatures.expenseAccountsProvision'),
    });

    items.push({
      icon: 'IconShieldCheck',
      description: isFull
        ? t('molecules.subscriptionFeatures.companyCustomPolicy')
        : t('molecules.subscriptionFeatures.companyWidePolicy'),
    });

    items.push({
      icon: 'IconReportAnalytics',
      description: t('molecules.subscriptionFeatures.managementReport'),
    });

    if (isFull) {
      items.push({
        icon: 'IconPlus',
        description: t('molecules.subscriptionFeatures.moreFeatures'),
      });
    }

    return items;
  }, [isFull, t]);

  return useMemo(() => {
    const items: SliderItem[] = [];

    const hasSAAS = !!saasServiceContract;
    const hasTravel = !!travelContract;

    if (hasTravel) {
      items.push(...getTravelFeatures(hasSAAS));
    }

    if (hasSAAS) {
      items.push(...getExpenseFeatures());
    }

    return items;
  }, [getExpenseFeatures, getTravelFeatures, saasServiceContract, travelContract]);
}
