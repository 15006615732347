import { FieldRegister } from '@containers/ApprovalFlows/CustomApprovalFlow/components/molecules/FieldRegister/FieldRegister';
import { ECriteriaKey } from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import { BasicInformationApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { SelectField, TextField, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { defaultCurrencySymbol } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { IOptionsSource } from '../hooks/useOptionsListCriteria';
import * as SC from '../styled';

interface IProps {
  index: number;
  label: string;
  isRequester: boolean;
  isSelectOptions: boolean;
  isLoading: boolean;
  valueOptions: IOptionsSource[];
}

export function CriteriaFieldValue({ index, label, isRequester, isLoading, valueOptions, isSelectOptions }: IProps) {
  const t = useTranslate('approvalFlows.approvalCriteria.fields');
  const { control, getValues } = useFormContext<BasicInformationApprovalFlowForm>();

  if (isSelectOptions)
    return (
      <FieldRegister isLoading={isLoading}>
        <Controller
          name={`criteria.${index}.value`}
          control={control}
          rules={{
            required: {
              value: !getValues(`criteria.${index}.value`),
              message: t('field.mandatoryInfo'),
            },
          }}
          defaultValue={''}
          render={({ field: { ref, ...field }, fieldState }) => (
            <SC.ContainerField>
              <Tooltip arrow title={!getValues(`criteria.${index}.key`) && t('field.tooltip')} placement="top">
                <SC.ContainerField>
                  <SelectField
                    {...field}
                    inputRef={ref}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={!getValues(`criteria.${index}.operator`) || isRequester}
                    label={label}
                    onSelectChange={(_, data) => field.onChange(data.value)}
                    options={valueOptions}
                  />
                </SC.ContainerField>
              </Tooltip>
            </SC.ContainerField>
          )}
        />
      </FieldRegister>
    );

  return (
    <FieldRegister isLoading={isLoading}>
      <Controller
        name={`criteria.${index}.value`}
        control={control}
        rules={{
          required: {
            value: !getValues(`criteria.${index}.value`),
            message: t('field.mandatoryInfo'),
          },
        }}
        defaultValue={''}
        render={({ field: { ref, ...field }, fieldState }) => (
          <SC.ContainerField>
            <Tooltip arrow title={!getValues(`criteria.${index}.key`) && t('field.tooltip')} placement="top">
              <SC.ContainerField>
                {getValues(`criteria.${index}.key`) === ECriteriaKey.REFUNDS ? (
                  <NumericFormat
                    {...field}
                    className="criteria-field-text"
                    prefix={defaultCurrencySymbol}
                    customInput={TextField}
                    disabled={!getValues(`criteria.${index}.operator`)}
                    label={label}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    onFocus={event => event.target.select()}
                    defaultValue={field?.value ? Number(field.value) : undefined}
                    onValueChange={values => field.onChange(String(values.value))}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    value={field?.value ? Number(field.value) : undefined}
                    onChange={() => null}
                  />
                ) : (
                  <TextField
                    {...field}
                    data-hovered={false}
                    className="criteria-field-text"
                    inputRef={ref}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={!getValues(`criteria.${index}.operator`) || isRequester}
                    label={label}
                    onChange={data => field.onChange(data?.target?.value)}
                  />
                )}
              </SC.ContainerField>
            </Tooltip>
          </SC.ContainerField>
        )}
      />
    </FieldRegister>
  );
}
