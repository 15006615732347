import styled from 'styled-components';

interface BorderedWrappingContainerProps {
  borderWidth: number | string;
  borderRadius: number | string;
  color: string;
}

const content = 'fit-content';
const minContent = '1px';

export const Container = styled('div')<BorderedWrappingContainerProps>(
  ({ borderWidth, borderRadius, color, ...props }) => ({
    borderWidth: borderWidth || props.theme.borders.width.xs2,
    borderStyle: 'solid',
    borderColor: color || props.theme.colors.neutral.light.light2,
    borderRadius: borderRadius || props.theme.borders.radius.m,
    minWidth: minContent,
    minHeight: minContent,
    width: content,
    height: content,
  }),
);
