import { useValueFormatter } from './formatters/useValueFormatter';

export function useDistanceHelper() {
  const { getFormatDecimalValue } = useValueFormatter();

  function distanceConvertMetersInKM(_distance: number): number {
    return _distance / 1000;
  }

  function getDistanceDecimalFormatter(_distance: number) {
    return getFormatDecimalValue(_distance || 0);
  }

  return {
    distanceConvertMetersInKM,
    getDistanceDecimalFormatter,
  };
}
