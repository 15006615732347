import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 650px;
`;

export const SelectContent = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacings.xs2};
`;
export const LinkContent = styled.div`
  width: fit-content;
  margin-left: auto;
`;

export const LinkLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PlusIcon = styled(Icons).attrs({
  name: 'IconPlus',
  size: 16,
})`
  margin-left: ${props => props.theme.spacings.xs4};
`;

export const SummaryReportOptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FolderIcon = styled(Icons).attrs({ name: 'IconFolder', size: 24, fill: 'transparent' })`
  margin-right: ${props => props.theme.spacings.xs4};
`;

export const DescriptionOption = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600;
`;
