import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Label = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600 !important;
  margin-left: ${({ theme }) => theme.spacings.xs5};
  color: ${({ theme }) => theme.colors.neutral[50]};
  width: 100%;
`;

export const Link = styled.a`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary[50]};
  text-decoration: underline;
  cursor: pointer;
`;
