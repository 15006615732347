import useTranslate from '@locale/useTranslate';
import { showToast } from '@shared/helpers/toast';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';
import { mutate } from 'swr';

import { deleteAdvancesMutation } from '../context/queries/advances';
import { IDeleteAdvanceRequest, IDeleteAdvanceResponse } from '../context/types/advance';

export type TAccessScreen =
  | 'reportDetails'
  | 'advances'
  | 'financialReportDetails'
  | 'advanceDetails'
  | 'financialAdvanceDetails';

export function useDeleteBatchAdvances() {
  const t = useTranslate();
  const currentPath = useCurrentPath();
  const [deleteAdvanceMutate, { loading, error }] = useMutation<IDeleteAdvanceResponse, IDeleteAdvanceRequest>(
    deleteAdvancesMutation,
  );

  function getRefetch() {
    if (currentPath.includes('reports/details')) return ['GetReportById', 'GetAdvances'];
    else if (currentPath.includes('approvals')) return ['GetAdvancesForApproval'];
    else if (currentPath.includes('accounting')) return [];

    return ['GetAdvances'];
  }

  const deleteAdvances = async (advanceIds: number[]) => {
    try {
      const response = await deleteAdvanceMutate({
        variables: {
          advanceIds,
        },
        refetchQueries: getRefetch(),
      });
      const successItems: boolean = !Boolean(response?.errors);
      if (successItems) {
        showToast({
          message: t(`organisms.advances.delete.successMessage.${advanceIds.length > 1 ? 'plural' : 'singular'}`),
          type: 'success',
        });
      } else {
        response.errors?.forEach(error => {
          showToast({
            message: error.message,
            type: 'error',
          });
        });
      }

      if (currentPath.includes('accounting')) {
        mutate(key => typeof key === 'string' && key.includes('v1/advances/finance?'));
        mutate(key => typeof key === 'string' && key.includes('v1/advances/finance/summary'));
      }
    } catch (e) {
      showToast({ message: e.message, type: 'error' });
    }
  };

  return {
    deleteAdvances,
    error,
    isLoading: loading,
  };
}
