import { ECriteriaStatement, ICriteria } from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import { ApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import {
  IconButton,
  Icons,
  IconTypes,
  LinkButton,
  ShapeIcon,
  Skeleton,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTheme } from 'styled-components';

import {
  ApprovalCriteriaConditionalRow,
  ICriteriaOptionsList,
} from './ApprovalCriteriaConditionalRow/ApprovalCriteriaConditionalRow';
import * as SC from './styled';

export interface IProps {
  isLoading?: boolean;
  options: ICriteriaOptionsList;
}

export function ApprovalCriteria({ options, isLoading }: IProps) {
  const t = useTranslate('approvalFlows.approvalCriteria');
  const { colors } = useTheme();
  const { control, clearErrors } = useFormContext<ApprovalFlowForm>();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'criteria',
    keyName: '_id',
  });
  const watch = useWatch();
  const addDisabled = watch?.criteria?.[fields.length - 1]?.value;

  function onChange(index: number, value: ICriteria) {
    update(index, value);
    clearErrors();
  }

  return (
    <SC.Container>
      <SectionContainer title={t('title')} caption={t('caption')}>
        <SC.Content>
          {fields?.map((_criteria, index) => {
            const identifier = `IconNumber${index + 1}` as IconTypes;
            return (
              <SC.ConditionalRow key={`${_criteria._id}-${index}`}>
                <SC.ConditionalIdentifier>
                  {isLoading ? (
                    <Skeleton variant="circular" width={32} height={32} />
                  ) : (
                    <ShapeIcon name={identifier} variant="default" color={colors.secondary[50]} size={32} />
                  )}
                </SC.ConditionalIdentifier>
                <ApprovalCriteriaConditionalRow
                  options={options}
                  onChange={onChange}
                  index={index}
                  criteria={_criteria}
                  isLoading={isLoading}
                />
                {index > 0 && (
                  <SC.ContainerTrash>
                    <IconButton icon="IconTrash" variant="line" size="small" onClick={() => remove(index)} />
                  </SC.ContainerTrash>
                )}
              </SC.ConditionalRow>
            );
          })}
          {control?._formValues?.criteria?.length <= 9 && (
            <div>
              <Tooltip arrow title={!addDisabled && t('tooltipLink')} placement="top" style={{ width: 'fit-content' }}>
                <div>
                  <LinkButton variant="default" disabled={!addDisabled} style={{ width: 'fit-content' }}>
                    <SC.LinkContent
                      onClick={() =>
                        append({
                          statement: ECriteriaStatement.AND,
                          key: null,
                          operator: null,
                          type: null,
                          value: null,
                        })
                      }>
                      {t('link')}
                      <Icons name="IconPlus" fill="transparent" size={18} />
                    </SC.LinkContent>
                  </LinkButton>
                </div>
              </Tooltip>
            </div>
          )}
        </SC.Content>
      </SectionContainer>
    </SC.Container>
  );
}
