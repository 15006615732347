import { IEstablishment } from '@containers/Expenses/context/types/establishments';

interface IRote extends IEstablishment {
  id?: string;
}

export function useDeleteEmptyRoutes(): (routes: IRote[]) => IEstablishment[] {
  const _routes: IEstablishment[] = [];

  function deleteEmptyRoutes(routes: IRote[]): IEstablishment[] {
    routes.forEach(route => {
      delete route?.id;
      delete route?.__typename;

      if (route?.placeId) _routes.push(route);
    });

    return _routes;
  }

  return deleteEmptyRoutes;
}
