import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  padding: theme.spacings.xs,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  borderRadius: theme.borders.radius.s,
  display: 'flex',
  gap: theme.spacings.xs,
  alignItems: 'center',
  height: 'min-content',
  width: '100%',
}));

export const DescriptionContent = styled.div({
  width: '100%',
});

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[50],
  marginBottom: theme.spacings.xs5,
  fontWeight: '700!important',
  fontSize: '16px',
  lineHeight: '18.55px',
}));

export const Description = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[30],
  fontWeight: '600!important',
}));

export const Icon = styled(Icons)(({ theme }) => ({
  color: theme.colors.primary,
}));
