import React from 'react';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { StatusTag } from '@atoms/StatusTag';
import { EReportsStatusColorVariant } from '@containers/Approvals/context/types/reports';
import { getReportStatusTranslator } from '@containers/Reports/common';
import { IReportSummaries } from '@containers/Reports/context/types';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';

import { ApprovalsReportGridActions } from '../ApprovalsReportGridActions/ApprovalsReportGridActions';
import * as SC from '../styled';
import { EAccessor } from './useHeaderGridColumns';

export type TGridRow = Record<EAccessor, React.ReactNode>;

export interface UseBodyGridRowsProps {
  reports: IReportSummaries[];
  selectedItems: (number | string)[];
  onSelectItemClick(id: IReportSummaries['id']): void;
  onReproveClick?(id: IReportSummaries['id']): void;
  onApproveClick?(id: IReportSummaries['id']): void;
  onExportPdfClick?(id: IReportSummaries['id']): void;
  onExportPdfByCategoryClick?(id: IReportSummaries['id']): void;
  exportingItems?: IReportSummaries['id'][];
  onViewClick?(reportsSelected: IReportSummaries['id']): void;
}

export function useBodyGridRows({
  reports,
  selectedItems,
  onSelectItemClick,
  onViewClick,
  onReproveClick,
  onApproveClick,
  onExportPdfClick,
  onExportPdfByCategoryClick,
  exportingItems,
}: UseBodyGridRowsProps): TGridRow[] {
  const { t } = useTranslation();
  const { getValueWithCurrency } = useValueFormatter();
  const { getDateOnlyFormatter } = useDateFormatter();
  const empty: string = '-';

  return reports.map(report => {
    return {
      selection: <Checkbox onChange={() => onSelectItemClick(report.id)} checked={selectedItems.includes(report.id)} />,
      name: (
        <SC.ReportNameContainer>
          <ShapeIcon
            size="medium"
            icon={'IconFolder'}
            variant={EReportsStatusColorVariant[report.status] || 'neutral'}
          />
          <SC.TdDescription>{`${report.name} | ${report.referenceId}`}</SC.TdDescription>
        </SC.ReportNameContainer>
      ),
      requester: <SC.TdDescription>{report.user?.name || empty}</SC.TdDescription>,
      value: <SC.TdDescription>{getValueWithCurrency({ value: report.value })}</SC.TdDescription>,
      status: (
        <StatusTag variant={EReportsStatusColorVariant[report.status]}>
          {t(getReportStatusTranslator(report.status))}
        </StatusTag>
      ),
      period: (
        <SC.TdDescription>{`${getDateOnlyFormatter(report.activePeriod.startDate)} - ${getDateOnlyFormatter(
          report.activePeriod.endDate,
        )}`}</SC.TdDescription>
      ),
      actions: (
        <ApprovalsReportGridActions
          isLoadingMenu={exportingItems.includes(report.id)}
          status={report.status}
          reportId={report.id}
          onViewClick={onViewClick}
          onReproveClick={onReproveClick}
          onApproveClick={onApproveClick}
          onExportPdfClick={onExportPdfClick}
          onExportPdfByCategoryClick={onExportPdfByCategoryClick}
        />
      ),
    };
  });
}
