import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs,
  alignItems: 'center',
}));

export const Icon = styled(Icons)(({ theme }) => ({
  color: theme.colors.neutral[60],
  transform: 'rotate(-45deg)',
  padding: `${theme.spacings.xs4} 0`,
}));

export const ContainerIcon = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
