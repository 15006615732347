import { useState } from 'react';
import { Drawer } from '@atoms/Drawer';
import { StatusTag } from '@atoms/StatusTag';
import { IApproversTarget } from '@containers/Reports/context/types/approvers';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ApproversDrawer } from './ApproversDrawer/ApproversDrawer';
import * as SC from './styled';
import { TagApprover } from './TagApprover/TagApprover';

interface IProps {
  approvers: IApproversTarget[];
}

export function ApproversByReport({ approvers }: IProps) {
  const t = useTranslate('report');
  const [open, setOpen] = useState(false);

  return (
    <SC.Container>
      <SC.TagsContainer>
        {[...approvers]
          ?.sort((firstItem, secondItem) => firstItem.level - secondItem.level)
          ?.slice(0, 2)
          ?.map((approver, index) => (
            <TagApprover key={index} approver={approver} />
          ))}
        {approvers.length > 2 && (
          <SC.OpenButtonContainer onClick={() => setOpen(true)}>
            <StatusTag variant="neutral">
              <SC.TagContent>{`+${approvers.length - 2}`}</SC.TagContent>
            </StatusTag>
          </SC.OpenButtonContainer>
        )}
      </SC.TagsContainer>
      <SC.Caption variant="body4">{t('approvers')}</SC.Caption>
      <SC.DrawerContainer>
        <Drawer
          isOpen={open}
          drawerWidth={670}
          renderDrawer={<ApproversDrawer approvers={approvers} onClose={() => setOpen(false)} />}
        />
        {open && <SC.Backdrop onClick={() => setOpen(false)} />}
      </SC.DrawerContainer>
    </SC.Container>
  );
}
