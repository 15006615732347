import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export interface TextAreaProps {
  error: boolean;
}

export const Container = styled.div`
  width: 100%;
  textarea {
    ::placeholder {
      font-weight: 600;
      font-size: 16px;
    }
    &:focus-visible {
      outline: none;
      border: ${props => props.theme.borders.width.xs2} solid ${props => props.theme.colors.secondary[70]};
    }
  }
`;

export const TextArea = styled.textarea<TextAreaProps>`
  border: ${props => props.theme.borders.width.xs2} solid
    ${props => (props.error ? props.theme.colors.feedback.error[50] : props.theme.colors.neutral[70])};
  background-color: ${props => (props.error ? props.theme.colors.feedback.error[90] : props.theme.colors.neutral[100])};
  border-radius: ${props => props.theme.borders.radius.m};
  padding: ${props => props.theme.spacings.xs2} ${props => props.theme.spacings.xs3};
  width: 100%;
`;

export const TextAreaContent = styled(Typography)`
  color: ${props => props.theme.colors.neutral[40]};
  font-weight: 600;
`;

export const HelperTextContainer = styled.div`
  position: absolute;
  left: 16px;
  fill: transparent;
  display: grid;
  grid-template-columns: min-content min-content;
  gap: ${props => props.theme.spacings.xs5};
  white-space: nowrap;
`;

export const HelperText = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
  font-weight: 600;
`;

export const IconInfoCircle = styled(Icons)`
  color: ${props => props.theme.colors.feedback.error[50]};
`;
