import { useContext } from 'react';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { useFetch } from '@shared/hooks/service/useFetch';

interface IExpenseReportSettings {
  expirationDateType: 'MANUAL' | 'AUTOMATIC';
  expirationDateFlag: boolean;
  hasFinancialIntegration: boolean;
}

export function useReportApprovalSettings() {
  const { companyId } = useContext(SessionContext);
  const { data, error, isLoading } = useFetch<IExpenseReportSettings>(
    `v1/settings/expense/report/defined?company=${companyId}`,
  );

  return {
    expirationDateType: data?.expirationDateType,
    expirationDateFlag: data?.expirationDateFlag,
    hasFinancialIntegration: data?.hasFinancialIntegration,
    isLoading,
    error,
  };
}
