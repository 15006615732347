import { useEffect } from 'react';
import { DataGrid, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { GridPagination, GridPaginationProps } from '@molecules/GridPagination';
import { usePagination } from '@molecules/GridPagination/kooks/usePagination';

import { BatchActions, IBatchActionsActions } from './BatchActions/BatchActions';
import * as SC from './styled';

export interface IGridColumn {
  Header: React.ReactNode;
  accessor: string;
  disableSortBy: boolean;
  sticky: boolean | string;
}

export interface IGridProps {
  columns: IGridColumn[];
  rows: object[];
  pageCount?: number;
  isLoading: boolean;
  hasPagination?: boolean;
  totalItems?: number;
  currentPage?: number;
  rowsPerPage?: number;
  rowsLoading?: number;
  paginationIsDisabled?: boolean;
  showRowsPerPageOptions?: boolean;
  batchActions?: IBatchActionsActions[];
  numberItemsSelected?: number;
  filters?: React.ReactNode[];
  emptyState?: {
    isFiltered?: boolean;
    message?: string;
    children?: React.ReactNode;
  };
  onPageClick?: (page: number) => void;
  onRowsPerPageClick?: (itemsPerPage: number) => void;
}

export function Grid({
  columns,
  rows,
  pageCount = 200,
  hasPagination = true,
  currentPage,
  totalItems,
  rowsPerPage,
  rowsLoading = 6,
  isLoading,
  paginationIsDisabled = false,
  showRowsPerPageOptions = true,
  batchActions = [],
  numberItemsSelected = 0,
  filters,
  emptyState,
  onPageClick,
  onRowsPerPageClick,
}: IGridProps) {
  const _pagination = usePagination({ currentPage: 1 || currentPage, itemsPerPageShow: rowsPerPage });
  const paginationData: GridPaginationProps = {
    ..._pagination,
    isLoading,
    totalItems,
    isDisabled: paginationIsDisabled,
    onPageClick: _page => {
      _pagination.onPageClick(_page);
      onPageClick(_page);
    },
    onRowsPerPageClick: _itemsPerPage => {
      _pagination.onRowsPerPageClick(_itemsPerPage);
      onRowsPerPageClick(_itemsPerPage);
    },
  };

  function getRowIsLoading() {
    const row = {};

    columns.map(column => {
      switch (column.accessor) {
        case 'selectAll':
        case 'selection':
        case 'select':
          row[column.accessor] = <Skeleton variant="rounded" style={{ marginLeft: '10px' }} height={24} width={24} />;
          break;
        case 'action':
        case 'actions':
          row[column.accessor] = <Skeleton variant="circular" height={40} width={40} />;
          break;
        default:
          row[column.accessor] = <Skeleton variant="text" height={22} width={110} />;
          break;
      }
    });

    return new Array(isLoading ? rowsLoading : rowsPerPage || rowsLoading).fill({}).map(() => row);
  }

  useEffect(() => {
    if (currentPage && _pagination?.selectedPage !== currentPage) _pagination?.onPageClick(currentPage);
  }, [currentPage]);

  return (
    <SC.Container>
      {filters && <>{filters}</>}
      <SC.GridContent>
        {numberItemsSelected > 0 && (
          <BatchActions actions={batchActions} numberItemsSelected={numberItemsSelected} totalItems={totalItems} />
        )}
        <DataGrid
          columns={columns}
          data={isLoading && columns.length ? getRowIsLoading() : rows}
          loading={!Boolean(columns.length) && isLoading}
          hasPagination={hasPagination && totalItems > 0}
          pageCount={!hasPagination && pageCount}
          emptyState={emptyState}
          customPagination={data =>
            hasPagination &&
            totalItems > 0 && (
              <GridPagination
                {...paginationData}
                setPageSize={data.setPageSize}
                isLoading={isLoading}
                showRowsPerPageOptions={showRowsPerPageOptions}
              />
            )
          }
        />
      </SC.GridContent>
    </SC.Container>
  );
}
