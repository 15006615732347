export interface IConfigurationAdvancesSetting {
  //Numbering and Sequence
  prefixValue?: string;
  startValue?: string;

  //General rules
  allowsMultipleAdvancesPerReport: boolean;
  approveOwnAdvance: boolean;
  includeAdvanceBankDetails: boolean;
  enableAdvanceBatch: boolean;
  allowsLinkingExpenseReturnOfAdvanceWithoutLinkingAdvances: boolean;

  //Customize down payment posting fields
  costCenter: IAdvanceFieldControl;
  approver: IAdvanceFieldControl;
  report: IAdvanceFieldControl;
  client: IAdvanceFieldControl;
  project: IAdvanceFieldControl;
  currency: IAdvanceFieldControl;
  paymentMethod: IAdvanceFieldControl;

  //PDF options
  allowIncludeBankDetailsPdf: boolean;
  allowIncludeSignaturePdf: boolean;
  allowIncludeReportHistoryPdf: boolean;
}

export class IAdvanceFieldControl {
  active: boolean;
  required: boolean;
}

export enum ELicenseFeaturesAdvanceSettings {
  costCenter = 'INTERMEDIATE_COST_CENTER',
  approver = 'BASIC_ADVANCE',
  report = 'BASIC_REPORTS',
  currency = 'BASIC_EXPENSE',
  client = 'INTERMEDIATE_CLIENT',
  project = 'INTERMEDIATE_PROJECT',
  paymentMethod = 'INTERMEDIATE_PAYMENT_METHOD',
}
