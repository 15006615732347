import { IconTypes, ShapeIcon, ShapeIconOptions } from '@flash-tecnologia/hros-web-ui-v2';
import { Avatar } from '@molecules/Avatar/Avatar';
import { useTheme } from 'styled-components';

import { IRowDescriptionProps, TextColumn } from '../TextColumn/TextColumn';
import * as SC from './styled';

export interface IProps {
  icon?: {
    name: IconTypes;
    variant: ShapeIconOptions;
    strokeWidth?: string;
  };
  image?: {
    url?: string;
    alt: string;
  };
  title: {
    description: IRowDescriptionProps['description'];
    tooltip?: IRowDescriptionProps['tooltip'];
  };
  caption?: IRowDescriptionProps['caption'];
}

export function MainRowColumn({ image, icon, title, caption }: IProps) {
  const { colors } = useTheme();

  return (
    <SC.Container>
      {!icon?.name && (image?.url || image?.alt) && <Avatar src={image?.url || ''} alt={image.alt} size={40} />}
      {!image?.url && icon?.name && (
        <ShapeIcon
          className="shape-icon-table"
          name={icon.name}
          size={40}
          variant={icon.variant}
          stroke={icon.variant}
          strokeWidth={icon.strokeWidth || '1.6'}
        />
      )}
      {title.description && (
        <TextColumn {...title} caption={{ ...caption, color: caption.color || colors.neutral[50] }} />
      )}
    </SC.Container>
  );
}
