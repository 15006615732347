import { useEffect, useState } from 'react';
import { useAuth } from '@flash-hros/auth-helper';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import { useCheckSignedContracts } from '../../../routes/hooks/useCheckSignedContracts';
import { useTermsInLS } from '../../../routes/hooks/useTermsInLS';
import { ICompanyLicenseAcceptanceTerm } from '../context/types/contracts';

export function useContractsHelper(shouldFetch: boolean = false) {
  const { setTermsInLS } = useTermsInLS();
  const checkSignedContracts = useCheckSignedContracts();
  const [subscriptionProcessIsLoading, setSubscriptionProcessIsLoading] = useState(false);

  const { data, error, isLoading } = useFetch<{
    contracts: ICompanyLicenseAcceptanceTerm['document'][];
    features: string[];
  }>(
    shouldFetch ? 'v1/users/me/contracts' : null,
    undefined,
    undefined,
    undefined,
    () => !!shouldFetch && showToast({ message: `${error.message}`, type: 'error' }),
    shouldFetch,
  );

  async function saveInfos() {
    setSubscriptionProcessIsLoading(true);
    setTermsInLS(data?.contracts || []);
    await useAuth.getState().setExpenseContracts(data?.contracts || []);
    await useAuth.getState().setPlanFeatures(data?.features || []);
    setSubscriptionProcessIsLoading(false);
  }

  useEffect(() => {
    if (data && !isLoading && shouldFetch) {
      saveInfos();
    }
  }, [isLoading, data, shouldFetch]);

  return {
    expensesDataBase: data,
    isLoading: subscriptionProcessIsLoading || isLoading,
    acceptedAllContracts: checkSignedContracts(data?.contracts || []),
  };
}
