import React from 'react';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

interface IconButtonAreaProps {
  icon: IconTypes;
  children: React.ReactNode;
  fullWidth?: boolean;
  onClick(): void;
}

export const IconButtonArea = ({ icon, children, fullWidth, onClick }: IconButtonAreaProps) => {
  return (
    <SC.Button fullWidth={fullWidth} onClick={onClick}>
      <ShapeIcon icon={icon} variant="secondary" size="medium" />
      <SC.ButtonTypography variant="body3">{children}</SC.ButtonTypography>
    </SC.Button>
  );
};
