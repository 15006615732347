import * as SC from './styled';

export interface ReceiptsImagesSkeletonProps {
  size: SC.SizeProps['size'];
}

export const ReceiptsImagesSkeleton = ({ size }: ReceiptsImagesSkeletonProps) => {
  return (
    <SC.Container size={size}>
      <SC.SkeletonReceipt variant="rounded" size={size} />
    </SC.Container>
  );
};
