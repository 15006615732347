import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  Container,
  ExpenseCollapseSummary,
  ExpenseCollapseSummaryLabel,
  ExpenseCollapseSummaryLabelValueGroup,
  ExpenseShowButtonContainer,
} from './styled';

export const ExpenseDetailsCollapseSkeleton = () => {
  const { t } = useTranslation();

  return (
    <BorderedWrapping>
      <Container>
        <ExpenseCollapseSummary>
          <ExpenseCollapseSummaryLabelValueGroup>
            <Skeleton variant="text" width={155} height={70} />
            <ExpenseCollapseSummaryLabel variant="body4">
              {t('molecules.expenseCollapse.value')}
            </ExpenseCollapseSummaryLabel>
          </ExpenseCollapseSummaryLabelValueGroup>
          <ExpenseCollapseSummaryLabelValueGroup>
            <Skeleton variant="text" width={200} height={35} />
            <ExpenseCollapseSummaryLabel variant="body4">
              {t('molecules.expenseCollapse.establishment')}
            </ExpenseCollapseSummaryLabel>
          </ExpenseCollapseSummaryLabelValueGroup>
          <ExpenseCollapseSummaryLabelValueGroup>
            <Skeleton variant="text" width={115} height={35} />
            <ExpenseCollapseSummaryLabel variant="body4">
              {t('molecules.expenseCollapse.date')}
            </ExpenseCollapseSummaryLabel>
          </ExpenseCollapseSummaryLabelValueGroup>
        </ExpenseCollapseSummary>
        <ExpenseShowButtonContainer>
          <Skeleton variant="text" width={96} height={35} />
        </ExpenseShowButtonContainer>
      </Container>
    </BorderedWrapping>
  );
};
