import { GridPagination, IRowsPerPageOption } from '../../../../../components/molecules/GridPagination';
import { EOrderBy } from '../../../../Expenses/context/types/expenses';
import { IReport } from '../../../context/types/report';
import { useReportBodyGridRows } from '../../../hooks/useReportBodyGridRows';
import { useBodyGridRowSkeleton } from '../../../hooks/useReportBodyGridRowSkeleton';
import { EAccessor, useReportHeaderGrid } from '../../../hooks/useReportGridHeader';
import { useHeaderGridSkeleton } from '../../../hooks/useReportGridHeaderSkeleton';
import { Grid, GridContainer, ReportGridContainer } from '../../styled';

export interface IGridPagination {
  totalItems: number;
  rowsPerPage: number;
  selectedPage: number;
  rowsPerPageOptions?: IRowsPerPageOption[];
  onPageClick(page: number): void;
  onRowsPerPageClick(itemsPerPage: number): void;
  isLoading: boolean;
}

export interface ReportGridProps {
  reports: IReport[];
  isLoading: boolean;
  pagination: IGridPagination;
  selectedItems: number[];
  isSelectedAll: boolean;
  onDeleteReport(id: IReport['id']): void;
  onViewReport(id: IReport['id']): void;
  onEditReport(id: IReport['id']): void;
  onOrderByClick(accessor: EAccessor, order: EOrderBy): void;
  onSelectAllClick(): void;
  onSelectReport(id: IReport['id']): void;
}

export const ReportGrid = ({
  reports,
  isLoading,
  pagination,
  selectedItems,
  isSelectedAll,
  onDeleteReport,
  onOrderByClick,
  onSelectAllClick,
  onSelectReport,
  onViewReport,
  onEditReport,
}: ReportGridProps) => {
  const headerGrid = useReportHeaderGrid({
    onOrderByClick,
    onSelectAllClick,
    isSelectedAll,
  });
  const bodyGridRows = useReportBodyGridRows({
    reports,
    selectedReports: selectedItems,
    onSelectReport,
    onDeleteReport,
    onViewReport,
    onEditReport,
  });
  const bodyGridRowSkeleton = useBodyGridRowSkeleton({ rowsPerPage: pagination.rowsPerPage });
  const headerGridSkeleton = useHeaderGridSkeleton();

  return (
    <ReportGridContainer>
      <GridContainer>
        <Grid
          columns={isLoading ? headerGridSkeleton : headerGrid}
          data={isLoading ? bodyGridRowSkeleton : bodyGridRows}
          hasPagination
          customPagination={data =>
            Boolean(pagination) && (
              <GridPagination
                {...pagination}
                setPageSize={data.setPageSize}
                isLoading={isLoading}
                showRowsPerPageOptions={false}
              />
            )
          }
        />
      </GridContainer>
    </ReportGridContainer>
  );
};
