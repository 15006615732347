import { StatusTag } from '@atoms/StatusTag';
import { ILimitGrid } from '@containers/Customizations/context/types/limits';
import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from 'react-i18next';

import { ActionOptions, IconAction } from '../LimitsGridActions';
import * as SC from './styled';

interface ActionsMultipleLimitsSelectedProps {
  selectedItems?: ILimitGrid['id'][];
  totalItems?: number;
  enableMultiple?: boolean;
  onDisableClick?(limitsSelected: ILimitGrid['id'][], active: boolean): void;
  onDeleteClick?(limitsSelected: ILimitGrid['id'][]): void;
}

export function ActionsMultipleLimitsSelected({
  selectedItems,
  totalItems,
  enableMultiple,
  onDisableClick,
  onDeleteClick,
}: ActionsMultipleLimitsSelectedProps) {
  const { t } = useTranslation();

  const events = {
    disableEnable: () => onDisableClick(selectedItems, enableMultiple),
    delete: () => onDeleteClick(selectedItems),
  };

  return (
    <SC.Container>
      <StatusTag variant="secondary">
        {t('organisms.limitGrid.selectedItems', { totalItems, selectedItems: selectedItems?.length })}
      </StatusTag>
      {Object.keys(ActionOptions).map((action, key) => {
        if (action == 'EDIT') {
          return;
        }
        let actionLabel: string;
        if (ActionOptions[action] == ActionOptions.DISABLE_ENABLE) {
          actionLabel = enableMultiple ? 'enable' : 'disable';
        } else {
          actionLabel = ActionOptions[action];
        }
        return (
          <div key={key}>
            <LinkButton variant="default">
              <SC.LinkContent onClick={events[ActionOptions[action]]}>
                <Icons name={IconAction[action]} fill="transparent" />
                {t(`organisms.limitGrid.actionsOptions.${actionLabel}`)}
              </SC.LinkContent>
            </LinkButton>
          </div>
        );
      })}
    </SC.Container>
  );
}
