import { Navigate } from 'react-router-dom';

import { usePathToRedirectAfterLogin } from './hooks/usePathToRedirectAfterLogin';
interface IProps {
  backToCurrentPage?: boolean;
}

export function PlatformEjector({ backToCurrentPage = false }: IProps) {
  const redirectAfterLogin = usePathToRedirectAfterLogin(backToCurrentPage);
  return <Navigate to={redirectAfterLogin} />;
}
