import { useState } from 'react';

import { IReport } from '../context/types/report';

export interface UseSelectionRowsProps {
  reports: IReport[];
}

export const useSelectionRows = ({ reports }: UseSelectionRowsProps) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const isSelectedAll: boolean = selectedItems?.length == reports?.length;

  function selectAll() {
    setSelectedItems(reports.map(e => e.id));
  }

  function unSelectAll() {
    setSelectedItems([]);
  }

  function toggleSelectAll() {
    if (isSelectedAll) unSelectAll();
    else selectAll();
  }

  function onSelectItemClick(itemId: number) {
    const isIdSelected = selectedItems.includes(itemId);

    if (isIdSelected) {
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  }

  return {
    selectedItems,
    isSelectedAll,
    onSelectItemClick,
    selectAll,
    unSelectAll,
    toggleSelectAll,
  };
};
