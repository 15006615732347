import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getUsersForSelection } from '../context/queries/users';
import { IGetUserSelectionResponse, IUserSelection } from '../context/types/users';

interface Users {
  users: IUserSelection[];
  total: number;
  isLoading: boolean;
  error: unknown;
}

export function useUsersSelectionList(): Users {
  const { loading: isLoading, data, error } = useQuery<IGetUserSelectionResponse>(getUsersForSelection);

  return {
    users: data?.getUsersForSelection?.userSelections || [],
    total: data?.getUsersForSelection?.total,
    isLoading,
    error,
  };
}
