import { Skeleton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 100%;
`;

export const FieldContainer = styled.div`
  width: 100%;

  .helperText,
  p.warning {
    position: absolute;
    margin-top: 3px;
  }

  .MuiFilledInput-root {
    border-width: ${({ theme }) => theme.borders.width.xs2}!important;
  }

  .select-custom .select-field-custom-theme div.Mui-disabled {
    background-color: transparent !important;
  }
`;

export const ToggleContainer = styled.div`
  display: grid;
  gap: ${props => props.theme.spacings.xs3};
  grid-template-columns: min-content 1fr;
`;

export const ToggleLabel = styled(Typography)`
  font-weight: 600 !important;
  color: ${props => props.theme.colors.neutral[30]};
`;

export const SkeletonField = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borders.radius.m};
`;

export const ContainerSkeletonData = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacings.xs5};
`;

export const SkeletonLabel = styled(Typography)`
  font-weight: 600 !important;
  color: ${({ theme }) => theme.colors.neutral[70]};
  padding: ${({ theme }) => theme.spacings.xs3} ${({ theme }) => theme.spacings.xs2};
  visibility: visible;
`;

export const SkeletonDescription = styled(Typography)`
  font-weight: 600 !important;
  color: ${({ theme }) => theme.colors.neutral[70]};
  visibility: visible;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacings.xs2};
`;
