import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

export interface DropdownItemProps {
  /**
   * Sets item  description
   */
  description?: string;

  /**
   * Sets item  icon
   */
  icon?: IconTypes;

  /**
   * if `true`, the order of items will be inverted
   */
  invert?: boolean;

  /**
   * if "true" apply opacity
   */
  disabled?: boolean;

  /**
   * event click
   */
  onClick?: () => void;
}

export const DropdownItem = ({ description, icon, invert, disabled = false, onClick }: DropdownItemProps) => {
  return (
    <SC.DropdownItemContainer invert={invert} disabled={disabled} onClick={() => onClick?.()}>
      {description && <SC.Description>{description}</SC.Description>}
      {icon && <SC.Icon name={icon} invert={invert} />}
    </SC.DropdownItemContainer>
  );
};
