import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import { BreadcrumbPage } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { approvalFlowsBaseURL, expensesApprovalsBaseURL, expensesCustomizationsBaseURL } from '@shared/constant';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { EFlowStep } from '../CustomApprovalFlowRegister';
import * as SC from './styled';

interface ClientRegisterProgressProps {
  step: EFlowStep;
  isEdit: boolean;
}

export const CustomApprovalFlowRegisterProgressHeader = ({ step, isEdit }: ClientRegisterProgressProps) => {
  const t = useTranslate('approvalFlows.pageRegister');
  const currentPath = useCurrentPath();
  const isApprovalReport = currentPath.includes('reports');

  return (
    <SC.Container>
      <BreadcrumbPage
        items={[
          { label: t('breadcrumb.approvals'), to: expensesApprovalsBaseURL },
          { label: t('breadcrumb.manageApprovals'), to: expensesCustomizationsBaseURL },
          {
            label: t(isApprovalReport ? 'breadcrumb.report' : 'breadcrumb.advance'),
            to: isApprovalReport ? `${approvalFlowsBaseURL}/reports` : `${approvalFlowsBaseURL}/advances`,
          },
          { label: t(`breadcrumb.${isEdit ? 'edit' : 'create'}`), to: '' },
        ]}
      />
      <Stepper
        activeStep={step === EFlowStep.BASIC_INFORMATION ? 0 : 1}
        disableClick={true}
        steps={[t('progress.basicInformation'), t('progress.approvalType')]}
      />
    </SC.Container>
  );
};
