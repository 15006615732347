import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import { IConfigurationProject } from '../../context/types/projectTypes';

export function useProjectById(projectId: string) {
  const { t } = useTranslation();

  const { data, error, isLoading } = useFetch<IConfigurationProject>(
    `v1/projects/${projectId}`,
    undefined,
    undefined,
    undefined,
    () => !!projectId && showToast({ message: t('pages.projectRegister.feedbacks.gettingOne.error'), type: 'error' }),
    !!projectId,
  );

  return {
    project: data,
    isLoading,
    error,
  };
}
