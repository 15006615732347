import { SelectField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs2};
  grid-row-gap: ${({ theme }) => theme.spacings.xs2};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
  padding-left: ${({ theme }) => theme.spacings.xs3};
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600 !important;
  white-space: nowrap;
`;

export const Select = styled(SelectField)`
  width: 280px;
`;
