import { useCallback, useMemo } from 'react';
import { PolicyOCRConfig } from '@containers/Policies/context/types/ocr';
import { useSaveOCRPolicy } from '@containers/Policies/hooks/ocr/useSaveOCRPolicy';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { Control, UseFormReturn, useWatch } from 'react-hook-form';

type Props = {
  control: Control<PolicyOCRConfig>;
  setValue: UseFormReturn['setValue'];
  policyId: string;
};

const SelectAllToggle = ({ control, setValue, policyId }: Props) => {
  const { handleUpdate } = useSaveOCRPolicy(policyId);

  const readFields = useWatch({ control, name: 'readFields' });

  const checkState = useMemo(() => {
    if (!readFields) return null;

    const checked = Object.keys(readFields).filter(key => {
      return readFields?.[key] === true;
    });

    if (checked.length === 0) return null;

    if (checked.length === Object.keys(readFields).length) {
      return 'checked';
    }

    return 'indeterminate';
  }, [readFields]);

  const onCheckboxClick = useCallback(() => {
    const newReadFields = {};
    const newValue = checkState === 'checked' ? false : true;

    Object.keys(readFields || {}).forEach(key => {
      setValue('readFields.' + key, newValue);
      newReadFields[key] = newValue;
    });

    handleUpdate({ readFields: newReadFields });
  }, [checkState, readFields, setValue]);

  return (
    <Checkbox
      onChange={onCheckboxClick}
      checked={checkState === 'checked'}
      indeterminate={checkState === 'indeterminate'}
      size="small"
    />
  );
};

export default SelectAllToggle;
