import { gql } from '@apollo/client';

export const getExpenseAnalyticsByCategory = gql`
  query GetExpenseAnalyticsByCategory(
    $exportType: ExportAnalyticsType
    $filters: [AnalyticsFilterFieldInput!]
    $config: AnalyticsConfigArgs
  ) {
    getExpenseAnalyticsByCategory(exportType: $exportType, filters: $filters, config: $config) {
      categories {
        category {
          description
        }
        quantity
        currencyPrefix
        value
      }
      base64Export
      limit
      total
    }
  }
`;

export const getCategories = gql`
  query GetAllCategories {
    getAllLegacyCategories {
      categories {
        id
        active
        description
      }
    }
  }
`;
