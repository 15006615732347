import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface IFieldContainerProps {
  fieldName: string;
}

interface TitleContainerProps {
  step: number;
}

interface ContainerProps {
  hasMaxWidth: boolean;
}

export const Container = styled(BorderedWrapping)<ContainerProps>`
  width: 100%;
  max-width: ${props => (props.hasMaxWidth ? '647px' : '70%')};
  padding: ${props => props.theme.spacings.xs};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
`;

export const Title = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
  font-weight: 700;
`;

export const SubTitle = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
`;

export const RequiredText = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};

  &::before {
    content: '*';
    color: ${props => props.theme.colors.feedback.error[40]};
    margin: ${props => props.theme.spacings.xs5};
  }
`;

export const TitleContainer = styled.div<TitleContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.step === 0 ? 'row' : 'column')};
`;

export const FieldContainer = styled.div<IFieldContainerProps>`
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
  position: relative;
  grid-column: '1 / 3';
  grid-row-start: 'auto';

  .helperText {
    position: absolute;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
`;
