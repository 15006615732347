import { useContext, useRef, useState } from 'react';
import { SubscriptionContext } from '@containers/Subscription/context';
import { Checkbox, Loader } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { CompletionRegistrationModal } from '../../molecules/CompletionRegistrationModal/CompletionRegistrationModal';
import { ContractsModal } from '../../molecules/ContractsModal';
import { useCompletionRegistration } from './hooks/useCompletionRegistration';
import * as SC from './styled';

interface Props {
  userHasAcceptedContracts?: boolean;
  onChangeAccept?(Accepted: boolean): void;
  isLoading?: boolean;
}

export const AcceptSubscriptionCheckbox = ({ userHasAcceptedContracts, onChangeAccept, isLoading }: Props) => {
  const t = useTranslate();

  const contractModalRef = useRef<ContractsModal>(null);

  const [contractIndex, setContractIndex] = useState<number | null>(null);

  const {
    contracts,
    onContractCreationRequest,
    isSigning,
    travelContract,
    corporateCardContract,
    saasServiceContract,
  } = useContext(SubscriptionContext);

  const { isLoadingAcceptance, showSuccessModal, handleSignContract, onFinishSubscription } = useCompletionRegistration(
    {
      onSignSuccess: () => {
        setContractIndex(null);
      },
    },
  );

  function onAction() {
    const isLastContract = contractIndex >= contracts.length - 1;

    if (isLastContract) {
      onChangeAccept(true);

      return handleSignContract();
    }

    contractModalRef.current.scrollToTop();

    return setContractIndex(contractIndex + 1);
  }

  function onCloseModal() {
    setContractIndex(null);
  }

  function onShowContracts() {
    onContractCreationRequest();

    setContractIndex(0);
  }

  function getCheckboxLabelServices() {
    const labels: string[] = [];

    if (corporateCardContract) {
      labels.push(t('molecules.acceptSubscriptionCheckbox.products.corporateCard'));
    }

    if (saasServiceContract) {
      labels.push(t('molecules.acceptSubscriptionCheckbox.products.saas'));
    }

    if (travelContract) {
      labels.push(t('molecules.acceptSubscriptionCheckbox.products.travel'));
    }

    return ` ${labels.join(', ')}.`;
  }

  return (
    <SC.Container>
      {isLoading ? (
        <div style={{ margin: '0 5px' }}>
          <Loader variant="primary" size="small" />
        </div>
      ) : (
        <Checkbox
          checked={userHasAcceptedContracts}
          onChange={(_, checked) => onChangeAccept(checked)}
          disabled={isSigning}
        />
      )}

      <SC.Label>
        {t('molecules.acceptSubscriptionCheckbox.iHaveReadEndAcceptThe')}{' '}
        <SC.Link onClick={onShowContracts}>{t('molecules.acceptSubscriptionCheckbox.commercialConditions')} </SC.Link>{' '}
        {t('molecules.acceptSubscriptionCheckbox.fromFlashServices')}
        {getCheckboxLabelServices()}
      </SC.Label>

      <ContractsModal
        url={contracts?.[contractIndex]?.url}
        open={contractIndex !== null}
        isLoading={isLoading}
        actionIsDisabled={isLoading}
        actionIsLoading={isLoadingAcceptance || isSigning}
        onActionClick={onAction}
        onCancelClick={onCloseModal}
        ref={contractModalRef}
      />

      <CompletionRegistrationModal open={showSuccessModal} onAction={onFinishSubscription} />
    </SC.Container>
  );
};
