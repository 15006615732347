import { Skeleton } from '@mui/material';

import * as SC from './styled';
import { WaypointFieldSkeleton } from './WaypointField/WaypointFieldSkeleton';

interface IProps {
  printItems?: number;
  viewOnly?: boolean;
}

export function WaypointFieldsSkeleton({ printItems = 2, viewOnly }: IProps) {
  return (
    <SC.Container>
      {new Array(printItems).fill({}).map((_, key) => (
        <WaypointFieldSkeleton viewOnly={viewOnly} key={key} />
      ))}
      <SC.AddButtonContainerSkeleton>
        {!viewOnly && (
          <>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="rounded" width={275} height={22} />
          </>
        )}
      </SC.AddButtonContainerSkeleton>
      {!viewOnly && (
        <SC.DistanceRoutesContainer>
          <Skeleton variant="rounded" width={500} height={96} />
        </SC.DistanceRoutesContainer>
      )}
    </SC.Container>
  );
}
