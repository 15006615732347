import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const LabelButtonContent = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs4};
  align-items: center;
`;

export const TitleModalConfirmContainer = styled.div`
  width: 420px;
`;

export const SubtitleModalConfirmContainer = styled.div`
  width: 420px;
`;
