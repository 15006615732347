import { useEffect, useState } from 'react';
import { IPolicy, IPolicyBase } from '@containers/Policies/context/types/types';
import { base64ToObjectURL, withBase64Prefix } from '@shared/helpers/files';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function usePolicyById(id: IPolicyBase['id'], shouldFetch: boolean = true) {
  const t = useTranslate('policy.register.feedbacks.byId');
  const { data, error, isLoading } = useFetch<IPolicy>(
    Boolean(id) ? `v1/policies/${id}` : null,
    undefined,
    undefined,
    undefined,
    error => showToast({ title: t('error.title'), message: error?.message || t('error.message'), type: 'error' }),
    shouldFetch,
  );
  const [policy, setPolicy] = useState<IPolicy>(null);

  async function serializePolicy() {
    const base64 = data?.document?.base64
      ? await base64ToObjectURL(withBase64Prefix(data.document.base64, 'application/pdf'))
      : null;

    setPolicy({ ...data, document: { ...data.document, base64 } });
  }

  useEffect(() => {
    if (!isLoading && data) data.document?.base64 ? serializePolicy() : setPolicy({ ...data });
  }, [JSON.stringify(data)]);

  return {
    policy: policy || data || null,
    isLoading: isLoading && !data,
    error,
  };
}
