import { useState } from 'react';
import { dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { showToast } from '@shared/helpers/toast';

import * as SC from './styled';

interface ExpirationDateModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  reportsNumber: number;
  onActionClick(date?): void;
  onCloseClick(): void;
}

export const ExpirationDateModal = ({
  isOpen,
  isLoading,
  reportsNumber,
  onActionClick,
  onCloseClick,
}: ExpirationDateModalProps) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(null);
  const [error, setError] = useState(false);
  const formOfDescription = reportsNumber > 1 ? 'pluralForm' : 'singularForm';

  const handleActionClick = () => {
    const isValidDate =
      dayjs(date).isSame(dayjs().startOf('day').toDate()) || dayjs(date).isAfter(dayjs().endOf('day'));

    if (!isValidDate) {
      setError(true);
      date && showToast({ message: t('organisms.expirationDateModal.expirationDateValidation'), type: 'error' });
    } else {
      setError(false);
      onActionClick(date);
      setDate(null);
    }
  };

  return (
    <SC.Container>
      <DefaultModal
        open={isOpen}
        isLoading={isLoading}
        onActionClick={handleActionClick}
        onCancelClick={onCloseClick}
        onCloseClick={onCloseClick}
        headerTextAlign={'left'}
        headerTitle={
          <SC.TitleModalConfirmContainer>
            {t(`organisms.expirationDateModal.popupTitle`, {
              reportsSelected: reportsNumber,
            })}
          </SC.TitleModalConfirmContainer>
        }
        headerSubtitle={
          <SC.SubtitleModalConfirmContainer>
            {t(`organisms.expirationDateModal.${formOfDescription}.popupSubtitle`)}
          </SC.SubtitleModalConfirmContainer>
        }
        labelButtonAction={t('organisms.expirationDateModal.conclude')}>
        <SC.Input
          error={error}
          onDateChange={value => {
            setDate?.(value?.format());
            setError(false);
          }}
          helperText={error && !date ? t('organisms.expirationDateModal.requiredField') : null}
        />
      </DefaultModal>
    </SC.Container>
  );
};
