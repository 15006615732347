import { Checkbox, DataGrid, IconButton, Icons, Menu, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const GridContainer = styled.div`
  margin-top: ${props => props.theme.spacings.xs};
  position: relative;

  .data-grid-pagination-select-container {
    .MuiSelect-select {
      min-width: 85px !important;
    }
    .select-icon-custom {
      padding: 10px !important;
      svg {
        right: 5px !important;
        top: 10px !important;
      }
    }
  }
`;

export const Grid = styled(DataGrid)`
  display: flex;
`;

export const RowCheckbox = styled(Checkbox)`
  height: 20px;
  width: 20px;
  div {
    height: 20px;
    width: 20px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const ThContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .menu-base-container-custom-theme {
    > div {
      :first-child {
        display: flex;
      }
    }
  }
`;

export const ThDescription = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 700 !important;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const IconArrowsSort = styled(Icons).attrs({
  name: 'IconArrowsSort',
  size: 16,
})`
  cursor: pointer;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.2);
  }
`;

export const IconDots = styled(IconButton).attrs({
  icon: 'IconDots',
  size: 'small',
  variant: 'line',
})`
  transform: rotate(90deg);
`;

export const TdDescription = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const SortByButton = styled(Menu).attrs({
  type: 'default',
  transformOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
})`
  .MuiPaper-elevation {
    margin: 5px 0;
  }
`;

export const TdContent = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionContent = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionIcon = styled(Icons).attrs({
  size: 22,
  fill: '#fff',
})`
  margin-right: ${props => props.theme.spacings.xs4};
`;

export const GridPaginationContainer = styled.div`
  margin: ${props => props.theme.spacings.s} 0px;
`;

export const CategoriesContainer = styled.div`
  display: flex;
`;
