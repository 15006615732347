import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useTheme } from 'styled-components';

import * as SC from './styled';

interface IProps {
  id?: string;
  step?: number;
  children?: React.ReactNode;
}

export function RegisterPolicyBasePage({ id, step = 0, children }: IProps) {
  const t = useTranslate('policy.register.steps');
  const { spacings } = useTheme();
  const stepName = useMemo(() => {
    if (step === 0) {
      return 'basicInformation';
    } else if (step === 1) {
      return 'policyProperties';
    } else {
      return 'policySummary';
    }
  }, [step]);

  return (
    <Grid container key={`approval-flow-${id}`} gap={spacings.m} width={'100%'}>
      <Grid item gap={spacings.xs} lg={12} xl={3} maxWidth={'389px'}>
        <SC.ContainerInfos>
          <SC.InfoTitle variant="headline7">{t(`${stepName}.title`)}</SC.InfoTitle>
          <SC.InfoDescription variant="body3">{t(`${stepName}.caption`)}</SC.InfoDescription>
        </SC.ContainerInfos>
      </Grid>
      <Grid item gap={spacings.xs} lg={12} xl={8}>
        {children}
      </Grid>
    </Grid>
  );
}
