import { useState } from 'react';
import { InternalRefetchQueriesInclude } from '@apollo/client';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { useDeleteExpenseList } from '@containers/Expenses/hooks/useExpenseMutate';
import { useTranslation } from '@locale/Translator';
import { expensesBaseUrl } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { EAccessScreen, TAccessScreen } from '@shared/types';
import { useNavigate } from 'react-router-dom';

interface IProps {
  unSelectAll?(): void;
  refetchQueries?: boolean;
  accessScreen?: TAccessScreen;
}

export interface UseDeleteExpense {
  deleteIsLoading: boolean;
  expensesToDelete: IExpense['id'][];
  deleteConfirmationIsOpen: boolean;
  onDeleteExpense(selectedItems: IExpense['id'][]): void;
  onCancelDeleteExpenseClick(): void;
  onConfirmDeleteExpenseClick(refetchQueries?: InternalRefetchQueriesInclude): void;
}

export function useDeleteExpense(props?: IProps): UseDeleteExpense {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState<boolean>(false);
  const [expensesToDelete, setExpensesToDelete] = useState<IExpense['id'][]>([]);
  const { deleteExpenseListMutate, isLoading } = useDeleteExpenseList();
  const formOfDescription = expensesToDelete?.length > 1 ? 'pluralForm' : 'singularForm';

  function onDeleteExpense(selectedItems: IExpense['id'][]) {
    setExpensesToDelete(selectedItems);
    setDeleteConfirmationIsOpen(true);
  }

  function onCancelDeleteExpenseClick() {
    setExpensesToDelete([]);
    setDeleteConfirmationIsOpen(false);
  }

  async function onConfirmDeleteExpenseClick(refetchQueries?: InternalRefetchQueriesInclude) {
    try {
      await deleteExpenseListMutate({
        variables: {
          expenses: expensesToDelete,
        },
        refetchQueries,
      });

      showToast({
        message: t(`organisms.expenseGrid.actionsGrid.feedbacks.delete.${formOfDescription}.success`),
        type: 'success',
      });
      setExpensesToDelete([]);
      setDeleteConfirmationIsOpen(false);
      props.unSelectAll?.();

      if (props.accessScreen == EAccessScreen.EXPENSE_DETAILS) {
        navigate(expensesBaseUrl);
      }
    } catch (error) {
      showToast({
        message: error?.message || t(`organisms.expenseGrid.actionsGrid.feedbacks.delete.${formOfDescription}.error`),
        type: 'error',
      });
      setExpensesToDelete([]);
      setDeleteConfirmationIsOpen(false);
    }
  }

  return {
    deleteIsLoading: isLoading,
    expensesToDelete,
    deleteConfirmationIsOpen,
    onDeleteExpense,
    onCancelDeleteExpenseClick,
    onConfirmDeleteExpenseClick,
  };
}
