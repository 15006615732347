import { useState } from 'react';
import { useSerializeExpenseSubmissionData } from '@containers/Expenses/components/organisms/ExpenseRegisterForm/ExpenseFormFields/hooks/useSerializeExpenseSubmissionData';
import { IExpenseInput } from '@containers/Expenses/context/types';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { useCreateExpense, useUpdateExpense } from '@containers/Expenses/hooks/useExpenseMutate';
import { IReport } from '@containers/Reports/context/types';
import { showToast } from '@shared/helpers/toast';
import { useExpenseDetailsDriver } from '@shared/hooks/navigation/useExpenseDetailsDriver';
import { useReportDetailsDriver } from '@shared/hooks/navigation/useReportDetailsDriver';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';
import { useTracking } from '@shared/hooks/useTracking';
import { TAccessScreen } from '@shared/types';

export interface ExpenseCreateProps {
  reportId: IReport['id'];
  expenseData: IExpenseInput;
}
export interface ExpenseUpdateProps {
  reportId: IReport['id'];
  expenseId: IExpense['id'];
  expenseData: IExpenseInput;
}

export interface UseExpenseMutateProps {
  reportId?: IReport['id'];
  expenseId?: IExpense['id'];
  accessScreen?: TAccessScreen;
}

export function useExpenseMutate({ reportId, expenseId, accessScreen }: UseExpenseMutateProps) {
  const [isLoadingMutate, setIsLoadingMutate] = useState(false);
  const trackingEvent = useTracking();
  const navigateExpenseDetails = useExpenseDetailsDriver();
  const expenseType = useCheckExpenseType();

  const {
    createExpenseMutate,
    isLoading: isLoadingCreate,
    error: errorCreate,
  } = useCreateExpense({ reportId, accessScreen });
  const {
    updateExpenseMutate,
    isLoading: isLoadingUpdate,
    error: errorUpdate,
  } = useUpdateExpense({ accessScreen, expenseId, reportId });
  const serializeExpenseSubmissionData = useSerializeExpenseSubmissionData();
  const navigateReportDetails = useReportDetailsDriver();

  async function expenseCreate({ expenseData }: ExpenseCreateProps) {
    setIsLoadingMutate(true);
    try {
      await serializeExpenseSubmissionData({ expenseData, reportId });

      const { data } = await createExpenseMutate({
        variables: {
          expense: expenseData,
        },
      });

      const createdId = data?.createExpense?.id;
      const _expenseType = data?.createExpense?.type;
      setIsLoadingMutate(false);

      trackingEvent('expense_create_success', {
        expenseId: String(createdId),
        reportId: String(reportId),
      });

      if (Boolean(reportId)) {
        navigateReportDetails(reportId);
      } else {
        navigateExpenseDetails({ expenseId: createdId, expenseType: _expenseType || expenseType });
      }
    } catch (error) {
      setIsLoadingMutate(false);
      trackingEvent('expense_create_error', {
        reportId: String(reportId),
        error: error.message,
      });

      showToast({
        message: error?.message,
        type: 'error',
      });
    }
  }

  async function expenseUpdate({ expenseId, expenseData, reportId }: ExpenseUpdateProps) {
    setIsLoadingMutate(true);
    try {
      await serializeExpenseSubmissionData({ expenseData, reportId });

      await updateExpenseMutate({
        variables: {
          expense: expenseData,
          expenseId,
        },
      });

      setIsLoadingMutate(false);
      trackingEvent('expense_update_success', {
        expenseId: String(expenseId),
        reportId: String(reportId),
      });

      if (accessScreen == 'reportDetails' && Boolean(reportId)) {
        navigateReportDetails(reportId);
      } else {
        navigateExpenseDetails({ expenseId, expenseType: expenseData?.type || expenseType });
      }
    } catch (error) {
      setIsLoadingMutate(false);
      trackingEvent('expense_update_error', {
        expenseId: String(expenseId),
        reportId: String(reportId),
        error: error.message,
      });

      showToast({
        message: error?.message,
        type: 'error',
      });
    }
  }

  return {
    isLoading: isLoadingCreate || isLoadingUpdate || isLoadingMutate,
    error: errorCreate?.message || errorUpdate?.message,
    expenseCreate,
    expenseUpdate,
  };
}
