import { SubscriptionInformation } from '@containers/Subscription/components/molecules/SubscriptionInformation/SubscriptionInformation';
import { SAASContractInformation } from '@containers/Subscription/context/types/contractInformation';
import { useSubscriptionPlan } from '@containers/Subscription/hooks/useSubscriptionPlan';
import { Box } from '@mui/material';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { useSAASContractConditions } from '../hooks/useSAASContractConditions';

type Props = {
  saasContract?: SAASContractInformation;
};

export const SAASCommercialConditions = ({ saasContract }: Props) => {
  const t = useTranslate('organisms.subscriptionDetails');

  const { saasContractItems, saasContractCaption } = useSAASContractConditions(saasContract);

  const { planName } = useSubscriptionPlan();

  return (
    <SubscriptionInformation
      title={t('sass.title')}
      description={t('sass.caption', { planName })}
      items={saasContractItems}
      price={{
        value: saasContract?.conditions?.pricePerUser || 0,
        caption: t('valueCaption'),
      }}
      footer={
        <>
          {saasContractCaption.map((condition, index) => (
            <Box key={index} mt={1}>
              {condition} <br />
            </Box>
          ))}
        </>
      }
    />
  );
};
