import { Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledModal = styled(Modal.Root)`
  && {
    .modal-content-area {
      padding: 76px ${props => props.theme.spacings.m} ${props => props.theme.spacings.m};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LeftWrapper = styled.div`
  width: 100%;
  min-width: 312px;
`;

export const RightWrapper = styled.div`
  width: 100%;
  min-width: 312px;
  position: relative;
`;

export const Title = styled(Typography)`
  margin-bottom: ${props => props.theme.spacings.xs5};
`;

export const Description = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
  margin-bottom: ${props => props.theme.spacings.xs};
`;

export const FieldDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.s};
`;
