import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 0px ${props => props.theme.spacings.xs} ${props => props.theme.spacings.l};
  padding-bottom: ${props => props.theme.spacings.xl};
  position: relative;
`;

export const PageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacings.m} 0 ${props => props.theme.spacings.s} 0;
`;

export const IconAdd = styled(Icons)`
  fill: ${props => props.theme.colors.neutral[100]};
`;

export const BreadcrumbsContainer = styled.div`
  padding: ${props => props.theme.spacings.m} 0 0 0;
`;

export const Instruction = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
`;

export const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  margin-bottom: ${({ theme }) => theme.spacings.xs};
`;

export const ContainerUsers = styled(BorderedWrapping)`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xs};
  height: calc(100vh - 275px);
  overflow-y: hidden;
  padding-bottom: ${({ theme }) => theme.spacings.m};
`;
