import { Navigate, RouteObject } from 'react-router-dom';

import { Router } from '../Router';

export function RedirectExpensesRoutes(): RouteObject[] {
  return [
    {
      path: Router.getBaseName('*'),
      element: <Navigate to={Router.getExpenses()} />,
    },
  ];
}
