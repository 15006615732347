import { useState } from 'react';
import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { Collapse } from '@atoms/Collapse';
import { ICustomField } from '@containers/Expenses/context/types';
import { IExpenses } from '@containers/Expenses/context/types/expenses';
import { LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Skeleton } from '@mui/material';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useCheckIsKmType } from '@shared/hooks/useCheckIsKmType';
import { useTranslation } from 'react-i18next';

import { ExpenseDetailsCollapseData } from './ExpenseDetailsCollapseData';
import { ExpenseDetailsCollapseSkeleton } from './ExpenseDetailsCollapseSkeleton';
import * as SC from './styled';
import { formatExpenseFields } from './utils/formatExpenseCollapseFields';

interface IExpenseDetailsCollapseProps {
  expense: Omit<
    IExpenses,
    'id' | 'referenceId' | 'status' | 'violationNumber' | 'commentsNumber' | 'attachmentsNumber'
  >;
  isLoading: boolean;
  customFields: ICustomField[];
}

export const ExpenseDetailsCollapse = ({ isLoading, expense, customFields }: IExpenseDetailsCollapseProps) => {
  const [openDetails, setOpenDetails] = useState(false);
  const { t } = useTranslation();
  const { getDateOnlyFormatter } = useDateFormatter();
  const { getValueWithCurrency } = useValueFormatter();
  const uninformedData = <SC.ValueEmpty variant="headline8">{t('molecules.expenseCollapse.uninformed')}</SC.ValueEmpty>;
  const { isKmType } = useCheckIsKmType(expense?.type);

  if (isLoading) {
    return <ExpenseDetailsCollapseSkeleton />;
  }

  return (
    <BorderedWrapping>
      <SC.Container>
        <SC.ExpenseCollapseSummary>
          <SC.ExpenseCollapseSummaryLabelValueGroup>
            <Typography variant="headline5">
              {getValueWithCurrency({ value: expense.value || 0, currencyPrefix: expense.currencyPrefix || 'R$' })}
            </Typography>
            <SC.ExpenseCollapseSummaryLabel variant="body4">
              {t('molecules.expenseCollapse.value')}
            </SC.ExpenseCollapseSummaryLabel>
          </SC.ExpenseCollapseSummaryLabelValueGroup>
          <SC.ExpenseCollapseSummaryLabelValueGroup>
            {isKmType ? (
              <Typography variant="headline8">{expense?.report?.name || uninformedData}</Typography>
            ) : (
              <Typography variant="headline8">{expense?.establishment?.place || uninformedData}</Typography>
            )}
            <SC.ExpenseCollapseSummaryLabel variant="body4">
              {t(`molecules.expenseCollapse.${isKmType ? 'report' : 'establishment'}`)}
            </SC.ExpenseCollapseSummaryLabel>
          </SC.ExpenseCollapseSummaryLabelValueGroup>
          <SC.ExpenseCollapseSummaryLabelValueGroup>
            {expense?.expenseDate ? (
              <Typography variant="headline8">{getDateOnlyFormatter(expense.expenseDate)}</Typography>
            ) : (
              uninformedData
            )}
            <SC.ExpenseCollapseSummaryLabel variant="body4">
              {t('molecules.expenseCollapse.date')}
            </SC.ExpenseCollapseSummaryLabel>
          </SC.ExpenseCollapseSummaryLabelValueGroup>
        </SC.ExpenseCollapseSummary>
        <Collapse isOpen={openDetails}>
          <ExpenseDetailsCollapseData
            expense={expense}
            fields={formatExpenseFields({
              client: expense.client,
              project: expense.project,
              category: expense.category,
              costCenter: expense.costCenter,
              quantity: expense.quantity,
              comments: expense.description,
              customFields: customFields,
            })}
          />
        </Collapse>
        <SC.ExpenseShowButtonContainer>
          {!isLoading ? (
            <LinkButton variant="default" onClick={() => setOpenDetails(isOpen => !isOpen)}>
              {openDetails ? t('report.toRecall') : t('report.seeDetails')}
            </LinkButton>
          ) : (
            <Skeleton variant="rounded" width={96} height={28} />
          )}
        </SC.ExpenseShowButtonContainer>
      </SC.Container>
    </BorderedWrapping>
  );
};
