import styled from 'styled-components';

export const ModalDescriptionContainer = styled.div`
  width: 460px;
  text-align: center;
`;

export const ModalButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xl};
`;

export const List = styled.ul`
  text-align: left;
`;
