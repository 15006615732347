import { useState } from 'react';
import emptyImage from '@assets/images/empty_state.png';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { useAdvances } from '@containers/Advances/hooks/dataSources/useAdvances';
import { useAttachAdvanceToReport } from '@containers/Advances/hooks/dataSources/useAttachAdvanceToReport';
import { Checkbox, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useDateFormatter } from '@shared/hooks';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { TAccessScreen } from '@shared/types';
import { useTranslation } from 'react-i18next';

import { AttachAdvanceModalSkeleton } from './AttachAdvanceModalSkeleton';
import * as SC from './styled';

interface AttachAdvanceModalProps {
  onClose(): void;
  isOpen: boolean;
  reportId: number;
  accessScreen?: TAccessScreen;
}

export const AttachAdvanceToReportModal = ({ onClose, isOpen, reportId }: AttachAdvanceModalProps) => {
  const { t } = useTranslation();
  const [selectedAdvances, setSelectedAdvances] = useState([]);
  const { isLoading: loading, handleAttach } = useAttachAdvanceToReport();
  const { getValueWithCurrency } = useValueFormatter();
  const { getDateOnlyFormatter } = useDateFormatter();
  const { advances, isLoading } = useAdvances({ page: 0, isOpenToAttach: true });

  const onSelectAdvanceClick = (itemId: number) => {
    const isIdSelected = selectedAdvances.includes(itemId);

    if (isIdSelected) {
      setSelectedAdvances(selectedAdvances.filter(id => id !== itemId));
    } else {
      setSelectedAdvances([...selectedAdvances, itemId]);
    }
  };

  const handleAttachAdvanceToReport = async () => {
    await handleAttach(selectedAdvances, reportId);
    onClose();
  };

  return (
    <DefaultModal
      open={isOpen}
      isLoading={loading}
      onCloseClick={onClose}
      onCancelClick={onClose}
      onActionClick={handleAttachAdvanceToReport}
      headerTitle={t('organisms.attachAdvanceModal.title')}
      headerSubtitle={t('organisms.attachAdvanceModal.subtitle')}
      labelButtonAction={
        <>
          {t('organisms.attachAdvanceModal.add')} <SC.PlusIcon />
        </>
      }>
      {isLoading ? (
        <AttachAdvanceModalSkeleton />
      ) : (
        <SC.Container>
          <SC.AdvancesContainer>
            {advances?.length > 0 ? (
              advances.map((advance, index) => {
                return (
                  <SC.AdvanceItem key={index}>
                    <Checkbox
                      onChange={() => onSelectAdvanceClick(advance.id)}
                      checked={selectedAdvances.find(id => id == advance.id)}
                    />
                    <SC.AdvanceNameContainer>
                      <ShapeIcon icon="IconCurrency" size="small" variant="secondary" />
                      <Typography variant="body3">{advance.referenceId || '-'}</Typography>
                    </SC.AdvanceNameContainer>
                    <Typography variant="body3">
                      {getValueWithCurrency({ value: advance.value, currencyPrefix: advance.currencyPrefix })}
                    </Typography>
                    <Typography variant="body3">{getDateOnlyFormatter(advance.advanceDate) || '-'}</Typography>
                  </SC.AdvanceItem>
                );
              })
            ) : (
              <SC.EmptyContainer>
                <SC.Image src={emptyImage} />
                <SC.DescriptionEmpty variant="body3">
                  {t('organisms.attachAdvanceModal.emptyAdvances')}
                </SC.DescriptionEmpty>
              </SC.EmptyContainer>
            )}
          </SC.AdvancesContainer>
        </SC.Container>
      )}
    </DefaultModal>
  );
};
