import { ActionsButton } from '@molecules/ActionsButton/ActionsButton';
import { clientsBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

export function AddClientsButton() {
  const navigate = useNavigate();
  const t = useTranslate('organisms.clientsPreview.addClient');

  function onImportClick(key: string) {
    if (key === 'addClientManually') {
      navigate(`${clientsBaseURL}/register`);
    } else {
      navigate(`${clientsBaseURL}/register/file`);
    }
  }

  return (
    <ActionsButton
      actionIcon="IconPlus"
      actionName={t('label')}
      actionsList={[
        {
          key: 'addClientManually',
          name: t('options.addClientManually'),
          onClick: onImportClick,
          icon: 'IconClick',
        },
        {
          key: 'addClientByFile',
          name: t('options.addClientByFile'),
          onClick: onImportClick,
          icon: 'IconTable',
        },
      ]}
    />
  );
}
