import { EExportAnalyticsType } from '@containers/Analytics/context/types/export';
import { useTranslation } from '@locale/Translator';

interface IProps {
  onlyShowActions?: EExportAnalyticsType[];
  onExportClick: (analyticsExport: EExportAnalyticsType) => void;
}

export function useAnalysisContainerActions({ onlyShowActions, onExportClick }: IProps) {
  const { t } = useTranslation();
  const translationBase: string = 'analytics.actions.export';

  function getItemExport(exportType: EExportAnalyticsType) {
    return {
      label: t(`${translationBase}${exportType}`),
      selected: false,
      onClick: () => onExportClick(exportType),
    };
  }

  return Object.values(EExportAnalyticsType)
    .filter(value => !onlyShowActions?.length || onlyShowActions.includes(value))
    .map(_exportType => getItemExport(_exportType));
}
