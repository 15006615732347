import React, { useMemo } from 'react';
import { ISummaryReport } from '@containers/Expenses/context/types/reports';
import { Option } from '@molecules/AutoComplete';

export interface UseSummaryReportListProps {
  reports: ISummaryReport[];
}

export interface UseSummaryReport {
  value: number | string;
  label: React.ReactNode;
  icon: React.ReactNode;
}

export const useSummaryReportList = ({ reports }: UseSummaryReportListProps): Option[] => {
  const options: Option[] = useMemo(() => {
    if (Boolean(reports?.length)) {
      return reports.map(report => ({
        value: report.id,
        label: `${report.referenceId} | ${report.name}`,
        icon: 'IconFolder',
      }));
    }

    return [];
  }, [reports]);

  return options;
};
