import { useState } from 'react';
import { EAdvanceStatus, IAdvance } from '@containers/Advances/context/types/advance';
import { useDeleteBatchAdvances } from '@containers/Advances/hooks/useDeleteBatchAdvances';
import { useStatusSwitchBatchAdvances } from '@containers/Advances/hooks/useStatusSwitchBatchAdvances';
import { useDetachAdvancesFromReportMutate } from '@containers/Reports/hooks/useDetachAdvancesFromReportMutate';
import { useTranslation } from '@locale/Translator';
import { IActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { IBatchActionsActions } from '@organisms/Grid/BatchActions/BatchActions';
import { useSelectionRows } from '@organisms/Grid/BatchActions/hooks/useSelectionRows';
import { expensesAccountingBaseURL, expensesAdvancesBaseURL, expensesApprovalsBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useAdvancesPermissions } from '@shared/hooks/permissions/systemPermissions/useAdvancesPermissions';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { useIntegrations } from '@shared/hooks/useIntegrations';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { TAdvancesConfirmationActions } from '../../AdvancesConfirmationActionsModal';

enum EActions {
  VIEW = 'view',
  EDIT = 'edit',
  ACTIVE = 'active',
  DELETE = 'delete',
  UNLINK = 'unlink',
  LINK = 'link',
  APPROVE = 'approve',
  REPROVE = 'reprove',
}

enum EIcons {
  VIEW = 'IconEye',
  EDIT = 'IconPencil',
  ACTIVE = 'IconToggleLeft',
  DELETE = 'IconTrash',
  UNLINK = 'IconUnlink',
  LINK = 'IconLink',
  APPROVE = 'IconThumbUp',
  REPROVE = 'IconThumbDown',
}

export function useAdvancesActionsGrid(advances: IAdvance[]) {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id: reportId } = useParams();
  const t = (key: string): string => translation.t(`organisms.advances.grid.${key}`);
  const [justificationIsOpen, setJustificationIsOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const [confirmationType, setConfirmationType] = useState<TAdvancesConfirmationActions>();
  const [isBatchAction, setIsBatchAction] = useState<boolean>(false);
  const [selectedAdvance, setSelectedAdvance] = useState<IAdvance>();
  const [selectedAdvancesForBatchAction, setSelectedAdvancesForBatchAction] = useState<IAdvance[]>([]);
  const { isSelectedAll, selectedItems, toggleSelectAll, onSelectItemClick, unSelectAllClick } =
    useSelectionRows<IAdvance>(advances);
  const { deleteAdvances, isLoading: isLoadingDelete, error } = useDeleteBatchAdvances();
  const { setStatusBatchAdvances, isLoading: statusIsLoading, listIdsLoading } = useStatusSwitchBatchAdvances();
  const { edit, hasAccounting, hasApprover, link, unlink, view } = useAdvancesPermissions();
  const { unlinkAdvance } = useDetachAdvancesFromReportMutate({});
  const getCurrentUrlBase = useCurrentUrlBase();
  const { hasFinancialIntegration } = useIntegrations();

  function onShowConfirmationActionsModal(): void {
    setShowConfirmationModal(_open => !_open);
  }

  async function onConfirmationActionModal() {
    try {
      switch (confirmationType) {
        case 'exclusion':
          await deleteAdvances(selectedItems.length > 0 ? selectedItems : [selectedAdvance.id]);
          break;
        case 'unlink':
          const advanceIds = selectedItems.length > 0 ? selectedItems : [selectedAdvance.id];
          await unlinkAdvance(Number(reportId) || selectedAdvance.report.id, advanceIds);
          break;
        case 'reprove':
          await setStatusBatchAdvances(
            selectedItems.length > 0 ? selectedItems : [selectedAdvance.id],
            EAdvanceStatus.REPROVED,
          );
          break;
      }
    } catch {
      if (error) {
        showToast({ type: 'error', message: error?.message });
      }
      showToast({ type: 'error', message: error?.message });
    } finally {
      unSelectAllClick();
      setSelectedAdvancesForBatchAction([]);
      onShowConfirmationActionsModal();
    }
  }

  function getSelectedAdvance(_id: IAdvance['id']): IAdvance {
    return advances?.find(advance => advance?.id === _id);
  }

  async function onActionClick(_actionKey: EActions, _id?: IAdvance['id']) {
    setIsBatchAction(_id ? false : selectedItems.length > 1);
    const _advancesSelectedIds: IAdvance['id'][] = _id ? [_id] : selectedItems;
    const _advance: IAdvance = !isBatchAction ? getSelectedAdvance(_advancesSelectedIds[0]) : null;

    _advance && setSelectedAdvance(_advance);

    switch (_actionKey) {
      case EActions.DELETE:
        setConfirmationType('exclusion');
        onShowConfirmationActionsModal();
        break;
      case EActions.EDIT:
        navigate(`${expensesAdvancesBaseURL}/register/${_id}`);
        break;
      case EActions.VIEW:
        switch (getCurrentUrlBase()) {
          case expensesAccountingBaseURL:
            navigate(`${expensesAccountingBaseURL}/advances/details/${_id}${search}`);
            break;
          case expensesApprovalsBaseURL:
            navigate(`${expensesApprovalsBaseURL}/advances/details/${_id}${search}`);
            break;
          default:
            navigate(`${expensesAdvancesBaseURL}/details/${_id}${search}`);
            break;
        }
        break;

      case EActions.UNLINK:
        setConfirmationType('unlink');
        onShowConfirmationActionsModal();
        break;

      case EActions.LINK:
        setShowLinkModal(true);
        break;

      case EActions.APPROVE:
        await setStatusBatchAdvances(
          selectedItems.length > 0 ? selectedItems : [selectedAdvance.id],
          getCurrentUrlBase() === expensesAccountingBaseURL
            ? hasFinancialIntegration
              ? EAdvanceStatus.AWAITING_FINANCIAL_PROCESSING
              : EAdvanceStatus.OPEN
            : EAdvanceStatus.APPROVED,
        );
        unSelectAllClick();
        break;

      case EActions.REPROVE:
        setConfirmationType('reprove');
        setJustificationIsOpen(true);
        break;

      default:
        setConfirmationType(null);
        navigate(`${expensesAdvancesBaseURL}`);
        break;
    }
  }

  const handleItemSelection = item => {
    onSelectItemClick(item?.id);
    const isSelected = selectedAdvancesForBatchAction.includes(item);

    if (isSelected) {
      setSelectedAdvancesForBatchAction(selectedAdvancesForBatchAction?.filter(advance => advance !== item));
    } else {
      setSelectedAdvancesForBatchAction([...selectedAdvancesForBatchAction, item]);
    }
  };

  const handleToggleAll = () => {
    toggleSelectAll();
    if (isSelectedAll) setSelectedAdvancesForBatchAction([]);
    else setSelectedAdvancesForBatchAction(advances);
  };

  function statusIsPending(advance?: IAdvance, _isBatchAction?: boolean) {
    return _isBatchAction
      ? selectedAdvancesForBatchAction?.every(_advance => _advance.status === EAdvanceStatus.PENDING)
      : advance?.status === EAdvanceStatus.PENDING;
  }

  function statusIsApprove(advance?: IAdvance, _isBatchAction?: boolean) {
    return _isBatchAction
      ? selectedAdvancesForBatchAction?.every(_advance => _advance.status === EAdvanceStatus.APPROVED)
      : advance?.status === EAdvanceStatus.APPROVED;
  }

  function statusIsSubmitted(advance?: IAdvance, _isBatchAction?: boolean) {
    return _isBatchAction
      ? selectedAdvancesForBatchAction?.every(_advance => _advance.status === EAdvanceStatus.SUBMITTED)
      : advance?.status === EAdvanceStatus.SUBMITTED;
  }

  function statusIsFinished(advance?: IAdvance, _isBatchAction?: boolean) {
    return _isBatchAction
      ? selectedAdvancesForBatchAction?.every(_advance => _advance.status === EAdvanceStatus.FINISHED)
      : advance?.status === EAdvanceStatus.FINISHED;
  }

  function hasLinkedReport(advance?: IAdvance, _isBatchAction?: boolean) {
    return _isBatchAction
      ? selectedAdvancesForBatchAction?.every(_advance => Boolean(_advance.report?.id))
      : Boolean(advance?.report?.id);
  }

  const getAllowedAction = (key: keyof EActions, isBatchAction: boolean, advance?: IAdvance) => {
    const hasAdm = hasAccounting || hasApprover;
    const allowedAction = {
      allowed: false,
      disabled: false,
    };

    switch (key) {
      case EActions.DELETE:
        if (edit) {
          allowedAction.allowed = isBatchAction
            ? !statusIsFinished(advance, isBatchAction)
            : statusIsPending(advance, isBatchAction);
          allowedAction.disabled = !statusIsPending(advance, isBatchAction);
        }
        return allowedAction;

      case EActions.EDIT:
        if (edit && !isBatchAction)
          allowedAction.allowed = statusIsPending(advance, isBatchAction) && !statusIsFinished(advance, isBatchAction);
        return allowedAction;

      case EActions.VIEW:
        allowedAction.allowed = view && !isBatchAction;
        return allowedAction;

      case EActions.APPROVE:
      case EActions.REPROVE:
        if (hasAdm) {
          allowedAction.allowed = isBatchAction
            ? !statusIsFinished(advance, isBatchAction)
            : hasAccounting
            ? statusIsApprove(advance, isBatchAction)
            : statusIsSubmitted(advance, isBatchAction);
          allowedAction.disabled = hasAccounting
            ? !statusIsApprove(advance, isBatchAction)
            : !statusIsSubmitted(advance, isBatchAction);
        }
        return allowedAction;

      case EActions.UNLINK:
        if (!hasAdm && unlink && !isBatchAction) {
          allowedAction.allowed = hasLinkedReport(advance, isBatchAction) && !statusIsFinished(advance, isBatchAction);
          allowedAction.disabled = hasLinkedReport(advance, isBatchAction);
        }
        return allowedAction;

      case EActions.LINK:
        if (!hasAdm && link && !isBatchAction) {
          allowedAction.allowed = !hasLinkedReport(advance, isBatchAction) && !statusIsFinished(advance, isBatchAction);
          allowedAction.disabled = !hasLinkedReport(advance, isBatchAction);
        }
        return allowedAction;

      default:
        return allowedAction;
    }
  };

  function getActionsRow(_id: IAdvance['id'], advance: IAdvance): IActionsRow[] {
    return (
      advances?.length &&
      Object.keys(EActions).map(key => ({
        id: _id,
        key: EActions[key],
        allowed: getAllowedAction(EActions[key], false, advance).allowed,
        iconName: EIcons[key],
        name:
          EActions[key] === EActions.APPROVE
            ? t(
                `body.actions.${
                  hasAccounting ? (hasFinancialIntegration ? 'sendToIntegration' : 'pay') : EActions[key]
                }`,
              )
            : t(`body.actions.${EActions[key]}`),
        onClick: (_key, id) => onActionClick(_key as EActions, id as IAdvance['id']),
      }))
    );
  }

  function getTooltipBatchActions(key: EActions): string {
    if (key === EActions.DELETE) {
      const isAllDelete = selectedAdvancesForBatchAction?.every(_advance => _advance.status === EAdvanceStatus.PENDING);
      return !isAllDelete ? t('header.batchActions.tooltips.status') : '';
    } else if (key === EActions.APPROVE || key === EActions.REPROVE) {
      const isAllowed = selectedAdvancesForBatchAction?.every(_advance =>
        hasAccounting ? _advance.status === EAdvanceStatus.APPROVED : _advance.status === EAdvanceStatus.SUBMITTED,
      );
      return !isAllowed
        ? translation.t(`pages.advanceRegister.actions.tooltip.${hasAccounting ? 'accounting' : 'approval'}`)
        : '';
    }

    return '';
  }

  function getBatchActions(): IBatchActionsActions[] {
    const batchActions: IBatchActionsActions[] = [];

    advances?.length &&
      Object.keys(EActions).forEach(key => {
        batchActions.push({
          key: EActions[key],
          allowed: getAllowedAction(EActions[key], true).allowed,
          disabled: getAllowedAction(EActions[key], true).disabled,
          iconName: EIcons[key],
          name:
            EActions[key] === EActions.APPROVE
              ? t(
                  `body.actions.${
                    hasAccounting ? (hasFinancialIntegration ? 'sendToIntegration' : 'pay') : EActions[key]
                  }`,
                )
              : t(`body.actions.${EActions[key]}`),
          tooltipMessage: getTooltipBatchActions(EActions[key]),
          onClick: _key => onActionClick(_key as EActions),
        });
      });

    return batchActions;
  }

  async function onActionJustificationPopup(justificationText: string) {
    try {
      await setStatusBatchAdvances(
        isBatchAction ? selectedItems : [selectedAdvance?.id],
        EAdvanceStatus.REPROVED,
        justificationText,
      );
      unSelectAllClick?.();
    } catch (error) {
      console.error(error);
    }
    setJustificationIsOpen(false);
  }

  return {
    showConfirmationModal,
    confirmationType,
    isSelectedAll,
    selectedItems,
    isBatchAction,
    isLoadingAction: isLoadingDelete || statusIsLoading,
    listIdsLoading,
    justificationIsOpen,
    showLinkModal,
    selectedAdvance,
    getActionsRow,
    getBatchActions,
    toggleSelectAll: handleToggleAll,
    unSelectAllClick,
    onSelectItemClick: handleItemSelection,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
    onActionJustificationPopup,
    setJustificationIsOpen,
    closeLinkModal: () => setShowLinkModal(false),
  };
}
