import { useMemo } from 'react';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { useTranslation } from 'react-i18next';

interface Props {
  isEmpty: boolean;
  allowSearchNewPlaces?: boolean;
  inputValue: string;
  selectedEstablishment: IEstablishment;
}

export function useCheckShouldSearchFromGoogleMaps({
  isEmpty,
  inputValue,
  selectedEstablishment,
  allowSearchNewPlaces = true,
}: Props): boolean {
  const { t } = useTranslation();

  return useMemo(
    () =>
      allowSearchNewPlaces &&
      isEmpty &&
      inputValue?.length > 2 &&
      inputValue != selectedEstablishment?.place &&
      inputValue !== t(`organisms.establishments.loading`),
    [inputValue],
  );
}
