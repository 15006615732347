import { ApolloError, LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { getTipsQuery } from '../../context/queries/tips';

export enum EAccessedPagesTips {
  customization = 'CUSTOMIZATION',
  addExpense = 'ADD_EXPENSE',
}
interface IGetTips {
  getTips: EAccessedPagesTips[];
}
interface IUseTips {
  tips: EAccessedPagesTips[];
  isLoadingTips: boolean;
  errorTips: ApolloError;
  getTips: LazyQueryExecFunction<IGetTips, OperationVariables>;
}

export const useTips = (): IUseTips => {
  const [getTips, { loading, data: tipsData, error }] = useLazyQuery<IGetTips>(getTipsQuery);

  return {
    tips: tipsData?.getTips ?? [],
    isLoadingTips: loading,
    errorTips: error,
    getTips,
  };
};
