import { useContext, useEffect } from 'react';
import { AdvancesGrid } from '@containers/Advances/components/organisms/AdvancesGrid';
import { EAdvanceStatus } from '@containers/Advances/context/types/advance';
import { IAdvanceForAccountingFilters } from '@containers/Financial/context/types/advances';
import { AccountingContext } from '@shared/contexts/AccountingContext/AccountingContext';

import { AdvancesCardFiltersGroup } from '../Filters/AdvancesCardFiltersGroup';
import { useAdvancesFilters } from '../Filters/hooks/useAdvancesFilters';
import * as SC from './styled';

export function FinancialAdvancesPreview() {
  const { setAdvancesQuantity } = useContext(AccountingContext);
  const { filters, onFilterClick } = useAdvancesFilters<IAdvanceForAccountingFilters>({
    initialFilters: { status: [EAdvanceStatus.APPROVED] },
  });

  useEffect(() => {
    setAdvancesQuantity(0);
  }, []);

  return (
    <SC.Container>
      <AdvancesCardFiltersGroup
        onSelectedStatusClick={_status => onFilterClick('status', _status)}
        selectedStatus={filters?.status}
        isLoading={false}
      />
      <AdvancesGrid
        setAmountAdvances={setAdvancesQuantity}
        onFilterClick={onFilterClick}
        showSearch={false}
        filters={filters}
      />
    </SC.Container>
  );
}
