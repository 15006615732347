import {
  ELicenseFeaturesAdvanceSettings,
  IConfigurationAdvancesSetting,
} from '@containers/Customizations/context/types/advances';
import { Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { FieldsSettingsSkeleton } from './FieldsSettingsSkeleton';
import * as SC from './styled';

export enum EFieldsSettings {
  COST_CENTER = 'costCenter',
  APPROVER = 'approver',
  REPORT = 'report',
  CLIENT = 'client',
  PROJECT = 'project',
  CURRENCY = 'currency',
  PAYMENT_METHOD = 'paymentMethod',
}

interface IProps {
  advancesSettings: IConfigurationAdvancesSetting;
  isLoading: boolean;
  handleChange(settingKey: string, value: unknown): void;
}

export const FieldsSettings = ({ advancesSettings, isLoading, handleChange }: IProps) => {
  const t = useTranslate('advancesSettings.form');

  async function onChangeValue(value: boolean, settingKey: EFieldsSettings, type: 'required' | 'field') {
    const active = type === 'field' ? value : (value && value) || advancesSettings?.[settingKey]?.active;
    let required = type === 'required' ? value : (!value && value) || advancesSettings?.[settingKey]?.required;

    if (type === 'field' && !value) {
      required = false;
    }

    handleChange(settingKey, {
      active,
      required,
    });
  }

  function getToggle(field: EFieldsSettings, checked: boolean = false, type: 'required' | 'field') {
    return (
      <SC.ToggleContainer>
        <Toggle checked={checked} onChange={(_, value) => onChangeValue(value, field, type)} />
      </SC.ToggleContainer>
    );
  }

  return (
    <SectionContainer title={t('title')} caption={t('caption')}>
      {isLoading ? (
        <FieldsSettingsSkeleton />
      ) : (
        <SC.Container>
          <SC.FieldsHeader>
            <SC.HeaderDescription variant="body3">{t('titleField')}</SC.HeaderDescription>
            <SC.HeaderDescription variant="body3">{t('titleRequired')}</SC.HeaderDescription>
          </SC.FieldsHeader>

          {Object.values(EFieldsSettings).map((field, key) => (
            <PermissionsChecker key={key} licenseFeature={ELicenseFeaturesAdvanceSettings[field]}>
              <SC.FieldContent>
                <SC.MandatoryInfoContainer>
                  <SC.ToggleLabel variant="body3">{t(`fields.${field}`)}</SC.ToggleLabel>
                </SC.MandatoryInfoContainer>
                {getToggle(field, advancesSettings?.[field]?.active, 'field')}
                {getToggle(field, advancesSettings?.[field]?.required, 'required')}
              </SC.FieldContent>
            </PermissionsChecker>
          ))}
        </SC.Container>
      )}
    </SectionContainer>
  );
};
