import { useEffect, useState } from 'react';
import { IExpenseAttachment, ReceiptFile } from '@containers/Expenses/context/types';
import { useSignedFileUrl } from '@containers/Expenses/hooks/useSignedFileUrl';
import { Icons, LinkButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { ImagesModal } from '@molecules/ImagesModal';

import * as SC from './styled';

interface IProps {
  attachments: IExpenseAttachment[];
  attachmentsNumber?: number;
  variant?: 'primary' | 'secondary';
}

export function ExpenseReceipts({ attachments, attachmentsNumber = 0, variant = 'primary' }: IProps) {
  const { t } = useTranslation();
  const attachmentsCount = attachments?.length || attachmentsNumber;
  const isEmpty = attachmentsCount === 0;
  const [showModal, setShowModal] = useState(false);
  const [receipts, setReceipts] = useState<ReceiptFile[]>([]);
  const { files, isLoading: isLoadingFiles } = useSignedFileUrl(
    (attachments || [])?.map(({ path }) => path) ?? null,
    receipts?.length > 0 || !attachments || showModal === false,
  );

  function onShowModal() {
    attachments?.length > 0 && setShowModal(open => !open);
  }

  useEffect(() => {
    if (!isLoadingFiles && receipts?.length === 0 && attachments?.length > 0) {
      setReceipts(files);
    }
  }, [files, isLoadingFiles]);

  return (
    <>
      {variant === 'primary' ? (
        <SC.Container $isEmpty={isEmpty} onClick={onShowModal}>
          <Tooltip
            arrow
            title={attachmentsCount === 0 ? t('organisms.expenseGrid.actionsGrid.tooltip.emptyReceipts') : ''}
            placement="top">
            <SC.Content $isEmpty={isEmpty}>
              <SC.ReceiptIcon
                $isEmpty={isEmpty}
                name={isEmpty ? 'IconReceiptOff' : 'IconReceipt'}
                size={35}
                fill="transparent"
                stroke="1.5"
              />
              {attachmentsCount > 1 && (
                <SC.NumberReceiptsContainer>
                  <SC.NumberReceiptsDescription variant="overline">{attachmentsCount}</SC.NumberReceiptsDescription>
                </SC.NumberReceiptsContainer>
              )}
            </SC.Content>
          </Tooltip>
        </SC.Container>
      ) : (
        <LinkButton variant="default" disabled={isEmpty} onClick={onShowModal}>
          {isEmpty ? 'Sem Recibo' : 'Ver Recibo'}
          <Icons name={isEmpty ? 'IconReceiptOff' : 'IconReceipt'} size={14} />
        </LinkButton>
      )}

      {showModal && (
        <ImagesModal
          images={receipts.map(({ contentType, url }) => ({ src: url, mime: contentType }))}
          isLoading={isLoadingFiles}
          loadingDescription={t('organisms.expenseReceipts.loading')}
          onCloseModalClick={onShowModal}
          initialIndex={0}
        />
      )}
    </>
  );
}
