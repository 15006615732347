import { Skeleton } from '@mui/material';

import * as SC from './styled';

export function DistanceRoutesSecondarySkeleton() {
  return (
    <SC.Container>
      <Skeleton variant="rounded" height={32} width={32} />
      <SC.DescriptionContent>
        <Skeleton variant="text" height={24} width={100} />
        <Skeleton variant="text" height={24} width={115} />
      </SC.DescriptionContent>
    </SC.Container>
  );
}
