import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

import { getVariantSize } from '../styled';
import { ESizeVariant } from '../variants';

export interface ImageContainerProps {
  size: ESizeVariant;
}

export const Container = styled.div`
  padding-bottom: ${props => props.theme.spacings.xs4};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  .image-wrapper {
    border-radius: ${props => props.theme.borders.radius.l};
  }
`;

export const ImageContainer = styled.img<ImageContainerProps>`
  position: relative;
  border-radius: ${props => props.theme.borders.radius.l};
  overflow: hidden;
  width: ${props => getVariantSize(props.size, 'width')};
  height: ${props => getVariantSize(props.size, 'height')};
  object-fit: cover;
`;

export const TrashIcon = styled(IconButton).attrs({
  variant: 'filled',
  size: 'medium',
})`
  position: absolute;
  z-index: 1;
  top: ${props => props.theme.spacings.xs};
  right: ${props => props.theme.spacings.xs};
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3;

  .MuiButtonBase-root {
    background-color: ${props => props.theme.colors.neutral[100]};
    border: ${props => props.theme.borders.width.s} solid ${props => props.theme.colors.neutral[80]};
    fill: ${props => props.theme.colors.neutral[40]};
  }
`;

export const FileFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

export const ZoomContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs4};
  z-index: 1;
  right: 12px;
  bottom: ${props => props.theme.spacings.l};
`;
