/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { useState } from 'react';
import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import {
  EReportPdfExportSettings,
  IConfigurationReportSetting,
} from '@containers/Customizations/context/types/settings/expenseReportSettingsTypes';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

interface IProps {
  reportsSettings: IConfigurationReportSetting;
  isLoading: boolean;
  handleChange(settingKey: string, value: unknown, currentReportSetting: IConfigurationReportSetting): void;
}

export enum EReportPdfExportSettingsLicenseFeatures {
  INCLUDE_REQUESTOR_DATA = 'BASIC_REPORTS',
  INCLUDE_SIGNATURE = 'BASIC_REPORTS',
  INCLUDE_PROJECT_SUBTOTAL = 'INTERMEDIATE_PROJECT',
  INCLUDE_COST_CENTER_SUBTOTAL = 'INTERMEDIATE_COST_CENTER',
  INCLUDE_MILEAGE_SUBTOTAL = 'BASIC_MILEAGE',
  INCLUDE_VIOLATION = 'BASIC_REPORTS',
  INCLUDE_COPY_OF_RECEIPTS_ATTACHED = 'BASIC_REPORTS',
  INCLUDE_HISTORY = 'BASIC_REPORTS',
}

export function ReportExportPreferences({ reportsSettings, isLoading, handleChange }: IProps) {
  const t = useTranslate('organisms.exportPreferencesReports');
  const [pdfExportSettingsKeys] = useState<string[]>(Object.values(EReportPdfExportSettings));
  const [totalItems] = useState<number>(pdfExportSettingsKeys?.length);

  function _handleChange(key: string, value: unknown) {
    const currentReportsSettings: IConfigurationReportSetting = { ...reportsSettings };
    currentReportsSettings.pdfExportSettings[key] = value;
    handleChange('pdfExportSettings', currentReportsSettings.pdfExportSettings, currentReportsSettings);
  }

  function getItems(start: number, end: number): React.ReactNode[] {
    return Object.keys(EReportPdfExportSettings)
      .slice(start, end)
      .map(key => (
        <PermissionsChecker
          key={EReportPdfExportSettings[key]}
          licenseFeature={EReportPdfExportSettingsLicenseFeatures[key]}>
          <ToggleSettings
            toggleKey={EReportPdfExportSettings[key]}
            checked={reportsSettings?.pdfExportSettings?.[EReportPdfExportSettings[key]]}
            onChange={_handleChange}
            label={t(`pdfExportSettings.${EReportPdfExportSettings[key]}`)}
            isLoading={isLoading}
          />
        </PermissionsChecker>
      ));
  }

  return (
    <SectionContainer title={t('title')}>
      <SC.Container>
        <Typography variant="body3">{t('includeInExportedDocument')}</Typography>
        <SC.ContainerItems>
          <SC.Content>{getItems(0, Math.round(totalItems / 2))}</SC.Content>
          <SC.Content>{getItems(Math.round(totalItems / 2), totalItems)}</SC.Content>
        </SC.ContainerItems>
      </SC.Container>
    </SectionContainer>
  );
}
