import { IconButtonArea } from '@atoms/IconButtonArea';
import { EExpenseType } from '@containers/Expenses/context/types';
import { useKmPolicies } from '@containers/Expenses/hooks/useKmPolicies';
import { useManualExpenses } from '@containers/Expenses/hooks/useManualExpenses';
import { IReport } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { useExpenseRegisterDriver } from '@shared/hooks/navigation/useExpenseRegisterDriver';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useCheckKmIsAllowed } from '@shared/hooks/permissions/systemPermissions/useCheckKmIsAllowed';
import {
  IExpensesAllowedActions,
  useExpensesPermissions,
} from '@shared/hooks/permissions/systemPermissions/useExpensesPermissions';

import * as SC from './styled';

export interface IProps {
  report: IReport;
  kmIsAllowed?: boolean;
  onShowAttachModal: () => void;
}

export const ExpensesEmptyActions = ({ report, kmIsAllowed, onShowAttachModal }: IProps) => {
  const { t } = useTranslation();
  const { checkLicenseFeature } = useLicenseFeatures();
  const navigateExpenseEdit = useExpenseRegisterDriver();
  const { getExpenseActions } = useExpensesPermissions();
  const { manualExpenseEntry } = useManualExpenses();
  const isKmAllowed = useCheckKmIsAllowed();
  const { allowOdometer, allowOriginDestination } = useKmPolicies();
  const permittedKmExpense = isKmAllowed && (allowOdometer || allowOriginDestination);

  const { edit: expenseEditingAllowed, link: expenseLinkAllowed }: IExpensesAllowedActions = getExpenseActions(
    EExpenseType.MANUAL,
    report?.status,
  );
  const { link: kmExpenseLinkAllowed }: IExpensesAllowedActions = getExpenseActions(
    EExpenseType.MANUAL,
    report?.status,
  );

  function onNavigateExpenseEdit(expenseType: EExpenseType) {
    navigateExpenseEdit({
      expenseType,
      linkedReport: {
        name: report?.name,
        id: report?.id,
        description: report?.description,
      },
    });
  }

  return (
    <SC.ButtonsContainer>
      {manualExpenseEntry && expenseEditingAllowed && checkLicenseFeature('BASIC_EXPENSE') && (
        <IconButtonArea icon="IconReceipt" onClick={() => onNavigateExpenseEdit(EExpenseType.MANUAL)}>
          {t('pages.reportDetails.addExpense')}
        </IconButtonArea>
      )}
      {permittedKmExpense && checkLicenseFeature('BASIC_MILEAGE') && (
        <IconButtonArea icon="IconMapPin" onClick={() => onNavigateExpenseEdit(EExpenseType.ORIGIN_DESTINATION)}>
          {t('pages.reportDetails.addKM')}
        </IconButtonArea>
      )}
      {(expenseLinkAllowed || (kmExpenseLinkAllowed && kmIsAllowed)) && (
        <IconButtonArea icon="IconFile" onClick={onShowAttachModal}>
          {t('pages.reportDetails.attachExpense')}
        </IconButtonArea>
      )}
    </SC.ButtonsContainer>
  );
};
