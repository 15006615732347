import MandatoryInfo from '@atoms/MandatoryInfo/MandatoryInfo';
import { IDelegationInput } from '@containers/Customizations/context/types/settings/delegation';
import { useDelegateUsersSave } from '@containers/Customizations/hooks/settings/delegation/useDelegateUsersSave';
import { useUserDelegateOptions } from '@containers/Customizations/hooks/settings/delegation/useUserDelegateOptions';
import { EFieldType } from '@containers/Expenses/context/types';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { FieldFactory } from '@organisms/FieldFactory';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useForm } from 'react-hook-form';

import { TTargetDelegation } from '../DelegationPreview';
import * as SC from './styled';

interface IProps {
  userId: string;
  open: boolean;
  target: TTargetDelegation;
  onClose: () => void;
}

export function AddDelegation({ open, target, userId, onClose }: IProps) {
  const t = useTranslate('delegation.configuration.add');
  const { users, isLoading: isLoadingOptions } = useUserDelegateOptions({ target, userId });
  const { handleSave, isLoading: saveIsLoading } = useDelegateUsersSave(target, users ?? []);

  const { control, handleSubmit, clearErrors } = useForm<IDelegationInput>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: {
      userId,
    },
  });

  async function onSubmit(data: IDelegationInput) {
    data.delegateUserIds = data.delegateUserIds.map(user => String(user));
    data.userId = String(data.userId);
    const success = await handleSave(data);
    success && onClose();
  }

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <DefaultModal
        open={open}
        isLoading={false}
        headerTitle={t(`${target}.title`)}
        headerSubtitle={t(`${target}.caption`)}
        labelButtonAction={t('add')}
        buttonType="submit"
        isActionLoading={saveIsLoading || isLoadingOptions}
        onActionClick={handleSubmit(onSubmit)}
        onCancelClick={onClose}>
        <SC.Container>
          <MandatoryInfo isPlural />
          <Controller
            key={'delegate-filed-people'}
            name={'delegateUserIds'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('requiredField'),
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <SC.FieldContainer>
                  <FieldFactory<IDelegationInput>
                    fieldType={EFieldType.OPTIONS}
                    isLoading={isLoadingOptions}
                    error={Boolean(error)}
                    helperText={error?.message}
                    label={t('fields.person')}
                    {...field}
                    onChange={data => {
                      clearErrors('delegateUserIds');
                      field.onChange(data);
                    }}
                    allowsEditing
                    value={field.value ?? []}
                    options={users ?? []}
                    isSelectMultiple
                  />
                </SC.FieldContainer>
              );
            }}
          />
          <SC.ContainerDate>
            {target === 'approval' && (
              <Controller
                key={'delegate-filed-people'}
                name={'startDate'}
                control={control}
                rules={{
                  required: {
                    value: target === 'approval',
                    message: t('requiredField'),
                  },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <SC.FieldContainer>
                      <FieldFactory<IDelegationInput>
                        fieldType={EFieldType.DATE}
                        error={Boolean(error)}
                        helperText={error?.message}
                        label={t('fields.startDate')}
                        isLoading={isLoadingOptions}
                        {...field}
                        value={field.value ?? ''}
                        allowsEditing
                      />
                    </SC.FieldContainer>
                  );
                }}
              />
            )}
            <Controller
              key={'delegate-filed-people'}
              name={'endDate'}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('requiredField'),
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <SC.FieldContainer>
                    <FieldFactory<IDelegationInput>
                      fieldType={EFieldType.DATE}
                      error={Boolean(error)}
                      helperText={error?.message}
                      label={t('fields.endDate')}
                      isLoading={isLoadingOptions}
                      {...field}
                      value={field.value ?? ''}
                      allowsEditing
                    />
                  </SC.FieldContainer>
                );
              }}
            />
          </SC.ContainerDate>
          {target === 'expense' && (
            <Controller
              key={'delegate-filed-people'}
              name={'reason'}
              control={control}
              rules={{
                required: {
                  value: target === 'expense',
                  message: t('requiredField'),
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <SC.FieldContainer>
                    <FieldFactory<IDelegationInput>
                      fieldType={EFieldType.TEXT}
                      error={Boolean(error)}
                      helperText={error?.message}
                      label={t('fields.reason')}
                      isLoading={isLoadingOptions}
                      {...field}
                      value={field.value ?? ''}
                      allowsEditing
                    />
                  </SC.FieldContainer>
                );
              }}
            />
          )}
        </SC.Container>
      </DefaultModal>
    </form>
  );
}
