import { Skeleton } from '@mui/material';
import { attachExpenseModalNumber } from '@shared/constant';

import * as SC from './styled';

interface AttachExpenseModalSkeletonProps {
  expenseNumber?: number;
}

export const AttachExpenseModalSkeleton = ({
  expenseNumber = attachExpenseModalNumber,
}: AttachExpenseModalSkeletonProps) => {
  return (
    <SC.Container>
      <SC.ExpensesContainer>
        {[...Array(expenseNumber)].map((item, index) => (
          <SC.ExpenseItem key={index}>
            <Skeleton variant="text" height={48} width={25} />
            <SC.ExpenseNameContainer>
              <Skeleton variant="circular" height={48} width={48} />
              <Skeleton variant="text" height={48} width={150} />
            </SC.ExpenseNameContainer>
            <Skeleton variant="text" height={48} width={90} />
            <Skeleton variant="text" height={48} width={90} />
          </SC.ExpenseItem>
        ))}
      </SC.ExpensesContainer>
    </SC.Container>
  );
};
