import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};

  .select-custom .select-field-custom-theme div.Mui-disabled {
    padding-right: 60px;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  width: 100%;
  align-items: center;
`;

export const ContentRow = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs};
  grid-template-columns: 65px auto min-content auto;
  align-items: center;
  width: 100%;
`;

export const Description = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const ContainerFieldTrash = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs};
  grid-template-columns: auto min-content;
  align-items: center;
`;

export const LinkContent = styled.div`
  white-space: nowrap;
  display: grid;
  gap: ${props => props.theme.spacings.xs5};
  grid-template-columns: min-content min-content;
`;

export const ContainerTrash = styled.div`
  width: 45px;
  height: 45px;
`;
