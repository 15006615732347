import { useMemo } from 'react';
import { SubscriptionInfoItem } from '@containers/Subscription/components/molecules/SubscriptionInformation/SubscriptionInformation';
import { SAASContractInformation } from '@containers/Subscription/context/types/contractInformation';

export function useERPIntegrationConditions(saasContract: SAASContractInformation) {
  const showERPIntegration = saasContract?.conditions?.erpIntegrations?.length > 0;

  const erpIntegrationItems: SubscriptionInfoItem[] = useMemo(() => {
    return saasContract?.conditions?.erpIntegrations?.map<SubscriptionInfoItem>(integration => ({
      name: integration.name,
      included: true,
      value: integration.value,
    }));
  }, [saasContract]);

  const totalERPIntegrationValue = useMemo(() => {
    return saasContract?.conditions?.erpIntegrations?.reduce((acc, integration) => acc + (integration.value || 0), 0);
  }, [saasContract?.conditions?.erpIntegrations]);

  return {
    showERPIntegration,
    erpIntegrationItems,
    totalERPIntegrationValue,
  };
}
