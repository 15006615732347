import React, { useMemo } from 'react';
import { IReportHistory } from '@containers/Reports/context/types/report';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';

import * as SC from '../styled';
import { EAccessor } from './UseHeaderGridColumns';

export type TGridRow = Record<EAccessor, React.ReactNode>;

export interface UseBodyGridRowsProps {
  historic: IReportHistory[];
}

export const useBodyGridRows = ({ historic }: UseBodyGridRowsProps): TGridRow[] => {
  const { getDateOnlyFormatter } = useDateFormatter();
  const rows: TGridRow[] = useMemo(
    () =>
      historic?.map(item => ({
        date: <SC.DescriptionBodyColumn>{getDateOnlyFormatter(item.createdAt)}</SC.DescriptionBodyColumn>,
        user: <SC.DescriptionBodyColumn>{item.user.name}</SC.DescriptionBodyColumn>,
        actions: <SC.DescriptionBodyColumn>{item.description}</SC.DescriptionBodyColumn>,
      })),
    [historic],
  );

  return rows;
};
