import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { SettingsContext } from '../context';
import { ESettingActions } from '../context/actions';
import { getCurrenciesQuery } from '../context/queries/currencies';
import { IGetCurrencies } from '../context/types';

export const useCurrencies = () => {
  const { loading: isLoadingCurrencies, data: currenciesData } = useQuery<IGetCurrencies>(getCurrenciesQuery);
  const { dispatch } = useContext(SettingsContext);
  const [selectFieldCurrencies, setSelectFieldCurrencies] = useState([]);

  useEffect(() => {
    dispatch({
      type: ESettingActions.GET_CURRENCIES,
      payload: { currencies: currenciesData?.getAllCurrencies.currencies ?? [] },
    });
  }, [currenciesData, dispatch]);

  useEffect(() => {
    const newArray = currenciesData?.getAllCurrencies.currencies?.map(currency => ({
      label: `${currency.code} ${currency.symbol ? `(${currency.symbol})` : ''}`,
      value: currency.id,
    }));

    setSelectFieldCurrencies(newArray);
  }, [currenciesData]);

  return {
    currencies: currenciesData?.getAllCurrencies.currencies,
    loading: isLoadingCurrencies,
    selectFieldTypeCurrencies: selectFieldCurrencies,
  };
};
