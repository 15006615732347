import React from 'react';
import { useTranslation } from '@locale/Translator';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';

import { EOrderBy } from '../context/types/report';

export enum EAccessor {
  SELECTION = 'selection',
  NAME = 'name',
  ID = 'id',
  ACTIVE_PERIOD = 'activePeriod',
  VALUE = 'value',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export interface ReportHeaderGrid {
  Header: React.ReactNode;
  accessor: string | number;
  disableSortBy?: boolean;
  sticky?: string;
}

interface UseReportHeaderGridProps {
  onOrderByClick(accessor: EAccessor, order: EOrderBy): void;
  onSelectAllClick(): void;
  isSelectedAll: boolean;
}

export const useReportHeaderGrid = ({
  onSelectAllClick,
  isSelectedAll,
}: UseReportHeaderGridProps): ReportHeaderGrid[] => {
  const { t } = useTranslation();

  return Object.values(EAccessor).map(accessor => ({
    Header: (
      <ColumnHeader
        isSelectedAll={accessor == EAccessor.SELECTION}
        onSelectAllClick={onSelectAllClick}
        selectAllChecked={isSelectedAll}
        description={accessor == 'id' ? 'ID' : t(`organisms.reportGrid.${accessor}`)}
      />
    ),
    accessor,
    disableSortBy: true,
    sticky: accessor == EAccessor.ACTIONS && 'right',
  }));
};
