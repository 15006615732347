import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import { IConfigurationAdvancesSetting } from '@containers/Customizations/context/types/advances';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { AdvancesSettingsCode } from './AdvancesSettingsCode/AdvancesSettingsCode';
import * as SC from './styled';

interface IProps {
  advancesSettings: IConfigurationAdvancesSetting;
  isLoading: boolean;
  handleChange(settingKey: string, value: unknown): void;
}

export function GeneralAdvancesSettings({ advancesSettings, isLoading, handleChange }: IProps) {
  const t = useTranslate('advancesSettings.general');

  function _handleChange(key: string, value: unknown) {
    handleChange(key, value);
  }

  return (
    <SectionContainer title={t('title')} caption={t('caption')}>
      <SC.Container>
        <SC.Container>
          <PermissionsChecker licenseFeature="ADVANCED_ADVANCE">
            <ToggleSettings
              toggleKey={'allowsMultipleAdvancesPerReport'}
              checked={advancesSettings?.allowsMultipleAdvancesPerReport}
              onChange={_handleChange}
              label={t('fields.allowsMultipleAdvancesPerReport')}
              isLoading={isLoading}
            />
          </PermissionsChecker>
          <PermissionsChecker licenseFeature="ADVANCED_ADVANCE">
            <ToggleSettings
              toggleKey={'approveOwnAdvance'}
              checked={advancesSettings?.approveOwnAdvance}
              onChange={_handleChange}
              label={t('fields.approveOwnAdvance')}
              isLoading={isLoading}
            />
          </PermissionsChecker>
          <PermissionsChecker licenseFeature="BASIC_ADVANCE">
            <ToggleSettings
              toggleKey={'includeAdvanceBankDetails'}
              checked={advancesSettings?.includeAdvanceBankDetails}
              onChange={_handleChange}
              label={t('fields.includeAdvanceBankDetails')}
              isLoading={isLoading}
            />
          </PermissionsChecker>
          <PermissionsChecker licenseFeature="ADVANCED_ADVANCE">
            <ToggleSettings
              toggleKey={'enableAdvanceBatch'}
              checked={advancesSettings?.enableAdvanceBatch}
              onChange={_handleChange}
              label={t('fields.enableAdvanceBatch')}
              isLoading={isLoading}
            />
          </PermissionsChecker>
          <PermissionsChecker licenseFeature="ADVANCED_ADVANCE">
            <ToggleSettings
              toggleKey={'allowsLinkingExpenseReturnOfAdvanceWithoutLinkingAdvances'}
              checked={advancesSettings?.allowsLinkingExpenseReturnOfAdvanceWithoutLinkingAdvances}
              onChange={_handleChange}
              label={t('fields.allowsLinkingExpenseReturnOfAdvanceWithoutLinkingAdvances')}
              isLoading={isLoading}
            />
          </PermissionsChecker>
        </SC.Container>

        <SC.Divider />
        <PermissionsChecker licenseFeature="BASIC_ADVANCE">
          <AdvancesSettingsCode
            prefixValue={advancesSettings?.prefixValue}
            startValue={advancesSettings?.startValue}
            isLoading={isLoading}
            handleChange={_handleChange}
          />
        </PermissionsChecker>
      </SC.Container>
    </SectionContainer>
  );
}
