import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';

type Direction = 'UP' | 'DOWN';

export function useChangeCustomFieldOrder() {
  const { mutation } = useMutate('v1/custom-fields', onError);

  function onError(error: Error) {
    return showToast({ message: error.message, type: 'error' });
  }

  async function handleMove(customFieldId: string, direction: Direction) {
    const response = await mutation.trigger({
      path: `/${customFieldId}/order?direction=${direction}`,
      options: {
        method: 'PATCH',
      },
    });

    return response;
  }

  return {
    handleMove,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
