import { IReport } from '@containers/Reports/context/types';
import { IWithdraw } from '@containers/Withdraw/context/types/types';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { useWithdrawalsToAttach } from './useWithdrawalsToAttach';

interface IResponse {
  id: string;
  error: boolean;
  message: string;
}

export function useAttachWithdrawToReport(reportId: IReport['id']) {
  const { mutation } = useMutate('v1/withdrawals');
  const t = useTranslate('withdraw.feedbacks');

  const { refetch } = useWithdrawalsToAttach();

  function getFeedbackResponse(key: 'attach' | 'detach', response: IResponse[]) {
    const success: boolean = response?.some(withdraw => !withdraw.error);
    response?.forEach(withdraw => {
      if (withdraw.error)
        showToast({
          message: withdraw?.message ?? t(`${key}.error`),
          type: 'error',
        });
    });

    if (success) {
      showToast({
        message: t(`${key}.success.${response.length > 1 ? 'plural' : 'singular'}`),
        type: 'success',
      });
    }
  }

  async function attach(ids: IWithdraw['userIdCard'][]) {
    try {
      const response: IResponse[] = await mutation.trigger({
        path: 'attach',
        options: {
          method: 'PATCH',
          body: JSON.stringify({
            reportId: String(reportId),
            userIdCards: ids,
          }),
        },
      });

      refetch();

      getFeedbackResponse('attach', response);

      return response;
    } catch (_error) {
      showToast({
        message: _error?.message ?? t('attach.error'),
        type: 'error',
      });
    }
  }

  async function detach(ids: IWithdraw['userIdCard'][]) {
    try {
      const response: IResponse[] = await mutation.trigger({
        path: 'detach',
        options: {
          method: 'PATCH',
          body: JSON.stringify({
            reportId: String(reportId),
            userIdCards: ids,
          }),
        },
      });

      refetch();

      getFeedbackResponse('detach', response);

      return response;
    } catch (_error) {
      showToast({
        message: _error?.message ?? t('detach.error'),
        type: 'error',
      });
    }
  }

  async function mutateAction(actionKey: 'link' | 'unlink', ids: IWithdraw['userIdCard'][]) {
    actionKey === 'link' ? await attach(ids) : await detach(ids);
  }

  return {
    attach,
    detach,
    mutateAction,
    isLoading: mutation.isMutating ?? false,
    error: mutation.error ?? false,
  };
}
