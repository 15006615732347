import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const TableContainer = styled.div`
  table {
    width: 100%;
    margin-top: ${props => props.theme.spacings.s};
  }

  tr {
    border-bottom: 1px solid ${props => props.theme.colors.neutral.light.light2};
    td {
      :first-child {
        padding-left: ${props => props.theme.spacings.m};
      }
    }

    th {
      :first-child {
        padding-left: ${props => props.theme.spacings.xs};
      }
    }
  }

  td,
  th {
    text-align: left;
    width: 50%;
    padding: ${props => props.theme.spacings.xs4};
  }
`;

export const TitleTable = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 700 !important;
  color: ${props => props.theme.colors.neutral.dark.dark3};
`;

export const ReportInfosTableContainer = styled.div`
  margin-top: ${props => props.theme.spacings.s};
  margin-bottom: ${props => props.theme.spacings.s};
`;

export const DescriptionTable = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600 !important;
  color: ${props => props.theme.colors.neutral.dark.dark3};
`;
