import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { BulkImportCostCenter } from '@pages/BulkImportCostCenters';
import { BulkImportCostCentersDetails } from '@pages/BulkImportCostCentersDetails';
import { CostCenterRegister } from '@pages/PlatformSettings/CostCenters/CostCenterRegister';
import { CostCentersList } from '@pages/PlatformSettings/CostCenters/CostCenters';
import { ImportingContextProvider } from '@shared/contexts/BulkImportContext';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function CostCenterRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['INTERMEDIATE_COST_CENTER']}
          routePermissions={[EAuthZPermission.CONFIGURATION_COST_CENTERS]}
        />
      ),
      errorElement: (
        <RouterErrorBoundary title="Erro ao carregar o módulo de Centro de Custos" moduleName="Centro de Custos" />
      ),
      children: [
        {
          path: Router.getCostCentersRegister(),
          element: <CostCenterRegister />,
        },
        {
          path: Router.getCostCentersRegister(':costCenterId'),
          element: <CostCenterRegister />,
        },
        {
          element: <ImportingContextProvider />,
          children: [
            {
              path: Router.getCostCentersRegisterFile(),
              element: <BulkImportCostCenter />,
            },
            {
              path: Router.getCostCenters(),
              element: <CostCentersList />,
            },
            {
              path: Router.getCostCentersRegisterFileDetails(),
              element: <BulkImportCostCentersDetails />,
            },
          ],
        },
      ],
    },
  ];
}
