import {
  ECriteriaKey,
  ECriteriaStatement,
  ECriteriaType,
  ICriteria,
} from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import {
  ApprovalFlowForm,
  BasicInformationApprovalFlowForm,
} from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { SelectField, Skeleton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldRegister } from '../../../molecules/FieldRegister/FieldRegister';
import { CriteriaFieldValue } from './CriteriaFieldValue/CriteriaFieldValue';
import { CriteriaOperator } from './CriteriaOperator/CriteriaOperator';
import { useIndexLabel } from './hooks/useIndexLabel';
import { IOptionsSource } from './hooks/useOptionsListCriteria';
import * as SC from './styled';

export interface ICriteriaOptionsList {
  statementOptions: IOptionsSource[];
  criteriaKeyOptions: IOptionsSource[];
  operatorOptionsList: IOptionsSource[];
  operatorPartialOptions: IOptionsSource[];
  areasOptions: IOptionsSource[];
  jobPositionOptions: IOptionsSource[];
  categoriesOptions: IOptionsSource[];
  subsidiariesOptions: IOptionsSource[];
  isLoading: boolean;
}

interface IProps {
  criteria: BasicInformationApprovalFlowForm;
  isLoading?: boolean;
  index: number;
  options: ICriteriaOptionsList;
  onChange: (index: number, value: ICriteria) => void;
}

export function ApprovalCriteriaConditionalRow({ criteria, isLoading, index, options, onChange }: IProps) {
  const t = useTranslate('approvalFlows.approvalCriteria.fields');
  const { control, getValues } = useFormContext<ApprovalFlowForm>();
  const indexLabel = useIndexLabel(index);
  const defaultResponsible = 'Solicitante';

  function getOptionsValue() {
    switch (getValues(`criteria.${index}.key`)) {
      case ECriteriaKey.AREA:
        return options.areasOptions;
      case ECriteriaKey.ROLE:
        return options.jobPositionOptions;
      case ECriteriaKey.SUBSIDIARY:
        return options.subsidiariesOptions;
      case ECriteriaKey.CATEGORY:
        return options.categoriesOptions;
    }
  }

  function getOptionsOperator() {
    switch (getValues(`criteria.${index}.key`)) {
      case ECriteriaKey.AREA:
      case ECriteriaKey.ROLE:
      case ECriteriaKey.SUBSIDIARY:
      case ECriteriaKey.CATEGORY:
      case ECriteriaKey.REQUESTER:
      case ECriteriaKey.RESPONSIBLE_PROJECT:
        return options.operatorPartialOptions;
      default:
        return options.operatorOptionsList;
    }
  }

  function isSelectOptions() {
    switch (getValues(`criteria.${index}.key`)) {
      case ECriteriaKey.AREA:
      case ECriteriaKey.ROLE:
      case ECriteriaKey.SUBSIDIARY:
      case ECriteriaKey.CATEGORY:
        return true;
      default:
        return false;
    }
  }

  function isRequester(value: string) {
    return value === ECriteriaKey.REQUESTER || value === ECriteriaKey.RESPONSIBLE_PROJECT;
  }

  return (
    <SC.ConditionalRow>
      {index === 0 && (
        <SC.MainLabelContainer>
          {isLoading ? (
            <Skeleton width={59} height={22} />
          ) : (
            <SC.MainLabel variant="body3">{t('labelMain')}</SC.MainLabel>
          )}
        </SC.MainLabelContainer>
      )}
      {index > 0 && (
        <FieldRegister isLoading={isLoading}>
          <Controller
            name={`criteria.${index}.statement`}
            rules={{
              required: {
                value: !getValues(`criteria.${index}.statement`),
                message: t('field.mandatoryInfo'),
              },
            }}
            control={control}
            defaultValue={''}
            render={({ field: { ref, ...field }, fieldState }) => (
              <SelectField
                {...field}
                inputRef={ref}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                label={`${t('field.label')} ${indexLabel + 1}`}
                onSelectChange={(_, data) => field.onChange(data.value)}
                options={options.statementOptions}
              />
            )}
          />
        </FieldRegister>
      )}

      <FieldRegister isLoading={isLoading}>
        <Controller
          name={`criteria.${index}.key`}
          control={control}
          rules={{
            required: {
              value: !getValues(`criteria.${index}.key`),
              message: t('field.mandatoryInfo'),
            },
          }}
          defaultValue={''}
          render={({ field: { ref, ...field }, fieldState }) => (
            <SC.ContainerField>
              <Tooltip arrow title={!getValues(`criteria.${index}.statement`) && t('field.tooltip')} placement="top">
                <SC.ContainerField>
                  <SelectField
                    {...field}
                    inputRef={ref}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={!getValues(`criteria.${index}.statement`)}
                    label={`${t('field.label')} ${indexLabel + 2}`}
                    onSelectChange={(_, data) =>
                      onChange(index, {
                        ...criteria,
                        key: data.value,
                        operator: undefined,
                        statement: getValues(`criteria.${index}.statement`) as ECriteriaStatement,
                        value: isRequester(data.value) ? defaultResponsible : undefined,
                        type: isSelectOptions ? ECriteriaType.TEXTUAL : undefined,
                      })
                    }
                    options={options.criteriaKeyOptions}
                  />
                </SC.ContainerField>
              </Tooltip>
            </SC.ContainerField>
          )}
        />
      </FieldRegister>

      <CriteriaOperator
        index={index}
        isLoading={isLoading}
        label={`${t('field.label')} ${indexLabel + 3}`}
        operatorOptions={getOptionsOperator()}
      />

      <CriteriaFieldValue
        index={index}
        isLoading={isLoading}
        isRequester={isRequester(getValues(`criteria.${index}.key`))}
        isSelectOptions={isSelectOptions()}
        label={`${t('field.label')} ${indexLabel + 4}`}
        valueOptions={getOptionsValue()}
      />
    </SC.ConditionalRow>
  );
}
