export interface IConfigurationClient {
  id: string;
  name: string;
  externalCode?: string;
  email?: string;
  phoneNumber?: string;
  currencyCode?: string;
  website?: string;
  active: boolean;
  employees?: string[];
}

export enum EOrderBy {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IGetClientsList {
  clients: IConfigurationClient[];
  total: number;
}

export enum EFieldType {
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export interface IClientMutation {
  client: {
    id: number;
  };
  error?: unknown;
}

export interface IClientUpdateMutation {
  data: {
    clientId: number;
    message: string;
    error: boolean;
  };
}
export interface IGetClientById {
  getClient: IConfigurationClient;
}
