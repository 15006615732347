import { IConfigurationPaymentMethod } from '@containers/Customizations/context/types/paymentMethodTypes';
import {
  flashCardPaymentMethodExternalCode,
  refundCardPaymentMethodExternalCode,
  supplierMethodExternalCode,
} from '@shared/constant';

type TProps = {
  paymentMethods: IConfigurationPaymentMethod[];
  selectedItems: IConfigurationPaymentMethod['id'][];
};

export function useCheckDefaultOptions({ paymentMethods, selectedItems }: TProps) {
  function isDefaultOption(_id: IConfigurationPaymentMethod['id']): boolean {
    const _paymentMethod: IConfigurationPaymentMethod = getSelectedPaymentMethods(_id);
    return (
      _paymentMethod?.externalCode?.toUpperCase() === flashCardPaymentMethodExternalCode ||
      _paymentMethod?.externalCode?.toUpperCase() === refundCardPaymentMethodExternalCode ||
      _paymentMethod?.externalCode?.toUpperCase() === supplierMethodExternalCode
    );
  }

  function containsDefaultOption(): boolean {
    return selectedItems?.some(_id => isDefaultOption(_id));
  }

  function getSelectedPaymentMethods(_id: IConfigurationPaymentMethod['id']): IConfigurationPaymentMethod {
    return paymentMethods?.find(_paymentType => _paymentType?.id === _id);
  }

  return {
    isDefaultOption,
    containsDefaultOption,
    getSelectedPaymentMethods,
  };
}
