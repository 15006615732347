import { IReport } from '@containers/Reports/context/types';
import { useWithdrawPermissions } from '@shared/hooks/permissions/systemPermissions/useWithdrawPermissions';

import { WithdrawGrid } from '../WithdrawGrid/WithdrawGrid';
import * as SC from './styled';
import { WithdrawActions } from './WithdrawActions';
import { WithdrawEmptyAction } from './WithdrawEmptyAction/WithdrawEmptyAction';

interface IProps {
  report: IReport;
  isLoading: boolean;
  reportByIdUpDate: () => void;
}

export function WithdrawLinkedReport({ report, isLoading, reportByIdUpDate }: IProps) {
  const getWithdrawPermissions = useWithdrawPermissions();
  const { unlink: allowedUnlink } = getWithdrawPermissions(report?.status ?? null);

  return (
    <SC.Container>
      {allowedUnlink && !isLoading && (
        <>
          {report?.withdraw?.length > 0 ? (
            <SC.ActionsContainer>
              <WithdrawActions report={report} reportByIdUpDate={reportByIdUpDate} />
            </SC.ActionsContainer>
          ) : (
            <WithdrawEmptyAction
              linkedReport={{
                description: report?.description ?? null,
                id: report?.id ?? null,
                name: report?.name ?? null,
                status: report?.status ?? null,
              }}
              reportByIdUpDate={reportByIdUpDate}
            />
          )}
        </>
      )}
      <WithdrawGrid report={report} isLoading={isLoading} reportByIdUpDate={reportByIdUpDate} />
    </SC.Container>
  );
}
