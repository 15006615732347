import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { external } from '@flash-hros/corporate-card';

export function AnalyticsCorporateCardSection() {
  return (
    <ModuleErrorBoundary
      moduleName="Cartão Corporativo"
      title="Erro ao tentar acessar os Insights e reports do cartão corporativo Flash">
      <external.ExternalRoot>
        <external.ReportSection />
      </external.ExternalRoot>
    </ModuleErrorBoundary>
  );
}
