import { FinancialPreview } from '@containers/Financial/components/organisms/FinancialPreview/FinancialPreview';
import { external } from '@flash-hros/corporate-card';
import { useTranslation } from '@locale/Translator';
import { BasePage } from '@organisms/BasePage/BasePage';
import { AccountingContextProvider } from '@shared/contexts/AccountingContext/AccountingContext';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';

export const FinancialReports = () => {
  const { t } = useTranslation();
  useTrackingScreen('financial_reports_screen');

  return (
    <external.ExternalRoot>
      <BasePage
        headerPage={{
          title: t('pages.financialReports.title'),
          faqType: 'accounting',
        }}>
        <AccountingContextProvider>
          <FinancialPreview />
        </AccountingContextProvider>
      </BasePage>
    </external.ExternalRoot>
  );
};
