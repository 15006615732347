import { gql } from '@apollo/client';

export const getAdvanceById = gql`
  query GetAdvanceById($advanceId: Int!) {
    getAdvanceById(advanceId: $advanceId) {
      id
      referenceId
      status
      advanceDate
      value
      currencyCode
      description
      report {
        id
        referenceId
        name
        status
      }
      costCenter {
        id
        name
        referenceId
      }
      project {
        id
        name
        referenceId
      }
      client {
        id
        referenceId
        name
        email
        active
      }
      approver {
        id
        name
        email
      }
      paymentMethod {
        id
        description
      }
      user {
        name
      }
    }
  }
`;

export const attachAdvanceToReport = gql`
  mutation AttachAdvanceToReport($advanceIds: [Int!]!, $reportId: Int!) {
    attachAdvanceToReport(advanceIds: $advanceIds, reportId: $reportId) {
      success
      message
    }
  }
`;

export const getAdvances = gql`
  query GetAdvances(
    $reportId: Int
    $page: Int = 1
    $limit: Int = 10
    $isOpenToAttach: Boolean! = false
    $status: [AdvanceStatus!]
    $paymentMethod: [Int!]
    $startDate: String
    $endDate: String
    $referenceId: String
  ) {
    getAdvances(
      reportId: $reportId
      page: $page
      limit: $limit
      isOpenToAttach: $isOpenToAttach
      status: $status
      paymentMethod: $paymentMethod
      startDate: $startDate
      endDate: $endDate
      referenceId: $referenceId
    ) {
      advances {
        id
        referenceId
        status
        advanceDate
        value
        currencyCode
        description
        report {
          id
          referenceId
          name
          status
        }
        costCenter {
          id
          name
          referenceId
        }
        project {
          id
          name
          referenceId
        }
        client {
          id
          referenceId
          name
          email
          active
        }
        approver {
          id
          name
          email
        }
        paymentMethod {
          id
          description
        }
      }
      pages
      limit
      total
      currentPage
    }
  }
`;
