import { EOrderBy } from '@containers/Expenses/context/types/expenses';
import { IReportSummaries } from '@containers/Reports/context/types';
import { GridPagination, IRowsPerPageOption } from '@molecules/GridPagination';
import { useRowsPerPageOptions } from '@molecules/GridPagination/kooks/useRowsPerPageOptions';
import { skeletonPrintGridRows } from '@shared/constant';

import { ActionsMultipleSelectedReports } from './ActionsMultipleSelectedReports';
import { useBodyGridRows } from './hooks/useBodyGridRow';
import { useBodyGridRowSkeleton } from './hooks/useBodyGridRowSkeleton';
import { useBulkActionsAllowed } from './hooks/useBulkActionsAllowed';
import { EAccessor, useHeaderGridColumns } from './hooks/useHeaderGridColumns';
import { useHeaderGridColumnsSkeleton } from './hooks/useHeaderGridColumnsSkeleton';
import * as SC from './styled';

export interface IGridPagination {
  totalItems: number;
  rowsPerPage?: number;
  selectedPage: number;
  rowsPerPageOptions?: IRowsPerPageOption[];
  onPageClick(page: number): void;
  onRowsPerPageClick(itemsPerPage: number): void;
}

export interface FinancialReportGridProps {
  reports: IReportSummaries[];
  isLoading: boolean;
  pagination?: IGridPagination;
  selectedItems: IReportSummaries['id'][];
  isSelectedAll: boolean;
  onViewClick?(reportsSelected: IReportSummaries['id']): void;
  onReproveClick(reportsSelected: IReportSummaries['id'][]): void;
  onRepayClick(reportsSelected: IReportSummaries['id'][]): void;
  onExportPdfClick(reportsSelected: IReportSummaries['id'][]): void;
  onExportPdfByCategoryClick(reportsSelected: IReportSummaries['id'][]): void;
  onOrderByClick(accessor: EAccessor, order: EOrderBy): void;
  onSelectAllClick(): void;
  onSelectItemClick(id: IReportSummaries['id']): void;
  exportingItems: IReportSummaries['id'][];
}

export const FinancialReportGrid = ({
  reports,
  isLoading,
  pagination,
  selectedItems,
  isSelectedAll,
  onOrderByClick,
  onSelectAllClick,
  onSelectItemClick,
  onViewClick,
  onReproveClick,
  onRepayClick,
  onExportPdfClick,
  onExportPdfByCategoryClick,
  exportingItems,
}: FinancialReportGridProps) => {
  const headerGridColumns = useHeaderGridColumns({
    onOrderByClick,
    onSelectAllClick,
    isSelectedAll,
  });
  const bodyGridRows = useBodyGridRows({
    reports,
    selectedItems,
    onSelectItemClick,
    onViewClick,
    onReproveClick: id => onReproveClick([id]),
    onRepayClick: id => onRepayClick([id]),
    onExportPdfClick: id => onExportPdfClick([id]),
    onExportPdfByCategoryClick: id => onExportPdfByCategoryClick([id]),
    exportingItems,
  });
  const bodyGridRowSkeleton = useBodyGridRowSkeleton({ rowsPerPage: pagination?.rowsPerPage || skeletonPrintGridRows });
  const headerGridColumnsSkeleton = useHeaderGridColumnsSkeleton();
  const multipleActionsAllowed = useBulkActionsAllowed({
    reports,
    selectedItems,
    isExporting: exportingItems.length > 0,
  });
  const { rowsPerPageOptions } = useRowsPerPageOptions({ intervalItems: 10, maxRows: 100 });

  return (
    <SC.Container>
      <SC.GridContainer>
        {Boolean(selectedItems.length) && (
          <ActionsMultipleSelectedReports
            actionsAllowed={multipleActionsAllowed}
            selectedItems={selectedItems}
            totalItems={pagination.totalItems}
            onReproveClick={onReproveClick}
            onRepayClick={onRepayClick}
            onExportPdfClick={onExportPdfClick}
            onExportPdfByCategoryClick={onExportPdfByCategoryClick}
          />
        )}
        <SC.Grid
          columns={isLoading ? headerGridColumnsSkeleton : headerGridColumns}
          data={isLoading ? bodyGridRowSkeleton : bodyGridRows}
          hasPagination
          customPagination={data =>
            Boolean(pagination) && (
              <GridPagination
                {...pagination}
                rowsPerPageOptions={rowsPerPageOptions}
                setPageSize={data.setPageSize}
                isLoading={isLoading}
                showRowsPerPageOptions={true}
              />
            )
          }
          pageCount={pagination.rowsPerPage}
          pageSizeOptions={rowsPerPageOptions}
        />
      </SC.GridContainer>
    </SC.Container>
  );
};
