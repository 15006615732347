import { getExpense, getExpenseCustomFieldsLabels, getExpenseFormById } from '@containers/Expenses/context/queries';
import { showToast } from '@shared/helpers/toast';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';
import { useTracking } from '@shared/hooks/useTracking';
import { TAccessScreen } from '@shared/types';
import { useTranslation } from 'react-i18next';

import { attachExpensesToReportMutation, getReportById } from '../context/queries/report';
import { IAttachExpenseToReportMutation, IAttachExpenseToReportMutationResponse } from '../context/types/report';

export interface IUseReportByIdProps {
  reportId: number;
  expenseIds: number[];
  accessScreen: TAccessScreen;
}

export const useAttachExpenseToReport = () => {
  const { t } = useTranslation();
  const [attachExpenses, { loading, data, error }] = useMutation<
    IAttachExpenseToReportMutationResponse,
    IAttachExpenseToReportMutation
  >(attachExpensesToReportMutation);
  const trackingEvent = useTracking();

  const attachExpenseToReport = async ({ reportId, expenseIds, accessScreen }: IUseReportByIdProps) => {
    const queries = {
      reportDetails: ['GetReportById', 'GetExpenses', 'GetAllExpensesToBind'],
      expenseForm: [
        {
          query: getExpenseFormById,
          variables: {
            id: expenseIds[0],
          },
        },
        'GetExpenseById',
      ],
      expenseDetails: [
        {
          query: getExpense,
          variables: {
            id: expenseIds[0],
          },
        },
        'GetExpenseById',
        {
          query: getExpenseCustomFieldsLabels,
        },
        'GetExpenseCustomFields',
      ],
      newReport: [
        {
          query: getReportById,
          variables: {
            id: reportId,
          },
        },
        'GetExpenses',
        'GetAllExpensesToBind',
      ],
    };
    try {
      const response = await attachExpenses({
        variables: {
          reportId,
          expenseIds,
        },
        refetchQueries: queries[accessScreen],
      });

      trackingEvent('expense_attach_success', {
        reportId: String(reportId),
        expenses: expenseIds.toString(),
      });

      showToast({ message: t('molecules.reportToast.attachExpenseSuccessMessage'), type: 'success' });

      return Promise.resolve(response.data.attachExpensesToReport);
    } catch (error) {
      trackingEvent('expense_attach_error', {
        reportId: String(reportId),
        error: error.message,
      });

      showToast({ message: error.message, type: 'error' });

      return Promise.reject();
    }
  };

  return {
    report: data?.attachExpensesToReport,
    attachExpenseToReport,
    loading,
    error,
  };
};
