import { Icons, Menu } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const IconAdd = styled(Icons)`
  fill: ${props => props.theme.colors.neutral.light.pure};
`;

export const ActionsButton = styled(Menu).attrs({
  type: 'default',
  transformOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
})`
  .MuiPaper-elevation {
    margin: 5px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const DelegateUserViewContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.xs5} ${({ theme }) => theme.spacings.xs4};
  border-radius: ${({ theme }) => theme.borders.radius.xl};
  border: ${({ theme }) => theme.borders.width.s} solid ${({ theme }) => theme.colors.secondary[70]};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  color: ${({ theme }) => theme.colors.secondary[70]};
`;
