import { useContext } from 'react';
import { expensesAdvancesBaseURL, expensesBaseUrl } from '@shared/constant';
import { DelegateContext } from '@shared/contexts/DelegateContext/DelegateContext';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';

import * as SC from './styled';

export function DelegateUserView() {
  const t = useTranslate('pages.sharedExpenses');
  const { userDelegateContext } = useContext(DelegateContext);
  const getCurrentBaseUrl = useCurrentUrlBase();

  if (
    (getCurrentBaseUrl() !== expensesBaseUrl && getCurrentBaseUrl() !== expensesAdvancesBaseURL) ||
    !userDelegateContext?.name
  ) {
    return <></>;
  }

  return (
    <SC.DelegateUserViewContainer>
      <SC.DelegateUserViewDescription variant="body4">
        {`${t('viewingAs')} ${userDelegateContext?.name}`}
      </SC.DelegateUserViewDescription>
    </SC.DelegateUserViewContainer>
  );
}
