import { ChangeEvent } from 'react';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { DatePicker, dayjs, SelectField, TextField, Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import { EstablishmentFieldConfig, Establishments } from '@organisms/Establishments';
import { SelectOption } from '@shared/helpers/selectOptions';
import { NumericFormat } from 'react-number-format';

import * as SC from './styled';

export enum EFieldType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  OPTIONS = 'OPTIONS',
  BAR_CODE = 'BAR_CODE',
  FLAG = 'FLAG',
  ADDRESSFILE = 'ADDRESSFILE',
  CUSTOM = 'CUSTOM',
  ADDRESS = 'ADDRESS',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
}

export interface IFieldFactoryProps<T> {
  fieldType: string;
  error?: boolean;
  helperText?: string;
  label?: string;
  value?: T[keyof T] | string[];
  allowsEditing?: boolean;
  // TODO: Update this type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  options?: SelectOption[];
  isCurrencyValue?: boolean;
  prefixCurrencyValue?: string;
  isLoading?: boolean;
  rowsTextArea?: number;
  isSelectMultiple?: boolean;
  fieldsConfig?: {
    establishment?: EstablishmentFieldConfig;
  };
  singleline?: boolean;
  skeletonDescription?: string;
  limitDate?: { after?: Date; before?: Date };
}

export const FieldFactory = <T extends object>({
  fieldType,
  label,
  value,
  options,
  allowsEditing,
  error,
  helperText,
  isCurrencyValue,
  onChange,
  prefixCurrencyValue,
  isLoading,
  rowsTextArea = 4,
  isSelectMultiple = false,
  fieldsConfig,
  singleline = false,
  skeletonDescription,
  limitDate,
}: IFieldFactoryProps<T>) => {
  const fieldLabel = label && label?.length ? label[0].toUpperCase() + label.substring(1) : '';

  const inputs = {
    [EFieldType.OPTIONS]: () => {
      const currentValue = value as string[];

      return (
        <SC.FieldContainer>
          <SelectField
            value={isSelectMultiple ? currentValue?.map(item => options?.find(option => option.value === item)) : value}
            disabled={!allowsEditing}
            error={error}
            helperText={helperText}
            label={fieldLabel}
            onSelectChange={(event, selected) => {
              onChange?.(isSelectMultiple ? selected?.map(item => item?.value || item) : selected?.value || event);
            }}
            options={options}
            multiple={isSelectMultiple}
            searchable
          />
        </SC.FieldContainer>
      );
    },

    [EFieldType.TEXT]: () => (
      <TextField
        error={error}
        helperText={helperText}
        rows={rowsTextArea}
        disabled={Boolean(isLoading && skeletonDescription) || !allowsEditing}
        label={fieldLabel}
        onChange={event => onChange?.(event, event?.target?.value)}
        value={(isLoading && skeletonDescription) || (value && String(value)) || ''}
        fullWidth
        multiline={isLoading ? false : !singleline}
      />
    ),
    [EFieldType.BAR_CODE]: () => (
      <TextField
        error={error}
        helperText={helperText}
        rows={rowsTextArea}
        disabled={!allowsEditing}
        label={fieldLabel}
        onChange={event => onChange?.(event, event?.target?.value)}
        value={(value && String(value)) || ''}
        fullWidth
        multiline
      />
    ),

    [EFieldType.DATE]: () => (
      <DatePicker
        error={error}
        helperText={helperText}
        disabled={!allowsEditing}
        label={fieldLabel}
        value={Boolean(value) ? dayjs(String(value)) : ''}
        onDateChange={value => onChange?.(value?.isValid() ? value?.format() : '')}
        disabledDate={{
          after: limitDate?.after || null,
          before: limitDate?.before || null,
        }}
      />
    ),

    [EFieldType.NUMBER]: () =>
      isCurrencyValue ? (
        <SC.FieldContainer>
          <NumericFormat
            prefix={prefixCurrencyValue || ''}
            customInput={TextField}
            disabled={!allowsEditing}
            label={fieldLabel}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            onFocus={event => event.target.select()}
            defaultValue={Number(value) ?? null}
            onValueChange={values => {
              onChange?.(Number(values.floatValue));
            }}
            error={error}
            helperText={helperText}
            value={(value as number) ?? null}
          />
        </SC.FieldContainer>
      ) : (
        <TextField
          error={error}
          helperText={helperText}
          disabled={!allowsEditing}
          label={fieldLabel}
          type="number"
          value={value}
          onChange={e => onChange?.(Number(e.target.value))}
        />
      ),

    [EFieldType.FLAG]: () => (
      <SC.ToggleContainer>
        <Toggle
          disabled={!allowsEditing}
          onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => onChange?.(event, checked)}
        />
        <SC.ToggleLabel variant="body4">{fieldLabel}</SC.ToggleLabel>
      </SC.ToggleContainer>
    ),

    [EFieldType.ADDRESS]: () => (
      <Establishments
        onSelectChange={(establishment: IEstablishment) => onChange?.(establishment)}
        value={value as IEstablishment}
        disableClearable={!allowsEditing}
        error={error}
        helperText={helperText}
        config={fieldsConfig?.establishment}
      />
    ),
  };

  if (isLoading) {
    if (skeletonDescription) return inputs[EFieldType.TEXT]?.();

    return (
      <SC.SkeletonField
        variant="rounded"
        height={fieldType === EFieldType.BAR_CODE || fieldType === EFieldType.TEXT ? rowsTextArea * 30 : 58}
        width={'100%'}>
        {skeletonDescription && (
          <SC.ContainerSkeletonData>
            <SC.SkeletonLabel variant="caption">{label}</SC.SkeletonLabel>
            <SC.SkeletonDescription variant="body3">{skeletonDescription}</SC.SkeletonDescription>
          </SC.ContainerSkeletonData>
        )}
      </SC.SkeletonField>
    );
  }

  return inputs[fieldType]?.() || <></>;
};
