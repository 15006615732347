import { useContext } from 'react';
import { CustomizationsPreview } from '@containers/Customizations/components/organisms/CustomizationsPreview';
import { external } from '@flash-hros/corporate-card';
import { useTranslation } from '@locale/Translator';
import { TipsModalController } from '@molecules/TipsModalController';
import { BasePage } from '@organisms/BasePage/BasePage';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';

export const Customizations = () => {
  useTrackingScreen('customizations_screen');
  const { isDisabledExpense } = useContext(SessionContext);

  const { t } = useTranslation();

  return (
    <BasePage
      headerPage={{
        title: t('pages.customizations.title'),
        faqType: 'customizations',
      }}>
      <external.ExternalRoot>
        <CustomizationsPreview />
      </external.ExternalRoot>
      {!isDisabledExpense && <TipsModalController page="customization" />}
    </BasePage>
  );
};
