import { gql } from '@apollo/client';

export const GetResolvedKmPoliciesQuery = gql`
  query GetResolvedKmPolicies {
    getResolvedKmPolicies {
      allowOriginDestination
      allowOdometer
      allowGPS
      allowGPSPin
      allowMultiplePlaces
      allowSavedEstablishments
      allowSearchNewPlaces
    }
  }
`;
