import { IAnalyticsLog } from '@containers/Analytics/context/types/analyticsLogs';
import { Divider } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { EFieldType, FieldFactory } from '@organisms/FieldFactory';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { AnalyticLogDifferenceGrid } from './AnalyticLogDifferenceGrid';
import { Container, Content, FieldDataWrapper, LeftWrapper, RightWrapper, StyledModal } from './styled';

type Props = {
  log: IAnalyticsLog | null;
  open: boolean;
  onCloseModal: () => void;
};

export const AnalyticsLogDetailsModal = ({ onCloseModal, open, log }: Props) => {
  const t = useTranslate('analytics.logs');

  return (
    <StyledModal
      open={open}
      headerTitle={t('modal.logDetails')}
      disableFooter
      footerCustom={<></>}
      onCancelClick={onCloseModal}>
      <Container>
        <Content>
          <LeftWrapper>
            <FieldDataWrapper>
              <Box>
                <FieldFactory
                  fieldType={EFieldType.TEXT}
                  label={t('modal.feature')}
                  allowsEditing={false}
                  value={[log?.extras?.feature || '-']}
                  singleline
                />
              </Box>

              <Box>
                <FieldFactory
                  fieldType={EFieldType.TEXT}
                  label={t('modal.viaAttendance')}
                  allowsEditing={false}
                  value={[log?.extras?.isAttendantChange ? t('common.yes') : t('common.no')]}
                  singleline
                />
              </Box>
            </FieldDataWrapper>
          </LeftWrapper>
          <Divider removeAt={768} orientation="vertical" />
          <Divider appearAt={768} orientation="vertical" />

          <RightWrapper>
            <AnalyticLogDifferenceGrid diff={log?.extras?.diff || []} />
          </RightWrapper>
        </Content>
      </Container>
    </StyledModal>
  );
};
