import { useMemo } from 'react';
import { IExpenseInput, ReceiptFile } from '@containers/Expenses/context/types';
import { useFormContext } from 'react-hook-form';

import { IOdometerFieldProps } from '../OdometerField/OdometerField';

interface IProps {
  odometer: IExpenseInput['odometer'];
  ratePerKm?: number;
  onUploadChange?(variant: IOdometerFieldProps['variant'], file: ReceiptFile): void;
  onValueChange?(variant: IOdometerFieldProps['variant'], value: number): void;
}

interface IUseOdometerFieldsHelper {
  distance: number;
  checkFieldValues: () => { start: number; end: number; isValidValues: boolean };
  calculateDistance: () => number;
  onOdometerValuesChange(): void;
  onOdometerFileChange(variant: IOdometerFieldProps['variant'], file: ReceiptFile);
  calculateTotalValue: (_distance: number) => number;
  onUploadChange?(variant: IOdometerFieldProps['variant'], file: ReceiptFile): void;
  onValueChange?(variant: IOdometerFieldProps['variant'], value: number): void;
  getFileType(attachment: ReceiptFile);
}

export function useOdometerFieldsHelper({ odometer, onUploadChange, onValueChange }: IProps): IUseOdometerFieldsHelper {
  const { getValues, setValue, watch } = useFormContext<IExpenseInput>();
  const distance = useMemo(() => {
    calculateTotalValue();
    return watch('odometer.distance') || 0;
  }, [watch('odometer.distance')]);

  useMemo(() => {
    if (Boolean(odometer)) {
      setValue('odometer.start.value', odometer?.start?.value);
      setValue('odometer.end.value', odometer?.end?.value);
      setValue('odometer.distance', odometer?.distance);
      setValue('odometer.ratePerKm', odometer?.ratePerKm);
      setValue('odometer.start.attachment', odometer?.start?.attachment);
      setValue('odometer.end.attachment', odometer?.end?.attachment);
    }
  }, [odometer]);

  function checkFieldValues() {
    const start = getValues('odometer.start.value');
    const end = getValues('odometer.end.value');
    return {
      start,
      end,
      isValidValues: Boolean(start) && Boolean(end) && start < end,
    };
  }

  function calculateDistance() {
    const _odometerValues = checkFieldValues();
    const _distance: number = _odometerValues.isValidValues ? _odometerValues.end - _odometerValues.start : 0;
    setValue('odometer.distance', _distance);

    return _distance;
  }

  function onOdometerValuesChange() {
    calculateDistance();
    calculateTotalValue();
  }

  function onOdometerFileChange(variant: IOdometerFieldProps['variant'], file: ReceiptFile) {
    setValue(`odometer.${variant}.attachment`, file);
  }

  function calculateTotalValue() {
    const _distance: number = watch('odometer.distance');
    const _totalValue: number = getValues('odometer.ratePerKm') * _distance;
    setValue('value', _totalValue);

    return _totalValue;
  }

  function _onValueChange(variant: IOdometerFieldProps['variant'], value: number) {
    onValueChange?.(variant, value);
    onOdometerValuesChange();
  }

  function _onUploadChange(variant: IOdometerFieldProps['variant'], file: ReceiptFile) {
    onUploadChange?.(variant, file);
    onOdometerFileChange(variant, file);
  }

  function getFileType(attachment: ReceiptFile) {
    if (attachment) {
      const contentType = attachment?.contentType?.split('/');
      return contentType?.[contentType?.length - 1];
    }

    return null;
  }

  return {
    distance,
    checkFieldValues,
    calculateDistance,
    onOdometerValuesChange,
    calculateTotalValue,
    onOdometerFileChange,
    onValueChange: _onValueChange,
    onUploadChange: _onUploadChange,
    getFileType,
  };
}
