import { gql } from '@apollo/client';

export const getAdvancesForApproval = gql`
  query GetAdvancesForApproval($page: Int = 1, $limit: Int = 10, $status: [AdvanceStatus!]) {
    getAdvancesForApproval(page: $page, limit: $limit, status: $status) {
      total
      advances {
        id
        referenceId
        status
        advanceDate
        value
        currencyCode
        description
        report {
          id
          referenceId
          name
          status
        }
        costCenter {
          id
          name
          referenceId
        }
        project {
          id
          name
          referenceId
        }
        client {
          id
          referenceId
          name
          email
          active
        }
        approver {
          id
          name
          email
        }
        paymentMethod {
          id
          description
        }
        user {
          name
        }
      }
    }
  }
`;
