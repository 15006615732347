import { useTranslation } from 'react-i18next';

import { SectionContainer } from '../../../../../components/organisms/SectionContainer/SectionContainer';
import { ApprovalFluxPreview } from '../ApprovalFluxPreview';

export const ApprovalsPreview = () => {
  const { t } = useTranslation();

  return (
    <>
      <SectionContainer
        title={t('organisms.approvalsFluxPreview.accordionTitle')}
        caption={t('organisms.approvalsFluxPreview.accordionDescription')}>
        <ApprovalFluxPreview />
      </SectionContainer>
    </>
  );
};
