import { useEffect, useMemo, useState } from 'react';
import { ReportDetailsPreview } from '@containers/Reports/components/organisms/ReportDetailsPreview';
import { IReport } from '@containers/Reports/context/types';
import { useReportById } from '@containers/Reports/hooks/useReportById';
import { SettingContextProvider } from '@containers/Settings/context';
import { Breadcrumbs, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { expensesApprovalsBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { Trans } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { ActionsFooterPage } from './ActionsFooterPage';
import * as SC from './styled';

export const ApprovalsReportDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { reportById, report, reportIsLoading } = useReportById();
  const [currentReport, setCurrentReport] = useState<IReport>(null);

  async function getReportById() {
    try {
      const response = await reportById({
        variables: {
          id: Number(id),
        },
      });
      setCurrentReport(response.data?.getReportById);
    } catch (error) {
      showToast({ message: error?.message, type: 'error' });
    }
  }

  useEffect(() => {
    getReportById();
  }, [id]);

  useMemo(() => {
    if (report) setCurrentReport(report);
  }, [report]);

  return (
    <SettingContextProvider>
      <Trans i18nKey="translations">
        <SC.PageContainer>
          <SC.BreadcrumbsContainer>
            <Breadcrumbs
              breadcrumbs={[
                <Link key="approval-expense" to={expensesApprovalsBaseURL}>
                  {t('pages.approvals.title')}
                </Link>,
                <Link key="report-details" to={''}>
                  {t('report.reportDetails')}
                </Link>,
              ]}
              separator={<Icons name="IconChevronRight" fill="transparent" />}
            />
          </SC.BreadcrumbsContainer>
          <ReportDetailsPreview report={currentReport} isLoading={reportIsLoading} />
        </SC.PageContainer>
        <ActionsFooterPage reportStatus={currentReport?.status} actionType={'approve'} />
      </Trans>
    </SettingContextProvider>
  );
};
