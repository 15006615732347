import { useMemo, useState } from 'react';
import { IReport } from '@containers/Reports/context/types';
import { IWithdraw } from '@containers/Withdraw/context/types/types';
import { useAttachWithdrawToReport } from '@containers/Withdraw/hooks/dataSources/useAttachWithdrawToReport';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import { useSelectionRows } from '@organisms/Grid/BatchActions/hooks/useSelectionRows';
import { useWithdrawPermissions } from '@shared/hooks/permissions/systemPermissions/useWithdrawPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

type TKeyAction = 'link' | 'unlink';

export function useWithdrawActionsGrid(report: IReport, reportByIdUpDate: () => void) {
  const t = useTranslate('withdraw.grid');
  const getWithdrawPermissions = useWithdrawPermissions();
  const { unlink: allowedUnlink } = getWithdrawPermissions(report?.status ?? null);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [isBatchAction, setIsBatchAction] = useState<boolean>(false);
  const [keyAction, setKeyAction] = useState<TKeyAction>(null);
  const [withdrawSelected, setWithdrawSelected] = useState<IWithdraw>();
  const withdraw = useMemo(
    () => report?.withdraw?.map(_withdraw => ({ ..._withdraw, id: _withdraw.userIdCard })) || [],
    [report?.withdraw],
  );
  const { isSelectedAll, selectedItems, toggleSelectAll, onSelectItemClick, unSelectAllClick } =
    useSelectionRows<IWithdraw>(withdraw);
  const { mutateAction, isLoading } = useAttachWithdrawToReport(report?.id);

  async function onConfirmationAction() {
    await mutateAction(keyAction, selectedItems.length ? selectedItems : [withdrawSelected.userIdCard]);
    await reportByIdUpDate();
    selectedItems.length && unSelectAllClick();
    showConfirmationModal && setShowConfirmationModal(false);
  }

  function getSelectedWithdraw(_id: IWithdraw['userIdCard']): IWithdraw {
    return report?.withdraw?.find(_withdraw => _withdraw?.userIdCard === _id);
  }

  function onActionClick(_keyAction: TKeyAction, _id?: IWithdraw['userIdCard']) {
    const _withdrawSelectedIds: IWithdraw['userIdCard'][] = _id ? [_id] : selectedItems;
    const _isBatchAction: boolean = _withdrawSelectedIds.length > 1;
    setIsBatchAction(_isBatchAction);
    setKeyAction(_keyAction);
    !_isBatchAction && setWithdrawSelected(getSelectedWithdraw(_withdrawSelectedIds[0]));
    setShowConfirmationModal(true);
  }

  function getBatchActions() {
    return [
      {
        allowed: true,
        disabled: !allowedUnlink,
        name: t('body.actions.unlink'),
        iconName: 'IconUnlink' as IconTypes,
        tooltipMessage: getMessageTooltip(),
        key: 'unlink',
        onClick: () => onActionClick('unlink'),
      },
    ];
  }

  function getMessageTooltip() {
    return !allowedUnlink ? t(`tooltips.notDetach.${isBatchAction ? 'plural' : 'singular'}`) : null;
  }

  return {
    showConfirmationModal,
    isSelectedAll,
    selectedItems,
    isBatchAction,
    isLoadingAction: isLoading,
    withdrawSelected,
    getMessageTooltip,
    onActionClick,
    toggleSelectAll,
    unSelectAllClick,
    onSelectItemClick,
    onConfirmationAction,
    getBatchActions,
    closeConfirmationModal: () => setShowConfirmationModal(false),
  };
}
