export function useSerializeCustomFields() {
  function serializeCustomFields(customFields: object) {
    if (customFields)
      return Object.keys(customFields).reduce((result, field) => {
        if (customFields[field])
          return [
            ...result,
            {
              id: field,
              value: customFields[field],
            },
          ];

        return [...result];
      }, []);

    return [];
  }

  return serializeCustomFields;
}
