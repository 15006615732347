import { EAuthZPermission } from '@shared/types/authZPermission';

import { usePermissionsAuthZ } from './usePermissionsAuthZ';

export function usePlatformPermissions() {
  const { checkPermissionAuthZ, permissions } = usePermissionsAuthZ();

  function checkPlatformPermission(permission: EAuthZPermission) {
    return checkPermissionAuthZ(permission);
  }

  return {
    permissions,
    checkPlatformPermission,
  };
}
