import React, { useEffect, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { IConfigurationPaymentMethod } from '@containers/Customizations/context/types/paymentMethodTypes';
import { usePaymentMethods } from '@containers/Customizations/hooks/PaymentMethods/rest/usePaymentMethods';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { Search } from '@molecules/GridFilters/Search/Search';
import { ActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ColorVariant } from 'src/themes/types/Variants';

import { PaymentMethodConfirmationActionsModal } from '../PaymentMethodConfirmationActionsModal/PaymentMethodConfirmationActionsModal';
import { PaymentMethodModal } from '../PaymentMethodModal/PaymentMethodModal';
import { usePaymentMethodsActionsGrid } from './hooks/usePaymentMethodsActionsGrid';
import * as SC from './styled';

enum EAccessor {
  SELECT_ALL = 'selectAll',
  CODE = 'code',
  PAYMENT_METHOD = 'paymentMethod',
  STATUS = 'status',
  MILEAGE = 'mileage',
  REFUNDABLE = 'refundable',
  ACTIONS = 'actions',
}

export function PaymentMethodsGrid() {
  const t = useTranslate('organisms.customizationsPaymentMethods');
  const { paymentMethods, isLoading } = usePaymentMethods();
  const [inputFilterField, setInputFilterField] = useState<string>('');
  const [currentPaymentMethods, setCurrentPaymentMethods] = useState<IConfigurationPaymentMethod[]>(paymentMethods);
  const {
    showEditModal,
    paymentMethodSelected,
    showConfirmationModal,
    isSelectedAll,
    selectedItems,
    confirmationType,
    isBatchAction,
    isLoadingAction,
    listIdsLoading,
    getActionsRow,
    unSelectAllClick,
    getBatchActions,
    toggleSelectAll,
    onSelectItemClick,
    onShowConfirmationActionsModal,
    onShowEditModal,
    isDefaultOption,
    onConfirmationActionModal,
  } = usePaymentMethodsActionsGrid(currentPaymentMethods);

  function getTooltipMessageHeader(accessor: EAccessor): string {
    return accessor === EAccessor.MILEAGE || accessor === EAccessor.REFUNDABLE
      ? t(`grid.header.tooltips.${accessor}`)
      : null;
  }

  function getColumns(): IGridColumn[] {
    return Object.values(EAccessor).map(accessor => ({
      accessor,
      disableSortBy: true,
      Header: (
        <ColumnHeader
          isLoading={isLoading}
          description={t(`grid.header.${accessor}`)}
          tooltipMessage={getTooltipMessageHeader(accessor)}
          isSelectedAll={accessor === EAccessor.SELECT_ALL}
          onSelectAllClick={toggleSelectAll}
          selectAllChecked={isSelectedAll}
        />
      ),
      sticky: accessor === EAccessor.ACTIONS && 'right',
    }));
  }

  function getRows(): Record<EAccessor, React.ReactNode>[] {
    return currentPaymentMethods?.map(_paymentMethod => {
      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(_paymentMethod?.id)}
            checked={selectedItems.some(_id => _id === _paymentMethod?.id)}
          />
        ),
        code: _paymentMethod?.externalCode?.toUpperCase(),
        paymentMethod: _paymentMethod?.name,
        status: getTag('active', _paymentMethod?.active),
        mileage: getTag('connected', _paymentMethod?.mileage),
        refundable: getTag('connected', _paymentMethod.refundable),
        actions: (
          <ActionsRow
            referenceId={_paymentMethod.id}
            actions={getActionsRow(_paymentMethod.id)}
            isLoading={listIdsLoading?.includes(_paymentMethod.id)}
          />
        ),
      };
    });
  }

  function getDescriptionTag(type: 'connected' | 'active', isActive: boolean): string {
    return type === 'active'
      ? t(`grid.body.${(isActive && 'active') || 'deactivate'}`)
      : t(`grid.body.${(isActive && 'yes') || 'not'}`);
  }

  function getVariantTag(type: 'connected' | 'active', isActive: boolean): ColorVariant {
    return isActive ? (type === 'active' && 'success') || 'secondary' : 'neutral';
  }

  function getTag(type: 'connected' | 'active', isActive: boolean) {
    return (
      <StatusTag showStatusIcons={type === 'active'} variant={getVariantTag(type, isActive)}>
        {getDescriptionTag(type, isActive)}
      </StatusTag>
    );
  }

  function getFilterItemsList() {
    return paymentMethods?.filter(_paymentMethod =>
      _paymentMethod?.name?.toLowerCase()?.includes(inputFilterField?.toLowerCase()),
    );
  }

  useEffect(() => {
    if (!isLoading && paymentMethods) {
      setCurrentPaymentMethods(Boolean(inputFilterField) ? getFilterItemsList() : paymentMethods || []);
    }
  }, [paymentMethods, inputFilterField]);

  return (
    <SC.Container>
      <Search
        label={t('searchFieldLabel')}
        onChange={_value => {
          selectedItems?.length && unSelectAllClick();
          setInputFilterField(_value);
        }}
        isLoading={isLoading}
        value={inputFilterField}
      />
      <Grid
        columns={getColumns()}
        rows={getRows()}
        batchActions={getBatchActions()}
        isLoading={isLoading}
        hasPagination={false}
        totalItems={currentPaymentMethods?.length || 0}
        numberItemsSelected={selectedItems?.length || 0}
      />
      {showConfirmationModal && (
        <PaymentMethodConfirmationActionsModal
          open={showConfirmationModal}
          confirmationType={confirmationType}
          onActionClick={onConfirmationActionModal}
          onClose={onShowConfirmationActionsModal}
          isBatchAction={isBatchAction}
          isLoading={isLoadingAction}
        />
      )}
      {showEditModal && (
        <PaymentMethodModal
          open={showEditModal}
          onCloseModal={onShowEditModal}
          paymentMethod={paymentMethodSelected}
          isDefaultOption={isDefaultOption(paymentMethodSelected?.id)}
        />
      )}
    </SC.Container>
  );
}
