import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';

import ExpensesOnly from './PageVariants/ExpensesOnly';
import ExpensesTabs from './PageVariants/ExpensesTabs';

export const Expenses = () => {
  const { checkPlatformPermission } = usePlatformPermissions();
  const expensePayablesEnabled = checkPlatformPermission(EAuthZPermission.EXPENSES_PAYABLES_VIEW);

  return expensePayablesEnabled ? <ExpensesTabs /> : <ExpensesOnly />;
};
