import { gql } from '@apollo/client';

export const getReportsForApproval = gql`
  query GetReportsForApproval($page: Int!, $filters: ReportForApprovalFilter) {
    getReportForApproval(page: $page, filters: $filters) {
      reports {
        status
        user {
          name
        }
        id
        referenceId
        name
        violationNumber
        commentsNumber
        value
        currencyPrefix
        createdAt
      }
      total
      currentPage
      pages
      limit
    }
  }
`;

export const updateReportListStatus = gql`
  mutation UpdateReportListStatus($reports: [Int!]!, $status: ReportStatusActions!, $message: String) {
    updateReportListStatus(reports: $reports, status: $status, message: $message) {
      reports {
        id
        message
        error
      }
    }
  }
`;

export const getExcelBase64ReportsForApproval = gql`
  query GetExcelBase64ReportsForApproval($filters: ReportForFinanceFilter) {
    getExcelBase64ReportsForApproval(filters: $filters)
  }
`;
