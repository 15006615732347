import { ReactNode } from 'react';

import * as SC from './styled';

interface IProps {
  items: ReactNode[];
}

export function MultipleStickContainer({ items }: IProps) {
  return (
    <SC.Container>
      {items.map((item, index) => (
        <SC.Content key={index}>{item}</SC.Content>
      ))}
    </SC.Container>
  );
}
