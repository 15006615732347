import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const Caption = styled(Typography)`
  font-weight: 600 !important;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
