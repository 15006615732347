import { IPolicyBase } from '@containers/Policies/context/types/types';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function usePoliciesApplied(shouldFetch: boolean = true) {
  const t = useTranslate('policy.policyGrid.feedbacks.list');
  const { data, error, isLoading, mutate } = useFetch<{ policies: IPolicyBase[] }>(
    `me/policies`,
    undefined,
    undefined,
    undefined,
    error => showToast({ title: t('error.title'), message: error?.message || t('error.message'), type: 'error' }),
    shouldFetch,
  );

  return {
    policies: data?.policies ?? [],
    isLoading: isLoading && !data,
    error,
    refetch: () => mutate(),
  };
}
