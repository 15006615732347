import { RegisterProjectByFileForm } from '@containers/Projects/components/organisms/RegisterProjectByFileForm/RegisterProjectByFileForm';
import { useTranslation } from '@locale/Translator';
import { BulkImportFiles } from '@molecules/BulkImportFiles';
import { PageHeader } from '@organisms/BasePage/PageHeader/PageHeader';
import { FormProvider, useForm } from 'react-hook-form';

import { ProjectFooterPage } from './ProjectFooterPage/ProjectFooterPage';
import { ProjectRegisterFileHeader } from './ProjectRegisterFileHeader';
import * as SC from './styled';

export const BulkImportProjects = () => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <>
      <ProjectRegisterFileHeader />
      <FormProvider {...methods}>
        <SC.Container>
          <PageHeader title={t('pages.projectRegister.title')} />
          <BulkImportFiles
            title={t('organisms.registerClient.bulkImport.sendData.title')}
            subtitle={t('organisms.registerClient.bulkImport.sendData.subTitle.projects')}>
            <RegisterProjectByFileForm />
          </BulkImportFiles>
        </SC.Container>
        <ProjectFooterPage />
      </FormProvider>
    </>
  );
};
