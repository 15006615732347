import { gql } from '@apollo/client';

export const getApproversQuery = gql`
  query GetApprovers($context: ApproverListContextType!) {
    getApprovers(context: $context) {
      approvers {
        name
        id
        email
      }
      total
    }
  }
`;
