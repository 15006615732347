import { Loader } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Box } from '@mui/material';

import * as SC from './styled';

export const SubscriptionLoading = () => {
  const { t } = useTranslation();

  return (
    <SC.Container px={{ xs: '10%', lg: '30%' }}>
      <Loader size="large" variant="primary" />

      <Box mt={6} mb={3}>
        <SC.Title variant="headline6">{t('pages.subscriptionConfirmation.loading.title')}</SC.Title>
      </Box>

      <SC.Description variant="body3">{t('pages.subscriptionConfirmation.loading.message')}</SC.Description>
    </SC.Container>
  );
};
