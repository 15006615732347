import { useMemo } from 'react';
import { Selected } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/controllers/useSelectedRows';
import { Row } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types';

type TProps<T extends object> = Selected<T> & {
  rows: Row<T>[];
};

export function useSelectedTable<T extends { id: unknown }>({
  selected = [],
  allSelected = false,
  rows = [],
}: TProps<T>) {
  const selectedItems = useMemo((): { selectedData: T[]; selectedIds: T['id'][] } => {
    const selectedIds = [];
    const selectedData = [];

    if (allSelected)
      rows
        ?.filter(row => !selected.some(item => item.original.id === row.original.id))
        ?.forEach(row => {
          row.original?.id && selectedIds.push(row.original.id);
          selectedData.push(row.original);
        });
    else
      selected?.forEach(row => {
        row.original.id && selectedIds.push(row.original.id);
        selectedData.push(row.original);
      });

    return {
      selectedData,
      selectedIds,
    };
  }, [selected]);

  return {
    countSelectedRows: selectedItems.selectedIds.length || selectedItems.selectedData.length || 0,
    ...selectedItems,
    allSelected,
  };
}
