import { Switch } from '@mui/material';
import styled from 'styled-components';

export const SwitchInput = styled(Switch)`
  padding: 0;
  width: 36px;
  height: 20px;
  margin-left: 11px;
  margin-right: 8px;

  .MuiSwitch-switchBase {
    padding: 0;
    &:hover {
      background: none;
    }
  }

  .MuiSwitch-track {
    border-radius: 12px;
  }

  .Mui-checked {
    transform: translateX(16px);
    color: white;

    & + .MuiSwitch-track {
      background-color: ${props => props.theme.colors.primary};
      opacity: 1;
    }
  }

  .MuiSwitch-thumb {
    padding: 0px;
    box-shadow: none;
    width: 16px;
    height: 16px;
    margin: 2px;
    background-color: ${props => props.theme.colors.neutral.light.pure};
  }
`;
