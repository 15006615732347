import { createContext, useReducer } from 'react';

import { settingsReducer } from './reducer/reducer';
import { initialSettingsState } from './store';
import { ISettingsContextProps, ISettingsContextState, SettingsProviderProps } from './types';

export const SettingsContext = createContext<ISettingsContextProps>({
  state: {} as ISettingsContextState,
  dispatch: () => {},
});

export const SettingContextProvider = ({ children, value }: SettingsProviderProps) => {
  const [state, dispatch] = useReducer(settingsReducer, value ?? initialSettingsState);

  return <SettingsContext.Provider value={{ state, dispatch }}>{children}</SettingsContext.Provider>;
};
