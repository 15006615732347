import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import CostCenterRegisterBreadcrumb from '../CostCenterRegisterBreadcrumb/CostCenterRegisterBreadcrumb';
import * as SC from './styled';

interface IProps {
  step: number;
  isEditing: boolean;
}

export const CostCenterRegisterProgressHeader = ({ step = 0, isEditing }: IProps) => {
  const t = useTranslate('pages.costCenterRegister');

  return (
    <SC.Header>
      <CostCenterRegisterBreadcrumb isEditing={isEditing} />
      <Stepper activeStep={step} steps={[t('steps.header.generalData'), t('steps.header.users')]} />
    </SC.Header>
  );
};
