import { useDateFormatter } from '@shared/hooks';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

function isIsoDate(str: unknown) {
  if (!str) return false;

  if (str instanceof Date) return true;

  if (typeof str !== 'string') return false;

  const isoDateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

  return isoDateRegex.test(str);
}

export function useLogValueFormatter() {
  const t = useTranslate('analytics.logs');
  const { getDateWithTimeFormatter } = useDateFormatter();

  function formatValue(value: unknown): string {
    if (typeof value === 'boolean') {
      return value ? t('common.yes') : t('common.no');
    }

    if (isIsoDate(value)) {
      return getDateWithTimeFormatter(value);
    }

    return value ? String(value) : ' - ';
  }

  return {
    formatValue,
  };
}
