import { useContext } from 'react';
import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { Analytics } from '@pages/Analytics';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function AnalyticsRoutes(): RouteObject[] {
  const { isDisabledExpense } = useContext(SessionContext);

  if (isDisabledExpense) {
    return [
      {
        element: <PermissionsGuard routePermissions={[EAuthZPermission.ANALYTICS_VIEW]} />,
        errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Analytics" moduleName="Analytics" />,
        children: [
          {
            path: Router.getAnalytics(),
            element: <Analytics />,
          },
        ],
      },
    ];
  }

  return [
    {
      element: (
        <PermissionsGuard routeFeatures={['BASIC_ANALYTICS']} routePermissions={[EAuthZPermission.ANALYTICS_VIEW]} />
      ),
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Analytics" moduleName="Analytics" />,
      children: [
        {
          path: Router.getAnalytics(),
          element: <Analytics />,
        },
      ],
    },
  ];
}
