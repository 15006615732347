import React from 'react';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { DropdownItem } from '@molecules/DropdownItem';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';

import { ReportNameGrid } from '../components/organisms/ReportGrid/ReportNameGrid/ReportNameGrid';
import { ReportPeriodGrid } from '../components/organisms/ReportGrid/ReportPeriodGrid/ReportPeriodGrid';
import { ReportStatusGrid } from '../components/organisms/ReportGrid/ReportStatusGrid/ReportStatusGrid';
import { ActionsButton, Description, IconDots } from '../components/styled';
import { EReportStatus, IReport } from '../context/types/report';
import { EAccessor } from './useReportGridHeader';

export type TGridRow = Record<EAccessor, React.ReactNode>;

export interface IUseReportBodyGridRowsProps {
  reports: IReport[];
  selectedReports: (number | string)[];
  onSelectReport(id: number): void;
  onDeleteReport(id: number): void;
  onViewReport(id: number): void;
  onEditReport(id: number): void;
}

export const useReportBodyGridRows = ({
  reports,
  selectedReports,
  onSelectReport,
  onDeleteReport,
  onViewReport,
}: IUseReportBodyGridRowsProps): TGridRow[] => {
  const { t } = useTranslation();
  const { getValueWithCurrency } = useValueFormatter();
  const getReportActionOptions = (report: IReport) => {
    const options = [
      {
        children: <DropdownItem icon="IconEye" description={t('organisms.reportGrid.viewReport')} />,
        onClick: () => onViewReport(report.id),
      },
    ];

    if (EReportStatus.OPEN == report.status) {
      options.push({
        children: <DropdownItem icon="IconTrash" description={t('organisms.reportGrid.deleteReport')} />,
        onClick: () => onDeleteReport(report.id),
      });
    }

    return options;
  };

  return reports?.map(report => {
    return {
      selection: <Checkbox onChange={() => onSelectReport(report.id)} checked={selectedReports.includes(report.id)} />,
      name: <ReportNameGrid name={report.name} status={report.status} />,
      id: <Description>{report.referenceId}</Description>,
      activePeriod: <ReportPeriodGrid period={report.activePeriod} />,
      value: (
        <Description>
          {getValueWithCurrency({ value: report.value || 0, currencyPrefix: report.currencyPrefix })}
        </Description>
      ),
      status: <ReportStatusGrid status={report.status} />,
      actions: (
        <ActionsButton options={getReportActionOptions(report)}>
          <IconDots />
        </ActionsButton>
      ),
    };
  });
};
