import { useAllCostCenters } from '@containers/CostCenters/hooks/rest/useAllCostCenters';
import { FieldRegister } from '@containers/Policies/componentes/molecules/FieldRegister/FieldRegister';
import { IRegisterSubsidiary } from '@containers/Subsidiaries/context/types/subsidiaries';
import { SubsidiaryFormData } from '@containers/Subsidiaries/validators/subsidiaryFormSchema';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Box, Grid } from '@mui/material';
import { EFieldType, FieldFactory } from '@organisms/FieldFactory';
import { Controller, useFormContext } from 'react-hook-form';

interface CostCenterInfoProps {
  isLoading: boolean;
}

export const CostCenterInfo = ({ isLoading }: CostCenterInfoProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<SubsidiaryFormData>();
  const { costCentersCompany: costCenters, isLoading: isLoadingCostCenters } = useAllCostCenters({
    page: 0,
    searchName: '',
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3} marginY={3}>
        <Typography variant="headline8">{t('organisms.subsidiaryRegister.sections.costCenterInfo')}</Typography>
      </Box>
      <Grid item xs={12}>
        <FieldRegister isLoading={isLoading}>
          <Controller
            control={control}
            name="costCenters"
            render={({ field: { ...field } }) => (
              <FieldFactory<IRegisterSubsidiary>
                {...field}
                fieldType={EFieldType.OPTIONS}
                label={t('organisms.subsidiaryRegister.fields.costCenters')}
                value={field.value || []}
                isSelectMultiple
                allowsEditing={!isLoadingCostCenters}
                options={costCenters.map(costCenter => {
                  return { value: costCenter.id, label: costCenter.name };
                })}
                onChange={newValue => {
                  field.onChange(newValue);
                }}
              />
            )}
          />
        </FieldRegister>
      </Grid>
    </>
  );
};
