import { useState } from 'react';
import { IMileageRate } from '@containers/Customizations/context/types/policies';
import { useDeleteMileageRate } from '@containers/Customizations/hooks/useDeleteMileageRate';
import { useMileageByPolicyId } from '@containers/Customizations/hooks/useMilageByPolicyId';
import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Skeleton } from '@mui/material';
import { showToast } from '@shared/helpers/toast';

import { DeleteMileageRateConfirmationModal } from '../../molecules/DeleteMileageRateConfirmationModal';
import { MileageRateRegisterModal } from '../../molecules/MileageRateRegisterModal';
import { MileageRateRow } from '../../molecules/MileageRateRow';

type Props = {
  policyId: number | undefined;
  isPolicyLoading: boolean;
};

export const MileageRatesPreview = ({ policyId, isPolicyLoading }: Props) => {
  const { t } = useTranslation();
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [mileageRateToDelete, setMileageRateToDelete] = useState<number>();
  const {
    mileagePolicy,
    refetch: refetchMileage,
    isLoading: isLoadingMileagePolicy,
  } = useMileageByPolicyId({ id: policyId });
  const [editingMileageRate, setEditingMileageRate] = useState<IMileageRate>();
  const { deleteMileageRate, isLoading: isDeleting } = useDeleteMileageRate({ policyId });
  const isLoading = isLoadingMileagePolicy || isPolicyLoading;

  async function handleDeleteMileageRate() {
    try {
      await deleteMileageRate(mileageRateToDelete);

      refetchMileage();

      showToast({
        message: t('organisms.mileageRatesPreview.feedbacks.mileageRateDeletedSuccessfully'),
        type: 'success',
      });
    } catch (err) {
      showToast({
        message: err.message || t('organisms.mileageRatesPreview.feedbacks.errorDeletingMileageRate'),
        type: 'error',
      });
    } finally {
      setMileageRateToDelete(undefined);
    }
  }

  return (
    <>
      <Button
        size="medium"
        variant="primary"
        onClick={() => {
          setEditingMileageRate(undefined);
          setIsRegisterModalOpen(true);
        }}>
        <Icons name="IconPlus" fill="transparent" />

        {t('organisms.mileagePreview.mileageRates.addKmRate')}
      </Button>

      {!isLoading &&
        mileagePolicy?.policyMileageRatesSettings?.map(rate => (
          <MileageRateRow
            key={rate.id}
            startDate={new Date(rate.startDate)}
            mileageRate={Number(rate.rate)}
            onDeleteClick={() => setMileageRateToDelete(rate.id)}
            onEditClick={() => {
              setEditingMileageRate(rate);
              setIsRegisterModalOpen(true);
            }}
          />
        ))}

      {isLoading &&
        new Array(5)
          .fill(null)
          .map((_, index) => <Skeleton variant={'rounded'} width={'100%'} height={84} sx={{ mt: 2 }} key={index} />)}

      <DeleteMileageRateConfirmationModal
        open={!!mileageRateToDelete}
        onCancel={() => setMileageRateToDelete(undefined)}
        onDelete={() => handleDeleteMileageRate()}
        isLoading={isDeleting}
      />

      <MileageRateRegisterModal
        open={isRegisterModalOpen}
        onClose={() => setIsRegisterModalOpen(false)}
        policyId={policyId}
        mileageRate={editingMileageRate}
      />
    </>
  );
};
