import { AdvancePolicyLimit } from '@containers/Policies/context/types/advances';

import { useAdvancesPolicySettings } from './useAdvancesPolicySettings';
import { useUpdateAdvancePolicySettings } from './useUpdateAdvancePolicySettings';

type Props = {
  policyId: string;
};

export function useSaveAdvanceLimit({ policyId }: Props) {
  const { handleUpdate, isLoading: isSaving } = useUpdateAdvancePolicySettings(policyId);
  const { policy, isLoading: isLoadingPolicy } = useAdvancesPolicySettings(policyId);

  async function saveAdvanceLimit(limit: AdvancePolicyLimit) {
    const limits = policy.limits.filter(l => l.currencyCode !== limit.currencyCode);

    limits.push(limit);

    await handleUpdate({ limits });
  }

  return {
    saveAdvanceLimit,
    isLoading: isSaving || isLoadingPolicy,
  };
}
