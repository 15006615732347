import { useMemo } from 'react';

import currenciesJSON from './currencies.json';

export function useAllCurrencyCodes() {
  const currencies = useMemo(() => {
    return Object.keys(currenciesJSON) as Array<keyof typeof currencies>;
  }, []);

  return { currencies };
}
