import { EFieldType, IConfigurationClient } from '@containers/Clients/context/types/clientTypes';

import * as SC from './styled';
export interface ExpenseFieldFactoryProps {
  fieldType: EFieldType;
  error: boolean;
  helperText: string;
  label: string;
  value?: IConfigurationClient[keyof IConfigurationClient];
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  description?: string;
  required?: boolean;
}

export const ClientFieldFactory = ({
  fieldType,
  label,
  value,
  error,
  helperText,
  onChange,
  description,
  required,
}: ExpenseFieldFactoryProps) => {
  const inputs = {
    [EFieldType.TEXT]: (
      <div>
        <SC.Input
          error={error}
          helperText={helperText}
          rows={4}
          label={label}
          onChange={onChange}
          value={value && String(value)}
          required={required}
          className={required && 'required'}
        />
        {description && <SC.InputDescription variant="body4">{description}</SC.InputDescription>}
      </div>
    ),
    [EFieldType.EMAIL]: (
      <SC.Input
        error={
          value?.toString()?.trim() !== '' &&
          value !== undefined &&
          value !== null &&
          !/.+@.+\.[A-Za-z]+$/.test(String(value))
        }
        helperText={helperText}
        rows={4}
        label={label}
        onChange={onChange}
        value={value && String(value)}
        imaskProps={{
          mask: /^\S*@?\S*$/,
        }}
      />
    ),
    [EFieldType.PHONE]: (
      <SC.Input
        error={error}
        helperText={helperText}
        rows={4}
        label={label}
        onChange={onChange}
        value={value && String(value)}
        imaskProps={{
          mask: '(00) 00000-0000',
        }}
      />
    ),

    [EFieldType.BOOLEAN]: (
      <SC.ToggleContainer>
        <SC.ToggleSwitch onChange={onChange} checked={Boolean(value)} />
        <SC.ToggleLabel variant="body3">{label}</SC.ToggleLabel>
        <SC.ToggleDescription variant="body4">{description}</SC.ToggleDescription>
      </SC.ToggleContainer>
    ),
  };

  return inputs[fieldType] || <></>;
};
