import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { mutate } from 'swr';

import { ICostCenterCompany } from '../../context/types/costCenters';

interface IResponse {
  id: ICostCenterCompany['id'];
  error: boolean;
  message: string;
}

export function useDeleteBatchCostCenters(costCenters: ICostCenterCompany[]) {
  const { mutation } = useMutate('v1/cost-centers');
  const t = useTranslate('pages.costCenterRegister.feedbacks.costCenterDeleteSuccess');

  async function getDeleteBathCostCenters(ids: ICostCenterCompany['id'][]) {
    const params = ids.map(_id => ({
      key: 'ids[]',
      value: _id,
    }));

    try {
      const response: IResponse[] = await mutation.trigger({
        path: '',
        parameters: params,
        options: {
          method: 'DELETE',
        },
      });
      const containsError = response?.some(costCenters => costCenters.error);

      if (containsError) {
        response?.forEach(costCenter => {
          const costCenterName = costCenters?.find(_costCenter => _costCenter.id == costCenter.id)?.name ?? '';
          showToast({
            message: `${costCenterName ? costCenterName + ' - ' : ''}${
              costCenter.error ? costCenter.message : t('singular')
            }`,
            type: costCenter.error ? 'error' : 'success',
          });
        });
      } else {
        showToast({
          message: `${t('plural')}`,
          type: 'success',
        });
      }

      mutate(key => typeof key === 'string' && key.includes('v1/cost-centers?page='));
    } catch (err) {
      showToast({
        message: err.message,
        type: 'error',
      });
    }
  }

  return {
    getDeleteBathCostCenters,
    error: mutation.error,
    isLoading: mutation.isMutating,
  };
}
