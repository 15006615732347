import { EExpenseType } from '@containers/Expenses/context/types';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';

export function useDefaultValues() {
  const expenseType = useCheckExpenseType();
  const isMileage: boolean = expenseType === EExpenseType.ORIGIN_DESTINATION || expenseType === EExpenseType.ODOMETER;

  if (isMileage)
    return {
      route: { waypoints: [{}, {}], ratePerKm: 0, distance: 0 },
      value: null,
      odometer: {
        ratePerKm: 0,
        distance: 0,
      },
    };

  return null;
}
