import { useEffect, useMemo, useState } from 'react';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { IConfigurationCategory, IGetConfigurationCategories } from '../context/types/categoryTypes';
import { serializeMutateCategory } from '../utils/serializeCategoryConfiguration';

export const useAllConfigurationCategories = () => {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const {
    data: categoriesData,
    error,
    isLoading,
  } = useFetch<IGetConfigurationCategories>('v1/categories', '', undefined, undefined, onError);

  return {
    error,
    isLoading: isLoading,
    categories: categoriesData?.categories ?? [],
  };
};

export const useAllCategories = () => {
  const { categories, isLoading } = useAllConfigurationCategories();
  const [selectFieldFormatCategories, setSelectFieldFormatCategories] = useState([]);

  useEffect(() => {
    const newArray = categories?.map(category => ({
      label: category.name,
      value: category.id,
    }));

    setSelectFieldFormatCategories(newArray);
  }, [JSON.stringify(categories)]);

  return {
    isLoadingCategories: isLoading,
    categories: categories ?? [],
    selectFieldFormatCategories,
  };
};

const initialCategory: IConfigurationCategory = {
  id: null,
  active: true,
  name: '',
  iconUrl: '',
  readOnly: false,
  externalCode: '',
};

export const useCategoryMutate = () => {
  const t = useTranslate('organisms.categoryFormModal.feedback');
  const [category, setCategory] = useState<IConfigurationCategory>(initialCategory);
  const { mutation } = useMutate('v1/categories');
  const body = useMemo(() => JSON.stringify(serializeMutateCategory(category)), [JSON.stringify(category)]);

  const createCategory = async () => {
    try {
      const result = await mutation.trigger(
        {
          path: '',
          options: {
            method: 'POST',
            body,
          },
        },
        {
          revalidate: false,
          populateCache: updateCache,
        },
      );
      showToast({ message: t('create.success'), type: 'success' });
      return result;
    } catch (error) {
      showToast({ message: error.message || t('create.error'), type: 'error' });
    }
  };

  const updateCategory = async () => {
    try {
      const result = await mutation.trigger(
        {
          path: `${category.id}`,
          options: {
            method: 'PATCH',
            body,
          },
        },
        {
          revalidate: false,
          populateCache: updateCache,
        },
      );
      showToast({ message: t('update.success'), type: 'success' });
      return result;
    } catch (error) {
      showToast({ message: error.message || t('update.error'), type: 'error' });
    }
  };

  const toggleCategory = async (category: IConfigurationCategory, toggle: boolean) => {
    const _category = { ...category, active: toggle };
    const body = serializeMutateCategory(_category);
    setCategory(category);
    const result = await mutation.trigger(
      {
        path: `${category.id}`,
        options: {
          method: 'PATCH',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
        populateCache: updateCache,
      },
    );
    return result;
  };

  const updateCache = (updatedItem, cachedData) => {
    const categories: IConfigurationCategory[] = [];
    cachedData?.categories?.forEach(item => {
      let category = item;
      if (category.id == updatedItem.id) {
        category = updatedItem;
      }

      categories.push(category);
    });

    if (!categories.some(item => item.id == updatedItem.id)) {
      categories.push(updatedItem);
    }

    return {
      categories: categories,
    };
  };

  const removeCacheItem = (itemId, cachedData) => {
    const categories: IConfigurationCategory[] = [];
    cachedData?.categories?.forEach(item => {
      const category = item;
      if (category.id != itemId) {
        categories.push(category);
      }
    });

    return {
      categories: categories,
    };
  };

  const toggleAllCategories = async (toggle: boolean) => {
    const result = await mutation.trigger({
      path: `toggle`,
      options: {
        method: 'POST',
        body: JSON.stringify({ active: toggle }),
      },
    });
    return result;
  };

  const activeCategory = async (category: IConfigurationCategory) => {
    return await toggleCategory(category, true);
  };

  const disableCategory = async (category: IConfigurationCategory) => {
    return await toggleCategory(category, false);
  };

  const disableAllCategories = async () => {
    return await toggleAllCategories(false);
  };

  const enableAllCategories = async () => {
    return await toggleAllCategories(true);
  };

  const deleteCategory = async (category: IConfigurationCategory) => {
    setCategory(category);
    try {
      const result = await mutation.trigger(
        {
          path: `${category.id}`,
          options: {
            method: 'DELETE',
          },
        },
        {
          revalidate: false,
          populateCache: (updatedItem, cachedData) => removeCacheItem(category.id, cachedData),
        },
      );
      cleanCategory();
      return result;
    } catch (error) {
      showToast({ message: error.message, type: 'error' });
      cleanCategory();
    }
  };

  const loadCategory = (category: IConfigurationCategory) => {
    setCategory(category);
  };

  const mutateCategory = () => {
    if (!category.id) {
      return createCategory();
    }

    return updateCategory();
  };

  const handleCategory = (field: keyof Omit<IConfigurationCategory, 'id'>, value: string | boolean | number) => {
    setCategory(currentCategory => ({ ...currentCategory, [field]: value }));
  };

  const cleanCategory = () => {
    setCategory(initialCategory);
  };

  return {
    isLoading: mutation.isMutating,
    category,
    loadCategory,
    createCategory,
    updateCategory,
    handleCategory,
    cleanCategory,
    disableCategory,
    activeCategory,
    deleteCategory,
    mutateCategory,
    disableAllCategories,
    enableAllCategories,
    isLoadingAllCategories: mutation.isMutating,
  };
};
