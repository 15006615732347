import { IConfigurationAdvancesSetting } from '@containers/Customizations/context/types/advances';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function useAdvancesSettingsMutate() {
  const t = useTranslate('advancesSettings.feedbacks');
  const onError = () =>
    showToast({
      message: t('error'),
      type: 'error',
    });
  const { mutation } = useMutate('v1/advances/configuration', onError);

  async function updateAdvancesSetting(data: IConfigurationAdvancesSetting) {
    await mutation.trigger(
      {
        options: {
          method: 'PUT',
          body: JSON.stringify(data),
        },
      },
      {
        revalidate: false,
      },
    );
  }

  return {
    updateAdvancesSetting,
    success: mutation.error == undefined,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
