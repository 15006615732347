import { useState } from 'react';
import { IExportPdfReports } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { expensesAccountingBaseURL, expensesApprovalsBaseURL } from '@shared/constant';
import { downloadPdfFile } from '@shared/helpers/files';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import qs from 'query-string';

export function usePdfFileExportReports() {
  const { t } = useTranslation();
  const [filtersPdf, setFiltersPdf] = useState<IExportPdfReports>(null);
  const params = qs.stringify(filtersPdf, { arrayFormat: 'bracket' });
  const getCurrentUrlBase = useCurrentUrlBase();
  const { data, error, isLoading, mutate } = useFetch<Blob>(
    getPath(),
    undefined,
    undefined,
    undefined,
    error => showToast({ message: error?.message, type: 'error' }),
    Boolean(filtersPdf),
  );

  function getPath() {
    switch (getCurrentUrlBase()) {
      case expensesApprovalsBaseURL:
        return `v1/reports/approval/pdf-file?${params}`;
      case expensesAccountingBaseURL:
        return `v1/reports/financial/pdf-file?${params}`;
      default:
        return `v1/reports/pdf-file?${params}`;
    }
  }

  if (data && !isLoading && !error) {
    downloadPdfFile(data, `${t('organisms.financialReportGrid.report')} ${new Date().toISOString()}`);
    filtersPdf && setFiltersPdf(null);
  }

  if (!data && error && filtersPdf) {
    showToast({
      message: t(
        `organisms.financialReportGrid.actionsOptions.feedbacks.export.${
          error.message?.includes('undefined')
            ? 'notFound'
            : `${filtersPdf.reportIds?.length > 1 ? 'pluralForm' : 'singularForm'}.error`
        }`,
      ),
      type: 'error',
    });
    setFiltersPdf(null);
  }

  return {
    getPdfReports: data => setFiltersPdf({ ...data }),
    exporting: filtersPdf?.reportIds || [],
    isLoading: isLoading,
    error,
    refetch: () => mutate(),
  };
}
