import { AddressInfo } from '../AddressInfo/AddressInfo';
import { CostCenterInfo } from '../CostCenterInfo';
import { SubsidiaryRegisterGeneralData } from '../SubsidiaryRegisterGeneralData/SubsidiaryRegisterGeneralData';
import * as SC from './styled';

type Props = {
  isLoading: boolean;
};

export const SubsidiaryRegisterForm = ({ isLoading }: Props) => {
  return (
    <SC.Container>
      <SubsidiaryRegisterGeneralData isLoading={isLoading} />

      <AddressInfo isLoading={isLoading} />

      <CostCenterInfo isLoading={isLoading} />
    </SC.Container>
  );
};
