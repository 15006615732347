import { SubscriptionInformation } from '@containers/Subscription/components/molecules/SubscriptionInformation/SubscriptionInformation';
import { TravelContractInformation } from '@containers/Subscription/context/types/contractInformation';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { useTravelConditions } from '../hooks/useTravelConditions';

type Props = {
  travelContract?: TravelContractInformation;
};

export const TravelCommercialConditions = ({ travelContract }: Props) => {
  const t = useTranslate();

  const { travelCommercialConditions } = useTravelConditions(travelContract);

  return (
    <SubscriptionInformation
      title={t('organisms.subscriptionDetails.travel.title')}
      items={travelCommercialConditions}
    />
  );
};
