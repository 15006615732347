import { useEffect, useState } from 'react';
import { Icons, RadioButtonCard } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useTranslation } from 'react-i18next';

import * as SC from './styled';

export enum EAddLimitType {
  SINGLE = 'single',
  SHARED = 'shared',
}

interface SelectLimitTypeModalProps {
  onSelectLimitType(type: EAddLimitType): void;
  open: boolean;
  onClose(): void;
}

export const SelectLimitTypeModal = ({ onSelectLimitType, open, onClose }: SelectLimitTypeModalProps) => {
  const { t } = useTranslation();
  const [limitType, setLimitType] = useState<EAddLimitType>(null);
  const translationBase: string = 'molecules.selectLimitTypeModal';

  useEffect(() => {
    !open && setLimitType(null);
  }, [open]);

  return (
    <DefaultModal
      open={open}
      onCloseClick={onClose}
      onActionClick={() => onSelectLimitType(limitType)}
      headerTitle={t(`${translationBase}.title`)}
      headerSubtitle={t(`${translationBase}.subtitle`)}
      actionDisabled={!Boolean(limitType)}
      labelButtonAction={
        <>
          {t(`${translationBase}.continue`)} <Icons name="IconArrowRight" fill="transparent" />
        </>
      }>
      <SC.ButtonContainer>
        <RadioButtonCard
          icon={'IconSquare'}
          checked={limitType === EAddLimitType.SINGLE}
          description={t(`${translationBase}.single.description`)}
          title={t(`${translationBase}.single.title`)}
          onClick={() => setLimitType(EAddLimitType.SINGLE)}
        />
        <RadioButtonCard
          icon={'IconCategory'}
          checked={limitType === EAddLimitType.SHARED}
          description={t(`${translationBase}.shared.description`)}
          title={t(`${translationBase}.shared.title`)}
          onClick={() => setLimitType(EAddLimitType.SHARED)}
        />
      </SC.ButtonContainer>
    </DefaultModal>
  );
};
