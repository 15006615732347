import React, { ReactNode } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import {
  ELimitsActivePropertyColorVariant,
  ELimitsActiveStatusPropertyColorVariant,
  ILimitGrid,
} from '@containers/Customizations/context/types/limits';
import { Checkbox, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';

import { LimitsGridActions } from '../LimitsGridActions/LimitsGridActions';
import * as SC from '../styled';
import { EAccessor } from './useHeaderGridColumns';

export type TGridRow = Record<EAccessor, React.ReactNode>;

export interface UseBodyGridRowsProps {
  limits: ILimitGrid[];
  selectedItems: (number | string)[];
  allowedActions: boolean;
  onSelectItemClick(id: ILimitGrid['id']): void;
  onDisableClick?(id: ILimitGrid['id'][], active: boolean): void;
  onDeleteClick?(id: ILimitGrid['id'][]): void;
  exportingItems?: ILimitGrid['id'][];
  onEditClick?(limit: ILimitGrid): void;
}

export function useBodyGridRows({
  limits,
  selectedItems,
  allowedActions,
  onSelectItemClick,
  onEditClick,
  onDisableClick,
  onDeleteClick,
}: UseBodyGridRowsProps): TGridRow[] {
  const { t } = useTranslation();
  const { getValueWithCurrency } = useValueFormatter();

  function getCategoryData(categoryLimits: ILimitGrid['categoryLimitsPolicy']): ReactNode {
    let tooltipMsg: string = '';

    const _categoryLimits: string = categoryLimits?.length
      ? categoryLimits
          .slice(0, 3)
          ?.map(category => category.categoryDescription)
          .join(', ')
      : '-';

    if (categoryLimits?.length > 3) {
      tooltipMsg = categoryLimits
        .slice(3)
        ?.map(category => category.categoryDescription)
        .join(', ');
    }

    return (
      <SC.TdDescription>
        <SC.CategoriesContainer>
          <div>{_categoryLimits}</div>
          {categoryLimits?.length > 3 && (
            <div>
              <Tooltip arrow title={tooltipMsg} placement="top-start">
                <div>{`... [+${categoryLimits?.length - 3}]`}</div>
              </Tooltip>
            </div>
          )}
        </SC.CategoriesContainer>
      </SC.TdDescription>
    );
  }

  return limits?.map(limit => {
    return {
      selection: (
        <Checkbox
          onChange={() => onSelectItemClick(limit?.limitPolicyId)}
          checked={selectedItems.includes(limit.limitPolicyId)}
        />
      ),
      category: getCategoryData(limit.categoryLimitsPolicy),
      criterion: <SC.TdDescription>{t(`organisms.limitGrid.criterionOptions.${limit?.criterion}`)}</SC.TdDescription>,
      limit: (
        <SC.TdDescription>
          {getValueWithCurrency({ currencyPrefix: limit.currency, value: limit.value })}
        </SC.TdDescription>
      ),
      status: (
        <StatusTag variant={ELimitsActiveStatusPropertyColorVariant[`${limit.active}`]}>
          {t(`organisms.limitGrid.active.${limit.active}`)}
        </StatusTag>
      ),
      limitBlocksSending: (
        <StatusTag variant={ELimitsActivePropertyColorVariant[`${!limit.allowValueGreaterThanLimit}`]}>
          {t(`organisms.limitGrid.${!limit.allowValueGreaterThanLimit}`)}
        </StatusTag>
      ),
      proofRequired: (
        <StatusTag variant={ELimitsActivePropertyColorVariant[`${limit.receiptRequired}`]}>
          {t(`organisms.limitGrid.${limit.receiptRequired}`)}
        </StatusTag>
      ),
      commentRequired: (
        <StatusTag variant={ELimitsActivePropertyColorVariant[`${limit.commentRequired}`]}>
          {t(`organisms.limitGrid.${limit.commentRequired}`)}
        </StatusTag>
      ),
      actions: (
        <LimitsGridActions
          allowedActions={allowedActions}
          limit={limit}
          onEditClick={onEditClick}
          onDisableClick={onDisableClick}
          onDeleteClick={onDeleteClick}
        />
      ),
    };
  });
}
