import { useContext } from 'react';
import { Loader } from '@atoms/Loader';
import { IExpenseInput } from '@containers/Expenses/context/types';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { ExpenseRegisterContext } from '@shared/contexts/ExpenseRegisterContext/ExpenseRegisterContext';
import { showToast } from '@shared/helpers/toast';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

interface IProps {
  methods: UseFormReturn<IExpenseInput, Record<string, unknown>>;
  hasExpense: boolean;
  isLoading: boolean;
  onContinueClick: (data: IExpenseInput) => void;
}

export function ExpenseRegisterFooterPage({ methods, isLoading, hasExpense, onContinueClick }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ocrIsLoading } = useContext(ExpenseRegisterContext);

  function onInvalidFields() {
    showToast({
      title: t('pages.expenseRegister.requiredFields'),
      message: t('pages.expenseRegister.requiredFieldsMgs'),
      type: 'warning',
    });
  }

  return (
    <>
      <div>
        <LinkButton variant="default" onClick={() => navigate(-1)}>
          {t('pages.expenseRegister.cancel')}
        </LinkButton>
      </div>
      <SC.ActionsButtonsFooterContainer>
        <Button variant="secondary" size="medium" onClick={() => navigate(-1)} disabled={ocrIsLoading}>
          <SC.ButtonContent>
            <Icons name="IconArrowLeft" fill="transparent" />
            {t('pages.expenseRegister.comeBack')}
          </SC.ButtonContent>
        </Button>
        <Button
          variant="primary"
          size="medium"
          type="submit"
          onClick={methods.handleSubmit(onContinueClick, onInvalidFields)}
          loading={ocrIsLoading}
          disabled={isLoading}>
          {t('pages.expenseRegister.continue')}
          <Icons name="IconArrowRight" color="#fff" fill="transparent" />
        </Button>
      </SC.ActionsButtonsFooterContainer>

      <Loader
        show={isLoading}
        description={
          !hasExpense ? t('pages.expenseRegister.creatingExpense') : t('pages.expenseRegister.updatingExpense')
        }
      />
    </>
  );
}
