import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import { IConfigurationProject } from '../../context/types/projectTypes';

interface IProps {
  page?: number;
  searchName?: string;
  shouldFetch?: boolean;
}

interface IGetProjectsSettings {
  projects: IConfigurationProject[];
  total: number;
}

export const useAllProjects = ({ page = 0, searchName = '', shouldFetch = true }: IProps) => {
  const { t } = useTranslation();
  const onError = () => showToast({ message: t('pages.projectRegister.feedbacks.gettingMany.error'), type: 'error' });

  const params = new URLSearchParams({ page: page.toString(), searchName });
  const { data, isLoading, error, mutate } = useFetch<IGetProjectsSettings>(
    `v1/projects?${params}`,
    '',
    undefined,
    undefined,
    onError,
    shouldFetch,
  );

  return {
    projects: data?.projects || [],
    totalProjects: data?.total || 0,
    isLoading: isLoading,
    error,
    refetch: mutate,
  };
};
