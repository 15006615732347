import { Checkbox as CheckboxComponent, Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: 'space-between';
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: 'space-between';
  gap: ${({ theme }) => theme.spacings.xs4};
  margin-right: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 700;
`;

export const TooltipIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const Checkbox = styled(CheckboxComponent)`
  height: 20px;
  width: 20px;
  div {
    height: 20px;
    width: 20px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;
