import { useEffect, useState } from 'react';
import { CustomApprovalFlowsGrid } from '@containers/ApprovalFlows/CustomApprovalFlow/components/organisms/CustomApprovalFlowsGrid/CustomApprovalFlowsGrid';
import { setInLS } from '@flash-tecnologia/hros-web-utility';
import { ActionsButton } from '@molecules/ActionsButton/ActionsButton';
import { BasePage } from '@organisms/BasePage/BasePage';
import { approvalFlowsBaseURL, expensesApprovalsBaseURL, expensesCustomizationsBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { useTargetCustomApprovalFlow } from '../../containers/ApprovalFlows/CustomApprovalFlow/hooks/useTargetCustomApprovalFlow';

export function CustomApprovalFlows() {
  const t = useTranslate('approvalFlows.pageGrid');
  const navigate = useNavigate();
  const target = useTargetCustomApprovalFlow();
  const [totalApprovalFlows, setTotalApprovalFlows] = useState<number>();

  useEffect(() => {
    setInLS({ key: 'totalApprovalFlows', value: totalApprovalFlows });
  }, [totalApprovalFlows]);

  return (
    <BasePage
      headerPage={{
        title: t(`title.${target}`),
        actions: (
          <ActionsButton
            actionName={t('addButton')}
            onClick={() =>
              navigate(`${approvalFlowsBaseURL}/${target}/register`, {
                state: {
                  totalApprovalFlows: totalApprovalFlows || 0,
                },
              })
            }
            actionIcon="IconPlus"
          />
        ),
        breadcrumbItems: [
          {
            to: expensesApprovalsBaseURL,
            label: t('breadcrumb.approvals'),
          },
          {
            to: expensesCustomizationsBaseURL,
            label: t('breadcrumb.manageApprovals'),
          },
          {
            to: '',
            label: t(`title.${target}`),
          },
        ],
      }}>
      <CustomApprovalFlowsGrid setTotalApprovalFlows={setTotalApprovalFlows} />
    </BasePage>
  );
}
