import { IGetSubsidiaryById } from '@containers/Subsidiaries/context/types/subsidiaries';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export function useSubsidiaryById(subsidiaryId: string) {
  const { t } = useTranslation();

  const { data, error, isLoading } = useFetch<IGetSubsidiaryById>(
    `v1/subsidiaries/${subsidiaryId}`,
    undefined,
    undefined,
    undefined,
    () =>
      !!subsidiaryId && showToast({ message: t('pages.subsidiaryRegister.feedbacks.gettingOne.error'), type: 'error' }),
    !!subsidiaryId,
  );

  return {
    subsidiary: data,
    isLoading,
    error,
  };
}
