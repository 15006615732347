import { Radio, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

interface IProps {
  isLoading: boolean;
  isDisabled?: boolean;
  fields?: {
    label?: string;
    field: React.ReactNode;
  }[];
  isChecked: boolean;
  onClick(): void;
}

export function SetDueDateSection({ isLoading, fields, isChecked, isDisabled, onClick }: IProps) {
  if (isLoading) {
    return (
      <SC.Content>
        <SC.ContentRadio>
          <Skeleton variant="circular" width={20} height={20} style={{ marginRight: '16px' }} />
          <Skeleton variant="rounded" width={100} height={20} />
        </SC.ContentRadio>
        <Skeleton variant="rounded" height={40} width={'100%'} />
        <Skeleton variant="rounded" height={20} width={'100%'} />
        <Skeleton variant="rounded" height={40} width={'100%'} />
      </SC.Content>
    );
  }
  return (
    <SC.Content>
      <SC.ContentRadio>
        <Radio checked={isChecked} onChange={onClick} />
        <SC.Content>
          {fields?.[0]?.label && (
            <SC.Text isDisabled={isDisabled} variant="body3">
              {fields[0].label}
            </SC.Text>
          )}
          {fields?.[0]?.field && fields[0].field}
        </SC.Content>
      </SC.ContentRadio>
      <SC.Content>
        {fields?.splice(1)?.map((data, key) => (
          <SC.Content key={key}>
            <>
              {data.label && (
                <SC.Text isDisabled={isDisabled} variant="body3">
                  {data.label}
                </SC.Text>
              )}
              {data?.field && data?.field}
            </>
          </SC.Content>
        ))}
      </SC.Content>
    </SC.Content>
  );
}
