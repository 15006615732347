import { TextField } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
  width: 647px;
`;

export const ReportField = styled(TextField)`
  width: 100%;
  max-width: 647px;
`;
