import { useState } from 'react';
import { TextArea } from '@atoms/TextArea';
import { useTranslation } from '@locale/Translator';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';

import * as SC from './styled';

interface JustificationModalRemoveExpenseProps {
  isOpen: boolean;
  isLoading?: boolean;
  expensesNumber: number;
  onActionClick(justificationText: string): void;
  onCloseClick(): void;
}

export const JustificationModalRemoveExpense = ({
  isOpen,
  isLoading,
  expensesNumber,
  onActionClick,
  onCloseClick,
}: JustificationModalRemoveExpenseProps) => {
  const { t } = useTranslation();
  const [justificationText, setJustificationText] = useState('');
  const formOfDescription = expensesNumber > 1 ? 'pluralForm' : 'singularForm';

  function onClose() {
    onCloseClick();
    setJustificationText('');
  }

  function onAction() {
    onActionClick(justificationText);
    setJustificationText('');
  }

  return (
    <SC.Container>
      <DangerActionModal
        isLoading={isLoading}
        headerIconLabel={t('organisms.justificationModalRemoveExpense.attention')}
        headerTitle={t(`organisms.justificationModalRemoveExpense.${formOfDescription}.popupTitle`, {
          expensesSelected: expensesNumber,
        })}
        headerSubtitle={t(`organisms.justificationModalRemoveExpense.${formOfDescription}.popupSubtitle`)}
        actionDisabled={justificationText?.length < 3}
        labelButtonAction={
          <SC.LabelButtonContent>
            {t(`organisms.justificationModalRemoveExpense.${formOfDescription}.confirmDisapproval`)}
          </SC.LabelButtonContent>
        }
        onActionClick={onAction}
        onCancelClick={onClose}
        onCloseClick={onClose}
        open={isOpen}>
        <SC.TextAreaLabel variant="body3">
          {t(`organisms.justificationModalRemoveExpense.${formOfDescription}.justifyTheDisapproval`)}
        </SC.TextAreaLabel>
        <TextArea
          label={t(`organisms.justificationModalRemoveExpense.enterTheJustification`)}
          onChange={(_, value) => setJustificationText(value)}
          value={justificationText}
          resize="none"
          rows={5}
        />
      </DangerActionModal>
    </SC.Container>
  );
};
