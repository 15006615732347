import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { AdvanceRegister } from '@pages/Advances/AdvanceRegister/AdvanceRegister';
import { ExpenseDetails } from '@pages/ExpenseDetails';
import { FinancialAccountability } from '@pages/Financial/FinancialAccountability';
import { FinancialAdvances } from '@pages/Financial/FinancialAdvances';
import { FinancialReportDetails } from '@pages/Financial/FinancialReportDetails';
import { FinancialReports } from '@pages/Financial/FinancialReports';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function AccountingRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard routeFeatures={['BASIC_EXPENSE']} routePermissions={[EAuthZPermission.ACCOUNTING_VIEW]} />
      ),
      errorElement: (
        <RouterErrorBoundary title="Erro ao carregar o módulo de Contabilizações" moduleName="Contabilizações" />
      ),
      children: [
        {
          path: Router.getAccounting(),
          element: <FinancialReports />,
        },
        {
          path: Router.getAccountingReports(),
          element: <FinancialReports />,
        },
        {
          path: Router.getAccountingReportDetails(':id'),
          element: <FinancialReportDetails />,
        },
        {
          path: Router.getAccountingExpenseDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          path: Router.getAccountingOriginDestinationDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          path: Router.getAccountingOdometerDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          path: Router.getAccountingAdvancedDetails(':id'),
          element: <AdvanceRegister />,
        },
        {
          path: Router.getAccountability(),
          element: <FinancialAccountability />,
        },
      ],
    },
    {
      element: (
        <PermissionsGuard
          routeFeatures={['ADVANCED_ADVANCE']}
          routePermissions={[EAuthZPermission.ADVANCES_REPORTS_VIEW, EAuthZPermission.ADVANCES_REPORTS_ACTION]}
        />
      ),
      errorElement: (
        <RouterErrorBoundary
          title="Erro ao carregar o módulo de Contabilizações de adiantamentos"
          moduleName="Contabilizações"
        />
      ),
      children: [
        {
          path: Router.getAccountingAdvances(),
          element: <FinancialAdvances />,
        },
      ],
    },
  ];
}
