import {
  EApprovalSourceType,
  ECriteriaKey,
  ECriteriaOperator,
  ECriteriaStatement,
  ECriteriaType,
  ECustomApprovalTarget,
  ECustomApprovalType,
  IApprovalFlow,
} from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import { ApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';

export function useCustomApprovalFlowInitialValues(approvalFlow: IApprovalFlow): ApprovalFlowForm {
  const currentPath = useCurrentPath();

  if (!approvalFlow) {
    return {
      name: '',
      active: true,
      criteria: [{ statement: ECriteriaStatement.WHEN, operator: undefined, value: undefined }],
      sources: [{}],
      target: currentPath.includes('reports') ? ECustomApprovalTarget.REPORT : ECustomApprovalTarget.ADVANCED,
      type: ECustomApprovalType.MANUAL,
      priority: getFromLS('totalApprovalFlows') + 1,
      customMessage: '',
    };
  }

  function getConfigTypeSource(configType: EApprovalSourceType) {
    switch (configType) {
      case EApprovalSourceType.COST_CENTER_OWNER_WITHOUT_DEFAULT:
      case EApprovalSourceType.COST_CENTER_OWNER:
        return EApprovalSourceType.COST_CENTER_OWNER;
      case EApprovalSourceType.IMMEDIATE_MANAGER:
      case EApprovalSourceType.SECOND_LEVEL_MANAGER:
      case EApprovalSourceType.THIRD_LEVEL_MANAGER:
      case EApprovalSourceType.FOURTH_LEVEL_MANAGER:
      case EApprovalSourceType.FIFTH_LEVEL_MANAGER:
        return EApprovalSourceType.APPROVAL_BY_HIERARCHY;
      default:
        return configType;
    }
  }

  function getSources(): IApprovalFlow['sources'] | object[] {
    return approvalFlow?.sources?.length
      ? approvalFlow?.sources?.map(source => ({
          configType: getConfigTypeSource(source.type),
          approvalFlowId: source.approvalFlowId,
          approverId: source.approverId,
          type: EApprovalSourceType[source.type],
          id: source.id,
        }))
      : [{}];
  }

  function getCriteria(): IApprovalFlow['criteria'] {
    return approvalFlow?.criteria?.length
      ? approvalFlow?.criteria?.map(criterion => ({
          id: criterion.id,
          approvalFlowId: criterion.approvalFlowId,
          key: ECriteriaKey[criterion.key],
          operator: ECriteriaOperator[criterion.operator],
          statement: ECriteriaStatement[criterion.statement],
          type: ECriteriaType[criterion.type],
          value: String(criterion.value),
        }))
      : [{ statement: ECriteriaStatement.WHEN, operator: undefined, value: undefined }];
  }

  return {
    id: approvalFlow.id,
    name: approvalFlow?.name,
    active: approvalFlow?.active,
    criteria: getCriteria(),
    sources: getSources(),
    target: ECustomApprovalTarget[approvalFlow?.target],
    type: ECustomApprovalType[approvalFlow?.type],
    priority: approvalFlow?.priority,
    description: approvalFlow?.description,
    customMessage: approvalFlow.customMessage ?? '',
  };
}
