import { IEstablishment } from '@containers/Expenses/context/types/establishments';

export function useSerializeWaypoints(establishments: IEstablishment[]) {
  function serializeRouteOptions(waypoints: IEstablishment[]) {
    const _waypoints: IEstablishment[] = [];

    waypoints?.forEach(waypoint => {
      const route = serializeRouteOption(waypoint);
      if (route) {
        _waypoints.push(route);
      }
    });

    return _waypoints;
  }

  function serializeRouteOption(_route: IEstablishment): IEstablishment {
    return establishments?.find(
      establishment =>
        _route.placeId == establishment.placeId || (establishment.lat == _route.lat && establishment.lng == _route.lng),
    );
  }

  return serializeRouteOptions;
}
