import { ISettingDispatchActions } from './actions';

export interface ISettingsContextState {
  step: number;
  categories: ICategory[];
  limits: ICategoryLimit[];
  currencies: ICurrency[];
}

export type SettingsDispatch = React.Dispatch<ISettingDispatchActions>;

export interface ISettingsContextProps {
  state: ISettingsContextState;
  dispatch: SettingsDispatch;
}

export interface SettingsProviderProps {
  children: React.ReactNode;
  value?: ISettingsContextState;
}

export interface ICategory {
  id: number;
  active: boolean;
  description: string;
  financeReferenceId: string;
  iconUrl: string;
  readOnly: boolean;
}

export type ICreateCategoryMutation = {
  category: Omit<ICategory, 'id'>;
};

export type ICreateCategoryMutationResponse = {
  createCategory: ICategory;
};

export type IUpdateCategoryMutation = {
  category: ICategory;
};

export interface IGetCategories {
  getAllLegacyCategories: {
    categories: ICategory[];
  };
}

export type IDeleteCategoryMutation = {
  categoryId: ICategory['id'];
};
export interface IGetCategoryLimits {
  getLimitsByCategoryId: {
    limits: ICategoryLimit[];
  };
}

export type IUpdateCategoryLimitsMutation = {
  limit: ICategoryLimitUpdate;
  categoryId: number;
  policyId: number;
};

export interface ICategoryLimitUpdate {
  allowValueGreaterThanLimit: boolean;
  commentRequired: boolean;
  minimumInvoiceProofValue: number;
  currencyId: number;
  criterion: ECriterionLimit;
  value: number;
  active: boolean;
}
export interface ICategoryLimit {
  id: number;
  allowValueGreaterThanLimit: boolean;
  commentRequired: boolean;
  isProofMandatory: boolean;
  minimumInvoiceProofValue: number;
  policy: {
    id: number;
    name: string;
  };
  currency: {
    id: number;
    code: string;
  };
  criterion: ECriterionLimit;
  value: number;
  active: boolean;
  limitId: number;
  categoryId: number;
}

export type ICurrency = {
  code: string;
  symbol: string;
  name: string;
  id: number;
};

export interface IGetCurrencies {
  getAllCurrencies: {
    currencies: ICurrency[];
  };
}

export enum ECriterionLimit {
  DAY = 'DAY',
  EXPENSE = 'EXPENSE',
  QUANTITY = 'QUANTITY',
  MONTH = 'MONTH',
}
