export function useIndexLabel(index: number) {
  function getIndexLabel() {
    switch (index) {
      case 0:
        return index - 1;
      case 1:
        return 3 * index;
      default:
        return 4 * index - 1;
    }
  }

  return getIndexLabel();
}
