import { t } from 'i18next';
import * as y from 'yup';

export const projectFormSchema = y.object({
  id: y.string().optional(),
  name: y.string().required().label(t('pages.projectRegister.fields.projectName')),
  active: y.boolean().default(false),
  clientId: y.string().nullable().label(t('pages.projectRegister.fields.client')),
  costCenterId: y.string().nullable().label(t('pages.projectRegister.fields.costCenter')),
  externalCode: y.string().nullable().label(t('pages.projectRegister.fields.code')),
  accountable: y.string().nullable().label(t('pages.projectRegister.fields.accountable')),
  startAt: y
    .date()
    .max(y.ref('endAt'), t('pages.projectRegister.validation.startDateMustBeLessThenEndDate'))
    .nullable()
    .label(t('pages.projectRegister.fields.startAt')),
  endAt: y.date().nullable().label(t('pages.projectRegister.fields.endAt')),
  employees: y.array(y.string()).nullable(),
});

export type ProjectFormData = y.InferType<typeof projectFormSchema>;
