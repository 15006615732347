import { useState } from 'react';
import { TextArea } from '@atoms/TextArea';
import { useTranslation } from '@locale/Translator';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';

import * as SC from './styled';

interface JustificationModalReportReprovalProps {
  isOpen: boolean;
  isLoading?: boolean;
  reportsNumber: number;
  target?: 'Reproval' | 'CancelIntegration';
  onActionClick(justificationText: string): void;
  onCloseClick(): void;
}

export const JustificationModalReportReproval = ({
  isOpen,
  isLoading,
  reportsNumber,
  target = 'Reproval',
  onActionClick,
  onCloseClick,
}: JustificationModalReportReprovalProps) => {
  const { t } = useTranslation();
  const [justificationText, setJustificationText] = useState('');
  const formOfDescription = reportsNumber > 1 ? 'pluralForm' : 'singularForm';

  function onClose() {
    onCloseClick();
    setJustificationText('');
  }

  function onAction() {
    onActionClick(justificationText);
    setJustificationText('');
  }

  return (
    <SC.Container>
      <DangerActionModal
        isLoading={isLoading}
        headerIconLabel={t(`organisms.justificationModalReport${target}.attention`)}
        headerTitle={t(`organisms.justificationModalReport${target}.${formOfDescription}.popupTitle`, {
          reportsSelected: reportsNumber,
        })}
        headerSubtitle={t(`organisms.justificationModalReport${target}.${formOfDescription}.popupSubtitle`)}
        actionDisabled={target === 'CancelIntegration' ? false : justificationText?.length < 3}
        labelButtonAction={
          <SC.LabelButtonContent>
            {t(`organisms.justificationModalReport${target}.${formOfDescription}.confirmDisapproval`)}
          </SC.LabelButtonContent>
        }
        onActionClick={onAction}
        onCancelClick={onClose}
        onCloseClick={onClose}
        open={isOpen}>
        {target === 'Reproval' && (
          <>
            <SC.TextAreaLabel variant="body3">
              {t(`organisms.justificationModalReport${target}.${formOfDescription}.justifyTheDisapproval`)}
            </SC.TextAreaLabel>
            <TextArea
              label={t(`organisms.justificationModalReport${target}.enterTheJustification`)}
              onChange={(_, value) => setJustificationText(value)}
              value={justificationText}
              resize="none"
              rows={5}
            />
          </>
        )}
      </DangerActionModal>
    </SC.Container>
  );
};
