import styled from 'styled-components';

export interface Container {
  isSelected: boolean;
}

export const Container = styled.div<Container>`
  width: 100%;
`;

export const Image = styled.img`
  width: 150px;
  margin: 0 auto;
`;

export const NoOptionsImage = styled.img`
  object-fit: contain;
  height: 150px;
  width: 150px;
  margin: 0 auto ${props => props.theme.spacings.xs2} auto;
`;
