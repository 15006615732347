export type TAccessScreen =
  | 'reportDetails'
  | 'expenseForm'
  | 'financialReportDetails'
  | 'expenseDetails'
  | 'financialExpenseDetails'
  | 'newReport';

export enum EAccessScreen {
  REPORT_DETAILS = 'reportDetails',
  EXPENSE_FORM = 'expenseForm',
  FINANCIAL_REPORT_DETAILS = 'financialReportDetails',
  EXPENSE_DETAILS = 'expenseDetails',
  FINANCIAL_EXPENSE_DETAILS = 'financialExpenseDetails',
  NEW_REPORT = 'newReport',
}

export enum EDaysOfTheWeek {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
}
