import { useState } from 'react';
import { Collapse } from '@atoms/Collapse';
import { StatusTag } from '@atoms/StatusTag';
import { DelegateUserView } from '@containers/Expenses/components/organisms/ExpenseActions/DelegateUserView/DelegateUserView';
import { useFinancialReportsActionManager } from '@containers/Financial/components/organisms/Reports/FinancialReportsPreview/hooks/useFinancialReportsActionManager';
import { getReportStatusColorVariant, getReportStatusTranslator } from '@containers/Reports/common';
import { IReport } from '@containers/Reports/context/types/report';
import { useReportMutate } from '@containers/Reports/hooks/useReportMutate';
import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Skeleton } from '@mui/material';
import { expensesBaseUrl } from '@shared/constant';
import { useNavigate } from 'react-router-dom';

import { DeleteReportModal } from '../../molecules/DeleteReportModal';
import { ReportActions } from '../../molecules/ReportActions';
import { ReportAmountReceivable } from './ReportAmountReceivable/ReportAmountReceivable';
import { ReportInfosDescription } from './ReportInfosDescription';
import { ReportInfosSummary, ReportInfosSummarySkeleton } from './ReportInfosSummary';
import { ReportPeriod } from './ReportPeriod/ReportPeriod';
import { ReportTotalExpenses } from './ReportTotalExpenses/ReportTotalExpenses';
import { ReportInfosMainSkeleton } from './Skeletons';
import * as SC from './styled';

export interface ReportInfosProps extends IReport {
  isLoading?: boolean;
  onEditReport(): void;
  onExportReport(): void;
}

export const ReportInfos = ({
  referenceId,
  name,
  status,
  reimbursableValue,
  nonReimbursableValue,
  withdrawValue,
  advancesValue,
  activePeriod,
  value,
  currencyPrefix,
  approver,
  // TODO: This prop is necessary?
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dateLimit,
  isLoading,
  description,
  user,
  onEditReport,
  id,
  remainingReimbursement,
  expirationDate,
  // TODO: This prop is necessary?
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onExportReport,
}: ReportInfosProps) => {
  const { t } = useTranslation();
  const [openDetails, setOpenDetails] = useState(false);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const reportStatusTranslator = t(getReportStatusTranslator(status));
  const reportStatusColorVariant = getReportStatusColorVariant({ status });
  const { deleteReport, isLoading: isDeleteLoading } = useReportMutate();
  const { onExportPdfClick } = useFinancialReportsActionManager({});
  const navigate = useNavigate();

  const handleReportDelete = async () => {
    await deleteReport(id);
    navigate(`${expensesBaseUrl}/reports`);
  };

  return (
    <>
      <SC.HeaderContainer>
        {!isLoading ? (
          <SC.MainInformationContainer>
            <SC.ReferenceIdContainer>
              <SC.ReportTitle>{name}</SC.ReportTitle>
              <StatusTag variant={reportStatusColorVariant}>{reportStatusTranslator}</StatusTag>
            </SC.ReferenceIdContainer>
            <SC.ReferenceIdDescription>{referenceId}</SC.ReferenceIdDescription>
          </SC.MainInformationContainer>
        ) : (
          <ReportInfosMainSkeleton />
        )}
        <SC.HeaderContainerActions>
          <DelegateUserView />
          <ReportActions
            reportStatus={status}
            isLoading={isLoading}
            onEditReportClick={onEditReport}
            onExportReportClick={() => onExportPdfClick([id])}
            onDeleteReportClick={() => setShowDeleteReportModal(true)}
          />
        </SC.HeaderContainerActions>
      </SC.HeaderContainer>
      <SC.ReportInfosContent>
        {!isLoading ? (
          <ReportInfosSummary
            totalValue={value}
            currencyPrefix={currencyPrefix}
            remainingReimbursement={remainingReimbursement}
            approver={approver}
            requester={user}
            dateLimit={expirationDate}
            reportId={id}
          />
        ) : (
          <ReportInfosSummarySkeleton />
        )}
        <Collapse isOpen={openDetails}>
          <SC.Container>
            <ReportTotalExpenses
              currencyPrefix={currencyPrefix}
              totalExpenses={value}
              reimbursableValue={reimbursableValue}
              nonReimbursableValue={nonReimbursableValue}
            />
          </SC.Container>
          <SC.Container>
            <ReportAmountReceivable
              valueToReceive={remainingReimbursement}
              currencyPrefix={currencyPrefix}
              reimbursableValue={reimbursableValue}
              advancesValue={advancesValue}
              withdrawValue={withdrawValue}
            />
          </SC.Container>
          <SC.Container>
            <ReportPeriod activePeriod={activePeriod} />
          </SC.Container>
          <SC.Container>{Boolean(description) && <ReportInfosDescription description={description} />}</SC.Container>
        </Collapse>
        <SC.ReportInfosShowButtonContainer>
          {!isLoading ? (
            <LinkButton variant="default" onClick={() => setOpenDetails(isOpen => !isOpen)}>
              {openDetails ? t('report.toRecall') : t('report.seeDetails')}
            </LinkButton>
          ) : (
            <Skeleton variant="rounded" width={96} height={28} />
          )}
        </SC.ReportInfosShowButtonContainer>
      </SC.ReportInfosContent>
      <DeleteReportModal
        isOpen={showDeleteReportModal}
        onActionClick={handleReportDelete}
        onCloseClick={() => setShowDeleteReportModal(false)}
        isLoading={isDeleteLoading}
      />
    </>
  );
};
