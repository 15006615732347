import { Dispatch, SetStateAction } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { ICostCenterCompany } from '@containers/CostCenters/context/types/costCenters';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { Search } from '@molecules/GridFilters/Search/Search';
import { ActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { useEmployeesSelectionList } from '@shared/hooks/employees/useEmployeesSelectionList';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { CostCenterConfirmationActionsModal } from './CostCenterConfirmationActionsModal/CostCenterConfirmationActionsModal';
import { useCostCentersActionsGrid } from './hooks/useCostCentersActionsGrid';
import * as SC from './styled';

enum EAccessor {
  SELECT_ALL = 'selectAll',
  COST_CENTER = 'costCenter',
  STATUS = 'status',
  RESPONSIBLE = 'responsible',
  CODE = 'code',
  ACTIONS = 'actions',
}

type TProps = {
  costCenters: ICostCenterCompany[];
  total: number;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSearchName: Dispatch<SetStateAction<string>>;
  searchName: string;
};

export function CostCentersGrid({ costCenters, total, isLoading, page, setPage, searchName, setSearchName }: TProps) {
  const t = useTranslate('costCenters.grid');
  const { employees: users, isLoading: isLoadingUsers } = useEmployeesSelectionList();
  const {
    showConfirmationModal,
    isSelectedAll,
    selectedItems,
    confirmationType,
    isBatchAction,
    isLoadingAction,
    listIdsLoading,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    unSelectAllClick,
    onSelectItemClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
  } = useCostCentersActionsGrid(costCenters);

  function getColumns(): IGridColumn[] {
    return Object.values(EAccessor).map(accessor => ({
      accessor,
      disableSortBy: true,
      Header: (
        <ColumnHeader
          isLoading={(isLoading || isLoadingUsers) && !Boolean(searchName) && !Boolean(costCenters.length)}
          isLoadingAction={isLoading}
          description={t(`header.columns.${accessor}`)}
          isSelectedAll={accessor === EAccessor.SELECT_ALL}
          onSelectAllClick={toggleSelectAll}
          selectAllChecked={isSelectedAll}
        />
      ),
      sticky: accessor === EAccessor.ACTIONS && 'right',
    }));
  }

  function getRows(): Record<EAccessor, React.ReactNode>[] {
    return costCenters?.map(_costCenter => {
      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(_costCenter?.id)}
            checked={selectedItems.some(_id => _id === _costCenter?.id)}
          />
        ),
        costCenter: _costCenter.name,
        status: (
          <StatusTag showStatusIcons variant={_costCenter.active ? 'success' : 'error'}>
            {t(`${_costCenter.active ? 'active' : 'inactive'}`)}
          </StatusTag>
        ),
        responsible: users?.find(_user => String(_user.id) === _costCenter.accountable)?.name ?? '-',
        code: _costCenter.externalCode,
        actions: (
          <ActionsRow
            referenceId={_costCenter.id}
            actions={getActionsRow(_costCenter.id)}
            isLoading={listIdsLoading?.includes(_costCenter.id)}
          />
        ),
      };
    });
  }

  return (
    <SC.Container>
      <Search
        label={t('searchFieldLabel')}
        onChange={data => {
          selectedItems.length && unSelectAllClick();
          setSearchName(data);
        }}
        isLoading={(isLoading || isLoadingUsers) && !searchName && !costCenters.length}
      />
      <Grid
        columns={getColumns()}
        rows={getRows()}
        batchActions={getBatchActions()}
        isLoading={isLoading || isLoadingUsers}
        hasPagination={total > 20}
        totalItems={total || 0}
        numberItemsSelected={selectedItems?.length || 0}
        rowsLoading={Boolean(costCenters.length) ? costCenters.length : 4}
        rowsPerPage={20}
        currentPage={page}
        paginationIsDisabled={isLoading || isLoadingUsers}
        onPageClick={_page => setPage(_page)}
        showRowsPerPageOptions={false}
      />
      {showConfirmationModal && (
        <CostCenterConfirmationActionsModal
          open={showConfirmationModal}
          confirmationType={confirmationType}
          onActionClick={onConfirmationActionModal}
          onClose={onShowConfirmationActionsModal}
          isBatchAction={isBatchAction}
          isLoading={isLoadingAction}
        />
      )}
    </SC.Container>
  );
}
