import { Skeleton } from '@mui/material';

import { EAccessor, HeaderGridColumn } from './useHeaderGridColumns';

export const useHeaderGridColumnsSkeleton = (): HeaderGridColumn[] => {
  const columnAccessors = Object.values(EAccessor);

  const skeletonColumns = columnAccessors.map((accessor, index) => {
    return {
      Header: <Skeleton variant="rounded" width={index != 0 ? 55 : 20} height={index != 0 ? 22 : 20} />,
      accessor,
      disableSortBy: true,
      sticky: accessor == EAccessor.ACTIONS ? 'right' : null,
    };
  });

  return skeletonColumns;
};
