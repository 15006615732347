import {
  Button,
  ButtonProps,
  Icons,
  IconsProps,
  LinkButton,
  LinkButtonProps,
  SearchField,
  SearchFieldProps,
  Typography,
  TypographyProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface MenuItemProps {
  selected: boolean;
}

export const SearchInputStyled = styled(SearchField)<SearchFieldProps>(props => ({
  padding: `${props.theme.spacings.xs3}`,
}));

export const ItemListContainer = styled.ul(props => ({
  display: 'flex',
  flexDirection: 'column',
  listStyleType: 'none',
  width: '100%',
  gap: `${props.theme.spacings.xs5}`,
  padding: `${props.theme.spacings.xs3}`,
  overflow: 'auto',
  maxHeight: 270,
}));

export const FooterContainer = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: `${props.theme.spacings.xs3}`,
  padding: `${props.theme.spacings.xs3}`,
}));

export const ApplyButton = styled(Button)<ButtonProps>(() => ({}));

export const ClearButton = styled(LinkButton)<LinkButtonProps>(() => ({}));

export type TypographyStyledProps = {
  /**
   * Flag to set a text "checked" and apply a checked style
   */
  checked?: boolean;
} & TypographyProps;

export const TypographyStyled = styled(Typography)<TypographyStyledProps>(props => ({
  ...(props?.checked && {
    color: props?.theme?.colors?.secondary?.[50],
  }),
}));

export type IconsStyledProps = {
  /**
   * Flag to set a icon "checked" and apply a checked style
   */
  checked?: boolean;
} & IconsProps;

export const IconsStyled = styled(Icons)<IconsStyledProps>(props => ({
  ...(props?.checked && {
    color: props?.theme?.colors?.secondary?.[50],
  }),
}));

export const MenuItem = styled.button<MenuItemProps>`
  display: flex;
  background-color: ${props => (props.selected ? props.theme.colors.secondary[90] : props.theme.colors.neutral[100])};
  border-radius: ${props => props.theme.borders.radius.xl};
  align-items: center;
  width: 100%;
  gap: ${props => props.theme.spacings.xs3};
  padding: ${props => props.theme.spacings.xs2};

  &:hover {
    background-color: ${props => props.theme.colors.neutral[95]};
    border-radius: ${props => props.theme.borders.radius.xl};
  }
`;

export const EmptyText = styled(Typography)<TypographyStyledProps>(props => ({
  color: props.theme.colors.neutral[50],
  marginTop: props.theme.spacings.xs3,
  textAlign: 'center',
}));
