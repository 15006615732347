import React from 'react';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import * as SC from '../styled';

export enum EAccessor {
  SELECTION = 'selection',
  CATEGORY = 'category',
  CRITERION = 'criterion',
  LIMIT = 'limit',
  STATUS = 'status',
  LIMIT_BLOCKS_SENDING = 'limitBlocksSending',
  PROOF_REQUIRED = 'proofRequired',
  COMMENT_REQUIRED = 'commentRequired',
  ACTIONS = 'actions',
}

export interface HeaderGridColumn {
  Header: React.ReactNode;
  accessor: string | number;
  disableSortBy?: boolean;
  sticky?: string;
}

interface UseHeaderGridColumnProps {
  onSelectAllClick(): void;
  isSelectedAll: boolean;
}

export function useHeaderGridColumns({
  onSelectAllClick,
  isSelectedAll,
}: UseHeaderGridColumnProps): HeaderGridColumn[] {
  const { t } = useTranslation();
  const columnAccessors = Object.values(EAccessor);

  const dataColumns = columnAccessors.map(accessor => {
    const hasCheckbox = accessor == EAccessor.SELECTION;
    const hasSticky = accessor == EAccessor.ACTIONS;

    return {
      Header: (
        <SC.ThContent>
          {hasCheckbox ? (
            <Checkbox onChange={onSelectAllClick} checked={isSelectedAll} />
          ) : (
            <SC.ThDescription>{t(`organisms.limitGrid.${accessor}`)}</SC.ThDescription>
          )}
        </SC.ThContent>
      ),
      accessor,
      disableSortBy: true,
      sticky: hasSticky && 'right',
    };
  });

  return dataColumns;
}
