import { TPendingExpenseByUser, TUserPendingExpense } from '@containers/Financial/context/types/users';
import { tableControllers } from '@flash-tecnologia/hros-web-ui-v2';
import { HeaderBulkActions } from '@organisms/Table/HeaderBulkActions/HeaderBulkActions';

export enum EAccessor {
  NAME = 'name',
  EXPENSE_PENDING_AMOUNT = 'expensePendingAmount',
  REPORTS_COUNT = 'reportsCount',
  PAYMENT_METHODS = 'paymentMethods',
  ACTION = 'action',
}

export enum EActionOptions {
  SEE_REPORT = 'seeReport',
  SEE_EXPENSE = 'seeExpense',
}

export enum EBatchActionOptions {
  UNLINK = 'unlink',
  DELETE = 'delete',
}

export enum EIconAction {
  VIEW = 'IconEye',
  EDIT = 'IconPencil',
  UNLINK = 'IconUnlink',
  DELETE = 'IconTrash',
}

export enum ERowType {
  MainRow = 'MAIN_ROW',
  SubRow = 'SUB_ROW',
}

export interface ISubRowAction {
  children: React.ReactNode;
  onClick(): void;
}

export type TDataTable = TUserPendingExpense &
  TPendingExpenseByUser & {
    _rowType: ERowType;
  };

export type TColumns = Parameters<typeof tableControllers.useTableColumns<TDataTable>>[0]['columns'];

export type TOptions = Parameters<typeof tableControllers.useTableColumns<TDataTable>>[0]['options'];

export type TTable = Parameters<typeof tableControllers.useTableColumns<TDataTable>>;

export type TBatchActions = Parameters<typeof HeaderBulkActions>[0]['actions'];

export type TUserExpenses = {
  userId: TUserPendingExpense['id'];
  expenses: TPendingExpenseByUser[];
  limitPerPage: number;
  shouldFetch: boolean;
  totalItems?: number;
};
