import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const InfoTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const InfoDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const InfoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const InfoListTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const InfoListDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const ContainerItemsList = styled.ul`
  padding-left: ${({ theme }) => theme.spacings.xs};
`;

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`;
