import { useWaypointIdentifierHelper } from './hooks/useWaypointIdentifierHelper';
import * as SC from './styled';
import { WaypointDivider } from './WaypointDivider/WaypointDivider';

export interface IWaypointIdentifierProps {
  hasPreviousValue: boolean;
  hasCurrentValue: boolean;
  hasNextValue: boolean;
  routeIndex: number;
  variant: 'field' | 'viewOnly';
  isLastItem?: boolean;
}

export function WaypointIdentifier({
  routeIndex,
  hasPreviousValue,
  hasCurrentValue,
  hasNextValue,
  variant = 'viewOnly',
  isLastItem = false,
}: IWaypointIdentifierProps) {
  const { identifierIcon, isFirstItem, getDividerType } = useWaypointIdentifierHelper(routeIndex);

  return (
    <SC.Container>
      {!isFirstItem && <WaypointDivider type={getDividerType(hasPreviousValue && hasCurrentValue)} />}
      <SC.IdentificationIcon
        isActive={hasCurrentValue}
        variant={variant}
        name={identifierIcon}
        fill="transparent"
        size={24}
      />
      {!isLastItem && <WaypointDivider type={getDividerType(hasNextValue && hasCurrentValue)} />}
    </SC.Container>
  );
}
