import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 982px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};

  .select-custom {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const PriorityContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  min-width: 100%;
`;

export const LinkContent = styled.div`
  white-space: nowrap;
  display: grid;
  gap: ${props => props.theme.spacings.xs5};
  grid-template-columns: min-content min-content;
`;

export const ConditionalRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  width: 100%;
  align-items: center;
`;

export const ConditionalIdentifier = styled.div`
  width: 35px;
`;
