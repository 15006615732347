import {
  EExpenseFieldType,
  EExpenseType,
  EFieldType,
  IExpenseFields,
  IExpenseInput,
} from '@containers/Expenses/context/types';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { FieldFactory } from '@organisms/FieldFactory';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectOption } from '../../../../../../shared/helpers/selectOptions';
import { IExpenseRefFields, useFieldTypeConfig } from './hooks/useFieldTypeConfig';
import * as SC from './styled';

export interface SelectData {
  options: SelectOption[];
  error: unknown;
  isLoading: boolean;
}
export interface ListFieldsOptions {
  category: SelectData;
  currency: SelectData;
  client: SelectData;
  project: SelectData;
  paymentType: SelectData;
  costCenter: SelectData;
}
export interface ExpenseFormFieldsProps {
  expenseType: EExpenseType;
  fields: IExpenseFields;
  expense: IExpense;
  isLoading: boolean;
  ocrIsLoading: boolean;
  error: unknown;
  listFieldsOptions: ListFieldsOptions;
  onSubmitClick(data: IExpenseInput): void;
}

export const ExpenseFormFields = ({
  expenseType,
  fields,
  expense,
  isLoading,
  ocrIsLoading,
  listFieldsOptions,
  onSubmitClick,
}: ExpenseFormFieldsProps) => {
  const t = useTranslate('organisms.expenseRegisterForm');
  const { checkLicenseFeature } = useLicenseFeatures();
  const fieldsData = useFieldTypeConfig({ fields, expense });
  const { control, handleSubmit, getValues } = useFormContext<IExpenseInput>();

  function getSkeletonDescription(fieldName: IExpenseRefFields) {
    switch (fieldName) {
      case IExpenseRefFields.value:
      case IExpenseRefFields.date:
      case IExpenseRefFields.address:
      case IExpenseRefFields.currency:
      case IExpenseRefFields.quantity:
        return t('ocr.loading');
      default:
        return null;
    }
  }

  return (
    <SC.MainContainer>
      <form onSubmit={handleSubmit(onSubmitClick)}>
        <SC.FieldsContainer isLoading={isLoading}>
          {isLoading && !getValues('id')
            ? Object.values(EExpenseFieldType)
                .filter((_, index) => index <= 8)
                .map((field, index) => (
                  <FieldFactory key={`field-factory-${index}`} fieldType={field} isLoading={isLoading} />
                ))
            : fieldsData.map((fieldData, key) => {
                if (
                  fieldData.active &&
                  fieldData.type != EExpenseFieldType.receiptFile &&
                  fieldData.name != IExpenseRefFields.report &&
                  fieldData.name != IExpenseRefFields.refundable
                ) {
                  return (
                    <Controller
                      key={key}
                      rules={{
                        required: {
                          value: fieldData.type == EFieldType.FLAG ? false : fieldData.required,
                          message: t('mandatoryField'),
                        },
                      }}
                      defaultValue={null}
                      name={fieldData.name}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const skeletonDescription =
                          ocrIsLoading && getSkeletonDescription(fieldData.name as IExpenseRefFields);
                        return (
                          <SC.FieldContainer
                            fieldName={fieldData.name}
                            hidden={!checkLicenseFeature(fieldData.licenseFeature)}>
                            <FieldFactory<IExpenseInput>
                              fieldType={fieldData.type}
                              isCurrencyValue={fieldData.name == IExpenseRefFields.value}
                              error={Boolean(error)}
                              helperText={error?.message}
                              label={fieldData.label}
                              allowsEditing={fieldData.allowsEditing}
                              {...field}
                              value={field.value}
                              options={
                                (fieldData.type == EFieldType.OPTIONS && fieldData.options) ||
                                listFieldsOptions[fieldData.ref]?.options
                              }
                              isLoading={isLoading || Boolean(skeletonDescription)}
                              skeletonDescription={skeletonDescription}
                              fieldsConfig={{
                                establishment: {
                                  allowSearchPlaces: expenseType === EExpenseType.MANUAL,
                                },
                              }}
                            />
                          </SC.FieldContainer>
                        );
                      }}
                    />
                  );
                }
              })}
        </SC.FieldsContainer>
      </form>
    </SC.MainContainer>
  );
};
