import { approvalFlowsBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { IApprovalFlow } from '../../types/approvalFlow';
import { ApprovalFlowForm } from '../../validators/ApprovalFlowFormSchema';

export function useSaveCustomApprovalFlow(isEdit: boolean) {
  const t = useTranslate(`approvalFlows.feedbacks.${isEdit ? 'edit' : 'create'}`);
  const { mutation } = useMutate('v1/approval', onError);
  const currentPath = useCurrentPath();
  const navigate = useNavigate();

  function onError() {
    return showToast({
      message: t('error'),
      type: 'error',
    });
  }

  async function handleSave(approvalFlow: ApprovalFlowForm, id: IApprovalFlow['id']) {
    const isEdit = Boolean(id);

    const response = await mutation.trigger({
      path: `${isEdit ? id : ''}`,
      options: {
        method: isEdit ? 'PATCH' : 'POST',
        body: JSON.stringify(approvalFlow),
      },
    });

    showToast({
      message: t('success'),
      type: 'success',
    });
    navigate(`${approvalFlowsBaseURL}/${currentPath.includes('reports') ? 'reports' : 'advances'}`, {
      replace: true,
    });

    return response;
  }

  return {
    handleSave,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
