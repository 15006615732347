import { FieldRegister } from '@containers/ApprovalFlows/CustomApprovalFlow/components/molecules/FieldRegister/FieldRegister';
import { BasicInformationApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { SelectField, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';

import { IOptionsSource } from '../hooks/useOptionsListCriteria';
import * as SC from '../styled';

interface IProps {
  index: number;
  label: string;
  isLoading: boolean;
  operatorOptions: IOptionsSource[];
}

export function CriteriaOperator({ index, label, isLoading, operatorOptions }: IProps) {
  const t = useTranslate('approvalFlows.approvalCriteria.fields');
  const { control, getValues } = useFormContext<BasicInformationApprovalFlowForm>();

  return (
    <FieldRegister isLoading={isLoading}>
      <Controller
        control={control}
        name={`criteria.${index}.operator`}
        rules={{
          required: {
            value: !getValues(`criteria.${index}.operator`),
            message: t('field.mandatoryInfo'),
          },
        }}
        render={({ field: { ref, ...field }, fieldState }) => (
          <SC.ContainerField>
            <Tooltip arrow title={!getValues(`criteria.${index}.key`) && t('field.tooltip')} placement="top">
              <SC.ContainerField>
                <SelectField
                  {...field}
                  inputRef={ref}
                  options={operatorOptions}
                  error={fieldState.invalid}
                  disabled={!getValues(`criteria.${index}.key`)}
                  helperText={fieldState.error?.message}
                  label={label}
                  onSelectChange={(_, data) => field.onChange(data.value)}
                />
              </SC.ContainerField>
            </Tooltip>
          </SC.ContainerField>
        )}
      />
    </FieldRegister>
  );
}
