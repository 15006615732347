import { gql } from '@apollo/client';

export const getCostCentersSummary = gql`
  query GetCostCenters {
    getCostCenters {
      costCenters {
        id
        name
        active
      }
      total
    }
  }
`;

export const getCostCenters = gql`
  query GetCostCenters {
    getCostCenters {
      costCenters {
        id
        name
        active
        referenceId
      }
      total
    }
  }
`;

export const deleteCostCenters = gql`
  mutation DeleteCostCenters($ids: [Int!]!) {
    deleteCostCenters(ids: $ids) {
      id
      error
      message
    }
  }
`;

export const deactivateCostCenters = gql`
  mutation ActiveCostCenters($ids: [Int!]!, $active: Boolean!) {
    activeCostCenters(ids: $ids, active: $active) {
      id
      error
      message
    }
  }
`;
