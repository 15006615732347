import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  display: 'grid',
  gap: theme.spacings.xs,
}));

export const ItemContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs,
}));

export const UploadContainer = styled.div(() => ({
  width: '313px',
}));

export const InputKmContainer = styled.div(() => ({
  width: '313px',

  '.text-field-custom-theme': {
    width: '100%',
  },
}));

export const InputKm = styled(NumericFormat)(() => ({
  width: '100%',
}));
