import { useContext } from 'react';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { TSystemFeatures } from '@shared/types/systemFeatures';

export function useLicenseFeatures() {
  const { features } = useContext(SessionContext);
  const currentFeatures = features?.length ? features : ['BASIC_ANALYTICS', 'BASIC_EXPENSE', 'BASIC_REPORTS'];

  function checkLicenseFeature(feature: TSystemFeatures): boolean {
    return currentFeatures.some(_feature => _feature === feature);
  }

  function checkLicenseFeatureBatch(systemFeatures: TSystemFeatures[]): boolean {
    return !systemFeatures?.length || systemFeatures?.some?.(f => currentFeatures.includes(f));
  }

  return {
    features,
    checkLicenseFeature,
    checkLicenseFeatureBatch,
  };
}
