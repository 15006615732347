import { ISettingsContextState } from './types';

export enum ESettingActions {
  GET_CATEGORIES = 'GET_CATEGORIES',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  GO_TO_STEP = 'GO_TO_STEP',
  GET_CATEGORY_LIMIT = 'GET_CATEGORY_LIMIT',
  GET_CURRENCIES = 'GET_CURRENCIES',
}

export interface ISettingDispatchActions {
  type: ESettingActions;
  // TODO: Update this type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Partial<ISettingsContextState> | any;
}
