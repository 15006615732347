import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { RegisterPolicy } from '@pages/Policies/RegiterPolicy/RegisterPolicy';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function PoliciesRoutes(): RouteObject[] {
  return [
    {
      element: <PermissionsGuard routeFeatures={['BASIC_POLICIES']} />,
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Políticas" moduleName="Políticas" />,
      children: [
        {
          path: Router.getPolicyRegister(),
          element: <RegisterPolicy />,
        },
        {
          path: Router.getPolicyRegister(':id'),
          element: <RegisterPolicy />,
        },
      ],
    },
  ];
}
