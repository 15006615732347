import { useTranslation } from '@locale/Translator';

import { DistanceRoutesSecondarySkeleton } from './DistanceRoutesSecondarySkeleton';
import * as SC from './styled';

interface IProps {
  distance: string;
  isLoading: boolean;
}

export function DistanceRoutesSecondary({ distance, isLoading }: IProps) {
  const { t } = useTranslation();

  if (isLoading) {
    return <DistanceRoutesSecondarySkeleton />;
  }

  return (
    <SC.Container>
      <SC.Icon name="IconMap" fill="transparent" size={32} />
      <SC.DescriptionContent>
        <SC.Label variant="headline8">{t('molecules.distanceRoutes.distance')}</SC.Label>
        <SC.Description variant="body3">{`${distance} km`}</SC.Description>
      </SC.DescriptionContent>
    </SC.Container>
  );
}
