import { useContext, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { useAllCostCenters } from '@containers/CostCenters/hooks/rest/useAllCostCenters';
import { FeedbackBox } from '@molecules/FeedbackBox';
import { costCentersBaseURL } from '@shared/constant';
import { ImportingContext } from '@shared/contexts/BulkImportContext';
import { EImportingActions } from '@shared/contexts/BulkImportContext/actions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { CostCentersGrid } from '../CostCentersGrid/CostCentersGrid';
import * as SC from './styled';

export function CostCentersPreview() {
  const t = useTranslate('costCenters.listPreview');
  const navigate = useNavigate();
  const { state, dispatch } = useContext(ImportingContext);
  const [searchName, setSearchName] = useState<string>('');
  const [page, setPage] = useState(1);
  const {
    costCentersCompany: costCenters,
    isLoading,
    total: totalCostCenters,
    refetch,
  } = useAllCostCenters({
    page,
    searchName,
  });

  return (
    <SC.Container>
      {state.hasTriedToImport && (
        <FeedbackBox
          onClose={() =>
            dispatch({
              type: EImportingActions.HAS_TRIED_TO_IMPORT,
              payload: {
                hasTriedToImport: false,
              },
            })
          }
          state={state}
          refetch={refetch}
          onClick={() => navigate(`${costCentersBaseURL}/register/file/details`)}
          page="costCenters"
        />
      )}
      <SC.ContainerCounter>
        <SC.CounterLabel variant="headline8">{t('counterData.label')}</SC.CounterLabel>
        <StatusTag variant="neutral">
          {t(`counterData.items.${totalCostCenters === 1 ? 'singular' : 'plural'}`, { items: totalCostCenters })}
        </StatusTag>
      </SC.ContainerCounter>
      <CostCentersGrid
        costCenters={costCenters}
        total={totalCostCenters}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        setSearchName={setSearchName}
        searchName={searchName}
      />
    </SC.Container>
  );
}
