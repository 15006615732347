import { Button, DataGrid, IconButton, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const GridContainer = styled.div`
  margin-top: ${props => props.theme.spacings.xs};
  position: relative;
`;

export const Grid = styled(DataGrid)`
  display: flex;
`;

export const TagBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const AlertIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[50]};
  margin-left: ${({ theme }) => theme.spacings.xs5};
`;

export const UserNameRowBox = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 600;
`;

export const TotalExpensesRowBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[80]};
  width: 42px;
  height: 42px;
`;

export const ContentPagination = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  width: min-content;
  width: auto;
`;

export const PaymentsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const IconDots = styled(IconButton).attrs({
  icon: 'IconDots',
  size: 'small',
  variant: 'line',
})`
  transform: rotate(90deg);
`;

export const CaptionExpenseReport = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`;

export const CircleDivider = styled.div`
  border-radius: 50%;
  height: 4px;
  width: 4px;
  background-color: ${({ theme }) => theme.colors.neutral[80]};
`;

export const ActionMainRowContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`;

export const ButtonActionMainRow = styled(Button)`
  border-radius: ${({ theme }) => theme.borders.radius.xl2}!important;
  word-wrap: 'no-wrap';
  flex-wrap: nowrap;
  white-space: nowrap;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
  white-space: nowrap;
`;
