import { DistanceRoutes } from '@containers/Expenses/components/molecules/DistanceRoutes/DistanceRoutes';
import { IRoute } from '@containers/Expenses/context/types/expenseById';
import { FormProvider, useForm } from 'react-hook-form';

import { WaypointFields } from '../../WaypointFields/WaypointFields';
import * as SC from './styled';

interface IProps {
  route?: IRoute;
  isLoading: boolean;
}

export function OriginDestinationExpenseDetail({ route, isLoading }: IProps) {
  const methods = useForm({
    values: {
      route: route,
    },
  });

  return (
    <SC.Container>
      <SC.ContainerWaypoints>
        <FormProvider {...methods}>
          <WaypointFields route={route} isLoading={isLoading} viewOnly ratePerKm={route?.ratePerKm} />
        </FormProvider>
      </SC.ContainerWaypoints>
      <SC.DistanceContainer>
        <DistanceRoutes distance={route?.distance || 0} isLoading={isLoading} variant="secondary" />
      </SC.DistanceContainer>
    </SC.Container>
  );
}
