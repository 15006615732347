import { getReportById } from '@containers/Reports/context/queries';
import { IReport } from '@containers/Reports/context/types';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';
import { useHeaderDelegateOptions } from '@shared/hooks/service/useHeaderDelegateOptions';
import { TAccessScreen } from '@shared/types';

import { createExpenseV2, deleteExpenseList, getExpense, updateExpenseByIdV2 } from '../context/queries';
import {
  IDeleteExpenseRequest,
  IDeleteExpenseResponse,
  IExpenseInput,
  IExpensesFilters,
  IGetCreateExpenseResponse,
  IGetUpdateExpenseResponse,
} from '../context/types';
import { IExpense } from '../context/types/expenseById';
import { IFileResponse } from '../context/types/files';

export interface ISaveExpenseMutationRequest {
  expense: Omit<IExpenseInput, 'attachments'> & {
    attachments: IFileResponse[];
  };
}

export interface IGetCreateExpenseRequest extends ISaveExpenseMutationRequest {}
export interface IGetUpdateExpenseMutateRequest extends ISaveExpenseMutationRequest {
  expenseId: number;
}

export interface IDeleteExpenseMutateRequest {
  limit?: number;
  page?: number;
  filters?: IExpensesFilters;
}

export interface UseCreateExpenseProps {
  reportId?: IReport['id'];
  accessScreen?: TAccessScreen;
}

export interface UseUpdateExpenseProps {
  accessScreen?: TAccessScreen;
  expenseId?: IExpense['id'];
  reportId?: IReport['id'];
}

export function useCreateExpense({ reportId, accessScreen }: UseCreateExpenseProps) {
  const { headers } = useHeaderDelegateOptions({}, true);

  const queries = {
    reportDetails: [
      {
        query: getReportById,
        variables: {
          id: reportId,
        },
        context: {
          headers,
        },
      },
      'GetExpenses',
    ],
  };

  const [createExpenseMutate, { data, loading, error }] = useMutation<
    IGetCreateExpenseResponse,
    IGetCreateExpenseRequest
  >(createExpenseV2, {
    refetchQueries: queries[accessScreen],
  });

  return {
    createExpenseMutate,
    expenseCreated: data,
    isLoading: loading,
    error,
  };
}

export function useUpdateExpense({ expenseId, reportId, accessScreen }: UseUpdateExpenseProps) {
  const { headers } = useHeaderDelegateOptions({}, true);
  const queries = {
    reportDetails: [
      {
        query: getReportById,
        variables: {
          id: reportId,
        },
        context: {
          headers,
        },
      },
      'GetReportById',
      'GetExpenses',
      'GetAllExpensesToBind',
    ],
    expenseDetails: [
      {
        query: getExpense,
        variables: {
          id: expenseId,
        },
        context: {
          headers,
        },
      },
      'GetExpenseCustomFields',
    ],
  };

  const [updateExpenseMutate, { data, loading, error }] = useMutation<
    IGetUpdateExpenseResponse,
    IGetUpdateExpenseMutateRequest
  >(updateExpenseByIdV2, {
    refetchQueries: queries[accessScreen],
  });

  return {
    updateExpenseMutate,
    expenseUpdate: data,
    isLoading: loading,
    error,
  };
}

export function useDeleteExpenseList(accessScreen?: TAccessScreen) {
  const queries = {
    expenseDetails: ['GetReportById', 'GetExpenses', 'GetAllExpensesToBind'],
  };

  const [deleteExpenseListMutate, { data, loading, error }] = useMutation<
    IDeleteExpenseResponse,
    IDeleteExpenseRequest
  >(deleteExpenseList, {
    refetchQueries: queries[accessScreen],
  });

  return {
    deleteExpenseListMutate,
    expenses: data?.expenses || [],
    isLoading: loading,
    error,
  };
}
