import styled from 'styled-components';

export const Container = styled.div`
  max-width: 650px;
`;

export const LabelButtonContent = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs4};
  align-items: center;
`;
