import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { GetResolvedKmPoliciesQuery } from '../context/queries/kmPolicies';
import { IGetResolvedKmPolicies } from '../context/types/kmPolicies';

interface IUseResolvedKmPolicies {
  allowOriginDestination: boolean;
  allowOdometer: boolean;
  allowMultiplePlaces: boolean;
  allowSavedEstablishments: boolean;
  allowSearchNewPlaces: boolean;
  error: unknown;
  loading: boolean;
}

export const useKmPolicies = (skip?: boolean): IUseResolvedKmPolicies => {
  const { loading, data, error } = useQuery<IGetResolvedKmPolicies>(GetResolvedKmPoliciesQuery, {
    skip,
  });

  return {
    ...data?.getResolvedKmPolicies,
    loading,
    error,
  };
};
