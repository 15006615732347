import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { mutate } from 'swr';

import { SubsidiaryFormData } from '../../validators/subsidiaryFormSchema';

export function useSaveSubsidiary() {
  const { t } = useTranslation();

  const subsidiaryMutate = useMutate('v1/subsidiaries', () =>
    showToast({
      message: t('pages.subsidiaryRegister.feedbacks.update.error'),
      type: 'error',
    }),
  );

  function serializeBody(subsidiary: SubsidiaryFormData) {
    const registrationNumber = subsidiary.registerNumber?.replace(/\D/g, '') || '';
    const body: SubsidiaryFormData = {
      ...subsidiary,
      address: subsidiary.address
        ? {
            ...subsidiary.address,
            zipCode: subsidiary.address.zipCode?.replace(/\D/g, ''),
          }
        : undefined,
      registerNumber: registrationNumber.length > 0 ? registrationNumber : undefined,
    };

    return body;
  }

  async function updateSubsidiary(subsidiaryId: string, subsidiary: SubsidiaryFormData) {
    const body = serializeBody(subsidiary);

    await subsidiaryMutate.mutation.trigger(
      {
        options: {
          method: 'PUT',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
        onSuccess: () =>
          showToast({
            message: t('pages.subsidiaryRegister.feedbacks.update.success'),
            type: 'success',
          }),
      },
    );

    mutate(key => typeof key === 'string' && key.includes('v1/subsidiaries?page='));
  }

  async function createSubsidiary(subsidiary: SubsidiaryFormData) {
    const body: SubsidiaryFormData = serializeBody(subsidiary);

    await subsidiaryMutate.mutation.trigger(
      {
        options: {
          method: 'POST',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
        onSuccess: () =>
          showToast({
            message: t('pages.subsidiaryRegister.feedbacks.create.success'),
            type: 'success',
          }),
      },
    );
    mutate(key => typeof key === 'string' && key.includes('v1/subsidiary?page='));
  }

  async function handleSave(subsidiary: SubsidiaryFormData, subsidiaryId?: string) {
    if (subsidiaryId) {
      return await updateSubsidiary(subsidiaryId, subsidiary);
    }

    await createSubsidiary(subsidiary);
  }

  return {
    handleSave,
    isLoading: subsidiaryMutate.mutation.isMutating,
    data: subsidiaryMutate.mutation.data,
  };
}
