export interface IConfigurationReportSetting {
  allowSameUserToApproveAdvanceAndReport: boolean;
  expirationDate: IReportExpirationDateSettings;
  allowReimbursementToContainReimbursementExpenseOnly: IReportAllowReimbursementToExpenseOnlySettings;
  prefixValue: string;
  startValue: number;
  pdfExportSettings: IReportPdfExportSettings;
}

export interface IReportExpirationDateSettings {
  allowAdd: boolean;
  expirationDateType: EReportExpirationDateTypeSettings;
  typeOfFrequency: EReportExpirationDateTypeOfFrequencySettings;
  value: number[];
  conditionalType: EReportExpirationDateConditionalTypeSettings;
}

export enum EReportExpirationDateTypeSettings {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export enum EReportExpirationDateTypeOfFrequencySettings {
  DAYS = 'DAYS',
  DAY_OF_THE_WEEK = 'DAY_OF_THE_WEEK',
  DAYS_OF_MONTH = 'DAYS_OF_MONTH',
}

export enum EExpenseReportCostCenterValueSettings {
  SUBSIDIARY = 'SUBSIDIARY',
  DEPARTMENT = 'DEPARTMENT',
}
export interface IReportAllowReimbursementToExpenseOnlySettings {
  allow: boolean;
  allowProject: boolean;
  allowPaymentMethod: boolean;
  allowCostCenter: boolean;
  allowCategory: boolean;
  allowByExpense: boolean;
  costCenterValue?: EExpenseReportCostCenterValueSettings;
}

export enum EReportExpirationDateConditionalTypeSettings {
  WHEN_REIMBURSED = 'whenReimbursed',
  WHEN_APPROVED = 'whenApproved',
}

export interface IReportPdfExportSettings {
  includeRequestorData: boolean;
  includeSignature: boolean;
  includeProjectSubtotal: boolean;
  includeCostCenterSubtotal: boolean;
  includeMileageSubtotal: boolean;
  includeViolation: boolean;
  includeCopyOfReceiptsAttached: boolean;
  includeHistory: boolean;
}

export enum EReportAllowReimbursementToExpenseOnlySettings {
  ALLOW_PROJECT = 'allowProject',
  ALLOW_PAYMENT_METHOD = 'allowPaymentMethod',
  ALLOW_COST_CENTER = 'allowCostCenter',
  ALLOW_CATEGORY = 'allowCategory',
  ALLOW_BY_EXPENSE = 'allowByExpense',
}

export enum EReportPdfExportSettings {
  INCLUDE_REQUESTOR_DATA = 'includeRequestorData',
  INCLUDE_SIGNATURE = 'includeSignature',
  INCLUDE_PROJECT_SUBTOTAL = 'includeProjectSubtotal',
  INCLUDE_COST_CENTER_SUBTOTAL = 'includeCostCenterSubtotal',
  INCLUDE_MILEAGE_SUBTOTAL = 'includeMileageSubtotal',
  INCLUDE_VIOLATION = 'includeViolation',
  INCLUDE_COPY_OF_RECEIPTS_ATTACHED = 'includeCopyOfReceiptsAttached',
  INCLUDE_HISTORY = 'includeHistory',
}
