import styled from 'styled-components';

export const ImagePreviewContainer = styled.div`
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  border-radius: ${props => props.theme.borders.radius.m};
  overflow: hidden;
  position: relative;
`;

export const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImagePreviewIconContainer = styled.div`
  padding: 19px 17px;
  border-radius: ${props => props.theme.borders.radius.m};
  background: ${props => props.theme.colors.neutral.dark.pure};
  opacity: 0;
  position: absolute;
  z-index: 1;

  :hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;
