import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const ApproversContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.borders.width.xs2};
`;

export const TagContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.borders.width.xs2};
  width: 100%;
  max-width: 220px;
`;
