import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';

import { createEstablishment } from '../context/queries/establishment';
import { IGetCreateEstablishmentRequest, IGetCreateEstablishmentResponse } from '../context/types/establishments';

export function useEstablishmentMutate() {
  const [getCreateEstablishment, { data, loading, error }] = useMutation<
    IGetCreateEstablishmentResponse,
    IGetCreateEstablishmentRequest
  >(createEstablishment);

  return {
    getCreateEstablishment,
    establishmentCreated: data?.createEstablishment,
    isLoading: loading,
    error,
  };
}
