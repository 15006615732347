import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs,
  alignItems: 'flex-end',
}));

export const Icon = styled(Icons)(({ theme }) => ({
  color: theme.colors.neutral[60],
  marginTop: theme.spacings.xs3,

  '&:hover': {
    cursor: 'pointer',
    color: theme.colors.primary,
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[50],
  fontWeight: 'bold !important',
  cursor: 'pointer',
}));

export const ContainerIcon = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
`;
