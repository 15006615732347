import { gql } from '@apollo/client';

export const getClients = gql`
  query GetClients {
    getClients {
      clients {
        id
        name
        active
      }
    }
  }
`;
