import { TTargetDelegation } from '@containers/Customizations/components/organisms/Delegation/DelegationPreview';
import { IDelegation } from '@containers/Customizations/context/types/settings/delegation';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useFetch } from '@shared/hooks/service/useFetch';
import { EAuthZPermission } from '@shared/types/authZPermission';

interface IProps {
  target: TTargetDelegation;
  userId: string;
  page: number;
  limit?: number;
}

interface IResponse {
  limit: number;
  total: number;
  userDelegation: IDelegation[];
}

export function useDelegateUsers({ target, userId, page = 1, limit = 10 }: IProps) {
  const { checkPlatformPermission } = usePlatformPermissions();
  const { data: response, error, isLoading } = useFetch<IResponse>(userId ? getPathDataSource() : null);

  function getPathDataSource() {
    switch (target) {
      case 'expense':
        return checkPlatformPermission(EAuthZPermission.CONFIGURATION_DELEGATION)
          ? `v1/user-delegation?userId=${userId}&page=${page}&limit=${limit}`
          : null;
      case 'approval':
        return checkPlatformPermission(EAuthZPermission.CONFIGURATION_DELEGATION_APPROVAL)
          ? `v1/user-delegation-approval?userId=${userId}`
          : null;
      default:
        return null;
    }
  }

  return {
    users: (target === 'approval' ? response ?? [] : response?.userDelegation ?? []) as IDelegation[],
    total: response?.total ?? 0,
    limit: response?.limit ?? 0,
    isLoading: isLoading,
    error,
  };
}
