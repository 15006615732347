import MandatoryInfo from '@atoms/MandatoryInfo/MandatoryInfo';
import { FieldRegister } from '@containers/Policies/componentes/molecules/FieldRegister/FieldRegister';
import { SubsidiaryFormData } from '@containers/Subsidiaries/validators/subsidiaryFormSchema';
import { BoxAdornment, TextField, Toggle, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Box, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  isLoading: boolean;
};

export const SubsidiaryRegisterGeneralData = ({ isLoading }: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext<SubsidiaryFormData>();

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography variant="headline8">{t('organisms.subsidiaryRegister.sections.generalData')}</Typography>

        <MandatoryInfo />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('organisms.subsidiaryRegister.fields.name')}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>

        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="registerNumber"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  value={field.value || null}
                  label={t('organisms.subsidiaryRegister.fields.cnpj')}
                  disabled={false}
                  imaskProps={{
                    mask: '00.000.000/0000-00',
                  }}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>

        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="ref"
              render={({ field: { ref, ...field } }) => (
                <TextField {...field} inputRef={ref} label={t('organisms.subsidiaryRegister.fields.code')} fullWidth />
              )}
            />
          </FieldRegister>
        </Grid>

        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="active"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <BoxAdornment
                  title={t('organisms.subsidiaryRegister.fields.activeSubsidiary.title')}
                  description={t('organisms.subsidiaryRegister.fields.activeSubsidiary.description')}
                  leftAdornment={<Toggle checked={value} onChange={(_, newValue) => onChange(newValue)} />}
                  onClick={() => onChange(!value)}
                  style={{ width: '100%', textAlign: 'left' }}
                />
              )}
            />
          </FieldRegister>
        </Grid>
      </Grid>
    </>
  );
};
