import { useState } from 'react';
import { IConfigurationProject } from '@containers/Projects/context/types/projectTypes';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { mutate } from 'swr';

export function useActiveBatchProjects(projects: IConfigurationProject[]) {
  const projectMutate = useMutate('v1/projects');
  const { t } = useTranslation();

  const [listIdsLoading, setListIdsLoading] = useState<IConfigurationProject['id'][]>([]);

  async function setActiveBatchProject(ids: IConfigurationProject['id'][], active: boolean) {
    setListIdsLoading([...listIdsLoading, ...ids]);

    try {
      const response = await projectMutate.mutation.trigger({
        path: 'toggle/active',
        options: {
          method: 'PATCH',
          body: JSON.stringify({ ids, active }),
        },
      });

      const _listIdsLoading = listIdsLoading;

      ids.forEach(id => {
        const index = _listIdsLoading.findIndex(_id => _id === id);
        _listIdsLoading.splice(index, 1);
      });

      response?.forEach(project => {
        if (!project.updated)
          showToast({
            title: `${t('pages.projectRegister.project')} ${
              projects?.find(_project => _project.id == project.id)?.name ?? ''
            }`,
            message: t('pages.projectRegister.feedbacks.toggleStatus.error'),
            type: 'error',
          });
        else
          showToast({
            title: `${t('pages.projectRegister.project')} ${
              projects?.find(_project => _project.id == project.id)?.name ?? ''
            }`,
            message: t('pages.projectRegister.feedbacks.toggleStatus.success'),
            type: 'success',
          });
      });

      mutate(key => typeof key === 'string' && key.includes('v1/projects?page='));
      setListIdsLoading(_listIdsLoading);
    } catch (_error) {
      showToast({
        message: _error.message,
        type: 'error',
      });
      setListIdsLoading([]);
    }
  }

  return {
    activeBatchProjects: projectMutate.mutation.data,
    setActiveBatchProject,
    error: projectMutate.mutation.error,
    isLoading: projectMutate.mutation.isMutating,
    listIdsLoading,
  };
}
