import { reportListRowPrintItems } from '../../../../../shared/constant';
import { ReportCardEmpty } from '../ReportCard/ReportCardEmpty';
import * as SC from './styled';

export interface ReportsListRowEmptyProps {
  numberReports?: number;
}

export const ReportsListRowEmpty = ({ numberReports = reportListRowPrintItems }: ReportsListRowEmptyProps) => {
  return (
    <SC.Container>
      {new Array(numberReports).fill({}).map((_, key) => (
        <SC.ReportCardContainer key={key}>
          <ReportCardEmpty />
        </SC.ReportCardContainer>
      ))}
    </SC.Container>
  );
};
