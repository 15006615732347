import { ExpenseActions } from './ExpenseActions';
import { ExpensePageActions } from './ExpensePageActions/ExpensePageActions';
import * as SC from './styled';

export const SharedExpenseActions = () => (
  <SC.Container>
    <ExpensePageActions />
    <ExpenseActions />
  </SC.Container>
);
