import { EAdvanceStatus } from '@containers/Advances/context/types/advance';
import { getErrorToast } from '@shared/helpers/errorToast';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { getAdvancesForApproval } from '../context/queries/advances';
import { IAdvanceForApprovalListFilters, IAdvanceForApprovalListResponse } from '../context/types/advances';

export function useAdvancesForApproval() {
  const [getAllAdvances, { loading, data, error }] = useLazyQuery<
    { getAdvancesForApproval: IAdvanceForApprovalListResponse },
    IAdvanceForApprovalListFilters
  >(getAdvancesForApproval);

  async function getAdvancesList({
    page = 1,
    limit = 20,
    status = [EAdvanceStatus.SUBMITTED],
    referenceId,
  }: IAdvanceForApprovalListFilters) {
    try {
      const response = await getAllAdvances({
        variables: {
          limit,
          page,
          status,
          referenceId,
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
      });
      const advancesData = response.data.getAdvancesForApproval;

      return {
        ...advancesData,
        isLoading: loading,
        error,
      };
    } catch (err) {
      getErrorToast(err);
    }
  }

  return {
    getAdvances: getAdvancesList,
    advancesData: {
      advances: data?.getAdvancesForApproval?.advances || [],
      isLoading: loading,
      currentPage: data?.getAdvancesForApproval?.currentPage,
      total: data?.getAdvancesForApproval?.total || 0,
      error,
    },
  };
}
