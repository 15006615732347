import { useTranslation } from '@locale/Translator';

export type TFaq = 'expenses' | 'reports' | 'accounting' | 'customizations';

interface UseFaq {
  tooltipDescription: string;
  onFaqClick(): void;
}

export function useFaq(linkFaqType: TFaq): UseFaq {
  const { t } = useTranslation();

  const faqData = {
    expenses: 'https://beneficios.flashapp.com.br/faq/flash-expense-nova-plataforma#despesas-colaborador',
    reports: 'https://beneficios.flashapp.com.br/faq/flash-expense-nova-plataforma#relat%C3%B3rios-colaborador',
    accounting:
      'https://beneficios.flashapp.com.br/faq/flash-expense-nova-plataforma#pagamentos-e-contabiliza%C3%A7%C3%B5es-administrador',
    customizations:
      'https://beneficios.flashapp.com.br/faq/flash-expense-nova-plataforma#personaliza%C3%A7%C3%A3o-do-m%C3%B3dulo-administrador',
  };

  function onFaqClick() {
    window.open(faqData[linkFaqType], '_blank');
  }

  return {
    tooltipDescription: t(`molecules.pageHeader.faq.${linkFaqType}`),
    onFaqClick,
  };
}
