import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface ItemContainerProps {
  isActive: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const Header = styled.div`
  border-bottom: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
  padding-bottom: ${({ theme }) => theme.spacings.xs5};
`;

export const HeaderContent = styled.div`
  padding: ${({ theme }) => theme.spacings.xs2};
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[95]};
  }
`;

export const HeaderDescription = styled(Typography)`
  font-weight: 700 !important;
  text-align: center;
  padding: ${({ theme }) => theme.spacings.xs3};
`;

export const DescriptionGoBack = styled(Typography)`
  font-weight: 700 !important;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const Body = styled.div`
  min-width: 290px;
  max-width: 350px;
`;

export const EmptyPeople = styled.div`
  width: 100%;
  height: 135px;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[80]};
  font-weight: 700 !important;
`;

export const Footer = styled.div`
  border-top: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs2} ${({ theme }) => theme.spacings.xs2} 0
    ${({ theme }) => theme.spacings.xs2};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  max-height: 240px;
  overflow: auto;
`;

export const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs2};
  width: 100%;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.secondary[95] : theme.colors.neutral[100])};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.secondary[50] : theme.colors.neutral[50])};
  font-weight: ${({ isActive }) => (isActive ? 700 : 600)}!important;
  cursor: pointer;
  max-width: 280px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary[90]};
    color: ${({ theme }) => theme.colors.neutral[40]};
  }
`;

export const ItemDescription = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
