import { EExpenseType } from '@containers/Expenses/context/types';
import { IBreadcrumbItem } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { expensesBaseUrl } from '@shared/constant';
import { useTranslation } from 'react-i18next';

interface IProps {
  isEdit: boolean;
  expenseType: EExpenseType;
}

export function useBreadcrumbs({ isEdit, expenseType }: IProps): IBreadcrumbItem[] {
  const { t } = useTranslation();

  function getBreadcrumbExpenseType() {
    if (isEdit) {
      return expenseType == EExpenseType.MANUAL ? 'editExpense' : 'editMileage';
    }

    return expenseType == EExpenseType.MANUAL ? 'newExpense' : 'addMileage';
  }

  return [
    {
      to: expensesBaseUrl,
      label: t('report.expenses'),
    },
    {
      to: '',
      label: t(`pages.expenseRegister.${getBreadcrumbExpenseType()}`),
    },
  ];
}
