import { SubscriptionInformation } from '@containers/Subscription/components/molecules/SubscriptionInformation/SubscriptionInformation';
import { CorporateCardContractInformation } from '@containers/Subscription/context/types/contractInformation';
import { useSubscriptionPlan } from '@containers/Subscription/hooks/useSubscriptionPlan';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Trans } from 'react-i18next';

import { useCorporateCardConditions } from '../hooks/useCorporateCardConditions';

type Props = {
  corporateCardContract?: CorporateCardContractInformation;
};

export const CorporateCardCommercialConditions = ({ corporateCardContract }: Props) => {
  const t = useTranslate();

  const { corporateCardItems, additionalCardValue, freeCardQuantity } =
    useCorporateCardConditions(corporateCardContract);

  const { planName } = useSubscriptionPlan();

  return (
    <SubscriptionInformation
      title={t('organisms.subscriptionDetails.corporateCard.title')}
      description={t('organisms.subscriptionDetails.corporateCard.caption', { planName })}
      items={corporateCardItems}
      price={{
        value: corporateCardContract?.conditions?.totalAdditionalCardValue || 0,
        highligted: true,
      }}
      footer={
        <Trans
          i18nKey="organisms.subscriptionDetails.corporateCard.infos"
          values={{
            freeCardQuantity: freeCardQuantity,
            additionalValue: additionalCardValue,
          }}
          components={{ b: <b /> }}
        />
      }
    />
  );
};
