import { useState } from 'react';
import { TabItens } from '@flash-tecnologia/hros-web-ui-v2';
import { useAdvancesPermissions } from '@shared/hooks/permissions/systemPermissions/useAdvancesPermissions';
import { useTranslation } from 'react-i18next';

import { ReportsTabGrid } from '../ReportsTabGrid';
import { AdvancesGridPreview } from './AdvancesGrid/AdvancesGrid';
import { usePayablesApprovalPermission } from './hooks/usePayablesApprovalPermission';
import { PayAblesApprovalTab } from './PayAblesTabItems';
import * as SC from './styled';

export const ApprovalsPreview = () => {
  const { t } = useTranslation();
  const [reportsQuantity, setReportsQuantity] = useState(0);
  const [advancesQuantity, setAdvancesQuantity] = useState(0);
  const { hasApprover: isAllowedViewAdvances } = useAdvancesPermissions();
  const { canViewPayablesTab } = usePayablesApprovalPermission();

  const tabItens: TabItens[] = [
    {
      component: <ReportsTabGrid setReportsQuantity={setReportsQuantity} />,
      label: `${t('organisms.approvals.tabs.reports')} ${Boolean(reportsQuantity) ? '(' + reportsQuantity + ')' : ''} `,
    },
  ];

  if (isAllowedViewAdvances) {
    tabItens.push({
      component: <AdvancesGridPreview setAdvancesQuantity={setAdvancesQuantity} />,
      label: `${t('organisms.approvals.tabs.advances')} ${
        Boolean(advancesQuantity) ? '(' + advancesQuantity + ')' : ''
      } `,
    });
  }

  if (canViewPayablesTab) {
    tabItens.push({
      component: <PayAblesApprovalTab />,
      label: t('organisms.approvals.tabs.companyPayables'),
    });
  }

  if (isAllowedViewAdvances || canViewPayablesTab) {
    return (
      <div>
        <SC.Tabs tabItens={tabItens} />
      </div>
    );
  }

  return (
    <div>
      <ReportsTabGrid setReportsQuantity={setReportsQuantity} />
    </div>
  );
};
