import React from 'react';
import { TypographyProps } from '@flash-tecnologia/hros-web-ui-v2';

import { ColorVariant, SizeVariant } from 'src/themes/types/Variants';

import * as SC from './styled';

export interface StatusTagProps {
  /**
   * Tag text content
   */
  children: React.ReactNode;

  /**
   * Sets the size
   *
   * @default size
   */
  size?: SizeVariant;

  /**
   * Sets variant theme
   *
   * @default primary
   */
  variant?: ColorVariant;

  /**
   * if "true" it displays the icon on the left side of the description
   *
   * @default primary
   */
  showStatusIcons?: boolean;
}

export const StatusTag = ({
  children,
  size = 'medium',
  variant = 'secondary',
  showStatusIcons = false,
}: StatusTagProps) => {
  const typographyVariants: { [k in SizeVariant]: TypographyProps['variant'] } = {
    small: `caption`,
    medium: 'body4',
    large: 'body3',
    xsmall: 'body1',
  };

  return (
    <SC.StatusTagContainer variant={variant} size={size}>
      {showStatusIcons && <SC.StatusIcon variant={variant} />}
      <SC.StatusTagTitle variant={typographyVariants[size]}>{children}</SC.StatusTagTitle>
    </SC.StatusTagContainer>
  );
};
