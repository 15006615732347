import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { ApprovalsReportDetails } from '@containers/Approvals/components/organisms/ApprovalsReportDetails';
import { AdvanceRegister } from '@pages/Advances/AdvanceRegister/AdvanceRegister';
import { Approvals } from '@pages/Approvals';
import { ExpenseDetails } from '@pages/ExpenseDetails';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function ApprovalsRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['INTERMEDIATE_APPROVALS']}
          routePermissions={[EAuthZPermission.APPROVALS_VIEW]}
        />
      ),
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Aprovações" moduleName="Aprovações" />,
      children: [
        {
          path: Router.getApprovals(),
          element: <Approvals />,
        },
        {
          path: Router.getApprovalsReportDetails(':id'),
          element: <ApprovalsReportDetails />,
        },
        {
          path: Router.getApprovalsExpenseDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          path: Router.getApprovalsOriginDestinationDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          path: Router.getApprovalsOdometerDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          path: Router.getApprovalsAdvancedDetails(':id'),
          element: <AdvanceRegister />,
        },
      ],
    },
  ];
}
