import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useAnalyticsFiltersManager } from '@containers/Analytics/components/molecules/AnalyticsFilters/hooks/useAnalyticsFiltersManager';
import { EAnalyticsDataType, IAnalyticsDataField } from '@containers/Analytics/context/types/analytics';
import { EExportAnalyticsType } from '@containers/Analytics/context/types/export';
import { EAnalyticsType } from '@containers/Analytics/context/types/filters';
import { useAnalyticsData } from '@containers/Analytics/hooks/dataSource/useAnalyticsData';
import { ExpenseReceipts } from '@containers/Expenses/components/organisms/ExpenseGrid/ExpenseReceipts/ExpenseReceipts';
import { IExpenseAttachment } from '@containers/Expenses/context/types';
import { IGridColumn } from '@organisms/Grid/Grid';
import { useDateFormatter, useValueFormatter } from '@shared/hooks';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { CustomizeReport } from '../../molecules/CustomizeReport/CustomizeReport';
import { EstablishmentGrid } from '../../molecules/EstablishmentGrid/EstablishmentGrid';
import { AnalysisContainer } from '../AnalysisContainer/AnalysisContainer';

type CustomColumn = Pick<IGridColumn, 'Header' | 'accessor'>;

export interface IProps {
  analyticsType: EAnalyticsType;
  translatePathBase: string;
  showMoreFiltersButton?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderActions?: (data: Record<string, any>) => ReactNode;
  customColumns?: CustomColumn[];
  renderRow?: (row: Record<string, unknown>, data: unknown) => Record<string, unknown>;
}

export interface IAnalyticsPagination {
  page: number;
  itemsPerPage: number;
}

enum EAcessor {
  ACTIONS = 'actions',
}

export function AnalyticsSection({
  translatePathBase,
  analyticsType,
  showMoreFiltersButton = true,
  renderActions,
  customColumns,
  renderRow,
}: IProps) {
  const defaultTranslate = useTranslate('analytics.actions');

  const t = useTranslate(translatePathBase);
  const [pagination, setPagination] = useState<IAnalyticsPagination>({ page: 1, itemsPerPage: 10 });
  const { filters, filtersSelected, filtersIsLoading, applyAllFilters } = useAnalyticsFiltersManager(
    analyticsType,
    pagination,
    setPagination,
  );
  const [selectedFields, setSelectedFields] = useState<string[]>(null);
  const { getAnalyticsData, analyticsData, isLoading, total } = useAnalyticsData(analyticsType, translatePathBase);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const { getDateOnlyFormatter, getDateWithTimeFormatter } = useDateFormatter();
  const { getValueWithCurrency } = useValueFormatter();
  const dataAnalyticsGrid = useMemo(
    () => ({ columns: getColumnsSerialize(), rows: getRowsSerialize() }),
    [JSON.stringify(analyticsData)],
  );

  function getColumnsSerialize() {
    const columns: IGridColumn[] = [];
    const selectedColumns = analyticsData?.fields
      ? analyticsData?.fields.filter(_field =>
          analyticsData?.selectedFields?.some(_selectedField => _selectedField === _field.key),
        )
      : [];

    selectedColumns?.forEach(field => {
      if (field.key) {
        columns.push({
          Header: field.label ?? '-',
          accessor: field.key?.[0].toLowerCase() + field.key?.substring(1),
          disableSortBy: true,
          sticky: false,
        });
      }
    });

    if (customColumns && selectedColumns.length > 0) {
      columns.push(...customColumns.map(c => ({ ...c, disableSortBy: true, sticky: false })));
    }

    if (selectedColumns.length > 0 && renderActions) {
      columns.push({
        Header: defaultTranslate('actions'),
        accessor: EAcessor.ACTIONS,
        disableSortBy: true,
        sticky: 'right',
      });
    }

    return columns;
  }

  async function getExport(_exportType: EExportAnalyticsType) {
    setExportIsLoading(true);
    await getAnalyticsData({
      config: {
        page: pagination.page,
        limit: pagination.itemsPerPage,
      },
      filters: filtersSelected,
      exportType: _exportType,
      selectedFields: selectedFields || analyticsData?.selectedFields || [],
    });
    setExportIsLoading(false);
  }

  function getRowsSerialize() {
    const rows: Record<IGridColumn['accessor'], ReactNode | IExpenseAttachment[]>[] = [];

    analyticsData?.data.forEach(_data => {
      let row: Record<IGridColumn['accessor'], ReactNode | IExpenseAttachment[]> = {};

      Object.keys(_data).forEach(_key => {
        const fieldKey = _key[0].toUpperCase() + _key.substring(1);

        const field: IAnalyticsDataField = analyticsData?.fields?.find(
          _field => _field.key === fieldKey || _field.key === _key,
        );

        if (fieldKey === 'ExpenseEstablishmentCompanyName' && _data[_key])
          row[_key] = <EstablishmentGrid establishment={_data[_key]} sumUp />;
        else row[_key] = getFormattedData(field, _data[_key]);
      });

      if (renderActions) {
        row[EAcessor.ACTIONS] = renderActions(_data);
      }

      if (renderRow) {
        row = renderRow(row, _data) as Record<IGridColumn['accessor'], ReactNode | IExpenseAttachment[]>;
      }

      rows.push(row);
    });

    return rows;
  }

  function getFormattedData(field: IAnalyticsDataField, data: ReactNode | IExpenseAttachment[]) {
    const empty = ' - ';

    switch (field?.format) {
      case EAnalyticsDataType.DATE:
        return getDateOnlyFormatter(data);
      case EAnalyticsDataType.DATE_TIME:
        return getDateWithTimeFormatter(data);
      case EAnalyticsDataType.DATE_TIME_WITH_TIMEZONE:
        return getDateWithTimeFormatter(data);
      case EAnalyticsDataType.CURRENCY:
      case EAnalyticsDataType.CURRENT_EVENT:
        return data ? getValueWithCurrency({ value: Number(data) }) : empty;
      case EAnalyticsDataType.RECEIPT:
        return <ExpenseReceipts attachments={(data as IExpenseAttachment[]) || []} />;
      default:
        return data || empty;
    }
  }

  useEffect(() => {
    getAnalyticsData({
      config: {
        page: pagination.page,
        limit: pagination.itemsPerPage,
      },
      filters: filtersSelected,
      selectedFields,
    });
  }, [JSON.stringify({ ...pagination, ...filtersSelected, selectedFields })]);

  return (
    <AnalysisContainer
      key={0}
      analyticsType={analyticsType}
      header={{ title: t('title') }}
      onExportClick={getExport}
      gridData={{
        columns: dataAnalyticsGrid.columns,
        rows: dataAnalyticsGrid.rows,
        isLoading: isLoading && !exportIsLoading,
        currentPage: pagination.page,
        totalItems: total,
        rowsPerPage: pagination.itemsPerPage,
        rowsLoading: 10,
        hasPagination: total > pagination.itemsPerPage,
        paginationIsDisabled: exportIsLoading,
        onPageClick: page => {
          setPagination({ ...pagination, page });
        },
        onRowsPerPageClick: itemsPerPage => {
          setPagination({ page: 1, itemsPerPage });
        },
      }}
      filtersData={{
        filters,
        filtersIsLoading: filtersIsLoading && !Boolean(filters?.length),
        filtersSelected,
        applyAllFilters,
        isAllDisabled: isLoading,
        showMoreFiltersButton: showMoreFiltersButton,
      }}
      exportIsLoading={exportIsLoading}
      customizeReport={
        <CustomizeReport
          analyticsType={analyticsType}
          analyticsData={analyticsData}
          isLoading={isLoading}
          onApply={setSelectedFields}
        />
      }
    />
  );
}
