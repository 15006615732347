import { StatusTag } from '@atoms/StatusTag';
import { Tooltip } from '@atoms/Tooltip';
import { IFinancialReportGrid } from '@containers/Financial/context/types/reports';
import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useIntegrations } from '@shared/hooks/useIntegrations';
import { useTranslation } from 'react-i18next';

import { ActionOptions, IconAction } from '../FinancialReportGridActions';
import { UseAllowedActions } from '../hooks/useBulkActionsAllowed';
import * as SC from './styled';

interface ActionsMultipleSelectedReportsProps {
  selectedItems?: IFinancialReportGrid['id'][];
  totalItems?: number;
  actionsAllowed: UseAllowedActions;
  onReproveClick?(reportsSelected: IFinancialReportGrid['id'][]): void;
  onRepayClick?(reportsSelected: IFinancialReportGrid['id'][]): void;
  onExportPdfClick?(reportsSelected: IFinancialReportGrid['id'][]): void;
  onExportPdfByCategoryClick?(reportsSelected: IFinancialReportGrid['id'][]): void;
}

export function ActionsMultipleSelectedReports({
  selectedItems,
  totalItems,
  actionsAllowed,
  onReproveClick,
  onRepayClick,
  onExportPdfClick,
  onExportPdfByCategoryClick,
}: ActionsMultipleSelectedReportsProps) {
  const { t } = useTranslation();
  const events = {
    reprove: onReproveClick,
    repay: onRepayClick,
    exportPdf: onExportPdfClick,
    exportPdfByCategory: onExportPdfByCategoryClick,
  };
  const { hasFinancialIntegration } = useIntegrations();

  function onEventActionClick(action: ActionOptions) {
    return events[action === ActionOptions.CANCEL_INTEGRATION ? ActionOptions.REPROVE : action](selectedItems);
  }

  function checkShowAction(action: ActionOptions) {
    if (action === ActionOptions.CANCEL_INTEGRATION) return hasFinancialIntegration;
    if (action === ActionOptions.CANCEL) return false;

    return action !== ActionOptions.VIEW;
  }

  return (
    <SC.Container>
      <StatusTag variant="secondary">
        {t('organisms.financialReportGrid.selectedItems', { totalItems, selectedItems: selectedItems.length })}
      </StatusTag>
      <SC.ContainerActions>
        {Object.keys(ActionOptions).map((action, key) => {
          if (checkShowAction(ActionOptions[action]))
            return (
              <div key={key}>
                <Tooltip
                  arrow
                  title={
                    !actionsAllowed[ActionOptions[action]]
                      ? t(
                          `organisms.financialReportGrid.actionsOptions.tooltip.${
                            ActionOptions[action] === ActionOptions.REPAY && hasFinancialIntegration
                              ? 'integration'
                              : ActionOptions[action]
                          }`,
                        )
                      : ''
                  }
                  placement="top">
                  <div>
                    <LinkButton disabled={!actionsAllowed[ActionOptions[action]]} variant="default">
                      <SC.LinkContent onClick={() => onEventActionClick(ActionOptions[action])}>
                        <Icons name={IconAction[action]} fill="transparent" />
                        {t(
                          `organisms.financialReportGrid.actionsOptions.${
                            ActionOptions[action] === ActionOptions.REPAY && hasFinancialIntegration
                              ? 'sendIntegration'
                              : ActionOptions[action]
                          }`,
                        )}
                      </SC.LinkContent>
                    </LinkButton>
                  </div>
                </Tooltip>
              </div>
            );
        })}
      </SC.ContainerActions>
    </SC.Container>
  );
}
