import { useMemo } from 'react';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';

import { Option } from '../Autocomplete/Autocomplete';

export interface IPlace {
  id: string;
  completeAddress: string;
  name: string;
  address: string;
}

export function useOptionsEstablishments(locations: IEstablishment[] | IPlace[]) {
  const options: Option[] = useMemo(() => {
    return locations?.map(
      (item): Option => ({
        label: item.name || item.place,
        value: item.id || item.placeId || `${item.lat}-${item.lng}`,
        icon: 'IconMapPin',
        caption: item.address || item.address,
      }),
    );
  }, [locations]);

  return options;
}
