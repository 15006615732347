import { gql } from '@apollo/client';

export const getAllExpenses = gql`
  query GetExpenses($page: Int!, $limit: Int!, $hasPending: Boolean, $reportId: Int) {
    getExpenses(page: $page, limit: $limit, hasPending: $hasPending, reportId: $reportId) {
      expenses {
        id
        referenceId
        type
        currencyPrefix
        status
        value
        category {
          description
        }
        card {
          id
          lastFourDigits
        }
        violationNumber
        commentsNumber
        attachmentsNumber
        attachments {
          contentType
          path
        }
        report {
          referenceId
          id
          name
          status
        }
        establishment {
          place
        }
        expenseDate
        establishment {
          place
        }
        route {
          imagePath
        }
      }
      total
      currentPage
      pages
      limit
    }
  }
`;

export const getExpense = gql`
  query GetExpenseById($id: Int!) {
    getExpenseById(id: $id) {
      id
      referenceId
      type
      description
      value
      valueInCompanyCurrency {
        value
        rate
      }
      currencyPrefix
      currencyCode
      quantity
      status
      category {
        description
      }
      establishment {
        place
      }
      costCenter {
        name
      }
      violations {
        description
      }
      violationNumber
      comments {
        message
        user {
          name
        }
        createdAt
      }
      report {
        id
        name
        status
      }
      attachments {
        path
        contentType
      }
      attachmentsNumber
      history {
        createdAt
        description
      }
      client {
        name
      }
      project {
        name
      }
      expenseDate
      customFields {
        id
        value
      }
      route {
        origin
        destination
        distance
        ratePerKm
        waypoints {
          placeId
          place
          address
          lat
          lng
        }
        waypointsNumber
        overviewPolyline
        imagePath
      }
      odometer {
        start {
          attachment {
            path
            contentType
          }
          value
        }
        end {
          attachment {
            path
            contentType
          }
          value
        }
        ratePerKm
        distance
      }
    }
  }
`;

export const getExpenseFields = gql`
  query GetExpenseFormFields {
    getExpenseFormFields {
      client {
        active
        required
        allowsEditing
        fieldOrder
      }
      project {
        active
        required
        allowsEditing
        fieldOrder
      }
      quantity {
        active
        required
        allowsEditing
        fieldOrder
      }
      category {
        active
        required
        allowsEditing
        fieldOrder
      }
      address {
        active
        required
        allowsEditing
        fieldOrder
      }
      receiptFile {
        active
        required
        allowsEditing
        fieldOrder
      }
      currency {
        active
        required
        allowsEditing
        fieldOrder
      }
      paymentType {
        active
        required
        allowsEditing
        fieldOrder
      }
      value {
        active
        required
        allowsEditing
        fieldOrder
      }
      date {
        active
        required
        allowsEditing
        fieldOrder
      }
      description {
        active
        required
        allowsEditing
        fieldOrder
      }
      costCenter {
        active
        required
        allowsEditing
        fieldOrder
      }
      refundable {
        active
        required
        allowsEditing
        fieldOrder
      }
      billable {
        active
        required
        allowsEditing
        fieldOrder
      }
      report {
        active
        required
        allowsEditing
        fieldOrder
      }
      invoice {
        active
        required
        allowsEditing
        fieldOrder
      }
      invoiceKey {
        active
        required
        allowsEditing
        fieldOrder
      }
      custom {
        id
        label
        type
        options
        active
        active
        required
        fieldOrder
      }
    }
  }
`;

export const getExpenseCustomFieldsLabels = gql`
  query GetExpenseCustomFields {
    getExpenseFormFields {
      custom {
        label
        id
      }
    }
  }
`;

export const createExpense = gql`
  mutation CreateExpense($expense: ExpenseInput!) {
    createExpense(expense: $expense) {
      id
      type
    }
  }
`;

export const createExpenseV2 = gql`
  mutation CreateExpense($expense: ExpenseInput!) {
    createExpense(expense: $expense, useNewUpload: true) {
      id
      type
    }
  }
`;

export const updateExpenseById = gql`
  mutation UpdateExpenseById($expense: ExpenseInput!, $expenseId: Int!) {
    updateExpenseById(expense: $expense, expenseId: $expenseId) {
      id
    }
  }
`;

export const updateExpenseByIdV2 = gql`
  mutation UpdateExpenseById($expense: ExpenseInput!, $expenseId: Int!) {
    updateExpenseById(expense: $expense, expenseId: $expenseId, useNewUpload: true) {
      id
    }
  }
`;

export const getExpenseFormById = gql`
  query GetExpenseFormById($id: Int!) {
    getExpenseById(id: $id) {
      id
      referenceId
      type
      refundable
      billable
      description
      value
      valueInCompanyCurrency {
        rate
        value
      }
      card {
        id
      }
      currencyCode
      quantity
      status
      category {
        id
        description
      }
      establishment {
        placeId
        place
        address
        lat
        lng
      }
      costCenter {
        id
        name
      }
      cardId
      paymentMethod {
        id
        description
      }
      comments {
        id
        message
      }
      attachments {
        path
        contentType
      }
      attachmentsNumber
      report {
        id
        name
        description
      }
      route {
        origin
        destination
        distance
        ratePerKm
        waypoints {
          place
          address
          lng
          lat
        }
        imagePath
        waypointsNumber
        overviewPolyline
      }
      invoiceKey
      invoice
      customFields {
        id
        value
      }
      client {
        id
        name
      }
      project {
        id
        name
      }
      expenseDate
      createdAt
      route {
        origin
        destination
        distance
        ratePerKm
        waypoints {
          placeId
          place
          address
          lat
          lng
        }
        waypointsNumber
        overviewPolyline
      }
      odometer {
        start {
          attachment {
            path
            contentType
          }
          value
        }
        end {
          attachment {
            path
            contentType
          }
          value
        }
        ratePerKm
        distance
      }
      ocrExpenseStatus
    }
  }
`;

export const deleteExpenseList = gql`
  mutation DeleteExpenseList($expenses: [Int!]!) {
    deleteExpenseList(expenses: $expenses) {
      expenses {
        id
        message
      }
    }
  }
`;
