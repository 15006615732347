import { IconButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Button = styled.button<{ fullWidth?: boolean }>`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  background-color: ${props => props.theme.colors.secondary[99]};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '386px')};
  width: 100%;
  height: 134px;
  gap: ${props => props.theme.spacings.xs};
  border-radius: ${props => props.theme.borders.radius.s};
`;

export const ButtonTypography = styled(Typography)`
  color: ${props => props.theme.colors.primary};
  font-weight: 600 !important;
`;

export const Icon = styled(IconButton)`
  align-self: unset !important;

  &.iconButton-custom-theme-filled.MuiButtonBase-root {
    background-color: ${props => props.theme.colors.secondary[95]};
  }

  &.iconButton-custom-theme-filled.MuiButtonBase-root.size-small svg {
    stroke: ${props => props.theme.colors.primary};
  }
`;
