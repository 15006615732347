import { useMemo } from 'react';
import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { useFormContext } from 'react-hook-form';

import { EClientFields } from '../RegisterClientForm';

export function useSetClientFieldsValues(client: IConfigurationClient) {
  const { setValue } = useFormContext<IConfigurationClient>();

  useMemo(() => {
    if (client) {
      Object.keys(client)?.map(clientKey => {
        setValue(EClientFields[clientKey], client[clientKey] ?? null);
      });
    }
  }, [client]);
}
