import * as SC from './styled';

export interface LoaderProps {
  /**
   * If 'true' show the loader
   */
  show: boolean;

  /**
   * Loader description
   *
   */
  description?: string;
}

export const Loader = ({ show = false, description }: LoaderProps) => {
  if (show)
    return (
      <SC.Container>
        <SC.LoaderContent>
          <SC.LoaderPageContent>
            <SC.LoaderPage variant="primary" size="large" />
          </SC.LoaderPageContent>
          <SC.Description variant="body3">{description}</SC.Description>
        </SC.LoaderContent>
      </SC.Container>
    );
  return <></>;
};
