import { IPolicy } from '@containers/Policies/context/types/types';
import { useAdvancesPolicySettings } from '@containers/Policies/hooks/advances/useAdvancesPolicySettings';
import { Divider, IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { PolicyRuleSection } from '../../molecules/PolicyRuleSection/PolicyRuleSection';
import { PolicyAdvancesLimits } from './PolicyAdvancesLimits';
import { PolicyAdvancesPreferences } from './PolicyAdvancesPreferences/PolicyAdvancesPreferences';

interface IProps {
  policyId: IPolicy['id'];
  onCloseRule(): void;
}

export function PolicyAdvances({ policyId, onCloseRule }: IProps) {
  const t = useTranslate('policy.register.advances');

  const { policy: advancePolicy, isLoading } = useAdvancesPolicySettings(policyId);

  return (
    <PolicyRuleSection
      icon="IconCurrencyDollar"
      title={t('title')}
      caption={t('caption')}
      action={<IconButton variant="line" icon="IconX" size="small" onClick={onCloseRule} />}>
      <PolicyAdvancesPreferences policyId={policyId} advancePolicy={advancePolicy} isLoading={isLoading} />

      <Divider orientation="horizontal" />

      <PolicyAdvancesLimits policyId={policyId} advancePolicy={advancePolicy} isPolicyLoading={isLoading} />
    </PolicyRuleSection>
  );
}
