import { createContext, useContext, useEffect, useState } from 'react';
import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';

import { SessionContext } from '../SessionContext/sessionContext';
import { IDelegateContext, IDelegateProviderProps } from './types';

export const DelegateContext = createContext<IDelegateContext>(null);

export function DelegateContextProvider({ children }: IDelegateProviderProps) {
  const { companyId } = useContext(SessionContext);
  const key = 'userDelegateExpense';
  const [userDelegateContext, setUserDelegateContext] = useState<IDelegateContext['userDelegateContext']>(
    getFromLS(key),
  );

  function setUserDelegate(delegateValue: IDelegateContext['userDelegateContext']) {
    const value = (delegateValue && { ...delegateValue, companyId }) || delegateValue;
    setUserDelegateContext(value);
    setInLS({ key, value });
  }

  useEffect(() => {
    if (companyId !== userDelegateContext?.companyId && userDelegateContext?.id) setUserDelegate(null);
  }, [companyId]);

  return (
    <DelegateContext.Provider
      value={{
        userDelegateContext,
        setUserDelegate,
      }}>
      {children}
    </DelegateContext.Provider>
  );
}
