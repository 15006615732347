import { EAdvanceStatus } from '@containers/Advances/context/types/advance';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export const useAdvancesForAccountingSummaryFilters = () => {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const { data, error, isLoading, mutate } = useFetch<
    {
      status: EAdvanceStatus;
      value: number;
    }[]
  >(`v1/advances/finance/summary`, '', undefined, undefined, onError);

  return {
    error,
    isLoading: isLoading,
    advancesSummary: data ?? [],
    refetch: mutate,
  };
};
