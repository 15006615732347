import { ActionsButton } from '@molecules/ActionsButton/ActionsButton';
import { subsidiariesBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

export function AddSubsidiaryButton() {
  const t = useTranslate('subsidiaries.addSubsidiaries');
  const navigate = useNavigate();

  return (
    <ActionsButton
      actionName={t('buttonLabel')}
      actionIcon="IconPlus"
      onClick={() => navigate(`${subsidiariesBaseURL}/register`)}
    />
  );
}
