import { useMemo } from 'react';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';

import { updateReportListStatus } from '../context/queries/reports';
import { IGetReportsStatusUpdateRequest, IGetReportsStatusUpdateResponse } from '../context/types/reports';

export function useReportsStatusMutate(isReportDetails?: boolean) {
  const refetchQueries = useMemo(() => {
    if (isReportDetails) {
      return ['GetExpenses', 'GetReportById', 'GetReportHistoryById'];
    }

    return ['GetReportsForFinance', 'GetSummaryForFinance'];
  }, [isReportDetails]);

  const [reportsStatusMutate, { data, loading, error }] = useMutation<
    IGetReportsStatusUpdateResponse,
    IGetReportsStatusUpdateRequest
  >(updateReportListStatus, {
    refetchQueries,
  });

  return {
    reportsStatusMutate,
    expenseCreated: data?.updateReportListStatus?.reports || [],
    isLoading: loading,
    error,
  };
}
