import { EReportStatus } from '@containers/Expenses/context/types';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useReportsPermissions } from '@shared/hooks/permissions/systemPermissions/useReportsPermissions';

import { ReportActionsSkeleton } from './ReportActionsSkeleton';
import * as SC from './styled';

export interface ReportActionsProps {
  reportStatus: EReportStatus;
  isLoading?: boolean;
  onEditReportClick(): void;
  onExportReportClick(): void;
  onDeleteReportClick(): void;
}

export const ReportActions = ({
  reportStatus,
  isLoading = false,
  onEditReportClick,
  onExportReportClick,
  onDeleteReportClick,
}: ReportActionsProps) => {
  const { checkLicenseFeature } = useLicenseFeatures();
  const { getReportActions } = useReportsPermissions();
  const { edit: allowedToEdit, delete: allowedToDelete, export: allowedToExport } = getReportActions(reportStatus);

  if (isLoading) {
    return <ReportActionsSkeleton />;
  }

  return (
    <SC.ReportActionsContainer>
      {checkLicenseFeature('BASIC_REPORTS') && (
        <>
          {allowedToExport && <SC.ActionButton icon="IconUpload" onClick={onExportReportClick} />}
          {allowedToEdit && <SC.ActionButton icon="IconPencil" onClick={onEditReportClick} />}
          {allowedToDelete && <SC.ActionButton icon="IconTrash" onClick={onDeleteReportClick} />}
        </>
      )}
    </SC.ReportActionsContainer>
  );
};
