import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { FormControlLabel as FormControlLabelMui } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: grid;
  gap: ${props => props.theme.spacings.xs4};
  grid-template-rows: min-content min-content;
`;

export const Description = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.theme.spacings.xs};
`;

export const FormControlLabel = styled(FormControlLabelMui)`
  display: flex;
  gap: ${props => props.theme.spacings.xs5};
  cursor: default;
`;

export const LoaderContainer = styled.div`
  margin: 0 ${props => props.theme.spacings.xs3};
`;
