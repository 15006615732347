import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { mutate } from 'swr';

export function useDeleteClients() {
  const clientMutate = useMutate('v1/clients');
  const { t } = useTranslation();

  async function deleteClients(ids: IConfigurationClient['id'][]) {
    const formOfDescription = ids?.length > 1 ? 'pluralForm' : 'singularForm';
    const params = ids.map(_ => {
      return {
        key: 'ids[]',
        value: _,
      };
    });

    await clientMutate.mutation.trigger(
      {
        path: '',
        parameters: params,
        options: {
          method: 'DELETE',
        },
      },
      {
        revalidate: false,
        onError: () =>
          showToast({
            message: t(`molecules.clientToast.deleteErrorMessage.${formOfDescription}`),
            type: 'error',
          }),
      },
    );
    showToast({
      message: t(`molecules.clientToast.deleteSuccessMessage.${formOfDescription}`),
      type: 'success',
    });

    mutate(key => typeof key === 'string' && key.includes('v1/clients?page='));
  }
  return {
    deleteClients,
    deletedClients: clientMutate.mutation.data,
    error: clientMutate.mutation.error,
    isLoading: clientMutate.mutation.isMutating,
  };
}
