import { EAnalyticsType } from '@containers/Analytics/context/types/filters';
import { useTranslation } from '@locale/Translator';

import { AnalyticsGroup } from '../../AnalyticsGroup/AnalyticsGroup';
import { AnalyticsSection } from '../AnalyticsSection';

export function AnalyticsAdvancesSection() {
  const { t } = useTranslation();
  const translationBase: string = 'analytics.sections.advances';
  return (
    <AnalyticsGroup
      header={{
        title: t(`${translationBase}.title`),
        caption: t(`${translationBase}.caption`),
        icon: 'IconCurrencyDollar',
      }}>
      <AnalyticsSection
        key={`reports-overview`}
        analyticsType={EAnalyticsType.ADVANCES_OVERVIEW}
        translatePathBase={'analytics.advancesOverview'}
      />
      <AnalyticsSection
        key={`reports-pending-approvals-by-user`}
        analyticsType={EAnalyticsType.ADVANCES_STATUS_BY_USER}
        translatePathBase={'analytics.advancesUsers'}
        showMoreFiltersButton={false}
      />
    </AnalyticsGroup>
  );
}
