import { Dropzone, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const CustomDropzone = styled(Dropzone)`
  &.dropzone-main-container {
    height: 60px;
    width: 100%;

    .dropzone-file-item {
      margin-top: 0;
      height: 60px;
      padding: ${props => props.theme.spacings.xs3} ${props => props.theme.spacings.m};

      .dropzone-file-item-image {
        display: none;
      }

      .dropzone-file-item-name-container {
        max-width: 100%;
      }
    }
  }

  .dropzone-padding {
    padding: ${props => props.theme.spacings.xs3} ${props => props.theme.spacings.m};
  }

  .dropzone .dropzone-default-children-container {
    flex-direction: row;
    gap: ${props => props.theme.spacings.xs3};

    .icon-container {
      margin-bottom: 0;
      height: 40px;
      width: 40px;
    }

    .dropzone-text-container {
      align-items: flex-start;

      #dropzone-text-dark5 {
        margin: 0;
      }
    }

    .dropzone-select-file-container {
      border-bottom: none;

      .icon-container {
        display: none;
      }
    }
  }
`;

export const OdometerImageContainer = styled.div(({ theme }) => ({
  height: '60px',
  width: '100%',
  border: `${theme.borders.width.xs2} solid ${theme.colors.secondary[80]}`,
  borderRadius: theme.borders.radius.s,
  backgroundColor: theme.colors.secondary[99],
  padding: `${theme.spacings.xs4} ${theme.spacings.xs}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.iconButton-custom-theme-line': {
    borderColor: `${theme.colors.secondary[80]}!important`,
    backgroundColor: `${theme.colors.secondary[90]}!important`,
  },
}));

export const FileName = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[20],
  whiteSpace: 'nowrap',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: theme.spacings.xs5,
}));
