/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { useClientById } from '@containers/Clients/hooks/rest/useClientById';
import { useTranslation } from '@locale/Translator';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { AssignUsers } from './AssignUsers';
import { ClientFieldFactory } from './ClientFieldFactory';
import { ClientFormSkeleton } from './ClientFormSkeleton';
import { useSetClientFieldsValues } from './hooks/useSetClientFieldValues';
import * as SC from './styled';

export enum EClientFields {
  name = 'name',
  email = 'email',
  phoneNumber = 'phoneNumber',
  website = 'website',
  externalCode = 'externalCode',
  active = 'active',
  employees = 'employees',
}

enum EClientFieldsType {
  name = 'TEXT',
  email = 'EMAIL',
  phoneNumber = 'PHONE',
  website = 'TEXT',
  externalCode = 'TEXT',
  active = 'BOOLEAN',
}

interface RegisterClientFormProps {
  onSubmit(data: IConfigurationClient): void;
  step: number;
}

export const RegisterClientForm = ({ onSubmit, step }: RegisterClientFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useFormContext<IConfigurationClient>();
  const { id } = useParams();
  const { client, isLoading } = useClientById(id);
  useSetClientFieldsValues(client);

  const getDescription = field => {
    if (field === EClientFields.active) {
      return t(`clients.registerClientForm.activeDescription`);
    } else if (field === EClientFields?.externalCode) {
      return t(`clients.registerClientForm.externalCodeHelper`);
    }
    return null;
  };
  return (
    <SC.Container hasMaxWidth={step === 0}>
      <SC.TitleContainer step={step}>
        <SC.Title variant="headline8">{t(`clients.registerClient.steps.pageContent.${step}.title`)}</SC.Title>
        {step === 0 ? (
          <SC.RequiredText variant="body3">{t('clients.registerClient.required')}</SC.RequiredText>
        ) : (
          <SC.SubTitle variant="body4">{t('clients.registerClient.assignUsersDescription')}</SC.SubTitle>
        )}
      </SC.TitleContainer>

      <SC.Form onSubmit={handleSubmit(onSubmit)}>
        {step === 0 ? (
          isLoading ? (
            <ClientFormSkeleton printFieldsNumber={6} />
          ) : (
            Object.keys(EClientFields).map((field, key) => {
              return (
                <Controller
                  key={key}
                  defaultValue={null}
                  name={EClientFields[field]}
                  control={control}
                  render={({ field: fieldData, fieldState: { error } }) => {
                    return (
                      <SC.FieldContainer fieldName={field}>
                        <ClientFieldFactory
                          fieldType={EClientFieldsType[field]}
                          error={Boolean(error)}
                          helperText={error?.message}
                          label={t(`clients.registerClientForm.${field}`)}
                          description={getDescription(field)}
                          {...fieldData}
                          value={fieldData?.value ?? client?.[field]}
                          onChange={fieldData.onChange}
                          required={field === 'name'}
                        />
                      </SC.FieldContainer>
                    );
                  }}
                />
              );
            })
          )
        ) : (
          <AssignUsers client={client} isLoadingClient={isLoading} />
        )}
      </SC.Form>
    </SC.Container>
  );
};
