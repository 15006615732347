import { EContractType, ICompanyLicenseAcceptanceTerm } from '@containers/Subscription/context/types/contracts';

export function useCheckSignedContracts() {
  let termsSigned: boolean = true;

  function checkAcceptanceTerms(
    terms: ICompanyLicenseAcceptanceTerm[] | ICompanyLicenseAcceptanceTerm['document'][],
    contractType: EContractType,
  ) {
    return termsSigned && terms.some(term => term?.document === contractType || term === contractType);
  }

  function checkTermsSignedUserAdm(): boolean {
    return termsSigned;
  }

  function checkSignedContracts(
    _acceptanceTerms: ICompanyLicenseAcceptanceTerm[] | ICompanyLicenseAcceptanceTerm['document'][],
  ): boolean {
    if (Boolean(_acceptanceTerms)) {
      termsSigned = checkAcceptanceTerms(_acceptanceTerms, EContractType.FLASH_APP_CONTRACT_CORPORATE_CARD);
      termsSigned = checkAcceptanceTerms(_acceptanceTerms, EContractType.FLASH_APP_SAAS_SERVICE_CONTRACT);
    }
    return checkTermsSignedUserAdm();
  }

  return checkSignedContracts;
}
