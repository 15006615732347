import { IReport, IUser, ReportUser } from '@containers/Reports/context/types/report';
import { useTranslation } from '@locale/Translator';
import { defaultCurrencySymbol } from '@shared/constant';
import { useDateFormatter } from '@shared/hooks';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useReportsPermissions } from '@shared/hooks/permissions/systemPermissions/useReportsPermissions';

import { ReportApprovers } from '../ReportApprovers/ReportApprovers';
import * as SC from '../styled';
import { MainSummaryData } from './MainSummaryData/MainSummaryData';

interface ReportInfosSummaryProps {
  currencyPrefix: string;
  totalValue: number;
  remainingReimbursement?: number;
  approver?: ReportUser;
  requester?: IUser;
  dateLimit?: string;
  reportStatus?: IReport['status'];
  reportId?: IReport['id'];
}

export const ReportInfosSummary = ({
  currencyPrefix = defaultCurrencySymbol,
  totalValue,
  remainingReimbursement,
  approver,
  requester,
  dateLimit,
  reportStatus,
  reportId,
}: ReportInfosSummaryProps) => {
  const { t } = useTranslation();
  const { getReportActions } = useReportsPermissions();
  const { reportDetailShowApproval, reportDetailShowRequester } = getReportActions(reportStatus);
  const { getValueWithCurrency } = useValueFormatter();
  const { getDateOnlyFormatter } = useDateFormatter();

  return (
    <SC.ReportInfosSummary>
      <MainSummaryData
        isTitleMain
        title={getValueWithCurrency({ value: totalValue || 0, currencyPrefix })}
        caption={t('report.totalExpenses')}
      />
      <MainSummaryData
        title={getValueWithCurrency({ value: remainingReimbursement || 0, currencyPrefix })}
        caption={t('report.reimbursableValue')}
      />
      {dateLimit && <MainSummaryData title={getDateOnlyFormatter(dateLimit)} caption={t('report.dateLimit')} />}

      {reportDetailShowRequester && requester?.name && (
        <MainSummaryData title={requester?.name || t('report.uninformed')} caption={t('report.requester')} />
      )}

      {reportDetailShowApproval && !!approver && <ReportApprovers approver={approver} reportId={reportId} />}
    </SC.ReportInfosSummary>
  );
};
