import { Skeleton } from '@mui/material';

import { EAccessor, HeaderGridColumn } from './UseHeaderGridColumns';

export const useHeaderGridColumnsSkeleton = (): HeaderGridColumn[] => {
  return Object.values(EAccessor).map((accessor, index) => ({
    Header: <Skeleton variant="rounded" width={index == 1 ? 58 : 40} height={22} />,
    accessor,
    disableSortBy: true,
  }));
};
