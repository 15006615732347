import { useContext } from 'react';
import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { useRowsPerPageOptions } from '@molecules/GridPagination/kooks/useRowsPerPageOptions';
import { PendingAccountContext } from '@shared/contexts/PendingAccountContext/PendingAccountContext';

import { TableHelper } from './TableHelper';

export function PendingExpensesTable() {
  const { usersQuantity, pagination, isLoading, setPagination } = useContext(PendingAccountContext);
  const { tableHelper } = TableHelper();
  const { rowsPerPageOptions } = useRowsPerPageOptions({ maxRows: 50, intervalItems: 10 });

  return (
    <Table.Root data-testid="accounting-pending-expenses-table">
      <Table.Grid.Root loading={isLoading}>
        <Table.Grid.Header getHeaderGroups={tableHelper.getHeaderGroups} />
        {tableHelper.rows?.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
      {!isLoading && (
        <Table.Pagination
          count={usersQuantity}
          onPaginationChange={({ pageSize, pageNumber }) => setPagination({ ...pagination, pageSize, pageNumber })}
          pagination={pagination}
          pageSizeOptions={rowsPerPageOptions}
        />
      )}
    </Table.Root>
  );
}
