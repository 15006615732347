import { useEffect, useState } from 'react';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { useAttachAdvanceToReport } from '@containers/Advances/hooks/dataSources/useAttachAdvanceToReport';
import { ModalReportSelection } from '@containers/Expenses/components/molecules/ModalReportSelection';
import { ISummaryReport } from '@containers/Expenses/context/types';
import { NewReportModal } from '@containers/Reports/components/molecules/NewReportModal';
import { IReport } from '@containers/Reports/context/types';
import { useAttachExpenseToReport } from '@containers/Reports/hooks/useAttachExpenseToReport';
import { useReportMutate } from '@containers/Reports/hooks/useReportMutate';
import { IconButton, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { useReportDetailsDriver } from '@shared/hooks/navigation/useReportDetailsDriver';
import { useTracking } from '@shared/hooks/useTracking';
import { EAccessScreen } from '@shared/types';

import { RegistrationFormContainerWithReportSkeleton } from './RegistrationFormContainerWithReportSkeleton';
import * as SC from './styled';

export interface IProps {
  report: ISummaryReport | IReport;
  isLoading: boolean;
  targetId: number;
  target: 'EXPENSE' | 'ADVANCED';
  children?: React.ReactNode;
  onSelectReportClick?(selectedReport: ISummaryReport): void;
  fullWidth?: boolean;
  isDisableAction?: boolean;
}

export const RegistrationFormContainerWithReport = ({
  report,
  isLoading,
  targetId,
  children,
  fullWidth,
  target,
  isDisableAction,
  onSelectReportClick,
}: IProps) => {
  const { t } = useTranslation();
  const trackingEvent = useTracking();
  const [showReportSelectionModal, setShowReportSelectionModal] = useState(false);
  const [showNewReportModal, setShowNewReportModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ISummaryReport | IReport>(report);
  const { attachExpenseToReport, loading: isLoadingAttachExpenseToReport } = useAttachExpenseToReport();
  const { handleAttach, isLoading: isLoadingAttachAdvanceToReport } = useAttachAdvanceToReport(
    selectedReport?.name?.toUpperCase() ?? '',
  );
  const { createReport, isLoading: isLoadingCreateReport } = useReportMutate();
  const navigateReportDetails = useReportDetailsDriver();

  function onCloseModal() {
    setShowNewReportModal(false);
    setShowReportSelectionModal(false);
  }

  async function attachData(id: number) {
    if (id && targetId) {
      const _targetIds = [targetId];
      if (target === 'EXPENSE')
        await attachExpenseToReport({
          reportId: id,
          expenseIds: _targetIds,
          accessScreen: EAccessScreen.EXPENSE_FORM,
        });
      else await handleAttach(_targetIds, id);
    }
  }

  const handleReportCreation = async report => {
    trackingEvent('report_create_clicked');
    const response = await createReport(report);

    await attachData(response?.id);

    onSelectReportClick?.(response);
    onCloseModal();
  };

  const handleReportSelection = async () => {
    await attachData(selectedReport?.id);

    onSelectReportClick?.(selectedReport);
    onCloseModal();
  };

  const hasReport = Boolean(selectedReport?.name);

  useEffect(() => {
    report?.id && !selectedReport?.id && setSelectedReport(report);
  }, [report]);

  return (
    <SC.Container fullWidth={fullWidth}>
      <SC.Header hasReport={hasReport}>
        {isLoading ? (
          <RegistrationFormContainerWithReportSkeleton />
        ) : (
          <>
            <SC.DescriptionContainer>
              <ShapeIcon icon="IconFolder" variant="secondary" size="small" />
              {hasReport ? (
                <SC.Description>{selectedReport?.name}</SC.Description>
              ) : (
                <LinkButton
                  disabled={isDisableAction}
                  onClick={() => setShowReportSelectionModal(true)}
                  variant="default">
                  {t('pages.expenseRegister.selectReport')}
                </LinkButton>
              )}
            </SC.DescriptionContainer>
            <SC.ActionsContainer>
              <IconButton
                variant="line"
                icon="IconListSearch"
                size="small"
                disabled={!Boolean(selectedReport?.id) || isDisableAction}
                onClick={() => setShowReportSelectionModal(true)}
              />

              <IconButton
                variant="line"
                icon="IconEye"
                size="small"
                disabled={!Boolean(selectedReport?.id)}
                onClick={() => navigateReportDetails(selectedReport?.id)}
              />
            </SC.ActionsContainer>
          </>
        )}
        <NewReportModal
          name={selectedReport?.name}
          description={selectedReport?.description}
          open={showNewReportModal}
          isLoading={isLoadingAttachExpenseToReport || isLoadingCreateReport || isLoadingAttachAdvanceToReport}
          actionType={'create'}
          onCancelClick={() => setShowNewReportModal(false)}
          onActionClick={handleReportCreation}
        />
        <ModalReportSelection
          open={showReportSelectionModal}
          selectedReportId={selectedReport?.id}
          actionIsLoading={isLoadingAttachExpenseToReport || isLoadingAttachAdvanceToReport}
          onCancelClick={() => setShowReportSelectionModal(false)}
          onCreateReport={() => setShowNewReportModal(true)}
          onActionClick={handleReportSelection}
          onSelectReport={data => {
            setSelectedReport(data);
            onSelectReportClick(data);
          }}
        />
      </SC.Header>
      {children && <SC.Content>{children}</SC.Content>}
    </SC.Container>
  );
};
