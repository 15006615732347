import { useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { useAllSubsidiaries } from '@containers/Subsidiaries/hooks/rest/useSubsidiaries';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { SubsidiariesGrid } from '../SubsidiariesGrid/SubsidiariesGrid';
import * as SC from './styled';

export function SubsidiariesPreview() {
  const t = useTranslate('subsidiaries.subsidiariesPreview');
  const [searchName, setSearchName] = useState<string>('');
  const [page, setPage] = useState(1);
  const { subsidiaries, isLoading } = useAllSubsidiaries({
    page,
    searchName,
  });

  return (
    <SC.Container>
      <SC.ContainerCounter>
        <SC.CounterLabel variant="headline8">{t('subsidiariesCounter.label')}</SC.CounterLabel>
        <StatusTag variant="neutral">
          {t(`subsidiariesCounter.items.${subsidiaries.length === 1 ? 'singular' : 'plural'}`, {
            items: subsidiaries.length,
          })}
        </StatusTag>
      </SC.ContainerCounter>
      <SubsidiariesGrid
        subsidiaries={subsidiaries}
        total={subsidiaries.length}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        setSearchName={setSearchName}
        searchName={searchName}
      />
    </SC.Container>
  );
}
