import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ExpenseTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 ${props => props.theme.spacings.xs4};
`;

export const ExpenseMidContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs};
  padding: ${props => props.theme.spacings.m} 0;
`;

export const ExpenseInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
`;

export const OccurrencesContainer = styled.div`
  width: 760px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs};
  align-items: center;
`;
