import { EExpenseType } from '@containers/Expenses/context/types';
import { EReportStatus, IReport } from '@containers/Reports/context/types';
import {
  expensesAccountingBaseURL,
  expensesAdvancesBaseURL,
  expensesApprovalsBaseURL,
  expensesBaseUrl,
} from '@shared/constant';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';
import { useCheckIsKmType } from '@shared/hooks/useCheckIsKmType';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { usePlatformPermissions } from './usePlatformPermissions';

export interface IExpensesAllowedActions {
  view?: boolean;
  edit?: boolean;
  unlink?: boolean;
  link?: boolean;
  delete?: boolean;
}

export interface IUseExpensesPermissions {
  getExpenseActions(_expenseType: EExpenseType, _statusReport: IReport['status']): IExpensesAllowedActions;
  getMultiExpenseActions(_expenseType: EExpenseType, _statusReport: IReport['status']): IExpensesAllowedActions;
}

export function useExpensesPermissions(): IUseExpensesPermissions {
  const getCurrentUrlBase = useCurrentUrlBase();
  const expenseType: EExpenseType = useCheckExpenseType();
  const { checkIsKmType } = useCheckIsKmType(expenseType);
  const { checkPlatformPermission } = usePlatformPermissions();
  const { checkLicenseFeature } = useLicenseFeatures();

  function getExpenseActions(_expenseType: EExpenseType, _statusReport?: IReport['status']): IExpensesAllowedActions {
    let allowedActions: IExpensesAllowedActions = {
      delete: false,
      unlink: false,
      link: false,
      edit: false,
      view: checkPlatformPermission(EAuthZPermission.EXPENSES_REPORTS_VIEW),
    };

    switch (getCurrentUrlBase()) {
      case expensesAccountingBaseURL:
        allowedActions = {
          ...allowedActions,
          unlink: checkPlatformPermission(EAuthZPermission.REPORT_REFUND_ACTION),
        };
        break;

      case expensesApprovalsBaseURL:
        allowedActions = {
          ...allowedActions,
          unlink: checkPlatformPermission(EAuthZPermission.REPORT_APPROVE_ACTION),
        };
        break;

      case expensesBaseUrl:
      case expensesAdvancesBaseURL:
        const expenseIsKm: boolean = checkIsKmType(_expenseType);
        const reportAllowed: boolean = _statusReport === EReportStatus.OPEN || !_statusReport;
        const expenseIsEdit: boolean =
          checkPlatformPermission(EAuthZPermission.EXPENSES_REPORTS_ACTION) && reportAllowed;
        const expenseKmIsEdit: boolean =
          checkPlatformPermission(EAuthZPermission.EXPENSES_MILEAGE_ACTION) &&
          reportAllowed &&
          checkLicenseFeature('BASIC_MILEAGE');

        if ((expenseIsKm && expenseKmIsEdit) || expenseIsEdit) {
          const _allowedActions: boolean = expenseIsKm ? expenseKmIsEdit : expenseIsEdit;

          allowedActions = {
            ...allowedActions,
            delete: _allowedActions,
            unlink: _allowedActions,
            link: _allowedActions,
            edit: _allowedActions,
          };
        }
        break;
    }

    return allowedActions;
  }

  function getMultiExpenseActions(
    _expenseType: EExpenseType,
    _statusReport: IReport['status'],
  ): IExpensesAllowedActions {
    return getExpenseActions(_expenseType, _statusReport);
  }

  return {
    getExpenseActions,
    getMultiExpenseActions,
  };
}
