import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { BreadcrumbPage } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { clientsBaseURL } from '@shared/constant';

import * as SC from './styled';

interface ClientRegisterProgressProps {
  step: number;
}

export const ClientRegisterProgressHeader = ({ step = 0 }: ClientRegisterProgressProps) => {
  const { t } = useTranslation();

  return (
    <SC.Header>
      <BreadcrumbPage
        items={[
          { label: t('clients.registerClient.breadcrumbs.manageCompany'), to: '/settings' },
          { label: t('clients.registerClient.breadcrumbs.clients'), to: `${clientsBaseURL}` },
          {
            label: t('clients.registerClient.breadcrumbs.registerClient'),
            to: '',
          },
        ]}
      />
      <Stepper
        activeStep={step}
        steps={[t('clients.registerClient.steps.header.generalData'), t('clients.registerClient.steps.header.users')]}
      />
    </SC.Header>
  );
};
