import { useEffect, useState } from 'react';
import { DateRange } from '@atoms/FilterFactory';
import { IUserSelection } from '@containers/Expenses/context/types/users';
import {
  EReportDateFilterOption,
  EReportStatus,
  IReportCreationDate,
  IReportSummariesFilter,
} from '@containers/Reports/context/types';
import { IFilterValues } from '@molecules/FiltersDrawer';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';
import { useDebounce } from 'use-debounce';

interface FiltersEvents {
  createdBy: (value: IUserSelection['id']) => void;
  periodDate: (dateRange: DateRange) => void;
  search: (value: string) => void;
  status: (value: EReportStatus[]) => void;
  awaitingImmediateApproverDecision: (value: string[]) => void;
}

interface UseManagerFiltersReport {
  events: FiltersEvents;
  applyAllFilters(_filterValues: IFilterValues[]): void;
  onClearAllFilters(): void;
}

interface UseManagerFiltersReportProps {
  onChange?(filters: IReportSummariesFilter): void;
  selectedFilters: IReportSummariesFilter;
}

export function useManagerFiltersReport({
  onChange,
  selectedFilters,
}: UseManagerFiltersReportProps): UseManagerFiltersReport {
  const { getFormatDateUTC } = useDateFormatter();

  const [inputFilterSearchChange, setInputFilterChange] = useState('');
  const [inputValueSearch] = useDebounce(inputFilterSearchChange, 250);

  function serializeDateRanges(dateRange: DateRange): IReportCreationDate {
    return {
      startDate: dateRange.from ? getFormatDateUTC(dateRange.from) : null,
      endDate: dateRange.to ? getFormatDateUTC(dateRange.to) : null,
    };
  }

  function onChangeFilters(filters: IReportSummariesFilter) {
    onChange?.(filters);
  }

  function applyAllFilters(_filterValues: IFilterValues[]) {
    const allFiltersSelected: IReportSummariesFilter = {};

    _filterValues.forEach(_filter => {
      allFiltersSelected[_filter.key] = _filter.value;
    });

    onChange(allFiltersSelected);
  }

  function onClearAllFilters() {
    onChange({ status: [EReportStatus.APPROVED] });
  }

  function setFilterDate(key: string, dateRange: DateRange, periodType: EReportDateFilterOption) {
    let newValue = { ...selectedFilters };

    if (dateRange.from) {
      newValue = { ...newValue, ...serializeDateRanges(dateRange) };
      newValue.periodType = periodType;
    } else {
      newValue.startDate && delete newValue.startDate;
      newValue.endDate && delete newValue.endDate;
      newValue.periodType && delete newValue.periodType;
    }

    return newValue;
  }

  const events = {
    createdBy: (value: IUserSelection['id']) => {
      const newValue = { ...selectedFilters, requesterId: value?.[0] };
      onChangeFilters(newValue);
    },
    periodDate: (dateRange: DateRange) => {
      onChangeFilters(setFilterDate('periodDate', dateRange, EReportDateFilterOption.PERIOD));
    },
    search: (value: string) => {
      if (value.length >= 3 || value.length == 0) {
        setInputFilterChange(value);
      }
    },
    awaitingImmediateApproverDecision: (value: string[]) => {
      const newValue = { ...selectedFilters, awaitingImmediateApproverDecision: value[0] === 'true' ? true : false };
      onChangeFilters(newValue);
    },
    status: (value: EReportStatus[]) => {
      const newValue = { ...selectedFilters, status: value?.length ? value : [EReportStatus.APPROVED] };
      onChangeFilters(newValue);
    },
  };

  useEffect(() => {
    const _filters = { ...selectedFilters, search: inputValueSearch };
    onChangeFilters(_filters);
  }, [inputValueSearch]);

  return {
    events: events,
    applyAllFilters,
    onClearAllFilters,
  };
}
