import { useContext, useEffect } from 'react';
import { useTranslation } from '@locale/Translator';
import { projectsBaseURL } from '@shared/constant';
import { ImportingContext } from '@shared/contexts/BulkImportContext';
import { EImportingActions } from '@shared/contexts/BulkImportContext/actions';
import { showToast } from '@shared/helpers/toast';
import { useUploadFile } from '@shared/hooks/service/useUploadFile';
import { useNavigate } from 'react-router-dom';

export const useProjectFileRegister = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { upload, data: uploadData, isLoading: isLoadingUpload, error } = useUploadFile('project/upload', true);

  const onError = () => showToast({ message: t('molecules.projectToast.uploadErrorMessage'), type: 'error' });
  const { dispatch } = useContext(ImportingContext);

  async function importProjects(data) {
    try {
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO_IS_LOADING,
        payload: {
          importingResponseIsLoading: true,
        },
      });
      dispatch({
        type: EImportingActions.HAS_TRIED_TO_IMPORT,
        payload: {
          hasTriedToImport: true,
        },
      });
      await upload(data.file, onError);
      navigate(projectsBaseURL);
    } catch {
      showToast({ message: t('molecules.projectToast.uploadErrorMessage'), type: 'error' });
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO_IS_LOADING,
        payload: {
          importingResponseIsLoading: false,
        },
      });
    }
  }

  useEffect(() => {
    if (uploadData) {
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO,
        payload: {
          ...uploadData,
        },
      });
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO_IS_LOADING,
        payload: {
          importingResponseIsLoading: false,
        },
      });
    }
  }, [uploadData]);

  return {
    importProjects,
    error,
    isLoading: isLoadingUpload,
  };
};
