import { AddProjectsButton } from '@containers/Projects/components/organisms/AddProjectsButton/AddProjectsButton';
import { ProjectsPreview } from '@containers/Projects/components/organisms/ProjectsPreview/ProjectsPreview';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function ProjectsList() {
  const t = useTranslate('projects.page');

  return (
    <BasePage
      headerPage={{
        title: t('title'),
        caption: t('caption'),
        actions: <AddProjectsButton />,
        breadcrumbItems: [
          {
            to: '/settings',
            label: t('breadcrumb.manageCompany'),
          },
          {
            to: '',
            label: t('title'),
          },
        ],
      }}>
      <ProjectsPreview />
    </BasePage>
  );
}
