import { ShapeIcon } from '@atoms/ShapeIcon';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';

import { ColorVariant } from 'src/themes/types/Variants';

import { CardFilterSkeleton } from './CardFilterSkeleton';
import * as SC from './styled';

interface IProps {
  isMain?: boolean;
  isLoading?: boolean;
  showQuantityItems?: boolean;
  variant: ColorVariant;
  quantityItems?: number;
  totalValue?: number;
  isSelected: boolean;
  title: string;
  onSelectedStatusClick(): void;
}

export const CardFilter = ({
  isMain,
  isLoading,
  variant,
  showQuantityItems = true,
  quantityItems = 0,
  totalValue = 0,
  isSelected = true,
  title,
  onSelectedStatusClick,
}: IProps) => {
  const { t } = useTranslation();
  const { getValueWithCurrency } = useValueFormatter();

  if (isLoading) {
    return <CardFilterSkeleton main={isMain} />;
  }

  return (
    <SC.Container main={isMain} isSelected={isSelected}>
      <SC.StatusDescriptionContainer isSelected={isSelected}>
        <SC.ReportsStatusTitle>
          <SC.ReportsStatusText variant="body4">
            {showQuantityItems ? `${quantityItems} ${title}` : title}
          </SC.ReportsStatusText>
        </SC.ReportsStatusTitle>
        <SC.IconFolderContainer isSelected={isSelected}>
          <ShapeIcon size="xsmall" variant={variant} icon="IconFolder" />
        </SC.IconFolderContainer>
      </SC.StatusDescriptionContainer>
      <SC.ReportsValueText variant="headline8" isSelected={isSelected}>
        {getValueWithCurrency({ value: totalValue || 0, currencyPrefix: 'R$' })}
      </SC.ReportsValueText>
      <SC.ToViewLink
        aria-label={`${isSelected} ? t("molecules.reportStatusCard.removeFilter") : t("molecules.reportStatusCard.filter")}`}
        variant="default"
        style={{ fontSize: '12px' }}
        onClick={() => onSelectedStatusClick()}>
        {isSelected ? (
          <>
            {t(`molecules.reportStatusCard.removeFilter`)}
            <Icons size={12} name="IconFilterOff" fill="transparent" />
          </>
        ) : (
          <>
            {t(`molecules.reportStatusCard.filter`)}
            <Icons size={12} name="IconFilter" fill="transparent" />
          </>
        )}
      </SC.ToViewLink>
    </SC.Container>
  );
};
