import { SettingHeader } from '@containers/Settings/components/molecules/SettingHeader';
import { SetupIntroduction } from '@containers/Settings/components/molecules/SetupIntroduction';
import { useTranslation } from '@locale/Translator';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useNavigate } from 'react-router-dom';

import { Container } from './styled';

interface SettingModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const SettingModal = ({ isOpen, onClose }: SettingModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DefaultModal
      className="setting-modal"
      headerCustom={
        <SettingHeader
          title={t('pages.setupOnboarding.title')}
          steps={[
            t('pages.setupOnboarding.steps.categories'),
            t('pages.setupOnboarding.steps.limits'),
            t('pages.setupOnboarding.steps.communication'),
          ]}
          activeStep={-1}
        />
      }
      open={isOpen}
      onCloseClick={onClose}>
      <Container>
        <SetupIntroduction
          userName="Patrick"
          onClickConfigureNow={() => navigate('/expenseManagement/setup')}
          onClickSkipConfigure={onClose}
        />
      </Container>
    </DefaultModal>
  );
};
