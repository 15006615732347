import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import { IConfigurationReportSetting } from '@containers/Customizations/context/types/settings/expenseReportSettingsTypes';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ReportSettingsCode } from './ReportSettingsCode/ReportSettingsCode';
import { ReportSettingsExpenseOnly } from './ReportSettingsExpenseOnly/ReportSettingsExpenseOnly';
import { ReportSettingsExpirationDate } from './ReportSettingsExpirationDate/ReportSettingsExpirationDate';
import * as SC from './styled';

interface IProps {
  reportsSettings: IConfigurationReportSetting;
  isLoading: boolean;
  updateIsLoading: boolean;
  handleChange(settingKey: string, value: unknown, currentReportSetting: IConfigurationReportSetting): void;
}

export function GeneralReportSettings({ reportsSettings, isLoading, updateIsLoading, handleChange }: IProps) {
  const t = useTranslate('organisms.generalReportSettings');

  function _handleChange(key: string, value: unknown) {
    const currentReportsSettings: IConfigurationReportSetting = { ...reportsSettings };
    currentReportsSettings[key] = value;
    handleChange(key, value, currentReportsSettings);
  }

  return (
    <SectionContainer title={t('title')} caption={t('caption')}>
      <SC.Container>
        <PermissionsChecker licenseFeature="INTERMEDIATE_REPORT">
          <SC.Container>
            <PermissionsChecker licenseFeature="BASIC_REPORTS">
              <ToggleSettings
                toggleKey={'allowSameUserToApproveAdvanceAndReport'}
                checked={reportsSettings?.allowSameUserToApproveAdvanceAndReport}
                onChange={_handleChange}
                label={t('allowSameUserToApproveAdvanceAndReport')}
                isLoading={isLoading}
              />
            </PermissionsChecker>
            <PermissionsChecker licenseFeature="INTERMEDIATE_REPORT">
              <ReportSettingsExpirationDate
                expirationDate={reportsSettings?.expirationDate}
                isLoading={isLoading}
                updateIsLoading={updateIsLoading}
                handleChange={_handleChange}
                tooltipText={t('tooltip.addDateToggle')}
              />
            </PermissionsChecker>
            <ReportSettingsExpenseOnly
              expenseOnly={reportsSettings?.allowReimbursementToContainReimbursementExpenseOnly}
              isLoading={isLoading}
              handleChange={_handleChange}
              tooltipText={t('tooltip.allowReportsOfTheSameToggle')}
            />
          </SC.Container>
        </PermissionsChecker>
        <SC.Divider />
        <PermissionsChecker licenseFeature="BASIC_REPORTS">
          <ReportSettingsCode
            prefixValue={reportsSettings?.prefixValue}
            startValue={reportsSettings?.startValue}
            isLoading={isLoading}
            handleChange={_handleChange}
          />
        </PermissionsChecker>
      </SC.Container>
    </SectionContainer>
  );
}
