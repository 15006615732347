import styled from 'styled-components';

export const Backdrop = styled.div`
  background-color: ${props => props.theme.colors.neutral[60]};
  opacity: 0.8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
`;

export const DrawerContainer = styled.div`
  position: absolute;
  z-index: 10000;
  overflow: hidden;
  height: 1px;
`;

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
  width: 100%;
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacings.xs} ${({ theme }) => theme.spacings.m};
  border-bottom: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: 0 ${({ theme }) => theme.spacings.m};
  overflow-y: auto;
`;

export const ApproversContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.borders.width.xs2};
`;

export const TagContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.borders.width.xs2};
  width: 100%;
  max-width: 220px;
`;

export const Image = styled.img``;
