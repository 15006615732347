import { useState } from 'react';
import { useTranslation } from '@locale/Translator';

import { ExpenseTable } from '../ExpenseTable/ExpenseTable';
import * as SC from './styled';

enum ETabExpenseActive {
  PENDING_EXPENSES,
  EXPENSE,
}

export const ExpensesPreview = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({ hasPending: true });

  const onTabChange = (currentTab: ETabExpenseActive) => {
    setFilters({ ...filters, hasPending: currentTab == ETabExpenseActive.PENDING_EXPENSES });
  };

  return (
    <SC.ExpensesPreviewContainer>
      <SC.TabGrid
        onTabChanged={currentTab => onTabChange(currentTab)}
        tabItens={[
          {
            component: <ExpenseTable filters={filters} />,
            label: t('organisms.expenseGrid.pendingExpenses'),
          },
          {
            component: <ExpenseTable filters={filters} />,
            label: t('organisms.expenseGrid.expenses'),
          },
        ]}
      />
    </SC.ExpensesPreviewContainer>
  );
};
