import React, { ReactNode, useMemo } from 'react';
import {
  CorporateCardContractInformation,
  SAASContractInformation,
  TravelContractInformation,
} from '@containers/Subscription/context/types/contractInformation';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { CorporateCardCommercialConditions } from './components/CorporateCardCommercialConditions';
import { ERPIntegrationConditions } from './components/ERPIntegrationConditions';
import { SAASCommercialConditions } from './components/SAASCommercialConditions';
import { TravelCommercialConditions } from './components/TravelCommercialConditions';
import { useERPIntegrationConditions } from './hooks/useERPIntegrationConditions';
import { InformationsContainer, Separator, Title } from './styled';

type Props = {
  sassContract?: SAASContractInformation;
  corporateCardContract?: CorporateCardContractInformation;
  travelContract?: TravelContractInformation;
};

export function SubscriptionDetails({ corporateCardContract, sassContract, travelContract }: Props) {
  const t = useTranslate();

  const { showERPIntegration } = useERPIntegrationConditions(sassContract);

  const conditions = useMemo(() => {
    const elements: ReactNode[] = [];

    if (travelContract) {
      elements.push(<TravelCommercialConditions travelContract={travelContract} />);
    }

    if (sassContract) {
      elements.push(<SAASCommercialConditions saasContract={sassContract} />);
    }

    if (corporateCardContract) {
      elements.push(<CorporateCardCommercialConditions corporateCardContract={corporateCardContract} />);
    }

    if (showERPIntegration) {
      elements.push(<ERPIntegrationConditions saasContract={sassContract} />);
    }

    return elements;
  }, [travelContract, sassContract, corporateCardContract, showERPIntegration]);

  return (
    <div>
      <Title>{t('organisms.subscriptionDetails.title')}</Title>
      <InformationsContainer>
        {conditions.map((condition, index) => (
          <React.Fragment key={index}>
            {condition}

            {index !== conditions.length - 1 && <Separator />}
          </React.Fragment>
        ))}
      </InformationsContainer>
    </div>
  );
}
