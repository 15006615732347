import { ColorVariant } from 'src/themes/types/Variants';

import { EReportStatus } from '../context/types/report';

export interface UseStatusColorVariantProps {
  status: EReportStatus;
}

export const getReportStatusColorVariant = ({ status }: UseStatusColorVariantProps): ColorVariant => {
  if (
    status == EReportStatus.AWAITING_FINANCIAL_PROCESSING ||
    status == EReportStatus.APPROVED ||
    status == EReportStatus.PROCESSING_PAYMENT
  ) {
    return 'info';
  }
  if (status == EReportStatus.REPROVED || status == EReportStatus.PROCESSING_PAYMENT_ERROR) {
    return 'error';
  }
  if (status == EReportStatus.REIMBURSED) {
    return 'success';
  }
  if (status == EReportStatus.OPEN) {
    return 'secondary';
  }

  return 'neutral';
};
