import { Skeleton } from '@mui/material';
import { attachExpenseModalNumber } from '@shared/constant';

import * as SC from './styled';

interface AttachAdvanceModalSkeletonProps {
  advanceNumber?: number;
}

export const AttachAdvanceModalSkeleton = ({
  advanceNumber = attachExpenseModalNumber,
}: AttachAdvanceModalSkeletonProps) => {
  return (
    <SC.Container>
      <SC.AdvancesContainer>
        {[...Array(advanceNumber)].map((item, index) => (
          <SC.AdvanceItem key={index}>
            <Skeleton variant="text" height={48} width={25} />
            <SC.AdvanceNameContainer>
              <Skeleton variant="circular" height={48} width={48} />
              <Skeleton variant="text" height={48} width={150} />
            </SC.AdvanceNameContainer>
            <Skeleton variant="text" height={48} width={90} />
            <Skeleton variant="text" height={48} width={90} />
          </SC.AdvanceItem>
        ))}
      </SC.AdvancesContainer>
    </SC.Container>
  );
};
