import { FinancialAdvancesPreview } from '@containers/Financial/components/organisms/Advances/FinancialAdvancesPreview/FinancialAdvancesPreview';
import { external } from '@flash-hros/corporate-card';
import { useTranslation } from '@locale/Translator';
import { BasePage } from '@organisms/BasePage/BasePage';
import { AccountingContextProvider } from '@shared/contexts/AccountingContext/AccountingContext';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';

export const FinancialAdvances = () => {
  const { t } = useTranslation();
  useTrackingScreen('financial_reports_advance_screen');

  return (
    <external.ExternalRoot>
      <BasePage
        headerPage={{
          title: t('pages.financialAdvances.title'),
        }}>
        <AccountingContextProvider>
          <FinancialAdvancesPreview />
        </AccountingContextProvider>
      </BasePage>
    </external.ExternalRoot>
  );
};
