import { useMemo } from 'react';
import { DataType } from '@flash-tecnologia/hros-web-ui-v2/dist/components/TransferList/types';

import { IUser } from '../context/types/clients';

interface useTransferListProps {
  data: IUser[];
  ids: string[];
  isRightList?: boolean;
}

export const useTransferListSet = ({ data, ids, isRightList = false }: useTransferListProps): DataType => {
  return useMemo(() => {
    let list = data;
    if (isRightList) {
      list = list.filter(item => ids?.includes(item.id.toString()));
    }
    return list.map(item => {
      return {
        name: item.name,
        _id: item.id.toString(),
        checked: false,
        hidden: isRightList ? false : ids?.includes(item.id),
      };
    });
  }, [data]);
};
