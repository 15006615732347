import { useMileageByPolicyId } from './useMilageByPolicyId';
import { useMileageSettingsMutate } from './useMileageSettingsMutate';

type Props = {
  policyId: number;
};

export function useDeleteMileageRate({ policyId }: Props) {
  const { mileagePolicy, refetch } = useMileageByPolicyId({ id: policyId });
  const { mileageMutate: updateMileage, isLoading } = useMileageSettingsMutate();

  async function deleteMileageRate(mileageRateId: number) {
    const newMileageRates = mileagePolicy.policyMileageRatesSettings
      .filter(rate => rate.id !== mileageRateId)
      .map(({ startDate, rate, id }) => ({
        id,
        startDate,
        rate,
      }));

    const { policyId, ...mileageSettings } = mileagePolicy;

    delete mileageSettings.id;

    const deleteMileage = await updateMileage({
      variables: {
        policyId,
        policyMileageId: mileagePolicy.id,
        policyMileageSettingsInput: {
          ...mileageSettings,
          policyMileageRatesSettings: newMileageRates,
        },
      },
    });

    await refetch();

    return deleteMileage;
  }

  return {
    deleteMileageRate,
    isLoading,
  };
}
