import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs};
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacings.xs};
`;
