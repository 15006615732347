import { useEffect, useState } from 'react';
import { Loader } from '@atoms/Loader';
import { AssignUsers } from '@containers/Subsidiaries/components/organisms/AssignUsers';
import { SubsidiaryRegisterForm } from '@containers/Subsidiaries/components/organisms/SubsidiaryRegisterForm';
import { useSaveSubsidiary } from '@containers/Subsidiaries/hooks/rest/useSaveSubsidiary';
import { useSubsidiaryById } from '@containers/Subsidiaries/hooks/rest/useSubsidiaryById';
import { SubsidiaryFormData, subsidiaryFormSchema } from '@containers/Subsidiaries/validators/subsidiaryFormSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { BasePage } from '@organisms/BasePage/BasePage';
import { subsidiariesBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import * as SC from './styled';
import { SubsidiaryRegisterFooterPage } from './SubsidiaryRegisterFooterPage/SubsidiaryRegisterFooterPage';
import { SubsidiaryRegisterHeader } from './SubsidiaryRegisterHeader';

export const SubsidiaryRegister = () => {
  const { id } = useParams();
  const [subsidiaryId, setSubsidiaryId] = useState(id);
  const isEditing = !!subsidiaryId;
  const t = useTranslate('organisms.subsidiaryRegister');
  const { subsidiary, isLoading: isLoadingSubsidiary } = useSubsidiaryById(id);
  const { handleSave, data: updatedSubsidiary, isLoading: isSaving } = useSaveSubsidiary();
  const navigate = useNavigate();
  const subsidiaryForm = useForm<SubsidiaryFormData>({
    resolver: yupResolver(subsidiaryFormSchema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      registerNumber: '',
      active: true,
      costCenters: [],
      userIds: [],
    },
  });
  const [step, setStep] = useState(0);

  useEffect(() => {
    updatedSubsidiary && setSubsidiaryId(updatedSubsidiary.id);
  }, [isSaving]);

  async function onSubmit(data: SubsidiaryFormData) {
    if (step === 0) {
      setStep(1);
    } else {
      const _subsidiary = { ...data };

      try {
        await handleSave(_subsidiary, subsidiary?.id);
        return navigate(subsidiariesBaseURL, { replace: true });
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (isLoadingSubsidiary || !isEditing) return;

    if (subsidiary) {
      subsidiaryForm.reset({ ...subsidiary, costCenters: subsidiary.costCenters.map(cost => String(cost.id)) });
    }
  }, [subsidiary, isLoadingSubsidiary, isEditing]);

  return (
    <>
      <SubsidiaryRegisterHeader isEditing={isEditing} step={step} />
      <FormProvider {...subsidiaryForm}>
        <form onSubmit={subsidiaryForm.handleSubmit(onSubmit)}>
          <BasePage
            footerPage={<SubsidiaryRegisterFooterPage isLoading={isSaving} step={step} setStep={setStep} />}
            headerPage={{
              title: !isEditing ? t(`manualAddSubsidiary`) : t('editSubsidiary'),
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={3} sx={{ mb: 3 }}>
                <SC.SectionTitle variant="headline7">{t(`pageContent.${step}.title`)}</SC.SectionTitle>
                <SC.Instruction variant="body3">{t(`pageContent.${step}.subTitle`)}</SC.Instruction>
              </Grid>
              <Grid item xs={12} md={12} lg={9} xl={8}>
                {step === 0 ? (
                  <SubsidiaryRegisterForm isLoading={isLoadingSubsidiary} />
                ) : (
                  <SC.ContainerUsers>
                    <AssignUsers
                      userIds={subsidiary?.userIds?.map(userId => userId) || []}
                      isLoading={isLoadingSubsidiary}
                    />
                  </SC.ContainerUsers>
                )}
              </Grid>
            </Grid>
            <Loader show={isSaving} />
          </BasePage>
        </form>
      </FormProvider>
    </>
  );
};
