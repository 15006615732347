import { gql } from '@apollo/client';

export const getAdvanceFormFields = gql`
  query GetAdvanceFormFields {
    getAdvanceFormFields {
      paymentType {
        active
        required
        allowsEditing
        fieldOrder
      }
      client {
        active
        required
        allowsEditing
        fieldOrder
      }
      project {
        active
        required
        allowsEditing
        fieldOrder
      }
      costCenter {
        active
        required
        allowsEditing
        fieldOrder
      }
      report {
        active
        required
        allowsEditing
        fieldOrder
      }
      approver {
        active
        required
        allowsEditing
        fieldOrder
      }
    }
  }
`;

export const createAdvance = gql`
  mutation CreateAdvance($advance: AdvanceInput!) {
    createAdvance(advance: $advance) {
      id
    }
  }
`;

export const updateAdvanceById = gql`
  mutation UpdateAdvanceById($advance: AdvanceInput!, $advanceId: Int!) {
    updateAdvanceById(advance: $advance, advanceId: $advanceId) {
      id
    }
  }
`;
