import { BreadcrumbPage } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { projectsBaseURL } from '@shared/constant';
import { useTranslation } from 'react-i18next';

type Props = {
  isEditing: boolean;
};

export default function ProjectRegisterBreadcrumb({ isEditing }: Props) {
  const { t } = useTranslation();

  return (
    <BreadcrumbPage
      items={[
        {
          to: '/settings',
          label: t('pages.projectRegister.breadcrumb.manageCompany'),
        },
        {
          to: projectsBaseURL,
          label: t('pages.projectRegister.breadcrumb.project'),
        },
        {
          to: '',
          label: !isEditing ? t('pages.projectRegister.manualAddProject.0') : t('pages.projectRegister.editProject'),
        },
      ]}
    />
  );
}
