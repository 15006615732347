import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface MainsContainerProps {
  hasReport: boolean;
}

export const Header = styled.div<MainsContainerProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacings.xs} ${props => props.theme.spacings.m};
  background-color: ${props => props.theme.colors.secondary[99]};
`;

export const DescriptionContainer = styled.div`
  width: fit-content;
  display: flex;
  grid-template-columns: 50px 1fr;
  gap: ${props => props.theme.spacings.xs2};
  align-items: center;

  .linkButton-custom-theme {
    align-self: unset !important;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs2};
`;

export const Description = styled(Typography).attrs({
  variant: `body3`,
})`
  color: ${props => props.theme.colors.neutral[20]};
  font-weight: 600 !important;
  width: fit-content;
  white-space: nowrap;
  width: 335px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  padding: ${props => props.theme.spacings.m} ${props => props.theme.spacings.m};
`;

export const Container = styled.div<{ fullWidth: boolean }>(({ theme, fullWidth }) => ({
  marginBottom: theme.spacings.s,
  maxWidth: fullWidth ? 'fit-content' : 'auto',
  overflow: 'hidden',
  borderRadius: theme.borders.radius.s,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
}));
