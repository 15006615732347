import { useContext } from 'react';
import { ensureStartPath, fetcher } from '@services/utils/fetcherClient';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { showToast } from '@shared/helpers/toast';
import useSWR from 'swr';

import { useHeaderDelegateOptions } from './useHeaderDelegateOptions';

/**
 * to use fetch using swr
 * @param mainPath path principal for a url
 * @param segmentPath optional when necessary
 * @param parameters sended in query string
 * @param onError custom error treat, by default showToast default error.
 * @returns returns result of service call
 */
export const useFetch = <T>(
  mainPath: string,
  segmentPath?: string,
  parameters?,
  config?: Partial<FetchConfig>,
  onError?,
  shouldFetch: boolean = true,
  options?: RequestInit,
) => {
  const { companyId, accessToken } = useContext(SessionContext);
  const defaultOnError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });
  const params = new URLSearchParams(parameters);
  const { headers } = useHeaderDelegateOptions(options?.headers);
  const { data, error, isLoading, isValidating, mutate } = useSWR<T>(
    shouldFetch ? mainPath : null,
    _ =>
      fetcher(
        `/${_}${segmentPath ? ensureStartPath(segmentPath) : ''}${parameters ? '?' + params.toString() : ''}`,
        {
          companyId,
          accessToken,
        },
        { ...options, headers },
      ),
    {
      revalidateOnFocus: false,
      onError: onError ?? defaultOnError,
      errorRetryCount: 0,
      ...config,
    },
  );

  return {
    data,
    isLoading: isLoading || isValidating,
    error,
    mutate,
  };
};

export interface FetchConfig {
  /**
   *  error retry interval in milliseconds
   *  @defaultValue 5000
   */
  errorRetryInterval: number;
  errorRetryCount?: number;
  /**
   * timeout to trigger the onLoadingSlow event in milliseconds
   * @defaultValue 3000
   */
  loadingTimeout: number;
  /**
   * only revalidate once during a time span in milliseconds
   * @defaultValue 5000
   */
  dedupingInterval: number;
  /**
   * automatically revalidate when the browser regains a network connection (via `navigator.onLine`)
   * @defaultValue true
   * @link https://swr.vercel.app/docs/revalidation
   */
  revalidateOnReconnect: boolean;
  /**
   * retry when fetcher has an error
   * @defaultValue true
   */
  shouldRetryOnError: boolean | ((err: Error) => boolean);
  /**
   * keep the previous result when key is changed but data is not ready
   * @defaultValue false
   */
  keepPreviousData?: boolean;
}
