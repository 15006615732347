import { ILimitGrid } from '@containers/Customizations/context/types/limits';
import { Menu } from '@flash-tecnologia/hros-web-ui-v2';
import { DropdownItem } from '@molecules/DropdownItem';
import { useTranslation } from 'react-i18next';

import * as SC from '../styled';

export interface Action {
  children: React.ReactNode;
  onClick(): void;
}

export enum ActionOptions {
  EDIT = 'edit',
  DISABLE_ENABLE = 'disableEnable',
  DELETE = 'delete',
}

export enum IconAction {
  EDIT = 'IconPencil',
  DISABLE_ENABLE = 'IconToggleRight',
  DELETE = 'IconTrash',
}

interface LimitsGridActionsProps {
  limit?: ILimitGrid;
  allowedActions: boolean;
  onEditClick(limit: ILimitGrid): void;
  onDisableClick?(id: ILimitGrid['id'][], active: boolean): void;
  onDeleteClick?(id: ILimitGrid['id'][]): void;
}

export function LimitsGridActions({
  limit,
  allowedActions,
  onEditClick,
  onDisableClick,
  onDeleteClick,
}: LimitsGridActionsProps) {
  const { t } = useTranslation();

  const events = {
    edit: () => onEditClick?.(limit),
    disableEnable: () => onDisableClick?.([limit.limitPolicyId], !limit.active),
    delete: () => onDeleteClick?.([limit.limitPolicyId]),
  };

  function getOptions(): Action[] {
    const options: Action[] = [];

    if (allowedActions) {
      Object.keys(ActionOptions).forEach((action): void => {
        let actionLabel: string;
        if (ActionOptions[action] == ActionOptions.DISABLE_ENABLE) {
          actionLabel = limit.active ? 'disable' : 'enable';
        } else {
          actionLabel = ActionOptions[action];
        }

        ('notAllowedActions');
        options.push({
          children: (
            <DropdownItem
              icon={IconAction[action]}
              description={t(`organisms.limitGrid.actionsOptions.${actionLabel}`)}
            />
          ),
          onClick: events[ActionOptions[action]],
        });
      });
    } else {
      options.push({
        children: <DropdownItem description={t(`organisms.limitGrid.notAllowedActions`)} />,
        onClick: () => null,
      });
    }

    return options;
  }

  return (
    <Menu
      type="default"
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      options={getOptions()}>
      <SC.IconDots />
    </Menu>
  );
}
