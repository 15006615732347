import { useEffect, useState } from 'react';
import { IReportExpirationDateSettings } from '@containers/Customizations/context/types/settings/expenseReportSettingsTypes';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { SetDueDate } from '../SetDueDate/SetDueDate';

interface IProps {
  open: boolean;
  isLoading: boolean;
  updateIsLoading: boolean;
  expirationDate: IReportExpirationDateSettings;
  onClose(): void;
  onChange(expirationDate: IProps['expirationDate']): void;
}

export function SetDueDateModal({ open, isLoading, updateIsLoading, expirationDate, onChange, onClose }: IProps) {
  const t = useTranslate('organisms.generalReportSettings.expirationDate.configurationModal');
  const [_expirationDate, setExpirationDate] = useState<IReportExpirationDateSettings>();

  function isActionDisabled(): boolean {
    return (
      !Boolean(_expirationDate?.conditionalType) || !_expirationDate?.value || _expirationDate?.value?.length === 0
    );
  }

  useEffect(() => {
    if (!isLoading && expirationDate) {
      setExpirationDate(expirationDate);
    }
  }, [expirationDate]);

  return (
    <DefaultModal
      open={open}
      onCloseClick={onClose}
      onActionClick={() => onChange(_expirationDate)}
      headerTitle={t('title')}
      isActionLoading={updateIsLoading}
      actionDisabled={isActionDisabled()}
      showTooltipButtonAction={isActionDisabled()}
      tooltipButtonAction={t('tooltip.invalidFields')}
      headerSubtitle={t('caption')}
      labelButtonAction={t('actionButton')}>
      <SetDueDate expirationDate={_expirationDate} isLoading={isLoading} onChange={setExpirationDate} />
    </DefaultModal>
  );
}
