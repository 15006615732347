import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const SetupContainer = styled.div`
  display: grid;
  grid-template-columns: auto 402px;
  gap: 136px;
  width: fit-content;
`;

export const SetupBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
`;

export const UserName = styled(Typography)`
  margin-bottom: 8px;
  color: ${props => props.theme.colors.primary};

  &.typography--variant-body3 {
    font-weight: 700;
  }
`;

export const Description = styled(Typography)`
  margin-top: 32px;
  color: ${props => props.theme.colors.neutral.dark.dark5};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 56px;

  button:first-of-type {
    margin: auto 26px auto 0;
  }
`;

export const Image = styled.img`
  border-radius: 0px 64px 0px 0px;
  width: 402px;
  height: 402px;
`;
