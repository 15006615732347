import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  padding: `${theme.spacings.m} 0 ${theme.spacings.xl5} 0`,

  '.vertical-tab-container': {
    '> div:first-child': {
      div: {
        div: {
          div: {
            padding: `${theme.spacings.xs2} ${theme.spacings.xs4}`,
            flexDirection: 'column',
            margin: 'auto',

            '.icon-container': {
              padding: '8px',
              borderRadius: '50%',
              backgroundColor: theme.colors.secondary[90],
              color: theme.colors.primary,
            },
          },
        },
      },
    },
  },
}));

export const MainTitle = styled(Typography)(({ theme }) => ({
  paddingBottom: `${theme.spacings.m}`,
}));

export const TabContainer = styled.div(({ theme }) => ({
  width: 'min-content',
  height: '100%',
  padding: theme.spacings.s,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  borderRadius: theme.borders.radius.m,
}));
