import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: auto;
  padding-bottom: ${props => props.theme.spacings.xs4};
`;

export const ReportCardContainer = styled.div`
  margin-right: ${props => props.theme.spacings.xs};
  :last-child {
    margin-right: 0;
  }
`;
