import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { FormControlLabel as FormControlLabelMui } from '@mui/material';
import styled from 'styled-components';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const SelectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
  max-width: 540px;

  .multi-select-custom .MuiChip-root {
    background-color: ${props => props.theme.colors.tertiary[90]};
  }
`;

export const CurrencyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacings.xs};
`;

export const SettingsTitle = styled(Typography)`
  font-weight: 700;
`;

export const SettingsContainer = styled.div`
  margin-top: ${props => props.theme.spacings.m};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs2};
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs2};
  justify-content: center;
`;
export const SettingsGroup = styled.div`
  align-items: center;
  display: flex;
  gap: ${props => props.theme.spacings.xs2};
`;

export const FormControlLabel = styled(FormControlLabelMui)`
  &.MuiFormControlLabel-root {
    margin-right: 0;
  }

  .MuiFormControlLabel-label {
    font-family: ${props => props.theme.fonts.secondary};
  }
`;
