import { gql } from '@apollo/client';

import { AnalyticsFilterField, EAnalyticsType } from '../types/filters';

export const getAnalyticsFilterFields = gql`
  query GetAnalyticsFilterFields($type: AnalyticsType!) {
    getAnalyticsFilterFields(type: $type) {
      key
      type
    }
  }
`;

export const getAnalyticsFilterFieldOptions = gql`
  query GetAnalyticsFilterFieldOptions($type: AnalyticsType!, $field: AnalyticsFilterFieldKey!, $search: String = "") {
    getAnalyticsFilterFieldOptions(type: $type, field: $field, search: $search) {
      id
      label
    }
  }
`;

export function getFiltersOptions(type: EAnalyticsType, data: AnalyticsFilterField[]) {
  return gql`
    query {${data
      ?.map(
        item => `${item.key}:getAnalyticsFilterFieldOptions(type: ${type}, field: ${item.key}, search: "") {
        id
        label
      }`,
      )
      .join('\n')}}`;
}
