import { useEffect, useState } from 'react';
import { base64ToObjectURL } from '@shared/helpers/files';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';
import { cloneDeep } from 'lodash';

import { getMultipleFileUploadSignerUrls } from '../context/queries/files';
import { IFileResponse, IGetFilesSignedUrlsResponse } from '../context/types/files';

interface UseSignedFileUrl {
  files: IFileResponse[];
  isLoading: boolean;
  error: unknown;
}

export const useSignedFileUrl = (paths: string[], skip?: boolean): UseSignedFileUrl => {
  const { loading, data, error } = useQuery<IGetFilesSignedUrlsResponse>(getMultipleFileUploadSignerUrls, {
    variables: { paths, base64: true },
    skip: skip || !paths?.length,
    fetchPolicy: 'no-cache',
  });

  const [files, setFiles] = useState<IFileResponse[]>([]);

  useEffect(() => {
    if (data?.getFilesUploadSignedUrls?.length > 0) {
      // Removing apollo cache from object
      const fetchedFiles: IFileResponse[] = cloneDeep(data?.getFilesUploadSignedUrls);

      // Generate Object URL to each file
      Promise.allSettled(
        fetchedFiles.map(file => {
          return base64ToObjectURL(file.base64);
        }),
      ).then(response => {
        response.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            fetchedFiles[index].url = result.value;
          }
        });

        setFiles(fetchedFiles);
      });
    }
  }, [data]);

  return {
    files: files || [],
    isLoading: loading,
    error,
  };
};
