import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { mutate } from 'swr';

import { ProjectFormData } from '../../validators/projectFormSchema';

export function useSaveProject() {
  const { t } = useTranslation();

  const { mutation } = useMutate('v1/projects', () =>
    showToast({
      message: t('pages.projectRegister.feedbacks.updated.error'),
      type: 'error',
    }),
  );

  async function updateProject(projectId: string, project: ProjectFormData) {
    const body = { ...project };
    await mutation.trigger(
      {
        path: `${projectId}`,
        options: {
          method: 'PATCH',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
        onSuccess: () =>
          showToast({
            message: t('pages.projectRegister.feedbacks.update.success'),
            type: 'success',
          }),
        onError: error =>
          showToast({
            message: error.message || t('pages.projectRegister.feedbacks.update.error'),
            type: 'error',
          }),
      },
    );

    mutate(key => typeof key === 'string' && key.includes('v1/projects?page='));
  }

  async function createProject(project: ProjectFormData) {
    const body = { ...project };
    await mutation.trigger(
      {
        path: '',
        options: {
          method: 'POST',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
        onSuccess: () =>
          showToast({
            message: t('pages.projectRegister.feedbacks.create.success'),
            type: 'success',
          }),
        onError: error =>
          showToast({
            message: error.message || t('pages.projectRegister.feedbacks.create.error'),
            type: 'error',
          }),
      },
    );
    mutate(key => typeof key === 'string' && key.includes('v1/projects?page='));
  }

  async function handleSave(project: ProjectFormData, projectId?: string) {
    if (projectId) {
      return updateProject(projectId, project);
    }
    createProject(project);
  }

  return {
    handleSave,
    isLoading: mutation.isMutating,
    data: mutation.data,
  };
}
