import { IImportingContextState } from './types';

export enum EImportingActions {
  GET_RESPONSE_INFO = 'GET_RESPONSE_INFO',
  GET_RESPONSE_INFO_IS_LOADING = 'GET_RESPONSE_INFO_IS_LOADING',
  HAS_TRIED_TO_IMPORT = 'HAS_TRIED_TO_IMPORT',
}

export interface IImportingDispatchActions {
  type: EImportingActions;

  // Update this type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Partial<IImportingContextState> | any;
}
