import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { keyframes } from 'styled-components';

interface DotsProps {
  active?: boolean;
}

const itemAnimation = keyframes`
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
  0% {
    opacity: 0%;
  }
`;

export const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BoxContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemsContainer = styled.div`
  padding: ${props => props.theme.spacings.xs};
`;

export const ItemContainer = styled.div`
  overflow: hidden;
  border-radius: ${props => props.theme.borders.radius.l};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${itemAnimation} 1s;
`;

export const DotsContainer = styled.div`
  display: flex;
  width: fit-content;
`;

export const Dots = styled.div<DotsProps>`
  height: 5px;
  width: 20px;
  margin: 0 ${props => props.theme.spacings.xs5};
  border-radius: ${props => props.theme.borders.radius.xl};
  cursor: pointer;
  background-color: ${props => (props.active ? props.theme.colors.neutral[50] : props.theme.colors.neutral[90])};
`;

export const ButtonContainer = styled.button`
  display: flex;
  width: 48px;
  height: 48px;
`;

export const Button = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.neutral[90]}!important;
  &:hover {
    opacity: 0.8;
  }
`;
