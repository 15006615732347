import { SearchField, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Search = styled(SearchField)`
  .MuiFilledInput-root {
    border-radius: ${({ theme }) => theme.borders.radius.pill}!important;
  }
`;

export const SearchSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borders.radius.pill}!important;
`;
