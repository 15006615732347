import React from 'react';
import useTranslate from '@locale/useTranslate';
import { config } from '@shared/config';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { showToast } from '@shared/helpers/toast';

const reports = {
  ACTIVE_USERS: {
    name: 'Usuários ativos',
    endpoint: 'activeUsers',
    body: {},
  },
  FLASH_CASH: {
    name: 'Extrato Flash Cash',
    endpoint: 'flashCash',
    body: {},
  },
  FLASH_CASH_MONTHLY: {
    name: 'Extrato Flash Cash Mensal',
    endpoint: 'flashCash',
    body: {
      monthlySummary: true,
    },
  },
  TRANSACTIONS: {
    name: 'Transações',
    endpoint: 'transactions',
    body: {},
  },
} as const;

type IInput =
  | {
      report: 'ACTIVE_USERS';
      format: 'csv';
    }
  | {
      report: Exclude<keyof typeof reports, 'ACTIVE_USERS'>;
      format: 'csv';
      startDate: Date;
      endDate: Date;
    };

const CORPORATE_CARD_BFF_URL = {
  staging: 'https://hros-web-corporate-card-backend-preview.private.flashapp.dev/trpc',
  production: 'https://hros-web-corporate-card-backend.us.flashapp.services/trpc',
};

const baseUrl =
  CORPORATE_CARD_BFF_URL[config.BUILD_ENV === 'production' ? 'production' : 'staging'] + '/company.reports';

export default function useDownloadCorporateCardReport() {
  const { companyId, accessToken } = React.useContext(SessionContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const t = useTranslate();

  function toastSuccess() {
    showToast({
      title: t('analytics.toasts.success.title'),
      message: t('analytics.toasts.success.message'),
      type: 'success',
    });
  }

  function toastError(message?: string) {
    showToast({
      title: t('analytics.toasts.error.title'),
      message: message ?? t('analytics.toasts.error.message'),
      type: 'error',
    });
  }

  return {
    isLoading,
    download: async function (input: IInput) {
      setIsLoading(true);
      const selectedReport = reports[input.report];

      try {
        const response = await fetch(`${baseUrl}.${selectedReport.endpoint}?batch=0`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: accessToken,
            'Access-Control-Allow-Origin': '*',
            'company-id': companyId || undefined,
          },
          body: JSON.stringify({
            '0': {
              json: {
                format: input.format,
                filter:
                  input.report === 'ACTIVE_USERS'
                    ? undefined
                    : {
                        startDate: input.startDate.toISOString().split('T')[0],
                        endDate: input.endDate.toISOString().split('T')[0],
                      },
                ...selectedReport.body,
              },
            },
          }),
        });

        if (response.status === 200) {
          const stringFile = (await response.json()) as [
            {
              result: {
                data: { json: string };
              };
            },
          ];
          const file = new Blob([stringFile[0].result.data.json], {
            type: 'application/json',
          });
          const fileURL = window.URL.createObjectURL(file);
          const tempLink = document.createElement('a');
          tempLink.href = fileURL;
          tempLink.setAttribute('download', `Relatório - ${selectedReport.name}.${input.format}`);
          tempLink.click();
          toastSuccess();
        } else {
          const responseJson = (await response.json()) as { userMessage?: string };
          toastError(responseJson.userMessage);
        }
      } catch {
        toastError();
      } finally {
        setIsLoading(false);
      }
    },
  };
}
