import { Skeleton } from '@mui/material';
import { attachExpenseModalNumber } from '@shared/constant';

import * as SC from './styled';

interface IProps {
  withdrawNumber?: number;
}

export const AttachWithdrawModalSkeleton = ({ withdrawNumber = attachExpenseModalNumber }: IProps) => {
  return (
    <SC.Container>
      <SC.Content>
        {[...Array(withdrawNumber)].map((item, index) => (
          <SC.Item key={index}>
            <Skeleton variant="text" height={48} width={25} />
            <SC.CardContainer>
              <Skeleton variant="circular" height={48} width={48} />
              <Skeleton variant="text" height={48} width={150} />
            </SC.CardContainer>
            <Skeleton variant="text" height={48} width={90} />
            <Skeleton variant="text" height={48} width={90} />
          </SC.Item>
        ))}
      </SC.Content>
    </SC.Container>
  );
};
