import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface ContainerProps {
  hasBackgroundColor?: boolean;
}

export const Container = styled(BorderedWrapping)<ContainerProps>`
  background-color: ${props => (props.hasBackgroundColor ? props.theme.colors.neutral[95] : 'transparent')};
  display: flex;
  gap: ${props => props.theme.spacings.xs};
  padding: ${props => props.theme.spacings.xs};
  width: 100%;
`;

export const Title = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
  font-weight: 700;
`;

export const SubTitle = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
  margin-bottom: ${props => props.theme.spacings.xs3};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Icon = styled(Icons)`
  fill: none;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.secondary[95]};
  border-radius: ${props => props.theme.borders.radius.circular};
  aspect-ratio: 1 / 1;
  height: 64px;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacings.xs2};

  svg {
    height: 30px;
    width: 30px;
  }
`;
