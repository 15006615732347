import { AnalyticsFilterField, EAnalyticsFilterFieldKey } from '@containers/Analytics/context/types/filters';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';

interface IUseFilterAnalyticsFieldsLicenseFeatures {
  getFieldsApplyingLicenseFeatures(_fields: AnalyticsFilterField[]): AnalyticsFilterField[];
}

export function useFilterAnalyticsFieldsLicenseFeatures(): IUseFilterAnalyticsFieldsLicenseFeatures {
  const { checkLicenseFeature } = useLicenseFeatures();

  function getFieldsApplyingLicenseFeatures(_fields: AnalyticsFilterField[]): AnalyticsFilterField[] {
    const currentFields: AnalyticsFilterField[] = [];
    _fields?.forEach(_field => {
      switch (_field.key) {
        case EAnalyticsFilterFieldKey.EXPENSE_AREA:
          checkLicenseFeature('INTERMEDIATE_AREA') && currentFields.push(_field);
          break;
        case EAnalyticsFilterFieldKey.EXPENSE_CATEGORY:
          checkLicenseFeature('BASIC_CATEGORIES') && currentFields.push(_field);
          break;
        case EAnalyticsFilterFieldKey.EXPENSE_CLIENT:
          checkLicenseFeature('INTERMEDIATE_CLIENT') && currentFields.push(_field);
          break;
        case EAnalyticsFilterFieldKey.EXPENSE_COST_CENTER:
          checkLicenseFeature('INTERMEDIATE_COST_CENTER') && currentFields.push(_field);
          break;
        case EAnalyticsFilterFieldKey.EXPENSE_PROJECT:
          checkLicenseFeature('INTERMEDIATE_PROJECT') && currentFields.push(_field);
          break;
        case EAnalyticsFilterFieldKey.EXPENSE_SUBSIDIARY:
          checkLicenseFeature('INTERMEDIATE_SUBSIDIARY') && currentFields.push(_field);
          break;
        case EAnalyticsFilterFieldKey.REPORT_LAST_APPROVER:
        case EAnalyticsFilterFieldKey.REPORT_CURRENT_APPROVER:
          checkLicenseFeature('INTERMEDIATE_APPROVALS') && currentFields.push(_field);
          break;
        default:
          currentFields.push(_field);
          break;
      }
    });

    return currentFields;
  }

  return { getFieldsApplyingLicenseFeatures };
}
