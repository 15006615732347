export interface IAnalyticsFilterFieldInput {
  key: EAnalyticsFilterFieldKey;
  value?: IAnalyticsFilterValue;
}

export enum EAnalyticsFilterFieldKey {
  EXPENSE_DATE = 'EXPENSE_DATE',
  EXPENSE_CATEGORY = 'EXPENSE_CATEGORY',
  EXPENSE_PAYMENT_METHOD = 'EXPENSE_PAYMENT_METHOD',
  EXPENSE_CORPORATE_CARD_NUMBER = 'EXPENSE_CORPORATE_CARD_NUMBER',
  USER = 'USER',
  EXPENSE_SUBSIDIARY = 'EXPENSE_SUBSIDIARY',
  EXPENSE_AREA = 'EXPENSE_AREA',
  EXPENSE_HAS_VIOLATIONS = 'EXPENSE_HAS_VIOLATIONS',
  EXPENSE_POSITION = 'EXPENSE_POSITION',
  EXPENSE_COST_CENTER = 'EXPENSE_COST_CENTER',
  EXPENSE_CLIENT = 'EXPENSE_CLIENT',
  EXPENSE_PROJECT = 'EXPENSE_PROJECT',
  CREATED_DATE = 'CREATED_DATE',
  VALUE = 'VALUE',
  REIMBURSABLE_VALUE = 'REIMBURSABLE_VALUE',
  REPORT = 'REPORT',
  REPORT_LAST_APPROVER = 'REPORT_LAST_APPROVER',
  REPORT_STATUS = 'REPORT_STATUS',
  REPORT_CREATION_DATE = 'REPORT_CREATION_DATE',
  REPORT_APPROVAL_DATE = 'REPORT_APPROVAL_DATE',
  REPORT_PAYMENT_SUBMIT_DATE = 'REPORT_PAYMENT_SUBMIT_DATE',
  REPORT_PAYMENT_DATE = 'REPORT_PAYMENT_DATE',
  REPORT_CURRENT_APPROVER = 'REPORT_CURRENT_APPROVER',
  ADVANCE_STATUS = 'ADVANCE_STATUS',
  ADVANCE_CREATION_DATE = 'ADVANCE_CREATION_DATE',
}

export enum IAnalyticsFilterFieldType {
  SEARCH = 'SEARCH',
  PERIOD = 'PERIOD',
  RANGE = 'RANGE',
  CURRENCY_RANGE = 'CURRENCY_RANGE',
  MULTIPLE = 'MULTIPLE',
  LIST = 'LIST',
  BOOLEAN = 'BOOLEAN',
}

export interface IAnalyticsFilterValue {
  text?: string;
  options?: string[];
  bool?: boolean;
  min?: number;
  max?: number;
  startDate?: string;
  endDate?: string;
}

export interface IAnalyticsFilterFieldOption {
  id: string;
  label: string;
}

export enum EAnalyticsType {
  EXPENSES_OVERVIEW = 'EXPENSES_OVERVIEW',
  EXPENSES_PENDING_OVERVIEW = 'EXPENSES_PENDING_OVERVIEW',
  EXPENSES_BY_CATEGORY = 'EXPENSES_BY_CATEGORY',
  EXPENSES_BY_USER = 'EXPENSES_BY_USER',
  CORPORATE_CARD_OVERVIEW = 'CORPORATE_CARD_OVERVIEW',
  CORPORATE_CARD_PENDING_OVERVIEW = 'CORPORATE_CARD_PENDING_OVERVIEW',
  CORPORATE_CARD_BY_USER = 'CORPORATE_CARD_BY_USER',
  REPORT_OVERVIEW = 'REPORT_OVERVIEW',
  REPORT_PENDING_APPROVALS = 'REPORT_PENDING_APPROVALS',
  REPORT_STATUS_BY_USER = 'REPORT_STATUS_BY_USER',
  ADVANCES_OVERVIEW = 'ADVANCES_OVERVIEW',
  ADVANCES_STATUS_BY_USER = 'ADVANCES_STATUS_BY_USER',
  LOGS_BY_APPROVAL = 'LOGS_BY_APPROVAL',
  LOGS_BY_AREA = 'LOGS_BY_AREA',
  LOGS_BY_COSTCENTER = 'LOGS_BY_COSTCENTER',
  LOGS_BY_PROJECT = 'LOGS_BY_PROJECT',
  LOGS_BY_USER = 'LOGS_BY_USER',
  LOGS_BY_POLICY = 'LOGS_BY_POLICY',
}

export interface AnalyticsFilterField {
  key: EAnalyticsFilterFieldKey;
  type: IAnalyticsFilterFieldType;
}

export interface IAnalyticsFilterFieldsRequest {
  type: EAnalyticsType;
}

export interface IAnalyticsFilterFieldsResponse {
  getAnalyticsFilterFields: AnalyticsFilterField[];
}

export interface IAnalyticsFilterFieldOptionsRequest {
  type: EAnalyticsType;
  field: EAnalyticsFilterFieldKey;
  search: string;
}

export interface IAnalyticsFilterFieldOptionsResponse {
  data: Record<EAnalyticsFilterFieldKey, IAnalyticsFilterFieldOption[]>;
}

export enum EAnalyticsFilterLabel {
  EXPENSE_DATE = 'expenseDate',
  EXPENSE_CATEGORY = 'expenseCategory',
  EXPENSE_PAYMENT_METHOD = 'expensePaymentMethod',
  EXPENSE_CORPORATE_CARD_NUMBER = 'expenseCorporateCardNumber',
  USER = 'user',
  EXPENSE_SUBSIDIARY = 'expenseSubsidiary',
  EXPENSE_AREA = 'expenseArea',
  EXPENSE_HAS_VIOLATIONS = 'expenseHasViolations',
  EXPENSE_POSITION = 'expensePosition',
  EXPENSE_COST_CENTER = 'expenseCosteCenter',
  EXPENSE_CLIENT = 'expenseClient',
  EXPENSE_PROJECT = 'expenseProject',
  CREATED_DATE = 'createdDate',
  VALUE = 'value',
  REIMBURSABLE_VALUE = 'reimbursableValue',
  REPORT = 'report',
  REPORT_LAST_APPROVER = 'reportLastApprover',
  REPORT_STATUS = 'reportStatus',
  REPORT_CREATION_DATE = 'reportCreationDate',
  REPORT_APPROVAL_DATE = 'reportApprovalDate',
  REPORT_PAYMENT_SUBMIT_DATE = 'reportPaymentSubmitDate',
  REPORT_PAYMENT_DATE = 'reportPaymentDate',
  REPORT_CURRENT_APPROVER = 'reportCurrentApprover',
  ADVANCE_STATUS = 'advanceStatus',
  ADVANCE_CREATION_DATE = 'advanceCreationDate',
  LOG_DATE = 'logDate',
  LOG_ACTION = 'logAction',
  LOG_PARAMETER = 'logParameter',
  LOG_USER = 'logUser',
  LOG_APPROVAL_FLOW = 'logApprovalFlow',
  LOG_AREA = 'logArea',
  LOG_COST_CENTER = 'logCostCenter',
  LOG_PROJECT = 'logProject',
  LOG_POLICY = 'logPolicy',
  LOG_USER_TARGET = 'logUserTarget',
}
