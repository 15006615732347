import {
  IConfigurationExpenseSettings,
  IFormExpenseSettings,
} from '@containers/Customizations/context/types/settings/expenseSettingsTypes';
import { useFetch } from '@shared/hooks/service/useFetch';

interface UseExpenseSettings {
  expenseSettings: IConfigurationExpenseSettings;
  isLoading: boolean;
  error: unknown;
}

interface UseFormExpenseSettings {
  formSettings: IFormExpenseSettings;
  isLoading: boolean;
  error: unknown;
}

export function useExpenseSettings(): UseExpenseSettings {
  const { data, error, isLoading } = useFetch<IConfigurationExpenseSettings>('v1/settings/expense');

  return {
    expenseSettings: data,
    isLoading: isLoading,
    error,
  };
}

export function useGeneralExpenseSettings(): UseExpenseSettings {
  const { data, error, isLoading } = useFetch<IConfigurationExpenseSettings>('v1/settings/expense/general');

  return {
    expenseSettings: data,
    isLoading: isLoading,
    error,
  };
}

export function useFormExpenseSettings(): UseFormExpenseSettings {
  const { data, error, isLoading } = useFetch<IFormExpenseSettings>('v1/settings/expense/form');

  return {
    formSettings: data,
    isLoading: isLoading,
    error,
  };
}
