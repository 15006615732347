import { useTranslation } from '@locale/Translator';
import { PageHeader } from '@organisms/BasePage/PageHeader/PageHeader';
import { BulkImportDetails } from '@organisms/BulkImportDetails';
import { ProjectFooterPage } from '@pages/BulkImportProjects/ProjectFooterPage';
import { ProjectRegisterFileHeader } from '@pages/BulkImportProjects/ProjectRegisterFileHeader';
import { projectsBaseURL } from '@shared/constant';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

export const BulkImportProjectsDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm();

  return (
    <>
      <ProjectRegisterFileHeader />
      <FormProvider {...methods}>
        <SC.Container>
          <PageHeader title={t('organisms.BulkImportDetails.header.costCenters')} />
          <BulkImportDetails newImportAction={() => navigate(`${projectsBaseURL}/register/file`)} page="projects" />
        </SC.Container>
        <ProjectFooterPage action={() => navigate(projectsBaseURL)} />
      </FormProvider>
    </>
  );
};
