import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function useDeleteCustomField() {
  const t = useTranslate('organisms.customizationsCustomFields.modal.customField.feedbacks');
  const { mutation } = useMutate('v1/custom-fields', onError);

  function onError(error: Error) {
    return showToast({ message: error.message, type: 'error' });
  }

  async function handleDelete(costCenterId: string) {
    const response = await mutation.trigger({
      path: '/' + costCenterId,
      options: {
        method: 'DELETE',
      },
    });

    showToast({
      message: t('success'),
      type: 'success',
    });

    return response;
  }

  return {
    handleDelete,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
