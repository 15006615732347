import { LazyQueryExecFunction } from '@apollo/client';
import {
  IExpenses,
  IExpensesFilters,
  IGetExpensesResponse,
  IPaginationSettings,
} from '@containers/Expenses/context/types';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { getAllExpensesToBind } from '../context/queries/expenses';

interface IUseExpensesToBind {
  getExpensesToBind: LazyQueryExecFunction<IGetExpensesResponse, IGetExpensesRequest>;
  expensesToBind: IExpenses[];
  isLoading: boolean;
  error: unknown;
}

export interface IGetExpensesRequest extends IPaginationSettings, IExpensesFilters {}

export const useExpensesToBind = (): IUseExpensesToBind => {
  const [getExpensesToBind, { loading, data, error }] = useLazyQuery<IGetExpensesResponse, IGetExpensesRequest>(
    getAllExpensesToBind,
  );

  return {
    getExpensesToBind,
    expensesToBind: data?.getExpenses?.expenses || [],
    isLoading: loading,
    error,
  };
};
