import { ICategory } from '@containers/Expenses/context/types';
import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

interface AddLimitSuccessModal {
  open: boolean;
  categories: ICategory[];
  onClose(): void;
  onContinueAdding(): void;
}

export const AddLimitSuccessModal = ({ open, categories, onClose, onContinueAdding }: AddLimitSuccessModal) => {
  const { t } = useTranslation();
  const pluralForm = categories?.length > 1 ? 'plural' : 'singular';

  return (
    <SC.Modal
      open={open}
      labelButtonCancel={t('molecules.addLimitSuccessModal.continue')}
      labelButtonAction={t('molecules.addLimitSuccessModal.conclude')}
      headerIcon="IconCheck"
      headerTitle={t(`molecules.addLimitSuccessModal.${pluralForm}.title`)}
      headerSubtitle={t(`molecules.addLimitSuccessModal.${pluralForm}.subtitle`, {
        category: categories.join(', '),
      })}
      headerTextAlign={'center'}
      onCloseClick={onClose}
      onCancelClick={onContinueAdding}
      onActionClick={onClose}
    />
  );
};
