import { useCallback } from 'react';
import extenso from 'numero-por-extenso';

export interface GetFormatLocaleValueProps {
  value: number;
  isDecimal?: boolean;
}
export interface GetValueWithCurrencyProps {
  value: number;
  currencyPrefix?: string;
  numberIfFull?: boolean;
}

export const useValueFormatter = () => {
  function getNumberInFull(value: number) {
    try {
      return `${extenso.porExtenso(value, extenso.estilo.monetario)}`;
    } catch (err) {
      return '';
    }
  }

  function getValueWithCurrency({ value, currencyPrefix, numberIfFull }: GetValueWithCurrencyProps) {
    let suffix = '';

    if (numberIfFull && value) {
      suffix = ` (${getNumberInFull(value)})`;
    }

    if (currencyPrefix) {
      return `${currencyPrefix} ${value?.toLocaleString('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}${suffix}`;
    }

    return `${value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}${suffix}`;
  }

  function getFormatDecimalValue(value: number) {
    return `${value?.toLocaleString('pt-BR', { maximumFractionDigits: 1 })}`;
  }

  const centsToMoney = useCallback((cents: number | undefined) => (cents || 0) / 100, []);

  return {
    getValueWithCurrency,
    getFormatDecimalValue,
    centsToMoney,
  };
};
