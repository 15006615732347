import * as SC from './styled';

interface IProps {
  children: React.ReactNode[];
  numberOfColumns?: number;
}

export const CardFiltersGroup = ({ children, numberOfColumns }: IProps) => {
  return <SC.ContainerStatus numberOfColumns={numberOfColumns}>{children}</SC.ContainerStatus>;
};
