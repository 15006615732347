import { config } from '@shared/config';
import { ServiceException } from '@shared/hooks/service/error.type';

import { Authorization } from './Authorization';

interface ISessionData {
  companyId: string;
  accessToken: string;
}

const updateOptions = async (options: RequestInit, sessionData: ISessionData): Promise<RequestInit> => {
  options.headers = await updateHeaders(options, sessionData);
  return options;
};

const updateHeaders = async (options: RequestInit, sessionData: ISessionData) => {
  const authorization = await Authorization.getAccessToken();
  const header = {
    Authorization: authorization,
    'Access-Control-Allow-Origin': '*',
    'company-id': sessionData.companyId || undefined,
    ...options.headers,
  };

  if (!options?.headers?.['isFile'] && !options?.headers?.['Content-Type']) {
    header['Content-Type'] = 'application/json';
  }

  return header;
};

// TODO: update generic type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetcher = async <T = any>(
  path: string,
  sessionData: ISessionData,
  options?: RequestInit,
  defaultPath?: boolean,
): Promise<T> => {
  const fetchOptions = await updateOptions(options || {}, sessionData);
  const url = `${config.API_URL}${!defaultPath ? '/api' : ''}${ensureStartPath(path)}`;

  const res = await fetch(url, fetchOptions).then(async response => {
    if (response.ok) {
      if (
        response?.headers.get('Content-Type')?.includes('excel') ||
        response?.headers.get('Content-Type')?.includes('pdf')
      ) {
        return await response.blob();
      }

      try {
        return await response.json();
      } catch (err) {
        return null;
      }
    }

    if (response.status === 403) {
      throw new Error('Você não tem permissão para efetuar essa ação');
    }

    const responseError = await response.json();

    const exception = new ServiceException(
      responseError.message || `Error:${response.status} - ${response.statusText}`,
      response,
      response.status,
    );

    exception.code = responseError?.error || responseError?.code;

    throw exception;
  });

  return res;
};

export const ensureStartPath = (path: string): string => {
  return path.startsWith('/') || path.startsWith('?') ? path : `/${path}`;
};

export const fetcherMutation = async (
  url: string,
  { arg }: { arg: { path?: string; parameters?: { key: string; value: string }[]; options: RequestInit } },
  sessionData: ISessionData,
  defaultPath?: boolean,
) => {
  if (arg?.path) {
    url = `${url}${ensureStartPath(arg.path)}`;
  }
  if (arg?.parameters) {
    url += '?';
    arg?.parameters?.forEach((parameter, index, array) => {
      url = `${url}${parameter.key}=${parameter.value}`;
      if (index < array.length - 1) {
        url += '&';
      }
    });
  }
  return await fetcher(url, sessionData, arg.options, defaultPath);
};
