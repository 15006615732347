import { Loader, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  background-color: ${props => props.theme.colors.neutral[0]};
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderContent = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: ${props => props.theme.spacings.xs2};
`;

export const Description = styled(Typography)`
  color: ${props => props.theme.colors.neutral[100]};
`;

export const LoaderPageContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LoaderPage = styled(Loader)`
  width: min-content;
  margin: 0 auto;
`;
