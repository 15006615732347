import React from 'react';
import { ErrorBoundary } from '@atoms/ErrorBoundary';

interface IModuleErrorBoundaryProps {
  children: React.ReactNode;
  moduleName?: string;
  title?: string;
  description?: string;
  printIsolatedError?: boolean;
}

export class ModuleErrorBoundary extends React.Component<IModuleErrorBoundaryProps> {
  state: {
    hasError: boolean;
    error: Error;
    message?: string;
    page?: string;
    createdAt?: string;
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    console.error({ error });

    return {
      hasError: true,
      error,
      message: error?.message || 'Nenhum erro listado',
      stack: error.stack,
      page: location.href,
      createdAt: new Date().toISOString(),
    };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true, error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundary
          moduleName={this.props.moduleName}
          title={this.props.title}
          description={this.props.description}
          printIsolatedError={this.props.printIsolatedError}
        />
      );
    }

    return this.props.children;
  }
}
