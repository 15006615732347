import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ETabCustomization } from '@containers/Customizations/components/organisms/CustomizationsPreview';
import { useCategoriesWithPolicies } from '@containers/Expenses/hooks/dataSource/useCategoriesWithPolicies';
import { IPolicy } from '@containers/Policies/context/types/types';
import { useCategories } from '@containers/Policies/hooks/dataSources/useCategories';
import { IconButton, Icons, LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { EFieldType, FieldFactory } from '@organisms/FieldFactory';
import { expensesCustomizationsBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { PolicyRuleSection } from '../../molecules/PolicyRuleSection/PolicyRuleSection';
import { LimitsPreview } from './LimitsPreview';
import * as SC from './styled';

interface IProps {
  policyId: IPolicy['id'];
  onCloseRule(): void;
  setCategoriesPolicySelected: Dispatch<SetStateAction<string[]>>;
}

export function PolicyCategories({ policyId, onCloseRule, setCategoriesPolicySelected }: IProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const t = useTranslate('policy.register.categories');
  const { colors } = useTheme();
  const { categories: categoriesWithPolicies, isLoading: categoriesWithPoliciesIsLoading } = useCategoriesWithPolicies({
    policyId,
  });
  const { categories, isLoading: categoriesIsLoading } = useCategories();
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);

  useEffect(() => {
    if (!categoriesWithPoliciesIsLoading && categoriesWithPolicies?.length && categories?.length) {
      const selectedCategoryList = Boolean(id)
        ? categoriesWithPolicies?.filter(categoryPolicy => categoryPolicy.active)?.map(category => String(category.id))
        : categories?.filter(categoryPolicy => categoryPolicy.active)?.map(category => String(category.id));

      setCategoriesSelected(selectedCategoryList);
      setCategoriesPolicySelected?.(selectedCategoryList);
    }
  }, [
    JSON.stringify(categoriesWithPolicies),
    categoriesWithPoliciesIsLoading,
    JSON.stringify(categories),
    categoriesIsLoading,
  ]);

  return (
    <PolicyRuleSection
      icon="IconCategory2"
      title={t('title')}
      caption={t('caption')}
      action={<IconButton variant="line" icon="IconX" size="small" onClick={onCloseRule} />}>
      <SC.ContainerDirect>
        <Typography variant="body3">{t('direct')}</Typography>
        <LinkButton
          variant="default"
          onClick={() => navigate(`${expensesCustomizationsBaseURL}?customizationTab=${ETabCustomization.CATEGORIES}`)}>
          <>{t('linkDirect')}</> <Icons size={16} name="IconExternalLink" color={colors.primary} />
        </LinkButton>
      </SC.ContainerDirect>
      <FieldFactory
        isLoading={categoriesWithPoliciesIsLoading || categoriesIsLoading}
        fieldType={EFieldType.OPTIONS}
        label={t('field.label')}
        onChange={data => {
          setCategoriesSelected(data), setCategoriesPolicySelected?.(data);
        }}
        allowsEditing
        value={categoriesSelected || []}
        options={categories?.map(category => ({ value: String(category.id), label: category.name })) || []}
        isSelectMultiple
      />
      <LimitsPreview policyId={Number(policyId)} isLoading={false} />
    </PolicyRuleSection>
  );
}
