import { useCallback, useContext, useState } from 'react';
import { SubscriptionContext } from '@containers/Subscription/context';
import { useSubscriptionFeatures } from '@containers/Subscription/hooks/useSubscriptionFeatures';
import { useSubscriptionPlan } from '@containers/Subscription/hooks/useSubscriptionPlan';
import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { useIsMobile } from '@shared/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { Feature } from './Feature/Feature';
import * as SC from './styled';

export const Features = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const isMobile = useIsMobile();

  const itemsSlider = useSubscriptionFeatures();

  const { planName, isFull } = useSubscriptionPlan();

  const { saasServiceContract } = useContext(SubscriptionContext);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
    setActiveIndex(swiperRef.realIndex);
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
    setActiveIndex(swiperRef.realIndex);
  }, [swiperRef]);

  if (!saasServiceContract) {
    return null;
  }

  return (
    <Box width={'100%'}>
      <SC.Title variant="headline8">{t('molecules.subscriptionFeatures.title', { planName })}</SC.Title>
      <SC.Slider
        onSwiper={setSwiperRef}
        onTouchMove={touchData => setActiveIndex(touchData.realIndex)}
        slidesPerView={isMobile ? 1 : 3}
        spaceBetween={15}
        loop={true}
        style={{ overflow: isMobile ? 'visible' : 'hidden' }}>
        {itemsSlider.map((item, index) => (
          <SwiperSlide key={index}>
            <Feature iconName={item.icon} description={item.description} isFullPlan={isFull} />
          </SwiperSlide>
        ))}
      </SC.Slider>
      <SC.ContainerNavigation>
        <SC.ContainerNavigationDots>
          {itemsSlider.map((_, index) => (
            <SC.Dot key={index} isActive={activeIndex === index} />
          ))}
        </SC.ContainerNavigationDots>
        <SC.ContainerNavigationButtons>
          <IconButton size="medium" variant="line" icon="IconChevronLeft" onClick={handlePrevious} />
          <IconButton size="medium" variant="line" icon="IconChevronRight" onClick={handleNext} />
        </SC.ContainerNavigationButtons>
      </SC.ContainerNavigation>
    </Box>
  );
};
