import { TUserPendingExpense } from '@containers/Financial/context/types/users';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import qs from 'query-string';

type TResponse = {
  users: TUserPendingExpense[];
  currentPage: number;
  pages: number;
  limit: number;
  total: number;
};

export interface IProps {
  filters?: {
    userId?: TUserPendingExpense['id'];
    page?: number;
    pageLimit?: number;
  };
}

export function useUsersPendingExpenses({ filters }: IProps) {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const params = qs.stringify(filters, { arrayFormat: 'bracket' });
  const {
    data,
    error,
    isLoading,
    mutate: refetch,
  } = useFetch<TResponse>(`v1/analytics/expense/user/pending?${params}`, undefined, undefined, undefined, onError);

  return {
    response: {
      ...data,
      users: data?.users ?? [],
    },
    isLoading: isLoading,
    error,
    refetch,
  };
}
