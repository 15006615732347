import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { EExpenseType, IExpenseInput } from '@containers/Expenses/context/types';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { IRoute } from '@containers/Expenses/context/types/expenseById';
import { useEstablishments } from '@containers/Expenses/hooks/useEstablishments';
import { useKmPolicies } from '@containers/Expenses/hooks/useKmPolicies';
import { FieldArrayMethodProps, FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';

import { useDistanceManagement } from './useDistanceManagement';
import { useSerializeWaypoints } from './useSerializeWaypoints';

interface IProps {
  route: IRoute;
  ratePerKm: number;
  expenseType: EExpenseType;
}

interface IUseWaypointFieldsHelper {
  showAllWaypoints: boolean;
  showExpandButton: boolean;
  fields: FieldArrayWithId<IExpenseInput, 'route.waypoints', 'id'>[];
  distance: number;
  distanceIsLoading: boolean;
  serializingIsProcessing: boolean;
  establishmentsIsLoading: boolean;
  showAddNewWaypoint: boolean;
  getLastIndex: () => number;
  checkMinimumFields: () => boolean;
  updateField: (index: number, value: IEstablishment) => void;
  appendField: (value: IEstablishment | IEstablishment[], options?: FieldArrayMethodProps) => void;
  removeField: (index?: number | number[]) => void;
  setShowAllWaypoints: Dispatch<SetStateAction<boolean>>;
}

export function useWaypointFieldsHelper({ route, ratePerKm, expenseType }: IProps): IUseWaypointFieldsHelper {
  const { establishments, isLoading: establishmentsIsLoading } = useEstablishments({ page: 1, limit: 250 });
  const serializeWaypoints = useSerializeWaypoints(establishments);
  const { control } = useFormContext<IExpenseInput>();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'route.waypoints',
    rules: { maxLength: 26, minLength: 2 },
  });

  const { distance, distanceIsLoading } = useDistanceManagement({ fields, route, ratePerKm });

  const [serializingIsProcessing, setSerializingIsProcessing] = useState(false);
  const [showAllWaypoints, setShowAllWaypoints] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(checkMaximumShowFields());
  const { allowMultiplePlaces } = useKmPolicies();

  function getLastIndex() {
    return fields.length > 0 ? fields.length - 1 : 0;
  }

  function checkMinimumFields() {
    return fields.length === 2;
  }

  function checkMaximumShowFields() {
    return fields.length >= 4;
  }

  useMemo(() => {
    if (checkMaximumShowFields()) {
      setShowExpandButton(true);
    } else {
      setShowExpandButton(false);
      setShowAllWaypoints(true);
    }
  }, [fields.length]);

  useMemo(() => {
    if (route && establishments && !establishmentsIsLoading) {
      setSerializingIsProcessing(true);

      if (expenseType !== EExpenseType.GPS) {
        serializeWaypoints(route?.waypoints)?.forEach((_route, index) => {
          update(index, _route);
        });
      }

      setSerializingIsProcessing(false);
    }
  }, [route?.waypoints, !establishmentsIsLoading]);

  return {
    showAllWaypoints,
    showExpandButton,
    fields,
    distance,
    distanceIsLoading,
    serializingIsProcessing,
    establishmentsIsLoading,
    showAddNewWaypoint: allowMultiplePlaces,
    getLastIndex,
    checkMinimumFields,
    setShowAllWaypoints,
    updateField: update,
    removeField: remove,
    appendField: append,
  };
}
