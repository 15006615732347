import { Tooltip, TooltipProps } from '@mui/material';
import styled from 'styled-components';

export const TooltipHelp = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: `typography--variant-body4 ${className}` }} />
))`
  & .MuiTooltip-tooltip {
    font-family: var(--font-family-secondary);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 16px;
    border-radius: 8px;
    background: ${props => props.theme.colors.neutral.dark.dark1};
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  }
`;
