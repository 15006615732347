import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 982px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const ContainerRules = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const ContainerRulesDetails = styled.div`
  width: 100%;
`;

export const Divider = styled.hr`
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  margin: ${({ theme }) => theme.spacings.xs} -24px ${({ theme }) => theme.spacings.xs} -24px;
`;

export const ContainerButtonIconLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[80]};
  width: 48px;
  height: 48px;
`;

export const KnowMoreButton = styled(LinkButton)`
  font-size: 14px !important;
`;
