import { useState } from 'react';
import { IExportPdfReports } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { config } from '@shared/config';
import { expensesAccountingBaseURL, expensesApprovalsBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import qs from 'query-string';

export function usePdfUrlExportReports() {
  const { t } = useTranslation();
  const [filtersPdf, setFiltersPdf] = useState<IExportPdfReports>(null);
  const params = qs.stringify(filtersPdf, { arrayFormat: 'bracket' });
  const getCurrentUrlBase = useCurrentUrlBase();
  const { data, error, isLoading, mutate } = useFetch<string>(
    getPath(),
    undefined,
    undefined,
    undefined,
    error => showToast({ message: error?.message, type: 'error' }),
    Boolean(filtersPdf),
  );

  function getPath() {
    switch (getCurrentUrlBase()) {
      case expensesApprovalsBaseURL:
        return `v1/reports/approval/pdf-url?${params}`;
      case expensesAccountingBaseURL:
        return `v1/reports/financial/pdf-url?${params}`;
      default:
        return `v1/reports/pdf-url?${params}`;
    }
  }

  if (data && !isLoading && !error) {
    window.open(`https://${config.BUILD_ENV === 'production' ? 'app' : 'qa'}.expenseon.com${data}`);
    filtersPdf && setFiltersPdf(null);
  }

  if (!data && error && filtersPdf) {
    showToast({
      message: t(
        `organisms.financialReportGrid.actionsOptions.feedbacks.export.${
          error.message?.includes('undefined')
            ? 'notFound'
            : `${filtersPdf.reportIds?.length > 1 ? 'pluralForm' : 'singularForm'}.error`
        }`,
      ),
      type: 'error',
    });
    setFiltersPdf(null);
  }

  return {
    getPdfUrlReports: data => setFiltersPdf({ ...data }),
    exporting: filtersPdf?.reportIds || [],
    isLoading: isLoading,
    error,
    refetch: () => mutate(),
  };
}
