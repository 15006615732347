import { t } from 'i18next';
import * as y from 'yup';

export const subsidiaryFormSchema = y.object({
  id: y.string().optional(),
  name: y
    .string()
    .required(t('organisms.subsidiaryRegister.fields.requiredField'))
    .label(t('organisms.subsidiaryRegister.fields.projectName')),
  active: y.boolean().default(false),
  registerNumber: y.string().label(t('organisms.subsidiaryRegister.fields.cnpj')).nullable(),
  ref: y.string().nullable().label(t('organisms.subsidiaryRegister.fields.code')),
  costCenters: y.array(y.string()).nullable(),
  address: y.object({
    street: y
      .string()
      .required(t('organisms.subsidiaryRegister.fields.requiredField'))
      .label(t('organisms.subsidiaryRegister.fields.')),
    number: y
      .number()
      .required(t('organisms.subsidiaryRegister.fields.requiredField'))
      .label(t('organisms.subsidiaryRegister.fields.')),
    complement: y.string().nullable().label(t('organisms.subsidiaryRegister.fields.')),
    district: y
      .string()
      .required(t('organisms.subsidiaryRegister.fields.requiredField'))
      .label(t('organisms.subsidiaryRegister.fields.')),
    city: y
      .string()
      .required(t('organisms.subsidiaryRegister.fields.requiredField'))
      .label(t('organisms.subsidiaryRegister.fields.')),
    state: y
      .string()
      .required(t('organisms.subsidiaryRegister.fields.requiredField'))
      .label(t('organisms.subsidiaryRegister.fields.uf')),
    zipCode: y
      .string()
      .required(t('organisms.subsidiaryRegister.fields.requiredField'))
      .label(t('organisms.subsidiaryRegister.fields.')),
  }),
  userIds: y.array(y.string()).nullable(),
});

export type SubsidiaryFormData = y.InferType<typeof subsidiaryFormSchema>;
