export interface IApprover {
  id: number;
  name: string;
  email: string;
}

export interface IGetApprovers {
  getApprovers: {
    approvers: IApprover[];
    total: number;
  };
}

export interface IApproversTarget {
  id: string;
  userId: string;
  level: number;
  status: EApprovalStatus;
  userName: string;
}

export enum EApprovalStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum ApproverListContextType {
  NONE = 'NONE',
  REPORT_FORM = 'REPORT_FORM',
}
