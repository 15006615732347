import { useMemo, useState } from 'react';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';
import { cloneDeep } from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import { useSetFieldsValues } from '../components/organisms/ExpenseRegisterForm/ExpenseFormFields/hooks/useSetFieldsValues';
import { getExpenseFormById } from '../context/queries';
import { IExpenseInput } from '../context/types';
import { IExpense, IGetExpenseByIdResponse } from '../context/types/expenseById';
import { useSignedFileUrl } from './useSignedFileUrl';

export interface IUseExpenseByIdProps {
  id: number;
}

export const useExpenseByIdForm = (methods: UseFormReturn<IExpenseInput, unknown, undefined>) => {
  const [getById, { loading, data, error }] = useLazyQuery<IGetExpenseByIdResponse, IUseExpenseByIdProps>(
    getExpenseFormById,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const { files, isLoading: isLoadingFiles } = useSignedFileUrl(
    data?.getExpenseById?.attachments.map(({ path }) => path),
  );
  const { files: odometerFiles, isLoading: isLoadingOdometerFiles } = useSignedFileUrl([...getOdometerPaths()]);
  const { files: gpsImage, isLoading: isLoadingGpsImage } = useSignedFileUrl(
    [data?.getExpenseById?.route?.imagePath],
    !data?.getExpenseById?.route?.imagePath,
  );
  const [expenseById, setExpenseById] = useState<IExpense>(null);

  function getOdometerPaths() {
    const odometerPaths: string[] = [];
    const _odometer: IExpense['odometer'] = data?.getExpenseById?.odometer;
    const startFilePath: string = _odometer?.start?.attachment?.path;
    const endFilePath: string = _odometer?.end?.attachment?.path;

    if (startFilePath) odometerPaths.push(startFilePath);
    if (endFilePath) odometerPaths.push(endFilePath);

    return odometerPaths;
  }

  useMemo(() => {
    const expense = cloneDeep(data?.getExpenseById);

    if (expense) {
      files && (expense.attachments = files);
      gpsImage && (expense.route = { ...data?.getExpenseById?.route, previewImage: gpsImage });

      if (odometerFiles.length) {
        const startFile = odometerFiles[0];
        const endFile = odometerFiles[1];
        delete startFile?.__typename;
        delete endFile?.__typename;
        expense.odometer.start.attachment = startFile;
        expense.odometer.end.attachment = endFile;
      }
    }

    setExpenseById(expense);
  }, [data, files, odometerFiles, gpsImage]);

  async function getExpenseById(id: IExpense['id']) {
    const response = id && (await getById({ variables: { id } }));
    setExpenseById(response?.data?.getExpenseById);
    return { expense: response?.data?.getExpenseById, error };
  }

  useSetFieldsValues(expenseById, loading, methods);

  return {
    getExpenseById,
    expense: expenseById,
    isLoading: loading || isLoadingFiles || isLoadingOdometerFiles || isLoadingGpsImage,
    error,
  };
};
