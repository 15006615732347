import { MileageRatesPreview } from '@containers/Customizations/components/organisms/MileageRatesPreview';
import { useTranslation } from '@locale/Translator';
import { Box } from '@mui/material';

import * as SC from './styled';

type Props = {
  policyId: string;
};

const MileagePolicyRates = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Box mb={3}>
        <SC.Title variant="headline8">{t('organisms.mileagePreview.mileageRates.accordionTitle')}</SC.Title>
        <SC.Caption variant="body3">{t('organisms.mileagePreview.mileageRates.accordionDescription')}</SC.Caption>
      </Box>
      <Box mb={3}>
        <MileageRatesPreview isPolicyLoading={false} policyId={Number(props.policyId)} />
      </Box>
    </div>
  );
};

export default MileagePolicyRates;
