import { useEffect, useState } from 'react';
import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';

import { getTipsQuery } from '../../context/queries/tips';
import { EAccessedPagesTips, useTips } from './useTips';
import { useTipsMutate } from './useTipsMutate';

export interface ITips {
  page: 'customization' | 'addExpense';
}

export const useFirstAccess = ({ page }: ITips) => {
  const keyLS: string = 'idAccessedExpensePages';
  const [accessedPages, setAccessedPages] = useState<EAccessedPagesTips[]>(getFromLS(keyLS) ?? []);
  const { getTips, isLoadingTips } = useTips();
  const { createTipMutate, loading: loadingMutate } = useTipsMutate();

  async function getIdentifiersAccessedPages() {
    if (!checkAccessedPagesLsExists()) {
      const tips = await getTips();
      const _accessedPages = tips?.data?.getTips;

      setAccessedPages(_accessedPages);
      setAccessedPagesInLS(_accessedPages);
    }
  }

  function checkCurrentPageAccess(_accessedPages: EAccessedPagesTips[]): boolean {
    return _accessedPages && _accessedPages?.includes(EAccessedPagesTips[page]);
  }

  function setAccessedPagesInLS(accessedPagesTips: EAccessedPagesTips[]) {
    checkCurrentPageAccess(accessedPagesTips) && setInLS({ key: keyLS, value: accessedPagesTips });
  }

  function checkAccessedPagesLsExists(): boolean {
    return Boolean(getFromLS(keyLS));
  }

  async function setFirstAccessDone() {
    try {
      await createTipMutate({
        variables: {
          tip: EAccessedPagesTips[page],
        },
        refetchQueries: [getTipsQuery],
      });

      setAccessedPagesInLS([...accessedPages, EAccessedPagesTips[page]]);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getIdentifiersAccessedPages();
  }, []);

  return {
    isLoading: isLoadingTips || loadingMutate,
    isFirstAccess: !checkCurrentPageAccess(accessedPages),
    setFirstAccessDone,
  };
};
