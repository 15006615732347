import styled from 'styled-components';

export const ActionsButtonsFooterContainer = styled.div`
  display: grid;
  gap: ${props => props.theme.spacings.xs};
  grid-template-columns: min-content min-content;
`;

export const ButtonContent = styled.div`
  display: grid;
  gap: ${props => props.theme.spacings.xs4};
  grid-template-columns: 1fr 1fr;
`;
