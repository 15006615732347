import { EExpenseType } from '@containers/Expenses/context/types';
import { IBreadcrumbItem } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { expensesBaseUrl } from '@shared/constant';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';
import { useTranslation } from 'react-i18next';

export function useExpenseDetailsBreadcrumbs(): IBreadcrumbItem[] {
  const { t } = useTranslation();
  const expenseType = useCheckExpenseType();

  function getBreadcrumbExpenseType() {
    if (expenseType === EExpenseType.ORIGIN_DESTINATION) {
      return 'originDestination';
    }

    if (expenseType === EExpenseType.ODOMETER) {
      return 'odometer';
    }

    return 'expenseDetails';
  }

  return [
    {
      to: expensesBaseUrl,
      label: t('pages.expenseDetails.breadcrumb.expenses'),
    },
    {
      to: '',
      label: t(`pages.expenseDetails.breadcrumb.${getBreadcrumbExpenseType()}`),
    },
  ];
}
