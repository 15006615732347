import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.m};
`;

export const ContainerCounter = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: ${({ theme }) => theme.spacings.xs4};
`;

export const CounterLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700 !important;
`;
