import {
  EWaypointDivider,
  WaypointDivider,
} from '@containers/Expenses/components/molecules/WaypointIdentifier/WaypointDivider/WaypointDivider';
import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

interface IProps {
  isOpen: boolean;
  viewOnly?: boolean;
  onClick: () => void;
}

export function WaypointsExpandButton({ isOpen, viewOnly = false, onClick }: IProps) {
  const { t } = useTranslation();
  return (
    <SC.Container>
      {!viewOnly && (
        <SC.ContainerIcon>
          <WaypointDivider type={EWaypointDivider.DOTTED} dottedNumber={2} />
          <SC.Icon fill="transparent" name={isOpen ? 'IconArrowsDiagonal' : 'IconArrowsDiagonalMinimize2'} />
          <WaypointDivider type={EWaypointDivider.DOTTED} dottedNumber={2} />
        </SC.ContainerIcon>
      )}
      <div>
        <Button size="small" variant="secondary" onClick={onClick}>
          {t(`organisms.waypointFields.button.${isOpen ? 'open' : 'close'}`)}
        </Button>
      </div>
    </SC.Container>
  );
}
