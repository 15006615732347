import { useState } from 'react';
import { ImagesModal } from '@molecules/ImagesModal';

import IconAttachment from '../../../assets/svgs/iconAttachment.svg';
import * as SC from './styled';

export interface Image {
  src: string;
  id?: string | number;
}

export interface ImagePreviewProps {
  /**
   * List of items to display
   * @default 'example images'
   */
  images: Image[];
}

export const ImagePreview = ({ images }: ImagePreviewProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SC.ImagePreviewContainer>
      <SC.ImagePreview src={images[0].src} />
      <SC.ImagePreviewIconContainer onClick={() => setIsOpen(isOpen => !isOpen)}>
        <IconAttachment />
      </SC.ImagePreviewIconContainer>
      {isOpen && <ImagesModal images={images} onCloseModalClick={() => setIsOpen(isOpen => !isOpen)} />}
    </SC.ImagePreviewContainer>
  );
};
