import _cloneDeep from 'lodash/cloneDeep';

import { IMileageRate } from '../context/types/policies';
import { useMileageByPolicyId } from './useMilageByPolicyId';
import { useMileageSettingsMutate } from './useMileageSettingsMutate';

type Props = {
  policyId: number;
};

export function useSaveMileageRate({ policyId }: Props) {
  const { mileagePolicy } = useMileageByPolicyId({ id: policyId });
  const { mileageMutate: updateMileage, isLoading } = useMileageSettingsMutate();

  async function saveMileageRate(rate: Pick<IMileageRate, 'rate' | 'startDate'>, mileageRateId?: number) {
    const newMileageRates = _cloneDeep(mileagePolicy).policyMileageRatesSettings || [];

    if (!mileageRateId) {
      newMileageRates.unshift(rate);
    } else {
      const mileageToUpdate = newMileageRates.find(rate => rate.id === mileageRateId);

      mileageToUpdate.rate = rate.rate;
      mileageToUpdate.startDate = rate.startDate;
    }

    const { policyId, ...mileageSettings } = mileagePolicy;

    delete mileageSettings.id;

    const response = await updateMileage({
      variables: {
        policyId,
        policyMileageId: mileagePolicy.id,
        policyMileageSettingsInput: {
          ...mileageSettings,
          policyMileageRatesSettings: newMileageRates,
        },
      },
    });

    return response;
  }

  return {
    saveMileageRate,
    isLoading,
  };
}
