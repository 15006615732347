import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs2};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings.xs1};
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
