import {
  EReportsMainStatus,
  EReportsStatusColorVariant,
  ReportStatusSummary,
} from '@containers/Financial/context/types/reports';
import { EReportStatus } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { CardFilter } from '@organisms/CardFilter';
import { CardFiltersGroup } from '@organisms/CardFiltersGroup';

interface IProps {
  status: ReportStatusSummary;
  isLoading?: boolean;
  selectedStatus: EReportStatus[];
  onSelectedStatusClick(status: EReportStatus): void;
}

export const ReportStatusCardGroup = ({ status, isLoading, selectedStatus, onSelectedStatusClick }: IProps) => {
  const { t } = useTranslation();

  function getCards() {
    const cards: React.ReactNode[] = [];

    Object.keys(EReportsMainStatus).forEach((currentStatus, key) => {
      if (currentStatus != EReportsMainStatus.REIMBURSED)
        cards.push(
          <CardFilter
            key={key}
            isMain={true}
            isLoading={isLoading}
            variant={EReportsStatusColorVariant[EReportsMainStatus[currentStatus]]}
            quantityItems={status?.[currentStatus.toLowerCase()]?.count}
            totalValue={status?.[currentStatus.toLowerCase()]?.totalValue}
            isSelected={selectedStatus?.includes(EReportsMainStatus[currentStatus])}
            onSelectedStatusClick={() => onSelectedStatusClick(EReportsMainStatus[currentStatus])}
            title={t(
              `molecules.reportStatusCard.${
                status?.[currentStatus.toLowerCase()]?.count !== 1 ? 'pluralForm' : 'singularForm'
              }.${currentStatus.toLowerCase()}`,
            )}
          />,
        );
    });

    return cards;
  }

  return <CardFiltersGroup>{getCards()}</CardFiltersGroup>;
};
