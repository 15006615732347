import { EExportAnalyticsType } from '@containers/Analytics/context/types/export';
import { useTranslation } from '@locale/Translator';
import { downloadCSV, downloadExcel, downloadPDF } from '@shared/helpers/files';

import { useDateFormatter } from './formatters/useDateFormatter';

enum EFileType {
  XLSX = 'xls',
  PDF = 'pdf',
  CSV = 'csv',
}
interface IProps {
  translatePath: string;
}

export function useAnalyticsDownloadFile({ translatePath }: IProps) {
  const { t } = useTranslation();
  const { getDateOnlyFormatter } = useDateFormatter();

  function getDownloadFile(file: string, exportType?: EExportAnalyticsType) {
    const fileName: string = `${t(translatePath)}-${getDateOnlyFormatter(new Date())}.${EFileType[exportType]}`;

    if (file) {
      if (exportType === EExportAnalyticsType.XLSX) downloadExcel(file, fileName);
      if (exportType === EExportAnalyticsType.PDF) downloadPDF(file, fileName);
      if (exportType === EExportAnalyticsType.CSV) downloadCSV(file, fileName);
    }
  }

  return getDownloadFile;
}
