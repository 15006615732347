import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';

import { GeneralReportSettings } from './GeneralReportSettings/GeneralReportSettings';
import { useExpenseReportSettingsManager } from './hooks/useExpenseReportSettingsManager';
import { ReportExportPreferences } from './ReportExportPreferences/ReportExportPreferences';
import * as SC from './styled';

export function ReportsPreview() {
  const { reportSetting, isLoading, updateIsLoading, handleChange } = useExpenseReportSettingsManager();

  return (
    <SC.Container>
      <GeneralReportSettings
        reportsSettings={reportSetting}
        isLoading={isLoading}
        handleChange={handleChange}
        updateIsLoading={updateIsLoading}
      />
      <PermissionsChecker licenseFeature="INTERMEDIATE_REPORT">
        <ReportExportPreferences reportsSettings={reportSetting} isLoading={isLoading} handleChange={handleChange} />
      </PermissionsChecker>
    </SC.Container>
  );
}
