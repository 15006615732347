import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { DefaultModal } from '@molecules/Modal/DefaultModal';

import * as SC from './styled';

export type TAdvancesConfirmationActions = 'exclusion' | 'approve' | 'reprove' | 'unlink';

type TProps = {
  open: boolean;
  isBatchAction?: boolean;
  confirmationType: TAdvancesConfirmationActions;
  customMessage?: string;
  isLoading?: boolean;
  onActionClick(): void;
  onClose: () => void;
};

export function AdvancesConfirmationActionsModal({
  open,
  confirmationType,
  isBatchAction = false,
  customMessage,
  isLoading,
  onActionClick,
  onClose,
}: TProps) {
  const translation = useTranslation();
  const t = (key: string): string => translation.t(`organisms.advances.modal.${confirmationType}.${key}`);

  function getTranslateType(): string {
    return isBatchAction ? 'plural' : 'singular';
  }

  return (
    <SC.Container>
      {confirmationType !== 'approve' ? (
        <DangerActionModal
          open={open}
          isLoading={isLoading}
          headerIconLabel={t('iconDescription')}
          headerTitle={t(`title.${getTranslateType()}`)}
          headerSubtitle={customMessage ?? t(`message.${getTranslateType()}`)}
          labelButtonAction={
            <SC.LabelButtonContent>
              <p>{t(`actions.confirm.${getTranslateType()}`)}</p>
              {confirmationType === 'exclusion' && <Icons name="IconTrash" fill="transparent" size={24} />}
            </SC.LabelButtonContent>
          }
          onActionClick={onActionClick}
          onCancelClick={onClose}
        />
      ) : (
        <DefaultModal
          open={open}
          isLoading={isLoading}
          headerIconLabel={t('iconDescription')}
          headerTitle={t(`title.${getTranslateType()}`)}
          headerSubtitle={customMessage ?? t(`message.${getTranslateType()}`)}
          labelButtonAction={
            <SC.LabelButtonContent>
              <p>{t(`actions.confirm.${getTranslateType()}`)}</p>
            </SC.LabelButtonContent>
          }
          onActionClick={onActionClick}
          onCancelClick={onClose}
        />
      )}
    </SC.Container>
  );
}
