import { useEffect, useMemo, useState } from 'react';
import { IExpenseInput } from '@containers/Expenses/context/types';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { IRoute } from '@containers/Expenses/context/types/expenseById';
import { useDistance } from '@containers/Expenses/hooks/useDistance';
import { useDistanceHelper } from '@shared/hooks/useDistanceHelper';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import { IExpenseRefFields } from '../../ExpenseRegisterForm/ExpenseFormFields/hooks/useFieldTypeConfig';
import { useDeleteEmptyRoutes } from './useDeleteEmptyRoutes';

interface IProps {
  route: IRoute;
  fields: FieldArrayWithId<IExpenseInput, 'route.waypoints', 'id'>[];
  ratePerKm: number;
}

interface IGetTotalValueDistanceProps {
  _distance: number;
  ratePerKm: number;
}

export function useDistanceManagement({ route, fields, ratePerKm }: IProps) {
  const { setValue } = useFormContext<IExpenseInput>();
  const [distance, setDistance] = useState<number>(0);
  const { getDistance, isLoading: distanceIsLoading } = useDistance();
  const deleteEmptyRoutes = useDeleteEmptyRoutes();
  const { distanceConvertMetersInKM } = useDistanceHelper();

  function getTotalValueDistance({ _distance, ratePerKm }: IGetTotalValueDistanceProps): number {
    return _distance * ratePerKm;
  }

  async function getDistanceRoutes(_routes: IEstablishment[]) {
    const response = await getDistance({
      variables: {
        waypoints: _routes,
      },
    });

    const distanceInMeters: number = response?.data?.getDistance?.distance;
    const _distance: number = distanceConvertMetersInKM(distanceInMeters);

    setValue(IExpenseRefFields.value, getTotalValueDistance({ _distance, ratePerKm }) as never);
    setValue('route.distance', _distance);
    setValue('route.ratePerKm', ratePerKm);

    setDistance(_distance || 0);
  }

  function validateDistanceCall(_waypoints: IEstablishment[]) {
    const _routes = route?.waypoints?.map(_waypoint => `${_waypoint?.lat}-${_waypoint?.lng}`);
    const _fields = _waypoints?.map(_waypoint => `${_waypoint?.lat}-${_waypoint?.lng}`);
    return _fields?.length > 1 && JSON.stringify(_routes) !== JSON.stringify(_fields);
  }

  useEffect(() => {
    const _waypoints: IEstablishment[] = deleteEmptyRoutes([...fields]);
    if (validateDistanceCall(_waypoints)) {
      getDistanceRoutes(_waypoints);
    }
  }, [JSON.stringify(fields)]);

  useMemo(() => {
    if (route) {
      setValue('route.distance', route.distance);
      setValue('route.ratePerKm', route.ratePerKm);
      setDistance(route.distance || 0);
    }
  }, [JSON.stringify(route)]);

  return {
    distance,
    distanceIsLoading,
  };
}
