import { useState } from 'react';
import { IConfigurationPaymentMethod } from '@containers/Customizations/context/types/paymentMethodTypes';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function usePaymentMethodSwitchActive() {
  const t = useTranslate('organisms.customizationsPaymentMethods.modal.paymentMethod.feedbacks');
  const [listIdsLoading, setListIdsLoading] = useState<IConfigurationPaymentMethod['id'][]>([]);
  const { mutation } = useMutate('v1/payment-methods', async error => {
    const response = await error.metadata.json();
    showToast({
      message: response?.message ?? t('error'),
      type: 'error',
    });
  });
  const togglePaymentMethods = async (toggle: boolean, ids: string[]) => {
    const result = await mutation.trigger(
      {
        path: `toggle`,
        options: {
          method: 'POST',
          body: JSON.stringify({ active: toggle, ids: ids }),
        },
      },
      {
        onSuccess: () =>
          showToast({
            message: t('success'),
            type: 'success',
          }),
      },
    );
    return result;
  };

  async function _setPaymentMethodActive(ids: IConfigurationPaymentMethod['id'][], active: boolean) {
    setListIdsLoading([...listIdsLoading, ...ids]);
    try {
      await togglePaymentMethods(active, ids);
    } finally {
      const _listIdsLoading = listIdsLoading;
      ids.forEach(id => {
        const index = _listIdsLoading.findIndex(_id => _id === id);
        _listIdsLoading.splice(index, 1);
      });

      setListIdsLoading(_listIdsLoading);
    }
  }

  return {
    _setPaymentMethodActive,
    listIdsLoading,
    isLoading: mutation.isMutating,
  };
}
