import { useEffect, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getCategoriesQuery } from '../context/queries';
import { IGetCategories } from '../context/types/types';

export const useAllCategories = () => {
  const {
    loading,
    data: categoriesData,
    networkStatus,
  } = useQuery<IGetCategories>(getCategoriesQuery, { notifyOnNetworkStatusChange: true });
  const [selectFieldFormatCategories, setSelectFieldFormatCategories] = useState([]);

  useEffect(() => {
    const newArray = categoriesData?.getAllLegacyCategories.categories?.map(category => ({
      label: category.description,
      value: category.id,
    }));

    setSelectFieldFormatCategories(newArray);
  }, [categoriesData]);

  return {
    isLoadingCategories: loading || networkStatus === NetworkStatus.loading,
    categories: categoriesData?.getAllLegacyCategories?.categories ?? [],
    selectFieldFormatCategories,
  };
};
