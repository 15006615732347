import { EAuthZPermission } from '@shared/types/authZPermission';

import { useLicenseFeatures } from '../licenseFeatures/useLicenseFeatures';
import { usePlatformPermissions } from './usePlatformPermissions';

export function useCheckKmIsAllowed() {
  const { checkPlatformPermission } = usePlatformPermissions();
  const { checkLicenseFeature } = useLicenseFeatures();

  return checkPlatformPermission(EAuthZPermission.EXPENSES_MILEAGE_ACTION) && checkLicenseFeature('BASIC_MILEAGE');
}
