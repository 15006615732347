import {
  IConfigurationPaymentMethod,
  IConfigurationPaymentMethodMutation,
} from '@containers/Customizations/context/types/paymentMethodTypes';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { updateCache } from './usePaymentMethodsMutate';

export function useUpdatePaymentMethod() {
  const { mutation } = useMutate('v1/payment-methods');
  const t = useTranslate('organisms.customizationsPaymentMethods.modal.paymentMethod.feedbacks.edit');

  async function getUpdatePaymentMethod(currentPaymentMethod: IConfigurationPaymentMethod) {
    try {
      const body = {
        paymentMethod: {
          externalCode: currentPaymentMethod.externalCode,
          name: currentPaymentMethod.name,
          active: currentPaymentMethod.active,
          type: currentPaymentMethod.type,
          mileage: currentPaymentMethod.mileage,
          refundable: currentPaymentMethod.refundable,
        },
      } as IConfigurationPaymentMethodMutation;

      const response = await mutation.trigger(
        {
          path: `${currentPaymentMethod.id}`,
          options: {
            method: 'PATCH',
            body: JSON.stringify(body.paymentMethod),
          },
        },
        {
          revalidate: false,
          populateCache: updateCache,
          onSuccess: () => {
            showToast({
              message: t('success'),
              type: 'success',
            });
          },
        },
      );
      const success: boolean = response;
      return success;
    } catch (_error) {
      showToast({
        message: _error?.message ?? t('error'),
        type: 'error',
      });
    }
  }

  return {
    getUpdatePaymentMethod,
    success: mutation.error == undefined,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
