import { useEffect, useState } from 'react';
import { Switch } from '@atoms/Switch';
import { ICategoryLimitPolicy, ILimitGrid } from '@containers/Customizations/context/types/limits';
import { useCategoryLimitPolicyMutate } from '@containers/Customizations/hooks/useCategoryLimitPolicyMutate';
import { EFieldType } from '@containers/Expenses/context/types';
import { ECriterionLimit, ICurrency } from '@containers/Settings/context/types';
import { SelectField, SelectFieldProps } from '@flash-tecnologia/hros-web-ui-v2';
import { Help } from '@molecules/Help';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { FieldFactory } from '@organisms/FieldFactory';
import { defaultCurrency, defaultCurrencyCode, defaultCurrencyId, defaultCurrencySymbol } from '@shared/constant';
import { useTranslation } from 'react-i18next';

import { EAddLimitType } from '../SelectLimitTypeModal';
import { LimitToCategoryModalFooter } from './LimitToCategoryModalFooter/LimitToCategoryModalFooter';
import * as SC from './styled';

export interface AddLimitToCategoryModal {
  currencyOptions: SelectFieldProps[];
  currencies: ICurrency[];
  categories: { value: number; label: string }[];
  type: EAddLimitType;
  open: boolean;
  policyId: number;
  limit?: ILimitGrid;
  isEdit?: boolean;
  handleSuccessModal({
    openSelectLimitModal,
    openAddLimitModal,
    openAddLimitModalSuccess,
    categories,
  }: {
    openAddLimitModal?: boolean;
    openAddLimitModalSuccess?: boolean;
    openSelectLimitModal?: boolean;
    categories?: string[];
  }): void;
}

export const AddLimitToCategoryModal = ({
  currencyOptions,
  currencies,
  categories,
  type,
  open,
  policyId,
  limit,
  isEdit,
  handleSuccessModal,
}: AddLimitToCategoryModal) => {
  const { t } = useTranslation();
  const { createLimit, updateLimit, isLoading } = useCategoryLimitPolicyMutate();
  const [valueInput, setValueInput] = useState(0);
  const [currency, setCurrency] = useState({
    id: defaultCurrencyId,
    symbol: defaultCurrencySymbol,
    code: defaultCurrencyCode,
  });
  const [allowValueGreaterThanLimit, setAllowValueGreaterThanLimit] = useState(false);
  const [commentRequired, setCommentRequired] = useState(false);
  const [receiptRequired, setReceiptRequired] = useState(false);
  const [minimumInvoiceProofValue, setMinimumInvoiceProofValue] = useState(0);
  const [criterion, setCriterion] = useState(ECriterionLimit.DAY);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const translationBase: string = 'molecules.addLimitToCategoryModal';
  const criterionOptions = Object.values(ECriterionLimit).map(_criterion => ({
    label: t(`molecules.limitsForm.criterion.${_criterion.toLowerCase()}`),
    value: _criterion,
  }));

  useEffect(() => {
    !isEdit && clearInputs();
  }, [open, isEdit]);

  useEffect(() => {
    if (limit) {
      const _currencyFound = currencies?.find(currency => currency.code === limit.currency);
      const _categoriesSelected = limit?.categoryLimitsPolicy?.map(category =>
        categories?.find(c => c.value == category.policyCategoryId),
      );
      setCurrency(_currencyFound);
      setCategoriesSelected(_categoriesSelected);
      setValueInput(limit.value);
      setAllowValueGreaterThanLimit(!limit.allowValueGreaterThanLimit);
      setCommentRequired(limit.commentRequired);
      setCriterion(limit.criterion as ECriterionLimit);
      setReceiptRequired(limit.receiptRequired);
      setMinimumInvoiceProofValue(limit.minimumInvoiceProofValue);
    }
  }, [limit, currencies]);

  const handleCurrency = (value: string) => {
    const currency = currencies.find(currency => currency.id == parseInt(value));
    setCurrency(currency);
  };

  const handleClick = async () => {
    const payload: ICategoryLimitPolicy = {
      active: true,
      policyId,
      currency: currency.code,
      receiptRequired,
      allowValueGreaterThanLimit: !allowValueGreaterThanLimit,
      commentRequired,
      criterion,
      value: valueInput ?? 0,
      minimumInvoiceProofValue,
      categoryLimitsPolicy: categoriesSelected.map(category => ({
        policyCategoryId: Number(category.value),
        categoryDescription: category.label,
      })),
    };

    if (isEdit) {
      await updateLimit({ ...payload, limitPolicyId: limit.limitPolicyId }, policyId);
      handleSuccessModal({});
    } else {
      await createLimit(payload, policyId);
      handleSuccessModal({
        openAddLimitModalSuccess: true,
        categories: categoriesSelected.map(category => category.label),
      });
    }
    clearInputs();
  };

  const clearInputs = () => {
    setValueInput(0);
    setCurrency(defaultCurrency);
    setAllowValueGreaterThanLimit(false);
    setCommentRequired(false);
    setReceiptRequired(false);
    setMinimumInvoiceProofValue(0);
    setCriterion(ECriterionLimit.DAY);
    setCategoriesSelected([]);
  };

  function checkDisableAction(): boolean {
    let disabledAction: boolean = true;

    disabledAction = !valueInput || !categoriesSelected?.length;

    if (!disabledAction && receiptRequired) {
      disabledAction = !minimumInvoiceProofValue;
    }

    return disabledAction;
  }

  function checkSelectIsMultiple(): boolean {
    return type === EAddLimitType.SHARED;
  }

  return (
    <DefaultModal
      open={open}
      headerTitle={t(`${translationBase}.${type}Title`)}
      headerSubtitle={t(`${translationBase}.${type}Subtitle`)}
      footerCustom={
        <LimitToCategoryModalFooter
          isLoading={isLoading}
          isEdit={isEdit}
          type={type}
          tooltipMessageCustom={
            receiptRequired && !minimumInvoiceProofValue ? t(`${translationBase}.tooltipAction.receiptRequired`) : null
          }
          actionDisabled={checkDisableAction()}
          onActionClick={handleClick}
          onCancelClick={() =>
            handleSuccessModal({
              openAddLimitModal: false,
              openAddLimitModalSuccess: false,
              openSelectLimitModal: !isEdit,
            })
          }
        />
      }
      isActionLoading={isLoading}
      onCloseClick={() =>
        handleSuccessModal({ openAddLimitModal: false, openAddLimitModalSuccess: false, openSelectLimitModal: false })
      }>
      <SC.FormContainer>
        <SC.SelectsContainer>
          <SelectField
            variant="standard"
            label={t(`${translationBase}.categoryLabel`)}
            options={categories}
            onSelectChange={(_, eventValue) =>
              setCategoriesSelected(checkSelectIsMultiple() ? eventValue : [eventValue])
            }
            value={checkSelectIsMultiple() ? categoriesSelected : categoriesSelected?.[0]}
            multiple={checkSelectIsMultiple()}
          />

          <SelectField
            variant="standard"
            label={t(`${translationBase}.releaseCriterionLabel`)}
            options={criterionOptions}
            onSelectChange={(_, eventValue) => setCriterion(eventValue.value)}
            value={criterion}
          />

          <SC.CurrencyContainer>
            <SelectField
              variant="standard"
              defaultValue={currency.id || defaultCurrencyCode}
              label={t(`${translationBase}.currencyLabel`)}
              options={currencyOptions}
              onSelectChange={(_, eventValue) => {
                handleCurrency(eventValue.value);
              }}
              value={currency.id}
              defaultChecked
              searchable
            />
            <FieldFactory<{ value: number }>
              fieldType={EFieldType.NUMBER}
              label={t(`${translationBase}.limitValueLabel`)}
              onChange={setValueInput}
              isCurrencyValue
              error={false}
              value={valueInput}
              allowsEditing
            />
          </SC.CurrencyContainer>
        </SC.SelectsContainer>
        <SC.SettingsContainer>
          <SC.SettingsTitle variant="body4">{t(`${translationBase}.settings`)}</SC.SettingsTitle>
          <SC.SettingsGroup>
            <SC.FormControlLabel
              id="input-is-active"
              control={<Switch />}
              label={t(`${translationBase}.allowValueGreaterThanLimitLabel`)}
              onChange={(_, checked) => setAllowValueGreaterThanLimit(checked)}
              checked={allowValueGreaterThanLimit}
            />
            <Help description={t(`${translationBase}.allowValueGreaterThanLimitHelp`)} placement={'top-end'} />
          </SC.SettingsGroup>
          <SC.FormControlLabel
            id="input-is-active"
            control={<Switch />}
            label={t(`${translationBase}.commentRequiredLabel`)}
            onChange={(_, checked) => setCommentRequired(checked)}
            checked={commentRequired}
          />
          <SC.GroupContainer>
            <SC.FormControlLabel
              id="input-is-active"
              control={<Switch />}
              label={t(`${translationBase}.proofMandatory`)}
              onChange={(_, checked) => setReceiptRequired(checked)}
              checked={receiptRequired}
            />
            {receiptRequired && (
              <SC.SettingsGroup>
                <FieldFactory<{ value: number }>
                  fieldType={EFieldType.NUMBER}
                  label={t(`${translationBase}.minimumInvoiceProofValueLabel`)}
                  onChange={setMinimumInvoiceProofValue}
                  isCurrencyValue
                  prefixCurrencyValue={currency.symbol}
                  allowsEditing
                  error={false}
                  value={minimumInvoiceProofValue}
                />
                <Help description={t(`${translationBase}.minimumInvoiceProofValueHelp`)} />
              </SC.SettingsGroup>
            )}
          </SC.GroupContainer>
        </SC.SettingsContainer>
      </SC.FormContainer>
    </DefaultModal>
  );
};
