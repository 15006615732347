import { ISubsidiary } from '@containers/Subsidiaries/context/types/subsidiaries';
import useTranslate from '@locale/useTranslate';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { mutate } from 'swr';

export function useDeleteBatchSubsidiaries(subsidiaries: ISubsidiary[]) {
  const subsidiaryMutate = useMutate('v1/subsidiaries');
  const t = useTranslate();

  async function deleteSubsidiaries(ids: ISubsidiary['id'][]) {
    const params = [
      {
        key: 'subsidiaryIds',
        value: ids.toString(),
      },
    ];
    const response = await subsidiaryMutate.mutation.trigger(
      {
        path: '',
        parameters: params,
        options: {
          method: 'DELETE',
        },
      },
      {
        revalidate: false,
      },
    );
    response?.forEach(subsidiary => {
      const subsidiaryName = subsidiaries?.find(_subsidiary => _subsidiary.id == subsidiary.id)?.name ?? '';

      if (!subsidiary.deleted) {
        showToast({
          title: t('subsidiaries.modal.exclusion.feedbacks.error.title'),
          message: t('subsidiaries.modal.exclusion.feedbacks.error.title', { name: subsidiaryName }),
          type: 'error',
        });
        return;
      }

      showToast({
        title: t('subsidiaries.modal.exclusion.feedbacks.success.title', { name: subsidiaryName }),
        message: t('subsidiaries.modal.exclusion.feedbacks.success.message'),
        type: 'success',
      });
    });

    mutate(key => typeof key === 'string' && key.includes('v1/subsidiaries?page='));
  }
  return {
    deleteSubsidiaries,
    deletedSubsidiaries: subsidiaryMutate.mutation.data,
    error: subsidiaryMutate.mutation.error,
    isLoading: subsidiaryMutate.mutation.isMutating,
  };
}
