import { useMemo } from 'react';
import { EReportStatus } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { SelectOption, serializeSelectOptions } from '@shared/helpers/selectOptions';
import { useIntegrations } from '@shared/hooks/useIntegrations';

export const useReportStatusesPermissions = () => {
  const { t } = useTranslation();
  const { hasFinancialIntegration } = useIntegrations();

  const reportStatus = useMemo(() => {
    const _options = [];
    Object.keys(EReportStatus).forEach(status => {
      if (
        status === EReportStatus.AWAITING_FINANCIAL_PROCESSING ||
        status === EReportStatus.PROCESSING_PAYMENT ||
        status === EReportStatus.PROCESSING_PAYMENT_ERROR
      ) {
        if (hasFinancialIntegration) {
          _options.push({
            id: EReportStatus[status],
            name: t(`organisms.financialReportGrid.statusOptions.${EReportStatus[status]?.toLowerCase()}`),
          });
        }
      } else {
        _options.push({
          id: EReportStatus[status],
          name: t(`organisms.financialReportGrid.statusOptions.${EReportStatus[status]?.toLowerCase()}`),
        });
      }
    });

    const options: SelectOption[] = serializeSelectOptions({
      options: _options,
    });

    return options;
  }, [hasFinancialIntegration]);

  return reportStatus;
};
