import { useMemo } from 'react';
import { EExpenseType, IExpenses } from '@containers/Expenses/context/types';
import { useTranslation } from '@locale/Translator';

export function useExpenseTitle(expense: IExpenses) {
  const { t } = useTranslation();

  function getExpenseTitle(): string {
    if (expense?.type === EExpenseType.ORIGIN_DESTINATION) {
      return t('organisms.expenseDetails.expenseTitle.originDestination');
    }

    if (expense?.type === EExpenseType.ODOMETER) {
      return t('organisms.expenseDetails.expenseTitle.odometer');
    }

    return expense?.category?.description ?? expense.referenceId;
  }

  return useMemo(() => getExpenseTitle(), [expense]);
}
