import { IReport } from '@containers/Reports/context/types';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import { useCurrentUrlBase } from '../useCurrentUrlBase';

export function useReportDetailsDriver() {
  const navigate = useNavigate();
  const getCurrentUrlBase = useCurrentUrlBase();

  function getUrlBaseDetails(): string {
    const urlBase: string = getCurrentUrlBase();

    if (urlBase.includes('/reports')) {
      return `${urlBase}/details`;
    }

    return `${urlBase}/reports/details`;
  }

  function navigateReportDetails(reportId: IReport['id'], state?: NavigateOptions['state']) {
    const urlBaseDetails: string = getUrlBaseDetails();

    navigate(`${urlBaseDetails}/${reportId}`, state);
  }

  return navigateReportDetails;
}
