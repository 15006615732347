import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ReportActionsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs};
`;

export const ActionButton = styled(IconButton).attrs({
  variant: 'line',
  size: 'medium',
})``;
