import { useState } from 'react';
import emptyImage from '@assets/images/empty_state.png';
import { useAttachWithdrawToReport } from '@containers/Withdraw/hooks/dataSources/useAttachWithdrawToReport';
import { useWithdrawalsToAttach } from '@containers/Withdraw/hooks/dataSources/useWithdrawalsToAttach';
import { Checkbox, ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useTheme } from 'styled-components';

import { AttachWithdrawModalSkeleton } from './AttachWithdrawModalSkeleton';
import * as SC from './styled';

interface IProps {
  onClose(): void;
  reportByIdUpDate(): void;
  isOpen: boolean;
  reportId: number;
}

export const AttachWithdrawModal = ({ onClose, isOpen, reportId, reportByIdUpDate }: IProps) => {
  const t = useTranslate('withdraw.attachWithdrawModal');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { attach, isLoading: attachIsLoading } = useAttachWithdrawToReport(reportId);
  const { withdrawals, isLoading: withdrawalsIsLoading } = useWithdrawalsToAttach();
  const { getValueWithCurrency } = useValueFormatter();
  const { colors } = useTheme();

  function onItemClick(itemId: string) {
    const isIdSelected = selectedItems.includes(itemId);
    setSelectedItems(isIdSelected ? selectedItems.filter(id => id !== itemId) : [...selectedItems, itemId]);
  }

  async function onAttach() {
    await attach(selectedItems);
    reportByIdUpDate();
    onClose();
  }

  return (
    <DefaultModal
      open={isOpen}
      isLoading={attachIsLoading}
      onCloseClick={onClose}
      onCancelClick={onClose}
      onActionClick={onAttach}
      headerTitle={t('title')}
      actionDisabled={selectedItems?.length === 0}
      headerSubtitle={t('subtitle')}
      labelButtonAction={
        <>
          {t('add')} <SC.PlusIcon />
        </>
      }>
      {withdrawalsIsLoading ? (
        <AttachWithdrawModalSkeleton />
      ) : (
        <SC.Container>
          <SC.Content>
            {withdrawals?.length > 0 ? (
              withdrawals.map((_withdraw, index) => {
                return (
                  <SC.Item key={index}>
                    <Checkbox
                      onChange={() => onItemClick(_withdraw.userIdCard)}
                      checked={selectedItems.some(id => id === _withdraw.userIdCard)}
                    />
                    <SC.ContainerInfos>
                      <SC.ContainerIcon>
                        <ShapeIcon name="IconCreditCard" size={40} variant="default" color={colors.primary} />
                        <SC.CardNumber>{_withdraw.cardNumber}</SC.CardNumber>
                      </SC.ContainerIcon>
                      <Typography variant="body3">{getValueWithCurrency({ value: _withdraw.value })}</Typography>
                    </SC.ContainerInfos>
                  </SC.Item>
                );
              })
            ) : (
              <SC.EmptyContainer>
                <SC.Image src={emptyImage} />
                <SC.DescriptionEmpty variant="body3">{t('empty')}</SC.DescriptionEmpty>
              </SC.EmptyContainer>
            )}
          </SC.Content>
        </SC.Container>
      )}
    </DefaultModal>
  );
};
