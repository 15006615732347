import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacings.xs,
}));

export const RouteContainer = styled.div(({ theme }) => ({
  width: `400px`,
  padding: theme.spacings.xs,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  borderRadius: theme.borders.radius.s,
  marginBottom: theme.spacings.xs,
}));

export const DetailsContainer = styled.div`
  width: 400px;
`;
