import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { EFlowPriorities } from '../../components/organisms/ReorderingModal/ReorderingModal';

export function usePriorityCustomApprovalFlow() {
  const t = useTranslate('approvalFlows.feedbacks.edit');
  const { mutation } = useMutate('v1/approvals', onError);

  function onError() {
    return showToast({
      message: t('error'),
      type: 'error',
    });
  }

  async function handleSave(approvalFlows: EFlowPriorities[]) {
    const response = await mutation.trigger({
      path: 'priorities',
      options: {
        method: 'PATCH',
        body: JSON.stringify(approvalFlows),
      },
    });

    showToast({
      message: t('success'),
      type: 'success',
    });

    return response;
  }

  return {
    handleSave,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
