import emptyImage from '@assets/images/empty_state.png';
import noOptionsImage from '@assets/images/empty_state_2.png';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { useTranslation } from '@locale/Translator';
import { Autocomplete } from '@molecules/AutoComplete/Autocomplete';

import { useDataManagementEstablishments } from './hooks/useDataManagementEstablishments';
import { useSerializeOption } from './hooks/useSerializeOption';
import * as SC from './styled';

export type EstablishmentFieldConfig = {
  allowSearchPlaces: boolean;
};

export interface EstablishmentsProps {
  value?: IEstablishment;
  label?: string;
  helperText?: string;
  onSelectChange?(establishment: IEstablishment): void;
  disableClearable?: boolean;
  error?: boolean;
  config?: EstablishmentFieldConfig;
}

export const Establishments = ({
  value: establishmentExpense,
  label,
  onSelectChange,
  error,
  disableClearable,
  helperText,
  config,
}: EstablishmentsProps) => {
  const { t } = useTranslation();
  const {
    options,
    hasEstablishments,
    selectedEstablishment,
    isLoading,
    shouldSearchFromGoogleMaps,
    onSelectedEstablishmentClick,
    onInputValueChange,
  } = useDataManagementEstablishments({ onSelectChange, establishmentExpense, config });
  const selectedOption = useSerializeOption(selectedEstablishment);

  return (
    <SC.Container isSelected={Boolean(selectedEstablishment)} className={'establishments-field'}>
      <Autocomplete
        error={error}
        disableClearable={disableClearable}
        fixedMessageOption={{
          title: t('organisms.establishments.fixedMessage.title'),
          caption: t('organisms.establishments.fixedMessage.caption'),
          image: <SC.Image src={emptyImage} />,
        }}
        isLoading={isLoading}
        noOptionContent={{
          title: t(`organisms.establishments.${!hasEstablishments ? 'empty' : 'fixedMessage'}.title`),
          caption: t(`organisms.establishments.${!hasEstablishments ? 'empty' : 'fixedMessage'}.caption`),
          image: !hasEstablishments ? <SC.NoOptionsImage src={noOptionsImage} /> : <SC.Image src={emptyImage} />,
        }}
        label={label || t('organisms.establishments.fieldLabel')}
        value={selectedOption?.value}
        options={options}
        onSelectChange={onSelectedEstablishmentClick}
        onInputChange={onInputValueChange}
        disabledFilter={shouldSearchFromGoogleMaps}
        selectedItem={selectedEstablishment}
      />
      {error && (
        <SC.ErrorText className={`${error && 'warning'}`} variant="caption">
          <SC.Icon name="IconInfoCircle" size={18} />
          {helperText}
        </SC.ErrorText>
      )}
    </SC.Container>
  );
};
