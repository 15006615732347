import { IReport } from '@containers/Reports/context/types';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';

import * as SC from './styled';

interface IProps {
  period: IReport['activePeriod'];
}

export function ReportPeriodGrid({ period }: IProps) {
  const { getDateOnlyFormatter } = useDateFormatter();

  return (
    <SC.Description>
      {getDateOnlyFormatter(period.startDate)}
      {period.endDate && ` - ${getDateOnlyFormatter(period.endDate)}`}
    </SC.Description>
  );
}
