import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getKmRate } from '../context/queries/km';
import { IGetRateKmRequest, IGetRateKmResponse } from '../context/types';

interface IProps {
  date: string;
  projectId?: number;
  skip: boolean;
}

export const useRatePerKm = ({ skip = false, date = new Date().toString(), projectId }: IProps) => {
  const { getFormatDateUTC } = useDateFormatter();

  const { loading, data, error } = useQuery<IGetRateKmResponse, IGetRateKmRequest>(getKmRate, {
    variables: { date: getFormatDateUTC(date), projectId },
    skip,
  });

  return {
    ratePerKm: data?.getKmRate || 0,
    isLoading: loading,
    error,
  };
};
