import { useState } from 'react';
import { IDelegation } from '@containers/Customizations/context/types/settings/delegation';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { SelectData } from '@shared/hooks/useSelectsOptions';
import { mutate } from 'swr';

export function useDelegateUsersMutate(userOptions: SelectData['options']) {
  const t = useTranslate('delegation.configuration');
  const { mutation: mutateDisable } = useMutate('v1/user-delegation');
  const { mutation: mutateDelete } = useMutate('v1/user-delegation-approval');
  const [listIdsLoading, setListIdsLoading] = useState<IDelegation['id'][]>([]);

  async function disableDelegations(ids: IDelegation['id'][]) {
    setListIdsLoading([...listIdsLoading, ...ids]);

    try {
      const _listIdsLoading = listIdsLoading;

      ids.forEach(id => {
        const index = _listIdsLoading?.findIndex(_id => _id === id);
        _listIdsLoading?.splice(index, 1);
      });

      const result = await mutateDisable.trigger({
        path: `disable`,
        options: {
          method: 'PUT',
          body: JSON.stringify({ delegateUserIds: ids }),
        },
      });

      mutate(key => typeof key === 'string' && key.includes('v1/user-delegation?userId'));
      setListIdsLoading(_listIdsLoading);
      return result;
    } catch (err) {
      setListIdsLoading([]);
      console.error(err);
    }
  }

  async function deleteDelegations(ids: IDelegation['id'][]) {
    setListIdsLoading([...listIdsLoading, ...ids]);

    try {
      const _listIdsLoading = listIdsLoading;

      ids.forEach(id => {
        const index = _listIdsLoading?.findIndex(_id => _id === id);
        _listIdsLoading?.splice(index, 1);
      });

      const result = await mutateDelete.trigger({
        path: '',
        parameters: ids.map(_ => ({
          key: 'userDelegationApprovalIds[]',
          value: _,
        })),
        options: {
          method: 'DELETE',
        },
      });

      result.forEach(_delegation => {
        if (_delegation.error) {
          showToast({
            message: `${t('labelPeople')}: ${
              userOptions?.find(user => String(user.value) === _delegation.userDelegationApprovalId)?.label || ''
            } - ${_delegation.message}`,
            type: 'error',
          });
        }
      });

      setListIdsLoading(_listIdsLoading);
      mutate(key => typeof key === 'string' && key.includes('v1/user-delegation-approval?userId'));
      return result;
    } catch (err) {
      setListIdsLoading([]);
      console.error(err);
    }
  }

  return {
    isLoading: mutateDelete?.isMutating || mutateDisable.isMutating,
    error: mutateDelete?.error || mutateDisable.error,
    listIdsLoading,
    disableDelegations,
    deleteDelegations,
  };
}
