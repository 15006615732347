import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { SubsidiariesList } from '@pages/PlatformSettings/Subsidiaries/Subsidiaries';
import { SubsidiaryRegister } from '@pages/PlatformSettings/Subsidiaries/SubsidiaryRegister';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function SubsidiariesRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['INTERMEDIATE_SUBSIDIARY']}
          routePermissions={[EAuthZPermission.CONFIGURATION_SUBSIDIARIES]}
        />
      ),
      errorElement: (
        <RouterErrorBoundary title="Erro ao carregar o módulo de Configurações de Filiais" moduleName="Filias" />
      ),
      children: [
        {
          path: Router.getSubsidiaries(),
          element: <SubsidiariesList />,
        },
        {
          path: Router.getSubsidiariesRegister(),
          element: <SubsidiaryRegister />,
        },
        {
          path: Router.getSubsidiariesRegister(':id'),
          element: <SubsidiaryRegister />,
        },
      ],
    },
  ];
}
