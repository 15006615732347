import { Accordion, Button, Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap ${({ theme }) => theme.spacings.xs};
    max-width: 1200px;

    .MuiAccordionSummary-gutters {
      align-items: flex-start;
    }
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap ${({ theme }) => theme.spacings.xs3};
`;

export const Content = styled.div`
  width: 100%;
`;

export const DescriptionLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700 !important;
`;

export const AccordionSection = styled(Accordion)`
  width: 100%;
`;

export const AccordionHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap ${({ theme }) => theme.spacings.xs};
    width: 100%;
`;

export const AccordionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700 !important;
`;

export const AccordionCaption = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400 !important;
`;

export const AddButton = styled(Button)`
  width: 100%;
`;

export const AddButtonContent = styled.div`
    display: flex;
    gap ${({ theme }) => theme.spacings.xs5};
`;

export const AddIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[100]};
`;

export const AccordionHeaderContainerDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap ${({ theme }) => theme.spacings.xs4};
`;
