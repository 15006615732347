import { IEstablishment } from '@containers/Expenses/context/types/establishments';

import { Option } from '../Autocomplete';
import { useOptionsEstablishments } from './useOptionsEstablishments';

interface Props {
  inputValue: string;
  establishments: IEstablishment[];
  selectedEstablishment: IEstablishment;
}

export function useFilterOptions({ inputValue, establishments, selectedEstablishment }: Props): Option[] {
  const establishmentsOptions = useOptionsEstablishments(establishments);

  function isIncludePlace() {
    return inputValue !== selectedEstablishment?.place
      ? establishmentsOptions?.filter(
          option =>
            option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
            option?.caption?.toLowerCase()?.includes(inputValue?.toLowerCase()),
        )
      : establishmentsOptions;
  }

  return isIncludePlace();
}
