import { OdometerUpload } from '@containers/Expenses/components/molecules/OdometerUpload';
import { IExpenseInput, ReceiptFile } from '@containers/Expenses/context/types';
import { TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import * as SC from './styled';

export interface IOdometerFieldProps {
  variant: 'start' | 'end';
  file: ReceiptFile;
  fieldValue: number;
  onUploadClick: (file: ReceiptFile[]) => void;
  onFieldChange: (value: number) => void;
}

export function OdometerField({ variant, fieldValue, file, onUploadClick, onFieldChange }: IOdometerFieldProps) {
  const { t } = useTranslation();
  const { control } = useFormContext<IExpenseInput>();

  return (
    <SC.ItemContainer>
      <SC.InputKmContainer>
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: t('organisms.expenseRegisterForm.mandatoryField'),
            },
          }}
          name={`odometer.${variant}.value`}
          defaultValue={fieldValue}
          render={({ field, fieldState: { error } }) => {
            return (
              <NumericFormat
                customInput={TextField}
                disabled={false}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={0}
                fixedDecimalScale
                onFocus={event => event.target.select()}
                defaultValue={field?.value || null}
                onValueChange={values => {
                  field.onChange(values?.floatValue);
                  onFieldChange?.(values?.floatValue);
                }}
                fullWidth
                value={field?.value || null}
                label={t(`organisms.expenseRegisterForm.fieldLabels.odometer.${variant}`)}
                error={Boolean(error)}
                helperText={error?.message}
              />
            );
          }}
        />
      </SC.InputKmContainer>
      <SC.UploadContainer>
        <OdometerUpload onChange={onUploadClick} odometerFile={file} />
      </SC.UploadContainer>
    </SC.ItemContainer>
  );
}
