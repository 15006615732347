import { IconTypes, Menu, MenuOptionType, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { DropdownItem } from '@molecules/DropdownItem';

import { ActionButtonItem } from './ActionButtonItem/ActionButtonItem';

type TActionButton = {
  key: string;
  name: string;
  icon?: IconTypes;
  disabled?: boolean;
  allowed?: boolean;
  onClick(key: string): void;
};

type TProps = {
  isLoading?: boolean;
  disable?: boolean;
  actionName: string;
  actionIcon?: IconTypes;
  actionsList?: TActionButton[];
  onClick?(): void;
};

export function ActionsButton({ actionName, actionIcon, actionsList, disable = false, isLoading, onClick }: TProps) {
  function getActions(): MenuOptionType[] {
    const actions: MenuOptionType[] = [];

    actionsList?.forEach(_action => {
      if (_action.allowed ?? true) {
        actions.push({
          children: <DropdownItem description={_action.name} icon={_action.icon ?? null} disabled={_action.disabled} />,
          onClick: () => !_action.disabled && _action.onClick(_action.key),
        });
      }
    });
    return actions;
  }

  if (isLoading) {
    return <Skeleton variant="rounded" height={60} width={actionName.length * 10 + 28} />;
  }

  if (!actionsList?.length && onClick) {
    return <ActionButtonItem actionName={actionName} actionIcon={actionIcon} onClick={onClick} />;
  }

  return (
    <Menu disabled={disable} options={getActions()} type="default">
      <ActionButtonItem actionName={actionName} actionIcon={actionIcon} onClick={onClick} />
    </Menu>
  );
}
