import { Loader } from '@flash-tecnologia/hros-web-ui-v2';
import { Carousel } from '@molecules/Carousel';

import * as SC from './styled';

export interface Image {
  src?: string;
  id?: string | number;
  mime?: string;
}

export interface ImagesModalProps {
  /**
   * List of items to display
   * @default 'exemple images'
   */
  images?: Image[];

  /**
   * If true, show carousel points
   * @default false
   */
  showDots?: boolean;

  /**
   * If true, show louder
   * @default false
   */
  isLoading?: boolean;

  /**
   * Description that appears below the loader
   */
  loadingDescription?: string;

  /**
   *  Event click close modal
   *
   */
  onCloseModalClick: () => void;

  initialIndex?: number;
}

export const ImagesModal = ({
  images,
  isLoading = false,
  loadingDescription,
  showDots = false,
  onCloseModalClick,
  initialIndex,
}: ImagesModalProps) => {
  return (
    <SC.ModalContainer>
      <SC.ModalBackgroundMask />
      <SC.ModalContent>
        <SC.ModalButtonClose
          key={'close-button-modal-images'}
          onClick={onCloseModalClick}
          icon="IconX"
          variant="filled"
          size="small"
        />
        {!isLoading ? (
          <SC.CarouselContainer>
            <Carousel
              items={images?.map((image, index) => (
                <SC.ImageContainer key={`image-repeipt-${index}`}>
                  {!image.mime?.includes('application/pdf') ? (
                    <SC.Image src={image.src} crossOrigin="anonymous" />
                  ) : (
                    <>
                      <SC.Frame src={image.src} />
                    </>
                  )}
                </SC.ImageContainer>
              ))}
              showDots={showDots}
              initialIndex={initialIndex}
            />
          </SC.CarouselContainer>
        ) : (
          <SC.LoaderContainer>
            <SC.LoaderContent>
              <Loader size="medium" variant="primary" />
              {loadingDescription && (
                <SC.DescriptionLoading variant="body3">{loadingDescription}</SC.DescriptionLoading>
              )}
            </SC.LoaderContent>
          </SC.LoaderContainer>
        )}
      </SC.ModalContent>
    </SC.ModalContainer>
  );
};
