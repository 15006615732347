import { useReports } from '@containers/Expenses/hooks/useReports';
import { useTranslation } from '@locale/Translator';
import { SectionTitle } from '@molecules/SectionTitle';
import { expensesBaseUrl } from '@shared/constant';
import { useNavigate } from 'react-router-dom';

import { ReportsListRow } from '../../molecules/ReportsListRow';
import * as SC from './styled';

export const LatestReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { reports, reportsIsLoading } = useReports({
    page: 1,
    limit: 4,
  });

  return (
    <div>
      <SectionTitle
        title={t('pages.expenses.latestReports')}
        linkLabel={t('pages.expenses.seeAllReports')}
        showLinkIcon
        onClick={() => navigate(`${expensesBaseUrl}/reports`)}
      />
      <SC.ReportsContainer>
        <ReportsListRow reports={reports} isLoading={reportsIsLoading} />
      </SC.ReportsContainer>
    </div>
  );
};
