import { useState } from 'react';
import emptyImage from '@assets/images/empty_state.png';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { useAdvances } from '@containers/Advances/hooks/dataSources/useAdvances';
import { useAttachAdvanceToReport } from '@containers/Advances/hooks/dataSources/useAttachAdvanceToReport';
import { Checkbox, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useDateFormatter } from '@shared/hooks';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useTranslation } from 'react-i18next';

import { AttachExpenseModalSkeleton } from './AttachExpenseModalSkeleton';
import * as SC from './styled';

interface IProps {
  onClose(): void;
  isOpen: boolean;
  reportId: number;
}

export const AttachAdvancesModal = ({ onClose, isOpen, reportId }: IProps) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);
  const { handleAttach, isLoading: attachIsLoading } = useAttachAdvanceToReport();
  const { advances: advancesToBind, isLoading: advancesToBindIsLoading } = useAdvances({
    page: 0,
    isOpenToAttach: true,
  });
  const { getValueWithCurrency } = useValueFormatter();
  const onItemClick = (itemId: number) => {
    const isIdSelected = selectedItems.includes(itemId);
    setSelectedItems(isIdSelected ? selectedItems.filter(id => id !== itemId) : [...selectedItems, itemId]);
  };
  const { getDateOnlyFormatter } = useDateFormatter();

  async function onAttach() {
    await handleAttach(selectedItems, reportId);
    onClose();
  }

  return (
    <DefaultModal
      open={isOpen}
      isLoading={attachIsLoading}
      onCloseClick={onClose}
      onCancelClick={onClose}
      onActionClick={onAttach}
      headerTitle={t('organisms.attachAdvancesModal.title')}
      headerSubtitle={t('organisms.attachAdvancesModal.subtitle')}
      labelButtonAction={
        <>
          {t('organisms.attachAdvancesModal.add')} <SC.PlusIcon />
        </>
      }>
      {advancesToBindIsLoading ? (
        <AttachExpenseModalSkeleton />
      ) : (
        <SC.Container>
          <SC.ExpensesContainer>
            {advancesToBind?.length > 0 ? (
              advancesToBind.map((_advance, index) => {
                return (
                  <SC.ExpenseItem key={index}>
                    <Checkbox
                      onChange={() => onItemClick(_advance.id)}
                      checked={selectedItems.find(id => id == _advance.id)}
                    />
                    <SC.ExpenseNameContainer>
                      <ShapeIcon icon="IconCurrencyDollar" size="small" variant="secondary" />
                      <Typography variant="body3">{_advance.referenceId || '-'}</Typography>
                    </SC.ExpenseNameContainer>
                    <Typography variant="body3">
                      {getValueWithCurrency({ value: _advance.value, currencyPrefix: _advance.currencyPrefix })}
                    </Typography>
                    <Typography variant="body3">
                      {(_advance.advanceDate && getDateOnlyFormatter(_advance.advanceDate)) || '-'}
                    </Typography>
                  </SC.ExpenseItem>
                );
              })
            ) : (
              <SC.EmptyContainer>
                <SC.Image src={emptyImage} />
                <SC.DescriptionEmpty variant="body3">
                  {t('organisms.attachAdvancesModal.emptyExpenses')}
                </SC.DescriptionEmpty>
              </SC.EmptyContainer>
            )}
          </SC.ExpensesContainer>
        </SC.Container>
      )}
    </DefaultModal>
  );
};
