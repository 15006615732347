import { IconButton, Icons, Menu, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.m};
`;

export const EmailContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${props => props.theme.spacings.xs5};
`;

export const Email = styled(Typography)`
  color: ${props => props.theme.colors.neutral[60]};
`;
export const MailIcon = styled(Icons)`
  color: ${props => props.theme.colors.neutral[70]};
  fill: transparent;
  width: 14px;
`;

export const ThContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .menu-base-container-custom-theme {
    > div {
      :first-child {
        display: flex;
      }
    }
  }
`;

export const IconDots = styled(IconButton).attrs({
  icon: 'IconDots',
  size: 'small',
  variant: 'line',
})`
  transform: rotate(90deg);
`;

export const ThDescription = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 700;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const Description = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const SortByButton = styled(Menu).attrs({
  type: 'default',
  transformOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
})`
  .MuiPaper-elevation {
    margin: 5px 0;
  }
`;

export const ActionsButton = styled(Menu).attrs({
  type: 'default',
  transformOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
})``;

export const TdContent = styled.div`
  display: flex;
  align-items: center;
`;

export const ClientInfosContainer = styled.div`
  padding-left: ${p => p.theme.spacings.xs3};
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const Icon = styled(Icons).attrs({
  size: 22,
  fill: 'transparent',
})`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.spacings.xs4};
`;
