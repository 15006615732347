import { useState } from 'react';
import { IReportExpense, ISummaryReport } from '@containers/Expenses/context/types';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { NewReportModal } from '@containers/Reports/components/molecules/NewReportModal';
import { useAttachExpenseToReport } from '@containers/Reports/hooks/useAttachExpenseToReport';
import { useReportMutate } from '@containers/Reports/hooks/useReportMutate';
import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useReportDetailsDriver } from '@shared/hooks/navigation/useReportDetailsDriver';
import { useTracking } from '@shared/hooks/useTracking';
import { EAccessScreen } from '@shared/types';
import { useTranslation } from 'react-i18next';

import { ModalReportSelection } from '../ModalReportSelection';
import { LinkedReportSkeleton } from './LinkedReportSkeleton';
import * as SC from './styled';

export interface LinkedReportProps {
  report?: IReportExpense;
  isLoading: boolean;
  expenseId: IExpense['id'];
}

export const LinkedReport = ({ report, isLoading, expenseId }: LinkedReportProps) => {
  const { t } = useTranslation();
  const trackingEvent = useTracking();

  const [openReportSelection, setOpenReportSelection] = useState(false);
  const [showNewReportModal, setShowNewReportModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ISummaryReport>(null);
  const { attachExpenseToReport, loading: isLoadingAttachExpenseToReport } = useAttachExpenseToReport();
  const { createReport, isLoading: isLoadingCreateReport } = useReportMutate();
  const navigateReportDetails = useReportDetailsDriver();

  function closeModal() {
    setShowNewReportModal(false);
    setOpenReportSelection(false);
  }

  const handleReportCreation = async report => {
    trackingEvent('report_create_clicked');

    const response = await createReport(report);
    await attachExpenseToReport({
      reportId: response.id,
      expenseIds: [expenseId],
      accessScreen: EAccessScreen.EXPENSE_DETAILS,
    });
    closeModal();
  };

  const handleReportSelection = async () => {
    await attachExpenseToReport({
      reportId: selectedReport?.id,
      expenseIds: [expenseId],
      accessScreen: EAccessScreen.EXPENSE_DETAILS,
    });
    closeModal();
  };

  if (isLoading) {
    return <LinkedReportSkeleton />;
  }

  return (
    <SC.BorderedWrappingContainer>
      {report ? (
        <SC.InfoContainer>
          <SC.LinkReport variant="default" onClick={() => navigateReportDetails(report?.id)}>
            {report.name} <Icons fill="transparent" name="IconExternalLink" size={24} />
          </SC.LinkReport>
          <SC.Description variant="body4">{t('molecules.linkedReport.report')}</SC.Description>
        </SC.InfoContainer>
      ) : (
        <LinkButton variant="default" onClick={() => setOpenReportSelection(true)}>
          {t('molecules.linkedReport.add')}
        </LinkButton>
      )}
      <ModalReportSelection
        selectedReportId={selectedReport?.id}
        open={openReportSelection}
        actionIsLoading={isLoadingAttachExpenseToReport}
        onActionClick={handleReportSelection}
        onCancelClick={closeModal}
        onSelectReport={setSelectedReport}
        onCreateReport={() => setShowNewReportModal(true)}
      />
      <NewReportModal
        open={showNewReportModal}
        isLoading={isLoadingAttachExpenseToReport || isLoadingCreateReport}
        actionType={'create'}
        onCancelClick={closeModal}
        onActionClick={handleReportCreation}
      />
    </SC.BorderedWrappingContainer>
  );
};
