import { useState } from 'react';
import { useDeleteCustomApprovalFlows } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useDeleteCustomApprovalFlows';
import { useDuplicateCustomApprovalFlows } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useDuplicateCustomApprovalFlows';
import { useToggleCustomApprovalFlows } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useToggleCustomApprovalFlows';
import { useTargetCustomApprovalFlow } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/useTargetCustomApprovalFlow';
import { ISummaryApprovalFlow } from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import { IActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { IBatchActionsActions } from '@organisms/Grid/BatchActions/BatchActions';
import { useSelectionRows } from '@organisms/Grid/BatchActions/hooks/useSelectionRows';
import { approvalFlowsBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { TProjectsConfirmationActions } from '../CustomApprovalFlowsConfirmationActionsModal/CustomApprovalFlowsConfirmationActionsModal';

enum EActions {
  EDIT = 'edit',
  ACTIVE = 'active',
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
}

enum EIcons {
  EDIT = 'IconPencil',
  ACTIVE = 'IconToggleLeft',
  DUPLICATE = 'IconCopy',
  DELETE = 'IconTrash',
}

export function useCustomApprovalFlowsActionsGrid(approvalFlows: ISummaryApprovalFlow[]) {
  const t = useTranslate('approvalFlows.grid');
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [confirmationType, setConfirmationType] = useState<TProjectsConfirmationActions>();
  const [isBatchAction, setIsBatchAction] = useState<boolean>(false);
  const [costCenterSelected, setPaymentMethodSelected] = useState<ISummaryApprovalFlow>();
  const [approvalFlowsSelectedIds, setProjectsSelectedIds] = useState<ISummaryApprovalFlow['id'][]>();
  const [costCenterSelectedActive, setPaymentMethodSelectedActive] = useState<boolean>();
  const { isSelectedAll, selectedItems, toggleSelectAll, onSelectItemClick, unSelectAllClick } =
    useSelectionRows<ISummaryApprovalFlow>(approvalFlows);
  const {
    isLoading: toggleIsLoading,
    listIdsLoading,
    setToggleCustomApprovalFlows,
  } = useToggleCustomApprovalFlows(approvalFlows);
  const { isLoading: isLoadingDelete, deleteCustomApprovalFlows } = useDeleteCustomApprovalFlows(approvalFlows);
  const {
    isLoading: isLoadingDuplicate,
    idIsLoading,
    duplicateCustomApprovalFlows,
  } = useDuplicateCustomApprovalFlows();
  const target = useTargetCustomApprovalFlow();

  function onShowConfirmationActionsModal(): void {
    setShowConfirmationModal(_open => !_open);
  }

  async function onConfirmationActionModal() {
    if (confirmationType === 'deactivation') {
      await setToggleCustomApprovalFlows(approvalFlowsSelectedIds, costCenterSelectedActive ?? false);
      selectedItems.length && unSelectAllClick();
    } else {
      await deleteCustomApprovalFlows(approvalFlowsSelectedIds);
      selectedItems.length && unSelectAllClick();
    }
    onShowConfirmationActionsModal();
  }

  function getSelectedProjects(_id: ISummaryApprovalFlow['id']): ISummaryApprovalFlow {
    return approvalFlows?.find(_costCenter => _costCenter?.id === _id);
  }

  function onActionClick(_actionKey: EActions, _id?: ISummaryApprovalFlow['id']) {
    const _approvalFlowsSelectedIds: ISummaryApprovalFlow['id'][] = _id ? [_id] : selectedItems;
    const _isBatchAction: boolean = _approvalFlowsSelectedIds.length > 1;
    const _approvalFlows: ISummaryApprovalFlow = !_isBatchAction
      ? getSelectedProjects(_approvalFlowsSelectedIds[0])
      : null;

    setPaymentMethodSelectedActive(_approvalFlows ? !_approvalFlows.active : null);
    setProjectsSelectedIds(_approvalFlowsSelectedIds);
    _approvalFlows && setPaymentMethodSelected(_approvalFlows);
    setIsBatchAction(_approvalFlowsSelectedIds.length > 1);

    switch (_actionKey) {
      case EActions.ACTIVE:
        setConfirmationType('deactivation');
        if ((_approvalFlowsSelectedIds.length === 1 && _approvalFlows.active) || _approvalFlowsSelectedIds.length > 1) {
          onShowConfirmationActionsModal();
        } else {
          setToggleCustomApprovalFlows([_approvalFlows.id], !_approvalFlows.active);
        }
        break;

      case EActions.DELETE:
        setConfirmationType('exclusion');
        onShowConfirmationActionsModal();
        break;

      case EActions.DUPLICATE:
        duplicateCustomApprovalFlows(_id);
        selectedItems.length && unSelectAllClick();
        break;

      default:
        setConfirmationType(null);
        navigate(`${approvalFlowsBaseURL}/${target}/register/${_id}`, {
          state: {
            totalApprovalFlows: approvalFlows?.length || 0,
          },
        });
        break;
    }
  }

  function getActionsRow(_id: ISummaryApprovalFlow['id']): IActionsRow[] {
    return (
      approvalFlows?.length &&
      Object.keys(EActions).map(key => ({
        id: _id,
        key: EActions[key],
        allowed: true,
        iconName: EIcons[key],
        name: t(
          `body.actions.${
            EActions[key] === EActions.ACTIVE && getSelectedProjects(_id)?.active ? 'deactivate' : EActions[key]
          }`,
        ),
        onClick: (_key, id) => onActionClick(_key as EActions, id as ISummaryApprovalFlow['id']),
      }))
    );
  }

  function getTooltipBatchActions(key: EActions): string {
    if (key === EActions.ACTIVE) {
      const approvalFlowsActiveList = selectedItems?.filter(selectedId =>
        approvalFlows?.some(costCenter => costCenter.id === selectedId && !costCenter.active),
      );
      return approvalFlowsActiveList?.length > 0 ? t('header.batchActions.tooltips.active') : '';
    }
    return '';
  }

  function getBatchActions(): IBatchActionsActions[] {
    const batchActions: IBatchActionsActions[] = [];

    approvalFlows?.length &&
      Object.keys(EActions).forEach(key => {
        batchActions.push({
          key: EActions[key],
          allowed: EActions[key] !== EActions.EDIT && EActions[key] !== EActions.DUPLICATE,
          disabled: Boolean(getTooltipBatchActions(EActions[key])) && EActions[key] !== EActions.DELETE,
          iconName: EIcons[key],
          name: t(`header.batchActions.${EActions[key] === EActions.ACTIVE ? 'deactivate' : EActions[key]}`),
          tooltipMessage: getTooltipBatchActions(EActions[key]),
          onClick: _key => onActionClick(_key as EActions),
        });
      });

    return batchActions;
  }

  return {
    costCenterSelected,
    showConfirmationModal,
    confirmationType,
    isSelectedAll,
    selectedItems,
    isBatchAction,
    isLoadingAction: isLoadingDelete || toggleIsLoading || isLoadingDuplicate,
    listIdsLoading: idIsLoading ? [...listIdsLoading, idIsLoading] : listIdsLoading,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    unSelectAllClick,
    onSelectItemClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
  };
}
