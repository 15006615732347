import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ReferenceIdContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const MainInformationContainer = styled.div`
  width: fit-content;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;
  margin-bottom: ${props => props.theme.spacings.s};
`;

export const ReferenceIdDescription = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600 !important;
  color: ${props => props.theme.colors.neutral[50]};
  display: flex;
  align-items: center;
`;

export const ReportTitle = styled(Typography).attrs({
  variant: 'headline6',
})`
  color: ${props => props.theme.colors.neutral[30]};
  margin-right: ${props => props.theme.spacings.xs3};
`;

export const ReportInfosSummary = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacings.m};
`;

export const TitleMainSummary = styled(Typography).attrs({
  variant: 'headline5',
})`
  color: ${props => props.theme.colors.neutral.dark.dark3};
`;

export const TitleSummary = styled(Typography).attrs({
  variant: 'headline8',
})`
  color: ${props => props.theme.colors.neutral.dark.dark3};
`;

export const SubtitleMainSummary = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600 !important;
  color: ${props => props.theme.colors.neutral.dark.dark5};
`;

export const SubtitleSummary = styled(Typography).attrs({
  variant: 'body4',
})`
  font-weight: 600 !important;
  color: ${props => props.theme.colors.neutral.dark.dark5};
`;

export const ReportInfosContent = styled.div`
  width: fit-content;
  min-width: 870px;
  padding: ${props => props.theme.spacings.xs};
  border: 1px solid ${props => props.theme.colors.neutral.light.light2};
  border-radius: ${props => props.theme.borders.radius.s};
`;

export const ReportInfosShowButtonContainer = styled.div`
  margin-top: ${props => props.theme.spacings.xs};
`;

export const SummaryContentDescription = styled.div``;

export const ReportInfosTableContainer = styled.div`
  margin-top: ${props => props.theme.spacings.s};
  margin-bottom: ${props => props.theme.spacings.s};
`;

export const ReportDescription = styled.div`
  padding: ${props => props.theme.spacings.xs4};
  padding-left: ${props => props.theme.spacings.m};
  margin-bottom: ${props => props.theme.spacings.s};
`;

export const ReportDescriptionTitleContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light.light2};
  padding: ${props => props.theme.spacings.xs4};
  padding-left: ${props => props.theme.spacings.xs};
  margin-top: ${props => props.theme.spacings.s};
`;

export const Container = styled.div`
  width: 100%;
`;

export const HeaderContainerActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;
