import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { useCustomApprovalFlows } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useCustomApprovalFlows';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { Search } from '@molecules/GridFilters/Search/Search';
import { ActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { CustomApprovalFlowsConfirmationActionsModal } from './CustomApprovalFlowsConfirmationActionsModal/CustomApprovalFlowsConfirmationActionsModal';
import { useCustomApprovalFlowsActionsGrid } from './hooks/useCustomApprovalFlowsActionsGrid';
import * as SC from './styled';

enum EAccessor {
  SELECT_ALL = 'selectAll',
  PRIORITY = 'priority',
  NAME = 'name',
  APPROVAL_TYPE = 'approvalType',
  STATUS = 'status',
  DESCRIPTION = 'description',
  ACTIONS = 'actions',
}

interface IProps {
  setTotalApprovalFlows?: Dispatch<SetStateAction<number>>;
}

enum EApprovalType {
  MANUAL = 'manual',
  AUTOMATIC_APPROVAL = 'automaticApproval',
  AUTOMATIC_REFUSAL = 'automaticRefusal',
}

export function CustomApprovalFlowsGrid({ setTotalApprovalFlows }: IProps) {
  const t = useTranslate('approvalFlows.grid');
  const [searchName, setSearchName] = useState<string>('');
  const { approvalFlows, isLoading } = useCustomApprovalFlows();
  const [currentApprovalFlows, setCurrentApprovalFlows] = useState(approvalFlows);
  const {
    showConfirmationModal,
    isSelectedAll,
    selectedItems,
    confirmationType,
    isBatchAction,
    isLoadingAction,
    listIdsLoading,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    unSelectAllClick,
    onSelectItemClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
  } = useCustomApprovalFlowsActionsGrid(currentApprovalFlows);

  function getColumns(): IGridColumn[] {
    return Object.values(EAccessor).map(accessor => ({
      accessor,
      disableSortBy: true,
      Header: (
        <ColumnHeader
          isLoading={isLoading && !Boolean(searchName) && !Boolean(approvalFlows.length)}
          isLoadingAction={isLoading}
          description={t(`header.columns.${accessor}`)}
          isSelectedAll={accessor === EAccessor.SELECT_ALL}
          onSelectAllClick={toggleSelectAll}
          selectAllChecked={isSelectedAll}
        />
      ),
      sticky: accessor === EAccessor.ACTIONS && 'right',
    }));
  }

  function getRows(): Record<EAccessor, React.ReactNode>[] {
    return currentApprovalFlows?.map(_approvalFlow => {
      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(_approvalFlow?.id)}
            checked={selectedItems.some(_id => _id === _approvalFlow?.id)}
          />
        ),
        priority: _approvalFlow.priority,
        name: _approvalFlow.name,
        approvalType: t(`body.approvalType.${EApprovalType[_approvalFlow.type]}`),
        status: (
          <StatusTag showStatusIcons variant={_approvalFlow.active ? 'success' : 'error'}>
            {t(`${_approvalFlow.active ? 'active' : 'inactive'}`)}
          </StatusTag>
        ),
        description: _approvalFlow.description ?? '-',
        actions: (
          <ActionsRow
            referenceId={_approvalFlow.id}
            actions={getActionsRow(_approvalFlow.id)}
            isLoading={listIdsLoading?.includes(_approvalFlow.id)}
          />
        ),
      };
    });
  }

  function getFilterItemsList() {
    return approvalFlows?.filter(_approvalFlow =>
      _approvalFlow?.name?.toLowerCase()?.includes(searchName?.toLowerCase()),
    );
  }

  useEffect(() => {
    if (approvalFlows && !isLoading) {
      setCurrentApprovalFlows(Boolean(searchName) ? getFilterItemsList() : approvalFlows || []);
      !searchName && setTotalApprovalFlows?.(approvalFlows?.length || 0);
    }
  }, [approvalFlows, searchName]);

  return (
    <SC.Container>
      <Search
        label={t('searchFieldLabel')}
        onChange={data => {
          selectedItems.length && unSelectAllClick();
          setSearchName(data);
        }}
        isLoading={isLoading && !Boolean(searchName) && !Boolean(approvalFlows.length)}
      />
      <Grid
        columns={getColumns()}
        rows={getRows()}
        batchActions={getBatchActions()}
        isLoading={isLoading}
        hasPagination={false}
        numberItemsSelected={selectedItems?.length || 0}
        rowsLoading={Boolean(approvalFlows.length) ? approvalFlows.length : 4}
      />
      {showConfirmationModal && (
        <CustomApprovalFlowsConfirmationActionsModal
          open={showConfirmationModal}
          confirmationType={confirmationType}
          onActionClick={onConfirmationActionModal}
          onClose={onShowConfirmationActionsModal}
          isBatchAction={isBatchAction}
          isLoading={isLoadingAction}
        />
      )}
    </SC.Container>
  );
}
