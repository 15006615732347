import { SharedExpenseActions } from '@containers/Expenses/components/organisms/ExpenseActions';
import { ExpensesPreview } from '@containers/Expenses/components/organisms/ExpensesPreview';
import { LatestReport } from '@containers/Expenses/components/organisms/LatestReport';
import { TipsModalController } from '@molecules/TipsModalController';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';
import { useTranslation } from 'react-i18next';

export default function ExpensesOnly() {
  const { t } = useTranslation();
  useTrackingScreen('expense_list_screen');

  return (
    <BasePage
      headerPage={{
        title: t('pages.expenses.pageTitle'),
        actions: <SharedExpenseActions />,
        faqType: 'expenses',
        showDelegateInfo: true,
      }}>
      <LatestReport />
      <ExpensesPreview />
      <TipsModalController page="addExpense" />
    </BasePage>
  );
}
