import { useState } from 'react';

import { IExpenses } from '../../../../context/types/expenses';

export interface UseSelectionRowsProps {
  expenses: IExpenses[];
}

export const useSelectionRows = ({ expenses }: UseSelectionRowsProps) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const isSelectedAll: boolean = selectedItems?.length == expenses?.length;

  function selectAll() {
    setSelectedItems(expenses.map(e => e.id));
  }

  function unSelectAll() {
    setSelectedItems([]);
  }

  function toggleSelectAll() {
    if (isSelectedAll) unSelectAll();
    else selectAll();
  }

  function onSelectItemClick(itemId: number) {
    const isIdSelected = selectedItems.includes(itemId);

    if (isIdSelected) {
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  }

  return {
    selectedItems,
    isSelectedAll,
    onSelectItemClick,
    selectAll,
    unSelectAll,
    toggleSelectAll,
  };
};
