import { useState } from 'react';
import { EReportStatus } from '@containers/Expenses/context/types';
import { Button, MenuOptionType } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { DropdownItem } from '@molecules/DropdownItem';
import { expensesAdvancesBaseURL } from '@shared/constant';
import { IReportNavigate } from '@shared/hooks/navigation/useExpenseRegisterDriver';
import { useDelegationAllowed } from '@shared/hooks/permissions/delegation/useDelegationAllowed';
import { useAdvancesPermissions } from '@shared/hooks/permissions/systemPermissions/useAdvancesPermissions';
import { useNavigate } from 'react-router-dom';

import { AttachAdvancesModal } from '../AttachAdvancesModal';
import * as SC from './styled';

interface IProps {
  report?: IReportNavigate;
}

export const AdvancesActions = ({ report }: IProps) => {
  const { delegationEnabled } = useDelegationAllowed();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { edit: editingIsAllowed, link: linkingIsAllowed } = useAdvancesPermissions();
  const allowedActions: MenuOptionType[] = getAllowedActions();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function getAllowedActions(): MenuOptionType[] {
    const _actions: MenuOptionType[] = [];

    if ((report?.status === EReportStatus.OPEN || !report?.status) && !delegationEnabled) {
      if (editingIsAllowed) {
        _actions.push({
          children: <DropdownItem description={t('pages.advances.advance')} />,
          onClick: () => navigate(`${expensesAdvancesBaseURL}/register`, { state: { linkedReport: report } }),
        });
      }

      if (linkingIsAllowed) {
        _actions.push({
          children: <DropdownItem description={t('pages.advances.attach')} />,
          onClick: () => setModalIsOpen(true),
        });
      }
    }

    return _actions;
  }

  return allowedActions?.length ? (
    <>
      <SC.ActionsButton options={allowedActions}>
        <Button size="small" variant="primary">
          {t('pages.advances.add')}
          <SC.IconAdd name="Add" />
        </Button>
      </SC.ActionsButton>
      {modalIsOpen && (
        <AttachAdvancesModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} reportId={report?.id} />
      )}
    </>
  ) : (
    <></>
  );
};
