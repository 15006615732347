import { useState } from 'react';
import { EAdvanceStatus } from '@containers/Advances/context/types/advance';
import {
  EAdvanceFieldType,
  EAdvanceKeyFields,
  EAdvanceRefFields,
  IAdvanceInput,
  IFieldControl,
} from '@containers/Advances/context/types/formFields';
import { useApproversAdvanceById } from '@containers/Advances/hooks/dataSources/useApproversAdvanceById';
import { ApproverItemsDrawer } from '@containers/Reports/components/organisms/ReportInfos/ApproversByReport/ApproversDrawer/ApproverItemsDrawer/ApproverItemsDrawer';
import { EReportStatus } from '@containers/Reports/context/types';
import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Skeleton } from '@mui/material';
import { FieldFactory } from '@organisms/FieldFactory';
import { RegistrationFormContainerWithReport } from '@organisms/RegistrationFormContainerWithReport';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useSelectsOptions } from '@shared/hooks/useSelectsOptions';
import { Controller, useFormContext } from 'react-hook-form';

import * as SC from './styled';

interface IProps {
  isLoading: boolean;
  advanceReport?: {
    name?: string;
    id?: number;
    description?: string;
    status?: EReportStatus;
  };
  advanceId: number;
  advanceStatus: EAdvanceStatus;
  user?: string;
  fields: IFieldControl[];
}

export function AdvanceRegisterForm({ isLoading, advanceReport, advanceId, advanceStatus, user, fields }: IProps) {
  const translation = useTranslation();
  const t = (key: string): string => translation.t(`pages.advanceRegister.${key}`);
  const { control, setValue } = useFormContext<IAdvanceInput>();
  const {
    currenciesOptions,
    clientsOptions,
    projectsOptions,
    paymentMethodsOptions,
    costCentersOptions,
    approversOptions,
    isLoadingOptions,
  } = useSelectsOptions({
    hasClients: getHasOptions(EAdvanceKeyFields.client),
    hasCostCenters: getHasOptions(EAdvanceKeyFields.costCenter),
    hasCurrencies: getHasOptions(EAdvanceKeyFields.currency),
    hasPaymentMethods: getHasOptions(EAdvanceKeyFields.paymentType),
    hasProjects: getHasOptions(EAdvanceKeyFields.project),
    hasApprovers: getHasOptions(EAdvanceKeyFields.approver),
  });
  const currentPath = useCurrentPath();
  const [toView] = useState(currentPath.includes('details'));
  const { approvers, isLoading: approversIsLoading } = useApproversAdvanceById(advanceId, toView);
  const allIsLoading = isLoading || isLoadingOptions;

  function getHasOptions(key) {
    return fields && fields.find(field => field.key === key)?.active;
  }

  function getIsDisabledLinkedActions() {
    return (
      (advanceReport?.status &&
        advanceReport?.status !== EReportStatus.OPEN &&
        advanceReport?.status !== EReportStatus.SUBMITTED) ||
      advanceStatus === EAdvanceStatus.FINISHED
    );
  }

  function getFieldOptions(key: EAdvanceKeyFields) {
    switch (key) {
      case EAdvanceKeyFields.client:
        return clientsOptions;
      case EAdvanceKeyFields.costCenter:
        return costCentersOptions;
      case EAdvanceKeyFields.currency:
        return currenciesOptions;
      case EAdvanceKeyFields.paymentType:
        return paymentMethodsOptions;
      case EAdvanceKeyFields.project:
        return projectsOptions;
      case EAdvanceKeyFields.approver:
        return approversOptions;
      default:
        return { options: null };
    }
  }

  function fieldsAllowedEdit(allowsEditing: boolean): boolean {
    if (!isLoading && !advanceStatus) return true;
    return !toView && advanceStatus === EAdvanceStatus.PENDING && allowsEditing;
  }

  return (
    <RegistrationFormContainerWithReport
      isLoading={allIsLoading}
      report={advanceReport?.id ? advanceReport : null}
      target="ADVANCED"
      targetId={advanceId}
      isDisableAction={getIsDisabledLinkedActions()}
      onSelectReportClick={_report => setValue('reportId', _report?.id)}>
      <SC.Content>
        <SC.FieldsContainer isLoading={allIsLoading}>
          <SC.FieldContainer
            fieldName={'requester'}
            hidden={!(currentPath.includes('accounting') || currentPath.includes('approvals'))}
            hasUser={Boolean(user)}>
            {allIsLoading ? (
              <Skeleton variant="rounded" height={60} width={'100%'} />
            ) : (
              <TextField label={t('fields.requester')} value={user} disabled />
            )}
          </SC.FieldContainer>
          {!allIsLoading
            ? fields?.map((_field, index) => (
                <Controller
                  key={`advance-filed-${index}`}
                  name={EAdvanceRefFields[_field.key]}
                  control={control}
                  rules={{
                    required: {
                      value: _field.required && _field.active,
                      message: t('validation.requiredField'),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <SC.FieldContainer
                        fieldName={_field.key}
                        hidden={_field.key === EAdvanceKeyFields.report || !_field.active}
                        hasUser={Boolean(user)}>
                        <FieldFactory<IAdvanceInput>
                          fieldType={EAdvanceFieldType[_field.key]}
                          isCurrencyValue={EAdvanceRefFields[_field.key] === EAdvanceRefFields.value}
                          error={Boolean(error)}
                          helperText={error?.message}
                          label={t(`fields.${_field.key}`)}
                          allowsEditing={fieldsAllowedEdit(_field.allowsEditing)}
                          {...field}
                          value={field.value ?? ''}
                          options={getFieldOptions(_field.key as EAdvanceKeyFields)?.options ?? null}
                          isLoading={allIsLoading}
                          limitDate={{
                            after: new Date(),
                          }}
                        />
                      </SC.FieldContainer>
                    );
                  }}
                />
              ))
            : Object.values(EAdvanceFieldType)
                .filter((_, index) => index <= 6)
                .map((field, index) => (
                  <FieldFactory key={`field-factory-${index}`} fieldType={field} isLoading={true} />
                ))}
          {toView && approvers?.length && !approversIsLoading ? (
            <SC.FieldContainer fieldName="">
              <SC.ApproversContainer>
                <Typography variant="body4">{t('approvers')}</Typography>
                <ApproverItemsDrawer approvers={approvers} />
              </SC.ApproversContainer>
            </SC.FieldContainer>
          ) : (
            <></>
          )}
        </SC.FieldsContainer>
      </SC.Content>
    </RegistrationFormContainerWithReport>
  );
}
