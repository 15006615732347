import { ShapeIcon } from '@atoms/ShapeIcon';
import { Dropzone, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { DropzoneFile } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types';
import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

interface IBulkImportProps {
  fileName: string;
  hasFile: boolean;
  accept?: string[];
  title?: string;
  onDropzoneChange(file: DropzoneFile[]): void;
}

export const BulkImportDropzone = ({ fileName, hasFile, accept, title, onDropzoneChange }: IBulkImportProps) => {
  const { t } = useTranslation();
  return !hasFile ? (
    <Dropzone
      title={title ?? t('molecules.bulkImportDropzone.sendFile.yourFile')}
      onChange={file => onDropzoneChange(file)}
      accept={accept ?? ['xlsx', 'xls']}
    />
  ) : (
    <SC.FileLoadContainer>
      <ShapeIcon icon="IconCheck" size="medium" variant="success" />
      <SC.TextButtonContainer>
        <SC.TextContainer>
          <SC.FileLoadTitle variant="body3">{fileName}</SC.FileLoadTitle>
          <SC.FileLoadSubTitle variant="body3">
            {t('molecules.bulkImportDropzone.loadFile.subTitle')}
          </SC.FileLoadSubTitle>
        </SC.TextContainer>
        <SC.DeleteButton aria-label="delete file" onClick={() => onDropzoneChange(null)} icon="IconTrash" />
      </SC.TextButtonContainer>
      <SC.ProgressBarContainer>
        <SC.ProgressBar variant="determinate" value={100} />
        <Typography variant="body3">100%</Typography>
      </SC.ProgressBarContainer>
    </SC.FileLoadContainer>
  );
};
