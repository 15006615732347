import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: top;
`;

export const IconContainer = styled.div`
  height: 16px;
  width: 16px;
`;
