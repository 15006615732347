import { ReactNode } from 'react';
import { FilterType } from '@atoms/FilterFactory';
import { IAnalyticsFilterFieldInput } from '@containers/Analytics/context/types/filters';
import { IDrawerFilter, IFilterValues } from '@molecules/FiltersDrawer';
import { GridFilters } from '@molecules/GridFilters';

interface IProps {
  filters: IDrawerFilter[];
  filtersIsLoading: boolean;
  filtersSelected: IAnalyticsFilterFieldInput[];
  customizeReport: ReactNode;
  maxFiltersShown?: number;
  isAllDisabled?: boolean;
  showSkeletonFilterItems?: number;
  showMoreFiltersButton?: boolean;
  applyAllFilters: (_filters: IFilterValues[]) => void;
}

export function AnalyticsFilters({
  filters,
  filtersIsLoading,
  filtersSelected,
  showSkeletonFilterItems,
  maxFiltersShown = 5,
  isAllDisabled = false,
  customizeReport,
  showMoreFiltersButton = true,
  applyAllFilters,
}: IProps) {
  function getValueFilter(_filter: IAnalyticsFilterFieldInput): IFilterValues['value'] {
    if (_filter?.value?.options) return _filter.value.options;
    if (_filter?.value?.bool) return _filter.value.bool;
    if (_filter?.value?.text) return _filter.value.text;

    if (_filter?.value?.startDate || _filter?.value?.endDate)
      return {
        from: _filter.value?.startDate ? new Date(_filter.value.startDate) : null,
        to: _filter.value?.endDate ? new Date(_filter.value.endDate) : null,
      };

    if (_filter?.value?.min || _filter?.value?.max)
      return _filter?.value?.min || _filter?.value?.max ? [_filter?.value?.min, _filter?.value?.max] : null;

    return null;
  }

  function serializeFiltersData(): IFilterValues[] {
    return filtersSelected?.map(_filter => {
      return {
        key: _filter.key,
        value: getValueFilter(_filter),
      };
    });
  }

  function checkIsRange(_filterData?: IDrawerFilter): boolean {
    return _filterData?.type === FilterType.CURRENCY_RANGE || _filterData?.type === FilterType.RANGE;
  }

  function getSelectedOptions(currentValue: IFilterValues['value'], type: FilterType): string[] {
    if (type === FilterType.BOOLEAN && currentValue === true) {
      return ['on'];
    }
    return currentValue;
  }

  function serializeFiltersValues() {
    return filters.map(_filterData => {
      const currentFilter = filtersSelected.find(_filterValue => _filterValue.key == _filterData.key);
      const currentValue: IFilterValues['value'] = getValueFilter(currentFilter);
      return {
        ..._filterData,
        dateRange: currentValue,
        min: checkIsRange(_filterData) && 0,
        max: checkIsRange(_filterData) && 20000,
        selectedOptions: getSelectedOptions(currentValue, _filterData.type),
        value: currentValue,
      };
    });
  }

  return (
    <GridFilters
      filters={serializeFiltersValues()}
      isLoading={filtersIsLoading}
      maxFiltersShown={maxFiltersShown}
      showMoreFiltersButton={showMoreFiltersButton}
      showSearchField={false}
      onApplyAllFilters={data => applyAllFilters(data)}
      showSkeletonFilterItems={showSkeletonFilterItems}
      onClearAllFilters={() => applyAllFilters([])}
      selectedFilters={serializeFiltersData() as IFilterValues[]}
      isAllDisabled={isAllDisabled}
      customTag={customizeReport}
    />
  );
}
