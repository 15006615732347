import { StatusTag } from '@atoms/StatusTag';
import { EApprovalStatus, IApproversTarget } from '@containers/Reports/context/types/approvers';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from '../styled';

interface IProps {
  approver: IApproversTarget;
}

export function TagApprover({ approver }: IProps) {
  function getVariant() {
    switch (approver.status) {
      case EApprovalStatus.APPROVED:
        return 'success';
      case EApprovalStatus.REJECTED:
        return 'error';
      default:
        return 'neutral';
    }
  }

  function getIcon() {
    switch (approver.status) {
      case EApprovalStatus.APPROVED:
        return <Icons name="IconCheck" size={16} />;
      case EApprovalStatus.REJECTED:
        return <Icons name="IconX" size={16} />;
      default:
        return approver.level;
    }
  }

  return (
    <StatusTag variant={getVariant()}>
      <SC.TagContent>
        {getIcon()}
        <div>{approver.userName}</div>
      </SC.TagContent>
    </StatusTag>
  );
}
