import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { ShapeIcon } from '@atoms/ShapeIcon';
import { DownloadButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled(BorderedWrapping)`
  padding: ${props => props.theme.spacings.xs};
  border-color: ${props => props.theme.colors.feedback.error[70]};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
  width: 100%;
  max-width: 765px;
`;

export const Icon = styled(ShapeIcon)``;

export const Title = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
`;

export const SubTitle = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
`;

export const DownloadFileButton = styled(DownloadButton)`
  max-width: 372px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: ${props => props.theme.spacings.xs2} ${props => props.theme.spacings.xs};
  border: ${props => props.theme.borders.width.xs2} solid rgba(74, 78, 87, 0.2);
  border-radius: ${props => props.theme.borders.radius.s};
  margin-top: ${props => props.theme.spacings.s};

  svg {
    width: 50px;
  }

  &:hover {
    opacity: 0.75;
  }
`;
