import { gql } from '@apollo/client';

export const getReportById = gql`
  query GetReportById($id: Int!) {
    getReportById(id: $id) {
      id
      referenceId
      companyId
      userId
      name
      description
      status
      createdAt
      expensesViolations {
        expenseId
        violations {
          id
          description
        }
      }
      violationNumber
      remainingReimbursement
      reimbursableValue
      nonReimbursableValue
      withdrawValue
      withdraw {
        userIdCard
        cardNumber
        value
        currency
        date
      }
      advancesValue
      activePeriod {
        startDate
        endDate
      }
      value
      commentsNumber
      attachmentsNumber
      currencyPrefix
      approver {
        name
        id
        email
      }
      user {
        name
      }
      reproved {
        message
      }
      expirationDate
    }
  }
`;

export const getReportHistoryById = gql`
  query GetReportHistoryById($reportId: Int!, $page: Int!) {
    getReportHistoryById(reportId: $reportId, page: $page) {
      history {
        id
        createdAt
        description
        user {
          id
          name
          email
        }
      }
      currentPage
      pages
      total
    }
  }
`;

export const getReportsQuery = gql`
  query GetReports($page: Int!, $limit: Int!, $search: String, $status: [ReportStatus!]) {
    getReports(page: $page, limit: $limit, search: $search, status: $status) {
      reports {
        id
        referenceId
        activePeriod {
          startDate
          endDate
        }
        name
        value
        status
        currencyPrefix
      }
      total
    }
  }
`;

export const createReportMutation = gql`
  mutation CreateReport($report: ReportInput!) {
    createReport(report: $report) {
      id
      referenceId
      name
      description
      currencyPrefix
      id
      status
      value
      remainingReimbursement
      reimbursableValue
      nonReimbursableValue
      withdrawValue
      advancesValue
      approver {
        name
      }
      companyId
      userId
      createdAt
      violationNumber
      commentsNumber
      attachmentsNumber
    }
  }
`;

export const updateReportMutation = gql`
  mutation UpdateReportById($report: ReportInput!, $reportId: Int!) {
    updateReportById(report: $report, reportId: $reportId) {
      id
      referenceId
      name
      description
      currencyPrefix
      id
      status
      value
      remainingReimbursement
      reimbursableValue
      nonReimbursableValue
      withdrawValue
      advancesValue
      approver {
        name
      }
      companyId
      userId
      createdAt
      violationNumber
      commentsNumber
      attachmentsNumber
    }
  }
`;

export const deleteReportMutation = gql`
  mutation DeleteReportById($reportId: Int!) {
    deleteReportById(reportId: $reportId) {
      id
      message
      error
    }
  }
`;

export const detachExpensesFromReport = gql`
  mutation DetachExpensesFromReport($reportId: Int!, $expenseIds: [Int!]!, $commentary: String) {
    detachExpensesFromReport(reportId: $reportId, expenseIds: $expenseIds, commentary: $commentary) {
      detachExpenseFromReportResponses {
        expenseId
        reportId
        message
        error
      }
    }
  }
`;

export const attachExpensesToReportMutation = gql`
  mutation AttachExpensesToReport($reportId: Int!, $expenseIds: [Int!]!) {
    attachExpensesToReport(reportId: $reportId, expenseIds: $expenseIds) {
      currentPage
      pages
      limit
      total
      expenses {
        id
      }
    }
  }
`;

export const detachAdvancesFromReport = gql`
  mutation detachAdvanceFromReport($reportId: Int!, $advanceIds: [Int!]!) {
    detachAdvanceFromReport(reportId: $reportId, advanceIds: $advanceIds) {
      message
      success
    }
  }
`;
