import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

export interface FeatureProps {
  iconName: IconsProps['name'];
  description: string;
  isFullPlan: boolean;
}

export const Feature = ({ iconName, description, isFullPlan }: FeatureProps) => {
  return (
    <SC.Container style={{ height: isFullPlan ? '216px' : '156px' }}>
      <SC.Content>
        <SC.ContainerIcon>
          <SC.Icon name={iconName} size={24} fill="transparent" />
        </SC.ContainerIcon>
        <SC.Description variant="body3">{description}</SC.Description>
      </SC.Content>
    </SC.Container>
  );
};
