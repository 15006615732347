import { useMemo } from 'react';
import { useTranslation } from '@locale/Translator';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';

import { DetailedValues, ReportInfosTable } from '../ReportInfosTable';

interface ReportTotalExpensesProps {
  currencyPrefix: string;
  totalExpenses: number;
  reimbursableValue: number;
  nonReimbursableValue: number;
}

export const ReportTotalExpenses = ({
  currencyPrefix,
  totalExpenses,
  reimbursableValue,
  nonReimbursableValue,
}: ReportTotalExpensesProps) => {
  const { t } = useTranslation();
  const { getValueWithCurrency } = useValueFormatter();
  const empty: string = '-';
  currencyPrefix = currencyPrefix || 'R$';

  const detailedValues: DetailedValues[] = useMemo(() => {
    const detailed: DetailedValues[] = [];

    detailed.push({
      description: t('report.reimbursableExpenses'),
      value: reimbursableValue ? getValueWithCurrency({ value: reimbursableValue, currencyPrefix }) : empty,
    });

    detailed.push({
      description: t('report.nonRefundableExpenses'),
      value: nonReimbursableValue ? getValueWithCurrency({ value: nonReimbursableValue, currencyPrefix }) : empty,
    });

    return detailed;
  }, [reimbursableValue, nonReimbursableValue, currencyPrefix]);

  return (
    <ReportInfosTable
      mainValueDescription={t('report.totalExpenses')}
      mainValue={getValueWithCurrency({ value: totalExpenses || 0, currencyPrefix })}
      detailedValues={detailedValues}
    />
  );
};
