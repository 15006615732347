import { StatusTag } from '@atoms/StatusTag';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { IFinancialReportGrid } from '@containers/Financial/context/types/reports';
import { Icons, LinkButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { expensesAccountingBaseURL, expensesApprovalsBaseURL } from '@shared/constant';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { useTranslation } from 'react-i18next';

import { IconAction } from '../ExpenseGridActions/ExpenseGridActions';
import * as SC from './styled';

export enum ActionsMultipleSelected {
  UNLINK = 'unlink',
  DELETE = 'delete',
}

export interface AllowedMultipleActions {
  unlink?: boolean;
  delete?: boolean;
}

export interface DisabledMultipleActions extends AllowedMultipleActions {}

interface ActionsMultipleSelectedExpensesProps {
  allowedMultipleActions?: AllowedMultipleActions;
  disabledMultipleActions?: DisabledMultipleActions;
  selectedItems?: IFinancialReportGrid['id'][];
  totalItems?: number;
  onUnlink?(ids: IExpense['id'][]): void;
  onDelete?(ids: IExpense['id'][]): void;
}

export function ActionsMultipleSelectedExpenses({
  allowedMultipleActions,
  disabledMultipleActions,
  selectedItems,
  totalItems,
  onUnlink,
  onDelete,
}: ActionsMultipleSelectedExpensesProps) {
  const { t } = useTranslation();
  const getCurrentUrlBase = useCurrentUrlBase();
  const urlBase: string = getCurrentUrlBase();
  const _isManagement: boolean = urlBase === expensesAccountingBaseURL || urlBase === expensesApprovalsBaseURL;

  const events = {
    unlink: () => onUnlink?.(selectedItems),
    delete: () => onDelete?.(selectedItems),
  };

  return (
    <SC.Container>
      <StatusTag variant="secondary">
        {t('organisms.reportGrid.selectedItems', { totalItems, selectedItems: selectedItems?.length })}
      </StatusTag>
      {Object.keys(ActionsMultipleSelected).map((action, key) => {
        const allowedMultipleAction = allowedMultipleActions?.[ActionsMultipleSelected[action]];

        if (allowedMultipleAction != undefined ? allowedMultipleAction : true) {
          return (
            <div key={key}>
              <Tooltip
                arrow
                title={
                  disabledMultipleActions?.[ActionsMultipleSelected[action]]
                    ? t(`organisms.expenseGrid.actionsGrid.tooltip.${ActionsMultipleSelected[action]}`)
                    : ''
                }
                placement="top">
                <div>
                  {
                    <LinkButton variant="default" disabled={disabledMultipleActions?.[ActionsMultipleSelected[action]]}>
                      <SC.LinkContent onClick={() => events[ActionsMultipleSelected[action]](selectedItems)}>
                        <Icons name={IconAction[action]} fill="transparent" />
                        {t(
                          `organisms.expenseGrid.actionsGrid.pluralForm.${
                            ActionsMultipleSelected[action] == ActionsMultipleSelected.UNLINK && _isManagement
                              ? 'remove'
                              : ActionsMultipleSelected[action]
                          }`,
                        )}
                      </SC.LinkContent>
                    </LinkButton>
                  }
                </div>
              </Tooltip>
            </div>
          );
        }
      })}
    </SC.Container>
  );
}
