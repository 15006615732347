import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { ConfirmationModalDeleteExpense } from '@containers/Expenses/components/organisms/ConfirmationModalDeleteExpense';
import { ConfirmationModalUnlinkExpense } from '@containers/Expenses/components/organisms/ConfirmationModalUnlinkExpense';
import { ExpenseGrid } from '@containers/Expenses/components/organisms/ExpenseGrid';
import { useExpensesGridActions } from '@containers/Expenses/components/organisms/ExpenseGrid/ExpenseGridActions/hooks/useExpensesGridActions';
import { useSelectionRows } from '@containers/Expenses/components/organisms/ExpenseGrid/hooks/useSelectionRows';
import { EExpenseType } from '@containers/Expenses/context/types';
import { useExpenses } from '@containers/Expenses/hooks/useExpenses';
import { useKmPolicies } from '@containers/Expenses/hooks/useKmPolicies';
import { JustificationModalRemoveExpense } from '@containers/Financial/components/organisms/Reports/JustificationModalRemoveExpense';
import { EReportStatus, IReport } from '@containers/Reports/context/types';
import { useExpensesToBind } from '@containers/Reports/hooks/useExpensesToBind';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useExpensesPermissions } from '@shared/hooks/permissions/systemPermissions/useExpensesPermissions';

import * as SC from '../styled';
import { AttachExpenseModal } from './AttachExpenseModal';
import { ExpenseActions } from './ExpenseActions';
import { ExpensesEmptyActions } from './ExpensesEmptyActions/ExpensesEmptyActions';

interface IProps {
  report: IReport;
  reportIsLoading?: boolean;
  actionsIsAllowed: boolean;
  setExpenseTabName: Dispatch<SetStateAction<string>>;
}

export function ExpensesLinkedReport({ report, reportIsLoading, actionsIsAllowed, setExpenseTabName }: IProps) {
  const { t } = useTranslation();
  const { getMultiExpenseActions } = useExpensesPermissions();
  const expensesActionsAllowed = getMultiExpenseActions(EExpenseType.MANUAL, report?.status);
  const { expenses, expensesIsLoading, expensesTotalItems } = useExpenses({
    filters: { reportId: report?.id },
    enabled: !!report?.id,
  });
  const hasExpenses: boolean = expenses?.length > 0;
  const hasActions: boolean =
    !expensesIsLoading && !reportIsLoading && actionsIsAllowed && report?.status === EReportStatus.OPEN;
  const actionsEmpty: boolean = actionsIsAllowed && !hasExpenses;
  const { allowOdometer, allowOriginDestination } = useKmPolicies();
  const { selectedItems, isSelectedAll, toggleSelectAll, onSelectItemClick, unSelectAll } = useSelectionRows({
    expenses: expenses,
  });
  const {
    actionsIsLoading,
    selectedActionItems,
    unlinkConfirmationIsOpen,
    removeConfirmationIsOpen,
    deleteConfirmationIsOpen,
    onDeleteExpense,
    onUnlinkExpense,
    onViewExpense,
    onEditExpense,
    onCancelUnlinkExpenseClick,
    onConfirmUnlinkExpenseClick,
    onConfirmDeleteExpenseClick,
    onCancelDeleteExpenseClick,
  } = useExpensesGridActions({ reportId: report?.id, isReportDetails: true, unSelectAll });
  const [showAttachExpensesToReportModal, setShowAttachExpensesToReportModal] = useState(false);
  const { getExpensesToBind, expensesToBind, isLoading: expensesToBindIsLoading } = useExpensesToBind();
  const deleteExpenses = () => {
    const refetchQueries = ['GetExpenses', 'GetReportById'];
    onConfirmDeleteExpenseClick(refetchQueries);
  };

  function getAllExpensesToBind() {
    try {
      getExpensesToBind({
        variables: { limit: 0, onlyUnattached: true, page: 1 },
      });
    } catch (error) {
      showToast({ message: error?.message, type: 'error' });
    }
  }

  useMemo(() => {
    if (showAttachExpensesToReportModal) {
      getAllExpensesToBind();
    }
  }, [showAttachExpensesToReportModal]);

  useEffect(() => {
    if (!expensesIsLoading && expenses && expenses.length)
      setExpenseTabName(`${t('report.expenses')} ${!expensesIsLoading ? `(${expensesTotalItems})` : ''}`);
  }, [expensesIsLoading]);

  return (
    <SC.GridContainer>
      {hasActions && (
        <>
          {!actionsEmpty ? (
            <SC.ActionsContainer>
              <ExpenseActions
                report={{
                  id: report?.id,
                  name: report?.name,
                  description: report?.description,
                  status: report?.status,
                }}
                onLinkExpense={() => setShowAttachExpensesToReportModal(true)}
              />
            </SC.ActionsContainer>
          ) : (
            <ExpensesEmptyActions
              report={report}
              onShowAttachModal={() => setShowAttachExpensesToReportModal(true)}
              kmIsAllowed={allowOdometer || allowOriginDestination}
            />
          )}
        </>
      )}

      <ExpenseGrid
        key={report?.id}
        expenses={expenses}
        allowedActions={expensesActionsAllowed}
        allowedMultipleActions={{
          unlink: expensesActionsAllowed?.unlink,
          delete: expensesActionsAllowed?.delete,
        }}
        isLoading={expensesIsLoading || reportIsLoading}
        selectedItems={selectedItems}
        isSelectedAll={isSelectedAll}
        onSelectAllClick={toggleSelectAll}
        onSelectExpense={id => onSelectItemClick(id)}
        onDeleteExpense={onDeleteExpense}
        onViewExpense={onViewExpense}
        onEditExpense={onEditExpense}
        onUnlinkExpense={onUnlinkExpense}
        totalItems={expensesTotalItems}
      />

      <ConfirmationModalUnlinkExpense
        expensesNumber={selectedActionItems?.length}
        isOpen={unlinkConfirmationIsOpen}
        onActionClick={onConfirmUnlinkExpenseClick}
        onCloseClick={onCancelUnlinkExpenseClick}
        isLoading={actionsIsLoading}
      />
      <ConfirmationModalDeleteExpense
        expensesNumber={selectedActionItems?.length}
        isOpen={deleteConfirmationIsOpen}
        onActionClick={deleteExpenses}
        onCloseClick={onCancelDeleteExpenseClick}
        isLoading={actionsIsLoading}
      />
      <JustificationModalRemoveExpense
        expensesNumber={selectedActionItems?.length}
        isOpen={removeConfirmationIsOpen}
        onActionClick={onConfirmUnlinkExpenseClick}
        onCloseClick={onCancelUnlinkExpenseClick}
        isLoading={actionsIsLoading}
      />

      {showAttachExpensesToReportModal && (
        <AttachExpenseModal
          isLoading={expensesToBindIsLoading}
          expenses={expensesToBind}
          isOpen={showAttachExpensesToReportModal}
          onClose={() => setShowAttachExpensesToReportModal(false)}
          reportId={report.id}
        />
      )}
    </SC.GridContainer>
  );
}
