import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 85px);
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacings.xs};
  background-color: ${props => props.theme.colors.neutral[95]};
  position: absolute;
  z-index: 15;
  min-height: 104px;
  left: 80px;
  top: 1px;
  border-top-right-radius: ${props => props.theme.borders.radius.m};
  border-top-left-radius: ${props => props.theme.borders.radius.m};
`;

export const LinkContent = styled.div`
  white-space: nowrap;
  display: grid;
  gap: ${props => props.theme.spacings.xs5};
  grid-template-columns: min-content min-content;
`;

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacings.xs};
  background-color: ${props => props.theme.colors.neutral[95]};
  z-index: 15;
  min-height: 104px;
  border-top-right-radius: ${props => props.theme.borders.radius.m};
  border-top-left-radius: ${props => props.theme.borders.radius.m};
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacings.xs} 0;
`;
