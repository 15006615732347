import { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import _range from 'lodash/range';

import * as SC from './styled';

export interface GridPaginationSkeletonProps {
  pagesNumber?: number;
}

export const GridPaginationSkeleton = ({ pagesNumber = 3 }: GridPaginationSkeletonProps) => {
  return (
    <SC.GridPaginationContainer>
      <SC.DescriptionContainer>
        <Skeleton variant="rounded" width={100} height={41} style={{ marginRight: '16px', borderRadius: '50px' }} />
        <Skeleton variant="text" width={130} height={22} />
      </SC.DescriptionContainer>
      <SC.DescriptionContainer>
        {useMemo(() => {
          return _range(pagesNumber).map(key => (
            <Skeleton
              key={key}
              variant="circular"
              width={40}
              height={40}
              style={{ marginRight: key + 1 == pagesNumber ? '0px' : '16px' }}
            />
          ));
        }, [pagesNumber])}
      </SC.DescriptionContainer>
    </SC.GridPaginationContainer>
  );
};
