import {
  expensesAccountingBaseURL,
  expensesAdvancesBaseURL,
  expensesAnalyticsBaseURL,
  expensesApprovalsBaseURL,
  expensesBaseUrl,
  expensesCustomizationsBaseURL,
} from '@shared/constant';

import { useCurrentPath } from './navigation/useCurrentPath';

export function useCurrentUrlBase() {
  const path: string = useCurrentPath();

  function getCurrentUrlBase(): string {
    if (path.includes(expensesApprovalsBaseURL)) {
      return expensesApprovalsBaseURL;
    }

    if (path.includes(expensesCustomizationsBaseURL)) {
      return expensesCustomizationsBaseURL;
    }

    if (path.includes(expensesAdvancesBaseURL)) {
      return expensesAdvancesBaseURL;
    }

    if (path.includes(expensesAccountingBaseURL)) {
      return expensesAccountingBaseURL;
    }

    if (path.includes(expensesAnalyticsBaseURL)) {
      return expensesAnalyticsBaseURL;
    }

    return expensesBaseUrl;
  }

  return getCurrentUrlBase;
}
