import { createContext, ReactNode, useEffect, useState } from 'react';
import { useUsersPendingExpenses } from '@containers/Financial/hooks/dataSources/useUsersPendingExpenses';
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';

import { IPendingAccountContext } from './types';

export const PendingAccountContext = createContext<IPendingAccountContext>(null);

interface IPendingAccountContextProviderProps {
  children: ReactNode;
}

export const PendingAccountContextProvider = ({ children }: IPendingAccountContextProviderProps) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  });

  const {
    response: { users, total: usersQuantity },
    isLoading,
  } = useUsersPendingExpenses({
    filters: {
      page: pagination.pageNumber,
      pageLimit: pagination.pageSize,
    },
  });

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      pageNumber: 1,
    }));
  }, [pagination.pageSize]);

  return (
    <PendingAccountContext.Provider
      value={{
        users,
        usersQuantity,
        pagination,
        setPagination,
        isLoading,
      }}>
      <>{children}</>
    </PendingAccountContext.Provider>
  );
};
