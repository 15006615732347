import React, { useMemo } from 'react';
import { IExpenseHistory } from '@containers/Expenses/context/types/expenses';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';

import * as SC from '../styled';
import { EAccessor } from './UseHeaderGridColumns';

export type TGridRow = Record<EAccessor, React.ReactNode>;

export interface UseBodyGridRowsProps {
  historic: IExpenseHistory[];
}

export const useBodyGridRows = ({ historic }: UseBodyGridRowsProps): TGridRow[] => {
  const { getDateOnlyFormatter } = useDateFormatter();
  const rows: TGridRow[] = useMemo(
    () =>
      historic?.map(item => ({
        date: <SC.DescriptionBodyColumn>{getDateOnlyFormatter(item?.createdAt)}</SC.DescriptionBodyColumn>,
        actions: <SC.DescriptionBodyColumn>{`${item?.description}`}</SC.DescriptionBodyColumn>,
      })),
    [historic],
  );

  return rows;
};
