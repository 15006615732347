import styled from 'styled-components';

interface FieldsContentProps {
  isMileage: boolean;
}

export const MainContainer = styled.div(({ theme }) => ({
  marginBottom: theme.spacings.s,
  maxWidth: 'fit-content',
  overflow: 'hidden',
  borderRadius: theme.borders.radius.s,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
}));

export const FieldsContainer = styled.div`
  width: 100%;
`;

export const ReceiptsContainer = styled.div`
  width: 100%;
`;

export const FieldsContent = styled.div<FieldsContentProps>(({ theme, isMileage }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '500px 480px',
  gap: theme.spacings.m,

  ...(isMileage && {
    gridTemplateColumns: '650px',
  }),
}));

export const FieldContainer = styled.div`
  display: grid;
  grid-template-rows: 1tf;
  gap: ${props => props.theme.spacings.xs};
`;

export const KmContainer = styled.div(({ theme }) => ({
  paddingBottom: theme.spacings.s,
}));

export const LoaderContainer = styled.div(({ theme }) => ({
  width: '42px',
  height: '42px',
  borderRadius: theme.borders.radius.circular,
  padding: theme.spacings.xs5,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
