import { useState } from 'react';
import { useReportsStatusMutate } from '@containers/Financial/hooks/useReportsStatusMutate';
import { EReportStatus, IReport } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { expensesBaseUrl } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { EventName, useTracking } from '@shared/hooks/useTracking';
import { useNavigate } from 'react-router-dom';

interface UseReportActionsManagerProps {
  isReportDetails?: boolean;
  reportId: IReport['id'];
}

interface UseReportActionsManager {
  isLoadingMutate: boolean;
  reopenConfirmIsOpen: boolean;
  handleReportStatusUpdate(status: EReportStatus): void;
  confirmReportStatusUpdatePopup(): void;
  onClosePopup(): void;
}

export function useReportActionsManager({
  isReportDetails,
  reportId,
}: UseReportActionsManagerProps): UseReportActionsManager {
  const { t } = useTranslation();
  const trackingEvent = useTracking();
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState<EReportStatus>(null);
  const [reopenConfirmIsOpen, setReopenConfirmIsOpen] = useState(false);
  const { reportsStatusMutate, isLoading } = useReportsStatusMutate(isReportDetails);

  function handleReportStatusUpdate(status: EReportStatus) {
    sendTrackingEvent(status, 'clicked');

    if (status == EReportStatus.OPEN) {
      setNewStatus(status);
      setReopenConfirmIsOpen(true);
    } else {
      updateReportStatus(status);
    }
  }

  function confirmReportStatusUpdatePopup() {
    updateReportStatus(newStatus);
  }

  function onClosePopup(): void {
    setReopenConfirmIsOpen(false);
  }

  const sendTrackingEvent = (status: EReportStatus, type: 'clicked' | 'success' | 'error', errorMessage?: string) => {
    const trackMapped: Partial<
      Record<
        EReportStatus,
        {
          clicked: EventName;
          success: EventName;
          error: EventName;
        }
      >
    > = {
      OPEN: {
        clicked: 'report_reopen_clicked',
        success: 'report_reopen_success',
        error: 'report_reopen_error',
      },
      SUBMITTED: {
        clicked: 'report_submit_clicked',
        success: 'report_submit_success',
        error: 'report_submit_error',
      },
      REPROVED: {
        clicked: 'report_reject_clicked',
        success: 'report_reject_success',
        error: 'report_reject_error',
      },
      REIMBURSED: {
        clicked: 'report_refund_clicked',
        success: 'report_refund_success',
        error: 'report_refund_error',
      },
      APPROVED: {
        clicked: 'report_approve_clicked',
        success: 'report_approve_success',
        error: 'report_approve_error',
      },
    };

    if (trackMapped[status]) {
      trackingEvent(trackMapped[status][type], {
        status,
        reportId: String(reportId),
        error: errorMessage,
      });
    }
  };

  async function updateReportStatus(status: EReportStatus) {
    try {
      const response = await reportsStatusMutate({
        variables: { reports: [reportId], status },
      });

      sendTrackingEvent(status, 'clicked');

      const reports = response?.data?.updateReportListStatus?.reports;
      const successItems: boolean = reports.some(report => !report.error);
      let hasError: boolean = false;
      Boolean(reports) &&
        reports.forEach(report => {
          if (report?.error) {
            showToast({ message: report?.message, type: 'error' });
            hasError = true;
          }
        });
      successItems &&
        showToast({
          message: t(
            `organisms.reportDetailsPreview.feedback.${status == EReportStatus.OPEN ? 'reopenReport' : 'sendReport'}.${
              reports.length > 1 ? 'pluralForm' : 'singularForm'
            }.success`,
          ),
          type: 'success',
        });

      sendTrackingEvent(status, 'success');
      status !== EReportStatus.OPEN && !hasError && navigate(`${expensesBaseUrl}/reports`);
    } catch (error) {
      showToast({ message: error?.message, type: 'error' });

      sendTrackingEvent(status, 'error', error?.message);
    }
    onClosePopup();
  }

  return {
    isLoadingMutate: isLoading,
    reopenConfirmIsOpen,
    handleReportStatusUpdate,
    confirmReportStatusUpdatePopup,
    onClosePopup,
  };
}
