import { useState } from 'react';
import { IReport } from '@containers/Reports/context/types';
import { useReportMutate } from '@containers/Reports/hooks/useReportMutate';
import { useReportDetailsDriver } from '@shared/hooks/navigation/useReportDetailsDriver';
import { useTracking } from '@shared/hooks/useTracking';

interface UseReportPageActions {
  openReportModal: boolean;
  showDeleteReportModal: boolean;
  createReportIsLoading: boolean;
  handleCreateReport(report: Pick<IReport, 'name' | 'description' | 'approver'>): void;
  handleOpenDeleteModal(reportId: number): void;
  handleDeleteReport(): void;
  onShowReportModal(open: boolean): void;
  onShowDeleteReportModal(open: boolean): void;
}

export function useReportPageActions(): UseReportPageActions {
  const trackingEvent = useTracking();
  const [openReportModal, setOpenReportModal] = useState(false);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [reportId, setReportId] = useState<number>(null);
  const { createReport, isLoading, deleteReport } = useReportMutate();
  const navigateReportDetails = useReportDetailsDriver();

  function handleCreateReport(report: Pick<IReport, 'name' | 'description' | 'approver'>) {
    trackingEvent('report_create_clicked');

    createReport(report).then(report => {
      navigateReportDetails(report.id, { ...report });
    });
  }

  function handleOpenDeleteModal(reportId: number) {
    setReportId(reportId);
    setShowDeleteReportModal(true);
  }

  async function handleDeleteReport() {
    await deleteReport(reportId);
    setShowDeleteReportModal(false);
  }

  function onShowReportModal(open: boolean) {
    setOpenReportModal(open);
  }

  function onShowDeleteReportModal(open: boolean) {
    setShowDeleteReportModal(open);
  }

  return {
    openReportModal,
    showDeleteReportModal,
    createReportIsLoading: isLoading,
    handleCreateReport,
    handleOpenDeleteModal,
    handleDeleteReport,
    onShowReportModal,
    onShowDeleteReportModal,
  };
}
