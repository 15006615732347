import { Dispatch, SetStateAction } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { ISubsidiary } from '@containers/Subsidiaries/context/types/subsidiaries';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { Search } from '@molecules/GridFilters/Search/Search';
import { ActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { formatCNPJ } from '@shared/helpers/formaters';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { useSubsidiariesActionsGrid } from './hooks/useSubsidiariesActionsGrid';
import * as SC from './styled';
import { SubsidiariesConfirmationActionsModal } from './SubsidiariesConfirmationActionsModal/SubsidiariesConfirmationActionsModal';

enum EAccessor {
  SELECT_ALL = 'selectAll',
  SUBSIDIARY = 'subsidiary',
  UF = 'uf',
  CNPJ = 'cnpj',
  STATUS = 'status',
  ACTIONS = 'actions',
}

type TProps = {
  subsidiaries: ISubsidiary[];
  total: number;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSearchName: Dispatch<SetStateAction<string>>;
  searchName: string;
};

export function SubsidiariesGrid({ subsidiaries, total, isLoading, page, setPage, setSearchName }: TProps) {
  const t = useTranslate('subsidiaries.grid');
  const {
    showConfirmationModal,
    isSelectedAll,
    selectedItems,
    confirmationType,
    isBatchAction,
    isLoadingAction,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    onSelectItemClick,
    unSelectAllClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
  } = useSubsidiariesActionsGrid(subsidiaries);

  function getColumns(): IGridColumn[] {
    return Object.values(EAccessor).map(accessor => ({
      accessor,
      disableSortBy: true,
      Header: (
        <ColumnHeader
          isLoading={isLoading}
          isLoadingAction={isLoading}
          description={t(`header.columns.${accessor}`)}
          isSelectedAll={accessor === EAccessor.SELECT_ALL}
          onSelectAllClick={toggleSelectAll}
          selectAllChecked={isSelectedAll}
        />
      ),
      sticky: accessor === EAccessor.ACTIONS && 'right',
    }));
  }

  function getRows(): Record<EAccessor, React.ReactNode>[] {
    return subsidiaries?.map(_subsidiary => {
      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(_subsidiary?.id)}
            checked={selectedItems.some(_id => _id === _subsidiary?.id)}
          />
        ),
        subsidiary: _subsidiary.name || '-',
        uf: _subsidiary.address?.state || '-',
        cnpj: formatCNPJ(_subsidiary.registerNumber),
        status: (
          <StatusTag showStatusIcons variant={_subsidiary.active ? 'success' : 'error'}>
            {t(`${_subsidiary.active ? 'active' : 'inactive'}`)}
          </StatusTag>
        ),
        actions: <ActionsRow referenceId={_subsidiary.id} actions={getActionsRow(_subsidiary.id)} />,
      };
    });
  }

  return (
    <SC.Container>
      <Search
        label={t('searchFieldLabel')}
        onChange={data => {
          selectedItems.length && unSelectAllClick();
          setSearchName(data);
        }}
        isLoading={isLoading}
      />
      <Grid
        columns={getColumns()}
        rows={getRows()}
        batchActions={getBatchActions()}
        isLoading={isLoading}
        numberItemsSelected={selectedItems?.length || 0}
        hasPagination={total > 20}
        totalItems={total || 0}
        rowsLoading={Boolean(subsidiaries.length) ? subsidiaries.length : 4}
        rowsPerPage={20}
        currentPage={page}
        paginationIsDisabled={isLoading}
        onPageClick={_page => setPage(_page)}
      />
      {showConfirmationModal && (
        <SubsidiariesConfirmationActionsModal
          open={showConfirmationModal}
          confirmationType={confirmationType}
          onActionClick={onConfirmationActionModal}
          onClose={onShowConfirmationActionsModal}
          isBatchAction={isBatchAction}
          isLoading={isLoadingAction}
        />
      )}
    </SC.Container>
  );
}
