import { forwardRef, useImperativeHandle } from 'react';
import { PDFViewer } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import { ContractsModalSkeleton } from './ContractsModalSkeleton';
import * as SC from './styled';

export interface ContractsModalProps {
  url: string | undefined;
  isLoading: boolean;
  open: boolean;
  actionIsLoading?: boolean;
  actionIsDisabled?: boolean;
  onCancelClick(): void;
  onActionClick(): void;
}

export type Ref = {
  scrollToTop: () => void;
};

export const ContractsModal = forwardRef<Ref, ContractsModalProps>(
  ({ url, actionIsLoading, actionIsDisabled, isLoading, open = true, onCancelClick, onActionClick }, ref) => {
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      scrollToTop() {
        document
          .querySelector('.MuiDialog-container.MuiDialog-scrollBody')
          .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      },
    }));

    function handleActionClick() {
      onActionClick?.();
    }

    return (
      <SC.Container>
        <SC.StyledContractModal
          open={open}
          labelButtonAction={t('molecules.contractsModal.confirmation')}
          labelButtonCancel={t('molecules.contractsModal.close')}
          headerTitle={t(`molecules.contractsModal.expenses.title`)}
          headerSubtitle={t('molecules.contractsModal.subtitle')}
          onActionClick={handleActionClick}
          isActionLoading={actionIsLoading}
          onCancelClick={onCancelClick}
          actionDisabled={actionIsDisabled}
          containerClassName="contract-modal-container"
          bodyClassName="contract-modal-body">
          {isLoading || !url ? (
            <ContractsModalSkeleton />
          ) : (
            <SC.ContainerPDF>
              <PDFViewer
                height="100%"
                options={{
                  fit: 'height',
                  page: 1,
                  showToolbar: true,
                }}
                src={url}
                width="100%"
              />
            </SC.ContainerPDF>
          )}
        </SC.StyledContractModal>
      </SC.Container>
    );
  },
);

ContractsModal.displayName = 'ContractsModal';

export type ContractsModal = Ref;
