export interface ILimitGrid {
  id?: number;
  allowValueGreaterThanLimit: boolean;
  commentRequired: boolean;
  receiptRequired: boolean;
  minimumInvoiceProofValue: number;
  policy?: {
    id: number;
    name: string;
  };
  currency: string;
  criterion: ECriterionLimit;
  value: number;
  active: boolean;
  limitPolicyId: number;
  categoryLimitsPolicy: {
    categoryDescription: string;
    policyCategoryId: number;
  }[];
}

export enum ECriterionLimit {
  DAY = 'DAY',
  EXPENSE = 'EXPENSE',
  QUANTITY = 'QUANTITY',
  MONTH = 'MONTH',
}

export enum ELimitsActivePropertyColorVariant {
  true = 'success',
  false = 'error',
}

export enum ELimitsActiveStatusPropertyColorVariant {
  true = 'success',
  false = 'neutral',
}

export interface ICategoryLimitPolicy {
  active: boolean;
  policyId: number;
  currency: string;
  receiptRequired: boolean;
  allowValueGreaterThanLimit: boolean;
  commentRequired: boolean;
  criterion: ECriterionLimit;
  value: number;
  minimumInvoiceProofValue: number;
  categoryLimitsPolicy: {
    policyCategoryId: number;
    categoryDescription: string;
  }[];
  limitPolicyId?: number;
}
