import { useEffect } from 'react';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { useSearchPlaces } from '@containers/Expenses/hooks/useSearchPlaces';

import { useSerializeLocationToEstablishment } from './useSerializeLocationToEstablishment';

interface Props {
  allowSearch: boolean;
  inputValue: string;
}

interface UseEstablishmentsGoogleMaps {
  establishmentsGoogleMaps: IEstablishment[];
  establishmentsGoogleMapsIsLoading: boolean;
}

export function useEstablishmentsGoogleMaps({ allowSearch, inputValue }: Props): UseEstablishmentsGoogleMaps {
  const { getSearchPlaces, places, isLoading: establishmentsGoogleMapsIsLoading } = useSearchPlaces();
  const establishmentsGoogleMaps: IEstablishment[] = useSerializeLocationToEstablishment(places);

  useEffect(() => {
    if (allowSearch) {
      getSearchPlaces({
        variables: {
          place: inputValue,
        },
      });
    }
  }, [inputValue]);

  return {
    establishmentsGoogleMaps,
    establishmentsGoogleMapsIsLoading,
  };
}
