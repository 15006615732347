import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { CompanyPayables } from '@flash-hros/company-payables';

const { ApprovalsTab } = CompanyPayables;
export function PayAblesApprovalTab() {
  return (
    <ModuleErrorBoundary moduleName="Contas a Pagar" title="Erro ao tentar carregar tab de Despesas com o Fornecedor">
      <ApprovalsTab />
    </ModuleErrorBoundary>
  );
}
