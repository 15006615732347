import { useState } from 'react';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { mutate } from 'swr';

import { ISummaryApprovalFlow } from '../../types/approvalFlow';
import { useTargetCustomApprovalFlow } from '../useTargetCustomApprovalFlow';

interface IResponse {
  id: ISummaryApprovalFlow['id'];
  error: boolean;
  message: string;
}

export function useDuplicateCustomApprovalFlows() {
  const t = useTranslate('approvalFlows.feedbacks.duplicate');
  const { mutation } = useMutate('v1/approvals');
  const [idIsLoading, setIdIsLoading] = useState<ISummaryApprovalFlow['id']>(null);
  const target = useTargetCustomApprovalFlow();

  async function getDuplicateCustomApprovalFlows(id: ISummaryApprovalFlow['id']) {
    const result: IResponse[] = await mutation.trigger({
      path: `duplicate`,
      options: {
        method: 'POST',
        body: JSON.stringify({ id }),
      },
    });
    return result;
  }

  async function duplicateCustomApprovalFlows(id: ISummaryApprovalFlow['id']) {
    try {
      setIdIsLoading(id);
      await getDuplicateCustomApprovalFlows(id);
      showToast({
        message: t('success'),
        type: 'success',
      });

      setIdIsLoading(null);
      mutate(`v1/approvals/${target}`);
    } catch (_error) {
      setIdIsLoading(null);
      showToast({
        message: _error.message || t('error'),
        type: 'error',
      });
    }
  }

  return {
    duplicateCustomApprovalFlows,
    error: mutation?.error,
    isLoading: mutation?.isMutating,
    idIsLoading,
  };
}
