import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const DelegateUserViewContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.xs5} ${({ theme }) => theme.spacings.xs4};
  border-radius: ${({ theme }) => theme.borders.radius.xl};
  border: ${({ theme }) => theme.borders.width.s} solid ${({ theme }) => theme.colors.secondary[70]};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  color: ${({ theme }) => theme.colors.secondary[70]};
  font-weight: 700 !important;
  height: fit-content;
`;

export const DelegateUserViewDescription = styled(Typography)`
  font-weight: 600 !important;
`;
