import { useContext } from 'react';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { usePermissionsAuthZ } from './permissions/systemPermissions/usePermissionsAuthZ';

export function useHasSignPermission() {
  const { isAdmin } = useContext(SessionContext);
  const { checkPermissionAuthZ } = usePermissionsAuthZ();

  return isAdmin || checkPermissionAuthZ(EAuthZPermission.CONTRACT_SIGN);
}
