import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
`;

export const ToggleLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600 !important;
`;

export const ToggleContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[40]};

  .icon-container {
    align-items: center;
  }
`;
export const ContainerToggleLoading = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 6px;
`;
