import { ReactNode } from 'react';
import { IconTypes, ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';

import * as SC from './styled';

interface IProps {
  icon?: IconTypes;
  title: string;
  caption?: string;
  action?: ReactNode;
  children: ReactNode;
}

export function PolicyRuleSection({ icon, title, caption, action, children }: IProps) {
  const { colors } = useTheme();
  return (
    <SC.Container>
      <SC.HeaderContainer>
        <SC.HeaderContent>
          <ShapeIcon variant="default" size={48} color={colors.secondary[50]} name={icon} />
          <SC.HeaderInfo>
            <SC.Title variant="headline8">{title}</SC.Title>
            {caption && <SC.Caption variant="body3">{caption}</SC.Caption>}
          </SC.HeaderInfo>
        </SC.HeaderContent>
        {action && action}
      </SC.HeaderContainer>
      <SC.Content>{children}</SC.Content>
    </SC.Container>
  );
}
