import { useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { useDelegateUsers } from '@containers/Customizations/hooks/settings/delegation/useDelegateUsers';
import { SelectData } from '@containers/Expenses/components/organisms/ExpenseRegisterForm/ExpenseFormFields';
import { Checkbox, IconButton, Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { useDateFormatter } from '@shared/hooks';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ActionConfirmationModal } from '../ActionConfirmationModal/ActionConfirmationModal';
import { TTargetDelegation } from '../DelegationPreview';
import { useDelegationActionsGrid } from './hooks/useDelegationActionsGrid';
import * as SC from './styled';

enum EAccessor {
  SELECT_ALL = 'selectAll',
  PERSON = 'person',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  REASON = 'reason',
  STATUS = 'status',
  ACTIONS = 'actions',
}

interface IProps {
  target: TTargetDelegation;
  selectedPersonId: string;
  userOptions: SelectData['options'];
}

export function DelegationGrid({ target, selectedPersonId, userOptions }: IProps) {
  const t = useTranslate('delegation.configuration.grid');
  const { getDateOnlyFormatter } = useDateFormatter();
  const [page, setPage] = useState(1);
  const { users, isLoading, total } = useDelegateUsers({ target, userId: selectedPersonId, page });
  const {
    isSelectedAll,
    selectedItems,
    isLoadingAction,
    confirmationType,
    showConfirmationModal,
    toggleSelectAll,
    getActionsRow,
    getIconsStatus,
    getBatchActions,
    onSelectItemClick,
    onConfirmationActionModal,
    onShowConfirmationActionsModal,
  } = useDelegationActionsGrid(users, target, userOptions);

  function getColumns() {
    const columns: IGridColumn[] = [];

    Object.values(EAccessor).forEach(accessor => {
      if (
        (target === 'approval' && accessor !== EAccessor.REASON && accessor !== EAccessor.STATUS) ||
        (target === 'expense' && accessor !== EAccessor.START_DATE)
      )
        columns.push({
          accessor,
          disableSortBy: true,
          Header: (
            <ColumnHeader
              isLoading={isLoading}
              isLoadingAction={isLoadingAction}
              description={t(`header.${accessor}`)}
              isSelectedAll={accessor === EAccessor.SELECT_ALL}
              onSelectAllClick={toggleSelectAll}
              selectAllChecked={isSelectedAll}
            />
          ),
          sticky: accessor === EAccessor.ACTIONS && 'right',
        });
    });

    return columns;
  }

  function getRows() {
    return users?.map(_person => {
      const actionsRow = getActionsRow(_person.id);

      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(_person.id)}
            checked={selectedItems?.some(_id => _id === _person?.id)}
          />
        ),
        person: (
          <SC.DataPersonContainer>
            <p>{_person.delegateUser?.name ?? '-'}</p>
            <SC.DataPersonMailContainer>
              <SC.IconMail name="IconMail" size={16} />
              <Typography variant="body4"> {_person.delegateUser?.email ?? '-'}</Typography>
            </SC.DataPersonMailContainer>
          </SC.DataPersonContainer>
        ),
        startDate: _person.startDate ? getDateOnlyFormatter(_person.startDate) : '-',
        endDate: _person.endDate ? getDateOnlyFormatter(_person.endDate) : '-',
        reason: _person.reason ?? '-',
        status: (
          <StatusTag showStatusIcons variant={_person.active ? 'success' : 'error'}>
            {t(`body.${_person.active ? 'active' : 'inactive'}`)}
          </StatusTag>
        ),
        actions: (
          <div style={{ width: 'min-content' }}>
            <Tooltip arrow title={actionsRow?.tooltip} placement="top-start">
              <div>
                <IconButton
                  variant="line"
                  size="small"
                  icon={target === 'expense' ? getIconsStatus(_person.id) : 'IconTrash'}
                  onClick={actionsRow?.onClick}
                  disabled={actionsRow?.disable}
                />
              </div>
            </Tooltip>
          </div>
        ),
      };
    });
  }

  return (
    <>
      <Grid
        columns={getColumns()}
        rows={getRows()}
        emptyState={{
          message: t(`body.${Boolean(selectedPersonId) ? 'emptyData' : 'selectUser'}`),
          isFiltered: Boolean(selectedPersonId),
        }}
        isLoading={isLoading}
        onPageClick={(_page: number) => setPage(_page)}
        batchActions={getBatchActions()}
        hasPagination={target === 'expense' ? total > 10 : false}
        totalItems={total || 0}
        numberItemsSelected={selectedItems?.length || 0}
        rowsLoading={Boolean(users?.length) ? users?.length : 4}
        rowsPerPage={10}
        currentPage={page}
        paginationIsDisabled={isLoading}
        showRowsPerPageOptions={false}
      />

      <ActionConfirmationModal
        target={confirmationType}
        open={showConfirmationModal}
        isLoading={isLoadingAction}
        quantityOfItems={selectedItems?.length ?? 0}
        onActionClick={onConfirmationActionModal}
        onClose={onShowConfirmationActionsModal}
      />
    </>
  );
}
