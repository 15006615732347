/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export interface IAdvanceFields {
  currency: IFieldControl;
  value: IFieldControl;
  paymentType: IFieldControl;
  client: IFieldControl;
  project: IFieldControl;
  costCenter: IFieldControl;
  report: IFieldControl;
  approver: IFieldControl;
  advanceDate: IFieldControl;
  description: IFieldControl;
}

export interface IFieldControl {
  key?: string;
  active: boolean;
  required: boolean;
  allowsEditing: boolean;
  fieldOrder: number;
}

export interface IAdvanceInput {
  clientId?: number;
  projectId?: number;
  reportId?: number;
  costCenterId?: number;
  currencyCode?: string;
  value: number;
  convertedValue: number;
  paymentMethodId: number;
  description: string;
  advanceDate: string;
  approverId?: number;
}

export enum EAdvanceFieldType {
  client = 'OPTIONS',
  project = 'OPTIONS',
  currency = 'OPTIONS',
  paymentType = 'OPTIONS',
  value = 'NUMBER',
  advanceDate = 'DATE',
  description = 'TEXT',
  costCenter = 'OPTIONS',
  approver = 'OPTIONS',
  report = 'TEXT',
  custom = 'CUSTOM',
}

export enum EAdvanceRefFields {
  client = 'clientId',
  project = 'projectId',
  report = 'reportId',
  costCenter = 'costCenterId',
  currency = 'currencyCode',
  value = 'value',
  convertedValue = 'convertedValue',
  description = 'description',
  paymentType = 'paymentMethodId',
  advanceDate = 'advanceDate',
  approver = 'approverId',
}

export enum EAdvanceKeyFields {
  client = 'client',
  project = 'project',
  report = 'report',
  costCenter = 'costCenter',
  currency = 'currency',
  value = 'value',
  convertedValue = 'convertedValue',
  description = 'description',
  paymentType = 'paymentType',
  advanceDate = 'advanceDate',
  approver = 'approver',
}
