import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { FooterPage } from '@molecules/FooterPage';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import * as SC from './styled';

interface ClientFooterPageProps {
  action(data?: IConfigurationClient): void;
  methods: UseFormReturn<IConfigurationClient>;
}

export const ClientFooterPage = ({ action, methods }: ClientFooterPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <FooterPage>
      <SC.ButtonsContainer>
        <LinkButton variant="default" onClick={() => navigate(-1)}>
          {t('pages.clientRegister.cancel')}
        </LinkButton>
        <Button variant="primary" size="medium" type="submit" onClick={methods.handleSubmit(action)}>
          {t('pages.clientRegister.continue')}
          <Icons name="IconArrowRight" fill="transparent" />
        </Button>
      </SC.ButtonsContainer>
    </FooterPage>
  );
};
