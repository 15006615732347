import { Fragment } from 'react';
import { IAnalyticsLog } from '@containers/Analytics/context/types/analyticsLogs';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

import { useLogValueFormatter } from '../useLogValueFormatter';
import { DiffColumnContainer, DiffText } from './styles';

type Props = {
  diff: IAnalyticsLog['extras']['diff'];
  type: 'previous' | 'current';
};

const DiffColumn = ({ diff, type }: Props) => {
  const logValue = useLogValueFormatter();

  return (
    <DiffColumnContainer>
      {diff.map(item => (
        <Fragment key={item.field}>
          <Typography variant="caption">
            {item.field} <DiffText type={type}>{logValue.formatValue(item[type])}</DiffText>
          </Typography>

          <br />
        </Fragment>
      ))}
    </DiffColumnContainer>
  );
};

export default DiffColumn;
