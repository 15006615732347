import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.spacings.xs2} ${props => props.theme.spacings.xs};
  background-color: ${props => props.theme.colors.neutral[100]};
  width: fill-available !important;
  position: fixed;
  bottom: 0;
  z-index: 1300;
  box-shadow: 20px 4px 26px rgba(0, 0, 0, 0.1);
`;
