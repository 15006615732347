import { EExpenseType, IExpenses } from '@containers/Expenses/context/types';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { useNavigate } from 'react-router-dom';

interface INavigateExpenseDetails {
  expenseId?: IExpenses['id'];
  expenseType: EExpenseType;
}

export function useExpenseDetailsDriver() {
  const navigate = useNavigate();
  const getCurrentUrlBase = useCurrentUrlBase();

  function getUrlBaseDetails(): string {
    const urlBase: string = getCurrentUrlBase();

    if (urlBase.includes('/expenses')) {
      return `${urlBase}/details`;
    }

    return `${urlBase}/expenses/details`;
  }

  function navigateExpenseDetails({ expenseId, expenseType }: INavigateExpenseDetails) {
    const urlBaseDetails: string = getUrlBaseDetails();

    switch (expenseType) {
      case EExpenseType.ODOMETER:
        navigate(`${urlBaseDetails}/odometer/${expenseId}`);
        break;
      case EExpenseType.ORIGIN_DESTINATION:
        navigate(`${urlBaseDetails}/originDestination/${expenseId}`);
        break;
      default:
        navigate(`${urlBaseDetails}/${expenseId}`);
        break;
    }
  }

  return navigateExpenseDetails;
}
