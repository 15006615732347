import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 650px;
  display: grid;
  grid-row-gap: ${props => props.theme.spacings.xs2};
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  grid-template-columns: 100%;
  grid-row-gap: ${props => props.theme.spacings.xs2};
  gap: ${props => props.theme.spacings.xs3};
  justify-content: center;
`;

export const ToggleLabel = styled(Typography)`
  color: ${props => props.theme.colors.neutral[40]};
  font-weight: 600 !important;
`;

export const FieldDays = styled(TextField)`
  width: 182px;
`;

export const FieldsHeader = styled.div`
  display: grid;
  grid-template-columns: 150px 200px 200px;
  grid-template-areas: '. field-enabled field-required';
  gap: ${props => props.theme.spacings.xs3};
`;

export const FieldContent = styled.div`
  display: grid;
  grid-template-columns: 150px 200px 200px;
  grid-row-gap: ${props => props.theme.spacings.m};
  gap: ${props => props.theme.spacings.xs3};
  align-items: center;
`;

export const HeaderDescription = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
  font-weight: 700 !important;
  text-align: center;

  &:first-child {
    grid-area: field-enabled;
  }
  &:last-child {
    grid-area: field-required;
  }
`;

export const InfoMandatoryDescription = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
`;

export const InfoMandatoryDescriptionAlert = styled(Typography)`
  color: ${props => props.theme.colors.feedback.error[40]};
`;

export const MandatoryInfoContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs5};
`;
