import { IconButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ModalBackgroundMask = styled.div`
  background-color: ${props => props.theme.colors.neutral.dark.pure};
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
`;

export const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
`;

export const ModalButtonClose = styled(IconButton)`
  position: absolute;
  top: ${props => props.theme.spacings.m};
  right: ${props => props.theme.spacings.m};
  opacity: 0.8;
  z-index: 10000;
`;

export const CarouselContainer = styled.div`
  max-width: 90%;
  margin: ${props => props.theme.spacings.l} auto 0px auto;
  text-align: center;
`;

export const ImageContainer = styled.div`
  height: calc(100vh - 150px);
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const Frame = styled.iframe`
  height: 100%;
  width: 1000px;
`;

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderContent = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs4};
  grid-template-rows: min-content min-content;

  div {
    margin: auto;
  }
`;

export const DescriptionLoading = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[100]};
`;
