import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

export interface SectionTitleProps {
  /**
   * Sets the title
   * @default Title
   */
  title: string;

  /**
   * Sets the text link
   */
  linkLabel?: string;

  /**
   * If true shows the icon next to the link
   *  @default true
   */
  showLinkIcon?: boolean;

  /**
   * Sets the event
   */
  onClick(): void;
}

export const SectionTitle = ({ title, linkLabel, showLinkIcon, onClick }: SectionTitleProps) => {
  return (
    <SC.Container>
      <SC.Title variant="headline8">{title}</SC.Title>
      {linkLabel && (
        <SC.LinkContainer>
          <LinkButton variant="default" onClick={onClick}>
            {linkLabel} {showLinkIcon && <SC.ArrowRightIcon size={18} name="IconArrowRight" />}
          </LinkButton>
        </SC.LinkContainer>
      )}
    </SC.Container>
  );
};
