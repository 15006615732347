import { EAnalyticsType } from '@containers/Analytics/context/types/filters';
import { useTranslation } from '@locale/Translator';

import { AnalyticsGroup } from '../../AnalyticsGroup/AnalyticsGroup';
import { AnalyticsSection } from '../AnalyticsSection';
import * as SC from './styled';

export function AnalyticsExpenseSection() {
  const { t } = useTranslation();
  const translationBase: string = 'analytics.sections.expenses';
  return (
    <SC.Container>
      <AnalyticsGroup
        header={{
          title: t(`${translationBase}.title`),
          caption: t(`${translationBase}.caption`),
          icon: 'IconReceipt2',
        }}>
        <AnalyticsSection
          key={`expense-overview`}
          analyticsType={EAnalyticsType.EXPENSES_OVERVIEW}
          translatePathBase={'analytics.expenseOverview'}
        />
        <AnalyticsSection
          key={`expense-categories`}
          analyticsType={EAnalyticsType.EXPENSES_BY_CATEGORY}
          translatePathBase={'analytics.expenseCategories'}
        />
        <AnalyticsSection
          key={`expense-by-requester`}
          analyticsType={EAnalyticsType.EXPENSES_BY_USER}
          translatePathBase={'analytics.expenseRequester'}
        />
        <AnalyticsSection
          key={`expense-corporate-card-overview`}
          analyticsType={EAnalyticsType.CORPORATE_CARD_OVERVIEW}
          translatePathBase={'analytics.expenseCorporateCardOverview'}
        />
        <AnalyticsSection
          key={`expense-corporate-card-by-user`}
          analyticsType={EAnalyticsType.CORPORATE_CARD_BY_USER}
          translatePathBase={'analytics.expenseCorporateCardPendenciesPerUser'}
        />
      </AnalyticsGroup>
    </SC.Container>
  );
}
