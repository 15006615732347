import { Skeleton } from '@mui/material';

import * as SC from './styled';

export const ModalReportSelectionSkeleton = () => (
  <SC.Container>
    <SC.SelectContent>
      <Skeleton variant="rounded" height={58} width={650} />
    </SC.SelectContent>
    <SC.LinkContent>
      <Skeleton variant="rounded" height={20} width={127} />
    </SC.LinkContent>
  </SC.Container>
);
