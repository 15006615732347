import { MileageGeneralPreview } from '@containers/Customizations/components/organisms/Policies/MileageGeneralPreview';
import { IPolicy } from '@containers/Policies/context/types/types';
import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { PolicyRuleSection } from '../../molecules/PolicyRuleSection/PolicyRuleSection';
import MileagePolicyRates from './MileagePolicyRates/MileagePolicyRates';

interface IProps {
  policyId: IPolicy['id'];
  onCloseRule(): void;
}

export function PolicyMileage({ policyId, onCloseRule }: IProps) {
  const t = useTranslate('policy.register.mileage');

  return (
    <PolicyRuleSection
      icon="IconMapPin"
      title={t('title')}
      caption={t('caption')}
      action={<IconButton onClick={onCloseRule} variant="line" icon="IconX" size="small" />}>
      <Box mt={2}>
        <MileageGeneralPreview policyId={Number(policyId)} isPolicyLoading={false} />
      </Box>

      <MileagePolicyRates policyId={policyId} />
    </PolicyRuleSection>
  );
}
