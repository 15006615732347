import { useState } from 'react';
import { IReport } from '@containers/Reports/context/types';
import { useSubmitReport } from '@containers/Reports/hooks/useSubmitReport';
import { expensesBaseUrl } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { ServiceException } from '@shared/hooks/service/error.type';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

type Props = {
  reportId: number;
};

export function useHandleSubmitReport(props: Props) {
  const t = useTranslate('organisms.reportDetailsPreview.feedback');
  const navigate = useNavigate();

  const [submitAlertMessage, setSubmitAlertMessage] = useState<string | null>(null);

  const [splittedReports, setSplittedReports] = useState<IReport[]>([]);

  const { submit, isLoading } = useSubmitReport();

  function handleError(error: ServiceException) {
    if (error.code === 'REPORT_SPLIT_NOT_ALLOWED') {
      return setSubmitAlertMessage(error.message);
    }

    setSubmitAlertMessage(null);

    return showToast({ type: 'error', message: error?.message });
  }

  async function handleSubmit(allowSplit: boolean) {
    try {
      const response = await submit(props.reportId, allowSplit);

      setSubmitAlertMessage(null);

      if (response.reports?.length > 1) {
        return setSplittedReports(response.reports);
      }

      showToast({
        message: t('sendReport.singularForm.success'),
        type: 'success',
      });

      return navigate(`${expensesBaseUrl}/reports`);
    } catch (err) {
      handleError(err);
    }
  }

  function onCancelAction() {
    setSubmitAlertMessage(null);
  }

  return {
    handleSubmit,
    submitAlertMessage,
    isSubmitting: isLoading,
    onCancelAction,
    splittedReports,
  };
}
