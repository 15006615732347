import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useDistanceHelper } from '@shared/hooks/useDistanceHelper';

import { DistanceRoutesPrimary } from './DistanceRoutesPrimary/DistanceRoutesPrimary';
import { DistanceRoutesSecondary } from './DistanceRoutesSecondary/DistanceRoutesSecondary';
interface IProps {
  distance: number;
  isLoading: boolean;
  ratePerKM?: number;
  variant: 'primary' | 'secondary';
}

export function DistanceRoutes({ variant = 'primary', distance, ratePerKM, isLoading }: IProps) {
  const { getDistanceDecimalFormatter } = useDistanceHelper();
  const { getValueWithCurrency } = useValueFormatter();
  const _distance: string = getDistanceDecimalFormatter(distance);
  const _ratePerKM: string = getValueWithCurrency({ value: ratePerKM || 0 });

  if (variant === 'primary') {
    return <DistanceRoutesPrimary distance={_distance} ratePerKM={_ratePerKM} isLoading={isLoading} />;
  }

  return <DistanceRoutesSecondary distance={_distance} isLoading={isLoading} />;
}
