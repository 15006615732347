import MandatoryInfo from '@atoms/MandatoryInfo/MandatoryInfo';
import { useClients } from '@containers/Clients/hooks/rest/useClients';
import { useAllCostCenters } from '@containers/CostCenters/hooks/rest/useAllCostCenters';
import { ProjectFormData } from '@containers/Projects/validators/projectFormSchema';
import { BoxAdornment, DatePicker, Skeleton, TextField, Toggle, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Autocomplete, Box, Grid } from '@mui/material';
import { useEmployeesSelectionList } from '@shared/hooks/employees/useEmployeesSelectionList';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import * as SC from './styled';

type Props = {
  isLoading: boolean;
};

export const ProjectRegisterGeneralData = ({ isLoading }: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProjectFormData>();
  const { clients, isLoading: isLoadingClients } = useClients({ page: 0, searchTerm: '' });
  const { employees: users, isLoading: isLoadingUsers } = useEmployeesSelectionList();
  const { costCentersCompany: costCenters, isLoading: isLoadingCostCenters } = useAllCostCenters({
    page: 0,
    searchName: '',
  });
  const isLoadingFields = isLoading || isLoadingClients || isLoadingCostCenters || isLoadingUsers;

  return (
    <SC.Container>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography variant="headline8">{t('pages.projectRegister.sections.generalData')}</Typography>

        <MandatoryInfo />
      </Box>

      {!isLoadingFields ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={`${t('pages.projectRegister.fields.projectName')} *`}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="clientId"
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  value={field.value || null}
                  options={clients.map(client => client.id.toString())}
                  getOptionLabel={id => clients.find(client => client.id.toString() === id.toString())?.name ?? ''}
                  fullWidth
                  onChange={(e, newValue) => field.onChange(newValue)}
                  renderInput={props => (
                    <TextField
                      {...props}
                      label={t('pages.projectRegister.fields.client')}
                      fullWidth
                      disabled={isLoadingClients}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="costCenterId"
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  value={field.value || null}
                  options={costCenters.map(costCenter => costCenter.id.toString())}
                  getOptionLabel={id => costCenters.find(costCenter => costCenter.id.toString() === id)?.name ?? ''}
                  fullWidth
                  onChange={(e, newValue) => field.onChange(newValue)}
                  renderInput={props => (
                    <TextField
                      {...props}
                      label={t('pages.projectRegister.fields.costCenter')}
                      fullWidth
                      disabled={isLoadingCostCenters}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="startAt"
              render={({ field: { ref, onChange, ...field }, fieldState }) => (
                <DatePicker
                  {...field}
                  label={t('pages.projectRegister.fields.startAt')}
                  fullWidth
                  onDateChange={date => onChange(date?.toDate())}
                  inputRef={ref}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="endAt"
              render={({ field: { ref, onChange, ...field }, fieldState }) => (
                <DatePicker
                  {...field}
                  label={t('pages.projectRegister.fields.endAt')}
                  fullWidth
                  onDateChange={date => onChange(date?.toDate())}
                  inputRef={ref}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="accountable"
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={field.value || null}
                  options={users.map(user => user.id.toString())}
                  getOptionLabel={id => users.find(user => user.id.toString() === id)?.name ?? ''}
                  fullWidth
                  onChange={(e, newValue) => field.onChange(newValue)}
                  renderInput={props => (
                    <TextField
                      {...props}
                      label={t('pages.projectRegister.fields.accountable')}
                      fullWidth
                      disabled={isLoadingUsers}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="externalCode"
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('pages.projectRegister.fields.code')}
                  fullWidth
                  helperText={t('pages.projectRegister.fields.codeHelperText')}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="active"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <BoxAdornment
                  title={t('pages.projectRegister.fields.activeProject.title')}
                  description={t('pages.projectRegister.fields.activeProject.description')}
                  leftAdornment={<Toggle checked={value} onChange={(_, newValue) => onChange(newValue)} />}
                  onClick={() => onChange(!value)}
                  style={{ width: '100%', textAlign: 'left' }}
                />
              )}
            />
          </Grid>
        </Grid>
      ) : (
        <Skeleton width={'100%'} height={500} />
      )}
    </SC.Container>
  );
};
