import { useAccountingPendingExpensesStatus } from '@containers/Financial/hooks/dataSources/useAccountingPendingExpensesStatus';

import { PendingExpensesTable } from '../../Users/PendingExpenses/PendingExpensesTable/PendingExpensesTable';
import { AccountingPendingCardGroup } from './AccountingPendingCardGroup/AccountingPendingCardGroup';
import * as SC from './styled';

export const AccountingPendingExpenses = () => {
  const { data, isLoading: isStatusLoading } = useAccountingPendingExpensesStatus({ shouldFetch: true });

  return (
    <SC.Container>
      <AccountingPendingCardGroup totalizers={data} isLoading={isStatusLoading} />
      <PendingExpensesTable />
    </SC.Container>
  );
};
