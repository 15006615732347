import {
  DocumentNode,
  NoInfer,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery as useQueryApolloClient,
} from '@apollo/client';

import { useHeaderDelegateOptions } from '../useHeaderDelegateOptions';

interface IQueryOptions<TData, TVariables> extends QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> {
  delegationAllowed?: boolean;
}

export function useQuery<TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: IQueryOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const { headers } = useHeaderDelegateOptions(options?.context?.headers, options?.delegationAllowed);

  return useQueryApolloClient(query, {
    ...options,
    context: {
      ...options?.context,
      headers,
    },
  });
}
