import { useState } from 'react';
import { FilterType } from '@atoms/FilterFactory';
import { IReport, IReportFilters } from '@containers/Reports/context/types';
import { useReportMutate } from '@containers/Reports/hooks/useReportMutate';
import { useReports } from '@containers/Reports/hooks/useReports';
import { useSelectionRows } from '@containers/Reports/hooks/useSelectionRows';
import { GridFilters } from '@molecules/GridFilters';
import { usePagination } from '@molecules/GridPagination/kooks/usePagination';
import { useReportDetailsDriver } from '@shared/hooks/navigation/useReportDetailsDriver';
import { useReportStatusesPermissions } from '@shared/hooks/permissions/systemPermissions/useReportStatusesPemission';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useTracking } from '@shared/hooks/useTracking';

import { NewReportModal } from '../../molecules/NewReportModal';
import { ReportGrid } from '../ReportGrid/ReportGrid';
import * as SC from './styled';

interface ReportGridContainerProps {
  onDeleteReportClick(reportId: IReport['id']): void;
}

export const ReportGridContainer = ({ onDeleteReportClick }: ReportGridContainerProps) => {
  const trackingEvent = useTracking();
  const t = useTranslate('organisms.reportGrid');
  const pagination = usePagination({ currentPage: 1, itemsPerPageShow: 20 });
  const [selectedFilters, setSelectedFilters] = useState<IReportFilters>({
    search: '',
    status: [],
  });
  const { reports, reportsTotalItems, reportsIsLoading } = useReports({
    limit: pagination.rowsPerPage,
    page: pagination.selectedPage,
    ...selectedFilters,
  });
  const { isSelectedAll, onSelectItemClick, selectedItems, toggleSelectAll, unSelectAll } = useSelectionRows({
    reports,
  });
  const { updateReport, isLoading: isLoadingUpdateReport } = useReportMutate();
  const [reportSelected, setReportSelected] = useState<IReport>(null);
  const [isOpenUpdateReportModal, setIsOpenUpdateReportModal] = useState<boolean>(false);
  const navigateReportDetails = useReportDetailsDriver();
  const reportStatus = useReportStatusesPermissions();

  const handleViewReport = (reportId: number) => {
    navigateReportDetails(reportId);
  };

  const onRowsPerPageClick = itemsPerPage => {
    pagination.onRowsPerPageClick(itemsPerPage);
    unSelectAll();
  };

  function onEditReportClick(id: IReport['id']) {
    setReportSelected(reports.find(report => report.id == id));
    setIsOpenUpdateReportModal(true);
  }

  async function handleReportUpdate(report: Pick<IReport, 'name' | 'description'>) {
    trackingEvent('report_update_clicked', { reportId: String(reportSelected?.id) });

    try {
      await updateReport(reportSelected?.id, report);
      setIsOpenUpdateReportModal(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSearch = (value: string) => {
    if (value !== undefined && value !== selectedFilters.search) {
      setSelectedFilters(prevState => ({ ...prevState, search: value }));
      unSelectAll();
      pagination.onPageClick(1);
    }
  };

  const handleStatusChange = value => {
    setSelectedFilters(prevState => ({ ...prevState, status: value }));
    unSelectAll();
    pagination.onPageClick(1);
  };

  return (
    <SC.ReportsGridContainer>
      <GridFilters
        filters={[
          {
            label: t('status'),
            onClick: handleStatusChange,
            type: FilterType.MULTIPLE,
            options: reportStatus,
            selectedOptions: selectedFilters.status,
          },
        ]}
        showSearchField
        onSearchChange={handleSearch}
        labelSearchField={t('search')}
        searchValue={selectedFilters.search}
        isLoading={reportsIsLoading}
        showMoreFiltersButton={false}
      />
      <ReportGrid
        reports={reports}
        isLoading={reportsIsLoading}
        pagination={{ ...pagination, isLoading: reportsIsLoading, totalItems: reportsTotalItems, onRowsPerPageClick }}
        selectedItems={selectedItems}
        isSelectedAll={isSelectedAll}
        onDeleteReport={onDeleteReportClick}
        onOrderByClick={() => {}}
        onSelectAllClick={toggleSelectAll}
        onSelectReport={onSelectItemClick}
        onViewReport={handleViewReport}
        onEditReport={(id: IReport['id']) => onEditReportClick(id)}
      />
      <NewReportModal
        description={reportSelected?.description}
        name={reportSelected?.name}
        actionType="update"
        isLoading={isLoadingUpdateReport}
        open={isOpenUpdateReportModal}
        onActionClick={handleReportUpdate}
        onCancelClick={() => setIsOpenUpdateReportModal(false)}
      />
    </SC.ReportsGridContainer>
  );
};
