import { useAdvancesPolicySettings } from './useAdvancesPolicySettings';
import { useUpdateAdvancePolicySettings } from './useUpdateAdvancePolicySettings';

type Props = {
  policyId: string;
};

export function useDeleteAdvanceLimit({ policyId }: Props) {
  const { handleUpdate, isLoading: isSaving } = useUpdateAdvancePolicySettings(policyId);
  const { policy, isLoading: isLoadingPolicy } = useAdvancesPolicySettings(policyId);

  async function deleteAdvanceLimit(currencyCode: string) {
    const limits = policy.limits.filter(l => l.currencyCode !== currencyCode);

    await handleUpdate({ limits });
  }

  return {
    deleteAdvanceLimit,
    isLoading: isSaving || isLoadingPolicy,
  };
}
