import { NetworkStatus } from '@apollo/client';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { getExcelBase64ReportsForFinance } from '../context/queries/reports';
import { IReportForFinanceFilter } from '../context/types/reports';

interface IGetExcelBase64Response {
  getExcelBase64ReportsForFinance: string;
}

interface IGetExcelBase64Request {
  filters: IReportForFinanceFilter;
}

export const useReportExportExcel = () => {
  const [exportExcel, { loading, data, error, networkStatus }] = useLazyQuery<
    IGetExcelBase64Response,
    IGetExcelBase64Request
  >(getExcelBase64ReportsForFinance, { notifyOnNetworkStatusChange: true });

  return {
    exportExcel,
    base64File: data?.getExcelBase64ReportsForFinance,
    isLoading: loading || networkStatus === NetworkStatus.loading,
    error,
  };
};
