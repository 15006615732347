import { IExpenses } from '@containers/Expenses/context/types';
import { tableControllers } from '@flash-tecnologia/hros-web-ui-v2';
import { HeaderBulkActions } from '@organisms/Table/HeaderBulkActions/HeaderBulkActions';

export enum EAccessor {
  CATEGORY = 'category',
  REFERENCE_ID = 'referenceId',
  VALUE = 'value',
  DATE = 'expenseDate',
  PLACE = 'establishment',
  RECEIPT = 'attachments',
}

export enum EActionOptions {
  VIEW = 'view',
  EDIT = 'edit',
  UNLINK = 'unlink',
  DELETE = 'delete',
}

export enum EBatchActionOptions {
  UNLINK = 'unlink',
  DELETE = 'delete',
}

export enum EIconAction {
  VIEW = 'IconEye',
  EDIT = 'IconPencil',
  UNLINK = 'IconUnlink',
  DELETE = 'IconTrash',
}

export type TColumns = Parameters<typeof tableControllers.useTableColumns<IExpenses>>[0]['columns'];

export type TOptions = Parameters<typeof tableControllers.useTableColumns<IExpenses>>[0]['options'];

export type TTable = Parameters<typeof tableControllers.useTableColumns<IExpenses>>;

export type TBatchActions = Parameters<typeof HeaderBulkActions>[0]['actions'];
