import styled from 'styled-components';

export const Container = styled.div({
  width: '100%',

  '.data-grid-table-container.sticky': {
    '.data-grid-table-header-container': {
      position: 'relative',
    },
  },
});
