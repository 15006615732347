import { config } from './config';

//base urls for access via project menu
export const baseUrl = '/expenseManagement';
export const expensesBaseUrl = `${baseUrl}/expenses`;
export const expensesApprovalsBaseURL = `${baseUrl}/approvals`;
export const expensesCustomizationsBaseURL = `${baseUrl}/customizations`;
export const expensesAdvancesBaseURL = `${baseUrl}/advances`;
export const expensesAccountingBaseURL = `${baseUrl}/accounting`;
export const expensesAnalyticsBaseURL = `${baseUrl}/analytics`;
export const settingsBaseURL = `${baseUrl}/settings`;
export const clientsBaseURL = `${settingsBaseURL}/clients`;
export const projectsBaseURL = `${settingsBaseURL}/projects`;
export const costCentersBaseURL = `${settingsBaseURL}/costCenters`;
export const approvalFlowsBaseURL = `${baseUrl}/approvalFlows`;
export const subsidiariesBaseURL = `${baseUrl}/subsidiaries`;

export const reportListRowPrintItems = 8;
export const reportActionsSkeletonPrintItems = 3;
export const skeletonPrintFieldsNumber = 10;
export const skeletonPrintGridRows = 10;
export const attachExpenseModalNumber = 4;
export const skeletonCategorySwitchCardPrintItems = 10;

// Next line is deprecated (modified by expense.cardId)
export const paymentMethodFlashCard = config.BUILD_ENV === 'production' ? 854 : 236;
export const paymentMethodRefundable = config.BUILD_ENV === 'production' ? 875 : 238;
export const defaultCurrencyId = config.BUILD_ENV === 'production' ? 743 : 697;
export const defaultCurrencySymbol = 'R$';
export const defaultCurrencyCode = 'BRL';
export const defaultCategoryRouteCode = 4;
export const defaultCurrency = {
  id: defaultCurrencyId,
  code: defaultCurrencyCode,
  symbol: defaultCurrencySymbol,
};

export const flashCardPaymentMethodExternalCode = 'FLCARD';
export const refundCardPaymentMethodExternalCode = 'REEM';
export const supplierMethodExternalCode = 'CAP';

export const companyNanoIdKeyLS = 'companyNanoId';
