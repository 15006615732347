import styled from 'styled-components';

export const Container = styled.div`
  max-height: calc(100vh - 400px);
  max-width: 800px;
  padding-bottom: ${({ theme }) => theme.spacings.m};
`;

export const Content = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings.m};
`;
