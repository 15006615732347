import { IconButton, Icons, Menu, Tab } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ReportDetailsPreviewContainer = styled.div`
  width: 100%;
`;

export const TabGrid = styled(Tab)`
  margin-top: ${props => props.theme.spacings.m};

  .MuiTab-root {
    text-transform: inherit;
    padding-bottom: ${props => props.theme.spacings.xs5};
  }
`;

export const GridContainer = styled.div`
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.theme.spacings.xs};
  width: 100%;
`;

export const ActionsButton = styled(Menu).attrs({
  type: 'default',
})`
  width: auto;
`;

export const IconDots = styled(IconButton).attrs({
  icon: 'IconDots',
  size: 'small',
  variant: 'line',
})`
  transform: rotate(90deg);
`;

export const OccurrencesContainer = styled.div`
  width: 871px;
  margin-bottom: ${props => props.theme.spacings.s};
`;

export const IconAdd = styled(Icons)`
  fill: ${props => props.theme.colors.neutral[100]};
`;
