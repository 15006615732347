import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ConditionalRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  width: 100%;
`;

export const ContainerConditionalFiled = styled.div`
  .select-custom {
    .select-field-custom-theme {
      max-width: 155px;
    }
  }
`;

export const MainLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MainLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700 !important;
`;

export const ContainerField = styled.div`
  width: 100%;

  .criteria-field-text {
    .MuiFilledInput-root {
      border: ${({ theme }) => theme.borders.width.s} solid ${({ theme }) => theme.colors.neutral[80]};
    }

    .MuiInputLabel-filled {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.neutral[75]};

      &:hover {
        font-weight: 500;
        color: ${({ theme }) => theme.colors.neutral[75]};
      }
    }
    .MuiInputLabel-root {
      transition: none;
    }

    .Mui-disabled {
      background: ${({ theme }) => theme.colors.neutral[95]};
      border-radius: ${({ theme }) => theme.borders.radius.m};
    }

    .Mui-disabled.MuiFilledInput-root {
      border: ${({ theme }) => theme.borders.width.s} solid ${({ theme }) => theme.colors.neutral[80]};
    }
  }
`;
