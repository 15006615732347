import { useState } from 'react';
import { AdvanceLimitRegisterModal } from '@containers/Customizations/components/molecules/AdvanceLimitRegisterModal';
import { DeleteAdvanceLimitConfirmModal } from '@containers/Customizations/components/molecules/DeleteAdvanceLimitConfirmaModal';
import { FieldRegister } from '@containers/Policies/componentes/molecules/FieldRegister/FieldRegister';
import { AdvancePolicy, AdvancePolicyLimit } from '@containers/Policies/context/types/advances';
import { useDeleteAdvanceLimit } from '@containers/Policies/hooks/advances/useDeleteAdvanceLimit';
import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { Box, Grid } from '@mui/material';
import { showToast } from '@shared/helpers/toast';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { AdvanceLimitItem } from './AdvanceLimitItem/AdvanceLimitItem';
import * as SC from './styled';

type Props = {
  policyId: string | undefined;
  isPolicyLoading: boolean;
  advancePolicy: AdvancePolicy | undefined;
};

export const PolicyAdvancesLimits = ({ policyId, isPolicyLoading, advancePolicy }: Props) => {
  const t = useTranslate('policy.register.advances');

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  const [editingLimit, setEditingLimit] = useState<AdvancePolicyLimit>();
  const [limitToDelete, setLimitToDelete] = useState<AdvancePolicyLimit>();

  const { deleteAdvanceLimit, isLoading: isDeleting } = useDeleteAdvanceLimit({ policyId });

  async function handleDeletePolicyLimit() {
    try {
      await deleteAdvanceLimit(limitToDelete.currencyCode);

      showToast({
        message: t('limits.feedbacks.advanceLimitDeletedSuccessfully'),
        type: 'success',
      });
    } catch (err) {
      showToast({
        message: err.message || t('limits.feedbacks.errorDeletingAdvanceLimit'),
        type: 'error',
      });
    } finally {
      setLimitToDelete(undefined);
    }
  }

  return (
    <div>
      <Box mb={3}>
        <SC.Title variant="headline8">{t('limits.title')}</SC.Title>
        <SC.Caption variant="body3">{t('limits.caption')}</SC.Caption>
      </Box>

      <Box mb={4}>
        <Button
          size="medium"
          variant="primary"
          onClick={() => {
            setEditingLimit(undefined);
            setIsRegisterModalOpen(true);
          }}>
          <Icons name="IconPlus" fill="transparent" />

          {t('limits.actions.addLimit')}
        </Button>
      </Box>

      <FieldRegister isLoading={isPolicyLoading} height={200}>
        <Grid container spacing={2}>
          {advancePolicy?.limits?.map(limit => (
            <Grid item md={6} sm={12} key={limit.currencyCode}>
              <AdvanceLimitItem
                currency={limit.currencyCode}
                value={limit.value}
                onDeleteClick={() => {
                  setLimitToDelete(limit);
                }}
                onEditClick={() => {
                  setEditingLimit(limit);
                  setIsRegisterModalOpen(true);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </FieldRegister>

      <DeleteAdvanceLimitConfirmModal
        open={!!limitToDelete}
        onCancel={() => setLimitToDelete(undefined)}
        onDelete={() => handleDeletePolicyLimit()}
        isLoading={isDeleting}
      />

      <AdvanceLimitRegisterModal
        open={isRegisterModalOpen}
        onClose={() => setIsRegisterModalOpen(false)}
        policyId={policyId}
        limit={editingLimit}
      />
    </div>
  );
};
