import { useEffect, useState } from 'react';
import { IconButton, Icons, IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import { DropdownItem, DropdownItemProps } from '@molecules/DropdownItem';
import { Popover } from '@mui/material';

import * as SC from './styled';

export type OptionProps = {
  dropDownProps: DropdownItemProps;
  iconRight?: IconTypes;
  key?: string;
  isPopover?: boolean;
  onClick: () => void;
};

type MenuPageActionsProps = {
  options: OptionProps[];
  open?: boolean;
  children?: React.ReactNode;
  customIcon?: IconTypes;
  onOpen?: () => void;
  onClose?: () => void;
};

export function MenuPageAction({ options, open, children, customIcon, onClose, onOpen }: MenuPageActionsProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(open);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
    onOpen?.();
  }

  function handleClose() {
    setPopoverOpen(false);
    onClose?.();
  }

  useEffect(() => {
    setPopoverOpen(open);
  }, [open]);

  if (!Boolean(options?.length)) {
    return <></>;
  }

  return (
    <>
      <IconButton
        size="small"
        icon={customIcon ? customIcon : options?.length === 1 ? 'IconSettings' : 'IconDotsVertical'}
        variant="line"
        onClick={e => {
          if (options?.length === 1) {
            options?.[0]?.onClick();
            options?.[0]?.isPopover && handleClick(e);
          } else {
            handleClick(e);
          }
        }}
      />
      {popoverOpen && (options.length || children) && (
        <Popover
          id={'page-action-popover'}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <SC.ContainerPopover>
            {children
              ? children
              : popoverOpen &&
                options?.map(({ onClick, dropDownProps, iconRight }, index) => (
                  <SC.ItemMenu key={index}>
                    <DropdownItem {...dropDownProps} onClick={onClick} />
                    {iconRight && <Icons name={iconRight} size={24} />}
                  </SC.ItemMenu>
                ))}
          </SC.ContainerPopover>
        </Popover>
      )}
    </>
  );
}
