import { useEffect, useState } from 'react';
import { Loader } from '@atoms/Loader';
import { AssignUsers } from '@containers/CostCenters/components/organisms/AssignUsers';
import { CostCenterRegisterGeneralData } from '@containers/CostCenters/components/organisms/CostCenterRegisterGeneralData/CostCenterRegisterGeneralData';
import { useByIdCostCenters } from '@containers/CostCenters/hooks/rest/useByIdCostCenters';
import { useSaveCostCenter } from '@containers/CostCenters/hooks/rest/useSaveCostCenter';
import { CostCenterForm, costCenterFormSchema } from '@containers/CostCenters/validators/CostCenterFormSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { BasePage } from '@organisms/BasePage/BasePage';
import { costCentersBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { CostCenterRegisterFooterPage } from './CostCenterRegisterFooterPage/CostCenterRegisterFooterPage';
import { CostCenterRegisterProgressHeader } from './CostCenterRegisterProgressHeader';
import * as SC from './styled';

export const CostCenterRegister = () => {
  const t = useTranslate('pages.costCenterRegister');
  const navigate = useNavigate();
  const { costCenterId } = useParams<{ costCenterId: string }>();
  const isEditing = !!costCenterId;
  const { costCenter, isLoading: isLoadingCostCenter } = useByIdCostCenters(costCenterId);
  const { handleSave, isLoading: saveIsLoading } = useSaveCostCenter();
  const costCenterForm = useForm<CostCenterForm>({
    resolver: yupResolver(costCenterFormSchema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      externalCode: '',
      active: true,
      userIds: [],
    },
  });
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (isLoadingCostCenter || !isEditing) return;

    if (costCenter) {
      return costCenterForm.reset({
        ...costCenter,
        departmentIds: costCenter.departments?.map(d => d.id) || [],
        userIds: costCenter?.users?.map(user => user.id) || [],
      });
    }

    return navigate(costCentersBaseURL, { replace: true });
  }, [isLoadingCostCenter, costCenter, isEditing, navigate, costCenterForm]);

  function onSubmit(data: CostCenterForm) {
    if (step === 0) {
      setStep(step + 1);
    } else {
      handleSave(data, costCenterId);
    }
  }

  return (
    <>
      <CostCenterRegisterProgressHeader isEditing={isEditing} step={step} />
      <FormProvider {...costCenterForm}>
        <form onSubmit={costCenterForm.handleSubmit(onSubmit)}>
          <BasePage
            footerPage={<CostCenterRegisterFooterPage step={step} setStep={setStep} />}
            headerPage={{
              title: !isEditing ? t('manualAddCostCenter') : t('editCostCenter'),
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={3} sx={{ mb: 3 }}>
                <SC.SectionTitle variant="headline7">{t(`steps.pageContent.${step}.title`)}</SC.SectionTitle>
                <SC.Instruction variant="body3">{t(`steps.pageContent.${step}.subTitle`)}</SC.Instruction>
              </Grid>
              <Grid item xs={12} md={12} lg={9} xl={8}>
                {step === 0 ? (
                  <CostCenterRegisterGeneralData isLoading={isLoadingCostCenter} />
                ) : (
                  <SC.ContainerUsers>
                    <AssignUsers
                      userIds={costCenter?.users?.map(user => user.id) || []}
                      isLoading={isLoadingCostCenter}
                    />
                  </SC.ContainerUsers>
                )}
              </Grid>
            </Grid>
            <Loader show={saveIsLoading} description={`${t('feedbacks.savingCostCenter')}...`} />
          </BasePage>
        </form>
      </FormProvider>
    </>
  );
};
