import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const WaypointContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs,
  alignItems: 'center',
}));

export const WaypointContainerSkeleton = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs,
  alignItems: 'center',
  padding: `${theme.spacings.xs3} 0`,
}));

export const IdentifierContainerSkeleton = styled.div(({ theme }) => ({
  padding: `${theme.spacings.xs3} 0`,
  '&:first-child': {
    paddingTop: '26px',
  },
}));

export const ViewOnlyContainer = styled.div({
  width: '100%',
});

export const ViewOnlyLabel = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[50],
  marginBottom: theme.spacings.xs5,
  fontWeight: '700!important',
  fontSize: '16px',
  lineHeight: '18.55px',
}));

export const ViewOnlyDescription = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[30],
  fontWeight: '600!important',
}));
