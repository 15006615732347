import { useMemo, useState } from 'react';
import { EExpenseType, EReportStatus, IExpenses } from '@containers/Expenses/context/types';
import { IconTypes, tableControllers } from '@flash-tecnologia/hros-web-ui-v2';
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';
import { useSelectedTable } from '@organisms/Table/hooks/useSelectedTable';
import { MainRowColumn } from '@organisms/Table/MainRowColumn/MainRowColumn';
import { expensesAccountingBaseURL, expensesApprovalsBaseURL } from '@shared/constant';
import { useDateFormatter, useValueFormatter } from '@shared/hooks';
import { useExpensesPermissions } from '@shared/hooks/permissions/systemPermissions/useExpensesPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useCheckIsKmType } from '@shared/hooks/useCheckIsKmType';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { useTheme } from 'styled-components';

import { ExpenseReceipts } from '../ExpenseGrid/ExpenseReceipts/ExpenseReceipts';
import { useExpensesGridActions } from './hooks/useExpensesGridActions';
import {
  EAccessor,
  EActionOptions,
  EBatchActionOptions,
  EIconAction,
  TBatchActions,
  TColumns,
  TOptions,
} from './types';

type TProps = {
  data: IExpenses[];
  pagination: PaginationState;
  setPagination: (data: TProps['pagination']) => void;
};

export function TableHelper({ data = [], pagination, setPagination: onPaginationChange }: TProps) {
  const t = useTranslate('organisms.expenseGrid');
  const getCurrentUrlBase = useCurrentUrlBase();
  const urlBase = getCurrentUrlBase();
  const _isManagement = urlBase === expensesAccountingBaseURL || urlBase === expensesApprovalsBaseURL;
  const { colors } = useTheme();
  const { checkIsKmType } = useCheckIsKmType();
  const { getDateOnlyFormatter } = useDateFormatter();
  const { getValueWithCurrency } = useValueFormatter();
  const { getExpenseActions } = useExpensesPermissions();
  const expenseTable = tableControllers.useTableColumns({
    columns: useMemo(() => Object.values(EAccessor).map(value => getColumns(value)), []),
    options: getOptions(),
    data,
    onPaginationChange,
    pagination,
  });
  const { selectedIds, countSelectedRows, allSelected } = useSelectedTable<IExpenses>({
    selected: expenseTable.selected.selected,
    allSelected: expenseTable.selected.allSelected,
    rows: expenseTable.rows,
  });
  const actionsEvents = useExpensesGridActions({
    unSelectAll: () => expenseTable.resetSelected(),
  });
  const disableMultiple: boolean = useMemo(
    () =>
      selectedIds?.some(id =>
        Boolean(
          data.find(expense => expense.id === id && expense?.report && expense?.report?.status !== EReportStatus.OPEN),
        ),
      ) || false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds],
  );
  const [isBatchAction, setIsBatchAction] = useState(false);

  function getColumns(accessor: EAccessor): TColumns[0] {
    return {
      header: t(accessor),
      accessorKey: accessor,
      minSize: accessor === EAccessor.CATEGORY ? 200 : null,
      cell: context => {
        const expense = context.row.original;

        function getIconNameCategory(): IconTypes {
          if (expense.cardId || expense.card?.id || expense.card?.lastFourDigits?.length === 16)
            return 'IconCreditCard';
          if (expense.type === EExpenseType.GPS) return 'IconGps';
          if (checkIsKmType(expense?.type)) return 'IconMapPin';
          return 'IconReceipt';
        }

        switch (accessor) {
          case EAccessor.CATEGORY:
            return (
              <MainRowColumn
                title={{
                  description: expense.category?.description || t('uninformedCategory'),
                }}
                caption={{
                  description: t(`${expense.violationNumber > 1 ? 'occurrences' : 'occurrence'}`),
                  icon: 'IconPaperclip',
                  color: colors.feedback.error[40],
                }}
                icon={{
                  name: getIconNameCategory(),
                  variant: 'default',
                }}
              />
            );
          case EAccessor.PLACE:
            return expense?.establishment?.[accessor] || '-';
          case EAccessor.DATE:
            return getDateOnlyFormatter(expense?.expenseDate);
          case EAccessor.VALUE:
            return getValueWithCurrency({
              value: expense?.value || 0,
              currencyPrefix: expense?.currencyPrefix || 'R$',
            });
          case EAccessor.RECEIPT:
            const images = expense.attachments.length
              ? expense.attachments
              : expense?.route?.imagePath
              ? [{ path: expense.route.imagePath }]
              : [];

            return <ExpenseReceipts attachments={images} attachmentsNumber={images.length} />;
          default:
            return expense[accessor] || '';
        }
      },
    };
  }

  function getBatchActionsOptions(): TBatchActions {
    return Object.keys(EBatchActionOptions).map(key => ({
      allowed: EBatchActionOptions[key] === EBatchActionOptions.DELETE,
      tooltip: t(`actionsGrid.tooltip.${EBatchActionOptions[key]}`),
      disabled: disableMultiple,
      label: t(
        `actionsGrid.pluralForm.${
          EBatchActionOptions[key] == EBatchActionOptions.UNLINK && _isManagement ? 'remove' : EBatchActionOptions[key]
        }`,
      ),
      icon: EIconAction[key],
      key: EBatchActionOptions[key],
      onClick: () => {
        setIsBatchAction(true);
        actionsEvents.onExpenseActionClick(EBatchActionOptions[key], selectedIds);
      },
    }));
  }

  function getOptions(): TOptions {
    return {
      selectable: true,
      actions: context => {
        const expense = context.row.original;
        const allowedActions = getExpenseActions(expense?.type, expense?.report?.status);
        const actions: TOptions['actions'] = [];

        Object.keys(EActionOptions).forEach(action => {
          const currentAllowedAction = allowedActions?.[EActionOptions[action]];

          if (currentAllowedAction != undefined ? currentAllowedAction : true)
            actions.push({
              label: t(`actionsGrid.singularForm.${EActionOptions[action]}`),
              icon: EIconAction[action],
              key: EActionOptions[action],
              onClick: () => {
                setIsBatchAction(false);
                actionsEvents.onExpenseActionClick(EActionOptions[action], [expense.id], expense.type);
              },
            });
        });

        return actions;
      },
    };
  }

  return {
    tableHelper: expenseTable,
    actionsHelper: {
      events: actionsEvents,
      batchActionsOptions: getBatchActionsOptions(),
    },
    selectedIds: {
      ids: selectedIds,
      countSelectedRows,
      allSelected,
      isBatchAction,
    },
  };
}
