import { createAdvance, updateAdvanceById } from '@containers/Advances/context/queries/register';
import { EAdvanceStatus, IAdvance } from '@containers/Advances/context/types/advance';
import { IAdvanceInput } from '@containers/Advances/context/types/formFields';
import { expensesAdvancesBaseURL } from '@shared/constant';
import { getErrorToast } from '@shared/helpers/errorToast';
import { showToast } from '@shared/helpers/toast';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { useStatusSwitchBatchAdvances } from '../useStatusSwitchBatchAdvances';

export function useSaveAdvance() {
  const t = useTranslate('pages.advanceRegister.feedbacks');
  const navigate = useNavigate();
  const [createAdvanceMutate, { loading: createLoading }] = useMutation<IAdvance, { advance: IAdvanceInput }>(
    createAdvance,
  );
  const [updateAdvanceMutate, { loading: updateLoading }] = useMutation<
    IAdvance,
    { advance: IAdvanceInput; advanceId: number }
  >(updateAdvanceById);
  const { setStatusBatchAdvances, isLoading } = useStatusSwitchBatchAdvances();

  async function handleSave(advance: IAdvanceInput, advanceId?: number) {
    if (advanceId) {
      try {
        const response = await updateAdvanceMutate({
          variables: {
            advanceId,
            advance,
          },
          refetchQueries: ['GetAdvances'],
        });

        await setStatusBatchAdvances([advanceId], EAdvanceStatus.SUBMITTED);

        if (response) {
          showToast({ message: t('save'), type: 'success' });
          navigate(expensesAdvancesBaseURL);
        }
      } catch (err) {
        getErrorToast(err);
      }
    } else {
      try {
        const response = await createAdvanceMutate({
          variables: {
            advance,
          },
          refetchQueries: ['GetAdvances'],
        });

        if (response) {
          showToast({ message: t('create'), type: 'success' });
          navigate(expensesAdvancesBaseURL);
        }
      } catch (err) {
        getErrorToast(err);
      }
    }
  }

  return {
    handleSave,
    isLoading: createLoading || updateLoading || isLoading,
  };
}
