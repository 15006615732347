import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useDistanceHelper } from '@shared/hooks/useDistanceHelper';
import { useTranslation } from 'react-i18next';

import * as SC from './styled';

export interface IProps {
  totalValue: number;
  distance: number;
  ratePerKm: number;
}

export const ExpenseDetailsKilometer = ({ totalValue, distance, ratePerKm }: IProps) => {
  const { t } = useTranslation();
  const { getValueWithCurrency } = useValueFormatter();
  const { getDistanceDecimalFormatter } = useDistanceHelper();

  return (
    <SC.Container>
      <SC.Row>
        <SC.Description isBold variant="body3">
          {t('molecules.expenseDetailsKilometer.totalValue')}
        </SC.Description>
        <SC.Description isBold variant="body3">
          {getValueWithCurrency({ value: totalValue || 0 })}
        </SC.Description>
      </SC.Row>
      <SC.Row>
        <SC.Description highlighted variant="body3">
          {t('molecules.expenseDetailsKilometer.distance')}
        </SC.Description>
        <SC.Description variant="body3">{`${getDistanceDecimalFormatter(distance)} km`}</SC.Description>
      </SC.Row>
      <SC.Row>
        <SC.Description highlighted variant="body3">
          {t('molecules.expenseDetailsKilometer.ratePerKM')}
        </SC.Description>
        <SC.Description variant="body3">{getValueWithCurrency({ value: ratePerKm || 0 })}</SC.Description>
      </SC.Row>
    </SC.Container>
  );
};
