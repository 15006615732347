import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { AccountabilityPreview } from '@containers/Financial/components/organisms/AccountabilityPreview/AccountabilityPreview';
import { external } from '@flash-hros/corporate-card';
import { useTranslation } from '@locale/Translator';
import { BasePage } from '@organisms/BasePage/BasePage';
import { AccountingContextProvider } from '@shared/contexts/AccountingContext/AccountingContext';
import { PendingAccountContextProvider } from '@shared/contexts/PendingAccountContext/PendingAccountContext';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';

export const FinancialAccountability = () => {
  const { t } = useTranslation();
  useTrackingScreen('financial_reports_screen');
  const context = 'pages.financialReports.Accountability';

  return (
    <external.ExternalRoot>
      <BasePage
        headerPage={{
          title: t(`${context}.title`),
          faqType: 'accounting',
        }}>
        <AccountingContextProvider>
          <PendingAccountContextProvider>
            <ModuleErrorBoundary
              moduleName={t(`${context}.errorBoundary.moduleName`)}
              title={t(`${context}.errorBoundary.title`)}>
              <AccountabilityPreview />
            </ModuleErrorBoundary>
          </PendingAccountContextProvider>
        </AccountingContextProvider>
      </BasePage>
    </external.ExternalRoot>
  );
};
