import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AdvancesGrid } from '@containers/Advances/components/organisms/AdvancesGrid';
import { IReport } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { useAdvancesPermissions } from '@shared/hooks/permissions/systemPermissions/useAdvancesPermissions';

import { AdvancesActions } from './AdvancesActions';
import { AdvancesEmptyAction } from './AdvancesEmptyAction/AdvancesEmptyAction';
import * as SC from './styled';

interface IProps {
  report: IReport;
  reportIsLoading: boolean;
  setAdvanceTabName: Dispatch<SetStateAction<string>>;
}

export function AdvancesLinkedReport({ report, reportIsLoading, setAdvanceTabName }: IProps) {
  const { t } = useTranslation();
  const { edit: editingIsAllowed } = useAdvancesPermissions();
  const hasActions = editingIsAllowed && !reportIsLoading;
  const [advancesQuantity, setAdvancesQuantity] = useState(0);
  const [advancesIsLoading, setAdvancesIsLoading] = useState(false);

  useEffect(() => {
    setAdvanceTabName(`${t('report.advance')} (${advancesQuantity})`);
  }, [report, advancesQuantity]);

  return (
    <SC.Container>
      {hasActions && !advancesIsLoading && !reportIsLoading && (
        <>
          {advancesQuantity > 0 ? (
            <SC.ActionsContainer>
              <AdvancesActions report={report} />
            </SC.ActionsContainer>
          ) : (
            <AdvancesEmptyAction
              linkedReport={{
                description: report?.description ?? null,
                id: report?.id ?? null,
                name: report?.name ?? null,
                status: report?.status ?? null,
              }}
            />
          )}
        </>
      )}
      <AdvancesGrid
        showSearch={false}
        report={report ?? null}
        setAmountAdvances={setAdvancesQuantity}
        setAdvancesIsLoading={setAdvancesIsLoading}
      />
    </SC.Container>
  );
}
