import { useTranslation } from '@locale/Translator';

import { EWaypointDivider, WaypointDivider } from '../WaypointIdentifier/WaypointDivider/WaypointDivider';
import * as SC from './styled';

interface IProps {
  onAddClick(): void;
}

export function AddNewWaypoint({ onAddClick }: IProps) {
  const { t } = useTranslation();
  return (
    <SC.Container>
      <SC.ContainerIcon>
        <WaypointDivider type={EWaypointDivider.DOTTED} />
        <SC.Icon name="IconCirclePlus" size={24} fill="transparent" onClick={onAddClick} />
      </SC.ContainerIcon>
      <SC.Description variant="body3">{t('molecules.addNewStopRoute.buttonLabel')}</SC.Description>
    </SC.Container>
  );
}
