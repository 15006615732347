import { useContext, useEffect } from 'react';
import { useTranslation } from '@locale/Translator';
import { clientsBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useDownloadFile } from '@shared/hooks/service/useDownloadFile';
import { useUploadFile } from '@shared/hooks/service/useUploadFile';
import { useNavigate } from 'react-router-dom';

import { ImportingContext } from '../../../shared/contexts/BulkImportContext';
import { EImportingActions } from '../../../shared/contexts/BulkImportContext/actions';

interface IUseClientFileRegister {
  importClients(data): void;
  download(): Promise<void>;
  error: unknown;
  isLoading: boolean;
}

export const useClientFileRegister = (): IUseClientFileRegister => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { download, isLoading, error } = useDownloadFile();

  const { upload, isLoading: isLoadingUpload, data: uploadData } = useUploadFile('v1/clients');
  const { dispatch } = useContext(ImportingContext);

  async function importClients(data) {
    try {
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO_IS_LOADING,
        payload: {
          isLoading: true,
        },
      });
      dispatch({
        type: EImportingActions.HAS_TRIED_TO_IMPORT,
        payload: {
          hasTriedToImport: true,
        },
      });
      await upload(data.clients.file, () =>
        showToast({
          message: t('molecules.clientToast.uploadErrorMessage'),
          type: 'error',
        }),
      );
      navigate(clientsBaseURL);
    } catch (e) {
      showToast({ message: t('molecules.clientToast.uploadErrorMessage'), type: 'error' });
      throw e;
    }
  }

  useEffect(() => {
    if (uploadData) {
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO,
        payload: {
          ...uploadData,
        },
      });
      dispatch({
        type: EImportingActions.GET_RESPONSE_INFO_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    }
  }, [uploadData]);

  return {
    importClients,
    download: () => download('modelo-import-cliente.xlsx', 'v1/clients/get-import-template'),
    error,
    isLoading: isLoadingUpload || isLoading,
  };
};
