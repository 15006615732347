import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { usePermissionsAuthZ } from '@shared/hooks/permissions/systemPermissions/usePermissionsAuthZ';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { TSystemFeatures } from '@shared/types/systemFeatures';
import { Navigate, Outlet } from 'react-router-dom';

import { Router } from './Router';

interface IProps {
  routeFeatures?: TSystemFeatures[];
  routePermissions?: EAuthZPermission[];
  children?: JSX.Element;
  redirectPath?: string;
}

export const PermissionsGuard = ({
  routeFeatures,
  routePermissions,
  redirectPath = Router.getExpenses(),
  children,
}: IProps) => {
  const { checkPermissionsBatch } = usePermissionsAuthZ();
  const { checkLicenseFeatureBatch } = useLicenseFeatures();

  function checkPermissionsPlatform() {
    const hasFeature = !routeFeatures?.length || checkLicenseFeatureBatch(routeFeatures);
    const hasPermissions = !routePermissions?.length || checkPermissionsBatch(routePermissions);
    return hasFeature && hasPermissions;
  }

  if (checkPermissionsPlatform()) {
    return children ? children : <Outlet />;
  }

  return <Navigate to={redirectPath} />;
};
