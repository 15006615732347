import { useEffect, useState } from 'react';

import * as SC from './styled';

interface IProps {
  value?: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
}

export function CounterField({ value, minValue, maxValue, disabled = false, onChange }: IProps) {
  const [_value, setValue] = useState<number>(value || 0);

  function setCurrentValues(_currentValue: number) {
    onChange?.(_currentValue);
    setValue(_currentValue);
  }

  function onHandleChange(type: 'Add' | 'Subtract', _currentValue: number) {
    if (type === 'Add' && (isNaN(maxValue) || _currentValue <= maxValue)) {
      setCurrentValues(_currentValue);
    }
    if (type === 'Subtract' && (isNaN(minValue) || _currentValue >= minValue)) {
      setCurrentValues(_currentValue);
    }
  }

  useEffect(() => {
    !isNaN(minValue) && setValue(value);
  }, [value]);

  return (
    <SC.Container disabled={disabled}>
      <SC.CircularButton
        onClick={() => !disabled && onHandleChange('Subtract', _value - 1)}
        disabled={disabled || _value <= minValue}>
        <SC.Icon name="IconMinus" size={16} />
      </SC.CircularButton>
      <SC.Text variant="body3">{_value}</SC.Text>
      <SC.CircularButton
        onClick={() => !disabled && onHandleChange('Add', _value + 1)}
        disabled={disabled || _value >= maxValue}>
        <SC.Icon name="IconPlus" size={16} />
      </SC.CircularButton>
    </SC.Container>
  );
}
