import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 0 0 50px 0;
  .helperText {
    gap: ${({ theme }) => theme.spacings.xs4};
    margin-left: ${({ theme }) => theme.spacings.xs4};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
  width: 100%;
`;
