export enum EAuthZPermission {
  ADMIN = '*',

  EXPENSE_VIEW = 'expense_view_expenses',
  EXPENSES_REPORTS_VIEW = 'expense_view_expenses_reports',
  EXPENSES_REPORTS_ACTION = 'expense_create_expenses_reports',
  EXPENSES_MILEAGE_ACTION = 'expense_create_mileage_expenses_and_reports',
  EXPENSES_PAYABLES_VIEW = 'expense_payables_bill_list_view',
  EXPENSE_PAYABLES_CREATE_BILL = 'expense_payables_create_bill',
  EXPENSE_TRANSACTION_VIEW = 'expense_transaction_list_view',

  VENDOR_CREATE = 'expense_payables_create_vendor',

  ADVANCES_REPORTS_VIEW = 'expense_view_approvals_advances',
  ADVANCES_REPORTS_ACTION = 'expense_create_advances_and_reports',

  CUSTOMIZATIONS_VIEW = 'expense_view_customizations',
  CUSTOMIZATION_POLICY_VIEW = 'expense_view_policy',
  CUSTOMIZATION_CATEGORIES_VIEW = 'expense_view_categories',
  CUSTOMIZATION_APPROVAL_VIEW = 'expense_view_approval_settings',
  CUSTOMIZATION_REPORTS_VIEW = 'expense_view_report',
  CUSTOMIZATION_FORMS_VIEW = 'expense_view_forms',
  CUSTOMIZATION_APPROVAL_ACTION = 'expense_configuration_approval_settings',
  CUSTOMIZATION_CATEGORIES_ACTION = 'expense_create_category',
  CUSTOMIZATION_PAYMENT_METHOD_ACTION = 'expense_configuration_payment_method',
  CUSTOMIZATION_LIMIT_ACTION = 'expense_create_limits',
  CUSTOMIZATION_EXPENSE_ACTION = 'expense_configuration_expenses',
  CUSTOMIZATION_PAYABLES_VIEW = 'expense_payables_configuration_view',
  CUSTOMIZATION_PAYABLES_FORM = 'expense_payables_form_configuration',
  CUSTOMIZATION_PAYABLES_CATEGORIES = 'expense_payables_category_configuration',

  ANALYTICS_VIEW = 'expense_view_analytics',
  ANALYTICS_EXPENSES_VIEW = 'expense_view_expenses_analytics',
  ANALYTICS_REPORTS_VIEW = 'expense_view_reports',
  ANALYTICS_CARD_VIEW = 'expense_view_corporate_card_expenses',
  ANALYTICS_CARD_ADMINISTRATOR = 'expense_manage_corporate_cards',
  ANALYTICS_ADVANCE_VIEW = 'expense_view_analytics_advance',
  ANALYTICS_EXPENSES_ACTION = 'expense_filter_export_expense_report',
  ANALYTICS_REPORTS_ACTION = 'expense_filter_export_report',
  ANALYTICS_CARD_ACTION = 'expense_filter_export_corporate_card_expense_report',
  ANALYTICS_ADVANCE_ACTION = 'expense_filter_export_advance_report',

  APPROVALS_VIEW = 'expense_view_reports_approval',
  REPORT_APPROVE_ACTION = 'expense_approve_report',
  REPORT_REFUND_ACTION = 'expense_refund_report',
  PAYABLES_APPROVE = 'expense_payables_approve_bill',

  ACCOUNTING_VIEW = 'expense_view_financial_reports',
  ACCOUNTING_PAYABLES_VIEW = 'expense_payables_account_view',
  DELEGATION_VIEW = 'expense_view_user_delegation',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  WITHDRAW_VIEW = 'expense_view_user_delegation',

  CONFIGURATION_CLIENTS = 'expense_view_client',
  CONFIGURATION_PROJECTS = 'expense_view_projects',
  CONFIGURATION_COST_CENTERS = 'expense_configuration_cost_center',
  CONFIGURATION_APPROVAL_FLOW = 'expense_configuration_approval_flow',
  CONFIGURATION_ADVANCES = 'expense_configuration_advance',
  CONFIGURATION_DELEGATION = 'expense_configuration_user_delegation',
  CONFIGURATION_DELEGATION_APPROVAL = 'expense_configuration_user_delegation_approval',
  CONFIGURATION_SUBSIDIARIES = 'expense_configuration_subsidiaries',

  SETTINGS_CORE_COMPANY = 'core_manage_company_settings',

  CONTRACT_SIGN = 'expense_contract_sign',
}
