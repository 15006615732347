import { ConfirmModalReportRepay } from '@containers/Financial/components/organisms/Reports/ConfirmModalReportRepay';
import { ExpirationDateModal } from '@containers/Financial/components/organisms/Reports/ExpirationDateModal';
import { useFinancialReportsActionManager } from '@containers/Financial/components/organisms/Reports/FinancialReportsPreview/hooks/useFinancialReportsActionManager';
import { JustificationModalReportReproval } from '@containers/Financial/components/organisms/Reports/JustificationModalReportReproval';
import { EReportStatus, IReport, IReportSummaries } from '@containers/Reports/context/types';
import { Button, Icons, LinkButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { useReportsPermissions } from '@shared/hooks/permissions/systemPermissions/useReportsPermissions';
import { useIntegrations } from '@shared/hooks/useIntegrations';
import { useNavigate, useParams } from 'react-router-dom';

import * as SC from './styled';

interface ActionsFooterPageProps {
  report: IReport;
  reportStatus: EReportStatus;
}

export const ActionsFooterPage = ({ reportStatus, report }: ActionsFooterPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    reportsSelected,
    isLoadingMutate,
    justificationIsOpen,
    expirationDateIsOpen,
    repayConfirmIsOpen,
    onActionJustificationPopup,
    onActionRepayConfirmPopup,
    onClosePopup,
    onRepayClick,
    onReproveClick,
  } = useFinancialReportsActionManager({ isReportDetails: true, reports: [report as IReportSummaries] });
  const { id } = useParams<{ id: string }>();
  const reportId = Number(id);
  const { getReportActions } = useReportsPermissions();
  const { reprove: allowedToReprove, repay: allowedToRepay, displayApprovalActions } = getReportActions(reportStatus);
  const { hasFinancialIntegration } = useIntegrations();

  function checkCancelIntegration() {
    return (
      reportStatus === EReportStatus.AWAITING_FINANCIAL_PROCESSING ||
      reportStatus === EReportStatus.PROCESSING_PAYMENT_ERROR
    );
  }

  return (
    <>
      <SC.Container>
        <div>
          <LinkButton variant="default" onClick={() => navigate(-1)}>
            {t('pages.financialReportDetails.comeBack')}
          </LinkButton>
        </div>

        <PermissionsChecker licenseFeature="BASIC_APPROVALS">
          <SC.ActionsButtonsFooterContainer>
            {!displayApprovalActions && (
              <>
                <Tooltip
                  arrow
                  title={!allowedToReprove ? t(`organisms.financialReportGrid.actionsOptions.tooltip.reprove`) : ''}
                  placement="top">
                  <div>
                    <Button
                      variant="secondary"
                      size="medium"
                      onClick={() => onReproveClick([reportId])}
                      disabled={!allowedToReprove}>
                      <SC.ButtonContent>
                        <Icons name={checkCancelIntegration() ? 'IconX' : 'IconThumbDown'} fill="transparent" />
                        <SC.ContainerDescriptionButton>
                          {t(
                            `pages.financialReportDetails.${
                              checkCancelIntegration() ? 'cancelIntegration' : 'reprove'
                            }`,
                          )}
                        </SC.ContainerDescriptionButton>
                      </SC.ButtonContent>
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip
                  arrow
                  title={
                    !allowedToRepay
                      ? t(
                          `organisms.financialReportGrid.actionsOptions.tooltip.${
                            hasFinancialIntegration ? 'integration' : 'repay'
                          }`,
                        )
                      : ''
                  }
                  placement="top-start">
                  <div>
                    <Button
                      variant="primary"
                      size="medium"
                      type="submit"
                      onClick={() => onRepayClick([reportId])}
                      disabled={!allowedToRepay}>
                      <Icons name={hasFinancialIntegration ? 'IconCloudUpload' : 'IconThumbUp'} fill="transparent" />
                      {t(`pages.financialReportDetails.${hasFinancialIntegration ? 'integration' : 'repay'}`)}
                    </Button>
                  </div>
                </Tooltip>
              </>
            )}
          </SC.ActionsButtonsFooterContainer>
        </PermissionsChecker>
      </SC.Container>
      <SC.ContainerModals>
        <JustificationModalReportReproval
          isOpen={justificationIsOpen}
          onActionClick={onActionJustificationPopup}
          onCloseClick={onClosePopup}
          reportsNumber={reportsSelected?.length}
          isLoading={isLoadingMutate}
          target={checkCancelIntegration() ? 'CancelIntegration' : 'Reproval'}
        />
        <ConfirmModalReportRepay
          isOpen={repayConfirmIsOpen}
          onActionClick={() => onActionRepayConfirmPopup()}
          onCloseClick={onClosePopup}
          reportsNumber={reportsSelected?.length}
          isLoading={isLoadingMutate}
        />
        <ExpirationDateModal
          isOpen={expirationDateIsOpen}
          onActionClick={onActionRepayConfirmPopup}
          onCloseClick={onClosePopup}
          reportsNumber={1}
          isLoading={isLoadingMutate}
        />
      </SC.ContainerModals>
    </>
  );
};
