import { SubscriptionInformation } from '@containers/Subscription/components/molecules/SubscriptionInformation/SubscriptionInformation';
import { SAASContractInformation } from '@containers/Subscription/context/types/contractInformation';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { useERPIntegrationConditions } from '../hooks/useERPIntegrationConditions';

type Props = {
  saasContract?: SAASContractInformation;
};

export const ERPIntegrationConditions = ({ saasContract }: Props) => {
  const t = useTranslate();

  const { erpIntegrationItems, totalERPIntegrationValue } = useERPIntegrationConditions(saasContract);

  return (
    <SubscriptionInformation
      title={t('organisms.subscriptionDetails.erpIntegration.title')}
      description={t('organisms.subscriptionDetails.erpIntegration.caption')}
      items={erpIntegrationItems}
      price={
        totalERPIntegrationValue > 0
          ? {
              value: totalERPIntegrationValue,
              highligted: true,
            }
          : undefined
      }
    />
  );
};
