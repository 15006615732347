import { Icons, Menu } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const IconAdd = styled(Icons)`
  fill: ${props => props.theme.colors.neutral.light.pure};
`;

export const ActionsButton = styled(Menu).attrs({
  type: 'default',
  transformOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
})`
  .MuiPaper-elevation {
    margin: 5px 0;
  }
`;
