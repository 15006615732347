import { useMemo } from 'react';
import { useApprovers } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useApprovers';
import { useAllCostCenters } from '@containers/CostCenters/hooks/useAllCostCenters';
import { EExpenseFieldsLicenseFeatures, EExpenseType } from '@containers/Expenses/context/types';
import { useCategoriesWithPolicies } from '@containers/Expenses/hooks/dataSource/useCategoriesWithPolicies';
import { useAllClients } from '@containers/Expenses/hooks/useAllClients';
import { useAllCurrencies } from '@containers/Expenses/hooks/useAllCurrencies';
import { useAllPaymentMethods } from '@containers/Expenses/hooks/useAllPaymentMethods';
import { useProjectsWithPolicies } from '@containers/Projects/hooks/rest/useProjectsWithPolicies';
import { useAllProjects } from '@containers/Projects/hooks/useAllProjects';
import { useUsersList } from '@containers/Projects/hooks/useUsersList';
import { useTranslation } from '@locale/Translator';
import { defaultCurrency } from '@shared/constant';
import { SelectOption, serializeSelectOptions } from '@shared/helpers/selectOptions';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';

import { useDateFormatter } from './formatters/useDateFormatter';

export interface SelectData {
  options: SelectOption[];
  error: unknown;
  isLoading: boolean;
}

interface IProps {
  hasCategories?: boolean;
  hasCurrencies?: boolean;
  hasClients?: boolean;
  hasProjects?: boolean;
  hasPaymentMethods?: boolean;
  hasCostCenters?: boolean;
  hasApprovers?: boolean;
  hasUsers?: boolean;
  projectId?: string;
  clientId?: string;
  expenseDate?: string;
  expenseType?: EExpenseType;
  isCardExpense?: boolean;
}
type TCallType =
  | 'categories'
  | 'currencies'
  | 'clients'
  | 'projects'
  | 'payment-methods'
  | 'cost-centers'
  | 'approvers';

export function useSelectsOptions({
  hasCategories = false,
  hasClients = false,
  hasCostCenters = false,
  hasCurrencies = false,
  hasPaymentMethods = false,
  hasProjects = false,
  hasApprovers = false,
  hasUsers = false,
  projectId,
  clientId,
  expenseDate,
  expenseType,
  isCardExpense,
}: IProps) {
  const { t } = useTranslation();
  const { checkLicenseFeature } = useLicenseFeatures();
  const { getFormatDateUTC } = useDateFormatter();
  const expenseDateFormatted = expenseDate ? getFormatDateUTC(expenseDate) : null;

  function checkCallPermission(key: TCallType) {
    switch (key) {
      case 'categories':
        return checkLicenseFeature(EExpenseFieldsLicenseFeatures.category) && hasCategories;
      case 'currencies':
        return checkLicenseFeature(EExpenseFieldsLicenseFeatures.currency) && hasCurrencies;
      case 'clients':
        return checkLicenseFeature(EExpenseFieldsLicenseFeatures.client) && hasClients;
      case 'projects':
        return checkLicenseFeature(EExpenseFieldsLicenseFeatures.project) && hasProjects;
      case 'payment-methods':
        return checkLicenseFeature(EExpenseFieldsLicenseFeatures.paymentType) && hasPaymentMethods;
      case 'cost-centers':
        return checkLicenseFeature(EExpenseFieldsLicenseFeatures.costCenter) && hasCostCenters;
      case 'approvers':
        return hasApprovers;
      default:
        return false;
    }
  }

  const {
    categories: categoriesWithPolicies,
    isLoading: categoriesWithPoliciesIsLoading,
    error: categoriesWithPoliciesError,
  } = useCategoriesWithPolicies({ projectId, policyId: null, shouldFetch: checkCallPermission('categories') });

  const {
    currencies,
    isLoading: currenciesIsLoading,
    error: currenciesError,
  } = useAllCurrencies(!checkCallPermission('currencies'));

  const { clients, isLoading: clientsIsLoading, error: clientsError } = useAllClients(!checkCallPermission('clients'));

  const {
    projects,
    isLoading: projectsIsLoading,
    error: projectsError,
  } = useAllProjects({
    skip: !checkCallPermission('projects') && !Boolean(clientId) && !Boolean(expenseDate),
    summaryData: true,
  });

  const {
    projects: projectsWithPolicies,
    isLoading: projectsWithPoliciesIsLoading,
    error: projectsWithPoliciesError,
  } = useProjectsWithPolicies({
    clientId,
    expenseDate: expenseDateFormatted,
    shouldFetch: checkCallPermission('projects') && (Boolean(clientId) || Boolean(expenseDate)),
  });

  const {
    paymentMethods,
    isLoading: paymentMethodsIsLoading,
    error: paymentMethodsError,
  } = useAllPaymentMethods(!checkCallPermission('payment-methods'));

  const {
    costCenters,
    isLoading: costCentersIsLoading,
    error: costCentersError,
  } = useAllCostCenters({
    summaryData: true,
    skip: !checkCallPermission('cost-centers'),
  });

  const {
    approvers,
    isLoading: approversIsLoading,
    error: approversError,
  } = useApprovers(!checkCallPermission('approvers'));

  const { users, isLoading: usersIsLoading, error: usersError } = useUsersList(!hasUsers);

  const empty: SelectOption[] = [
    {
      label: t('organisms.expenseRegisterForm.optionsNotFound'),
      value: null,
    },
  ];

  const emptyStringLabel = t('general.helper.optionNotRegistered');

  const categoryOptions: SelectData = useMemo(() => {
    let activeCategories = categoriesWithPolicies?.filter(category => category.active) || [];
    if (expenseType === EExpenseType.MANUAL) {
      activeCategories = activeCategories.filter(category => category.name !== 'Quilometragem');
    }
    return {
      options: serializeSelectOptions({ options: activeCategories, empty, emptyStringLabel }),
      isLoading: categoriesWithPoliciesIsLoading,
      error: categoriesWithPoliciesError,
    };
  }, [categoriesWithPolicies]);

  const currenciesOptions: SelectData = useMemo(
    () => ({
      options: serializeSelectOptions({ options: currencies || [defaultCurrency], empty, emptyStringLabel }),
      isLoading: currenciesIsLoading,
      error: currenciesError,
    }),
    [currencies],
  );

  const clientsOptions: SelectData = useMemo(
    () => ({
      options: serializeSelectOptions({ options: clients?.filter(client => client.active), empty, emptyStringLabel }),
      isLoading: clientsIsLoading,
      error: clientsError,
    }),
    [clients],
  );

  const projectsOptions: SelectData = useMemo(
    () => ({
      options: serializeSelectOptions({
        options:
          Boolean(clientId) || Boolean(expenseDate)
            ? projectsWithPolicies
                ?.map(project => ({ ...project, id: Number(project.id) }))
                ?.filter(project => project.active)
            : projects || [],
        empty,
        emptyStringLabel,
      }),
      isLoading: projectsIsLoading || projectsWithPoliciesIsLoading,
      error: projectsError || projectsWithPoliciesError,
    }),
    [projects, projectsWithPolicies],
  );

  const paymentMethodsOptions: SelectData = useMemo(() => {
    const filteredPaymentMethods =
      paymentMethods?.filter(method => method.active).filter(method => (isCardExpense ? true : !method.isCard)) || [];

    return {
      options: serializeSelectOptions({
        options: filteredPaymentMethods,
        empty,
        emptyStringLabel,
      }),
      isLoading: paymentMethodsIsLoading,
      error: paymentMethodsError,
    };
  }, [paymentMethods]);

  const costCentersOptions: SelectData = useMemo(
    () => ({
      options: serializeSelectOptions({
        options: costCenters?.filter(costCenter => costCenter.active),
        empty,
        emptyStringLabel,
      }),
      isLoading: costCentersIsLoading,
      error: costCentersError,
    }),
    [costCenters],
  );

  const approversOptions: SelectData = useMemo(
    () => ({
      options: serializeSelectOptions({
        options: approvers.map(approver => ({ ...approver, id: Number(approver.id) })),
        empty,
      }),
      isLoading: approversIsLoading,
      error: approversError,
    }),
    [approvers],
  );

  const userOptions: SelectData = useMemo(
    () => ({
      options: serializeSelectOptions({ options: users, empty, emptyStringLabel }),
      isLoading: usersIsLoading,
      error: usersError,
    }),
    [users],
  );

  return {
    categoryOptions,
    currenciesOptions,
    clientsOptions,
    projectsOptions,
    paymentMethodsOptions,
    costCentersOptions,
    approversOptions,
    userOptions,
    isLoadingOptions:
      costCentersIsLoading ||
      paymentMethodsIsLoading ||
      projectsIsLoading ||
      clientsIsLoading ||
      currenciesIsLoading ||
      costCentersIsLoading ||
      approversIsLoading ||
      usersIsLoading,
  };
}
