import { DeleteReportModal } from '@containers/Reports/components/molecules/DeleteReportModal';
import { NewReportModal } from '@containers/Reports/components/molecules/NewReportModal';
import { ReportGridContainer } from '@containers/Reports/components/organisms/ReportGridContainer/ReportGridContainer';
import { useTranslation } from '@locale/Translator';
import { ActionsButton } from '@molecules/ActionsButton/ActionsButton';
import { BasePage } from '@organisms/BasePage/BasePage';
import { expensesBaseUrl } from '@shared/constant';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';

import { useReportPageActions } from './hooks/useReportPageActions';

export const Reports = () => {
  useTrackingScreen('report_list_screen');

  const { t } = useTranslation();
  const {
    createReportIsLoading,
    openReportModal,
    showDeleteReportModal,
    handleCreateReport,
    handleDeleteReport,
    handleOpenDeleteModal,
    onShowReportModal,
    onShowDeleteReportModal,
  } = useReportPageActions();

  return (
    <BasePage
      headerPage={{
        title: t('pages.reports.title'),
        actions: (
          <ActionsButton actionName={t('pages.reports.add')} onClick={() => onShowReportModal(true)} actionIcon="Add" />
        ),
        faqType: 'reports',
        breadcrumbItems: [
          {
            to: `${expensesBaseUrl}`,
            label: t('pages.expenses.expenses'),
          },
          {
            to: `${expensesBaseUrl}/reports`,
            label: t('pages.reports.title'),
          },
        ],
        showDelegateInfo: true,
      }}>
      <ReportGridContainer onDeleteReportClick={id => handleOpenDeleteModal(id)} />
      <NewReportModal
        open={openReportModal}
        isLoading={createReportIsLoading}
        onActionClick={handleCreateReport}
        onCancelClick={() => onShowReportModal(false)}
        actionType="create"
      />
      <DeleteReportModal
        isOpen={showDeleteReportModal}
        onActionClick={handleDeleteReport}
        onCloseClick={() => onShowDeleteReportModal(false)}
      />
    </BasePage>
  );
};
