import { useEffect, useState } from 'react';
import { getAdvanceFormFields } from '@containers/Advances/context/queries/register';
import { EAdvanceKeyFields, IAdvanceFields, IFieldControl } from '@containers/Advances/context/types/formFields';
import { ApproverListContextType } from '@containers/Reports/context/types/approvers';
import { useReportsApprovers } from '@containers/Reports/hooks/useReportApprovers';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';
import { cloneDeep } from 'lodash';
export function useAdvanceRegisterFields() {
  const { loading, data, error } = useQuery<{ getAdvanceFormFields: IAdvanceFields }>(getAdvanceFormFields, {
    fetchPolicy: 'no-cache',
  });
  const [advanceFields, setAdvanceFields] = useState<IFieldControl[]>([]);
  const { approvers, approversIsLoading } = useReportsApprovers(false, ApproverListContextType.REPORT_FORM);

  function serializeFields(currentFields?: IAdvanceFields) {
    const fields: IFieldControl[] = [];
    if (currentFields)
      Object.keys(currentFields)?.forEach((key, index) => {
        const currentField = currentFields[key];
        currentField['key'] = key;

        switch (key) {
          case EAdvanceKeyFields.currency:
          case EAdvanceKeyFields.value:
          case EAdvanceKeyFields.advanceDate:
            fields.push(currentField);
            break;
          default:
            currentField.fieldOrder = index + 1;
            fields.push(currentField);
            break;
        }
      });

    return fields
      ?.filter(field => field.key !== EAdvanceKeyFields.report)
      ?.sort((f1, f2) => (f1.fieldOrder > f2.fieldOrder ? 1 : f1.fieldOrder < f2.fieldOrder ? -1 : 0));
  }

  useEffect(() => {
    if (!loading && data?.getAdvanceFormFields) {
      setAdvanceFields(
        serializeFields({
          currency: {
            active: true,
            required: true,
            allowsEditing: true,
            fieldOrder: 0,
          },
          value: {
            active: true,
            required: true,
            allowsEditing: true,
            fieldOrder: 1,
          },
          advanceDate: {
            active: true,
            required: true,
            allowsEditing: true,
            fieldOrder: 2,
          },
          ...cloneDeep(data?.getAdvanceFormFields),
          approver: {
            ...data?.getAdvanceFormFields['approver'],
            active: approvers.length > 0 || false,
          },
          description: {
            active: true,
            required: true,
            allowsEditing: true,
            fieldOrder: Object.keys(data?.getAdvanceFormFields || {}).length,
          },
        }),
      );
    }
  }, [loading, data, approvers]);

  return {
    fields: advanceFields,
    fieldsDeserialized: data?.getAdvanceFormFields,
    isLoading: loading || approversIsLoading,
    error,
  };
}
