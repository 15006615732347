import { useEffect, useState } from 'react';
import { ILimitGrid } from '@containers/Customizations/context/types/limits';

export interface UseLimitSelectionRowsProps {
  limits: ILimitGrid[];
}

export const useLimitSelectionRows = ({ limits }: UseLimitSelectionRowsProps) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isEnableSelectedItems, setIsEnableSelectedItems] = useState(false);
  const isSelectedAll: boolean = selectedItems?.length == limits?.length;

  useEffect(() => {
    setIsEnableSelectedItems(
      limits?.filter(limit => selectedItems.includes(limit.limitPolicyId) && limit.active)?.length <
        Math.floor(limits?.length / 2),
    );
  }, [selectedItems]);

  function onSelectAllClick() {
    setSelectedItems(limits.map(e => e.limitPolicyId));
  }

  function unSelectAllClick() {
    setSelectedItems([]);
  }

  function toggleSelectAll() {
    if (isSelectedAll) unSelectAllClick();
    else onSelectAllClick();
  }

  function onSelectItemClick(itemId: number) {
    const isIdSelected = selectedItems.includes(itemId);

    if (isIdSelected) {
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  }

  return {
    selectedItems,
    isSelectedAll,
    isEnableSelectedItems,
    onSelectItemClick,
    onSelectAllClick,
    unSelectAllClick,
    toggleSelectAll,
  };
};
