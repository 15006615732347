import { Loader } from '@atoms/Loader';
import { useClientFileRegister } from '@containers/Clients/hooks/useClientFileRegister';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { FooterPage } from '@molecules/FooterPage';
import { clientsBaseURL } from '@shared/constant';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import * as SC from './styled';

interface ClientFooterPageProps {
  action?(): void;
}

export const ClientFooterPage = ({ action }: ClientFooterPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSubmit } = useFormContext();
  const { importClients, isLoading } = useClientFileRegister();
  const { pathname } = useLocation();
  const isDetails = pathname.includes('file/details');

  return (
    <FooterPage>
      <SC.ButtonsContainer>
        <LinkButton onClick={() => navigate(clientsBaseURL)} variant="default">
          {t('organisms.registerClient.bulkImport.footer.cancel')}
        </LinkButton>
        <Button variant="primary" size="medium" type="submit" onClick={action ? action : handleSubmit(importClients)}>
          {t(`pages.clientRegister.${isDetails ? 'conclude' : 'continue'}`)}
          {!isDetails && <Icons name="IconArrowRight" fill="transparent" />}
        </Button>
      </SC.ButtonsContainer>
      <Loader show={isLoading} description={t('pages.clientRegister.bulkImportLoading')} />
    </FooterPage>
  );
};
