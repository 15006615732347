import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs3,
  alignItems: 'center',
  width: '100%',

  '.establishments-field': {
    width: '100%',
  },
}));

export const StopContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs,
  alignItems: 'center',
}));
