import { useState } from 'react';
import { ICustomField } from '@containers/Customizations/context/types/customFieldTypes';
import { useChangeCustomFieldOrder } from '@containers/Customizations/hooks/CustomFields/useChangeCustomFieldOrder';
import { useDeleteCustomField } from '@containers/Customizations/hooks/CustomFields/useDeleteCustomField';
import { useSwitchCustomFieldState } from '@containers/Customizations/hooks/CustomFields/useSwitchCustomFieldState';
import { IActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { CustomFieldConfimationActions } from '../../CustomFieldConfirmationActionsModal/CustomFieldConfirmationActionsModal';

enum EActions {
  MOVE_UP = 'moveUp',
  MOVE_DOWN = 'moveDown',
  EDIT = 'edit',
  ACTIVE = 'active',
  DELETE = 'delete',
}

enum EIcons {
  edit = 'IconPencil',
  active = 'IconToggleLeft',
  delete = 'IconTrash',
  moveUp = 'IconChevronUp',
  moveDown = 'IconChevronDown',
}

export function useCustomFieldsActionsGrid(customFields: ICustomField[]) {
  const t = useTranslate('organisms.customizationsCustomFields.grid');
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [confirmationType, setConfirmationType] = useState<CustomFieldConfimationActions>();

  const [selectedCustomField, setSelectedCustomField] = useState<ICustomField>();

  const deleteCustomField = useDeleteCustomField();
  const switchCustomFieldState = useSwitchCustomFieldState();
  const moveCustomField = useChangeCustomFieldOrder();

  function onShowConfirmationActionsModal(): void {
    setShowConfirmationModal(_open => !_open);
  }

  async function onConfirmationActionModal() {
    onShowConfirmationActionsModal();

    if (confirmationType === 'exclusion') {
      return deleteCustomField.handleDelete(selectedCustomField.id);
    }

    if (confirmationType === 'deactivation') {
      return switchCustomFieldState.handleSwitch(selectedCustomField);
    }
  }

  function onShowEditModal(): void {
    setShowEditModal(_open => !_open);
  }

  function onActionClick(_actionKey: EActions, field: ICustomField) {
    setSelectedCustomField(field);

    switch (_actionKey) {
      case EActions.ACTIVE:
        if (field.active) {
          setConfirmationType('deactivation');

          onShowConfirmationActionsModal();
        } else {
          switchCustomFieldState.handleSwitch(field);
        }

        break;

      case EActions.MOVE_UP:
        moveCustomField.handleMove(field.id, 'UP');
        break;

      case EActions.MOVE_DOWN:
        moveCustomField.handleMove(field.id, 'DOWN');
        break;

      case EActions.DELETE:
        setConfirmationType('exclusion');
        onShowConfirmationActionsModal();
        break;

      default:
        setConfirmationType(null);
        onShowEditModal();
        break;
    }
  }

  function getActionsRow(customField: ICustomField): IActionsRow[] {
    const actions = [EActions.EDIT, EActions.ACTIVE, EActions.DELETE];

    const isFirst = customField.order === 1;

    const lastFromList = customFields[customFields.length - 1];
    const isLast = customField.order === lastFromList?.order;

    const isInactive = !customField.active;

    if (!isInactive) {
      if (!isLast) {
        actions.unshift(EActions.MOVE_DOWN);
      }

      if (!isFirst) {
        actions.unshift(EActions.MOVE_UP);
      }
    }

    return actions.map(key => ({
      id: customField.id,
      key: key,
      allowed: true,
      iconName: EIcons[key],
      name: t(`body.actions.${key === EActions.ACTIVE && customField.active ? 'deactivate' : key}`),
      onClick: _key => onActionClick(_key as EActions, customField),
    }));
  }

  return {
    showEditModal,
    showConfirmationModal,
    confirmationType,
    isLoadingAction: switchCustomFieldState.isLoading || deleteCustomField.isLoading || moveCustomField.isLoading,
    getActionsRow,
    onShowConfirmationActionsModal,
    onShowEditModal,
    onConfirmationActionModal,
    selectedCustomField,
  };
}
