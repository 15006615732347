import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { FilterType } from '@atoms/FilterFactory';
import { StatusTag } from '@atoms/StatusTag';
import { JustificationModalAdvanceReproval } from '@containers/Advances/components/organisms/JustificationModalAdvanceReproval';
import { IAdvanceListFilters } from '@containers/Advances/context/types/advance';
import { useAdvances } from '@containers/Advances/hooks/dataSources/useAdvances';
import { useAttachAdvanceToReport } from '@containers/Advances/hooks/dataSources/useAttachAdvanceToReport';
import { ModalReportSelection } from '@containers/Expenses/components/molecules/ModalReportSelection';
import { ISummaryReport } from '@containers/Expenses/context/types';
import { NewReportModal } from '@containers/Reports/components/molecules/NewReportModal';
import { IReport } from '@containers/Reports/context/types';
import { useReportMutate } from '@containers/Reports/hooks/useReportMutate';
import { Checkbox, Icons, ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { GridFilters } from '@molecules/GridFilters';
import { ActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { defaultCurrencySymbol } from '@shared/constant';
import { useDateFormatter, useValueFormatter } from '@shared/hooks';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useTracking } from '@shared/hooks/useTracking';

import { AdvancesConfirmationActionsModal } from '../AdvancesConfirmationActionsModal';
import { AttachAdvanceToReportModal } from '../AttachAdvanceToReportModal';
import { useAdvancesActionsGrid } from './hooks/useAdvanceActionsGrid';
import { useAdvancesStatus } from './hooks/useAdvancesStatus';
import * as SC from './styled';

enum EAccessor {
  SELECT_ALL = 'selectAll',
  ADVANCE = 'advance',
  STATUS = 'status',
  VALUE = 'value',
  ADVANCE_DATE = 'advanceDate',
  ACTIONS = 'actions',
}

interface IAdvanceColumns {
  selectAll: ReactNode;
  advance: ReactNode;
  requester?: ReactNode;
  status: ReactNode;
  value: ReactNode;
  advanceDate: ReactNode;
  actions: ReactNode;
}

type TProps<T> = {
  setAmountAdvances?: Dispatch<SetStateAction<number>>;
  setAdvancesIsLoading?: Dispatch<SetStateAction<boolean>>;
  reportId?: number;
  filters?: T;
  showSearch?: boolean;
  report?: IReport;
  onClickPage?: Dispatch<SetStateAction<number>>;
  onFilterClick?: (key: string, data: unknown) => void;
};

export function AdvancesGrid<
  T extends { page: number; referenceId?: string; reportId?: IReport['id']; status?: IAdvanceListFilters['status'] },
>({ report, filters, showSearch = true, setAmountAdvances, setAdvancesIsLoading, onFilterClick }: TProps<T>) {
  const translation = useTranslation();
  const t = (key: string): string => translation.t(`organisms.advances.grid.${key}`);
  const [selectedFilters, setSelectedFilters] = useState<IAdvanceListFilters>({
    search: '',
    status: [],
    ...filters,
  });
  const [page, setPage] = useState(filters?.page || 1);
  const [showAttachAdvanceToReportModal, setShowAttachAdvanceToReportModal] = useState(false);
  const [showNewReportModal, setShowNewReportModal] = useState(false);
  const trackingEvent = useTracking();
  const [selectedReport, setSelectedReport] = useState<ISummaryReport | IReport>(null);
  const { createReport, isLoading: isLoadingCreateReport } = useReportMutate();
  const { handleAttach, isLoading: isLoadingAttachAdvanceToReport } = useAttachAdvanceToReport();
  const { advances, isLoading, total } = useAdvances<T>({
    ...filters,
    reportId: filters?.reportId || report?.id,
    page,
    referenceId: filters?.referenceId || selectedFilters.search,
    status: selectedFilters.status,
  });
  const {
    showConfirmationModal,
    isSelectedAll,
    selectedItems,
    confirmationType,
    isBatchAction,
    isLoadingAction,
    listIdsLoading,
    justificationIsOpen,
    selectedAdvance,
    showLinkModal,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    unSelectAllClick,
    onSelectItemClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
    onActionJustificationPopup,
    setJustificationIsOpen,
    closeLinkModal,
  } = useAdvancesActionsGrid(advances);
  const { getValueWithCurrency } = useValueFormatter();
  const { getDateOnlyFormatter } = useDateFormatter();
  const { advancedStatusOptions, getAdvancesStatusColor, getAdvancesStatusLabel, getAdvancesShapeIconStatusColor } =
    useAdvancesStatus();
  const currentPath = useCurrentPath();
  const showRequester = currentPath.includes('accounting') || currentPath.includes('approvals');

  function onCloseModal() {
    setShowNewReportModal(false);
    closeLinkModal();
  }

  async function attachData(id: number) {
    if (id && selectedAdvance.id) {
      await handleAttach([selectedAdvance.id], id);
    }
  }

  const handleReportCreation = async report => {
    trackingEvent('report_create_clicked');
    const response = await createReport(report);

    await attachData(response.id);
    onCloseModal();
  };

  const handleReportSelection = async () => {
    await attachData(selectedReport.id);
    onCloseModal();
  };

  const handleStatusChange = value => {
    setSelectedFilters(prevState => ({ ...prevState, status: value }));
    onFilterClick?.('status', value);
    setPage(1);
  };

  function getColumns(): IGridColumn[] {
    const columns: IGridColumn[] = Object.values(EAccessor).map(accessor => ({
      accessor,
      disableSortBy: true,
      Header: (
        <ColumnHeader
          isLoading={isLoading && !Boolean(selectedFilters.search) && !Boolean(advances.length)}
          isLoadingAction={isLoading}
          description={t(`header.columns.${accessor}`)}
          isSelectedAll={accessor === EAccessor.SELECT_ALL}
          onSelectAllClick={toggleSelectAll}
          selectAllChecked={isSelectedAll}
        />
      ),
      sticky: accessor === EAccessor.ACTIONS && 'right',
    }));
    if (showRequester) {
      columns.splice(2, 0, {
        accessor: 'requester',
        disableSortBy: true,
        Header: (
          <ColumnHeader
            isLoading={isLoading && !Boolean(selectedFilters.search) && !Boolean(advances.length)}
            isLoadingAction={isLoading}
            description={t(`header.columns.requester`)}
            onSelectAllClick={toggleSelectAll}
            selectAllChecked={isSelectedAll}
          />
        ),
        sticky: '',
      });
    }
    return columns;
  }

  function getRows(): IAdvanceColumns[] {
    return advances?.map(_advance => {
      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(_advance)}
            checked={selectedItems.some(_id => _id === _advance?.id)}
          />
        ),
        advance: (
          <SC.AdvanceIdContainer>
            <ShapeIcon
              name="IconCurrencyDollar"
              variant={getAdvancesShapeIconStatusColor(_advance.status)}
              stroke={getAdvancesShapeIconStatusColor(_advance.status)}
              size={40}
            />
            <SC.AdvanceInfo>
              {_advance.referenceId}
              {_advance.violationNumber ? (
                <SC.OccurrenceContainer>
                  <Icons name="IconMessageCircle2" size={16} />
                  <Typography variant="body4">{_advance.violationNumber}</Typography>{' '}
                  {t(`body.occurrence.${_advance.violationNumber > 1 ? 'plural' : 'singular'}`)}
                </SC.OccurrenceContainer>
              ) : (
                <></>
              )}
            </SC.AdvanceInfo>
          </SC.AdvanceIdContainer>
        ),
        requester: _advance.user?.name,
        status: (
          <StatusTag showStatusIcons variant={getAdvancesStatusColor(_advance.status)}>
            {getAdvancesStatusLabel(_advance.status)}
          </StatusTag>
        ),
        advanceDate: getDateOnlyFormatter(_advance.advanceDate) ?? '-',
        value: getValueWithCurrency({ value: _advance.value, currencyPrefix: defaultCurrencySymbol }),
        actions: (
          <ActionsRow
            referenceId={_advance.id}
            actions={getActionsRow(_advance?.id, _advance)}
            isLoading={listIdsLoading?.includes(_advance?.id)}
          />
        ),
      };
    });
  }

  useEffect(() => {
    setAmountAdvances?.(total);
    setAdvancesIsLoading?.(isLoading);
  }, [total, isLoading]);

  useEffect(() => {
    setPage(1);
    currentPath.includes('accounting') &&
      filters?.status &&
      setSelectedFilters({ ...selectedFilters, status: filters.status });
  }, [JSON.stringify(filters?.status)]);

  return (
    <SC.Container>
      <GridFilters
        filters={[
          {
            type: FilterType.MULTIPLE,
            label: t('statusLabel'),
            options: advancedStatusOptions,
            onClick: handleStatusChange,
            selectedOptions: selectedFilters.status,
          },
        ]}
        showSearchField={showSearch}
        labelSearchField={t('searchFieldLabel')}
        onSearchChange={data => {
          selectedItems.length && unSelectAllClick();
          setSelectedFilters(prevState => ({ ...prevState, search: data }));
        }}
        isLoading={isLoading && !Boolean(selectedFilters.search) && !Boolean(advances.length)}
        showMoreFiltersButton={false}
      />
      <Grid
        columns={getColumns()}
        rows={getRows()}
        batchActions={getBatchActions()}
        isLoading={isLoading}
        hasPagination={total > 50}
        totalItems={total}
        numberItemsSelected={selectedItems?.length || 0}
        rowsLoading={Boolean(advances.length) ? advances?.length : 4}
        currentPage={page}
        paginationIsDisabled={isLoading}
        onPageClick={_page => setPage(_page)}
        rowsPerPage={50}
        showRowsPerPageOptions={false}
      />
      {showConfirmationModal && (
        <AdvancesConfirmationActionsModal
          open={showConfirmationModal}
          confirmationType={confirmationType}
          onActionClick={onConfirmationActionModal}
          onClose={onShowConfirmationActionsModal}
          isBatchAction={isBatchAction}
          isLoading={isLoadingAction}
        />
      )}

      {showAttachAdvanceToReportModal && (
        <AttachAdvanceToReportModal
          isOpen={showAttachAdvanceToReportModal}
          onClose={() => setShowAttachAdvanceToReportModal(false)}
          reportId={report?.id}
        />
      )}

      {showLinkModal && (
        <ModalReportSelection
          open={showLinkModal}
          selectedReportId={null}
          actionIsLoading={isLoadingAttachAdvanceToReport}
          onCancelClick={closeLinkModal}
          onCreateReport={() => setShowNewReportModal(true)}
          onActionClick={handleReportSelection}
          onSelectReport={data => setSelectedReport(data)}
        />
      )}

      {showNewReportModal && (
        <NewReportModal
          open={showNewReportModal}
          isLoading={isLoadingCreateReport || isLoadingAttachAdvanceToReport}
          actionType={'create'}
          onCancelClick={() => setShowNewReportModal(false)}
          onActionClick={handleReportCreation}
        />
      )}

      {justificationIsOpen && (
        <JustificationModalAdvanceReproval
          isOpen={justificationIsOpen}
          isLoading={isLoadingAction}
          onActionClick={onActionJustificationPopup}
          onCloseClick={() => setJustificationIsOpen(false)}
          advancesNumber={isBatchAction ? selectedItems?.length : 1}
        />
      )}
    </SC.Container>
  );
}
