import { useEffect } from 'react';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { useEstablishmentMutate } from '@containers/Expenses/hooks/useEstablishmentMutate';
import { usePlaceDetails } from '@containers/Expenses/hooks/usePlaceDetails';

interface Props {
  selectedEstablishment: IEstablishment;
  establishmentExpense: IEstablishment;
  onSelectChange(establishment: IEstablishment): void;
  setSelectedEstablishment(establishment: IEstablishment): void;
}

export function useAddingLocationEstablishment({
  selectedEstablishment,
  establishmentExpense,
  setSelectedEstablishment,
  onSelectChange,
}: Props): void {
  const { getPlaceInfos } = usePlaceDetails(selectedEstablishment?.placeId);
  const { getCreateEstablishment } = useEstablishmentMutate();

  async function addingLocationEstablishment(): Promise<void> {
    const establishment: IEstablishment = {
      ...selectedEstablishment,
      lat: selectedEstablishment.lat,
      lng: selectedEstablishment.lng,
    };
    try {
      const details = await getPlaceInfos({
        variables: {
          placeId: selectedEstablishment?.placeId,
        },
      });

      establishment.lat = details?.data?.getPlaceDetails?.location?.lat;
      establishment.lng = details?.data?.getPlaceDetails?.location?.lng;

      onSelectChange?.(establishment);

      if (establishment?.lat && establishment?.lng) {
        setSelectedEstablishment(establishment);

        getCreateEstablishment({
          variables: {
            establishment: establishment,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (
      Boolean(selectedEstablishment) &&
      (!selectedEstablishment?.lat || !selectedEstablishment?.lng) &&
      establishmentExpense != selectedEstablishment
    ) {
      addingLocationEstablishment();
    }
  }, [selectedEstablishment]);
}
