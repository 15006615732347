import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

interface IAnalyticsHeader {
  title: string;
  caption?: string;
  icon?: IconTypes;
}

interface IProps {
  header: IAnalyticsHeader;
  children: React.ReactNode;
}

export function AnalyticsGroup({ header, children }: IProps) {
  return (
    <SC.Container>
      <SC.HeaderContainer>
        {header.icon && (
          <SC.HeaderIconContainer>
            <SC.HeaderIcon name={header.icon} fill="transparent" />
          </SC.HeaderIconContainer>
        )}
        <SC.HeaderTitleContainer>
          <SC.HeaderTitle variant="headline8">{header.title}</SC.HeaderTitle>
          <SC.HeaderCaption variant="body4">{header.caption}</SC.HeaderCaption>
        </SC.HeaderTitleContainer>
      </SC.HeaderContainer>
      <>{children}</>
    </SC.Container>
  );
}
