import { useEffect, useState } from 'react';
import { getAllExpenses } from '@containers/Expenses/context/queries';
import { IExpensesFilters } from '@containers/Expenses/context/types';
import { useExpenses } from '@containers/Expenses/hooks/useExpenses';
import { Table as FlashTable } from '@flash-tecnologia/hros-web-ui-v2';
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';
import { useRowsPerPageOptions } from '@molecules/GridPagination/kooks/useRowsPerPageOptions';
import { HeaderBulkActions } from '@organisms/Table/HeaderBulkActions/HeaderBulkActions';

import { ConfirmationModalDeleteExpense } from '../ConfirmationModalDeleteExpense';
import { ConfirmationModalUnlinkExpense } from '../ConfirmationModalUnlinkExpense';
import { TableHelper } from './TableHelper';

type TProps = {
  filters?: IExpensesFilters;
};

export function ExpenseTable({ filters }: TProps) {
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  });
  const { rowsPerPageOptions } = useRowsPerPageOptions({ maxRows: 20, intervalItems: 5 });
  const [currentFilters, setCurrentFilters] = useState(filters || null);
  const { expenses, expensesIsLoading, expensesTotalItems } = useExpenses({
    page: pagination.pageNumber,
    limit: pagination.pageSize,
    filters: currentFilters,
  });
  const { tableHelper, actionsHelper, selectedIds } = TableHelper({ data: expenses, pagination, setPagination });

  useEffect(() => {
    if (filters) {
      tableHelper.resetSelected();
      setPagination({ ...pagination, pageNumber: 1 });
      filters && setCurrentFilters(filters);
    }
  }, [filters]);

  return (
    <>
      <FlashTable.Root>
        <FlashTable.Grid.Root loading={expensesIsLoading}>
          {selectedIds.allSelected || selectedIds.countSelectedRows > 0 ? (
            <HeaderBulkActions
              actions={actionsHelper.batchActionsOptions}
              totalItems={expensesTotalItems}
              allSelected={selectedIds.allSelected}
              selectedLength={selectedIds.countSelectedRows}
              onCheckboxClick={() => tableHelper.setAllSelected(!selectedIds.allSelected)}
            />
          ) : (
            <FlashTable.Grid.Header getHeaderGroups={tableHelper.getHeaderGroups} />
          )}
          {tableHelper.rows.map((row, index) => (
            <FlashTable.Grid.Row key={index + row.id} row={row} />
          ))}
        </FlashTable.Grid.Root>
        <FlashTable.Pagination
          count={expensesTotalItems}
          onPaginationChange={data => {
            tableHelper.resetSelected();
            setPagination({
              pageNumber: data.pageSize !== pagination.pageSize ? 1 : data.pageNumber,
              pageSize: data.pageSize,
            });
          }}
          pagination={pagination}
          pageSizeOptions={rowsPerPageOptions}
        />
      </FlashTable.Root>
      <ConfirmationModalUnlinkExpense
        expensesNumber={selectedIds.isBatchAction ? tableHelper.selected.selected.length : 1}
        isOpen={actionsHelper.events.unlinkConfirmationIsOpen}
        onActionClick={actionsHelper.events.onConfirmUnlinkExpenseClick}
        onCloseClick={actionsHelper.events.onCancelUnlinkExpenseClick}
        isLoading={actionsHelper.events.actionsIsLoading}
      />
      <ConfirmationModalDeleteExpense
        expensesNumber={selectedIds.isBatchAction ? tableHelper.selected.selected.length : 1}
        isOpen={actionsHelper.events.deleteConfirmationIsOpen}
        onActionClick={() =>
          actionsHelper.events.onConfirmDeleteExpenseClick([{ query: getAllExpenses }, 'GetExpenses'])
        }
        onCloseClick={actionsHelper.events.onCancelDeleteExpenseClick}
        isLoading={actionsHelper.events.actionsIsLoading}
      />
    </>
  );
}
