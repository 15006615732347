import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  width: '100%',
  borderRadius: theme.borders.radius.m,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[80]}`,
  display: 'flex',
  gap: theme.spacings.s,
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacings.s,
}));

export const HeaderContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacings.xs,
}));

export const HeaderTitleContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gap: theme.spacings.xs5,
  gridTemplateRows: '1fr 1fr',
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[30],
  fontWeight: `${700}Important`,
}));

export const HeaderCaption = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral[50],
  fontWeight: `${400}Important`,
}));

export const HeaderIconContainer = styled.div(({ theme }) => ({
  borderRadius: theme.borders.radius.circular,
  background: theme.colors.secondary[95],
  padding: theme.spacings.xs3,
  width: '48px',
  height: '48px',
}));

export const HeaderIcon = styled(Icons)(({ theme }) => ({
  color: theme.colors.primary,
}));
