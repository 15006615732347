import { Switch } from '@atoms/Switch';
import { IConfigurationCategory } from '@containers/Customizations/context/types/categoryTypes';
import { useTranslation } from '@locale/Translator';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';

import * as SC from './styled';

export interface CategoryFormModalProps {
  isOpen: boolean;
  category: IConfigurationCategory;
  isLoading: boolean;
  handleCategory(field: string, value: string | boolean | number | object): void;
  handleSubmitCategory(e?: React.FormEvent<HTMLFormElement>): void;
  handleCancel(): void;
}

export const CategoryFormModal = ({
  isOpen,
  category,
  isLoading,
  handleSubmitCategory,
  handleCategory,
  handleCancel,
}: CategoryFormModalProps) => {
  const { t } = useTranslation();
  const { checkLicenseFeature } = useLicenseFeatures();
  const hasContabilityAccount = checkLicenseFeature('INTERMEDIATE_EXPENSE') || checkLicenseFeature('ADVANCED_EXPENSE');

  return (
    <DefaultModal
      open={isOpen}
      headerTitle={t(`organisms.categoryFormModal.${category?.id ? 'editCategory' : 'createCategory'}`)}
      headerTextAlign="left"
      isActionLoading={isLoading}
      actionDisabled={!Boolean(category?.name?.length >= 3)}
      onActionClick={handleSubmitCategory}
      onCloseClick={handleCancel}
      labelButtonAction={t(`organisms.categoryFormModal.${category?.id ? 'edit' : 'create'}`)}>
      <SC.FieldsContainer>
        <SC.FieldContainer>
          <SC.Input
            label={
              <SC.MandatoryDescriptionContainer>
                {t('organisms.categoryFormModal.fieldsLabel.description')}
                <SC.MandatoryDescriptionAlert variant="body4">*</SC.MandatoryDescriptionAlert>
              </SC.MandatoryDescriptionContainer>
            }
            value={category?.name}
            onChange={e => handleCategory('name', e.target.value)}
            disabled={category.readOnly}
          />
        </SC.FieldContainer>
        <SC.FieldContainer>
          <SC.Input
            label={t('organisms.categoryFormModal.fieldsLabel.code')}
            helperText={t('organisms.categoryFormModal.helperTextCodeField')}
            value={category?.externalCode}
            onChange={e => handleCategory('externalCode', e.target.value)}
          />
        </SC.FieldContainer>

        {hasContabilityAccount && (
          <>
            <SC.FieldContainer>
              <SC.Input
                label={t('organisms.categoryFormModal.fieldsLabel.contabilityAccountName')}
                value={category?.contabilityAccount?.name}
                onChange={e =>
                  handleCategory('contabilityAccount', { ...category?.contabilityAccount, name: e.target.value })
                }
              />
            </SC.FieldContainer>
            <SC.FieldContainer>
              <SC.Input
                label={t('organisms.categoryFormModal.fieldsLabel.contabilityAccountCode')}
                value={category?.contabilityAccount?.code}
                onChange={e =>
                  handleCategory('contabilityAccount', { ...category?.contabilityAccount, code: e.target.value })
                }
              />
            </SC.FieldContainer>
          </>
        )}

        <SC.MandatoryDescriptionContainer>
          <SC.MandatoryDescriptionAlert variant="body4">*</SC.MandatoryDescriptionAlert>
          <SC.MandatoryDescription variant="body4">
            {t('organisms.categoryFormModal.mandatoryDescription')}
          </SC.MandatoryDescription>
        </SC.MandatoryDescriptionContainer>

        <SC.ActiveField
          control={<Switch />}
          label={t('organisms.categoryFormModal.fieldsLabel.activated')}
          checked={category.active}
          onChange={(_, value) => handleCategory('active', value)}
        />
      </SC.FieldsContainer>
    </DefaultModal>
  );
};
