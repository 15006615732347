import { useState } from 'react';
import { ModalReportSelection } from '@containers/Expenses/components/molecules/ModalReportSelection';
import { ISummaryReport } from '@containers/Expenses/context/types';
import { NewReportModal } from '@containers/Reports/components/molecules/NewReportModal';
import { useReportMutate } from '@containers/Reports/hooks/useReportMutate';
import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { expensesAdvancesBaseURL } from '@shared/constant';
import { IReportNavigate } from '@shared/hooks/navigation/useExpenseRegisterDriver';
import { useTracking } from '@shared/hooks/useTracking';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

export const AddAdvanceButton = () => {
  const { t } = useTranslation();
  const trackingEvent = useTracking();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const [showReportSelection, setShowReportSelection] = useState(false);
  const [showNewReport, setShowNewReport] = useState(false);
  const [reportSelected, setReportSelected] = useState<ISummaryReport>();
  const { createReport, isLoading: isLoadingCreateReport } = useReportMutate();

  function onNavigateAdvanceRegister(_report?: IReportNavigate) {
    navigate(`${expensesAdvancesBaseURL}/register`, {
      state: {
        linkedReport: _report || {
          name: reportSelected?.name,
          id: reportSelected?.id,
          description: reportSelected?.description,
        },
      },
    });
  }

  const handleReportCreation = async report => {
    trackingEvent('report_create_clicked');

    const response = await createReport(report);
    const _report: IReportNavigate = {
      name: response.name,
      id: response.id,
    };
    onNavigateAdvanceRegister(_report);
  };

  function onOpenReport() {
    setShowReportSelection(show => !show);
    onNavigateAdvanceRegister();
  }

  function onCancel() {
    setShowReportSelection(show => !show);
    setReportSelected(null);
  }

  function onSelectReport(report: ISummaryReport) {
    setReportSelected(report);
  }

  function onAdvanceRegisterClick() {
    trackingEvent('advance_add_clicked');
    setShowReportSelection(show => !show);
  }

  return (
    <>
      <Button size="large" variant="primary" onClick={() => onAdvanceRegisterClick()}>
        {t('pages.advances.addButton')}
        <Icons name="Add" color={colors.neutral[100]} />
      </Button>

      <ModalReportSelection
        open={showReportSelection}
        selectedReportId={reportSelected?.id}
        onActionClick={onOpenReport}
        onCancelClick={onCancel}
        onSelectReport={reportSelected => onSelectReport(reportSelected)}
        onCreateReport={() => setShowNewReport(true)}
      />
      <NewReportModal
        open={showNewReport}
        isLoading={isLoadingCreateReport}
        actionType={'create'}
        onCancelClick={() => setShowNewReport(false)}
        onActionClick={handleReportCreation}
      />
    </>
  );
};
