import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TextAreaLabel = styled(Typography)`
  font-weight: 700 !important;
  margin-bottom: ${props => props.theme.spacings.xs2};
`;

export const LabelButtonContent = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs4};
  align-items: center;
`;
