import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getExpenseSettings } from '../../Customizations/context/queries/expenseSettings';
import { IGetExpenseSettingsResponse } from '../../Customizations/context/types/expenseSettings';

interface IManualExpensePermission {
  manualExpenseEntry: boolean;
}

export const useManualExpenses = (skip?: boolean): IManualExpensePermission => {
  const { data } = useQuery<IGetExpenseSettingsResponse>(getExpenseSettings, {
    skip,
  });

  return {
    ...data?.getExpenseSettings,
  };
};
