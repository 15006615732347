import { NetworkStatus } from '@apollo/client';
import { getAdvanceById } from '@containers/Advances/context/queries/advance';
import { IAdvance } from '@containers/Advances/context/types/advance';
import { expensesAdvancesBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';
import { useNavigate } from 'react-router-dom';

export const useAdvanceById = () => {
  const navigate = useNavigate();
  const [advanceById, { loading, data, error, networkStatus }] = useLazyQuery<
    { getAdvanceById: IAdvance },
    { advanceId: number }
  >(getAdvanceById, { notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' });

  async function getAdvance(advanceId: number) {
    try {
      const advance = await advanceById({
        variables: {
          advanceId,
        },
      });

      return advance;
    } catch (err) {
      showToast({ message: err.message, type: 'warning' });
      navigate(expensesAdvancesBaseURL, { replace: true });
    }
  }

  return {
    getAdvanceById: getAdvance,
    advance: data?.getAdvanceById,
    isLoading: loading || networkStatus === NetworkStatus.loading,
    error,
  };
};
