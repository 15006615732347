import { useState } from 'react';
import { EReportPdfExportType, IApprovalsReportGrid } from '@containers/Approvals/context/types/reports';
import { useReportsStatusMutate } from '@containers/Approvals/hooks/useReportsStatusMutate';
import { EReportStatus } from '@containers/Reports/context/types';
import { usePdfFileExportReports } from '@containers/Reports/hooks/usePdfFileExportReports';
import { usePdfUrlExportReports } from '@containers/Reports/hooks/usePdfUrlExportReports';
import { useTranslation } from '@locale/Translator';
import { expensesApprovalsBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useReportDetailsDriver } from '@shared/hooks/navigation/useReportDetailsDriver';
import { useNavigate } from 'react-router-dom';

interface useApprovalsReportsActionsManagerProps {
  isReportDetails?: boolean;
  unSelectAllClick?(): void;
}

export function useApprovalsReportsActionManager({
  isReportDetails,
  unSelectAllClick,
}: useApprovalsReportsActionsManagerProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [justificationIsOpen, setJustificationIsOpen] = useState(false);
  const [approveConfirmIsOpen, setApproveConfirmIsOpen] = useState(false);
  const [reportsSelected, setReportsSelected] = useState<IApprovalsReportGrid['id'][]>([]);
  const { reportsStatusMutate, isLoading } = useReportsStatusMutate(isReportDetails);
  const [exporting, setExporting] = useState<IApprovalsReportGrid['id'][]>([]);
  const { getPdfReports } = usePdfFileExportReports();
  const { getPdfUrlReports } = usePdfUrlExportReports();
  const navigateReportDetails = useReportDetailsDriver();

  function onViewClick(reportsSelected: IApprovalsReportGrid['id']): void {
    navigateReportDetails(reportsSelected);
  }

  function onReproveClick(reportsSelected: IApprovalsReportGrid['id'][]): void {
    setReportsSelected(reportsSelected);
    setJustificationIsOpen(true);
  }

  function onApproveClick(reportsSelected: IApprovalsReportGrid['id'][]) {
    setApproveConfirmIsOpen(true);
    setReportsSelected(reportsSelected);
  }

  async function handleExport(reportsSelected: IApprovalsReportGrid['id'][], type: EReportPdfExportType) {
    try {
      setExporting(exporting => [...exporting, ...reportsSelected]);
      setReportsSelected(reportsSelected);

      await getPdfReports({
        reportIds: reportsSelected,
        pdfType: type,
      });
    } catch (err) {
      showToast({
        message: t('organisms.ApprovalsReportGrid.actionsOptions.feedbacks.export.singularForm.error'),
        type: 'error',
      });
    } finally {
      setExporting(exporting => exporting.filter(current => !reportsSelected.includes(current)));
    }
  }

  async function onExportPdfClick(reportsSelected: IApprovalsReportGrid['id'][]) {
    handleExport(reportsSelected, EReportPdfExportType.NORMAL);
  }

  function onExportPdfByCategoryClick(reportsSelected: IApprovalsReportGrid['id'][]) {
    handleExport(reportsSelected, EReportPdfExportType.CATEGORY);
  }

  async function handleExportPdfUrl(reportsSelected: IApprovalsReportGrid['id'][], type: EReportPdfExportType) {
    try {
      setExporting(exporting => [...exporting, ...reportsSelected]);
      setReportsSelected(reportsSelected);

      await getPdfUrlReports({
        reportIds: reportsSelected,
        pdfType: type,
      });
    } catch (err) {
      showToast({
        message: t('organisms.ApprovalsReportGrid.actionsOptions.feedbacks.export.singularForm.error'),
        type: 'error',
      });
    } finally {
      setExporting(exporting => exporting.filter(current => !reportsSelected.includes(current)));
    }
  }

  async function onExportPdfUrlClick(reportsSelected: IApprovalsReportGrid['id'][]) {
    handleExportPdfUrl(reportsSelected, EReportPdfExportType.NORMAL);
  }

  function onExportPdfUrlByCategoryClick(reportsSelected: IApprovalsReportGrid['id'][]) {
    handleExportPdfUrl(reportsSelected, EReportPdfExportType.CATEGORY);
  }

  function onClosePopup(): void {
    setReportsSelected([]);
    setJustificationIsOpen(false);
    setApproveConfirmIsOpen(false);
  }

  async function onActionJustificationPopup(justificationText: string) {
    try {
      const response = await reportsStatusMutate({
        variables: { reports: reportsSelected, status: EReportStatus.REPROVED, message: justificationText },
      });

      const reports = response?.data?.updateReportListStatus?.reports;
      const successItems: boolean = reports.some(report => !report.error);
      Boolean(reports) &&
        reports.forEach(report => {
          report?.error && showToast({ message: report?.message, type: 'error' });
        });
      successItems &&
        showToast({
          message: t(
            `organisms.approvalReportsPreview.${
              reports?.length > 1 ? 'pluralForm' : 'singularForm'
            }.disapprovedSuccessMessage`,
          ),
          type: 'success',
        });
      isReportDetails && navigateToApprovalsReports();
      unSelectAllClick?.();
    } catch (error) {
      showToast({ message: error?.message, type: 'error' });
    }
    setJustificationIsOpen(false);
  }

  async function onActionApproveConfirmPopup() {
    try {
      const response = await reportsStatusMutate({
        variables: { reports: reportsSelected, status: EReportStatus.APPROVED },
      });

      const reports = response?.data?.updateReportListStatus?.reports;
      const successItems: boolean = reports.some(report => !report.error);
      Boolean(reports) &&
        reports.forEach(report => {
          report?.error && showToast({ message: report?.message, type: 'error' });
        });
      successItems &&
        showToast({
          message: t(
            `organisms.approvalReportsPreview.${
              reports?.length > 1 ? 'pluralForm' : 'singularForm'
            }.approvedSuccessMessage`,
          ),
          type: 'success',
        });

      isReportDetails && navigateToApprovalsReports();
      unSelectAllClick?.();
    } catch (error) {
      showToast({ message: error?.message, type: 'error' });
    }
    setApproveConfirmIsOpen(false);
  }

  function navigateToApprovalsReports() {
    navigate(`${expensesApprovalsBaseURL}`);
  }

  return {
    isLoadingMutate: isLoading,
    justificationIsOpen,
    approveConfirmIsOpen,
    reportsSelected,
    exportingItems: exporting,
    onReproveClick,
    onApproveClick,
    onExportPdfClick,
    onExportPdfByCategoryClick,
    onClosePopup,
    onActionJustificationPopup,
    onActionApproveConfirmPopup,
    onViewClick,
    onExportPdfUrlClick,
    onExportPdfUrlByCategoryClick,
  };
}
