import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Header = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs5};
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const Caption = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const Body = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const Field = styled(TextField)`
  width: 100%;
`;
