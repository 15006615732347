import { useEffect, useMemo } from 'react';
import { CustomFieldRegisterForm, ICustomField } from '@containers/Customizations/context/types/customFieldTypes';
import { useSaveCustomField } from '@containers/Customizations/hooks/CustomFields/useSaveCustomField';
import { EFieldType } from '@containers/Expenses/context/types';
import { BoxAdornment, Divider, TextField, Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { Box } from '@mui/material';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useForm } from 'react-hook-form';

import CustomFieldTypeSelect from '../CustomFieldTypeSelect/CustomFieldTypeSelect';
import CustomFieldPreview from './CustomFieldPreview';
import CustomFieldSelectOptions from './CustomFieldSelectOptions/CustomFieldSelectOptions';
import { Container, Content, Description, FieldDataWrapper, LeftWrapper, RightWrapper, Title } from './styled';

type Props = {
  customField?: ICustomField;
  open: boolean;
  onCloseModal: () => void;
};

export const CustomFieldModal = ({ onCloseModal, open, customField }: Props) => {
  const t = useTranslate('organisms.customizationsCustomFields.modal.customField');
  const actionType = useMemo<'add' | 'edit'>(() => (customField ? 'edit' : 'add'), [customField]);
  const saveCustomField = useSaveCustomField();
  const customFieldForm = useForm<CustomFieldRegisterForm>({
    defaultValues: {
      active: true,
      required: false,
      options: [{ value: '' }],
    },
  });

  useEffect(() => {
    if (customField) {
      customFieldForm.reset({
        active: customField.active,
        label: customField.label,
        type: { label: customField.type, value: customField.type },
        options: customField.metadata?.options?.map(value => ({ value })) || [],
        required: customField.required,
      });
    }
  }, [customField, customFieldForm]);

  async function onSubmit(form: CustomFieldRegisterForm) {
    await saveCustomField.handleSave(form, customField?.id);

    onCloseModal?.();
  }

  return (
    <DefaultModal
      open={open}
      actionDisabled={!customFieldForm.formState.isValid}
      labelButtonAction={t(`actionButton.${actionType}`)}
      headerTitle={t(`title.${actionType}`)}
      onActionClick={customFieldForm.handleSubmit(onSubmit)}
      isActionLoading={customFieldForm.formState.isSubmitting}
      showTooltipButtonAction={!customFieldForm.formState.isValid}
      tooltipButtonAction={t('fields.validationMessage')}
      onCancelClick={onCloseModal}>
      <Container>
        <Content>
          <LeftWrapper>
            <Title variant="headline8">{t('configureField')}</Title>
            <Description variant="body4">{t('addFieldInfo')}</Description>
            <FieldDataWrapper>
              <CustomFieldTypeSelect control={customFieldForm.control} customField={customField} />

              <Divider orientation="horizontal" />

              <Controller
                control={customFieldForm.control}
                name="label"
                render={({ field }) => <TextField label={t(`fields.customFieldName`)} fullWidth {...field} />}
                rules={{ required: true }}
              />

              <Divider orientation="horizontal" />

              {customFieldForm?.watch?.('type')?.value === EFieldType.OPTIONS && (
                <CustomFieldSelectOptions control={customFieldForm.control} />
              )}

              <Box mb={1}>
                <BoxAdornment
                  title={t(`fields.settings.label`)}
                  description={t(`fields.settings.caption`)}
                  leftAdornment={
                    <Controller
                      control={customFieldForm.control}
                      name="active"
                      render={({ field }) => (
                        <Toggle checked={field.value} onChange={() => field.onChange(!field.value)} />
                      )}
                    />
                  }
                />
              </Box>

              <BoxAdornment
                title={t(`fields.requiredField.label`)}
                description={t(`fields.requiredField.tooltip`)}
                leftAdornment={
                  <Controller
                    control={customFieldForm.control}
                    name="required"
                    render={({ field }) => (
                      <Toggle checked={field.value} onChange={() => field.onChange(!field.value)} />
                    )}
                  />
                }
              />
            </FieldDataWrapper>
          </LeftWrapper>
          <Divider removeAt={768} orientation="vertical" />
          <Divider appearAt={768} orientation="vertical" />

          <RightWrapper>
            <Box position="fixed" width={400}>
              <Title variant="headline8">Preview</Title>
              <Description variant="body4">{t('preview.description')}</Description>

              <CustomFieldPreview control={customFieldForm.control} />
            </Box>
          </RightWrapper>
        </Content>
      </Container>
    </DefaultModal>
  );
};
