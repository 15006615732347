import { useEffect, useState } from 'react';
import { EReportStatus } from '@containers/Reports/context/types';
import { SelectOption } from '@shared/helpers/selectOptions';
import { useReportStatusesPermissions } from '@shared/hooks/permissions/systemPermissions/useReportStatusesPemission';

interface useManageSelectionReportStatusProps {
  defaultFilters?: EReportStatus[];
  onPageClick?(selectedPage: number): void;
}
interface useManageSelectionReportStatus {
  selectedStatus: EReportStatus[];
  optionsSelectStatus: SelectOption[];
  onSelectedStatusClick(status: EReportStatus): EReportStatus[];
  onSelectedStatusMultipleClick(status: EReportStatus[]): void;
  onClearAllStatus(): void;
  onSelectAllStatus(): void;
  setCheckedOptions(options: SelectOption[], currentStatus: EReportStatus[]): SelectOption[];
}

export function useManageSelectionReportStatus({
  defaultFilters,
  onPageClick,
}: useManageSelectionReportStatusProps): useManageSelectionReportStatus {
  const [selectedStatus, setSelectedStatus] = useState([...defaultFilters]);
  const reportStatus = useReportStatusesPermissions();
  const [optionsSelectStatus, setOptionsSelectStatus] = useState<SelectOption[]>(reportStatus);

  useEffect(() => setOptionsSelectStatus(reportStatus), [reportStatus?.toString?.()]);

  function onSelectedStatusClick(status: EReportStatus): EReportStatus[] {
    let currentStatus = [...selectedStatus];
    const currentStatusIndex: number = currentStatus.findIndex(item => item == status);

    if (currentStatusIndex != -1) {
      currentStatus.splice(currentStatusIndex, 1);
    } else {
      currentStatus = [...selectedStatus, status];
    }

    if (!Boolean(currentStatus?.length)) {
      currentStatus = Object.values(EReportStatus);
    }

    onPageClick(1);
    setOptionsSelectStatus(setCheckedOptions(optionsSelectStatus, currentStatus));
    setSelectedStatus(currentStatus);
    return currentStatus;
  }

  function onClearAllStatus(): void {
    setSelectedStatus([]);
  }

  function onSelectAllStatus(): void {
    setSelectedStatus(Object.values(EReportStatus));
  }

  function setCheckedOptions(options: SelectOption[], currentStatus: EReportStatus[]): SelectOption[] {
    return options.map(option => ({
      ...option,
      checked: currentStatus?.includes(EReportStatus[option.value]),
    }));
  }

  function onSelectedStatusMultipleClick(status: EReportStatus[]): void {
    setSelectedStatus(status);
  }

  useEffect(() => {
    setSelectedStatus([...defaultFilters]);
  }, [defaultFilters]);

  return {
    optionsSelectStatus,
    selectedStatus,
    onSelectedStatusClick,
    onClearAllStatus,
    onSelectAllStatus,
    onSelectedStatusMultipleClick,
    setCheckedOptions,
  };
}
