import { ReactNode } from 'react';
import { ExpenseHistoryGrid } from '@containers/Expenses/components/molecules/ExpenseHistoryGrid';
import { OdometerExpenseDetails } from '@containers/Expenses/components/molecules/OdometerExpenseDetails';
import { EExpenseType, IExpenses } from '@containers/Expenses/context/types';
import { Tab } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import { GPSDetails } from '../GPSDetails/GPSDetails';
import { OriginDestinationExpenseDetail } from '../OriginDestinationExpenseDetail/OriginDestinationExpenseDetail';

interface IProps {
  expense: IExpenses;
  isLoading: boolean;
}

export function ExpenseTypeDetails({ expense, isLoading }: IProps) {
  const { t } = useTranslation();
  const history: ReactNode = (
    <ExpenseHistoryGrid
      expenseType={expense?.type}
      historic={expense?.history}
      expenseHistoryTotal={expense?.history?.length}
      isLoading={isLoading}
      onHistoryPageClick={() => {}}
    />
  );

  if (isLoading || expense.type === EExpenseType.MANUAL) {
    return history;
  }

  function renderKilometerByType() {
    switch (expense.type) {
      case EExpenseType.ORIGIN_DESTINATION:
        return <OriginDestinationExpenseDetail route={expense?.route} isLoading={isLoading} />;
      case EExpenseType.ODOMETER:
        return <OdometerExpenseDetails {...expense?.odometer} />;
      case EExpenseType.GPS:
        return <GPSDetails isLoading={isLoading} route={expense.route} />;
      default:
        return <h1>Tipo de despesa não mapeado</h1>;
    }
  }

  return (
    <Tab
      tabItens={[
        {
          label: t('organisms.expenseDetails.route'),
          component: renderKilometerByType(),
        },
        {
          label: t('organisms.expenseHistoryGrid.title'),
          component: history,
        },
      ]}
      variant="primary"
    />
  );
}
