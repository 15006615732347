import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';

import * as SC from './styled';

interface ConfirmationModalDeleteExpenseProps {
  isOpen: boolean;
  isLoading?: boolean;
  expensesNumber: number;
  onActionClick(): void;
  onCloseClick(): void;
}

export const ConfirmationModalDeleteExpense = ({
  isOpen,
  isLoading,
  expensesNumber,
  onActionClick,
  onCloseClick,
}: ConfirmationModalDeleteExpenseProps) => {
  const { t } = useTranslation();
  const formOfDescription = expensesNumber > 1 ? 'pluralForm' : 'singularForm';

  return (
    <SC.Container>
      <DangerActionModal
        isLoading={isLoading}
        headerIconLabel={t('organisms.confirmationModalDeleteExpense.attention')}
        headerTitle={t(`organisms.confirmationModalDeleteExpense.${formOfDescription}.popupTitle`, {
          expensesSelected: expensesNumber,
        })}
        headerSubtitle={t(`organisms.confirmationModalDeleteExpense.${formOfDescription}.popupSubtitle`)}
        labelButtonAction={
          <SC.LabelButtonContent>
            <div>{t(`organisms.confirmationModalDeleteExpense.${formOfDescription}.confirm`)}</div>
            <Icons name="IconTrash" fill="transparent" size={24} />
          </SC.LabelButtonContent>
        }
        onActionClick={onActionClick}
        onCancelClick={onCloseClick}
        onCloseClick={onCloseClick}
        open={isOpen}></DangerActionModal>
    </SC.Container>
  );
};
