import React from 'react';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

interface ImportContainerProps {
  title: string;
  subTitle: string;
  icon?: IconTypes;
  hasBackgroundColor?: boolean;
  children?: React.ReactNode;
}

export const ImportContainer = ({
  title,
  subTitle,
  icon,
  hasBackgroundColor = false,
  children,
}: ImportContainerProps) => {
  return (
    <SC.Container hasBackgroundColor={hasBackgroundColor}>
      {icon && <SC.Icon name={icon} />}
      <SC.TextContainer>
        <SC.Title variant="headline8">{title}</SC.Title>
        <SC.SubTitle variant="body3">{subTitle}</SC.SubTitle>
        {children && children}
      </SC.TextContainer>
    </SC.Container>
  );
};
