import { useContext } from 'react';
import { ApolloProvider } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { __DEV__ } from '@apollo/client/utilities/globals';
import { apolloClient } from '@services/utils/apolloClient';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';

interface IProps {
  children: React.ReactNode;
}
export function ApiProvider({ children }: IProps) {
  const { companyId, accessToken } = useContext(SessionContext);

  if (__DEV__) {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
  }

  return <ApolloProvider client={apolloClient(companyId, accessToken)}>{children}</ApolloProvider>;
}
