import { useMemo } from 'react';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';

export function useTargetCustomApprovalFlow() {
  const currentPath = useCurrentPath();

  return useMemo(() => {
    return currentPath.includes('reports') ? 'reports' : 'advances';
  }, [currentPath]);
}
