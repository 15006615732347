import { useEffect, useState } from 'react';
import { IConfigurationReportSetting } from '@containers/Customizations/context/types/settings/expenseReportSettingsTypes';
import { useExpenseReportSettings } from '@containers/Customizations/hooks/settings/report/useExpenseReportsSettings';
import { useReportExpenseSettingsMutate } from '@containers/Customizations/hooks/settings/report/useExpenseReportsSettingsMutate';
import { showToast } from '@shared/helpers/toast';
import { cloneDeep } from 'lodash';

export interface IUseReportSettingsManager {
  reportSetting: IConfigurationReportSetting;
  isLoading: boolean;
  updateIsLoading: boolean;
  handleChange(settingKey: string, value: unknown, currentReportSetting: IConfigurationReportSetting): void;
}

export function useExpenseReportSettingsManager(): IUseReportSettingsManager {
  const { settings, isLoading: isFetchingExpenseReport } = useExpenseReportSettings();
  const [reportsSettingsIsLoading, setReportsSettingsIsLoading] = useState(false);
  const [reportSetting, setReportSetting] = useState<IConfigurationReportSetting>();
  const { updateExpenseSetting, isLoading: updateIsLoading } = useReportExpenseSettingsMutate();

  async function handleChange(
    settingKey: string,
    value: number | boolean | string | object,
    currentReportSetting: IConfigurationReportSetting,
  ) {
    try {
      setReportSetting(currentReportSetting);
      await updateExpenseSetting(settingKey, value);
    } catch (error) {
      getDataReportsSettings();
    }
  }

  function serializeReportSettings(data: IConfigurationReportSetting): IConfigurationReportSetting {
    const _reportSetting: IConfigurationReportSetting = (data && cloneDeep(data)) || null;
    return _reportSetting;
  }

  async function getDataReportsSettings() {
    try {
      setReportSetting(serializeReportSettings(settings));
      setReportsSettingsIsLoading(false);
    } catch (error) {
      error && showToast({ message: error?.message, type: 'error' });
      setReportsSettingsIsLoading(false);
    }
  }

  useEffect(() => {
    setReportsSettingsIsLoading(true);
    getDataReportsSettings();
  }, []);

  useEffect(() => {
    setReportsSettingsIsLoading(true);
    getDataReportsSettings();
  }, [settings]);

  return {
    reportSetting,
    isLoading: reportsSettingsIsLoading || isFetchingExpenseReport,
    updateIsLoading,
    handleChange,
  };
}
