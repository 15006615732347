import { useEffect, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { DistanceUnitType, IMileagePolicySettings } from '@containers/Customizations/context/types/policies';
import { useMileageByPolicyId } from '@containers/Customizations/hooks/useMilageByPolicyId';
import { useMileageSettingsMutate } from '@containers/Customizations/hooks/useMileageSettingsMutate';
import { Divider, Radio, Skeleton, Toggle, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Box } from '@mui/material';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { showToast } from '@shared/helpers/toast';

import * as SC from './styled';

type Props = {
  policyId: number | undefined;
  isPolicyLoading: boolean;
};

export const MileageGeneralPreview = ({ policyId }: Props) => {
  const { t } = useTranslation();
  const isDistanceUnitEnabled = false;
  const { mileagePolicy, refetch: refetchMileage } = useMileageByPolicyId({
    id: policyId,
  });
  const { mileageMutate: updateMileage } = useMileageSettingsMutate();
  const [settings, setSettings] = useState<IMileagePolicySettings>();
  async function updateSettings(updated: Partial<IMileagePolicySettings>) {
    saveMileageSettings({ ...settings, ...updated });
  }
  function updateMileageRate(rate: DistanceUnitType) {
    const newSettings: IMileagePolicySettings = { ...settings, distanceUnitType: rate };

    saveMileageSettings(newSettings);
  }

  async function saveMileageSettings(newSettings: IMileagePolicySettings) {
    try {
      setSettings(newSettings);

      await updateMileage({
        variables: {
          policyId: policyId,
          policyMileageId: mileagePolicy.id,
          policyMileageSettingsInput: {
            enableSourceDestiny: newSettings?.enableSourceDestiny,
            enableOdometer: newSettings?.enableOdometer,
            enableGps: newSettings?.enableGps,
            enablePinGps: newSettings?.enablePinGps,
            allowSearchNewPlaces: newSettings?.allowSearchNewPlaces,
            enableRegisteredLocations: newSettings?.enableRegisteredLocations,
            enableMultipleLocations: newSettings?.enableMultipleLocations,
            distanceUnitType: newSettings?.distanceUnitType,
            policyMileageRatesSettings: newSettings?.policyMileageRatesSettings.map(({ rate, startDate, id }) => ({
              id,
              rate,
              startDate,
            })),
          },
        },
      });
    } catch (err: unknown) {
      await refetchMileage();

      setSettings(mileagePolicy);

      showToast({
        message: (err as Error).message,
        type: 'error',
      });
    }
  }

  useEffect(() => setSettings(mileagePolicy), [mileagePolicy]);

  if (!settings) return <Skeleton variant={'rounded'} width={'100%'} height={400} />;

  return (
    <>
      <SC.ToggleContainer>
        <SC.MileageConfigurationBox
          key="originDestination"
          title={t('organisms.mileagePreview.mileageToggles.originDestination.title')}
          description={t('organisms.mileagePreview.mileageToggles.originDestination.description')}
          leftAdornment={
            <Toggle
              checked={settings?.enableSourceDestiny}
              onChange={(_, enableSourceDestiny) => updateSettings({ enableSourceDestiny })}
            />
          }
        />

        <Box paddingLeft={{ sm: 3, md: 5 }}>
          {/* <PermissionsChecker licenseFeature={'ADVANCED_MILEAGE'}>
            <SC.MileageConfigurationBox
              key="registeredPlaces"
              title={t('organisms.mileagePreview.mileageToggles.registeredPlaces.title')}
              description={t('organisms.mileagePreview.mileageToggles.registeredPlaces.description')}
              leftAdornment={
                <Toggle
                  checked={settings?.enableRegisteredLocations}
                  onChange={(_, enableRegisteredLocations) => updateSettings({ enableRegisteredLocations })}
                />
              }
            />
          </PermissionsChecker> */}

          <SC.MileageConfigurationBox
            key="multipleAddresses"
            title={t('organisms.mileagePreview.mileageToggles.multipleAddresses.title')}
            description={t('organisms.mileagePreview.mileageToggles.multipleAddresses.description')}
            leftAdornment={
              <Toggle
                checked={settings?.enableMultipleLocations}
                onChange={(_, enableMultipleLocations) => updateSettings({ enableMultipleLocations })}
              />
            }
          />

          <PermissionsChecker licenseFeature={'ADVANCED_MILEAGE'}>
            <SC.MileageConfigurationBox
              key="gps"
              title={t('organisms.mileagePreview.mileageToggles.gps.title')}
              description={t('organisms.mileagePreview.mileageToggles.gps.description')}
              leftAdornment={
                <Toggle
                  checked={settings?.enablePinGps}
                  onChange={(_, enablePinGps) => updateSettings({ enablePinGps })}
                />
              }
            />
          </PermissionsChecker>

          <SC.MileageConfigurationBox
            key="manualEntry"
            title={t('organisms.mileagePreview.mileageToggles.manualEntry.title')}
            description={t('organisms.mileagePreview.mileageToggles.manualEntry.description')}
            leftAdornment={
              <Toggle
                checked={settings?.enableRegisteredLocations}
                onChange={(_, allowSearchNewPlaces) =>
                  updateSettings({ allowSearchNewPlaces, enableRegisteredLocations: allowSearchNewPlaces })
                }
              />
            }
          />
        </Box>

        <SC.MileageConfigurationBox
          key="odometer"
          title={t('organisms.mileagePreview.mileageToggles.odometer.title')}
          description={t('organisms.mileagePreview.mileageToggles.odometer.description')}
          leftAdornment={
            <Toggle
              checked={settings?.enableOdometer}
              onChange={(_, enableOdometer) => updateSettings({ enableOdometer })}
            />
          }
        />

        <PermissionsChecker licenseFeature={'ADVANCED_MILEAGE'}>
          <SC.MileageConfigurationBox
            title={
              <Box flexDirection="row" display="flex" alignItems="center" mb={0.5}>
                {t('organisms.mileagePreview.mileageToggles.gpsPin.title')}

                <Box ml={1}>
                  <StatusTag size="small" variant="neutral">
                    {t('organisms.mileagePreview.mileageToggles.gpsPin.appOnly')}
                  </StatusTag>
                </Box>
              </Box>
            }
            description={t('organisms.mileagePreview.mileageToggles.gpsPin.description')}
            leftAdornment={
              <Toggle checked={settings?.enableGps} onChange={(_, enableGps) => updateSettings({ enableGps })} />
            }
          />
        </PermissionsChecker>
      </SC.ToggleContainer>

      <Divider orientation="horizontal" />

      {isDistanceUnitEnabled && (
        <>
          <Divider orientation="horizontal" />

          <SC.UnityContainer>
            <Typography variant="headline8">{t('organisms.mileagePreview.distanceUnit.title')}</Typography>

            <SC.UnityTypeContainer>
              <div>
                <Radio
                  checked={settings.distanceUnitType === DistanceUnitType.KILOMETERS}
                  onChange={() => updateMileageRate(DistanceUnitType.KILOMETERS)}
                />
                <Typography variant="body3">{t('organisms.mileagePreview.distanceUnit.kilometer')}</Typography>
              </div>

              <div>
                <Radio
                  checked={settings.distanceUnitType === DistanceUnitType.MILES}
                  onChange={() => updateMileageRate(DistanceUnitType.MILES)}
                />
                <Typography variant="body3">{t('organisms.mileagePreview.distanceUnit.miles')}</Typography>
              </div>
            </SC.UnityTypeContainer>
          </SC.UnityContainer>
        </>
      )}
    </>
  );
};
