import { Collapse } from '@atoms/Collapse';
import { EExpenseType } from '@containers/Expenses/context/types';
import { IRoute } from '@containers/Expenses/context/types/expenseById';
import { TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from 'react-i18next';

import { AddNewWaypoint } from '../../molecules/AddNewWaypoint/AddNewWaypoint';
import { DistanceRoutes } from '../../molecules/DistanceRoutes/DistanceRoutes';
import { ReceiptsImages } from '../../molecules/ReceiptsImages';
import { ESizeVariant } from '../../molecules/ReceiptsImages/variants';
import { useWaypointFieldsHelper } from './hooks/useWaypointFieldsHelper';
import * as SC from './styled';
import { WaypointField } from './WaypointField/WaypointField';
import { WaypointFieldsSkeleton } from './WaypointFieldsSkeleton';
import { WaypointsExpandButton } from './WaypointsExpandButton/WaypointsExpandButton';

interface IProps {
  route?: IRoute;
  isLoading?: boolean;
  viewOnly?: boolean;
  ratePerKm: number;
  ratePerKmIsLoading?: boolean;
  expenseType?: EExpenseType;
}

export function WaypointFields({
  route,
  viewOnly = false,
  ratePerKm,
  ratePerKmIsLoading = false,
  expenseType,
  isLoading,
}: IProps) {
  const { t } = useTranslation();
  const {
    fields,
    distance,
    showAllWaypoints,
    showExpandButton,
    distanceIsLoading,
    establishmentsIsLoading,
    showAddNewWaypoint,
    serializingIsProcessing,
    checkMinimumFields,
    setShowAllWaypoints,
    getLastIndex,
    appendField,
    removeField,
    updateField,
  } = useWaypointFieldsHelper({ route, ratePerKm, expenseType });

  if (isLoading || establishmentsIsLoading || serializingIsProcessing || ratePerKmIsLoading) {
    return <WaypointFieldsSkeleton viewOnly={viewOnly} />;
  }

  return (
    <SC.Container viewOnly={viewOnly}>
      {expenseType === EExpenseType.GPS ? (
        <SC.ContainerGpsFields>
          <TextField value={route?.origin} label={t('organisms.waypointFields.fieldLabel.origin')} disabled />
          <TextField value={route?.destination} label={t('organisms.waypointFields.fieldLabel.destination')} disabled />
        </SC.ContainerGpsFields>
      ) : (
        <>
          <WaypointField
            index={0}
            currentValue={fields[0]}
            nextValue={fields[1]}
            fieldType={'origin'}
            onDelete={removeField}
            onChange={data => updateField(0, data)}
            disabledDelete={true}
            viewOnly={viewOnly}
          />

          {!showAllWaypoints && showExpandButton && (
            <SC.ExpandButtonContainer viewOnly={viewOnly}>
              <WaypointsExpandButton isOpen={showAllWaypoints} onClick={() => setShowAllWaypoints(open => !open)} />
            </SC.ExpandButtonContainer>
          )}

          <Collapse isOpen={showAllWaypoints}>
            {fields?.slice(1, getLastIndex()).map((_, index) => {
              const _index: number = index + 1;

              return (
                <WaypointField
                  key={`waypoint-field-${_index}`}
                  index={_index}
                  previousValue={fields[_index - 1]}
                  currentValue={fields[_index]}
                  nextValue={fields[_index + 1]}
                  fieldType={'stop'}
                  onDelete={removeField}
                  onChange={data => updateField(_index, data)}
                  disabledDelete={_index == 1 && checkMinimumFields()}
                  viewOnly={viewOnly}
                />
              );
            })}
          </Collapse>

          <WaypointField
            index={getLastIndex()}
            previousValue={fields[getLastIndex() - 1]}
            currentValue={fields[getLastIndex()]}
            fieldType={'destination'}
            onDelete={removeField}
            onChange={data => updateField(getLastIndex(), data)}
            disabledDelete={getLastIndex() && checkMinimumFields()}
            viewOnly={viewOnly}
            isLastItem={viewOnly && showAddNewWaypoint}
          />

          {showAllWaypoints && showExpandButton && (
            <SC.ExpandButtonContainer>
              <WaypointsExpandButton
                isOpen={showAllWaypoints}
                onClick={() => setShowAllWaypoints(open => !open)}
                viewOnly={viewOnly}
              />
            </SC.ExpandButtonContainer>
          )}
        </>
      )}

      {!viewOnly && showAddNewWaypoint && (
        <>
          {!viewOnly && expenseType !== EExpenseType.GPS && <AddNewWaypoint onAddClick={() => appendField({})} />}
          <SC.DistanceRoutesContainer>
            <DistanceRoutes
              variant="primary"
              distance={distance}
              ratePerKM={ratePerKm || route?.ratePerKm || 0}
              isLoading={distanceIsLoading}
            />
          </SC.DistanceRoutesContainer>
        </>
      )}

      {!isLoading && expenseType === EExpenseType.GPS && route.imagePath && (
        <SC.ContainerImageGps>
          <SC.ContentImageGps>
            <ReceiptsImages
              receiptsImages={route.previewImage.map(({ path, contentType, base64, url }) => ({
                contentType,
                base64,
                path,
                previewUrl: url,
              }))}
              previewOnly
              size={ESizeVariant.large}
              isLoading={isLoading}
            />
          </SC.ContentImageGps>
        </SC.ContainerImageGps>
      )}
    </SC.Container>
  );
}
