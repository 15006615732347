import { BoxAdornment } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ToggleContainer = styled.div``;

export const MileageConfigurationBox = styled(BoxAdornment)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacings.xs};
`;

export const UnityContainer = styled.div`
  margin-top: ${props => props.theme.spacings.xs};
`;

export const UnityTypeContainer = styled.div`
  display: flex;
  margin-top: ${props => props.theme.spacings.xs};

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${props => props.theme.spacings.m};
  }
`;
