import {
  ECustomApprovalType,
  IApprovalFlowSource,
} from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import { ApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { IconButton, Icons, IconTypes, LinkButton, Radio, ShapeIcon, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { AutomaticDisapproval } from '../AutomaticDisapproval/AutomaticDisapproval';
import { ApprovalByLevelRow } from './ApprovalByLevelRow/ApprovalByLevelRow';
import { useOptionsListSources } from './ApprovalByLevelRow/hooks/useOptionsListSources';
import * as SC from './styled';

export interface IProps {
  isLoading?: boolean;
}

export function ApprovalByLevel({ isLoading }: IProps) {
  const t = useTranslate('approvalFlows.approvalSources');
  const currentPath = useCurrentPath();
  const hasAdvance = currentPath.includes('advances');
  const { colors } = useTheme();
  const { control, getValues, setValue, clearErrors } = useFormContext<ApprovalFlowForm>();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'sources',
    keyName: '_id',
  });
  const addDisabled = control?._formValues?.sources?.length >= 3 || getValues(`sources.${fields.length - 1}.type`);
  const { criteriaOptions, hierarchyOptions, approversOptions, isLoadingSourceOptions } = useOptionsListSources();
  const watch = useWatch();

  function onChange(index: number, value: IApprovalFlowSource) {
    update(index, value);
    clearErrors();
  }

  return (
    <SC.Container>
      <SectionContainer
        title={t('approvalByLevels.title')}
        caption={t('approvalByLevels.caption')}
        leftAdornment={
          <Radio
            size="medium"
            checked={watch.type === ECustomApprovalType.MANUAL}
            onChange={() => setValue('type', ECustomApprovalType.MANUAL)}
          />
        }>
        {watch.type === ECustomApprovalType.MANUAL && (
          <SC.Content>
            {fields.map((_data, index) => {
              const identifier = `IconNumber${index + 1}` as IconTypes;
              return (
                <SC.ConditionalRow key={`${_data?._id}-${index}`}>
                  <SC.ConditionalIdentifier>
                    <ShapeIcon name={identifier} variant="default" color={colors.secondary[50]} size={32} />
                  </SC.ConditionalIdentifier>
                  <ApprovalByLevelRow
                    isLoading={isLoadingSourceOptions || isLoading}
                    criteriaOptions={criteriaOptions}
                    hierarchyOptions={hierarchyOptions}
                    approversOptions={approversOptions}
                    onChange={onChange}
                    index={index}
                  />
                  {index > 0 && (
                    <div>
                      <IconButton
                        icon="IconTrash"
                        variant="line"
                        size="small"
                        onClick={() => remove(index)}
                        loading={isLoadingSourceOptions || isLoading}
                      />
                    </div>
                  )}
                </SC.ConditionalRow>
              );
            })}
            <Tooltip
              arrow
              title={!addDisabled && t('approvalByLevels.tooltipLink')}
              placement="top"
              style={{ width: 'fit-content' }}>
              <div>
                <LinkButton variant="default" disabled={!addDisabled} style={{ width: 'fit-content' }}>
                  <SC.LinkContent
                    onClick={() =>
                      append({
                        approverId: 0,
                        configType: null,
                        type: null,
                      })
                    }>
                    {t('approvalByLevels.buttonLink')}
                    <Icons name="IconPlus" fill="transparent" size={18} />
                  </SC.LinkContent>
                </LinkButton>
              </div>
            </Tooltip>
          </SC.Content>
        )}
      </SectionContainer>
      <SectionContainer
        title={t('automaticApproval.title')}
        caption={t(`automaticApproval.caption.${hasAdvance ? 'advance' : 'report'}`)}
        leftAdornment={
          <Radio
            size="medium"
            checked={watch.type === ECustomApprovalType.AUTOMATIC_APPROVAL}
            onChange={() => setValue('type', ECustomApprovalType.AUTOMATIC_APPROVAL)}
          />
        }
      />
      <SectionContainer
        title={t('automaticDisapproval.title')}
        caption={t(`automaticDisapproval.caption.${hasAdvance ? 'advance' : 'report'}`)}
        leftAdornment={
          <Radio
            size="medium"
            checked={watch.type === ECustomApprovalType.AUTOMATIC_REFUSAL}
            onChange={() => setValue('type', ECustomApprovalType.AUTOMATIC_REFUSAL)}
          />
        }>
        {watch.type === ECustomApprovalType.AUTOMATIC_REFUSAL && (
          <SC.Content>
            <AutomaticDisapproval />
          </SC.Content>
        )}
      </SectionContainer>
    </SC.Container>
  );
}
