import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getAllCurrencies } from '../context/queries/currencies';
import { ICurrenciesSummary, ICurrenciesSummaryResponse } from '../context/types/currencies';

interface UseCurrencies {
  currencies: ICurrenciesSummary[];
  isLoading: boolean;
  error: unknown;
}

export const useAllCurrencies = (skip: boolean = false): UseCurrencies => {
  const { loading, data, error, networkStatus } = useQuery<ICurrenciesSummaryResponse>(getAllCurrencies, {
    notifyOnNetworkStatusChange: true,
    skip,
  });

  return {
    currencies: data?.getAllCurrencies?.currencies || [],
    isLoading: loading || networkStatus === NetworkStatus.loading,
    error,
  };
};
