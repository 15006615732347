import { EExpenseType } from '@containers/Expenses/context/types';
import { useLocation } from 'react-router-dom';

enum ECurrentPage {
  ORIGIN_DESTINATION = 'originDestination',
  ODOMETER = 'odometer',
  GPS = 'gps',
}

export function useCheckExpenseType() {
  const location = useLocation();
  const disassembledPathname: string[] = location.pathname.split('/');

  if (disassembledPathname.includes(ECurrentPage.ORIGIN_DESTINATION)) {
    return EExpenseType.ORIGIN_DESTINATION;
  }

  if (disassembledPathname.includes(ECurrentPage.ODOMETER)) {
    return EExpenseType.ODOMETER;
  }

  if (disassembledPathname.includes(ECurrentPage.GPS)) {
    return EExpenseType.GPS;
  }

  return EExpenseType.MANUAL;
}
