import { useMemo } from 'react';
import { RouteField } from '@containers/Expenses/components/molecules/RouteField/RouteField';
import { WaypointIdentifier } from '@containers/Expenses/components/molecules/WaypointIdentifier/WaypointIdentifier';
import { IExpenseInput } from '@containers/Expenses/context/types';
import { IEstablishment } from '@containers/Expenses/context/types/establishments';
import { useTranslation } from '@locale/Translator';
import { Controller, useFormContext } from 'react-hook-form';

import * as SC from './styled';

export type TWaypointFieldType = 'origin' | 'destination' | 'stop';

interface IProps {
  index: number;
  fieldType: TWaypointFieldType;
  disabledDelete: boolean;
  previousValue?: IEstablishment;
  currentValue?: IEstablishment;
  nextValue?: IEstablishment;
  viewOnly?: boolean;
  isLastItem?: boolean;
  onDelete?: (index: number) => void;
  onChange?: (waypoint: IEstablishment) => void;
}

export function WaypointField({
  index,
  fieldType,
  disabledDelete,
  previousValue,
  currentValue,
  nextValue,
  viewOnly = false,
  isLastItem = false,
  onDelete,
  onChange,
}: IProps) {
  const { t } = useTranslation();
  const { control } = useFormContext<IExpenseInput>();
  const _previousValue: boolean = !viewOnly ? Boolean(previousValue?.placeId || previousValue?.place) : !viewOnly;
  const _currentValue: boolean = viewOnly || Boolean(currentValue?.placeId || currentValue?.place);
  const _nextValue: boolean = !viewOnly ? Boolean(nextValue?.placeId || nextValue?.place) : !viewOnly;

  return useMemo(
    () => (
      <Controller
        control={control}
        name={`route.waypoints.${index}`}
        defaultValue={{}}
        render={({ field }) => {
          return (
            <SC.WaypointContainer key={`field-waypoint${index}`}>
              <WaypointIdentifier
                routeIndex={index}
                hasPreviousValue={_previousValue}
                hasCurrentValue={_currentValue}
                hasNextValue={_nextValue}
                variant={viewOnly ? 'viewOnly' : 'field'}
                isLastItem={isLastItem}
              />
              {!viewOnly ? (
                <RouteField
                  onChange={data => {
                    field.onChange?.(data || {});
                    onChange?.(data || {});
                  }}
                  value={currentValue}
                  label={t(`organisms.waypointFields.fieldLabel.${fieldType}`)}
                  onDelete={() => onDelete?.(index)}
                  disabledDelete={disabledDelete}
                />
              ) : (
                <SC.ViewOnlyContainer>
                  <SC.ViewOnlyLabel variant="headline8">
                    {t(`organisms.waypointFields.fieldLabel.${fieldType}`)}
                  </SC.ViewOnlyLabel>
                  <SC.ViewOnlyDescription variant="body3">{currentValue?.place}</SC.ViewOnlyDescription>
                </SC.ViewOnlyContainer>
              )}
            </SC.WaypointContainer>
          );
        }}
      />
    ),
    [currentValue, previousValue, nextValue, disabledDelete],
  );
}
