import { TTargetDelegation } from '@containers/Customizations/components/organisms/Delegation/DelegationPreview';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useFetch } from '@shared/hooks/service/useFetch';
import { EAuthZPermission } from '@shared/types/authZPermission';

interface IProps {
  target: TTargetDelegation;
  userId: string;
}

interface IUserOptionResponse {
  id: string;
  name: string;
}

export function useUserDelegateOptions({ target, userId }: IProps) {
  const { checkPlatformPermission } = usePlatformPermissions();
  const { data, error, isLoading } = useFetch<IUserOptionResponse[]>(userId ? getPathDataSource() : null);

  function getPathDataSource() {
    switch (target) {
      case 'expense':
        return checkPlatformPermission(EAuthZPermission.CONFIGURATION_DELEGATION)
          ? 'v1/user-delegation/get-user-for-selection'
          : null;
      case 'approval':
        return checkPlatformPermission(EAuthZPermission.CONFIGURATION_DELEGATION_APPROVAL)
          ? `v1/user-delegation-approval/get-user-for-selection?userId=${userId}`
          : null;
      default:
        return null;
    }
  }

  function serializeOptions() {
    return data
      ? data.map(user => ({
          value: user.id,
          label: user.name,
        }))
      : [];
  }

  return {
    users: serializeOptions(),
    isLoading,
    error,
  };
}
