import { useState } from 'react';

interface UsePaginationProps {
  currentPage: number;
  itemsPerPageShow?: number;
}

export const usePagination = ({ currentPage, itemsPerPageShow = 5 }: UsePaginationProps) => {
  const [selectedPage, setSelectedPage] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPageShow);

  return {
    selectedPage: selectedPage,
    rowsPerPage: rowsPerPage,
    onPageClick: selectedPage => setSelectedPage(selectedPage),
    onRowsPerPageClick: itemsPerPage => {
      setRowsPerPage(itemsPerPage);
      setSelectedPage(1);
    },
  };
};
