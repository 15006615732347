import { IUserSelection } from '@containers/Expenses/context/types/users';
import {
  EReportStatus,
  IReport,
  IReportCreationDate,
  IReportEndDate,
  IReportStartDate,
} from '@containers/Reports/context/types';

export enum EReportsMainStatus {
  APPROVED = 'APPROVED',
  SUBMITTED = 'SUBMITTED',
  REPROVED = 'REPROVED',
  OPEN = 'OPEN',
  REIMBURSED = 'REIMBURSED',
}

export enum EReportsStatusColorVariant {
  OPEN = 'secondary',
  REIMBURSED = 'success',
  REPROVED = 'error',
  SUBMITTED = 'neutral',
  APPROVED = 'info',
}

export interface IFinancialReportGrid {
  id: number;
  referenceId: string;
  name: string;
  status: EReportStatus;
  currencyPrefix: string;
  value: number;
  createdAt: string;
  violationNumber: number;
  commentsNumber: number;
  user: FinancialReportUser;
  message?: string;
  error?: boolean;
}

export interface FinancialReportUser {
  id?: number;
  name: string;
  cpf?: string;
  email?: string;
}

export interface ISummaryQuantityDetails {
  count: number;
  totalValue: number;
}

export interface ReportStatusSummary {
  open: ISummaryQuantityDetails;
  submitted: ISummaryQuantityDetails;
  approved: ISummaryQuantityDetails;
  reproved: ISummaryQuantityDetails;
  reimbursed?: ISummaryQuantityDetails;
}

export interface IGetReportStatusSummaryResponse {
  getSummaryForFinance: {
    reports: ReportStatusSummary;
  };
}

export interface IReportForFinanceFilter {
  status?: EReportStatus[];
  search?: string;
  reportCreationDate?: IReportCreationDate;
  reportStartDate?: IReportStartDate;
  reportEndDate?: IReportEndDate;
  createdBy?: IUserSelection['id'];
}

export interface IGetFinancialReportResponse {
  getReportsForFinance: {
    reports: IFinancialReportGrid[];
    currentPage: number;
    limit: number;
    pages: number;
    total: number;
  };
}

export enum ReportStatusActions {
  REIMBURSED = 'REIMBURSED',
  REPROVED = 'REPROVED',
}

export interface IGetReportsStatusUpdateRequest {
  reports: IReport['id'][];
  status: EReportStatus;
  message?: string;
  expirationDate?: Date;
}

export interface IGetReportsStatusUpdateResponse {
  updateReportListStatus: {
    reports: IFinancialReportGrid[];
    total: number;
  };
}

export interface IReportForFinanceFilter {
  status?: EReportStatus[];
  search?: string;
  reportCreationDate?: IReportCreationDate;
  reportStartDate?: IReportStartDate;
  reportEndDate?: IReportEndDate;
  createdBy?: IUserSelection['id'];
}

export interface IUserSelectionList {
  userSelections: IUserSelection[];
  total: number;
}
