import { DateRange, FilterType } from '@atoms/FilterFactory';
import { EPolicyLevel } from '@containers/Policies/context/types/types';
import { GridFilters } from '@molecules/GridFilters';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

interface IProps {
  filters: IFiltersPolicy;
  isLoading: boolean;
  onFiltersChange(key: TFilterType, data: string | EPolicyStatus | EPolicyLevel[] | DateRange): void;
}

type TFilterType = 'search' | 'status' | 'level' | 'lastUpdate';

export interface IFiltersPolicy {
  search?: string;
  status?: EPolicyStatus[];
  level?: EPolicyLevel[];
  lastUpdate?: DateRange;
}

export enum EPolicyStatus {
  ON = 'active',
  OFF = 'inactive',
}

export function PolicyGridFilters({ filters, isLoading, onFiltersChange }: IProps) {
  const t = useTranslate('policy.policyGrid');
  return (
    <GridFilters
      filters={[
        {
          label: t('filters.level.label'),
          onClick: data => onFiltersChange('level', data),
          type: FilterType.MULTIPLE,
          options: Object.values(EPolicyLevel).map(level => ({
            label: t(`filters.level.options.${level.toLocaleLowerCase()}`),
            value: level,
          })),
          selectedOptions: filters?.level ?? [],
        },
        {
          label: t('filters.status.label'),
          onClick: data => onFiltersChange('status', data || null),
          type: FilterType.LIST,
          options: Object.values(EPolicyStatus).map(level => ({
            label: t(`filters.status.options.${level.toLocaleLowerCase()}`),
            value: level,
          })),
          selectedOptions: filters?.status ?? [],
        },
        // {
        //   label: t('filters.lastUpdate.label'),
        //   onClick: data => onFiltersChange('lastUpdate', data || null),
        //   type: FilterType.PERIOD,
        // },
      ]}
      showSearchField
      onSearchChange={data => onFiltersChange('search', data)}
      labelSearchField={t('search')}
      searchValue={filters?.search}
      isLoading={isLoading}
      showMoreFiltersButton={false}
    />
  );
}
