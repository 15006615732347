import { Tooltip } from '@atoms/Tooltip';

import { IconsStyled } from './styled';

interface HelpProps {
  description: string;
  placement?:
    | 'right'
    | 'left'
    | 'top'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
}

export const Help = ({ description, placement = 'right' }: HelpProps) => {
  return (
    <Tooltip title={description} placement={placement}>
      <span>
        <IconsStyled name="IconInfoCircle" fill="transparent" size={12} />
      </span>
    </Tooltip>
  );
};
