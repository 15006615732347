import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 0 ${props => props.theme.spacings.xs} ${props => props.theme.spacings.xl5}
    ${props => props.theme.spacings.xs};
`;

export const BreadcrumbsContainer = styled.div`
  padding: ${props => props.theme.spacings.m} 0;
`;
