import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getApproversQuery } from '../context/queries/approvers';
import { ApproverListContextType, IApprover, IGetApprovers } from '../context/types/approvers';

interface useReportsApprovers {
  approvers: IApprover[];
  approversIsLoading: boolean;
  error: unknown;
  total: number;
}

export const useReportsApprovers = (
  skip: boolean = false,
  context = ApproverListContextType.NONE,
): useReportsApprovers => {
  const {
    loading: approversIsLoading,
    data,
    error,
  } = useQuery<IGetApprovers>(getApproversQuery, {
    variables: {
      context,
    },
    skip,
    fetchPolicy: context === ApproverListContextType.REPORT_FORM ? 'no-cache' : null,
  });

  return {
    approvers: data?.getApprovers?.approvers || [],
    error,
    approversIsLoading,
    total: data?.getApprovers.total,
  };
};
