import { useEffect } from 'react';
import { showToast } from '@shared/helpers/toast';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getProjects, getProjectsSummary } from '../context/queries/projects';
import { IProjectsSummaryResponse } from '../context/types/projects';
interface IProps {
  skip?: boolean;
  summaryData?: boolean;
}

export const useAllProjects = ({ skip = false, summaryData = false }: IProps) => {
  const { loading, data, error } = useQuery<IProjectsSummaryResponse>(summaryData ? getProjectsSummary : getProjects, {
    skip,
  });

  useEffect(() => {
    !loading && error && showToast({ message: error?.message, type: 'error' });
  }, [loading, data, error]);

  return {
    projects: data?.getProjects?.projects || [],
    totalProjects: data?.getProjects?.total || 0,
    isLoading: loading,
    error,
  };
};
