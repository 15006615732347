import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

export const OdometerFieldsSkeleton = () => {
  return (
    <SC.Container>
      <SC.SkeletonContainer>
        <Skeleton variant="rounded" height={58} width={'100%'} />
        <Skeleton variant="rounded" height={58} width={'100%'} />
      </SC.SkeletonContainer>
    </SC.Container>
  );
};
