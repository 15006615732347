import { PolicyOCRConfig } from '@containers/Policies/context/types/ocr';
import { useMutate } from '@shared/hooks/service/useMutate';

export function useSaveOCRPolicy(policyId: string) {
  const { mutation } = useMutate('v1/ocr/policy/' + policyId);

  async function handleUpdate(ocrPolicy: PolicyOCRConfig) {
    const response = await mutation.trigger({
      path: '',
      options: {
        method: 'PUT',
        body: JSON.stringify(ocrPolicy),
      },
    });

    return response;
  }

  return {
    handleUpdate,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
