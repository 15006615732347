import { SettingContextProvider } from '@containers/Settings/context';
import { BreadcrumbPage, IBreadcrumbItem } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { Trans } from 'react-i18next';

import { TFaq } from './PageHeader/hooks/useFaq';
import { PageHeader } from './PageHeader/PageHeader';
import * as SC from './styled';

type THeaderPage = {
  title?: React.ReactNode;
  caption?: string;
  actions?: React.ReactNode;
  faqType?: TFaq;
  breadcrumbItems?: IBreadcrumbItem[];
  showDelegateInfo?: boolean;
};

type TProps = {
  headerPage?: THeaderPage;
  children?: React.ReactNode;
  footerPage?: React.ReactNode;
};

export function BasePage({ headerPage, children, footerPage }: TProps) {
  const hasTitlePage: boolean = Boolean(headerPage.title || headerPage.caption);
  const hasActions: boolean = Boolean(headerPage.actions);

  return (
    <SettingContextProvider>
      <Trans i18nKey="translations">
        <SC.Container hasActionsFooter={Boolean(footerPage)}>
          <SC.Content>
            <SC.Header>
              {headerPage && (
                <>
                  {headerPage?.breadcrumbItems && <BreadcrumbPage items={headerPage.breadcrumbItems} />}
                  {(hasTitlePage || hasActions) && (
                    <PageHeader
                      title={headerPage.title ?? ''}
                      caption={headerPage.caption ?? ''}
                      action={headerPage.actions ?? null}
                      faq={headerPage.faqType ?? null}
                      showDelegateInfo={headerPage.showDelegateInfo ?? false}
                    />
                  )}
                </>
              )}
            </SC.Header>
            <SC.Body>{children}</SC.Body>
          </SC.Content>
          {footerPage && <SC.Footer>{footerPage}</SC.Footer>}
        </SC.Container>
      </Trans>
    </SettingContextProvider>
  );
}
