import styled from 'styled-components';

interface IContainerStatusProps {
  numberOfColumns?: number;
}

export const ContainerStatus = styled.div<IContainerStatusProps>`
  display: grid;
  gap: ${props => props.theme.spacings.xs};
  grid-template-columns: ${({ numberOfColumns = 4 }) => `repeat(${numberOfColumns}, 1fr)`};
`;
