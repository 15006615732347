import { useLazyQuery } from '@shared/hooks/service/apolloClient/useLazyQuery';

import { getDistance as getDIstanceQuery } from '../context/queries/km';
import { IGetDistanceRequest, IGetDistanceResponse } from '../context/types';

export const useDistance = () => {
  const [getDistance, { loading, data, error }] = useLazyQuery<IGetDistanceResponse, IGetDistanceRequest>(
    getDIstanceQuery,
  );

  return {
    getDistance,
    distance: data?.getDistance,
    isLoading: loading,
    error,
  };
};
