import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { useTranslation } from 'react-i18next';

import * as SC from './styled';

interface DeleteReportModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onActionClick(): void;
  onCloseClick(): void;
}
export const DeleteReportModal = ({ isOpen, isLoading, onActionClick, onCloseClick }: DeleteReportModalProps) => {
  const { t } = useTranslation();

  return (
    <DangerActionModal
      isLoading={isLoading}
      headerIconLabel={t('molecules.deleteReportModal.attention')}
      headerTitle={t(`molecules.deleteReportModal.popupTitle`)}
      headerSubtitle={t(`molecules.deleteReportModal.popupSubtitle`)}
      actionDisabled={false}
      labelButtonAction={
        <SC.LabelButtonContent>
          <div>{t(`molecules.deleteReportModal.confirmDeletion`)}</div>
          <Icons name="IconTrash" fill="transparent" size={24} />
        </SC.LabelButtonContent>
      }
      onActionClick={onActionClick}
      onCancelClick={onCloseClick}
      onCloseClick={onCloseClick}
      open={isOpen}></DangerActionModal>
  );
};
