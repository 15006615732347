import { useContext } from 'react';
import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary/ModuleErrorBoundary';
import { CompanyPayables } from '@flash-hros/company-payables';
import { Tab, TabItens } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { NewFeatureTag } from '@molecules/NewFeatureTag';
import { AccountingContext } from '@shared/contexts/AccountingContext/AccountingContext';
import { useAdvancesPermissions } from '@shared/hooks/permissions/systemPermissions/useAdvancesPermissions';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { FinancialAdvancesPreview } from '../Advances/FinancialAdvancesPreview/FinancialAdvancesPreview';
import { FinancialReportsPreview } from '../Reports/FinancialReportsPreview';
const { AccountingTab } = CompanyPayables;

export function FinancialPreview() {
  const { t } = useTranslation();
  const { advancesQuantity, reportsQuantity } = useContext(AccountingContext);
  const { hasAccounting: isAllowedViewAdvances } = useAdvancesPermissions();
  const { checkPlatformPermission } = usePlatformPermissions();
  const accountingPayablesEnabled = checkPlatformPermission(EAuthZPermission.ACCOUNTING_PAYABLES_VIEW);

  const tabItens: TabItens[] = [
    {
      component: <FinancialReportsPreview />,
      label: `${t('organisms.approvals.tabs.reports')} ${Boolean(reportsQuantity) ? '(' + reportsQuantity + ')' : ''} `,
    },
  ];

  if (accountingPayablesEnabled) {
    tabItens.push({
      component: (
        <ModuleErrorBoundary moduleName="Contas a Pagar">
          <AccountingTab />
        </ModuleErrorBoundary>
      ),
      label: <NewFeatureTag title={t('organisms.approvals.tabs.companyPayables')} />,
    });
  }

  if (isAllowedViewAdvances) {
    tabItens.push({
      component: <FinancialAdvancesPreview />,
      label: `${t('organisms.approvals.tabs.advances')} ${
        Boolean(advancesQuantity) ? '(' + advancesQuantity + ')' : ''
      } `,
    });
  }

  if (tabItens.length > 1) {
    return <Tab tabItens={tabItens} />;
  }

  return <FinancialReportsPreview />;
}
