import { ICustomField } from '@containers/Customizations/context/types/customFieldTypes';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function useSwitchCustomFieldState() {
  const t = useTranslate('organisms.customizationsCustomFields.modal.customField.feedbacks');

  const { mutation } = useMutate('v1/custom-fields', onError);

  function onError(error: Error) {
    return showToast({ message: error.message, type: 'error' });
  }

  async function handleSwitch(customField: ICustomField) {
    const response = await mutation.trigger({
      path: '/' + customField.id,
      options: {
        method: 'PUT',
        body: JSON.stringify({ ...customField, active: !customField.active }),
      },
    });

    showToast({
      message: t('active.success'),
      type: 'success',
    });

    return response;
  }

  return {
    handleSwitch,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
