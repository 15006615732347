import React from 'react';
import { DelegateUserView } from '@containers/Expenses/components/organisms/ExpenseActions/DelegateUserView/DelegateUserView';
import { Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2';

import { TFaq, useFaq } from './hooks/useFaq';
import * as SC from './styled';
interface Props {
  title: React.ReactNode;
  action?: React.ReactNode;
  faq?: TFaq;
  caption?: string;
  showDelegateInfo?: boolean;
}

export function PageHeader({ title, faq, action, caption, showDelegateInfo = false }: Props) {
  const { tooltipDescription, onFaqClick } = useFaq(faq);

  return (
    <SC.Container>
      <SC.TitleContainer>
        <SC.TitleDescription>
          <Typography variant="headline6">{title}</Typography>
          {faq && (
            <Tooltip arrow title={tooltipDescription} placement="top-start">
              <div>
                <SC.FaqButton name="IconHelp" size={20} fill="transparent" onClick={onFaqClick} />
              </div>
            </Tooltip>
          )}
        </SC.TitleDescription>

        <SC.ContainerAction>
          {showDelegateInfo && <DelegateUserView />} {action && action}
        </SC.ContainerAction>
      </SC.TitleContainer>
      {caption && <SC.Caption variant="body3">{caption}</SC.Caption>}
    </SC.Container>
  );
}
