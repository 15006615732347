import { useContext } from 'react';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { Outlet } from 'react-router-dom';

import { PlatformEjector } from './PlatformEjector';

export const RoutesAuthGuard = ({ children }: { children?: JSX.Element }) => {
  const { accessToken } = useContext(SessionContext);

  if (!accessToken) {
    return <PlatformEjector backToCurrentPage />;
  }

  return children ? children : <Outlet />;
};
