import { ReactNode, useState } from 'react';
import categories from '@assets/gifs/categorias.gif';
import expenses01 from '@assets/gifs/despesas-01.gif';
import expenses02 from '@assets/gifs/despesas-02.gif';
import expenses03 from '@assets/gifs/despesas-03.gif';
import limits from '@assets/gifs/limites.gif';
import { IconTypes, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Carousel } from '@molecules/Carousel';
import { ITips, useFirstAccess } from '@shared/hooks/useFirstAccess';

import { TextAlign } from '../../../themes/types/Variants';
import * as SC from './styled';

interface IModals {
  header?: ReactNode;
  labelButtonAction: string;
  labelButtonCancel: string;
  headerIcon?: IconTypes;
  headerIconLabel?: string;
  headerTitle?: string;
  headerSubtitle?: ReactNode | string;
  children?: ReactNode;
  headerUpperItem?: ReactNode;
  onCloseClick?: () => void;
  onActionClick?: () => void;
  onCancelClick?(): void;
  footer?: ReactNode;
  headerAlign: TextAlign;
  disableFooter?: boolean;
  maxWidth?: number;
}
interface TipsModalControllerProps extends ITips {}

export const TipsModalController = ({ page }: TipsModalControllerProps) => {
  const [showModal, setShowModal] = useState(true);
  const [step, setStep] = useState(0);
  const { isFirstAccess, setFirstAccessDone, isLoading } = useFirstAccess({ page });
  const { t } = useTranslation();

  const onCloseModal = () => {
    setFirstAccessDone();
    setShowModal(false);
  };

  const modals: { [K in TipsModalControllerProps['page']]: IModals[] } = {
    customization: [
      {
        labelButtonAction: t('organisms.customizationTipsModal.0.labelButtonAction'),
        labelButtonCancel: t('organisms.customizationTipsModal.0.labelButtonCancel'),
        headerIcon: 'IconDirections',
        headerIconLabel: t('organisms.customizationTipsModal.0.iconLabel'),
        headerTitle: t(`organisms.customizationTipsModal.0.title`),
        headerSubtitle: `${t('organisms.customizationTipsModal.0.subtitle')}`,
        children: false,
        onActionClick: () => setStep(1),
        footer: null,
        disableFooter: false,
        headerAlign: 'center',
        maxWidth: 645,
      },
      {
        headerTitle: t('organisms.customizationTipsModal.1.title'),
        headerSubtitle: `${t('organisms.customizationTipsModal.1.subtitle')}`,
        labelButtonAction: t('organisms.customizationTipsModal.1.labelButtonAction'),
        labelButtonCancel: t('organisms.customizationTipsModal.1.labelButtonCancel'),
        headerUpperItem: <SC.StepperComponent steps={['', '']} type={'dotted'} activeStep={0} />,
        children: true,
        footer: <></>,
        disableFooter: true,
        headerAlign: 'left',
        maxWidth: 871,
      },
      {
        headerTitle: t('organisms.customizationTipsModal.2.title'),
        headerSubtitle: `${t('organisms.customizationTipsModal.2.subtitle')}`,
        labelButtonAction: t('organisms.customizationTipsModal.2.labelButtonAction'),
        labelButtonCancel: t('organisms.customizationTipsModal.2.labelButtonCancel'),
        headerUpperItem: <SC.StepperComponent steps={['', '']} type={'dotted'} activeStep={1} />,
        children: true,
        onActionClick: () => setStep(3),
        footer: <></>,
        disableFooter: true,
        headerAlign: 'left',
        maxWidth: 871,
      },
      {
        headerTitle: t('organisms.customizationTipsModal.3.title'),
        headerSubtitle: (
          <SC.SubtitleContainer>
            <>
              <SC.Link
                href={
                  'mailto:test@example.com?subject=&body=Olá, time!%0D%0ATemos uma novidade para vocês: a partir de hoje, <data>, nossa empresa passa a ter acesso aos serviços de solicitação de reembolso e de prestação de contas de cartão corporativo na plataforma web da Flash, bem como no FlashAPP.%0D%0AAgora os serviços de benefícios e de gestão de despesas são acessados na mesma ferramenta e essa mudança foi pensada para melhorar o nosso dia a dia, a fim de facilitar e organizar processos, economizar tempo e evitar o desencontro de informações.%0D%0APara prestar contas no app da Flash, siga o passo a passo abaixo:%0D%0A1 - Acesse o app da Flash.%0D%0A  Android %0D%0A IOS %0D%0A2 - Faça login utilizando o seu usuário e senha.%0D%0A3 - Acesse a seção de despesas corporativas.%0D%0A4 - Lance novas despesas ou revise os gastos com cartão corporativo.%0D%0APara quem tem interesse em prestar contas pelo computador, basta utilizar a nova plataforma da Flash (clique aqui), logando com o mesmo usuário e senha do app.%0D%0ACaso tenha alguma dúvida, entre em contato com o time Flash: WhatsApp (11 4118-0488) | Telefone (11 3181-8885)%0D%0AAté mais,%0D%0A<Assinatura>'
                }>
                <Typography variant="body3">{`${t('organisms.customizationTipsModal.3.link')}`}</Typography>
              </SC.Link>{' '}
              {t('organisms.customizationTipsModal.3.subtitle')}
            </>
          </SC.SubtitleContainer>
        ),
        labelButtonAction: t('organisms.customizationTipsModal.3.labelButtonAction'),
        labelButtonCancel: t('organisms.customizationTipsModal.3.labelButtonCancel'),
        headerIcon: 'IconDirections',
        headerIconLabel: t('organisms.customizationTipsModal.3.iconLabel'),
        onCancelClick: () => setStep(2),
        onActionClick: () => {
          setShowModal(false);
          setFirstAccessDone();
        },
        children: false,
        headerAlign: 'center',
        disableFooter: false,
        maxWidth: 645,
      },
    ],
    addExpense: [
      {
        labelButtonAction: t('organisms.expensesTipsModal.0.labelButtonAction'),
        labelButtonCancel: t('organisms.expensesTipsModal.0.labelButtonCancel'),
        headerIcon: 'IconDirections',
        headerIconLabel: t('organisms.expensesTipsModal.0.iconLabel'),
        headerTitle: t(`organisms.expensesTipsModal.0.title`),
        headerSubtitle: `${t('organisms.expensesTipsModal.0.subtitle')}`,
        children: false,
        onActionClick: () => setStep(1),
        footer: null,
        disableFooter: false,
        headerAlign: 'center',
      },
      {
        headerTitle: t('organisms.expensesTipsModal.1.title'),
        headerSubtitle: `${t('organisms.expensesTipsModal.1.subtitle')}`,
        labelButtonAction: t('organisms.expensesTipsModal.1.labelButtonAction'),
        labelButtonCancel: t('organisms.expensesTipsModal.1.labelButtonCancel'),
        headerUpperItem: <SC.StepperComponent steps={['', '', '']} type={'dotted'} activeStep={0} />,
        children: true,
        footer: <></>,
        disableFooter: true,
        headerAlign: 'left',
        maxWidth: 871,
      },
      {
        headerTitle: t('organisms.expensesTipsModal.2.title'),
        headerSubtitle: `${t('organisms.expensesTipsModal.2.subtitle')}`,
        labelButtonAction: t('organisms.expensesTipsModal.2.labelButtonAction'),
        labelButtonCancel: t('organisms.expensesTipsModal.2.labelButtonCancel'),
        headerUpperItem: <SC.StepperComponent steps={['', '', '']} type={'dotted'} activeStep={1} />,
        children: true,
        footer: <></>,
        disableFooter: true,
        headerAlign: 'left',
        maxWidth: 871,
      },
      {
        headerTitle: t('organisms.expensesTipsModal.3.title'),
        headerSubtitle: `${t('organisms.expensesTipsModal.3.subtitle')}`,
        labelButtonAction: t('organisms.expensesTipsModal.3.labelButtonAction'),
        labelButtonCancel: t('organisms.expensesTipsModal.3.labelButtonCancel'),
        headerUpperItem: <SC.StepperComponent steps={['', '', '']} type={'dotted'} activeStep={2} />,
        children: true,
        footer: <></>,
        disableFooter: true,
        headerAlign: 'left',
        maxWidth: 871,
      },
      {
        headerTitle: t('organisms.expensesTipsModal.4.title'),
        headerSubtitle: (
          <SC.SubtitleContainer>
            <>
              {t('organisms.expensesTipsModal.4.subtitle')}
              <SC.Link href={'https://beneficios.flashapp.com.br/faq/flash-expense-nova-plataforma'}>
                <Typography variant="body3">{`${t('organisms.expensesTipsModal.4.link')}`}</Typography>
              </SC.Link>{' '}
            </>
          </SC.SubtitleContainer>
        ),
        labelButtonAction: t('organisms.expensesTipsModal.4.labelButtonAction'),
        labelButtonCancel: t('organisms.expensesTipsModal.4.labelButtonCancel'),
        headerIcon: 'IconCheck',
        headerIconLabel: t('organisms.expensesTipsModal.4.iconLabel'),
        onCancelClick: () => setStep(2),
        onActionClick: () => {
          setShowModal(false);
          setFirstAccessDone();
        },
        children: false,
        headerAlign: 'center',
        disableFooter: false,
      },
    ],
  };

  const carouselGif: { [K in TipsModalControllerProps['page']]: ReactNode[] } = {
    customization: [<SC.Gif key="categories" src={categories} />, <SC.Gif key="limits" src={limits} />, ''],
    addExpense: [
      <SC.Gif key="expenses" src={expenses01} />,
      <SC.Gif key="expenses-2" src={expenses02} />,
      <SC.Gif key="expense-3" src={expenses03} />,
      '',
    ],
  };

  if (!isFirstAccess || isLoading) {
    return null;
  }

  return (
    <SC.Modal
      open={showModal && isFirstAccess}
      labelButtonAction={modals[page][step]?.labelButtonAction}
      labelButtonCancel={modals[page][step]?.labelButtonCancel}
      headerIcon={modals[page][step]?.headerIcon}
      headerIconLabel={modals[page][step]?.headerIconLabel}
      headerTitle={modals[page][step]?.headerTitle}
      headerSubtitle={modals[page][step]?.headerSubtitle}
      onCancelClick={modals[page][step]?.onCancelClick}
      onCloseClick={modals[page][step]?.onCloseClick ?? onCloseModal}
      onActionClick={modals[page][step]?.onActionClick}
      headerCustom={modals[page][step]?.header}
      footerCustom={modals[page][step]?.footer}
      disableFooter={modals[page][step]?.disableFooter}
      headerUpperItem={modals[page][step]?.headerUpperItem}
      headerTextAlign={modals[page][step]?.headerAlign}
      containerClassName={modals[page][step]?.maxWidth ? 'large-modal' : null}>
      {modals[page][step]?.children && (
        <SC.CarouselContainer>
          <Carousel
            getCurrentIndex={value => setStep(prevStep => prevStep + value)}
            showDots={false}
            customArrows={{ left: 'IconChevronLeft', right: 'IconChevronRight' }}
            items={carouselGif[page]}
          />
        </SC.CarouselContainer>
      )}
    </SC.Modal>
  );
};
