import { Skeleton } from '@mui/material';

import * as SC from '../styled';
export const ReportInfosMainSkeleton = () => {
  return (
    <SC.MainInformationContainer>
      <SC.ReferenceIdContainer>
        <Skeleton variant="rounded" width={70} height={28} style={{ marginRight: '16px' }} />
        <Skeleton variant="rounded" width={95} height={22} />
      </SC.ReferenceIdContainer>
      <Skeleton variant="rounded" width={500} height={38} style={{ margin: '16px 0 32px 0' }} />
    </SC.MainInformationContainer>
  );
};
