import React, { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import _range from 'lodash/range';

import { EAccessor } from './UseHeaderGridColumns';

export interface UseBodyGridRowSkeletonProps {
  rowsNumber?: number;
}

export type TGridRow = Record<EAccessor, React.ReactNode>;

export const useBodyGridRowSkeleton = ({ rowsNumber = 5 }: UseBodyGridRowSkeletonProps): TGridRow[] => {
  const rows: TGridRow[] = useMemo(() => {
    return _range(rowsNumber).map(() => ({
      date: <Skeleton variant="rounded" height={22} width={144} />,
      user: <Skeleton variant="rounded" height={22} width={144} />,
      actions: <Skeleton variant="rounded" height={22} width={400} />,
    }));
  }, [rowsNumber]);

  return rows;
};
