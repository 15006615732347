import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';

import { EWaypointDivider } from '../WaypointDivider/WaypointDivider';

interface IUseWaypointIdentifierHelper {
  identifierIcon: IconTypes;
  isFirstItem: boolean;
  getDividerType(hasValue: boolean): EWaypointDivider;
}

export function useWaypointIdentifierHelper(index: number): IUseWaypointIdentifierHelper {
  const alphabet: string = 'abcdefghijklmnopqrstuvwxyz';
  const stopListId: string[] = alphabet.split('');
  const isFirstItem: boolean = index === 0;

  function getIconName() {
    const icon = `IconLetter${stopListId[index]?.toUpperCase()}`;
    return icon as IconTypes;
  }

  function getDividerType(hasValue: boolean): EWaypointDivider {
    return hasValue ? EWaypointDivider.SOLID : EWaypointDivider.DOTTED;
  }

  return {
    isFirstItem,
    identifierIcon: getIconName(),
    getDividerType,
  };
}
