import { useEffect, useState } from 'react';
import { Loader } from '@atoms/Loader';
import { ApprovalByLevel } from '@containers/ApprovalFlows/CustomApprovalFlow/components/organisms/ApprovalByLevel/ApprovalByLevel';
import { ApprovalCriteria } from '@containers/ApprovalFlows/CustomApprovalFlow/components/organisms/ApprovalCriteria/ApprovalCriteria';
import { useOptionsListCriteria } from '@containers/ApprovalFlows/CustomApprovalFlow/components/organisms/ApprovalCriteria/ApprovalCriteriaConditionalRow/hooks/useOptionsListCriteria';
import { BasicInformation } from '@containers/ApprovalFlows/CustomApprovalFlow/components/organisms/BasicInformation/BasicInformation';
import { useCustomApprovalFlowById } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useCustomApprovalFlowById';
import { useSaveCustomApprovalFlow } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useSaveCustomApprovalFlow';
import { ECustomApprovalType } from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import { ApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { cloneDeep } from 'lodash';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { CustomApprovalFlowRegisterBasePage } from './CustomApprovalFlowRegisterBasePage/CustomApprovalFlowRegisterBasePage';
import { CustomApprovalFlowRegisterFooterPage } from './CustomApprovalFlowRegisterFooterPage/CustomApprovalFlowRegisterFooterPage';
import { CustomApprovalFlowRegisterProgressHeader } from './CustomApprovalFlowRegisterProgressHeader';
import { useCustomApprovalFlowInitialValues } from './hooks/useCustomApprovalFlowInitialValues';
import * as SC from './styled';

export enum EFlowStep {
  BASIC_INFORMATION = `basicInformation`,
  APPROVAL_TYPE = 'approvalType',
}

export function CustomApprovalFlowRegister() {
  const t = useTranslate('approvalFlows.pageRegister');
  const { id } = useParams<{ id: string }>();
  const { approvalFlow, isLoading: byIdIsLoading } = useCustomApprovalFlowById(id);
  const [step, setStep] = useState<EFlowStep>(EFlowStep.BASIC_INFORMATION);
  const defaultValues = useCustomApprovalFlowInitialValues(approvalFlow);
  const approvalFlowForm = useForm<ApprovalFlowForm>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues,
  });
  const { handleSave, isLoading: saveIsLoading } = useSaveCustomApprovalFlow(Boolean(id));
  const criteriaOptions = useOptionsListCriteria();

  function onSubmit(data?: ApprovalFlowForm) {
    const approvalFlow = cloneDeep(data);

    if (step === EFlowStep.BASIC_INFORMATION) {
      setStep(EFlowStep.APPROVAL_TYPE);
    } else {
      approvalFlow?.sources?.map(source => {
        delete source.configType;
        return source;
      });

      approvalFlow?.criteria?.map(criterion => {
        delete criterion._id;
        return criterion;
      });

      switch (approvalFlow?.type) {
        case ECustomApprovalType.MANUAL:
          delete approvalFlow.customMessage;
          break;
        case ECustomApprovalType.AUTOMATIC_APPROVAL:
          delete approvalFlow.sources;
          delete approvalFlow.customMessage;
          break;
        case ECustomApprovalType.AUTOMATIC_REFUSAL:
          delete approvalFlow.sources;
          break;
      }

      handleSave(approvalFlow, Number(id));
    }
  }

  function onError(data: FieldErrors) {
    if (!data.criteria && !data.description && !data.name && step === EFlowStep.BASIC_INFORMATION) {
      setStep(EFlowStep.APPROVAL_TYPE);
      approvalFlowForm.clearErrors();
    }
  }

  function onBackClick() {
    setStep(EFlowStep.BASIC_INFORMATION);
  }

  useEffect(() => {
    if (approvalFlow && !byIdIsLoading) {
      return approvalFlowForm.reset({
        ...defaultValues,
      });
    }
  }, [approvalFlow, byIdIsLoading, criteriaOptions.isLoading]);

  return (
    <>
      <FormProvider {...approvalFlowForm}>
        <form onSubmit={approvalFlowForm.handleSubmit(onSubmit, onError)}>
          <SC.Container>
            <BasePage
              headerPage={{
                title: t(`title.${id ? 'edit' : 'create'}`),
              }}
              footerPage={<CustomApprovalFlowRegisterFooterPage step={step} onBackClick={onBackClick} />}>
              <CustomApprovalFlowRegisterProgressHeader
                step={step}
                isEdit={Boolean(id)}></CustomApprovalFlowRegisterProgressHeader>
              <CustomApprovalFlowRegisterBasePage approvalFlowId={id} step={step}>
                <SC.Content>
                  {step === EFlowStep.BASIC_INFORMATION ? (
                    <>
                      <BasicInformation isLoading={byIdIsLoading || criteriaOptions.isLoading} />
                      <ApprovalCriteria
                        options={criteriaOptions}
                        isLoading={byIdIsLoading || criteriaOptions.isLoading}
                      />
                    </>
                  ) : (
                    <ApprovalByLevel />
                  )}
                </SC.Content>
              </CustomApprovalFlowRegisterBasePage>
            </BasePage>
          </SC.Container>
        </form>
      </FormProvider>
      <Loader show={saveIsLoading} description={!id ? t('loader.create') : t('loader.edit')} />
    </>
  );
}
