import { IConfigurationPaymentMethod } from '@containers/Customizations/context/types/paymentMethodTypes';

export const updateCache = (updatedItem, cachedData) => {
  const paymentMethods: IConfigurationPaymentMethod[] = [];
  cachedData?.paymentMethods?.forEach(item => {
    let paymentMethod = item;
    if (paymentMethod.id == updatedItem.id) {
      paymentMethod = updatedItem;
    }

    paymentMethods.push(paymentMethod);
  });

  if (!paymentMethods.some(item => item.id == updatedItem.id)) {
    paymentMethods.push(updatedItem);
  }

  return {
    paymentMethods: paymentMethods,
  };
};

export const removeCacheItem = (itemId, cachedData) => {
  const paymentMethods: IConfigurationPaymentMethod[] = [];
  cachedData?.paymentMethods?.forEach(item => {
    const paymentMethod = item;
    if (paymentMethod.id != itemId) {
      paymentMethods.push(paymentMethod);
    }
  });

  return {
    paymentMethods: paymentMethods,
  };
};
