import { TypographyProps } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import { SizeVariant } from 'src/themes/types/Variants';

import { ActionIcon, Container, DescriptionActions } from './styled';

export interface ActionsFeedbackProps {
  /**
   * ActionsFeedback attached files
   */
  showAttachments?: boolean;

  /**
   * ActionsFeedback messages
   */
  showMessages?: boolean;

  /**
   * ActionsFeedback  Number of actions
   */
  numberOfActions?: number;
  /**
   * ActionsFeedback  Sizes variant
   * @default medium
   */
  size?: SizeVariant;

  /**
   * if `true`, the order of items will be inverted
   */
  invert?: boolean;
}

export const ActionsFeedback = ({
  showAttachments,
  showMessages,
  numberOfActions,
  size = 'medium',
  invert,
}: ActionsFeedbackProps) => {
  const { t } = useTranslation();

  const typographyVariant: Record<SizeVariant, TypographyProps['variant']> = {
    large: 'body2',
    medium: 'body3',
    small: 'body4',
    xsmall: 'body1',
  };

  return (
    <Container invert={invert}>
      {numberOfActions > 0 && (
        <DescriptionActions variant={typographyVariant[size]} invert={invert}>
          {numberOfActions} {t(`molecules.actionsFeedback.${numberOfActions > 1 ? 'occurrences' : 'occurrence'}`)}
        </DescriptionActions>
      )}

      {showMessages && <ActionIcon name="IconMessageCircle2"></ActionIcon>}

      {showAttachments && <ActionIcon fill="#ffffff" name="IconPaperclip"></ActionIcon>}
    </Container>
  );
};
