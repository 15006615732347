import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const PageContent = styled.div``;

export const PageContainer = styled.div`
  padding: 0px ${props => props.theme.spacings.xs} ${props => props.theme.spacings.l};
  position: relative;
`;

export const BreadcrumbTypography = styled(Typography).attrs({
  variant: 'body4',
})`
  font-weight: 700 !important;
`;

export const PageTitleContainer = styled.div``;

export const PageTitle = styled(Typography).attrs({
  variant: 'headline5',
})`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;

export const PageSubtitle = styled(Typography).attrs({
  variant: 'body3',
})`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-top: ${({ theme }) => theme.spacings.xs4};
  margin-bottom: ${({ theme }) => theme.spacings.s};
`;

export const ContainerAcceptSubscription = styled.div`
  margin: ${({ theme }) => theme.spacings.m} 0;
`;

export const ImageContainer = styled.div`
  height: 100vh;
  padding: 0 ${({ theme }) => theme.spacings.xl3};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.div<{ src: string }>`
  position: fixed;
  width: 25%;
  height: 50%;
  max-height: 800px;
  top: 20%;
  border-radius: ${props => props.theme.borders.radius.xl};
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
`;

export const BreadcrumbsContainer = styled.div`
  padding: ${props => props.theme.spacings.m} 0 0 0;
`;

export const FooterSpacing = styled.div`
  padding-bottom: ${props => props.theme.spacings.xl};
`;
