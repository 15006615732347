import { useEffect, useState } from 'react';
import { IReport } from '@containers/Reports/context/types';
import { ApproverListContextType } from '@containers/Reports/context/types/approvers';
import { useReportsApprovers } from '@containers/Reports/hooks/useReportApprovers';
import { SelectField, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { serializeSelectOptions } from '@shared/helpers/selectOptions';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useTranslation } from 'react-i18next';

import * as SC from './styled';

type EReportActionType = 'create' | 'update';

export interface ModalReportProps {
  open: boolean;
  isLoading: boolean;
  actionType: EReportActionType;
  description?: string;
  name?: string;
  approverId?: number;
  onCancelClick(): void;
  onActionClick(report: Pick<IReport, 'name' | 'description'>): void;
}

export const NewReportModal = ({
  open = true,
  onCancelClick,
  onActionClick,
  isLoading,
  actionType,
  name,
  description,
  approverId,
}: ModalReportProps) => {
  const { t } = useTranslation();
  const { checkLicenseFeature } = useLicenseFeatures();
  const approverEnabled = checkLicenseFeature('INTERMEDIATE_APPROVALS');
  const [reportName, setReportName] = useState(name ?? '');
  const [reportApproverId, setReportApproverId] = useState<number>(approverId ?? null);
  const [reportDescription, setReportDescription] = useState(description ?? '');
  const { approvers, approversIsLoading } = useReportsApprovers(
    !approverEnabled || !open,
    ApproverListContextType.REPORT_FORM,
  );
  const approversOptions = serializeSelectOptions({ options: approvers });

  const handleActionClick = () => {
    const report: {
      name: string;
      description: string;
      approverId?: number;
    } = {
      name: reportName,
      description: reportDescription,
    };
    if (approverEnabled) {
      report.approverId = reportApproverId;
    }
    onActionClick(report);
  };

  useEffect(() => {
    setReportName(name);
    setReportDescription(description);
  }, [name, description]);

  return (
    <DefaultModal
      open={open}
      labelButtonAction={t(`molecules.newReportModal.${actionType}`)}
      headerTitle={t(`molecules.newReportModal.${actionType}Title`)}
      onActionClick={handleActionClick}
      isActionLoading={isLoading}
      actionDisabled={approversIsLoading}
      onCancelClick={onCancelClick}>
      <SC.Container>
        {approversIsLoading ? (
          <Skeleton width={'100%'} height={60} variant="rounded" />
        ) : (
          <SC.ReportField
            label={t('molecules.newReportModal.reportName')}
            value={reportName}
            onChange={e => setReportName(e.target.value)}
          />
        )}

        {approverEnabled && (
          <>
            {!approversIsLoading ? (
              approversOptions?.length > 0 && (
                <SelectField
                  label={t('molecules.newReportModal.reportApprover')}
                  value={reportApproverId}
                  onSelectChange={(_, data) => setReportApproverId(data.value)}
                  options={approversOptions}
                  searchable
                />
              )
            ) : (
              <Skeleton width={'100%'} height={60} variant="rounded" />
            )}
          </>
        )}
        {approversIsLoading ? (
          <Skeleton width={'100%'} height={125} variant="rounded" />
        ) : (
          <SC.ReportField
            multiline
            label={t('molecules.newReportModal.description')}
            value={reportDescription}
            onChange={e => setReportDescription(e.target.value)}
            rows={4}
          />
        )}
      </SC.Container>
    </DefaultModal>
  );
};
