import React, { useMemo } from 'react';
import { useAreas } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useAreas';
import { useJobPositions } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useJobPositions';
import { useSubsidiaries } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useSubsidiaries';
import { useAllProjects } from '@containers/Projects/hooks/rest/useAllProjects';
import { useUsersList } from '@containers/Projects/hooks/useUsersList';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { EPolicyLevel } from '../context/types/types';

export interface IOptionsSource {
  value: string | number;
  caption?: string;
  label: React.ReactNode;
  disabled?: boolean;
}

export enum EOperatorOptions {
  EQUALS = 'equals',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
  NOT_EQUALS = 'notEqual',
  LESSER_THAN = 'lesserThan',
  LESSER_THAN_OR_EQUAL = 'lesserThanOrEqual',
}

export enum EOperatorPartialOptions {
  EQUALS = 'equals',
  NOT_EQUALS = 'notEqual',
}

export function useOptionsConditions() {
  const t = useTranslate('policy.register.conditions');
  const { checkLicenseFeature } = useLicenseFeatures();
  const isControlPlan = useMemo(() => checkLicenseFeature('ADVANCED_EXPENSE'), []);
  const { areas, isLoading: areasIsLoading } = useAreas(isControlPlan);
  const { subsidiaries, isLoading: subsidiariesIsLoading } = useSubsidiaries(isControlPlan);
  const { projects, isLoading: projectsIsLoading } = useAllProjects({ shouldFetch: isControlPlan });
  const { users, isLoading: usersIsLoading } = useUsersList(!isControlPlan);
  const { jobPosition, isLoading: jobPositionsIsLoading } = useJobPositions(isControlPlan);

  const roleOptions: IOptionsSource[] = useMemo(
    () =>
      jobPosition?.map(position => ({
        value: position.id,
        label: position.name,
      })),
    [JSON.stringify(jobPosition)],
  );

  const userOptions: IOptionsSource[] = useMemo(
    () =>
      users?.map(user => ({
        value: String(user.id),
        label: user.name,
      })),
    [JSON.stringify(users)],
  );

  const projectOptions: IOptionsSource[] = useMemo(
    () =>
      projects?.map(project => ({
        value: project.id,
        label: project.name,
      })),
    [JSON.stringify(projects)],
  );

  const subsidiaryOptions: IOptionsSource[] = useMemo(
    () =>
      subsidiaries?.map(subsidiary => ({
        value: subsidiary.id,
        label: subsidiary.name,
      })),
    [JSON.stringify(subsidiaries)],
  );

  const areaOptions: IOptionsSource[] = useMemo(
    () =>
      areas?.map(area => ({
        value: area.id,
        label: area.name,
      })),
    [JSON.stringify(areas)],
  );

  const conditionOptions = useMemo(
    () =>
      Object.keys(EPolicyLevel).map(key => ({
        value: key,
        label: t(`conditionOptions.${EPolicyLevel[key].toLocaleLowerCase()}`),
        disabled: false,
      })),
    [],
  );

  return {
    options: {
      areaOptions,
      subsidiaryOptions,
      projectOptions,
      conditionOptions,
      userOptions,
      roleOptions,
    },
    isLoading: areasIsLoading || subsidiariesIsLoading || projectsIsLoading || usersIsLoading || jobPositionsIsLoading,
  };
}
