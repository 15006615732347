import React from 'react';
import { Skeleton } from '@mui/material';

import { EAccessor } from './useHeaderGridColumns';

export interface UseBodyGridRowSkeletonProps {
  rowsPerPage: number;
}
export type TGridRow = Record<EAccessor, React.ReactNode>;

export function useBodyGridRowSkeleton({ rowsPerPage = 3 }: UseBodyGridRowSkeletonProps): TGridRow[] {
  const skeletonText = <Skeleton variant="text" height={22} width={90} />;

  return new Array(rowsPerPage).fill({}).map(() => {
    return {
      selection: <Skeleton variant="rounded" height={20} width={20} />,
      category: skeletonText,
      criterion: skeletonText,
      limit: skeletonText,
      status: skeletonText,
      limitBlocksSending: skeletonText,
      proofRequired: skeletonText,
      commentRequired: skeletonText,
      actions: <Skeleton variant="circular" height={40} width={40} />,
    };
  });
}
