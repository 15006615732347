import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export const useApprovers = (skip: boolean = false) => {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const { data, error, isLoading } = useFetch<{ id: string; name: string; email: string }[]>(
    skip ? null : `v1/approvers/get-for-selection`,
    '',
    undefined,
    undefined,
    onError,
  );

  return {
    error,
    isLoading: isLoading,
    approvers: data ?? [],
  };
};
