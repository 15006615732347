import { useState } from 'react';
import { ISubsidiary } from '@containers/Subsidiaries/context/types/subsidiaries';
import { useDeleteBatchSubsidiaries } from '@containers/Subsidiaries/hooks/rest/useDeleteBatchSubsidiaries';
import { useUpdateSubsidiaryStatus } from '@containers/Subsidiaries/hooks/rest/useUpdateSubsidiaryStatus';
import { IActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { IBatchActionsActions } from '@organisms/Grid/BatchActions/BatchActions';
import { useSelectionRows } from '@organisms/Grid/BatchActions/hooks/useSelectionRows';
import { subsidiariesBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { TSubsidiariesConfirmationActions } from '../SubsidiariesConfirmationActionsModal/SubsidiariesConfirmationActionsModal';

enum EActions {
  EDIT = 'edit',
  ACTIVE = 'active',
  DELETE = 'delete',
}

enum EIcons {
  EDIT = 'IconPencil',
  ACTIVE = 'IconToggleLeft',
  DELETE = 'IconTrash',
}

export function useSubsidiariesActionsGrid(subsidiaries: ISubsidiary[]) {
  const t = useTranslate('subsidiaries.grid');
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [confirmationType, setConfirmationType] = useState<TSubsidiariesConfirmationActions>();
  const [isBatchAction, setIsBatchAction] = useState<boolean>(false);
  const [subsidiariesSelected, setSelected] = useState<ISubsidiary>();
  const [subsidiariesSelectedIds, setSubsidiariesSelectedIds] = useState<ISubsidiary['id'][]>();
  const { isSelectedAll, onSelectItemClick, selectedItems, toggleSelectAll, unSelectAllClick } =
    useSelectionRows<ISubsidiary>(subsidiaries);

  const { deleteSubsidiaries, isLoading: isLoadingDelete } = useDeleteBatchSubsidiaries(subsidiaries);
  const { updateStatus } = useUpdateSubsidiaryStatus(subsidiaries);
  function onShowConfirmationActionsModal(): void {
    setShowConfirmationModal(_open => !_open);
  }

  async function onConfirmationActionModal() {
    if (confirmationType === 'deactivation') {
      await updateStatus(subsidiariesSelectedIds, false);
      unSelectAllClick();
    } else {
      await deleteSubsidiaries(subsidiariesSelectedIds);
      unSelectAllClick();
    }
    onShowConfirmationActionsModal();
  }

  function getSelectedSubsidiaries(_id: ISubsidiary['id']): ISubsidiary {
    return subsidiaries?.find(subsidiary => subsidiary?.id === _id);
  }

  function onActionClick(_actionKey: EActions, _id?: ISubsidiary['id']) {
    const _subsidiariesSelectedIds: ISubsidiary['id'][] = _id ? [_id] : selectedItems;
    const _isBatchAction: boolean = _subsidiariesSelectedIds?.length > 1;
    const _subsidiaries: ISubsidiary = !_isBatchAction ? getSelectedSubsidiaries(_subsidiariesSelectedIds[0]) : null;

    setSubsidiariesSelectedIds(_subsidiariesSelectedIds);
    _subsidiaries && setSelected(_subsidiaries);
    setIsBatchAction(_subsidiariesSelectedIds?.length > 1);

    switch (_actionKey) {
      case EActions.ACTIVE:
        setConfirmationType('deactivation');
        if ((subsidiariesSelectedIds?.length === 1 && _subsidiaries?.active) || subsidiariesSelectedIds?.length > 1) {
          onShowConfirmationActionsModal();
        } else {
          updateStatus(_subsidiariesSelectedIds, _id ? !_subsidiaries.active : false);
        }
        break;

      case EActions.DELETE:
        setConfirmationType('exclusion');
        onShowConfirmationActionsModal();
        break;

      default:
        setConfirmationType(null);
        navigate(`${subsidiariesBaseURL}/register/${_id}`);
        break;
    }
  }

  function getActionsRow(_id: ISubsidiary['id']): IActionsRow[] {
    return (
      subsidiaries?.length &&
      Object.keys(EActions).map(key => ({
        id: _id,
        key: EActions[key],
        allowed: true,
        iconName: EIcons[key],
        name: t(
          `body.actions.${
            EActions[key] === EActions.ACTIVE && getSelectedSubsidiaries(_id)?.active ? 'deactivate' : EActions[key]
          }`,
        ),
        onClick: onActionClick,
      }))
    );
  }

  function getTooltipBatchActions(key: EActions): string {
    if (key === EActions.ACTIVE) {
      const subsidiariesActiveList = selectedItems?.filter(selectedId =>
        subsidiaries?.some(subsidiary => subsidiary.id === selectedId && !subsidiary.active),
      );
      return subsidiariesActiveList?.length > 0 ? t('header.batchActions.tooltips.active') : '';
    }
    return '';
  }

  function getBatchActions(): IBatchActionsActions[] {
    const batchActions: IBatchActionsActions[] = [];

    subsidiaries?.length &&
      Object.keys(EActions).forEach(key => {
        batchActions.push({
          key: EActions[key],
          allowed: EActions[key] !== EActions.EDIT,
          disabled: Boolean(getTooltipBatchActions(EActions[key])) && EActions[key] !== EActions.DELETE,
          iconName: EIcons[key],
          name: t(`header.batchActions.${EActions[key] === EActions.ACTIVE ? 'deactivate' : EActions[key]}`),
          tooltipMessage: getTooltipBatchActions(EActions[key]),
          onClick: _key => onActionClick(_key as EActions),
        });
      });

    return batchActions;
  }

  return {
    subsidiariesSelected,
    showConfirmationModal,
    confirmationType,
    isSelectedAll,
    selectedItems,
    isBatchAction,
    isLoadingAction: isLoadingDelete,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    onSelectItemClick,
    unSelectAllClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
  };
}
