import { useEffect, useState } from 'react';
import MandatoryInfo from '@atoms/MandatoryInfo/MandatoryInfo';
import {
  ELicenseFeaturesExpenseSettings,
  IFormExpenseSettings,
} from '@containers/Customizations/context/types/settings/expenseSettingsTypes';
import { useFormExpenseSettings } from '@containers/Customizations/hooks/settings/expense/useExpenseSettings';
import { useUpdateExpenseSettings } from '@containers/Customizations/hooks/settings/expense/useExpenseSettingsMutate';
import { Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { FieldsSettingsSkeleton } from './FieldsSettingsSkeleton';
import * as SC from './styled';

export enum EFieldsSettings {
  DATE = 'date',
  CURRENCY = 'currency',
  VALUE = 'value',
  CATEGORY = 'category',
  ADDRESS = 'address',
  RECEIPT_FILE = 'receiptFile',
  DESCRIPTION = 'description',
  INVOICE = 'invoice',
  QUANTITY = 'quantity',
  CLIENT = 'client',
  PROJECT = 'project',
  COST_CENTER = 'costCenter',
  PAYMENT_TYPE = 'paymentType',
  INVOICE_KEY = 'invoiceKey',
}

export const FieldsSettings = () => {
  const translation = useTranslation();
  const t = (key: string, field?: string): string => translation.t(`molecules.fieldCustomization.${key}`, { field });
  const { checkPlatformPermission } = usePlatformPermissions();
  const allowedActions: boolean = checkPlatformPermission(EAuthZPermission.CUSTOMIZATION_EXPENSE_ACTION);
  const { formSettings, isLoading } = useFormExpenseSettings();
  const { updateExpenseSetting } = useUpdateExpenseSettings();
  const [currentSettings, setCurrentSettings] = useState<IFormExpenseSettings>();

  async function onChangeValue(value: boolean, settingKey: EFieldsSettings, type: 'required' | 'field') {
    const field = {
      ...currentSettings[settingKey],
      active: type === 'field' ? value : (value && value) || currentSettings[settingKey].active,
      required:
        type === 'required'
          ? value
          : type === 'field' && !value
          ? value
          : (!value && value) || currentSettings[settingKey].required,
      allowsEditing:
        type === 'field' && settingKey === EFieldsSettings.INVOICE_KEY
          ? value
          : currentSettings[settingKey].allowsEditing,
    };
    const fields: IFormExpenseSettings = {
      ...currentSettings,
      [settingKey]: {
        ...field,
      },
    };
    try {
      setCurrentSettings(fields);
      await updateExpenseSetting(settingKey, field);
    } catch (error) {
      setCurrentSettings(currentSettings);
    }
  }

  function isMandatory(field: EFieldsSettings): boolean {
    return (
      field == EFieldsSettings.VALUE ||
      field == EFieldsSettings.CURRENCY ||
      field == EFieldsSettings.DATE ||
      field == EFieldsSettings.CATEGORY
    );
  }

  function getToggle(
    field: EFieldsSettings,
    checked: boolean = false,
    disabled: boolean = false,
    type: 'required' | 'field',
  ) {
    return (
      <SC.ToggleContainer>
        <Toggle disabled={disabled} checked={checked} onChange={(_, value) => onChangeValue(value, field, type)} />
      </SC.ToggleContainer>
    );
  }

  useEffect(() => {
    formSettings && setCurrentSettings(formSettings);
  }, [formSettings]);

  return (
    <SectionContainer title={t('title')} caption={t('description')}>
      {isLoading && !currentSettings ? (
        <FieldsSettingsSkeleton />
      ) : (
        <SC.Container>
          <SC.FieldsHeader>
            <SC.HeaderDescription variant="body3">{t('enabledField')}</SC.HeaderDescription>
            <SC.HeaderDescription variant="body3">{t('mandatoryField')}</SC.HeaderDescription>
          </SC.FieldsHeader>

          {Object.values(EFieldsSettings).map((field, key) => (
            <PermissionsChecker key={key} licenseFeature={ELicenseFeaturesExpenseSettings[field]}>
              <SC.FieldContent>
                <SC.MandatoryInfoContainer>
                  <SC.ToggleLabel variant="body3">{t(`fields.${field}`)}</SC.ToggleLabel>
                  {isMandatory(field) && (
                    <SC.InfoMandatoryDescriptionAlert variant="body4">*</SC.InfoMandatoryDescriptionAlert>
                  )}
                </SC.MandatoryInfoContainer>
                {getToggle(field, currentSettings?.[field]?.active, !allowedActions || isMandatory(field), 'field')}
                {getToggle(
                  field,
                  currentSettings?.[field]?.required,
                  !allowedActions || !currentSettings?.[field]?.active,
                  'required',
                )}
              </SC.FieldContent>
            </PermissionsChecker>
          ))}
          <MandatoryInfo />
        </SC.Container>
      )}
    </SectionContainer>
  );
};
