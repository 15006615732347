import { EActions, IActions } from '../actions';
import { initialState } from '../store';
import { IContext } from '../types';
import { reducerFunction } from './functions';

const reducer = (state: IContext, action: IActions) => {
  switch (action.type) {
    case EActions.REDUCER_FUNCTION:
      return {
        ...state,
        currentState: reducerFunction(state.currentState),
      };

    default:
      throw new Error('Error in context reducer');
  }
};

export { reducer, initialState };
