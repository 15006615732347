import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Line = styled.span`
  display: block;
  border-top: ${props => props.theme.borders.width.xs2} solid ${props => props.theme.colors.neutral[90]};
  width: 100%;
`;

export const VerticalLine = styled.span`
  display: block;
  border-left: ${props => props.theme.borders.width.xs2} ${props => props.theme.colors.neutral[90]};
  height: 100%;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 0.5fr auto 0.5fr;
  width: 100%;
  gap: ${props => props.theme.spacings.xs};
`;

export const MidContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
`;

export const Button = styled(IconButton)``;
