import { Dispatch, SetStateAction } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { IConfigurationProject } from '@containers/Projects/context/types/projectTypes';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { Search } from '@molecules/GridFilters/Search/Search';
import { ActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { useDateFormatter } from '@shared/hooks';
import { useEmployeesSelectionList } from '@shared/hooks/employees/useEmployeesSelectionList';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { useProjectsActionsGrid } from './hooks/useProjectsActionsGrid';
import { ProjectsConfirmationActionsModal } from './ProjectsConfirmationActionsModal/ProjectsConfirmationActionsModal';
import * as SC from './styled';

enum EAccessor {
  SELECT_ALL = 'selectAll',
  PROJECTS = 'projects',
  CLIENTS = 'clients',
  ACCOUNTABLE = 'accountable',
  PERIOD = 'period',
  STATUS = 'status',
  ACTIONS = 'actions',
}

type TProps = {
  projects: IConfigurationProject[];
  total: number;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSearchName: Dispatch<SetStateAction<string>>;
  searchName: string;
};

export function ProjectGrid({ projects, total, isLoading, page, setPage, searchName, setSearchName }: TProps) {
  const t = useTranslate('projects.grid');
  const { employees: users, isLoading: isLoadingUsers } = useEmployeesSelectionList();
  const { getDateOnlyFormatter } = useDateFormatter();
  const {
    showConfirmationModal,
    isSelectedAll,
    selectedItems,
    confirmationType,
    isBatchAction,
    isLoadingAction,
    listIdsLoading,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    onSelectItemClick,
    unSelectAllClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
  } = useProjectsActionsGrid(projects);

  function getColumns(): IGridColumn[] {
    return Object.values(EAccessor).map(accessor => ({
      accessor,
      disableSortBy: true,
      Header: (
        <ColumnHeader
          isLoading={(isLoading || isLoadingUsers) && !Boolean(searchName)}
          isLoadingAction={isLoading}
          description={t(`header.columns.${accessor}`)}
          isSelectedAll={accessor === EAccessor.SELECT_ALL}
          onSelectAllClick={toggleSelectAll}
          selectAllChecked={isSelectedAll}
        />
      ),
      sticky: accessor === EAccessor.ACTIONS && 'right',
    }));
  }

  function getRows(): Record<EAccessor, React.ReactNode>[] {
    return projects?.map(_project => {
      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(_project?.id)}
            checked={selectedItems.some(_id => _id === _project?.id)}
          />
        ),
        projects: _project.name || '-',
        clients: _project.client?.name || '-',
        accountable: users?.find(_user => _user.id.toString() === _project.accountable)?.name || '-',
        period: `${getDateOnlyFormatter(_project.startAt)} - ${getDateOnlyFormatter(_project.endAt)}`,
        status: (
          <StatusTag showStatusIcons variant={_project.active ? 'success' : 'error'}>
            {t(`${_project.active ? 'active' : 'inactive'}`)}
          </StatusTag>
        ),
        actions: (
          <ActionsRow
            referenceId={_project.id}
            actions={getActionsRow(_project.id)}
            isLoading={listIdsLoading?.includes(_project.id)}
          />
        ),
      };
    });
  }

  return (
    <SC.Container>
      <Search
        label={t('searchFieldLabel')}
        onChange={data => {
          selectedItems.length && unSelectAllClick();
          setSearchName(data);
        }}
        isLoading={(isLoading || isLoadingUsers) && !Boolean(searchName)}
      />
      <Grid
        columns={getColumns()}
        rows={getRows()}
        batchActions={getBatchActions()}
        isLoading={isLoading || isLoadingUsers}
        numberItemsSelected={selectedItems?.length || 0}
        hasPagination={total > 20}
        totalItems={total || 0}
        rowsLoading={Boolean(projects.length) ? projects.length : 4}
        rowsPerPage={20}
        currentPage={page}
        paginationIsDisabled={isLoading || isLoadingUsers}
        onPageClick={_page => setPage(_page)}
      />
      {showConfirmationModal && (
        <ProjectsConfirmationActionsModal
          open={showConfirmationModal}
          confirmationType={confirmationType}
          onActionClick={onConfirmationActionModal}
          onClose={onShowConfirmationActionsModal}
          isBatchAction={isBatchAction}
          isLoading={isLoadingAction}
        />
      )}
    </SC.Container>
  );
}
