import { Skeleton } from '@mui/material';

import * as SC from './styled';

export type TWaypointFieldType = 'origin' | 'destination' | 'stop';

interface IProps {
  viewOnly?: boolean;
}

export function WaypointFieldSkeleton({ viewOnly }: IProps) {
  return (
    <SC.WaypointContainerSkeleton>
      <SC.IdentifierContainerSkeleton>
        <Skeleton width={viewOnly ? 36 : 24} height={viewOnly ? 36 : 24} variant={viewOnly ? 'circular' : 'rounded'} />
      </SC.IdentifierContainerSkeleton>
      <Skeleton width={viewOnly ? 280 : 398} height={viewOnly ? 45 : 58} variant="rounded" />
      {!viewOnly && <Skeleton width={40} height={40} variant="circular" />}
    </SC.WaypointContainerSkeleton>
  );
}
