import { IconButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useValueFormatter } from '@shared/hooks';
import { useCurrencyCode } from '@shared/hooks/currency/useCurrencyCode';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

type Props = {
  currency: string;
  value: number;
  onDeleteClick: () => void;
  onEditClick: () => void;
};

export const AdvanceLimitItem = (props: Props) => {
  const { getValueWithCurrency } = useValueFormatter();

  const { symbol } = useCurrencyCode(props.currency);

  const t = useTranslate('policy.register.advances');

  return (
    <SC.MileageRateRowContainer>
      <SC.RowColumn>
        <SC.ColumnTitle variant="body4">{t('limits.item.currency')}</SC.ColumnTitle>
        <Typography variant="body3">{props.currency}</Typography>
      </SC.RowColumn>

      <SC.RowColumn ml={4}>
        <SC.ColumnTitle variant="body4">{t('limits.item.value')}</SC.ColumnTitle>
        <Typography variant="body3">
          {getValueWithCurrency({
            value: props.value,
            currencyPrefix: symbol,
          })}
        </Typography>
      </SC.RowColumn>

      <SC.ActionsRow>
        <IconButton variant="line" size="medium" icon="IconPencil" onClick={() => props.onEditClick()} />
        <IconButton variant="line" size="medium" icon="IconTrash" onClick={() => props.onDeleteClick()} />
      </SC.ActionsRow>
    </SC.MileageRateRowContainer>
  );
};
