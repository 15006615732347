import { Drawer as DrawerMui } from '@mui/material';
import styled from 'styled-components';

interface DrawerContainerProps {
  open: boolean;
  width: string | number;
}

export const DrawerContainer = styled.div`
  display: flex;
`;

export const DrawerMain = styled('main')<DrawerContainerProps>(({ open, width }) => ({
  flexGrow: 1,
  transition: `transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
  marginRight: `-${width}`,
  ...(open && {
    transition: `transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
    marginRight: 0,
    paddingRight: '24px',
  }),
}));

interface DrawerShowProps {
  width: string | number;
  height?: string | number;
  'margin-top'?: string | number;
}

export const DrawerShow = styled(DrawerMui)<DrawerShowProps>(({ open, width, height = '100vh', ...props }) => ({
  width: width,
  pointerEvents: open ? 'all' : 'none',
  flexShrink: 0,
  height,
  '& .MuiDrawer-paper': {
    width: width,
    ...(props['margin-top'] && {
      marginTop: props['margin-top'],
      height: `calc(${height} - ${props['margin-top']})`,
    }),
  },
}));
