import { createContext, useReducer } from 'react';
import { Outlet } from 'react-router-dom';

import { importingReducer } from './reducer/reducer';
import { initialSettingsState } from './store';
import { IImportingContextProps, IImportingContextState, ImportingProviderProps } from './types';

export const ImportingContext = createContext<IImportingContextProps>({
  state: {} as IImportingContextState,
  dispatch: () => {},
});

export const ImportingContextProvider = ({ value }: ImportingProviderProps) => {
  const [state, dispatch] = useReducer(importingReducer, value ?? initialSettingsState);

  return <ImportingContext.Provider value={{ state, dispatch }}>{<Outlet />}</ImportingContext.Provider>;
};
