import { usePermissions, useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { EAuthZPermission } from '@shared/types/authZPermission';

export function usePermissionsAuthZ() {
  const permissionsData = usePermissions();
  const dataCompany = useSelectedCompany();
  const currencyCompanyPermissions = permissionsData?.companies?.find(
    company => company?.registrationNumber === dataCompany?.selectedCompany?.registrationNumber,
  );
  const permissions = currencyCompanyPermissions?.permissions;

  const defaultPermissions = [
    //expenses
    EAuthZPermission.EXPENSE_VIEW,
    EAuthZPermission.EXPENSES_REPORTS_VIEW,
    EAuthZPermission.EXPENSES_REPORTS_ACTION,
    EAuthZPermission.EXPENSES_MILEAGE_ACTION,

    //exp_analytics
    EAuthZPermission.ANALYTICS_VIEW,
    EAuthZPermission.ANALYTICS_EXPENSES_VIEW,
    EAuthZPermission.ANALYTICS_REPORTS_VIEW,
    EAuthZPermission.ANALYTICS_CARD_VIEW,
    EAuthZPermission.ANALYTICS_EXPENSES_ACTION,
    EAuthZPermission.ANALYTICS_REPORTS_ACTION,
    EAuthZPermission.ANALYTICS_CARD_ACTION,

    //exp_advances
    EAuthZPermission.ADVANCES_REPORTS_VIEW,
    EAuthZPermission.ADVANCES_REPORTS_ACTION,
  ];

  function checkAdmin() {
    return permissions?.includes(EAuthZPermission.ADMIN);
  }

  function checkPermissionAuthZ(slug: EAuthZPermission): boolean {
    const isAdmin = checkAdmin();
    const isDefaultPermission = defaultPermissions.some(permission => slug === permission);
    const isPermission = permissions?.some(_slug => _slug === slug || checkAdmin());

    return isAdmin || isDefaultPermission || isPermission;
  }

  function checkPermissionsBatch(slugs: EAuthZPermission[]): boolean {
    const isAdmin = checkAdmin();
    const isDefaultPermission = slugs?.some(p => defaultPermissions?.includes(p));
    const isPermission = slugs?.some(p => permissions?.includes(p));

    return isAdmin || isDefaultPermission || isPermission;
  }

  return {
    permissions,
    defaultPermissions,
    checkPermissionAuthZ,
    checkPermissionsBatch,
  };
}
