import { ReceiptFile } from '@containers/Expenses/context/types';
import { IOdometer } from '@containers/Expenses/context/types/expenseById';
import { useTranslation } from '@locale/Translator';

import { DistanceRoutes } from '../../molecules/DistanceRoutes/DistanceRoutes';
import { useOdometerFieldsHelper } from './hooks/useOdometerFieldsHelper';
import { IOdometerFieldProps, OdometerField } from './OdometerField/OdometerField';
import { OdometerFieldsSkeleton } from './OdometerFieldsSkeleton';
import * as SC from './styled';

interface IProps {
  odometer: IOdometer;
  isLoading?: boolean;
  ratePerKm: number;
  ratePerKmIsLoading: boolean;
  onUploadChange?(variant: IOdometerFieldProps['variant'], file: ReceiptFile): void;
  onValueChange?(variant: IOdometerFieldProps['variant'], value: number): void;
}

export const OdometerFields = ({
  odometer,
  isLoading,
  ratePerKm,
  ratePerKmIsLoading,
  onUploadChange,
  onValueChange,
}: IProps) => {
  const { t } = useTranslation();
  const {
    distance,
    getFileType,
    onUploadChange: _onUploadChange,
    onValueChange: _onValueChange,
  } = useOdometerFieldsHelper({ odometer, ratePerKm, onUploadChange, onValueChange });

  return (
    <SC.Container>
      {isLoading ? (
        <OdometerFieldsSkeleton />
      ) : (
        <>
          <OdometerField
            variant="start"
            fieldValue={0}
            file={{
              ...odometer?.start?.attachment,
              fileName: `${t('molecules.odometerUpload.label.start')}.${getFileType(odometer?.start?.attachment)}`,
            }}
            onFieldChange={value => _onValueChange?.('start', value)}
            onUploadClick={file => _onUploadChange?.('start', file[0])}
          />
          <OdometerField
            variant="end"
            fieldValue={0}
            file={{
              ...odometer?.end?.attachment,
              fileName: `${t('molecules.odometerUpload.label.final')}.${getFileType(odometer?.end?.attachment)}`,
            }}
            onFieldChange={value => _onValueChange?.('end', value)}
            onUploadClick={file => _onUploadChange?.('end', file[0])}
          />
        </>
      )}

      <DistanceRoutes
        variant="primary"
        distance={distance}
        ratePerKM={ratePerKm}
        isLoading={isLoading || ratePerKmIsLoading}
      />
    </SC.Container>
  );
};
