import { useState } from 'react';
import { RegisterClientPreview } from '@containers/Clients/components/organisms/RegisterClientPreview';
import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { useClientRegisterPage } from '@containers/Clients/hooks/rest/useClientRegister';
import { useTranslation } from '@locale/Translator';
import { PageHeader } from '@organisms/BasePage/PageHeader/PageHeader';
import { ClientFooterPage } from '@pages/ClientRegister/ClientFooterPage/ClientFooterPage';
import { ClientRegisterProgressHeader } from '@pages/ClientRegister/ClientRegisterProgressHeader';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Loader } from './Loader';
import * as SC from './styled';

export const ClientRegister = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [step, setStep] = useState(0);

  const methods = useForm<IConfigurationClient>({
    defaultValues: { active: false, name: '' },
  });

  const { onContinueClick, isLoading } = useClientRegisterPage({ setStep });

  const onSubmit = (data: IConfigurationClient) => {
    onContinueClick(data, step);
  };

  return (
    <>
      <ClientRegisterProgressHeader step={step} />
      <SC.Container>
        <PageHeader title={t('pages.clientRegister.title')} />
        <FormProvider {...methods}>
          <RegisterClientPreview onSubmit={onSubmit} step={step} />
        </FormProvider>
      </SC.Container>
      <ClientFooterPage action={onSubmit} methods={methods} />
      <Loader
        show={isLoading}
        description={!id ? t('pages.clientRegister.creatingClient') : t('pages.clientRegister.updatingClient')}
      />
    </>
  );
};
