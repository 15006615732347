import { useEffect } from 'react';
import { EExpenseType, IExpenseInput } from '@containers/Expenses/context/types';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { useCheckExpense } from '@pages/ExpenseRegister/hooks/useCheckExpense';
import { defaultCategoryRouteCode, defaultCurrencyCode, paymentMethodRefundable } from '@shared/constant';
import { useDateFormatter } from '@shared/hooks';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';
import { UseFormReturn } from 'react-hook-form';

import { IExpenseRefFields } from './useFieldTypeConfig';

export function useSetFieldsValues(
  expense: IExpense,
  isLoading: boolean,
  methods: UseFormReturn<IExpenseInput, unknown, undefined>,
) {
  const { setValue, getValues } = methods;
  const expenseType = useCheckExpenseType();
  const isKmType = expenseType === EExpenseType.ODOMETER || expenseType === EExpenseType.ORIGIN_DESTINATION;
  const { getFormatDateUTC } = useDateFormatter();
  const { hasExpense } = useCheckExpense();

  useEffect(() => {
    if (expense && !isLoading) {
      Object.keys(expense).map(expenseKey => {
        if (IExpenseRefFields[expenseKey] && expense[expenseKey]) {
          setValue(IExpenseRefFields[expenseKey], expense[expenseKey]?.id || expense[expenseKey] || null);
        }

        if (expenseKey == IExpenseRefFields.date) {
          getValues(expenseKey) !== String(expense[expenseKey]) &&
            setValue(
              expenseKey,
              expense[expenseKey] === '0001-01-01T00:00:00'
                ? undefined
                : expense[expenseKey]
                ? expense[expenseKey]
                : String(new Date()),
            );
        }

        if (expenseKey == 'paymentMethod') {
          setValue(IExpenseRefFields.paymentType, expense[expenseKey]?.id);
        }

        if (expenseKey == IExpenseRefFields.address) {
          setValue(IExpenseRefFields.address, expense[IExpenseRefFields.address]);
        }

        if (expenseKey == IExpenseRefFields.currency) {
          setValue(IExpenseRefFields.currency, expense[expenseKey] || defaultCurrencyCode);
        }
      });

      if (hasExpense)
        setValue(
          'attachments',
          expense.attachments.map(({ contentType, base64, url, path }) => ({
            contentType,
            base64,
            previewUrl: url,
            path,
          })) || [],
        );
    } else if (!isLoading) {
      if (isKmType) {
        setValue(IExpenseRefFields.category, defaultCategoryRouteCode);
      }
      setValue(IExpenseRefFields.currency, defaultCurrencyCode);
      setValue(IExpenseRefFields.paymentType, paymentMethodRefundable);

      if (!getValues('expenseDate')) {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        setValue(IExpenseRefFields.date, String(getFormatDateUTC(currentDate)));
      }
    }
  }, [expense, isLoading]);
}
