import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { ReportDetails } from '@pages/ReportDetails';
import { Reports } from '@pages/Reports';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function ReportsRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['BASIC_REPORTS']}
          routePermissions={[EAuthZPermission.EXPENSES_REPORTS_VIEW]}
        />
      ),
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Relatórios" moduleName="Relatórios" />,
      children: [
        {
          path: Router.getReports(),
          element: <Reports />,
        },
        {
          path: Router.getReportDetails(':id'),
          element: <ReportDetails />,
        },
      ],
    },
  ];
}
