import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getReportsQuery } from '../context/queries';
import { EReportStatus, IGetRequestResponse, IReport, IReportsRequest } from '../context/types/report';

interface useReports {
  reports: IReport[];
  reportsIsLoading: boolean;
  currentPage: number;
  reportsTotalItems: number;
  reportsError: unknown;
}

export interface IUseReportsProps {
  limit: number;
  page: number;
  search?: string;
  status?: EReportStatus[];
}

export const useReports = ({ limit, page, search, status }: IUseReportsProps): useReports => {
  const {
    loading,
    data,
    error: reportsError,
    networkStatus,
  } = useQuery<IGetRequestResponse, IReportsRequest>(getReportsQuery, {
    variables: {
      limit,
      page,
      search,
      status,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  return {
    reports: data?.getReports?.reports || [],
    reportsIsLoading: loading || networkStatus === NetworkStatus.loading,
    currentPage: data?.getReports?.currentPage,
    reportsTotalItems: data?.getReports?.total || 0,
    reportsError,
  };
};
