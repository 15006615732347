import { BreadcrumbPage } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { costCentersBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

type Props = {
  isEditing: boolean;
};

export default function CostCenterRegisterBreadcrumb({ isEditing }: Props) {
  const t = useTranslate('pages.costCenterRegister');

  return (
    <BreadcrumbPage
      items={[
        {
          to: '/settings',
          label: t('breadcrumb.manageCompany'),
        },
        {
          to: costCentersBaseURL,
          label: t('breadcrumb.costCenter'),
        },
        {
          to: '',
          label: !isEditing ? t('manualAddCostCenter') : t('editCostCenter'),
        },
      ]}
    />
  );
}
