import { AddAdvanceButton } from '@containers/Advances/components/organisms/AddAdvanceButton/AddAdvanceButton';
import { AdvancesPreview } from '@containers/Advances/components/organisms/AdvancesPreview';
import { useTranslation } from '@locale/Translator';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useDelegationAllowed } from '@shared/hooks/permissions/delegation/useDelegationAllowed';

export const Advances = () => {
  const { t } = useTranslation();
  const { delegationEnabled } = useDelegationAllowed();

  return (
    <BasePage
      headerPage={{
        title: t('pages.advances.title'),
        caption: t('pages.advances.caption'),
        actions: !delegationEnabled ? <AddAdvanceButton /> : <></>,
        showDelegateInfo: true,
      }}>
      <AdvancesPreview />
    </BasePage>
  );
};
