import { IOdometer } from '@containers/Expenses/context/types';
import { useTranslation } from '@locale/Translator';
import { ImagePreview } from '@organisms/ImagePreview';

import * as SC from './styled';

export const OdometerExpenseDetails = ({ start, end, distance }: IOdometer) => {
  const { t } = useTranslation();

  return (
    <SC.Container>
      <SC.Box>
        <SC.TextContainer>
          <SC.Title variant="headline8">{t('organisms.odometerExpenseDetails.initialKm')}</SC.Title>
          <SC.Text variant="body3">{start?.value ?? t('organisms.odometerExpenseDetails.notInformed')}</SC.Text>
        </SC.TextContainer>
        <ImagePreview images={[{ src: start?.attachment?.url }]} />
      </SC.Box>
      <SC.DistanceBox>
        <SC.Icon name="IconMap" />
        <SC.TextContainer>
          <SC.Title variant="headline8">{t('organisms.odometerExpenseDetails.distance')}</SC.Title>
          <SC.Text variant="body3">{distance} km</SC.Text>
        </SC.TextContainer>
      </SC.DistanceBox>
      <SC.Box>
        <SC.TextContainer>
          <SC.Title variant="headline8">{t('organisms.odometerExpenseDetails.finalKm')}</SC.Title>
          <SC.Text variant="body3">{end?.value ?? t('organisms.odometerExpenseDetails.notInformed')}</SC.Text>
        </SC.TextContainer>
        <ImagePreview images={[{ src: end?.attachment?.url }]} />
      </SC.Box>
    </SC.Container>
  );
};
