import styled from 'styled-components';

export const StyledTag = styled.div`
  display: flex;
  padding: 3px 12px;
  border-radius: 24px;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  color: ${({ theme }) => theme.colors.primary};
`;

export const Box = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`;
