import { useTranslation } from '@locale/Translator';

import { SectionContainer } from '../../../../../components/organisms/SectionContainer/SectionContainer';
import { GeneralSettingsFields } from './GeneralSettingsFields';

export function GeneralSettingsPreview() {
  const { t } = useTranslation();
  const translationBase = 'molecules.generalSettings';

  return (
    <SectionContainer title={t(`${translationBase}.title`)} caption={t(`${translationBase}.description`)}>
      <GeneralSettingsFields />
    </SectionContainer>
  );
}
