import { EExpenseFieldsLicenseFeatures, IExpenses } from '@containers/Expenses/context/types';
import { useExpenseFormFields } from '@containers/Expenses/hooks/useExpenseFormFields';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { useCheckIsKmType } from '@shared/hooks/useCheckIsKmType';
import { useTranslation } from 'react-i18next';

import { ExpenseDetailsKilometer } from './ExpenseDetailsKilometer/ExpenseDetailsKilometer';
import * as SC from './styled';
import { ExpenseFields } from './utils/formatExpenseCollapseFields';

export interface ExpenseDetailsCollapseDataProps {
  fields: ExpenseFields[];
  expense: Omit<
    IExpenses,
    'id' | 'referenceId' | 'status' | 'violationNumber' | 'commentsNumber' | 'attachmentsNumber'
  >;
}

export const ExpenseDetailsCollapseData = ({ fields, expense }: ExpenseDetailsCollapseDataProps) => {
  const { t } = useTranslation();
  const { isKmType } = useCheckIsKmType(expense?.type);
  const { fields: fieldsConfig } = useExpenseFormFields();
  const distance: number = expense?.odometer?.distance || expense?.route?.distance || 0;
  const ratePerKm: number = expense?.odometer?.ratePerKm || expense?.route?.ratePerKm || 0;

  return (
    <SC.ExpenseCollapseDetailsContainer>
      {isKmType && <ExpenseDetailsKilometer distance={distance} ratePerKm={ratePerKm} totalValue={expense.value} />}

      {fields?.map((field: ExpenseFields, index: number) => {
        if (field.label == 'comments')
          return (
            <PermissionsChecker key={index} licenseFeature={EExpenseFieldsLicenseFeatures[field?.key]}>
              <SC.ExpenseCollapseDetailsComment hasCustomFields={fields?.length - 1 != index}>
                <SC.ExpenseCollapseDetailsCommentLabel variant="body3">
                  {t(`molecules.expenseCollapse.${field.label}`)}
                </SC.ExpenseCollapseDetailsCommentLabel>
                <SC.ExpenseCollapseDetailsCommentValue variant="body3">
                  {field.value}
                </SC.ExpenseCollapseDetailsCommentValue>
              </SC.ExpenseCollapseDetailsComment>
            </PermissionsChecker>
          );

        return (
          <PermissionsChecker
            key={index}
            licenseFeature={EExpenseFieldsLicenseFeatures[field?.key]}
            hidden={!((Boolean(field.value) && field.value != '-') || fieldsConfig[field.key]?.active)}>
            <SC.ExpenseCollapseDetailsGrid>
              <Typography variant="body3">
                {field.hasLabelTranslation ? t(`molecules.expenseCollapse.${field.label}`) : field.label}
              </Typography>
              <SC.ExpenseCollapseDetailsValue variant="body3">{field.value}</SC.ExpenseCollapseDetailsValue>
            </SC.ExpenseCollapseDetailsGrid>
          </PermissionsChecker>
        );
      })}
    </SC.ExpenseCollapseDetailsContainer>
  );
};
