import { useState } from 'react';
import { IConfigurationProject } from '@containers/Projects/context/types/projectTypes';
import { useActiveBatchProjects } from '@containers/Projects/hooks/rest/useActiveBatchProjects';
import { useDeleteBatchProjects } from '@containers/Projects/hooks/rest/useDeleteBatchProjects';
import { IActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { IBatchActionsActions } from '@organisms/Grid/BatchActions/BatchActions';
import { useSelectionRows } from '@organisms/Grid/BatchActions/hooks/useSelectionRows';
import { projectsBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { TProjectsConfirmationActions } from '../ProjectsConfirmationActionsModal/ProjectsConfirmationActionsModal';

enum EActions {
  EDIT = 'edit',
  ACTIVE = 'active',
  DELETE = 'delete',
}

enum EIcons {
  EDIT = 'IconPencil',
  ACTIVE = 'IconToggleLeft',
  DELETE = 'IconTrash',
}

export function useProjectsActionsGrid(projects: IConfigurationProject[]) {
  const t = useTranslate('projects.grid');
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [confirmationType, setConfirmationType] = useState<TProjectsConfirmationActions>();
  const [isBatchAction, setIsBatchAction] = useState<boolean>(false);
  const [projectSelected, setSelected] = useState<IConfigurationProject>();
  const [projectsSelectedIds, setProjectsSelectedIds] = useState<IConfigurationProject['id'][]>();
  const [projectSelectedActive, setSelectedActive] = useState<boolean>();
  const { isSelectedAll, onSelectItemClick, selectedItems, toggleSelectAll, unSelectAllClick } =
    useSelectionRows<IConfigurationProject>(projects);

  const { deleteProjects, isLoading: isLoadingDelete } = useDeleteBatchProjects(projects);
  const { setActiveBatchProject, listIdsLoading, isLoading: activeIsLoading } = useActiveBatchProjects(projects);

  function onShowConfirmationActionsModal(): void {
    setShowConfirmationModal(_open => !_open);
  }

  async function onConfirmationActionModal() {
    if (confirmationType === 'deactivation') {
      await setActiveBatchProject(projectsSelectedIds, projectSelectedActive ?? false);
      unSelectAllClick();
    } else {
      await deleteProjects(projectsSelectedIds);
      unSelectAllClick();
    }
    onShowConfirmationActionsModal();
  }

  function getSelectedProjects(_id: IConfigurationProject['id']): IConfigurationProject {
    return projects?.find(_project => _project?.id === _id);
  }

  function onActionClick(_actionKey: EActions, _id?: IConfigurationProject['id']) {
    const _projectsSelectedIds: IConfigurationProject['id'][] = _id ? [_id] : selectedItems;
    const _isBatchAction: boolean = _projectsSelectedIds?.length > 1;
    const _projects: IConfigurationProject = !_isBatchAction ? getSelectedProjects(_projectsSelectedIds[0]) : null;

    setSelectedActive(_projects ? !_projects.active : null);
    setProjectsSelectedIds(_projectsSelectedIds);
    _projects && setSelected(_projects);
    setIsBatchAction(_projectsSelectedIds?.length > 1);

    switch (_actionKey) {
      case EActions.ACTIVE:
        setConfirmationType('deactivation');
        if ((_projectsSelectedIds?.length === 1 && _projects.active) || _projectsSelectedIds?.length > 1) {
          onShowConfirmationActionsModal();
        } else {
          setActiveBatchProject([_projects.id], !_projects.active);
        }
        break;

      case EActions.DELETE:
        setConfirmationType('exclusion');
        onShowConfirmationActionsModal();
        break;

      default:
        setConfirmationType(null);
        navigate(`${projectsBaseURL}/register/${_id}`);
        break;
    }
  }

  function getActionsRow(_id: IConfigurationProject['id']): IActionsRow[] {
    return (
      projects?.length &&
      Object.keys(EActions).map(key => ({
        id: _id,
        key: EActions[key],
        allowed: true,
        iconName: EIcons[key],
        name: t(
          `body.actions.${
            EActions[key] === EActions.ACTIVE && getSelectedProjects(_id)?.active ? 'deactivate' : EActions[key]
          }`,
        ),
        onClick: onActionClick,
      }))
    );
  }

  function getTooltipBatchActions(key: EActions): string {
    if (key === EActions.ACTIVE) {
      const projectsActiveList = selectedItems?.filter(selectedId =>
        projects?.some(project => project.id === selectedId && !project.active),
      );
      return projectsActiveList?.length > 0 ? t('header.batchActions.tooltips.active') : '';
    }
    return '';
  }

  function getBatchActions(): IBatchActionsActions[] {
    const batchActions: IBatchActionsActions[] = [];

    projects?.length &&
      Object.keys(EActions).forEach(key => {
        batchActions.push({
          key: EActions[key],
          allowed: EActions[key] !== EActions.EDIT,
          disabled: Boolean(getTooltipBatchActions(EActions[key])) && EActions[key] !== EActions.DELETE,
          iconName: EIcons[key],
          name: t(`header.batchActions.${EActions[key] === EActions.ACTIVE ? 'deactivate' : EActions[key]}`),
          tooltipMessage: getTooltipBatchActions(EActions[key]),
          onClick: _key => onActionClick(_key as EActions),
        });
      });

    return batchActions;
  }

  return {
    projectSelected,
    showConfirmationModal,
    confirmationType,
    isSelectedAll,
    selectedItems,
    isBatchAction,
    isLoadingAction: activeIsLoading || isLoadingDelete,
    listIdsLoading: listIdsLoading,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    onSelectItemClick,
    unSelectAllClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
  };
}
