import { Dispatch, SetStateAction } from 'react';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import * as SC from './styled';

interface IProps {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

export function CostCenterRegisterFooterPage({ step, setStep }: IProps) {
  const t = useTranslate('pages.costCenterRegister.actions');
  const { colors } = useTheme();
  const navigate = useNavigate();

  return (
    <SC.Container>
      <div>
        <LinkButton variant="default" onClick={() => navigate(-1)}>
          {t('cancel')}
        </LinkButton>
      </div>
      <SC.ActionsButtonsFooterContainer>
        {step === 1 && (
          <Button variant="secondary" size="medium" onClick={() => setStep(0)}>
            <Icons name="IconArrowLeft" color={colors.secondary[50]} fill="transparent" />
            {t('goBack')}
          </Button>
        )}

        <Button variant="primary" size="medium" type="submit">
          {t(step === 0 ? 'continue' : 'save')}
          <Icons name="IconArrowRight" color="#fff" fill="transparent" />
        </Button>
      </SC.ActionsButtonsFooterContainer>
    </SC.Container>
  );
}
