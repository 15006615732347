import { Skeleton } from '@mui/material';
import _range from 'lodash/range';

import * as SC from '../styled';

export const ReportInfosSummarySkeleton = () => {
  return (
    <SC.ReportInfosSummary>
      <SC.SummaryContentDescription>
        <Skeleton variant="rounded" width={215} height={45} style={{ marginBottom: '5px' }} />
        <Skeleton variant="rounded" width={116} height={20} />
      </SC.SummaryContentDescription>
      {_range(3).map(key => (
        <SC.SummaryContentDescription key={key}>
          <Skeleton variant="rounded" width={98} height={21} style={{ marginBottom: '5px' }} />
          <Skeleton variant="rounded" width={90} height={18} />
        </SC.SummaryContentDescription>
      ))}
    </SC.ReportInfosSummary>
  );
};
