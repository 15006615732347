import { Skeleton } from '@mui/material';

import { EFieldsSettings } from './FieldsSettings';
import * as SC from './styled';

export const FieldsSettingsSkeleton = () => (
  <SC.Container>
    <SC.FieldsHeader>
      <Skeleton variant="rounded" width={199} height={24} />
      <Skeleton variant="rounded" width={199} height={24} />
    </SC.FieldsHeader>
    {Object.values(EFieldsSettings).map((_, key) => (
      <div key={key}>
        <SC.FieldContent>
          <Skeleton variant="text" width={160} height={24} />
          <SC.ToggleContainer>
            <Skeleton variant="rounded" width={36} />
            <Skeleton variant="rounded" width={160} height={24} />
          </SC.ToggleContainer>
          <SC.ToggleContainer>
            <Skeleton variant="rounded" width={36} />
            <Skeleton variant="rounded" width={160} height={24} />
          </SC.ToggleContainer>
        </SC.FieldContent>
      </div>
    ))}
    <SC.MandatoryInfoContainer>
      <Skeleton variant="text" width={160} />
    </SC.MandatoryInfoContainer>
  </SC.Container>
);
