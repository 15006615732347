import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { mutate } from 'swr';

import { ISummaryApprovalFlow } from '../../types/approvalFlow';
import { useTargetCustomApprovalFlow } from '../useTargetCustomApprovalFlow';

interface IResponse {
  id: ISummaryApprovalFlow['id'];
  error: boolean;
  message: string;
}

export function useDeleteCustomApprovalFlows(approvalFlows: ISummaryApprovalFlow[]) {
  const t = useTranslate('approvalFlows.feedbacks.delete');
  const { mutation } = useMutate('v1/approvals');
  const target = useTargetCustomApprovalFlow();

  async function getDeleteCustomApprovalFlows(ids: ISummaryApprovalFlow['id'][]) {
    const params = ids.map(_id => ({
      key: 'ids[]',
      value: String(_id),
    }));

    const result: IResponse[] = await mutation.trigger({
      path: ``,
      parameters: params,
      options: {
        method: 'DELETE',
      },
    });
    return result;
  }

  async function deleteCustomApprovalFlows(ids: ISummaryApprovalFlow['id'][] = []) {
    try {
      const response = await getDeleteCustomApprovalFlows(ids);
      const approvalFlowsError = response?.filter(_approvalFlow => _approvalFlow.error);
      const approvalFlowsSuccess = response.filter(_approvalFlow => !_approvalFlow.error);

      if (approvalFlowsError?.length) {
        approvalFlowsError?.forEach(approvalFlow => {
          const approvalFlowName =
            approvalFlows?.find(_approvalFlow => _approvalFlow.id == approvalFlow.id)?.name ?? '';
          showToast({
            message: `${approvalFlow.error ? approvalFlow.message : t('error')}: ${approvalFlowName ?? ''}`,
            type: 'error',
          });
        });
      }

      if (approvalFlowsSuccess.length) {
        approvalFlowsSuccess?.length;
        showToast({
          message: t(`success.${approvalFlowsSuccess?.length > 1 ? 'plural' : 'singular'}`),
          type: 'success',
        });
      }

      mutate(`v1/approvals/${target}`);
    } catch (_error) {
      showToast({
        message: _error.message,
        type: 'error',
      });
    }
  }

  return {
    deleteCustomApprovalFlows,
    error: mutation?.error,
    isLoading: mutation?.isMutating,
  };
}
