import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';

import { ColorVariant, SizeVariant } from 'src/themes/types/Variants';

import { Icon, ShapeIconContainer } from './styled';

export interface ShapeIconProps {
  /**
   * Icon content
   * @default IconAlertCircle
   */
  icon: IconTypes;

  /**
   * Sets the size
   *
   * @default medium
   */
  size: SizeVariant;

  /**
   * Sets the variant theme
   *
   * @default secondary
   */
  variant?: ColorVariant;

  $foregroundColor?: string;
}

export const ShapeIcon = ({
  icon = 'IconAlertCircle',
  size = 'medium',
  variant = 'secondary',
  $foregroundColor,
}: ShapeIconProps) => {
  const iconSize: Record<SizeVariant, number> = {
    large: 34,
    medium: 24,
    small: 32,
    xsmall: 16,
  };

  return (
    <ShapeIconContainer variant={variant} size={size}>
      <Icon
        name={icon}
        variant={variant}
        size={iconSize[size]}
        fill="transparent"
        $foregroundColor={$foregroundColor}
      />
    </ShapeIconContainer>
  );
};
