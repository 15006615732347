import { useEffect } from 'react';
import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import { PolicyOCRConfig } from '@containers/Policies/context/types/ocr';
import { useOCRPolicySettings } from '@containers/Policies/hooks/ocr/useOCRPolicySettings';
import { useSaveOCRPolicy } from '@containers/Policies/hooks/ocr/useSaveOCRPolicy';
import { Checkbox, Divider, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { Box, Grid } from '@mui/material';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import merge from 'lodash/merge';
import { Controller, useForm } from 'react-hook-form';

import SelectAllToggle from './SelectAllToggle';
import * as SC from './styled';
import { usePolicyOCRChecks } from './usePolicyOCRChecks';

type Props = {
  policyId: string;
};

const PolicyOCRSettings = (props: Props) => {
  const t = useTranslate('policy.register.ocr');

  const checks = usePolicyOCRChecks();

  const { control, setValue, reset, getValues } = useForm<PolicyOCRConfig>({
    defaultValues: {
      readFields: {},
    },
  });

  const { policy, isLoading } = useOCRPolicySettings(props.policyId);

  const saveOCRPolicy = useSaveOCRPolicy(props.policyId);

  useEffect(() => reset(policy), [policy, reset]);

  function handleUpdate(newPolicy: PolicyOCRConfig) {
    const formValues = getValues();
    saveOCRPolicy.handleUpdate(merge({ ...formValues, isEnabled: true }, newPolicy));
  }

  if (isLoading) {
    return <Skeleton variant="rounded" height={400} width={'100%'} />;
  }

  return (
    <div>
      <SC.SectionContainer style={{ marginBottom: 0 }}>
        <SC.SectionTitle variant="headline9">{t('sections.validations.title')}</SC.SectionTitle>
        <SC.SectionCaption variant="body3">{t('sections.validations.caption')}</SC.SectionCaption>
      </SC.SectionContainer>

      <SC.CheckContainer>
        <SelectAllToggle control={control} setValue={setValue} policyId={props.policyId} />
        <SC.CheckboxLabel variant="body3">{t('selectAll')}</SC.CheckboxLabel>
      </SC.CheckContainer>

      <Box pl={3}>
        <Grid container>
          {checks.map(check => (
            <Grid key={check.key} sm={12} md={6} item>
              <SC.CheckContainer>
                <Controller
                  control={control}
                  defaultValue={false}
                  name={`readFields.${check.key}` as const}
                  render={({ field }) => (
                    <Checkbox
                      size="small"
                      checked={field.value === true}
                      onChange={() => {
                        handleUpdate({ readFields: { [check.key]: !field.value } });
                        field.onChange(!field.value);
                      }}
                    />
                  )}
                />

                <SC.CheckboxLabel variant="body3">{check.label}</SC.CheckboxLabel>
              </SC.CheckContainer>
            </Grid>
          ))}
        </Grid>
      </Box>

      <SC.SectionContainer>
        <SC.SectionCaption variant="body3">{t('sections.validations.violationBehaviour')}</SC.SectionCaption>

        <SC.ToggleContainer>
          <Controller
            control={control}
            name={'general.generateViolation'}
            render={({ field }) => (
              <ToggleSettings
                onChange={() => {
                  handleUpdate({ general: { generateViolation: !field.value } });
                  field.onChange(!field.value);
                }}
                checked={field.value}
                label={t('behaviour.generateViolation')}
              />
            )}
          />

          <Controller
            control={control}
            name={'general.blockReportSubmitWhenInvalid'}
            render={({ field }) => (
              <ToggleSettings
                onChange={() => {
                  handleUpdate({
                    general: {
                      blockReportSubmitWhenInvalid: !field.value,
                    },
                  });
                  field.onChange(!field.value);
                }}
                checked={field.value}
                label={t('behaviour.blockSubmit')}
              />
            )}
          />
        </SC.ToggleContainer>
      </SC.SectionContainer>

      <Divider orientation="horizontal" />

      <SC.SectionContainer>
        <SC.SectionTitle variant="headline9">{t('sections.manualChanges.title')}</SC.SectionTitle>
        <SC.SectionCaption variant="body3">{t('sections.manualChanges.caption')}</SC.SectionCaption>

        <SC.ToggleContainer>
          <Controller
            control={control}
            name={'compliantReadLevel.generateViolation'}
            render={({ field }) => (
              <ToggleSettings
                onChange={() => {
                  handleUpdate({
                    compliantReadLevel: {
                      generateViolation: !field.value,
                    },
                  });
                  field.onChange(!field.value);
                }}
                checked={field.value}
                label={t('behaviour.generateViolation')}
              />
            )}
          />

          <Controller
            control={control}
            name={'compliantReadLevel.blockReportSubmitWhenInvalid'}
            render={({ field }) => (
              <ToggleSettings
                onChange={() => {
                  handleUpdate({
                    compliantReadLevel: {
                      blockReportSubmitWhenInvalid: !field.value,
                    },
                  });
                  field.onChange(!field.value);
                }}
                checked={field.value}
                label={t('behaviour.blockSubmit')}
              />
            )}
          />
        </SC.ToggleContainer>
      </SC.SectionContainer>

      <Divider orientation="horizontal" />

      <SC.SectionContainer>
        <SC.SectionTitle variant="headline9">{t('sections.compliantRules.title')}</SC.SectionTitle>
        <SC.SectionCaption variant="body3">{t('sections.compliantRules.caption')}</SC.SectionCaption>

        <SC.ToggleContainer>
          <Controller
            control={control}
            name={'compliantPersonalItems.generateViolation'}
            render={({ field }) => (
              <ToggleSettings
                onChange={(_, value) => {
                  handleUpdate({
                    compliantPersonalItems: {
                      generateViolation: !field.value,
                      blockReportSubmitWhenInvalid: !value
                        ? value
                        : getValues('compliantPersonalItems.blockReportSubmitWhenInvalid'),
                    },
                  });
                  field.onChange(!field.value);
                }}
                checked={field.value}
                label={t('behaviour.generateViolation')}
              />
            )}
          />

          <Controller
            control={control}
            name={'compliantPersonalItems.blockReportSubmitWhenInvalid'}
            render={({ field }) => (
              <ToggleSettings
                onChange={() => {
                  handleUpdate({
                    compliantPersonalItems: {
                      blockReportSubmitWhenInvalid: !field.value,
                    },
                  });
                  field.onChange(!field.value);
                }}
                disabled={!getValues('compliantPersonalItems.generateViolation')}
                checked={field.value}
                label={t('behaviour.blockSubmit')}
              />
            )}
          />
        </SC.ToggleContainer>
      </SC.SectionContainer>
    </div>
  );
};

export default PolicyOCRSettings;
