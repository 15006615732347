import { ICompanyLicenseAcceptanceTerm } from '@containers/Subscription/context/types/contracts';
import { Permissions, useUnleashPermissions } from '@shared/hooks/permissions/unleash/useUnleashPermissions';

import { useAcceptedContacts } from './useAcceptedContacts';

interface PermissionsRoutes extends Permissions {
  accounting: boolean;
  customizations: boolean;
  approver: boolean;
  expenseManagement: boolean;
  enabledExpensePermission: boolean;
  contract: boolean;
}
interface UsePermissionsRoutes {
  userIsAdmin: boolean;
  termsSigned: boolean;
  permissions: PermissionsRoutes;
  checkSignedContracts(acceptanceTerms: ICompanyLicenseAcceptanceTerm[]): boolean;
}

export function useCompanyAccessConfiguration(): UsePermissionsRoutes {
  const { termsSigned, checkSignedContracts } = useAcceptedContacts();
  const { userIsAdmin, permissions } = useUnleashPermissions();
  const _permissions: PermissionsRoutes = {
    ...permissions,
    accounting: userIsAdmin,
    customizations: userIsAdmin,
  };

  return {
    userIsAdmin,
    termsSigned,
    permissions: _permissions,
    checkSignedContracts,
  };
}
