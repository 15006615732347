import { EExpenseType } from '@containers/Expenses/context/types';

interface IUseCheckIsKmType {
  isKmType: boolean;
  checkIsKmType(expenseType: EExpenseType): boolean;
}

export function useCheckIsKmType(expenseType?: EExpenseType): IUseCheckIsKmType {
  function checkIsKmType(_expenseType: EExpenseType): boolean {
    return (
      _expenseType === EExpenseType.ODOMETER ||
      _expenseType === EExpenseType.ORIGIN_DESTINATION ||
      _expenseType === EExpenseType.GPS
    );
  }

  return {
    isKmType: checkIsKmType(expenseType),
    checkIsKmType,
  };
}
