import { Accordion as AccordionComponent } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ChildrenContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacings.s,
  flexDirection: 'column',
  alignItems: 'flex-start',

  '.MuiAccordion-root': {
    overflow: 'visible!important',
  },

  '.filters-container': {
    flexWrap: 'wrap',
  },

  '.filter-container': {
    whiteSpace: 'nowrap',
  },
}));

export const Accordion = styled(AccordionComponent)(() => ({
  width: '100%',
}));

export const GridContainer = styled.div({
  width: '100%',
  display: 'grid',

  '.data-grid-table-container.sticky .data-grid-table-header-container': {
    position: 'initial',
  },
});
