import { useState } from 'react';
import { ExpenseActions } from '@containers/Expenses/components/molecules/ExpenseActions';
import { ExpenseDetailsCollapse } from '@containers/Expenses/components/molecules/ExpenseDetailsCollapse';
import { LinkedReport } from '@containers/Expenses/components/molecules/LinkedReport';
import { ReceiptsImages } from '@containers/Expenses/components/molecules/ReceiptsImages';
import { ESizeVariant } from '@containers/Expenses/components/molecules/ReceiptsImages/variants';
import { useExpenseById } from '@containers/Expenses/hooks/useExpenseById';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { OccurrencesBox } from '@molecules/OccurrencesBox';
import { Skeleton } from '@mui/material';
import { useExpenseRegisterDriver } from '@shared/hooks/navigation/useExpenseRegisterDriver';
import { EAccessScreen } from '@shared/types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ConfirmationModalDeleteExpense } from '../ConfirmationModalDeleteExpense';
import { DelegateUserView } from '../ExpenseActions/DelegateUserView/DelegateUserView';
import { useDeleteExpense } from '../ExpenseGrid/ExpenseGridActions/hooks/useDeleteExpense';
import { ExpenseTypeDetails } from './ExpenseTypeDetails/ExpenseTypeDetails';
import { useExpenseTitle } from './hooks/useExpenseTitle';
import * as SC from './styled';

export const ExpenseDetailsPreview = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { expense, isLoading, customFields } = useExpenseById({ id: parseInt(id) });
  const expenseTitle = useExpenseTitle(expense);
  const {
    onDeleteExpense,
    onConfirmDeleteExpenseClick,
    onCancelDeleteExpenseClick,
    deleteConfirmationIsOpen,
    deleteIsLoading,
  } = useDeleteExpense({ accessScreen: EAccessScreen.EXPENSE_DETAILS });
  const [showOccurrences, setShowOccurrences] = useState(true);
  const navigateExpenseEdit = useExpenseRegisterDriver();

  return (
    <SC.Container>
      <SC.ExpenseTopContainer>
        {isLoading ? (
          <Skeleton variant="text" width={155} height={50} />
        ) : (
          <Typography variant="headline6">{expenseTitle}</Typography>
        )}
        <SC.ActionsContainer>
          <DelegateUserView />
          <ExpenseActions
            expense={expense}
            onDeleteExpenseClick={() => onDeleteExpense([expense?.id])}
            onEditExpenseClick={() => {
              navigateExpenseEdit({ expenseId: expense?.id, expenseType: expense?.type });
            }}
          />
        </SC.ActionsContainer>
      </SC.ExpenseTopContainer>
      {expense.violationNumber > 0 && !isLoading && showOccurrences && (
        <SC.OccurrencesContainer>
          <OccurrencesBox
            occurrences={expense?.violations}
            title={`${t('organisms.expenseDetails.occurrences.title')} ${expense.violationNumber} ${t(
              `organisms.expenseDetails.occurrences.${
                expense.violationNumber != 1 ? 'pluralForm' : 'singularForm'
              }.occurrences`,
            )}`}
            onClose={() => setShowOccurrences(false)}
          />
        </SC.OccurrencesContainer>
      )}
      <SC.ExpenseMidContainer>
        {expense?.attachmentsNumber > 0 && (
          <ReceiptsImages
            receiptsImages={expense.attachments.map(({ path, contentType, base64, url }) => ({
              contentType,
              base64,
              path,
              previewUrl: url,
            }))}
            previewOnly
            size={ESizeVariant.small}
            isLoading={isLoading}
          />
        )}
        <SC.ExpenseInfoContainer>
          <ExpenseDetailsCollapse expense={expense} isLoading={isLoading} customFields={customFields} />
          <LinkedReport report={expense?.report} isLoading={isLoading} expenseId={expense?.id} />
        </SC.ExpenseInfoContainer>
      </SC.ExpenseMidContainer>
      <ExpenseTypeDetails expense={expense} isLoading={isLoading} />
      <ConfirmationModalDeleteExpense
        isOpen={deleteConfirmationIsOpen}
        expensesNumber={1}
        isLoading={deleteIsLoading}
        onActionClick={onConfirmDeleteExpenseClick}
        onCloseClick={onCancelDeleteExpenseClick}
      />
    </SC.Container>
  );
};
