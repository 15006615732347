import { Dispatch, SetStateAction, useState } from 'react';
import { useCustomApprovalFlows } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useCustomApprovalFlows';
import { usePriorityCustomApprovalFlow } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/usePriorityCustomApprovalFlow';
import { ISummaryApprovalFlow } from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import { BasicInformationApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { ReorderingGrid } from '@organisms/ReorderingGrid/ReorderingGrid';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useFormContext } from 'react-hook-form';

import * as SC from './styled';

interface IProps {
  id: number;
  open: boolean;
  onClose: () => void;
  setPrioritiesModalOpen: Dispatch<SetStateAction<boolean>>;
}

enum EApprovalType {
  MANUAL = 'manual',
  AUTOMATIC_APPROVAL = 'automaticApproval',
  AUTOMATIC_REFUSAL = 'automaticRefusal',
}

export interface EFlowPriorities {
  id: number;
  priority: number;
}

export function ReorderingModal({ id, open, onClose, setPrioritiesModalOpen }: IProps) {
  const t = useTranslate('approvalFlows');
  const { approvalFlows, isLoading } = useCustomApprovalFlows();
  const [currentApprovalFlows, setCurrentApprovalFlows] = useState<EFlowPriorities[]>();
  const { handleSave, isLoading: handleSaveIsLoading } = usePriorityCustomApprovalFlow();
  const { setValue } = useFormContext<BasicInformationApprovalFlowForm>();

  function onChange(flows: ISummaryApprovalFlow[]) {
    const _flows = flows.map((flow, index) => {
      if (id && id === flow.id) {
        setValue('priority', index + 1);
      }

      return {
        id: flow.id,
        priority: index + 1,
      };
    });

    setCurrentApprovalFlows(_flows);
  }

  async function onSave() {
    try {
      await handleSave(currentApprovalFlows);
      setPrioritiesModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <DefaultModal
      open={open}
      onCloseClick={onClose}
      onActionClick={onSave}
      headerTitle={t('reorderingModal.title')}
      headerSubtitle={t('reorderingModal.caption')}
      labelButtonAction={t('reorderingModal.save')}
      isActionLoading={handleSaveIsLoading}>
      <SC.Container>
        {isLoading ? (
          <Skeleton variant="rounded" height={400} width={600} />
        ) : (
          <SC.Content>
            <ReorderingGrid
              gridIdentifier="approval-flow-custom"
              columns={[
                { accessor: 'priority', content: t('grid.header.columns.priority') },
                { accessor: 'name', content: t('grid.header.columns.name') },
                { accessor: 'type', content: t('grid.header.columns.approvalType') },
              ]}
              rows={
                approvalFlows.map(flow => ({
                  id: flow.id,
                  priority: flow.priority,
                  name: flow.name,
                  type: t(`grid.body.approvalType.${EApprovalType[flow.type]}`),
                })) || [
                  {
                    priority: '',
                    name: '',
                    type: '',
                  },
                ]
              }
              onChange={onChange}
            />
          </SC.Content>
        )}
      </SC.Container>
    </DefaultModal>
  );
}
