import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

interface IProps {
  distance: string;
  isLoading: boolean;
  ratePerKM: string;
}

export function DistanceRoutesPrimary({ distance, ratePerKM, isLoading }: IProps) {
  const { t } = useTranslation();

  return (
    <SC.Container>
      <SC.Content>
        <SC.InfoContainer>
          <SC.Label variant="body3">{t('molecules.distanceRoutes.distance')}</SC.Label>
          <SC.Value variant="body3">
            {isLoading ? <SC.LoaderDistance variant="primary" size="extraSmall" /> : `${distance} km`}
          </SC.Value>
        </SC.InfoContainer>
        <SC.InfoContainer>
          <SC.Label variant="body3">{t('molecules.distanceRoutes.ratePerKM')}</SC.Label>
          <SC.Value variant="body3">
            {isLoading ? <SC.LoaderDistance variant="primary" size="extraSmall" /> : ratePerKM}
          </SC.Value>
        </SC.InfoContainer>
      </SC.Content>
    </SC.Container>
  );
}
