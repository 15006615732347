import { useState } from 'react';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { mutate } from 'swr';

import { ISummaryApprovalFlow } from '../../types/approvalFlow';
import { useTargetCustomApprovalFlow } from '../useTargetCustomApprovalFlow';

interface IResponse {
  id: ISummaryApprovalFlow['id'];
  error: boolean;
  message: string;
}

export function useToggleCustomApprovalFlows(approvalFlows: ISummaryApprovalFlow[]) {
  const t = useTranslate('approvalFlows.feedbacks.toggle');
  const { mutation } = useMutate('v1/approvals');
  const [listIdsLoading, setListIdsLoading] = useState<ISummaryApprovalFlow['id'][]>([]);
  const target = useTargetCustomApprovalFlow();

  async function toggleCustomApprovalFlows(toggle: boolean, ids: ISummaryApprovalFlow['id'][]) {
    const result: IResponse[] = await mutation.trigger({
      path: `toggle`,
      options: {
        method: 'PATCH',
        body: JSON.stringify({ active: toggle, ids: ids }),
      },
    });
    return result;
  }

  async function setToggleCustomApprovalFlows(ids: ISummaryApprovalFlow['id'][] = [], active: boolean) {
    setListIdsLoading([...listIdsLoading, ...ids]);
    try {
      const response = await toggleCustomApprovalFlows(active, ids);
      const _listIdsLoading = listIdsLoading;
      const approvalFlowsError = response?.filter(_approvalFlow => _approvalFlow.error);
      const approvalFlowsSuccess = response.filter(_approvalFlow => !_approvalFlow.error);

      ids.forEach(id => {
        const index = _listIdsLoading?.findIndex(_id => _id === id);
        _listIdsLoading?.splice(index, 1);
      });

      if (approvalFlowsError?.length) {
        approvalFlowsError?.forEach(approvalFlow => {
          const approvalFlowName =
            approvalFlows?.find(_approvalFlow => _approvalFlow.id == approvalFlow.id)?.name ?? '';
          showToast({
            message: `${approvalFlow.error ? approvalFlow.message : t('error')}: ${approvalFlowName ?? ''}`,
            type: 'error',
          });
        });
      }

      if (approvalFlowsSuccess.length) {
        approvalFlowsSuccess?.length;
        showToast({
          message: t(`success.${approvalFlowsSuccess?.length > 1 ? 'plural' : 'singular'}`),
          type: 'success',
        });
      }

      mutate(`v1/approvals/${target}`);
      setListIdsLoading(_listIdsLoading);
    } catch (_error) {
      showToast({
        message: _error.message,
        type: 'error',
      });
      setListIdsLoading([]);
    }
  }

  return {
    setToggleCustomApprovalFlows,
    error: mutation?.error,
    isLoading: mutation?.isMutating,
    listIdsLoading,
  };
}
