import { useState } from 'react';
import { IReportSummaries } from '@containers/Reports/context/types';

export interface UseFinancialReportSelectionRowsProps {
  reports: IReportSummaries[];
}

export const useFinancialReportSelectionRows = ({ reports }: UseFinancialReportSelectionRowsProps) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const isSelectedAll: boolean = selectedItems?.length == reports?.length;

  function onSelectAllClick() {
    setSelectedItems(reports.map(e => e.id));
  }

  function unSelectAllClick() {
    setSelectedItems([]);
  }

  function toggleSelectAll() {
    if (isSelectedAll) unSelectAllClick();
    else onSelectAllClick();
  }

  function onSelectItemClick(itemId: number) {
    const isIdSelected = selectedItems.includes(itemId);

    if (isIdSelected) {
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  }

  return {
    selectedItems,
    isSelectedAll,
    onSelectItemClick,
    onSelectAllClick,
    unSelectAllClick,
    toggleSelectAll,
  };
};
