import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import { IEmployeeSelection } from './types/employees';

interface EmployeeSelectionList {
  employees: IEmployeeSelection[];
  total: number;
  isLoading: boolean;
  error: unknown;
}

export function useEmployeesSelectionList(): EmployeeSelectionList {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const { data, error, isLoading } = useFetch<EmployeeSelectionList>(`v1/employees`, '', undefined, undefined, onError);

  return {
    employees: data?.employees || [],
    total: data?.total,
    isLoading,
    error,
  };
}
