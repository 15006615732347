import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { mutate } from 'swr';

export function useProjectEmployees() {
  const { t } = useTranslation();

  const projectMutate = useMutate('v1/projects', () =>
    showToast({
      message: t('pages.projectRegister.feedbacks.updated.error'),
      type: 'error',
    }),
  );

  async function addProjectEmployee(projectId: string, employees: string[]) {
    const body = { projectId, employees };
    await projectMutate.mutation.trigger(
      {
        path: `${projectId}/employees`,
        options: {
          method: 'PATCH',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
        onSuccess: () =>
          showToast({
            message: t('pages.projectRegister.feedbacks.update.success'),
            type: 'success',
          }),
      },
    );

    mutate(key => typeof key === 'string' && key.includes('v1/projects?page='));
  }

  async function removeProjectEmployee(projectId: string, employees: string[]) {
    await projectMutate.mutation.trigger(
      {
        path: `${projectId}/employees`,
        options: {
          method: 'DELETE',
        },
        parameters: employees.map(_ => {
          return { key: 'employees[]', value: _ };
        }),
      },
      {
        revalidate: false,
        onSuccess: () =>
          showToast({
            message: t('pages.projectRegister.feedbacks.update.success'),
            type: 'success',
          }),
      },
    );

    mutate(key => typeof key === 'string' && key.includes('v1/projects?page='));
  }

  async function handle({
    projectId,
    currentProjectEmployees = [],
    newAProjectEmployees = [],
  }: {
    projectId: string;
    currentProjectEmployees?: string[];
    newAProjectEmployees?: string[];
  }) {
    const usersToBeAdded: string[] = [];
    const usersToBeRemoved: string[] = [];

    newAProjectEmployees.forEach(newAllowedUser => {
      const hasUserToAdd = !currentProjectEmployees.includes(newAllowedUser);
      hasUserToAdd && usersToBeAdded.push(newAllowedUser);
    });
    currentProjectEmployees.forEach(currentAllowedUser => {
      const hasUserToRemove = !newAProjectEmployees.includes(currentAllowedUser);
      hasUserToRemove && usersToBeRemoved.push(currentAllowedUser);
    });

    if (usersToBeAdded.length > 0) {
      try {
        await addProjectEmployee(projectId, usersToBeAdded);
      } catch (error) {
        showToast({ message: error.message, type: 'error' });
      }
    }

    if (usersToBeRemoved.length > 0) {
      try {
        await removeProjectEmployee(projectId, usersToBeRemoved);
      } catch (error) {
        showToast({ message: error.message, type: 'error' });
      }
    }
  }

  return {
    handle,
    isLoading: projectMutate.mutation.isMutating,
  };
}
