import { Tab } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-bottom: ${props => props.theme.spacings.xs1}; ;
`;

export const TabContainer = styled.div`
  width: calc(100% - 200px);
  display: grid;
  grid-column: ${props => props.theme.spacings.s};
  grid-row-gap: ${props => props.theme.spacings.s};
`;

export const Tabs = styled(Tab)`
  display: grid;
  grid-template-columns: 200px 100%;

  .data-grid-empty-table-container {
    min-width: auto;
  }

  .MuiAccordion-root {
    display: grid;
    overflow: auto;
  }

  .MuiAccordion-root.Mui-expanded {
    display: block;
  }

  .MuiAccordionSummary-content {
    text-align: left;
  }
`;

export const TabsInline = styled(Tab)`
  .MuiTab-root {
    text-transform: inherit;
    padding-bottom: ${props => props.theme.spacings.xs5};
  }
`;
