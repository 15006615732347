import { ESettingActions, ISettingDispatchActions } from '../actions';
import { ISettingsContextState } from '../types';

export const settingsReducer = (
  state: ISettingsContextState,
  action: ISettingDispatchActions,
): ISettingsContextState => {
  switch (action.type) {
    case ESettingActions.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload.categories ?? [],
      };

    case ESettingActions.GO_TO_STEP:
      return {
        ...state,
        step: action.payload.step,
      };

    case ESettingActions.GET_CATEGORY_LIMIT:
      return {
        ...state,
        limits: action.payload.limits,
      };

    case ESettingActions.GET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload.currencies,
      };

    default:
      throw new Error('Error in setting reducer');
  }
};
