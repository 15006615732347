import { useState } from 'react';
import MandatoryInfo from '@atoms/MandatoryInfo/MandatoryInfo';
import { StatusTag } from '@atoms/StatusTag';
import { IPolicy } from '@containers/Policies/context/types/types';
import { usePolicyMutate } from '@containers/Policies/hooks/dataSources/usePolicyMutate';
import { Dropzone, IconButton, Icons, Skeleton, TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { ImagesModal } from '@molecules/ImagesModal';
import { Grid } from '@mui/material';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { FieldRegister } from '../../molecules/FieldRegister/FieldRegister';
import * as SC from './styled';

export interface IProps {
  isLoading?: boolean;
  viewOnly?: boolean;
  policyId: IPolicy['id'];
}

export function BasicInformation({ isLoading, viewOnly, policyId }: IProps) {
  const t = useTranslate('policy.register.steps.basicInformation');
  const [openDocument, setOpenDocument] = useState(false);
  const { control, setValue } = useFormContext<IPolicy>();
  const { deleteDocument, isLoading: handleSaveIsLOading } = usePolicyMutate();
  const { colors } = useTheme();

  async function onDeleteDocument() {
    const response = await deleteDocument(policyId);
    if (response?.id) {
      setValue('document', null);
      setValue('file', null);
    }
  }

  return (
    <SC.Container>
      <SectionContainer title={t('title')} action={<MandatoryInfo />} showDivider={viewOnly}>
        <Grid container spacing={3} width={'100%'}>
          <Grid item xs={12}>
            <FieldRegister isLoading={isLoading}>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: true,
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={<MandatoryInfo label={t('fields.namePolicy')} />}
                    fullWidth
                    error={fieldState.invalid}
                    helperText={fieldState.invalid && t('fields.mandatory')}
                  />
                )}
              />
            </FieldRegister>
          </Grid>
          <Grid item xs={12}>
            <FieldRegister isLoading={isLoading} height={125}>
              <Controller
                control={control}
                name="description"
                rules={{
                  required: true,
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={<MandatoryInfo label={t('fields.description')} />}
                    fullWidth
                    rows={4}
                    multiline
                    error={fieldState.invalid}
                    helperText={fieldState.invalid && t('fields.mandatory')}
                  />
                )}
              />
            </FieldRegister>
          </Grid>
        </Grid>
      </SectionContainer>
      <SectionContainer
        title={
          <SC.TitleSectionDocument>
            {t('documentSection.title')}
            <StatusTag size="small" variant="neutral">
              {t('documentSection.optional')}
            </StatusTag>
          </SC.TitleSectionDocument>
        }
        caption={t('documentSection.caption')}>
        <SC.LabelDocument variant="body3">{t('documentSection.field')}</SC.LabelDocument>
        <Controller
          control={control}
          name="document"
          render={({ field }) => {
            return (
              <>
                {isLoading || handleSaveIsLOading ? (
                  <Skeleton width={'100%'} height={110} variant="rounded" />
                ) : (
                  <>
                    {!field.value?.type ? (
                      <Dropzone
                        title={t('documentSection.field')}
                        onChange={file => setValue('file', file[0]?.file)}
                        accept={['pdf']}
                      />
                    ) : (
                      <>
                        <SC.ContainerFile>
                          <SC.ContentFile>
                            <SC.SmallImageContainer>
                              {openDocument && (
                                <ImagesModal
                                  onCloseModalClick={() => setOpenDocument(false)}
                                  images={[
                                    {
                                      src: field.value?.base64,
                                      mime: field.value.type,
                                    },
                                  ]}
                                  isLoading={isLoading}
                                />
                              )}

                              <Icons name="IconFileDescription" size={25} color={colors.neutral[80]} stroke={'1px'} />
                            </SC.SmallImageContainer>
                            <SC.FileName variant="body3">
                              {field.value?.name ?? t('documentSection.documentName')}
                            </SC.FileName>
                          </SC.ContentFile>
                          <SC.ActionsFile>
                            <IconButton
                              variant="line"
                              size="small"
                              icon="IconArrowsDiagonal"
                              onClick={() => setOpenDocument(true)}
                            />
                            <IconButton variant="line" size="small" icon="IconTrash" onClick={onDeleteDocument} />
                          </SC.ActionsFile>
                        </SC.ContainerFile>
                      </>
                    )}
                  </>
                )}
              </>
            );
          }}
        />
      </SectionContainer>
    </SC.Container>
  );
}
