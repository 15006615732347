import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';

import { createTipMutation } from '../../context/queries/tips';

interface ICreateTip {
  data: {
    createTip: boolean;
  };
}

export const useTipsMutate = () => {
  const [createTipMutate, { loading }] = useMutation<ICreateTip>(createTipMutation);

  return {
    createTipMutate,
    loading,
  };
};
