import { expensesBaseUrl } from '@shared/constant';
import { Navigate, Outlet } from 'react-router-dom';

import { useCompanyAccessConfiguration } from './hooks/useCompanyAccessConfiguration';

export const RoutesGuardSubscription = ({ children }: { children?: JSX.Element }) => {
  const { termsSigned } = useCompanyAccessConfiguration();

  if (termsSigned) {
    return <Navigate to={expensesBaseUrl} />;
  }

  return children ? children : <Outlet />;
};
