import { CounterField } from '@atoms/CounterField/CounterField';
import { EReportExpirationDateTypeOfFrequencySettings } from '@containers/Customizations/context/types/settings/expenseReportSettingsTypes';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { useDayFrequencyOptions } from '../hooks/useDayFrequencyOptions';
import * as SC from './styled';

interface IProps {
  type: EReportExpirationDateTypeOfFrequencySettings;
  value: number[];
  isDisabled: boolean;
  onChange(value: IProps['value']): void;
}

export function PeriodFrequency({ type, value, isDisabled = false, onChange }: IProps) {
  const t = useTranslate('organisms.generalReportSettings.expirationDate.configurationModal.fields');
  const { daysOfMonthOptions, daysOfTheWeekOptions } = useDayFrequencyOptions();

  function isDayOfTheWeek(): boolean {
    return type === EReportExpirationDateTypeOfFrequencySettings.DAY_OF_THE_WEEK;
  }

  function onSelectValue(data) {
    let currentValue: number[] = [];

    if (Array.isArray(data)) {
      currentValue = data?.length ? data?.map(_data => Number(_data?.value)) : [];
    } else {
      currentValue = !isNaN(data?.value) ? [data?.value] : [];
    }
    onChange(currentValue);
  }

  if (type === EReportExpirationDateTypeOfFrequencySettings.DAYS) {
    return (
      <CounterField
        disabled={isDisabled}
        value={value?.[0] || 0}
        minValue={1}
        maxValue={30}
        onChange={data => onChange([data])}
      />
    );
  }

  return (
    <SC.Select
      disabled={isDisabled}
      label={t(isDayOfTheWeek() ? 'weeklyFrequency' : 'frequency')}
      options={isDayOfTheWeek() ? daysOfTheWeekOptions : daysOfMonthOptions}
      multiple={!isDayOfTheWeek()}
      defaultValue={isDayOfTheWeek() ? value?.[0] : value}
      onSelectChange={(_, data) => onSelectValue(data)}
    />
  );
}
