import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Line = styled.span`
  display: block;
  border-top: ${props => props.theme.borders.width.xs2} solid ${props => props.theme.colors.neutral[90]};
  width: 100%;
`;

export const Container = styled(BorderedWrapping)`
  width: 100%;
  padding: ${props => props.theme.spacings.xs};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.xs};
`;

export const Title = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
  font-weight: 700;
`;

export const SubTitle = styled(Typography)`
  color: ${props => props.theme.colors.neutral[30]};
`;
