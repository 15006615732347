import { IConfigurationCategory } from '@containers/Customizations/context/types/categoryTypes';
import { IPolicy } from '@containers/Policies/context/types/types';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';

export function useCategoryMutate() {
  const { mutation } = useMutate('v1/categories/policy');

  async function handleSave(ids: IConfigurationCategory['id'][], policyId: IPolicy['id']) {
    try {
      const response: { categories: IConfigurationCategory[] } = await mutation.trigger({
        path: `${policyId}`,
        options: {
          method: 'PUT',
          body: JSON.stringify({ categoryIds: ids || [] }),
        },
      });

      return response;
    } catch (_error) {
      showToast({
        message: _error?.message,
        type: 'error',
      });
    }
  }

  return {
    handleSave,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
