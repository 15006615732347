import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: ${props => props.theme.spacings.xs2};
`;

export const ToggleContainer = styled.div`
  display: grid;
  grid-template-columns: 48px 290px;
  grid-row-gap: ${props => props.theme.spacings.xs2};
  gap: ${props => props.theme.spacings.xs3};
  align-items: center;
`;

export const ToggleLabel = styled(Typography)`
  color: ${props => props.theme.colors.neutral[40]};
  font-weight: 600 !important;
`;

export const FieldDays = styled(TextField)`
  width: 182px;
`;
