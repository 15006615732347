import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.secondary[99]};
  border-radius: ${props => props.theme.borders.radius.s};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacings.xs};
`;

export const Content = styled.div`
  display: grid;
  row-gap: ${props => props.theme.spacings.xs2};
  align-items: center;
`;

export const Icon = styled(Icons)`
  color: ${props => props.theme.colors.secondary[50]};
`;

export const Description = styled(Typography)`
  color: ${props => props.theme.colors.secondary[50]};
  font-weight: 600 !important;
  text-align: center;
`;

export const ContainerIcon = styled.div`
  height: 48px;
  width: 48px;
  background-color: ${props => props.theme.colors.secondary[95]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: ${props => props.theme.borders.radius.circular};
`;
