import { useState } from 'react';
import { IReport } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { OccurrencesBox } from '@molecules/OccurrencesBox';

import * as SC from './styled';

interface IProps {
  report: IReport;
}

export function ReportViolationsAndFeedback({ report }: IProps) {
  const { t } = useTranslation();
  const [showViolations, setShowViolations] = useState(true);

  return (
    <>
      {report?.reproved?.message && (
        <SC.OccurrencesContainer>
          <OccurrencesBox
            occurrences={{ message: report?.reproved?.message }}
            onClose={() => null}
            title={t('report.reprovalMessageTitle')}
            showClose={false}
            customIcon="IconCircleX"
          />
        </SC.OccurrencesContainer>
      )}
      {showViolations && report?.expensesViolations?.length > 0 && (
        <SC.OccurrencesContainer>
          <OccurrencesBox
            occurrences={report.expensesViolations?.reduce((result, expenseViolations) => {
              return [...result, ...expenseViolations.violations.map(violation => violation)];
            }, [])}
            onClose={() => setShowViolations(false)}
            title={t(
              `organisms.reportDetailsPreview.occurrences.${
                report.violationNumber !== 1 ? 'pluralForm' : 'singularForm'
              }.title`,
              { occurrencesNumber: report.violationNumber },
            )}
            showClose={true}
          />
        </SC.OccurrencesContainer>
      )}
    </>
  );
}
