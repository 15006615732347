import { ICategoryWithPolicies } from '@containers/Expenses/context/types/categories';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

interface IProps {
  projectId?: string;
  policyId?: string;
  shouldFetch?: boolean;
}

interface IResponse {
  categories: ICategoryWithPolicies[];
}

export function useCategoriesWithPolicies({ projectId, policyId, shouldFetch = true }: IProps) {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  function getPath() {
    let path = 'v1/categories/policy';

    projectId && (path = `${path}?projectId=${projectId}`);
    policyId && (path = `${path}${projectId ? '&' : '?'}policyId=${policyId}`);

    return path;
  }

  const { data, error, isLoading } = useFetch<IResponse>(
    getPath(),
    '',
    undefined,
    {
      keepPreviousData: true,
    },
    onError,
    shouldFetch,
  );

  return {
    categories: data?.categories || [],
    isLoading: isLoading,
    error,
  };
}
