import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import { ICostCenterCompany } from '../../context/types/costCenters';

interface IProps {
  page: number;
  searchName?: string;
}

interface IGetCostCenterSettings {
  costCenters: ICostCenterCompany[];
  total: number;
}

export const useAllCostCenters = ({ page, searchName = '' }: IProps) => {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });
  const params = `page=${page}${searchName && '&searchName=' + searchName}`;

  const { data, error, isLoading, mutate } = useFetch<IGetCostCenterSettings>(
    `v1/cost-centers?${params}`,
    '',
    undefined,
    undefined,
    onError,
  );

  return {
    error,
    isLoading: isLoading,
    costCentersCompany: data?.costCenters ?? [],
    total: data?.total ?? 0,
    refetch: mutate,
  };
};
