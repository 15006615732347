import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs};
`;

export const Content = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs};
  margin-left: 48px;
`;

export const ConditionalTypeFiledContainer = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs3};
  width: 360px;
`;

export const Divider = styled.hr`
  width: 100%;
  margin: 0;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;
