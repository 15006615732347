import { AddClientsButton } from '@containers/Clients/components/organisms/AddClientsButton/AddClientsButton';
import { ClientsPreview } from '@containers/Clients/components/organisms/ClientsPreview';
import { BasePage } from '@organisms/BasePage/BasePage';
import { PermissionsChecker } from '@organisms/PermissionsChecker/PermissionsChecker';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export const Clients = () => {
  const t = useTranslate('clients.page');

  return (
    <PermissionsChecker licenseFeature="INTERMEDIATE_CLIENT">
      <BasePage
        headerPage={{
          title: t('title'),
          caption: t('caption'),
          actions: <AddClientsButton />,
          breadcrumbItems: [
            {
              to: '/settings',
              label: t('breadcrumb.manageCompany'),
            },
            {
              to: '',
              label: t('title'),
            },
          ],
        }}>
        <ClientsPreview />
      </BasePage>
    </PermissionsChecker>
  );
};
