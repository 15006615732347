import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  color: ${props => props.theme.colors.neutral.dark.dark3};
`;

export const LinkContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ArrowRightIcon = styled(Icons)`
  color: ${props => props.theme.colors.primary};
`;
