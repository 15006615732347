import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import * as SC from './styled';

interface IProps {
  label: string;
  value?: string;
  isLoading: boolean;
  disabled?: boolean;
  onChange(value: string): void;
}

export function Search({ label, value, isLoading, disabled = false, onChange }: IProps) {
  const [inputFilterSearchChange, setInputFilterChange] = useState<string>(value || '');
  const [inputValueSearch] = useDebounce(inputFilterSearchChange, 250);

  useEffect(() => {
    inputValueSearch !== undefined && onChange(inputValueSearch);
  }, [inputValueSearch]);

  useEffect(() => {
    value !== undefined && setInputFilterChange(value);
  }, [value]);

  return !isLoading ? (
    <SC.Search
      label={label}
      disabled={disabled}
      onInputChange={(_, value) => setInputFilterChange(value)}
      value={inputValueSearch}
    />
  ) : (
    <SC.SearchSkeleton width={'100%'} height={60} />
  );
}
