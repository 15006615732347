import { useContext, useEffect, useMemo, useState } from 'react';
import { Loader } from '@atoms/Loader';
import { ETabCustomization } from '@containers/Customizations/components/organisms/CustomizationsPreview';
import { BasicInformation } from '@containers/Policies/componentes/organisms/BasicInformation/BasicInformation';
import { PolicyProperties } from '@containers/Policies/componentes/organisms/PolicyProperties/PolicyProperties';
import { EPolicyLevel, IPolicy } from '@containers/Policies/context/types/types';
import { useCategoryMutate } from '@containers/Policies/hooks/dataSources/useCategoryMutate';
import { usePolicyById } from '@containers/Policies/hooks/dataSources/usePolicyById';
import { usePolicyMutate } from '@containers/Policies/hooks/dataSources/usePolicyMutate';
import { BasePage } from '@organisms/BasePage/BasePage';
import { expensesCustomizationsBaseURL } from '@shared/constant';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { base64ToObjectURL, toDataUrl } from '@shared/helpers/files';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { RegisterPolicyBasePage } from './RegisterPolicyBasePage/RegisterPolicyBasePage';
import { RegisterPolicyFooter } from './RegisterPolicyFooter/RegisterPolicyFooter';
import { RegisterPolicyProgress } from './RegisterPolicyProgress/RegisterPolicyProgress';
import * as SC from './styled';

export function RegisterPolicy() {
  const navigate = useNavigate();
  const t = useTranslate('policy.register');
  const { id } = useParams();
  const [policyId, setPolicyId] = useState<IPolicy['id']>(id);
  const [step, setStep] = useState(0);
  const { policy, isLoading } = usePolicyById(policyId);
  const policyForm = useForm<IPolicy>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: {
      conditions: [{ key: null, value: '' }],
      enabled: true,
    },
  });
  const { handleSave, conditionalSave, sendDocument, isLoading: handleSaveIsLOading } = usePolicyMutate();
  const [categoriesSelected, setCategoriesPolicySelected] = useState<string[]>([]);
  const { handleSave: handleSaveCategories, isLoading: categoriesIsLoading } = useCategoryMutate();
  const { checkLicenseFeature } = useLicenseFeatures();
  const isControlPlan = useMemo(() => checkLicenseFeature('ADVANCED_EXPENSE'), []);
  const { companyName } = useContext(SessionContext);

  async function onSubmit(data?: IPolicy) {
    if (step === 0) {
      const response = await handleSave(
        {
          description: data.description,
          name: data.name,
          enabled: data.enabled,
        },
        policyId,
      );

      if (response?.id) {
        if (data.file) {
          const uploading = await sendDocument(data.file, response.id);
          if (uploading?.id) {
            const base64 = await toDataUrl(data.file);
            const base64URL = await base64ToObjectURL(base64);

            policyForm.setValue('document', {
              name: data.file.name ?? '',
              type: data.file.type,
              base64: base64URL,
            });
            toDataUrl(data.file);
          } else {
            return;
          }
        }
        setPolicyId(response?.id || id || null);
        setStep(step + 1);
      }
    } else if (step === 1) {
      const conditionalResponse = isControlPlan ? await conditionalSave(data.conditions ?? [], policyId) : null;
      const categoriesResponse = await handleSaveCategories(categoriesSelected ?? [], policyId);

      if (
        (isControlPlan && conditionalResponse?.conditions && categoriesResponse.categories) ||
        (!isControlPlan && categoriesResponse.categories)
      )
        navigate(`${expensesCustomizationsBaseURL}?customizationTab=${ETabCustomization.POLICIES}`);
    }
  }

  useEffect(() => {
    if (policy && !isLoading) {
      return policyForm.reset({
        ...policy,
        conditions: policy.conditions?.length
          ? policy.conditions
          : [
              {
                key: !isControlPlan ? EPolicyLevel.COMPANY : null,
                value: !isControlPlan ? companyName : '',
              },
            ],
        enabled: Boolean(id) ? policy.enabled : true,
      });
    }
  }, [policy, isLoading]);

  return (
    <>
      <FormProvider {...policyForm}>
        <form onSubmit={policyForm.handleSubmit(onSubmit)}>
          <SC.Container>
            <RegisterPolicyProgress isEditing={Boolean(policyId)} step={step} />
            <BasePage
              headerPage={{
                title: t(`title.${!Boolean(policyId) ? 'add' : 'edit'}`),
              }}
              footerPage={
                <RegisterPolicyFooter
                  isLoading={isLoading || handleSaveIsLOading || categoriesIsLoading}
                  step={step}
                  setStep={setStep}
                />
              }>
              <RegisterPolicyBasePage step={step} id={policyId}>
                {step === 0 && <BasicInformation isLoading={isLoading} policyId={policyId} />}
                {step === 1 && (
                  <PolicyProperties setCategoriesPolicySelected={setCategoriesPolicySelected} policyId={policyId} />
                )}
              </RegisterPolicyBasePage>
            </BasePage>
          </SC.Container>
        </form>
      </FormProvider>
      <Loader show={false} />
    </>
  );
}
