import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

type InvertableElementProps = {
  invert: boolean;
};

export const Container = styled('div')<InvertableElementProps>`
  display: flex;
  flex-direction: ${props => (props.invert ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: flex-end;
`;

export const DescriptionActions = styled(Typography)<InvertableElementProps>`
  color: ${props => props.theme.colors.feedback.error.dark1};
  margin-left: ${props => (props.invert ? props.theme.spacings.xs5 : 0)};
  margin-right: ${props => props.theme.spacings.xs5};
  word-wrap: break-word;
  white-space: nowrap;
`;

export const ActionIcon = styled(Icons)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.neutral.light.light5};
  fill: ${props => props.theme.colors.neutral.light.pure};
  height: ${props => props.theme.icons.sizes.xs2};
  width: ${props => props.theme.icons.sizes.xs2};
  margin-left: ${props => props.theme.spacings.xs5};
  margin-right: ${props => props.theme.spacings.xs5};
`;
