import { EExpenseStatus, EExpenseType } from '@containers/Expenses/context/types';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { Menu } from '@flash-tecnologia/hros-web-ui-v2';
import { DropdownItem } from '@molecules/DropdownItem';
import { IExpensesAllowedActions } from '@shared/hooks/permissions/systemPermissions/useExpensesPermissions';
import { useTranslation } from 'react-i18next';

import * as SC from '../styled';

export interface Action {
  children: React.ReactNode;
  onClick(): void;
}

export enum ActionOptions {
  VIEW = 'view',
  EDIT = 'edit',
  UNLINK = 'unlink',
  DELETE = 'delete',
}

export enum IconAction {
  VIEW = 'IconEye',
  EDIT = 'IconPencil',
  UNLINK = 'IconUnlink',
  DELETE = 'IconTrash',
}

interface ExpenseGridActionsProps {
  id: IExpense['id'];
  expenseType: EExpenseType;
  status?: EExpenseStatus;
  allowedActions?: IExpensesAllowedActions;
  onView?(id: IExpense['id'], expenseType: EExpenseType): void;
  onEdit?(id: IExpense['id'], expenseType: EExpenseType): void;
  onUnlink?(id: IExpense['id']): void;
  onDelete?(id: IExpense['id']): void;
}

export function ExpenseGridActions({
  id,
  expenseType,
  allowedActions,
  onView,
  onEdit,
  onUnlink,
  onDelete,
}: ExpenseGridActionsProps) {
  const { t } = useTranslation();
  const options: Action[] = [];

  const events = {
    view: () => onView?.(id, expenseType),
    edit: () => onEdit?.(id, expenseType),
    unlink: () => onUnlink?.(id),
    delete: () => onDelete?.(id),
  };

  Object.keys(ActionOptions).forEach((action): void => {
    const currentAllowedAction = allowedActions?.[ActionOptions[action]];

    if (currentAllowedAction != undefined ? currentAllowedAction : true) {
      options.push({
        children: (
          <DropdownItem
            icon={IconAction[action]}
            description={t(`organisms.expenseGrid.actionsGrid.singularForm.${ActionOptions[action]}`)}
          />
        ),
        onClick: () => events[ActionOptions[action]](),
      });
    }
  });

  return (
    <Menu
      type="default"
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      options={options}>
      <SC.IconDots />
    </Menu>
  );
}
