import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ActionsFeedbackContainer = styled.div`
  p {
    font-size: 14px;
  }
`;

export const ThDescription = styled(Typography).attrs({
  variant: 'body4',
})`
  font-weight: 700;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const Description = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const TdContent = styled.div`
  display: flex;
  align-items: center;
`;
export const CategoryInfosContainer = styled.div`
  padding-left: ${p => p.theme.spacings.xs3};
`;

export const CategoryDescription = styled(Typography)`
  font-weight: 600;
  word-wrap: break-word;
  white-space: nowrap;
`;
