import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import styled from 'styled-components';

export const HeaderStepsContainer = styled.div``;

export const CarouselContainer = styled.div``;

export const Modal = styled(DefaultModal)`
  display: flex;

  .large-modal {
    max-width: 871px;
  }
`;
export const SubtitleContainer = styled.span`
  display: flex;
  gap: 5px;
`;

export const Link = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  font-weight: 600;
`;

export const Gif = styled.img`
  max-width: 650px;
`;

export const StepperComponent = styled(Stepper)`
  margin-bottom: ${props => props.theme.spacings.xs};
`;
