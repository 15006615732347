import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { IconButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const FileLoadContainer = styled(BorderedWrapping)`
  align-items: center;
  border-color: ${props => props.theme.colors.feedback.success[40]};
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 535px;
  margin-top: ${props => props.theme.spacings.xs2};
  padding: ${props => props.theme.spacings.m} 0;
  width: 100%;
`;

export const FileLoadTitle = styled(Typography)`
  font-weight: 700 !important;
`;

export const FileLoadSubTitle = styled(Typography)`
  color: ${props => props.theme.colors.neutral[0]};
`;

export const DeleteButton = styled(IconButton).attrs({
  variant: 'filled',
  size: 'medium',
})`
  align-self: unset !important;
`;

export const ProgressBarContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${props => props.theme.spacings.xs3};
  max-width: 345px;
  width: 100%;
`;

export const ProgressBar = styled(LinearProgress)`
  overflow: visible;
  max-width: 290px;
  width: 100%;

  .MuiLinearProgress-bar1Determinate {
    background: ${props => props.theme.colors.feedback.success[40]};
    border-radius: ${props => props.theme.borders.radius.xs};
    height: 4px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextButtonContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs3};
  margin-bottom: ${props => props.theme.spacings.xs3};
`;
