import { Switch } from '@atoms/Switch';
import { IconButton, Loader } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

interface SwitchCardProps {
  isActive?: boolean;
  label: string;
  handleIsActive(): void;
  onEdit?(): void;
  onDelete?(): void;
  isLoading: boolean;
  allowedActions?: boolean;
}

export const SwitchCard = ({
  isActive,
  label,
  handleIsActive,
  onEdit,
  onDelete,
  isLoading,
  allowedActions = true,
}: SwitchCardProps) => {
  let actionsQuantity = 0;
  onEdit && actionsQuantity++;
  onDelete && actionsQuantity++;
  return (
    <SC.Container isLoading={isLoading}>
      <SC.FormControlLabel
        actions={actionsQuantity}
        control={
          isLoading ? (
            <SC.LoaderContainer>
              <Loader size="extraSmall" variant="primary" />
            </SC.LoaderContainer>
          ) : (
            <Switch checked={isActive} onChange={handleIsActive} disabled={isLoading || !allowedActions} />
          )
        }
        label={
          <SC.Label isLoading={isLoading} variant="body3">
            {label}
          </SC.Label>
        }
      />
      <div className="buttons-container">
        {allowedActions && onEdit && (
          <IconButton icon="IconPencil" size="small" variant="line" onClick={onEdit} disabled={isLoading} />
        )}
        {allowedActions && onDelete && (
          <IconButton icon="IconTrash" size="small" variant="line" onClick={onDelete} disabled={isLoading} />
        )}
      </div>
    </SC.Container>
  );
};
