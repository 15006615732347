import { gql } from '@apollo/client';

export const getAdvances = gql`
  query GetAdvances(
    $page: Int!
    $limit: Int!
    $status: [AdvanceStatus!]
    $reportId: Int
    $isOpenToAttach: Boolean! = false
    $paymentMethod: [Int!]
    $startDate: String
    $endDate: String
    $referenceId: String
  ) {
    getAdvances(
      page: $page
      limit: $limit
      status: $status
      isOpenToAttach: $isOpenToAttach
      reportId: $reportId
      paymentMethod: $paymentMethod
      startDate: $startDate
      endDate: $endDate
      referenceId: $referenceId
    ) {
      advances {
        id
        referenceId
        status
        advanceDate
        value
        currencyCode
        currencyPrefix
        violationNumber
        approver {
          id
          name
          email
        }
        description
        user {
          id
          name
          email
        }
        report {
          referenceId
          id
          name
          status
        }
      }
      total
      currentPage
      pages
      limit
    }
  }
`;

export const deleteAdvancesMutation = gql`
  mutation DeleteAdvances($advanceIds: [Int!]!) {
    deleteAdvances(advanceIds: $advanceIds) {
      advances {
        id
        message
        error
      }
      total
    }
  }
`;

export const updateAdvancesStatusMutation = gql`
  mutation UpdateAdvancesStatus($advancesIds: [Int!]!, $status: AdvanceStatus!, $message: String) {
    updateAdvancesStatus(advancesIds: $advancesIds, status: $status, message: $message) {
      error
    }
  }
`;
