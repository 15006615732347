import { EReportStatus, IReportSummaries } from '@containers/Reports/context/types';
import { Loader, Menu } from '@flash-tecnologia/hros-web-ui-v2';
import { DropdownItem } from '@molecules/DropdownItem';
import { useTranslation } from 'react-i18next';

import * as SC from '../styled';

export interface Action {
  children: React.ReactNode;
  onClick(): void;
}

export enum ActionOptions {
  VIEW = 'view',
  APPROVE = 'approve',
  REPROVE = 'reprove',
  EXPORT_PDF = 'exportPdf',
  EXPORT_PDF_BY_CATEGORY = 'exportPdfByCategory',
}

export enum IconAction {
  VIEW = 'IconEye',
  APPROVE = 'IconThumbUp',
  REPROVE = 'IconThumbDown',
  EXPORT_PDF = 'IconFileExport',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  EXPORT_PDF_BY_CATEGORY = 'IconFileExport',
}

interface ApprovalsReportGridActionsProps {
  reportId?: number;
  status: EReportStatus;
  onViewClick?(id: IReportSummaries['id']): void;
  onReproveClick?(id: IReportSummaries['id']): void;
  onApproveClick?(id: IReportSummaries['id']): void;
  onExportPdfClick?(id: IReportSummaries['id']): void;
  onExportPdfByCategoryClick?(id: IReportSummaries['id']): void;
  isLoadingMenu: boolean;
}

export function ApprovalsReportGridActions({
  reportId,
  status,
  onViewClick,
  onReproveClick,
  onApproveClick,
  onExportPdfClick,
  onExportPdfByCategoryClick,
  isLoadingMenu,
}: ApprovalsReportGridActionsProps) {
  const { t } = useTranslation();
  const options: Action[] = [];

  const events = {
    view: () => onViewClick?.(reportId),
    reprove: () => onReproveClick?.(reportId),
    approve: () => onApproveClick?.(reportId),
    exportPdf: () => onExportPdfClick?.(reportId),
    exportPdfByCategory: () => onExportPdfByCategoryClick?.(reportId),
  };

  Object.keys(ActionOptions).forEach((action): void => {
    const showAction: boolean =
      ActionOptions[action] == ActionOptions.EXPORT_PDF ||
      ActionOptions[action] == ActionOptions.EXPORT_PDF_BY_CATEGORY ||
      ActionOptions[action] == ActionOptions.VIEW ||
      status == EReportStatus.SUBMITTED;

    showAction &&
      options.push({
        children: (
          <DropdownItem
            icon={IconAction[action]}
            description={t(`organisms.ApprovalsReportGrid.actionsOptions.${ActionOptions[action]}`)}
          />
        ),
        onClick: () => events[ActionOptions[action]](),
      });
  });

  if (isLoadingMenu) {
    return <Loader size="small" variant="primary" />;
  }

  return (
    <Menu
      type="default"
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      options={options}>
      <SC.IconDots />
    </Menu>
  );
}
