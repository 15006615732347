import { IConfigurationPaymentMethod } from '@containers/Customizations/context/types/paymentMethodTypes';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function useDeletePaymentMethods(paymentMethods: IConfigurationPaymentMethod[]) {
  const { mutation } = useMutate('v1/payment-methods');
  const t = useTranslate('organisms.customizationsPaymentMethods.modal.paymentMethod.feedbacks');

  async function deletePaymentMethods(paymentMethodIds: string[]) {
    try {
      const params = paymentMethodIds.map(_ => {
        return {
          key: 'ids[]',
          value: _,
        };
      });

      const response = await mutation.trigger({
        path: '',
        parameters: params,
        options: {
          method: 'DELETE',
        },
      });

      const hasError = response?.content?.some(_paymentMethod => _paymentMethod.deleted === false);

      if (hasError) {
        response?.content?.forEach(paymentMethod => {
          const costCenterName =
            paymentMethods?.find(_paymentMethod => _paymentMethod.id == paymentMethod.id)?.name ?? '';
          showToast({
            message: `${costCenterName ? costCenterName + ' - ' : ''}${
              !paymentMethod.deleted ? t('delete.error.message') : t('delete.success')
            }`,
            type: !paymentMethod.deleted ? 'error' : 'success',
          });
        });
      } else {
        showToast({
          message: t('delete.success'),
          type: 'success',
        });
      }
    } catch (_error) {
      showToast({
        title: t('error.title'),
        message: _error?.message ?? t('error.description'),
        type: 'error',
      });
    }
  }

  return {
    deletePaymentMethods,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
