import { useState } from 'react';
import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { IReportNavigate } from '@shared/hooks/navigation/useExpenseRegisterDriver';
import { useWithdrawPermissions } from '@shared/hooks/permissions/systemPermissions/useWithdrawPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { AttachWithdrawModal } from '../AttachWithdrawModal';

interface IProps {
  report?: IReportNavigate;
  reportByIdUpDate(): void;
}

export const WithdrawActions = ({ report, reportByIdUpDate }: IProps) => {
  const t = useTranslate('withdraw.grid.body.actions');
  const getWithdrawPermissions = useWithdrawPermissions();
  const { unlink: allowedUnlink } = getWithdrawPermissions(report?.status ?? null);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return allowedUnlink ? (
    <>
      <Button size="small" variant="primary" onClick={() => setModalIsOpen(true)}>
        {t('link')}
        <Icons name="IconLink" fill="transparent" />
      </Button>

      {modalIsOpen && (
        <AttachWithdrawModal
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          reportId={report?.id}
          reportByIdUpDate={reportByIdUpDate}
        />
      )}
    </>
  ) : (
    <></>
  );
};
