import { EExpenseType, IExpenseInput, ReceiptFile } from '@containers/Expenses/context/types';
import { IOdometer, IRoute } from '@containers/Expenses/context/types/expenseById';
import { IFileResponse } from '@containers/Expenses/context/types/files';
import { useExpenseFileUpload } from '@containers/Expenses/hooks/useExpenseFileUpload';
import { paymentMethodFlashCard } from '@shared/constant';
import { useCheckExpenseType } from '@shared/hooks/useCheckExpenseType';

import { useDeleteEmptyRoutes } from '../../../WaypointFields/hooks/useDeleteEmptyRoutes';
import { useSerializeCustomFields } from './useSerializeCustomFields';

export interface SerializeExpenseSubmissionDataProps {
  expenseData: IExpenseInput;
  reportId?: number;
}

export function useSerializeExpenseSubmissionData() {
  const fileUpload = useExpenseFileUpload();
  const serializeCustomFields = useSerializeCustomFields();
  const expenseType = useCheckExpenseType();
  const deleteEmptyRoutes = useDeleteEmptyRoutes();

  async function serializeExpenseSubmissionData({ expenseData, reportId }: SerializeExpenseSubmissionDataProps) {
    expenseData.customFields = serializeCustomFields(expenseData?.customFields);
    expenseData.refundable = Number(expenseData?.paymentMethodId) !== paymentMethodFlashCard ? true : false;
    expenseData.currencyCode = expenseData?.currencyCode || '';
    expenseData.reportId = reportId;
    expenseData.type = expenseType;
    delete expenseData?.establishment?.__typename;

    if (expenseData.type === EExpenseType.MANUAL) {
      expenseData.attachments = await serializeReceiptFile(expenseData?.attachments);
      expenseData.odometer = null;
      expenseData.route = null;
      expenseData.projectId && (expenseData.projectId = Number(expenseData.projectId));
      expenseData.clientId && (expenseData.clientId = Number(expenseData.clientId));
    }

    if (expenseData.type === EExpenseType.ORIGIN_DESTINATION) {
      expenseData.route.waypoints = serializeOriginDestinationExpense(expenseData?.route?.waypoints);
      expenseData.odometer = null;
    }

    if (expenseData.type === EExpenseType.ODOMETER) {
      const odometerAttachments = await serializeOdometerFiles(expenseData?.odometer);
      expenseData.odometer.start.attachment = odometerAttachments?.start;
      expenseData.odometer.end.attachment = odometerAttachments?.end;
      expenseData.route = null;
    }
  }

  function serializeOriginDestinationExpense(waypoints: IRoute['waypoints']) {
    let routes: IRoute['waypoints'] = [];

    if (waypoints) {
      routes = deleteEmptyRoutes(waypoints);
      routes?.forEach(waypoint => {
        delete waypoint?.__typename;
      });
    }

    return routes;
  }

  async function serializeReceiptFile(attachments: ReceiptFile[]) {
    const filesToUpload = attachments?.filter(({ file }) => !!file);
    const _attachments = attachments
      ?.filter(attachment => !attachment.file)
      .map<IFileResponse>(({ contentType, path }) => ({
        contentType,
        path,
      }));
    const uploadedFiles =
      filesToUpload && (await Promise.all(filesToUpload?.map(({ file }) => fileUpload.upload(file))));

    if (uploadedFiles) {
      _attachments.push(...uploadedFiles.map(file => ({ path: file.path, contentType: file.contentType })));
    }

    return _attachments;
  }

  function checkOdometerFile(attachment) {
    if (attachment && !attachment?.file)
      return {
        path: attachment.path,
        contentType: attachment.contentType,
      };

    return attachment;
  }

  function serializeOdometerFile(file: IFileResponse) {
    return file ? { contentType: file.contentType, path: file.path } : null;
  }

  async function serializeOdometerFiles(odometer: IOdometer) {
    const attachments = {
      start: null,
      end: null,
    };

    if (Boolean(odometer)) {
      const _start = checkOdometerFile(odometer.start?.attachment);
      const _final = checkOdometerFile(odometer.end?.attachment);
      const startUpload = _start && !_start?.path ? await fileUpload.upload(_start?.file) : _start;
      const finalUpload = _final && !_final?.path ? await fileUpload.upload(_final?.file) : _final;

      attachments.start = serializeOdometerFile(startUpload);
      attachments.end = serializeOdometerFile(finalUpload);
    }

    return attachments;
  }

  return serializeExpenseSubmissionData;
}
