import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { ExpenseDetails } from '@pages/ExpenseDetails';
import { ExpenseRegister } from '@pages/ExpenseRegister';
import { Expenses } from '@pages/Expenses';
import { InitialPage } from '@pages/index';
import { ExpenseRegisterContextProvider } from '@shared/contexts/ExpenseRegisterContext/ExpenseRegisterContext';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function ExpenseRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['BASIC_EXPENSE']}
          routePermissions={[EAuthZPermission.EXPENSES_REPORTS_VIEW]}
        />
      ),
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de Despesas" moduleName="Despesas" />,
      children: [
        {
          path: '',
          element: <InitialPage />,
        },
        {
          path: Router.getExpenses(),
          element: <Expenses />,
        },
        {
          path: Router.getExpensesDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          path: Router.getOriginDestinationDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          path: Router.getOdometerDetails(':id'),
          element: <ExpenseDetails />,
        },
        {
          element: <ExpenseRegisterContextProvider />,
          children: [
            {
              element: <PermissionsGuard routePermissions={[EAuthZPermission.EXPENSES_REPORTS_ACTION]} />,
              children: [
                {
                  path: Router.getExpenseRegister(),
                  element: <ExpenseRegister />,
                },
                {
                  path: Router.getExpenseRegister(':expenseId'),
                  element: <ExpenseRegister />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      element: (
        <PermissionsGuard
          routeFeatures={['BASIC_MILEAGE']}
          routePermissions={[EAuthZPermission.EXPENSES_MILEAGE_ACTION]}
        />
      ),
      errorElement: (
        <RouterErrorBoundary title="Erro ao carregar o módulo de Quilometragem" moduleName="Quilometragem" />
      ),
      children: [
        {
          element: <ExpenseRegisterContextProvider />,
          children: [
            {
              path: Router.getOriginAdnDestinationRegister(),
              element: <ExpenseRegister />,
            },
            {
              path: Router.getOriginAdnDestinationRegister(':expenseId'),
              element: <ExpenseRegister />,
            },
            {
              path: Router.getOdometerRegister(),
              element: <ExpenseRegister />,
            },
            {
              path: Router.getOdometerRegister(':expenseId'),
              element: <ExpenseRegister />,
            },
          ],
        },
      ],
    },
    {
      element: (
        <PermissionsGuard
          routeFeatures={['ADVANCED_MILEAGE']}
          routePermissions={[EAuthZPermission.EXPENSES_MILEAGE_ACTION]}
        />
      ),
      errorElement: <RouterErrorBoundary title="Erro ao carregar o módulo de GPS" moduleName="GPS" />,
      children: [
        {
          element: <ExpenseRegisterContextProvider />,
          children: [
            {
              path: Router.getGpsRegister(':expenseId'),
              element: <ExpenseRegister />,
            },
          ],
        },
      ],
    },
  ];
}
