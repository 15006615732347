import { StatusTag } from '@atoms/StatusTag';
import { getReportStatusColorVariant, getReportStatusTranslator } from '@containers/Reports/common';
import { EReportStatus } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';

interface IProps {
  status: EReportStatus;
}

export function ReportStatusGrid({ status }: IProps) {
  const { t } = useTranslation();
  const reportStatusColorVariant = getReportStatusColorVariant({ status });

  return <StatusTag variant={reportStatusColorVariant}>{t(getReportStatusTranslator(status))}</StatusTag>;
}
