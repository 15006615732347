import { IExpenses } from '@containers/Expenses/context/types';
import {
  IExpensesAllowedActions,
  IUseExpensesPermissions,
  useExpensesPermissions,
} from '@shared/hooks/permissions/systemPermissions/useExpensesPermissions';

import { ExpenseActionsSkeleton } from './ExpenseActionsSkeleton';
import { ActionButton, ExpenseActionsContainer } from './styled';

export interface ExpenseActionsProps {
  isLoading?: boolean;
  expense: IExpenses;
  onEditExpenseClick: () => void;
  onExportExpenseClick?: () => void;
  onDeleteExpenseClick: () => void;
  enableModifyingActions?: boolean;
}

export const ExpenseActions = ({
  isLoading = false,
  expense,
  onEditExpenseClick,
  onDeleteExpenseClick,
}: ExpenseActionsProps) => {
  const { getExpenseActions }: IUseExpensesPermissions = useExpensesPermissions();
  const { edit: allowedToEdit, delete: allowedToDelete }: IExpensesAllowedActions = getExpenseActions(
    expense?.type,
    expense?.report?.status,
  );

  if (isLoading) return <ExpenseActionsSkeleton />;

  return (
    <ExpenseActionsContainer>
      {allowedToEdit && <ActionButton icon="IconPencil" onClick={onEditExpenseClick} />}
      {allowedToDelete && <ActionButton icon="IconTrash" onClick={onDeleteExpenseClick} />}
    </ExpenseActionsContainer>
  );
};
