import { IAnalyticsLogDiff } from '@containers/Analytics/context/types/analyticsLogs';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { useLogValueFormatter } from '../useLogValueFormatter';

enum EColumns {
  FIELD = 'field',
  OLD = 'previous',
  NEW = 'current',
}

type Props = {
  diff: IAnalyticsLogDiff[];
};

export const AnalyticLogDifferenceGrid = (props: Props) => {
  const t = useTranslate('analytics.logs');

  const { formatValue } = useLogValueFormatter();

  function getColumns(): IGridColumn[] {
    return [
      {
        accessor: EColumns.FIELD,
        disableSortBy: true,
        Header: t('modal.changedData'),
        sticky: false,
      },
      {
        accessor: EColumns.OLD,
        disableSortBy: true,
        Header: t('modal.oldValue'),
        sticky: false,
      },
      {
        accessor: EColumns.NEW,
        disableSortBy: true,
        Header: t('modal.newValue'),
        sticky: false,
      },
    ];
  }

  function getRows(): object[] {
    return props.diff.map(data => {
      data.current = formatValue(data.current);
      data.previous = formatValue(data.previous);

      return data;
    });
  }

  return <Grid columns={getColumns()} rows={getRows()} isLoading={false} paginationIsDisabled hasPagination={false} />;
};
