import useTranslate from '@locale/useTranslate';
import { BreadcrumbPage } from '@molecules/BreadcrumbPage/BreadcrumbPage';
import { subsidiariesBaseURL } from '@shared/constant';

type Props = {
  isEditing: boolean;
};

export default function SubsidiaryRegisterBreadcrumb({ isEditing }: Props) {
  const t = useTranslate();

  return (
    <BreadcrumbPage
      items={[
        {
          to: '/settings',
          label: t('subsidiaries.page.breadcrumb.manageCompany'),
        },
        {
          to: subsidiariesBaseURL,
          label: t('subsidiaries.page.breadcrumb.subsidiaries'),
        },
        {
          to: '',
          label: !isEditing ? t('subsidiaries.page.breadcrumb.add') : t('subsidiaries.page.breadcrumb.edit'),
        },
      ]}
    />
  );
}
