import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: ${props => props.theme.spacings.xs};
  background-color: ${props => props.theme.colors.neutral[100]};
  width: fill-available !important;
  position: fixed;
  height: 75px;
  top: 0px;
  z-index: 1300;
  box-shadow: rgba(0, 0, 0, 0.1) 20px 4px 26px;
  margin-left: -${({ theme }) => theme.spacings.m}; ;
`;
