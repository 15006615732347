import { useState } from 'react';
import { AcceptSubscriptionCheckbox } from '@containers/Subscription/components/atoms/AcceptSubscriptionCheckbox/AcceptSubscriptionCheckbox';
import { useContracts } from '@containers/Subscription/components/atoms/AcceptSubscriptionCheckbox/hooks/useContracts';
import { Features } from '@containers/Subscription/components/molecules/Features/Features';
import { SubscriptionDetails } from '@containers/Subscription/components/organisms/SubscriptionDetails/SubscriptionDetails';
import { SubscriptionContext } from '@containers/Subscription/context';
import { useSubscriptionPlan } from '@containers/Subscription/hooks/useSubscriptionPlan';
import { Grid, Hidden } from '@mui/material';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import contractInformation from '../../assets/images/contractInformationImage.jpeg';
import { ActionsFooterPage } from './ActionsFooterPage';
import * as SC from './styled';
import { SubscriptionLoading } from './SubscriptionLoading';

export const SubscriptionConfirmationPage = () => {
  const t = useTranslate();

  const [acceptedAllContracts, setAcceptedAllContracts] = useState(false);

  const {
    contracts,
    saasServiceContract,
    corporateCardContract,
    travelContract,
    isLoading,
    onContractCreationRequest,
    isFullPageLoaderEnabled,
    isSigning,
    signExpenseContracts,
  } = useContracts();

  const { planName } = useSubscriptionPlan({ saasServiceContract });

  if (isLoading && isFullPageLoaderEnabled) {
    return <SubscriptionLoading />;
  }

  return (
    <SubscriptionContext.Provider
      value={{
        contracts,
        saasServiceContract,
        corporateCardContract,
        travelContract,
        isSigning,
        onContractCreationRequest,
        onSignContract: signExpenseContracts,
      }}>
      <BasePage
        headerPage={{
          breadcrumbItems: [
            {
              to: '/home',
              label: t('pages.subscriptionConfirmation.breadcrumbs.start'),
            },
            {
              to: '',
              label: t('pages.subscriptionConfirmation.breadcrumbs.getToKnowFlashExpense'),
            },
            {
              to: '',
              label: t('pages.subscriptionConfirmation.breadcrumbs.confirmYourSubscription'),
            },
          ],
        }}
        footerPage={<ActionsFooterPage confirmIsDisabled={!acceptedAllContracts} />}>
        <SC.PageContent>
          <Grid container>
            <Grid item sm={12} lg={6} width={'100%'}>
              <div>
                <SC.PageTitle variant="headline5">
                  {t(`pages.subscriptionConfirmation.title`, {
                    planName,
                  })}
                </SC.PageTitle>
                <SC.PageSubtitle variant="body3">
                  {t(`pages.subscriptionConfirmation.caption`, {
                    planName,
                  })}
                </SC.PageSubtitle>

                <Features />

                <SubscriptionDetails
                  corporateCardContract={corporateCardContract}
                  sassContract={saasServiceContract}
                  travelContract={travelContract}
                />

                <SC.ContainerAcceptSubscription>
                  <AcceptSubscriptionCheckbox
                    userHasAcceptedContracts={acceptedAllContracts}
                    onChangeAccept={accepted => setAcceptedAllContracts(accepted)}
                    isLoading={isLoading}
                  />
                </SC.ContainerAcceptSubscription>
              </div>
            </Grid>
            <Hidden lgDown>
              <Grid item sm={12} lg={6}>
                <SC.ImageContainer>
                  <SC.Image src={contractInformation} />
                </SC.ImageContainer>
              </Grid>
            </Hidden>
          </Grid>
        </SC.PageContent>

        <SC.FooterSpacing />
      </BasePage>
    </SubscriptionContext.Provider>
  );
};
