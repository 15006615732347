import { useContext, useState } from 'react';
import { ESizeVariant } from '@containers/Expenses/components/molecules/ReceiptsImages/variants';
import { useProjectFileRegister } from '@containers/Projects/hooks/useProjectFileRegister';
import { useTranslation } from '@locale/Translator';
import { BulkImportDropzone } from '@molecules/BulkImportDropzone';
import { ImportContainer } from '@molecules/ImportContainer';
import { Loader } from '@pages/ClientRegister/Loader';
import { ImportingContext } from '@shared/contexts/BulkImportContext';
import { showToast } from '@shared/helpers/toast';
import { Controller, useFormContext } from 'react-hook-form';
import writeXlsxFile from 'write-excel-file';

import * as SC from './styled';
import { TEMPLATE_FIELDS } from './templateFileFields';

export const RegisterProjectByFileForm = () => {
  const { t } = useTranslation();
  const { isLoading, importProjects } = useProjectFileRegister();
  const { handleSubmit, control } = useFormContext();
  const [hasFile, setHasFile] = useState(false);
  const [fileName, setFileName] = useState('');
  const { state } = useContext(ImportingContext);

  const onDropzoneChange = async (e, onChange) => {
    if (e?.length > 0) {
      onChange(e[0].file);
      setHasFile(true);
      setFileName(e[0]?.file?.name);
    } else {
      setHasFile(false);
      setFileName('');
      showToast({ message: t('organisms.registerProject.bulkImport.fileModel.warning'), type: 'warning' });
    }
  };

  return (
    <SC.Container>
      <ImportContainer
        title={t('organisms.registerProject.bulkImport.fileModel.title')}
        subTitle={t('organisms.registerProject.bulkImport.fileModel.subTitle')}>
        <SC.FileDownload
          fileName="Modelo-import-projeto.xlsx"
          onClick={async () => {
            const data = [
              TEMPLATE_FIELDS.map(field => {
                return {
                  value: field,
                  fontWeight: 'bold' as const,
                  backgroundColor: '#BFBFBF',
                };
              }),
            ];

            await writeXlsxFile([data], {
              columns: [
                TEMPLATE_FIELDS.map(field => ({
                  width: field.length + 2,
                })),
              ],
              fileName: 'modelo_de_planilha.xlsx',
              sheets: ['Projetos'],
            });
          }}
        />
      </ImportContainer>
      <ImportContainer
        title={t('organisms.registerProject.bulkImport.sendFile.title')}
        subTitle={t('organisms.registerProject.bulkImport.sendFile.subTitle')}>
        <SC.DropzoneContainer size={ESizeVariant.small} hasFile={hasFile} onSubmit={handleSubmit(importProjects)}>
          <Controller
            name="file"
            control={control}
            render={({ field }) => (
              <BulkImportDropzone
                fileName={fileName}
                hasFile={hasFile}
                onDropzoneChange={file => onDropzoneChange(file, field.onChange)}
              />
            )}
          />
        </SC.DropzoneContainer>
      </ImportContainer>
      <Loader show={isLoading || state.importingResponseIsLoading} />
    </SC.Container>
  );
};
