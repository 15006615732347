import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.form`
  display: grid;
  grid-template-rows: repeat(4, min-content);
  gap: ${props => props.theme.spacings.xs};
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, min-content);
  gap: ${props => props.theme.spacings.xs};
  min-width: 600px;
`;

export const MandatoryDescriptionContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs5};
`;

export const MandatoryDescriptionAlert = styled(Typography)`
  color: ${props => props.theme.colors.feedback.error[40]};
`;

export const MandatoryDescription = styled(Typography)`
  color: ${props => props.theme.colors.neutral[50]};
`;

export const FieldContainer = styled.div`
  width: 100%;
  .text-field-custom-theme {
    width: 100%;
  }
`;

export const ActiveField = styled(FormControlLabel)`
  width: fit-content;
`;

export const Input = styled(TextField)`
  width: 100%;
`;
