import React, { useState } from 'react';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

export interface CarouselProps {
  /**
   * List of items to display
   * @default 'exemple images'
   */
  items: React.ReactNode[];

  /**
   * If true presents of the points
   *
   */
  showDots?: boolean;

  /**
   * Sets the initial index value
   *
   */
  initialIndex?: number;

  /**
   * Sets custom arrows
   *
   */
  customArrows?: {
    left: IconTypes;
    right: IconTypes;
  };

  /**
   * get current index
   *
   */
  getCurrentIndex?(currentIndex: number): void;
}

export const Carousel = ({
  items,
  showDots = true,
  initialIndex = 0,
  customArrows,
  getCurrentIndex,
}: CarouselProps) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(initialIndex);

  const hasPrevImage = currentItemIndex > 0 && items?.length > 1;
  const hasNextImage = currentItemIndex < items?.length - 1;
  const _showDots = showDots && items?.length > 1;

  const handleIndexChange = (increment: number) => {
    getCurrentIndex && getCurrentIndex(increment);
    setCurrentItemIndex(prevIndex => prevIndex + increment);
  };

  return (
    <SC.CarouselContainer>
      <SC.BoxContainer>
        <SC.ButtonContainer>
          {hasPrevImage && (
            <SC.Button
              icon={customArrows ? customArrows.left : 'IconArrowLeft'}
              variant="line"
              size="medium"
              onClick={() => handleIndexChange(-1)}
            />
          )}
        </SC.ButtonContainer>
        <SC.ItemsContainer>
          <SC.ItemContainer key={`item-carousel-${currentItemIndex}`}>{items?.[currentItemIndex]}</SC.ItemContainer>
        </SC.ItemsContainer>
        <SC.ButtonContainer>
          {hasNextImage && (
            <SC.Button
              icon={customArrows ? customArrows.right : 'IconArrowRight'}
              variant="line"
              size="medium"
              onClick={() => handleIndexChange(1)}
            />
          )}
        </SC.ButtonContainer>
      </SC.BoxContainer>
      {_showDots && (
        <SC.DotsContainer>
          {items.map((_, index) => (
            <SC.Dots
              active={index == currentItemIndex}
              key={`item-carousel-dots-${index}`}
              onClick={() => setCurrentItemIndex(index)}
            />
          ))}
        </SC.DotsContainer>
      )}
    </SC.CarouselContainer>
  );
};
