import { showToast } from '@shared/helpers/toast';
import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';
import { useTranslation } from 'react-i18next';

import { detachAdvancesFromReport } from '../context/queries';
import { IDetachAdvancesFromReportRequest, IDetachAdvancesFromReportResponse } from '../context/types';

export interface UseDetachAdvancesFromReportMutate {
  refetchQueryType?: 'report';
}

export function useDetachAdvancesFromReportMutate({ refetchQueryType = 'report' }: UseDetachAdvancesFromReportMutate) {
  const { t } = useTranslation();
  const queries = {
    report: ['GetReportById', 'GetAdvances', 'GetAllAdvancesToBind'],
  };

  const [detachAdvancesFromReportMutate, { data, loading, error }] = useMutation<
    IDetachAdvancesFromReportResponse,
    IDetachAdvancesFromReportRequest
  >(detachAdvancesFromReport, {
    refetchQueries: queries[refetchQueryType],
  });

  async function unlinkAdvance(reportId, advanceIds) {
    const formOfDescription = advanceIds?.length > 1 ? 'plural' : 'singular';
    try {
      const response = await detachAdvancesFromReportMutate({
        variables: {
          reportId,
          advanceIds,
        },
      });
      // const advances: DetachAdvancesResponse[] = response?.data?.detachAdvanceFromReportResponses;
      // const successItems: boolean = advances?.some(Advance => Advance?.success);
      const successItems: boolean = response?.data?.detachAdvanceFromReport?.success;

      if (successItems) {
        showToast({
          message: t(`organisms.advances.unlink.successMessage.${formOfDescription}`),
          type: 'success',
        });
      } else {
        response.data.detachAdvanceFromReport?.message.forEach(error =>
          showToast({
            message: error,
            type: 'error',
          }),
        );
      }
    } catch (error) {
      showToast({
        message: error?.message || t(`organisms.advanceGrid.actionsGrid.feedbacks.unlink.${formOfDescription}.error`),
        type: 'error',
      });
    }
  }

  return {
    detachAdvancesFromReportMutate,
    advance: data?.detachAdvanceFromReport || [],
    isLoading: loading,
    error,
    unlinkAdvance,
  };
}
