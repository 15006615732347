import { Checkbox, Skeleton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

interface IProps {
  description?: string;
  tooltipMessage?: string;
  isLoading?: boolean;
  isLoadingAction?: boolean;
  isSelectedAll?: boolean;
  selectAllChecked?: boolean;
  onSelectAllClick?(): void;
}

export function ColumnHeader({
  isLoading,
  description,
  tooltipMessage,
  isSelectedAll,
  isLoadingAction = false,
  selectAllChecked,
  onSelectAllClick,
}: IProps) {
  return (
    <SC.Container>
      <SC.Content>
        {isLoading ? (
          <>
            {!isSelectedAll ? (
              <Skeleton variant="rounded" width={60} height={22} />
            ) : (
              <Skeleton variant="rounded" width={24} height={24} />
            )}
            {tooltipMessage && !isSelectedAll && <Skeleton variant="circular" width={16} height={16} />}
          </>
        ) : (
          <>
            {!isSelectedAll ? (
              <SC.Description variant="body3">{description}</SC.Description>
            ) : (
              <Checkbox
                size="small"
                onChange={onSelectAllClick}
                checked={selectAllChecked}
                disabled={isLoadingAction}
              />
            )}
            {tooltipMessage && !isSelectedAll && (
              <Tooltip arrow title={tooltipMessage} placement="top">
                <div>
                  <SC.TooltipIcon size={16} name="IconInfoCircle" fill="transparent" />
                </div>
              </Tooltip>
            )}
          </>
        )}
      </SC.Content>
    </SC.Container>
  );
}
