import { FieldRegister } from '@containers/Policies/componentes/molecules/FieldRegister/FieldRegister';
import { SubsidiaryFormData } from '@containers/Subsidiaries/validators/subsidiaryFormSchema';
import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { Box, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface AddressInfoProps {
  isLoading: boolean;
}

export const AddressInfo = ({ isLoading }: AddressInfoProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<SubsidiaryFormData>();

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3} marginY={3}>
        <Typography variant="headline8">{t('organisms.subsidiaryRegister.sections.addressInfo')}</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="address.zipCode"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  defaultValue=""
                  value={field.value}
                  inputRef={ref}
                  label={t('organisms.subsidiaryRegister.fields.zipCode')}
                  imaskProps={{
                    mask: '00.000-000',
                  }}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>
        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="address.street"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('organisms.subsidiaryRegister.fields.address')}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>

        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="address.number"
              render={({ field: { ref, ...field }, fieldState }) => (
                <NumericFormat
                  {...field}
                  inputRef={ref}
                  label={t('organisms.subsidiaryRegister.fields.number')}
                  customInput={TextField}
                  disabled={false}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>

        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="address.complement"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('organisms.subsidiaryRegister.fields.complement')}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>

        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="address.district"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('organisms.subsidiaryRegister.fields.neighborhood')}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>
        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="address.city"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('organisms.subsidiaryRegister.fields.city')}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>

        <Grid item xs={12}>
          <FieldRegister isLoading={isLoading}>
            <Controller
              control={control}
              name="address.state"
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('organisms.subsidiaryRegister.fields.uf')}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FieldRegister>
        </Grid>
      </Grid>
    </>
  );
};
