import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs1};
  max-width: 500px;
`;

export const FieldContainer = styled.div`
  position: relative;
  width: 100%;

  .helperText {
    position: inherit !important;
  }

  .MuiFilledInput-root {
    &:hover {
      border-width: ${({ theme }) => theme.borders.width.xs2} !important;
    }
  }
`;

export const ContainerDate = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
`;
