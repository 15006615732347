import { useMemo } from 'react';
import { Button, LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';

import { Router } from '../../../routes/Router';
import * as SC from './styled';

interface IErrorBoundaryProps {
  moduleName: string;
  title?: string;
  description?: string;
  printIsolatedError?: boolean;
}

export function ErrorBoundary(props: IErrorBoundaryProps) {
  const createdAt = useMemo(() => new Date().toISOString(), []);

  if (props.printIsolatedError)
    return (
      <SC.ContainerIsolatedError>
        <SC.IconAlert name="IconAlertTriangle" size={40} />
        <div>
          {props.moduleName && (
            <SC.IsolatedErrorModuleName variant="body4">{`Modulo: ${props.moduleName}`}</SC.IsolatedErrorModuleName>
          )}

          {props.title && <SC.IsolatedErrorTitle variant="body3">{props.title}</SC.IsolatedErrorTitle>}

          {props.description && (
            <SC.IsolatedErrorDescription variant="body4">{props.description}</SC.IsolatedErrorDescription>
          )}
        </div>
      </SC.ContainerIsolatedError>
    );

  return (
    <SC.Container>
      <div>
        <SC.Title variant="headline4">{props.title || 'Erro ao tentar carregar a página'}</SC.Title>

        <Typography variant="body4">
          <b>Página:</b> <code>{location.href}</code>
        </Typography>

        <Typography variant="body4">
          <b>Módulo:</b> {props.moduleName}
        </Typography>

        <Typography variant="body4">
          <b>Horário:</b> {createdAt}
        </Typography>
      </div>

      <Typography variant="body2">
        Clique em uma das opções abaixo para tentar novamente. <br />
        Caso o erro persistir, entre em contato com o suporte.
      </Typography>

      <SC.ActionContainer>
        <a href={Router.getExpenses()}>
          <LinkButton variant="default">Ir para tela inicial de despesas</LinkButton>
        </a>

        <Button size="medium" variant="primary" onClick={() => location.reload()}>
          Tentar novamente
        </Button>
      </SC.ActionContainer>
    </SC.Container>
  );
}
