import { IApproversTarget } from '@containers/Reports/context/types/approvers';
import { IconButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ApproverItemsDrawer } from './ApproverItemsDrawer/ApproverItemsDrawer';
import * as SC from './styled';

interface IProps {
  approvers: IApproversTarget[];
  onClose: () => void;
}

export function ApproversDrawer({ approvers, onClose }: IProps) {
  const t = useTranslate('approversDrawer');

  return (
    <SC.Container>
      <SC.Header>
        <SC.HeaderContent>
          <div>
            <Typography variant="headline6">{t('title')}</Typography>
            <Typography variant="body3">{t('caption')}</Typography>
          </div>
          <IconButton size="small" variant="line" icon="IconX" onClick={onClose} />
        </SC.HeaderContent>
      </SC.Header>
      <SC.Body>
        <ApproverItemsDrawer approvers={approvers} />
      </SC.Body>
    </SC.Container>
  );
}
