import { ChangeEvent } from 'react';

import * as SC from './styled';

export type TResize = 'both' | 'none' | 'vertical' | 'horizontal';

export interface TextAreaProps {
  value?: string;
  rows?: number;
  cols?: number;
  resize?: TResize;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  label: string;
  onChange?(event: ChangeEvent, value: string): void;
}

export const TextArea = ({
  value,
  rows,
  cols,
  disabled,
  label,
  helperText,
  error,
  resize = 'both',
  onChange,
}: TextAreaProps) => {
  return (
    <SC.Container>
      <SC.TextAreaContent variant="body3">
        <SC.TextArea
          error={error}
          style={{ resize }}
          placeholder={label}
          rows={rows}
          cols={cols}
          disabled={disabled}
          onChange={event => onChange(event, event?.target?.value)}>
          {value || ''}
        </SC.TextArea>
      </SC.TextAreaContent>
      {helperText && (
        <SC.HelperTextContainer>
          {error && <SC.IconInfoCircle name="IconInfoCircle" size={18} />}
          <SC.HelperText variant="caption">{helperText}</SC.HelperText>
        </SC.HelperTextContainer>
      )}
    </SC.Container>
  );
};
