import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ConfigurationContext } from '../context';
import { CustomRoutesContractsGuard } from './CustomRoutesContractsGuard';
import { AccountingRoutes } from './moduleRoutes/AccountingRoutes';
import { AdvancesRoutes } from './moduleRoutes/AdvancesRoutes';
import { AnalyticsRoutes } from './moduleRoutes/AnalyticsRoutes';
import { ApprovalsRoutes } from './moduleRoutes/ApprovalsRoutes';
import { ClientsRoutes } from './moduleRoutes/ClientsRoutes';
import { CostCenterRoutes } from './moduleRoutes/CostCenterRoutes';
import { CustomApprovalRoutes } from './moduleRoutes/CustomApprovalRoutes';
import { CustomizationsRoutes } from './moduleRoutes/CustomizationsRoutes';
import { DelegationRoutes } from './moduleRoutes/DelegationRoutes';
import { ExpenseRoutes } from './moduleRoutes/ExpenseRoutes';
import { PoliciesRoutes } from './moduleRoutes/PoliciesRoutes';
import { ProjectsRoutes } from './moduleRoutes/ProjectsRoutes';
import { RedirectExpensesRoutes } from './moduleRoutes/RedirectExpensesRoutes';
import { ReportsRoutes } from './moduleRoutes/ReportsRoutes';
import { SubscriptionRoutes } from './moduleRoutes/SubscriptionRoutes';
import { SubsidiariesRoutes } from './moduleRoutes/SubsidiariesRoutes';
import { RoutesAuthGuard } from './RoutesAuthGuard';
import { RoutesContractsGuard } from './RoutesContractsGuard';

function AppRouter() {
  const routes = createBrowserRouter([
    {
      element: <RoutesAuthGuard />,
      errorElement: <RouterErrorBoundary />,
      children: [
        {
          element: <RoutesContractsGuard />,
          children: [
            ...AdvancesRoutes(),
            ...AccountingRoutes(),
            ...ApprovalsRoutes(),
            ...ClientsRoutes(),
            ...CostCenterRoutes(),
            ...CustomApprovalRoutes(),
            ...DelegationRoutes(),
            ...ExpenseRoutes(),
            ...PoliciesRoutes(),
            ...ProjectsRoutes(),
            ...ReportsRoutes(),
            ...SubsidiariesRoutes(),
          ],
        },
        ...SubscriptionRoutes(),
        ...RedirectExpensesRoutes(),
        {
          element: <CustomRoutesContractsGuard />,
          children: AnalyticsRoutes(),
        },
        {
          element: <CustomRoutesContractsGuard />,
          children: CustomizationsRoutes(),
        },
      ],
    },
  ]);

  return (
    <ConfigurationContext>
      <RouterProvider router={routes} />
    </ConfigurationContext>
  );
}

export default AppRouter;
