import { StatusTag } from '@atoms/StatusTag';
import { Tooltip } from '@atoms/Tooltip';
import { IFinancialReportGrid } from '@containers/Financial/context/types/reports';
import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from 'react-i18next';

import { ActionOptions, IconAction } from '../ApprovalsReportGridActions';
import { UseAllowedActions } from '../hooks/useBulkActionsAllowed';
import * as SC from './styled';

interface ActionsMultipleSelectedReportsProps {
  selectedItems?: IFinancialReportGrid['id'][];
  totalItems?: number;
  actionsAllowed: UseAllowedActions;
  onReproveClick?(reportsSelected: IFinancialReportGrid['id'][]): void;
  onApproveClick?(reportsSelected: IFinancialReportGrid['id'][]): void;
  onExportPdfClick?(reportsSelected: IFinancialReportGrid['id'][]): void;
  onExportPdfByCategoryClick?(reportsSelected: IFinancialReportGrid['id'][]): void;
}

export function ActionsMultipleSelectedReports({
  selectedItems,
  totalItems,
  actionsAllowed,
  onReproveClick,
  onApproveClick,
  onExportPdfClick,
  onExportPdfByCategoryClick,
}: ActionsMultipleSelectedReportsProps) {
  const { t } = useTranslation();

  const events = {
    reprove: onReproveClick,
    approve: onApproveClick,
    exportPdf: onExportPdfClick,
    exportPdfByCategory: onExportPdfByCategoryClick,
  };

  return (
    <SC.Container>
      <StatusTag variant="secondary">
        {t('organisms.ApprovalsReportGrid.selectedItems', { totalItems, selectedItems: selectedItems?.length })}
      </StatusTag>
      {Object.keys(ActionOptions).map((action, key) => {
        if (ActionOptions[action] != ActionOptions.VIEW)
          return (
            <div key={key}>
              <Tooltip
                arrow
                title={
                  !actionsAllowed[ActionOptions[action]]
                    ? t(`organisms.ApprovalsReportGrid.actionsOptions.tooltip.${ActionOptions[action]}`)
                    : ''
                }
                placement="top">
                <div>
                  <LinkButton disabled={!actionsAllowed[ActionOptions[action]]} variant="default">
                    <SC.LinkContent onClick={() => events[ActionOptions[action]](selectedItems)}>
                      <Icons name={IconAction[action]} fill="transparent" />
                      {t(`organisms.ApprovalsReportGrid.actionsOptions.${ActionOptions[action]}`)}
                    </SC.LinkContent>
                  </LinkButton>
                </div>
              </Tooltip>
            </div>
          );
      })}
    </SC.Container>
  );
}
