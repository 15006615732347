import { gql } from '@apollo/client';

export const getCategoryLimitQuery = gql`
  query GetCategoryLimits($categoryId: Float!) {
    getLimitsByCategoryId(categoryId: $categoryId) {
      limits {
        id
        criterion
        value
        minimumInvoiceProofValue
        allowValueGreaterThanLimit
        commentRequired
        active
        limitId
        policy {
          id
          name
        }
        categoryId
        currency {
          id
          code
        }
      }
    }
  }
`;

export const updateCategoryLimitByCategoryIdAndPolicyIdMutation = gql`
  mutation UpdateCategoryLimits($policyId: Int!, $categoryId: Int!, $limit: CategoryLimitProps!) {
    updateLimitByCategoryIdAndPolicyId(policyId: $policyId, categoryId: $categoryId, limit: $limit) {
      id
      criterion
      value
      minimumInvoiceProofValue
      allowValueGreaterThanLimit
      commentRequired
      active
      limitId
      policy {
        id
        name
      }
      categoryId
      currency {
        id
        code
      }
    }
  }
`;
