import { useMemo } from 'react';
import { EActionGrid } from '@containers/Policies/context/types/types';
import { useListPolicies } from '@containers/Policies/hooks/dataSources/useListPolicies';
import { usePolicyMutate } from '@containers/Policies/hooks/dataSources/usePolicyMutate';
import { useActions } from '@containers/Policies/hooks/useActions';
import { Checkbox, Icons, Loader, Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import { ActionsButton } from '@molecules/ActionsButton/ActionsButton';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';
import { ActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { MultipleStickContainer } from '@organisms/Grid/StickMultiple/MultipleStickContainer';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { expensesCustomizationsBaseURL } from '@shared/constant';
import { useDateFormatter } from '@shared/hooks';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { PolicyGridFilters } from '../../molecules/PolicyGridFilters/PolicyGridFilters';
import * as SC from './styled';

enum EAccessor {
  SELECT_ALL = 'selectAll',
  NAME = 'name',
  DESCRIPTION = 'description',
  LEVEL = 'level',
  UPDATE_AT = 'updatedAt',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export function PolicyGrid() {
  const t = useTranslate('policy');
  const navigate = useNavigate();
  const { policies, isLoading } = useListPolicies();
  const { isLoading: mutateIsLoading } = usePolicyMutate();
  const { getDateWithTimeFormatter } = useDateFormatter();
  const {
    openConfirmationAction,
    selectedItems,
    listIdsLoading,
    isLoadingAction,
    isSelectedAll,
    confirmationType,
    plurality,
    filters,
    setFilters,
    onActionClick,
    getActionsRow,
    getBatchActions,
    unSelectAllClick,
    toggleSelectAll,
    onSelectItemClick,
    onActionConfirmed,
    onCloseModal,
  } = useActions(policies, true);
  const { checkLicenseFeature } = useLicenseFeatures();
  const isControlPlan = useMemo(() => checkLicenseFeature('ADVANCED_EXPENSE'), []);

  function getColumns(): IGridColumn[] {
    const columns: IGridColumn[] = [];
    Object.values(EAccessor).map(accessor => {
      if (accessor !== EAccessor.STATUS)
        columns.push({
          accessor,
          disableSortBy: true,
          Header:
            accessor === EAccessor.ACTIONS ? (
              <MultipleStickContainer
                items={[t(`policyGrid.header.${EAccessor.STATUS}`), t(`policyGrid.header.${accessor}`)]}
              />
            ) : (
              <ColumnHeader
                isLoading={isLoading}
                isLoadingAction={mutateIsLoading}
                description={t(`policyGrid.header.${accessor}`)}
                isSelectedAll={accessor === EAccessor.SELECT_ALL}
                onSelectAllClick={toggleSelectAll}
                selectAllChecked={isSelectedAll}
              />
            ),
          sticky: accessor === EAccessor.ACTIONS && 'right',
        });
    });

    return columns;
  }

  function getPoliciesFiltered() {
    return (
      policies?.filter(policy => {
        let isValid = true;
        const updatedAt = policy.updatedAt ? new Date(policy.updatedAt).getDate() : null;

        isValid &&=
          !filters?.search ||
          policy.description?.toLowerCase()?.includes(filters?.search?.toLowerCase()) ||
          policy.name?.toLowerCase()?.includes(filters?.search?.toLowerCase());

        isValid &&= !filters?.level?.length || filters?.level?.some(level => level == policy.level);
        isValid &&= !filters?.status?.length || (filters?.status[0] === 'active' ? policy.enabled : !policy.enabled);

        isValid &&=
          !filters?.lastUpdate?.from ||
          !updatedAt ||
          (!filters?.lastUpdate?.to
            ? updatedAt == new Date(filters?.lastUpdate?.from).getDate()
            : updatedAt >= new Date(filters?.lastUpdate?.from).getDate());
        isValid &&= !filters?.lastUpdate?.to || !updatedAt || updatedAt <= new Date(filters?.lastUpdate?.to).getDate();

        return isValid;
      }) || []
    );
  }

  function getRows(): Record<EAccessor, React.ReactNode>[] {
    const empty = ' - ';
    return getPoliciesFiltered()?.map(policy => {
      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(policy?.id)}
            checked={selectedItems.some(id => id === policy?.id)}
          />
        ),
        name: policy.name || empty,
        description: policy.description || empty,
        level: policy.level ? t(`policyGrid.filters.level.options.${policy.level.toLowerCase()}`) : empty,
        updatedAt: policy.updatedAt ? getDateWithTimeFormatter(policy.updatedAt) : empty,
        status: policy.enabled,
        actions: (
          <MultipleStickContainer
            items={[
              <div key={`action-${policy.id}`}>
                {(isLoadingAction && listIdsLoading?.some(idLoading => idLoading === policy.id)) || isLoading ? (
                  <SC.ContainerLoading>
                    <Loader size="extraSmall" variant="primary" />
                  </SC.ContainerLoading>
                ) : (
                  <Toggle
                    key={'toggle-grid'}
                    checked={policy.enabled}
                    onChange={(_, data) => {
                      policy.enabled = data ? data : policy.enabled;
                      onActionClick(EActionGrid.TOGGLE, policy.id, data);
                    }}
                  />
                )}
              </div>,

              <ActionsRow
                key={'action-grid'}
                referenceId={policy.id}
                actions={getActionsRow(policy.id)}
                disabled={(isLoadingAction && listIdsLoading?.some(idLoading => idLoading === policy.id)) || isLoading}
              />,
            ]}
          />
        ),
      };
    });
  }

  return (
    <>
      <SectionContainer
        title={t('gridPage.title')}
        caption={t('gridPage.caption')}
        action={
          isControlPlan && (
            <ActionsButton
              actionName={t('gridPage.add.label')}
              actionIcon="IconPlus"
              onClick={() => navigate(`${expensesCustomizationsBaseURL}/policy/register`)}
            />
          )
        }>
        <SC.Container>
          {isControlPlan && (
            <PolicyGridFilters
              filters={filters}
              onFiltersChange={(key, data) => {
                unSelectAllClick();
                setFilters({ ...filters, [key]: data });
              }}
              isLoading={isLoading}
            />
          )}

          <Grid
            columns={getColumns()}
            rows={getRows()}
            batchActions={isControlPlan ? getBatchActions() : []}
            isLoading={isLoading}
            numberItemsSelected={selectedItems?.length || 0}
            hasPagination={false}
            rowsLoading={Boolean(policies.length) ? policies.length : 4}
          />
          <DangerActionModal
            open={openConfirmationAction}
            isLoading={isLoadingAction}
            headerTitle={t(`${confirmationType}.${plurality}.modal.title`)}
            headerSubtitle={t(`${confirmationType}.${plurality}.modal.message`)}
            labelButtonAction={
              <SC.LabelButtonContent>
                <div>{t(`${confirmationType}.${plurality}.modal.confirm`)}</div>
                {confirmationType === EActionGrid.DELETE && <Icons name="IconTrash" fill="transparent" size={24} />}
              </SC.LabelButtonContent>
            }
            onActionClick={onActionConfirmed}
            onCancelClick={onCloseModal}
          />
        </SC.Container>
      </SectionContainer>
    </>
  );
}
