import { IConfigurationProject } from '@containers/Projects/context/types/projectTypes';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { mutate } from 'swr';

export function useDeleteBatchProjects(projects: IConfigurationProject[]) {
  const projectMutate = useMutate('v1/projects');
  const { t } = useTranslation();

  async function deleteProjects(ids: IConfigurationProject['id'][]) {
    const params = ids.map(_ => {
      return {
        key: 'ids[]',
        value: _,
      };
    });
    const response = await projectMutate.mutation.trigger(
      {
        path: '',
        parameters: params,
        options: {
          method: 'DELETE',
        },
      },
      {
        revalidate: false,
      },
    );
    response?.forEach(project => {
      if (!project.deleted)
        showToast({
          title: `${t('pages.projectRegister.project')} ${
            projects?.find(_project => _project.id == project.id)?.name ?? ''
          }`,
          message: t('pages.projectRegister.feedbacks.delete.error'),
          type: 'error',
        });
      else
        showToast({
          title: `${t('pages.projectRegister.project')} ${
            projects?.find(_project => _project.id == project.id)?.name ?? ''
          }`,
          message: t('pages.projectRegister.feedbacks.delete.success'),
          type: 'success',
        });
    });

    mutate(key => typeof key === 'string' && key.includes('v1/projects?page='));
  }
  return {
    deleteProjects,
    deletedProjects: projectMutate.mutation.data,
    error: projectMutate.mutation.error,
    isLoading: projectMutate.mutation.isMutating,
  };
}
