import { ISubsidiary } from '@containers/Subsidiaries/context/types/subsidiaries';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

interface IProps {
  page: number;
  searchName?: string;
}

interface IGetSubsidiariesSettings {
  subsidiaries: ISubsidiary[];
}

export const useAllSubsidiaries = ({ page, searchName }: IProps) => {
  const { t } = useTranslation();
  const onError = () => showToast({ message: t('subsidiaries.feedbacks.error'), type: 'error' });

  const params = new URLSearchParams({ page: page.toString(), searchName });
  const { data, isLoading, error, mutate } = useFetch<IGetSubsidiariesSettings['subsidiaries']>(
    `v1/subsidiaries?${params}`,
    '',
    undefined,
    undefined,
    onError,
  );

  return {
    subsidiaries: data || [],
    isLoading: isLoading,
    error,
    refetch: mutate,
  };
};
