import { IconButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { useDateFormatter, useValueFormatter } from '@shared/hooks';

import * as SC from './styled';

type Props = {
  startDate: Date;
  mileageRate: number;
  onDeleteClick: () => void;
  onEditClick: () => void;
};

export const MileageRateRow = (props: Props) => {
  const { t } = useTranslation();

  const { getDateOnlyFormatter } = useDateFormatter();
  const { getValueWithCurrency } = useValueFormatter();

  return (
    <SC.MileageRateRowContainer>
      <SC.RowColumn>
        <SC.ColumnTitle variant="body4">{t('organisms.mileagePreview.mileageRates.startDate')}</SC.ColumnTitle>
        <Typography variant="body3">{getDateOnlyFormatter(props.startDate)}</Typography>
      </SC.RowColumn>

      <SC.RowColumn>
        <SC.ColumnTitle variant="body4">{t('organisms.mileagePreview.mileageRates.ratePerKm')}</SC.ColumnTitle>
        <Typography variant="body3">
          {getValueWithCurrency({
            value: props.mileageRate,
            currencyPrefix: 'R$',
          })}
        </Typography>
      </SC.RowColumn>

      <SC.ActionsRow>
        <IconButton variant="line" size="medium" icon="IconPencil" onClick={() => props.onEditClick()} />
        <IconButton variant="line" size="medium" icon="IconTrash" onClick={() => props.onDeleteClick()} />
      </SC.ActionsRow>
    </SC.MileageRateRowContainer>
  );
};
