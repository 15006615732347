import { segmentTracking } from '@flash-tecnologia/hros-web-utility';

export interface SegmentTrackingProps {
  track: string;
  module: string;
  businessUnit: string;
  params?: Record<string, string>;
  identify?: {
    id: string;
    params?: Record<string, string>;
  };
}

export const segmentEvent: (props: SegmentTrackingProps) => void = segmentTracking;
