import * as SC from './styled';

export enum EWaypointDivider {
  SOLID = 'solid',
  DOTTED = 'dotted',
}

interface IProps {
  type: EWaypointDivider;
  dottedNumber?: number;
}

export function WaypointDivider({ type, dottedNumber = 3 }: IProps) {
  return (
    <SC.Container>
      {type == EWaypointDivider.SOLID ? (
        <SC.Solid />
      ) : (
        new Array(dottedNumber).fill({}).map((_, index) => <SC.Dotted key={index} index={index}></SC.Dotted>)
      )}
    </SC.Container>
  );
}
