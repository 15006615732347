import * as SC from './styled';

export interface DetailedValues {
  description: string;
  value: string;
}

export interface ReportInfosTableProps {
  mainValueDescription: string;
  mainValue: string;
  detailedValues?: DetailedValues[];
}

export const ReportInfosTable = ({ mainValueDescription, mainValue, detailedValues }: ReportInfosTableProps) => {
  const hasDetailedValues = Boolean(detailedValues && detailedValues.length);

  return (
    <SC.TableContainer>
      <table>
        <thead>
          <tr>
            <th>
              <SC.TitleTable>{mainValueDescription}</SC.TitleTable>
            </th>
            <th>
              <SC.TitleTable>{mainValue}</SC.TitleTable>
            </th>
          </tr>
        </thead>
        {hasDetailedValues && (
          <tbody>
            {detailedValues.map((item, key) => (
              <tr key={`table-item-${key}`}>
                <td>
                  <SC.DescriptionTable>{item.description}</SC.DescriptionTable>
                </td>
                <td>
                  <SC.DescriptionTable>{item.value}</SC.DescriptionTable>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </SC.TableContainer>
  );
};
