import { useState } from 'react';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { mutate } from 'swr';

import { IConfigurationClient } from '../../context/types/clientTypes';

export const useClientToggleActivateFlag = () => {
  const { t } = useTranslation();
  const clientMutate = useMutate('v1/clients');
  const [listIdsLoading, setListIdsLoading] = useState<IConfigurationClient['id'][]>([]);

  async function toggle(ids: IConfigurationClient['id'][], active: boolean) {
    setListIdsLoading([...listIdsLoading, ...ids]);

    try {
      await clientMutate.mutation.trigger({
        path: 'toggle/active',
        options: {
          method: 'PATCH',
          body: JSON.stringify({ ids, active }),
        },
      });

      const _listIdsLoading = listIdsLoading;
      showToast({ message: t('molecules.clientToast.createSuccessMessage'), type: 'success' });

      mutate(key => typeof key === 'string' && key.includes('v1/clients?page='));
      setListIdsLoading(_listIdsLoading);
    } catch (_error) {
      showToast({ message: t('molecules.clientToast.createErrorMessage'), type: 'error' });
      setListIdsLoading([]);
    }
  }

  return {
    activeBatchClients: clientMutate.mutation.data,
    toggle,
    error: clientMutate.mutation.error,
    isLoading: clientMutate.mutation.isMutating,
    listIdsLoading,
  };
};
