import { useMemo } from 'react';
import { IEstablishment, IPlace } from '@containers/Expenses/context/types/establishments';

export function useSerializeLocationToEstablishment(places: IPlace[]): IEstablishment[] {
  return useMemo(() => {
    return (
      (places &&
        places.map(place => ({
          placeId: place.id,
          place: place.name,
          address: place.address,
        }))) ||
      []
    );
  }, [places]);
}
