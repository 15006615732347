import { useEffect, useState } from 'react';
import { IAnalyticsData } from '@containers/Analytics/context/types/analytics';
import { EAnalyticsType } from '@containers/Analytics/context/types/filters';
import { useSaveCustomAnalytics } from '@containers/Analytics/hooks/dataSource/useSaveCustomAnalytics';
import { Button, Icons, LinkButton, Tooltip, TransferList } from '@flash-tecnologia/hros-web-ui-v2';
import { DataType } from '@flash-tecnologia/hros-web-ui-v2/dist/components/TransferList/types';
import { ContainerButtonTag } from '@molecules/GridFilters/styled';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import * as SC from './styled';

interface IProps {
  analyticsData: IAnalyticsData;
  isLoading: boolean;
  analyticsType: EAnalyticsType;
  onApply: (data: string[]) => void;
}

interface ITransferData {
  right: DataType;
  left: DataType;
}

export interface IFieldAnalytics {
  _id: string;
  name: string;
  checked: boolean;
  hidden: boolean;
}

type TTarget = 'left' | 'right';

export function CustomizeReport({ analyticsData, isLoading, analyticsType, onApply }: IProps) {
  const t = useTranslate('analytics.customizeReport');
  const [transferData, setTransferData] = useState<ITransferData>({
    right: [],
    left: [],
  });
  const [open, setOpen] = useState(false);
  const { handleSave, isLoading: saveIsLoading } = useSaveCustomAnalytics();

  function getInitialFields() {
    let currentFields: IFieldAnalytics[] =
      analyticsData?.fields?.map(_field => ({
        _id: _field.key,
        name: _field.label,
        checked: false,
        hidden: false,
      })) || [];

    currentFields = getFilteredFields(currentFields);

    setTransferData({
      left: getFilter(currentFields, false),
      right: getFilter(currentFields, true),
    });
  }

  function getFilteredFields(fields: IFieldAnalytics[]) {
    switch (analyticsType) {
      case EAnalyticsType.EXPENSES_BY_CATEGORY:
        return fields.filter(
          field =>
            field._id === 'ExpenseCategoryName' ||
            field._id === 'ExpenseConvertedAmount' ||
            field._id === 'ExpenseQuantity' ||
            field._id === 'ExpenseCategoryReferenceCode',
        );
      case EAnalyticsType.EXPENSES_BY_USER:
        return fields.filter(
          field =>
            field._id === 'UserName' ||
            field._id === 'ExpenseConvertedAmount' ||
            field._id === 'ExpenseQuantity' ||
            field._id === 'UserProfile' ||
            field._id === 'UserIsActive',
        );
      default:
        return fields;
    }
  }

  function getFilter(currentFields: DataType, isSelectedList: boolean) {
    return currentFields.filter(_field =>
      isSelectedList
        ? analyticsData.selectedFields.some(selectedField => selectedField === _field._id)
        : !analyticsData.selectedFields.some(selectedField => selectedField === _field._id),
    );
  }

  function onCheck(allChecked: boolean, data: DataType, target: TTarget) {
    let _fields: DataType = data ?? [];
    const selected = _fields.filter(_field => _field.checked);

    if ((selected?.length === data?.length && !allChecked) || allChecked) {
      _fields = _fields.map(_field => ({ ..._field, checked: allChecked === undefined ? true : allChecked }));
    }

    setTransferData({
      ...transferData,
      [target]: _fields,
    });
  }

  function getSearchItems(value: string, target: TTarget) {
    const list = transferData[target] ?? [];

    setTransferData({
      ...transferData,
      [target]: list?.map(item => ({ ...item, hidden: !item.name?.toLowerCase()?.includes(value.toLowerCase()) })),
    });
  }

  function getTransferListData(target: TTarget) {
    return {
      data: transferData?.[target] ?? [],
      total: transferData?.[target]?.length ?? 0,
      title: `${t(target === 'left' ? 'availableFields' : 'selectedFields')} (${transferData?.[target]?.length ?? 0})`,
      onCheck: ({ allChecked, data }) => onCheck(allChecked, data, target),
      onSearch: (_search: string) => getSearchItems(_search, target),
    };
  }

  function serializeDataForSending() {
    return transferData?.right?.length ? transferData?.right?.map(_field => _field._id) : null;
  }

  function toApply() {
    onApply(serializeDataForSending());
    setOpen(false);
  }

  async function save() {
    await handleSave(analyticsType, serializeDataForSending() || []);
    toApply();
  }

  useEffect(() => {
    open && analyticsData && getInitialFields();
  }, [JSON.stringify(analyticsData), open]);

  return (
    <div>
      <ContainerButtonTag>
        <Button disabled={isLoading} variant="secondary" size="small" onClick={() => setOpen(true)}>
          <Icons name="IconSettings" fill="transparent" />
          <SC.ButtonLabel>{t(`customizeReport`)}</SC.ButtonLabel>
        </Button>
      </ContainerButtonTag>

      <SC.StyledContractModal
        open={open}
        onCloseClick={() => setOpen(false)}
        onActionClick={toApply}
        isActionLoading={isLoading}
        bodyClassName="customize-report-modal-body"
        headerTitle={t(`customizeReport`)}
        footerCustom={
          <SC.ModalFooterContainer>
            <div>
              <LinkButton variant="default" onClick={() => setOpen(false)}>
                {t('cancel')}
              </LinkButton>
            </div>
            <SC.ActionButtonContainer>
              <div>
                <Tooltip arrow title={transferData?.right?.length === 0 ? t('tooltip') : ''} placement="top-start">
                  <div>
                    <Button
                      size="large"
                      variant="secondary"
                      loading={isLoading || saveIsLoading}
                      disabled={transferData?.right?.length === 0}
                      onClick={save}>
                      {t('applyAndSave')}
                    </Button>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip arrow title={transferData?.right?.length === 0 ? t('tooltip') : ''} placement="top-start">
                  <div>
                    <Button
                      size="large"
                      variant="primary"
                      loading={isLoading || saveIsLoading}
                      disabled={transferData?.right?.length === 0}
                      onClick={toApply}>
                      {t('apply')}
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </SC.ActionButtonContainer>
          </SC.ModalFooterContainer>
        }>
        <SC.ModalContent>
          <SC.ListTransferContent>
            <TransferList
              key={'customize-report-list-transfer'}
              columns={[{ Header: t('fields'), accessor: 'name', disableSortBy: true }]}
              leftList={getTransferListData('left')}
              rightList={getTransferListData('right')}
              onTransfer={({ leftList, rightList }) =>
                setTransferData({
                  right: rightList.data,
                  left: leftList.data,
                })
              }
              customTransferLabel={t('tooltipButtonTransfer')}
            />
          </SC.ListTransferContent>
        </SC.ModalContent>
      </SC.StyledContractModal>
    </div>
  );
}
