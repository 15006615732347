import { useContext, useEffect, useState } from 'react';
import { useDelegateRequestingUsers } from '@containers/Expenses/hooks/dataSource/useDelegateRequestingUsers';
import { Button, Icons, LinkButton, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { DelegateContext } from '@shared/contexts/DelegateContext/DelegateContext';
import { IDelegateContext } from '@shared/contexts/DelegateContext/types';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useTheme } from 'styled-components';

import * as SC from './styled';

interface IProps {
  showBackButton?: boolean;
  onClose: () => void;
  onGoBackClick: () => void;
}

export function DelegateUsers({ showBackButton = true, onGoBackClick, onClose }: IProps) {
  const { userDelegateContext, setUserDelegate } = useContext(DelegateContext);
  const { colors } = useTheme();
  const t = useTranslate('popoverDelegateUsers');
  const [userSelected, setUserSelected] = useState<IDelegateContext['userDelegateContext']>(null);
  const { delegateRequestingUsers, isLoading } = useDelegateRequestingUsers();

  useEffect(() => {
    setUserSelected(userDelegateContext);
  }, [userDelegateContext?.id]);

  return (
    <SC.Container>
      <SC.Header onClick={() => showBackButton && onGoBackClick()}>
        {showBackButton ? (
          <SC.HeaderContent>
            <Icons name="IconChevronLeft" size={24} color={colors.neutral[50]} />
            <SC.DescriptionGoBack variant="body3">{t('goBack')}</SC.DescriptionGoBack>
          </SC.HeaderContent>
        ) : (
          <SC.HeaderDescription variant="body2">{t('delegateProfiles')}</SC.HeaderDescription>
        )}
      </SC.Header>

      <SC.Body>
        {!isLoading ? (
          Boolean(delegateRequestingUsers.length) ? (
            <SC.ItemsContainer>
              {delegateRequestingUsers.map(user => (
                <SC.ItemContainer
                  key={`delegation-user-${user.id}`}
                  isActive={userSelected?.id === user?.id}
                  onClick={() => setUserSelected(user)}>
                  <SC.ItemDescription variant="body3">{user.name}</SC.ItemDescription>
                  {userSelected?.id === user.id && <Icons name="IconCheck" size={24} />}
                </SC.ItemContainer>
              ))}
            </SC.ItemsContainer>
          ) : (
            <SC.EmptyPeople>
              <SC.EmptyDescription variant="body3">{t('empty')}</SC.EmptyDescription>
            </SC.EmptyPeople>
          )
        ) : (
          <SC.ItemsContainer>
            {new Array(3).fill({}).map((_, index) => (
              <Skeleton key={index} variant="rounded" width={'100%'} height={54} />
            ))}
          </SC.ItemsContainer>
        )}
      </SC.Body>
      <SC.Footer>
        <div>
          <LinkButton variant="default" onClick={() => setUserSelected(null)}>
            {t('clear')}
          </LinkButton>
        </div>
        <Button
          variant="primary"
          size="small"
          onClick={() => {
            setUserDelegate(userSelected);
            onClose();
          }}>
          {t('apply')}
        </Button>
      </SC.Footer>
    </SC.Container>
  );
}
