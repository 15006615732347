import { DataGrid, IconButton, Icons, Menu, Tab, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ReportGridContainer = styled.div``;

export const GridContainer = styled.div`
  margin: 0 0 ${props => props.theme.spacings.m};
  > div {
    :first-child {
      position: relative;
      border: 1px solid ${p => p.theme.colors.neutral.light.light2};
      border-radius: ${p => p.theme.borders.radius.m};
    }
  }
`;

export const Grid = styled(DataGrid)`
  display: flex;
`;

export const ActionsFeedbackContainer = styled.div`
  p {
    font-size: 14px;
  }
`;

export const ThContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .menu-base-container-custom-theme {
    > div {
      :first-child {
        display: flex;
      }
    }
  }
`;

export const ThDescription = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 700;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const IconArrowsSort = styled(Icons).attrs({
  name: 'IconArrowsSort',
  size: 16,
})`
  cursor: pointer;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.2);
  }
`;

export const IconDots = styled(IconButton).attrs({
  icon: 'IconDots',
  size: 'small',
  variant: 'line',
})`
  transform: rotate(90deg);
`;

export const Description = styled(Typography).attrs({
  variant: 'body3',
})`
  font-weight: 600;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const SortByButton = styled(Menu).attrs({
  type: 'default',
  transformOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
})`
  .MuiPaper-elevation {
    margin: 5px 0;
  }
`;

export const ActionsButton = styled(Menu).attrs({
  type: 'default',
  transformOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
})``;

export const TdContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacings.xs3};
`;

export const OptionContent = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionIcon = styled(Icons).attrs({
  size: 22,
  fill: '#fff',
})`
  margin-right: ${props => props.theme.spacings.xs4};
`;

export const TabGrid = styled(Tab)`
  .MuiTab-root {
    text-transform: inherit;
    padding-bottom: ${props => props.theme.spacings.xs5};
  }
`;
