import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Checkbox, OptionsTagFilter } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import * as SC from './styled';

export interface CheckboxListOptions {
  label: string;
  value: string;
  icon?: SC.IconsStyledProps;
  checked?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface CheckboxListProps {
  children?: React.ReactNode;
  loading?: boolean;
  onApply?(value?: string[]): void;
  onChange?(value: string[]): void;
  onClear?(): void;
  onClick?(value: string | string[]): void;
  selectedOptions?: OptionsTagFilter['value'][];
  options?: CheckboxListOptions[];
  placeholder?: string;
  disableFooter?: boolean;
  disableSearch?: boolean;
  multiple?: boolean;
  hideButtons?: boolean;
}

export const CheckboxList = ({
  options = [],
  onApply,
  onClear,
  onClick,
  placeholder,
  disableFooter = false,
  disableSearch = false,
  selectedOptions,
  multiple,
}: CheckboxListProps) => {
  const [search, setSearch] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState(selectedOptions);
  const { t } = useTranslation();

  const handleItemSelection = option => {
    const index = selectedValues?.findIndex(item => item === option);
    let newOptions = Array.isArray(selectedValues) ? [...selectedValues] : [];

    if (!multiple) {
      newOptions = index >= 0 || newOptions[index] === option ? [] : [option];
    } else {
      if (index >= 0) {
        newOptions.splice(index, 1);
      } else {
        newOptions.push(option);
      }
    }
    setSelectedValues(newOptions);
    onClick?.(newOptions);
    disableFooter && onApply?.(newOptions);
  };

  useEffect(() => {
    setSelectedValues(selectedOptions || []);
  }, [selectedOptions]);

  const handleOnClear = () => {
    setSearch('');
    if (onClear) {
      onClear();
      setSelectedValues([]);
    }
  };

  const handleOnApply = () => {
    setSearch('');
    if (onApply) {
      onApply();
    }
  };

  const handleSearchChange = (event: Parameters<ChangeEventHandler<HTMLInputElement>>[0]) => {
    switch (event.type) {
      case 'click':
        setSearch('');
        return;

      default:
        setSearch(event.target.value);
        return;
    }
  };

  const itemsList = options
    .filter(option => option.label.toLowerCase().includes(search.toLowerCase()))
    .map((option, index) => {
      const isSelected =
        selectedValues && Boolean(selectedValues?.find(selectedValue => selectedValue === option.value));
      return (
        <SC.MenuItem onClick={() => handleItemSelection(option.value)} key={index} selected={isSelected}>
          <Checkbox checked={isSelected} />
          <SC.TypographyStyled variant="body3" checked={isSelected}>
            {option.label}
          </SC.TypographyStyled>
        </SC.MenuItem>
      );
    });

  return (
    <>
      {!disableSearch && (
        <SC.SearchInputStyled
          label={placeholder || 'Buscar'}
          onChange={e => setSearch(e.target.value)}
          onSearchChange={handleSearchChange}
          value={search}
        />
      )}
      <SC.ItemListContainer>
        {Array.isArray(itemsList) && itemsList?.length ? (
          itemsList
        ) : (
          <SC.EmptyText variant="body4">{t('atoms.checkboxList.empty')}</SC.EmptyText>
        )}
      </SC.ItemListContainer>
      {!disableFooter && (
        <SC.FooterContainer>
          <SC.ClearButton variant="default" onClick={handleOnClear}>
            {t('atoms.checkboxList.clean')}
          </SC.ClearButton>
          <SC.ApplyButton variant={'primary'} size={'medium'} onClick={handleOnApply}>
            {t('atoms.checkboxList.apply')}
          </SC.ApplyButton>
        </SC.FooterContainer>
      )}
    </>
  );
};
