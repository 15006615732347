import { useTranslation } from '@locale/Translator';
import { DangerActionModal } from '@molecules/Modal/DangerActionModal';

interface Props {
  open: boolean;
  isLoading: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

export const DeleteMileageRateConfirmationModal = ({ onCancel, onDelete, open, isLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <DangerActionModal
      open={open}
      headerIconLabel={t('molecules.deleteMileageRateConfirmModal.iconLabel')}
      headerTitle={t(`molecules.deleteMileageRateConfirmModal.title.singular`)}
      headerSubtitle={t(`molecules.deleteMileageRateConfirmModal.subtitle.singular`)}
      labelButtonAction={t(`molecules.deleteMileageRateConfirmModal.delete`)}
      onActionClick={onDelete}
      onCancelClick={onCancel}
      isLoading={isLoading}
    />
  );
};
