import { useParams } from 'react-router-dom';

import { IConfigurationClient } from '../../context/types/clientTypes';
import { useClientMutation } from './useClientMutation';

interface IUseClientRegisterPage {
  isLoading: boolean;
  onContinueClick(data: IConfigurationClient, step: number): void;
}
interface IProps {
  setStep(data): void;
}

export function useClientRegisterPage({ setStep }: IProps): IUseClientRegisterPage {
  const { handleSave, isLoading } = useClientMutation();
  const { id } = useParams();

  function onContinueClick(data: IConfigurationClient, step: number) {
    if (step === 0) {
      setStep(1);
    } else {
      const clientData = { ...data };
      if (clientData) {
        handleSave(clientData, id);
      }
    }
  }

  return {
    isLoading,
    onContinueClick,
  };
}
