import { useEffect, useState } from 'react';

export function useSelectionRows<T extends { id?: number | string }>(list: T[]) {
  const [selectedItems, setSelectedItems] = useState<T['id'][]>([]);

  function checkIsSelectedAll(): boolean {
    return selectedItems?.length >= list?.length && list?.length > 0;
  }

  function onSelectAllClick() {
    setSelectedItems(list.map(e => e.id));
  }

  function unSelectAllClick() {
    setSelectedItems([]);
  }

  function toggleSelectAll() {
    if (checkIsSelectedAll()) unSelectAllClick();
    else onSelectAllClick();
  }

  function onSelectItemClick(itemId: T['id']) {
    const isIdSelected = selectedItems.includes(itemId);

    if (isIdSelected) {
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  }

  useEffect(() => {
    list?.length &&
      selectedItems?.length &&
      setSelectedItems(selectedItems.filter(id => list?.some(_item => _item?.id === id)));
  }, [list]);

  return {
    selectedItems,
    isSelectedAll: checkIsSelectedAll(),
    onSelectItemClick,
    onSelectAllClick,
    unSelectAllClick,
    toggleSelectAll,
  };
}
