import { useContext, useEffect, useMemo, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { ExpenseReceipts } from '@containers/Expenses/components/organisms/ExpenseGrid/ExpenseReceipts/ExpenseReceipts';
import { TPendingExpenseByUser, TUserPendingExpense } from '@containers/Financial/context/types/users';
import { useExpensePendingByUser } from '@containers/Financial/hooks/dataSources/useExpensePendingByUser';
import { ReportStatusGrid } from '@containers/Reports/components/organisms/ReportGrid/ReportStatusGrid/ReportStatusGrid';
import { Icons, Menu, PillButton, tableControllers, Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Row } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types';
import { DropdownItem } from '@molecules/DropdownItem';
import { useSelectedTable } from '@organisms/Table/hooks/useSelectedTable';
import { MainRowColumn } from '@organisms/Table/MainRowColumn/MainRowColumn';
import { expensesAccountingBaseURL, expensesApprovalsBaseURL } from '@shared/constant';
import { PendingAccountContext } from '@shared/contexts/PendingAccountContext/PendingAccountContext';
import { useValueFormatter } from '@shared/hooks';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Router } from '../../../../../../../routes/Router';
import PaginationExpensesTable from './PaginationExpensesTable';
import * as SC from './styled';
import {
  EAccessor,
  EActionOptions,
  EBatchActionOptions,
  EIconAction,
  ERowType,
  TBatchActions,
  TColumns,
  TDataTable,
  TOptions,
  TUserExpenses,
} from './types';

export function TableHelper() {
  const t = useTranslate('organisms.accountingPending.expensesTable');
  const getCurrentUrlBase = useCurrentUrlBase();
  const navigate = useNavigate();

  const [userExpenses, setUserExpenses] = useState<TUserExpenses[]>([]);
  const [currencyUserExpenses, setCurrencyUserExpenses] = useState<TUserExpenses>(null);

  const { getValueWithCurrency } = useValueFormatter();
  const { users, pagination, setPagination: onPaginationChange } = useContext(PendingAccountContext);
  const { colors } = useTheme();
  const { response, isLoading: isSubRowLoading } = useExpensePendingByUser({
    filters: {
      userId: currencyUserExpenses?.userId,
      page: 1,
      pageSize: currencyUserExpenses?.limitPerPage,
    },
    shouldFetch: currencyUserExpenses?.shouldFetch || false,
  });
  const urlBase = getCurrentUrlBase();
  const _isManagement = urlBase === expensesAccountingBaseURL || urlBase === expensesApprovalsBaseURL;

  const table = tableControllers.useTableColumns({
    columns: useMemo(() => Object.values(EAccessor)?.map(value => getColumn(value)), [currencyUserExpenses]),
    options: getOptions(),
    data: users?.map(user => ({
      ...user,
      _rowType: ERowType.MainRow,
      subRows: [
        ...(getStoreUserExpenses(user.id)?.expenses || [])?.map(expense => ({
          ...expense,
          _rowType: ERowType.SubRow,
        })),

        <PaginationExpensesTable
          key={`pagination-${user?.id}`}
          user={user}
          isSubRowLoading={isSubRowLoading}
          currencyUserExpenses={currencyUserExpenses}
          setCurrencyUserExpenses={setCurrencyUserExpenses}
          getStoreUserExpenses={getStoreUserExpenses}
        />,
      ],
    })),
    onPaginationChange,
    pagination,
  });
  const { selectedIds, countSelectedRows, allSelected } = useSelectedTable<TDataTable>({
    selected: table.selected.selected,
    allSelected: table.selected.allSelected,
    rows: table.rows,
  });
  const disableMultiple: boolean = useMemo(() => false, []);
  const [isBatchAction, setIsBatchAction] = useState(false);

  function getStoreUserExpenses(userId: TUserPendingExpense['id']) {
    const userIdExpenses = userExpenses.find(data => String(data.userId) === String(userId));
    const defaultExpensesUser = {
      expenses: [],
      limitPerPage: 10,
      userId,
      totalItems: 0,
    };

    return userIdExpenses ? userIdExpenses : defaultExpensesUser;
  }

  function setStoreUserExpenses(currentData) {
    const userIdIndex = userExpenses.findIndex(data => data.userId === currentData?.userId);
    const userExpensesData = userExpenses;

    userIdIndex !== -1 ? (userExpensesData[userIdIndex] = currentData) : userExpensesData.push(currentData);
    setUserExpenses(userExpensesData);
  }

  function handleSubRowClick(rowData: Row<TDataTable>) {
    const userId = rowData.original?.id;
    const isExpanded = rowData.getIsExpanded();
    const userIdExpenses = getStoreUserExpenses(userId) as TUserExpenses;
    const hasExpenses = userIdExpenses.expenses.length;

    if (!isExpanded)
      !hasExpenses && setCurrencyUserExpenses({ ...userIdExpenses, shouldFetch: true, totalItems: response.total });
    else setCurrencyUserExpenses(null);

    rowData.toggleExpanded();
  }

  function getRows(accessor: EAccessor, rowData: Row<TDataTable>) {
    const user: TUserPendingExpense = rowData.original;

    switch (accessor) {
      case EAccessor.NAME:
        return (
          <MainRowColumn
            title={{
              description: user.name || t('uninformedCategory'),
            }}
            caption={{
              custom: (
                <SC.FlexContainer>
                  {`${user.pendingExpensesCount} ${t(
                    `body.${user.pendingExpensesCount !== 1 ? 'pendingExpensesCount' : 'totalPendingExpense'}`,
                  )}`}
                  {user?.violationCount > 0 && (
                    <Tooltip
                      arrow
                      title={t(`body.violationCount`, {
                        violationCount: user.violationCount,
                        expense:
                          user?.violationCount !== 1
                            ? `${t('body.pendingExpensesCount')}`
                            : `${t('body.totalPendingExpense')}`,
                      })}
                      placement="top-start">
                      <Icons name="IconAlertTriangle" size={16} color={colors.feedback.error[40]} />
                    </Tooltip>
                  )}
                </SC.FlexContainer>
              ),
            }}
            image={{
              alt: user.name || '',
            }}
          />
        );
      case EAccessor.EXPENSE_PENDING_AMOUNT:
        return (
          <>
            <SC.FlexContainer>
              <Typography variant="body3" weight={600}>
                {getValueWithCurrency({
                  value: user.expensePendingAmount || 0,
                  currencyPrefix: 'R$',
                })}
              </Typography>
            </SC.FlexContainer>
            {user?.totalReceiptNotFound !== 0 && (
              <SC.FlexContainer>
                {t(`body.totalReceiptNotFound`, { totalReceiptNotFound: user.totalReceiptNotFound })}
              </SC.FlexContainer>
            )}
          </>
        );
      case EAccessor.REPORTS_COUNT:
        return `${user.reportsCount || 0} ${t(`body.${user.reportsCount !== 1 ? 'reportsCount' : 'totalReport'}`)}`;
      case EAccessor.PAYMENT_METHODS:
        return (
          <SC.PaymentsContainer>
            {user.paymentMethods?.map((paymentMethod: string, index: number) => (
              <StatusTag key={index} variant="neutral">
                {paymentMethod}
              </StatusTag>
            ))}
          </SC.PaymentsContainer>
        );
      default:
        return (
          <SC.ActionMainRowContainer>
            {/* TODO: Add action button */}
            {/* <SC.ButtonActionMainRow variant="secondary" onClick={() => null} size="small">
              {t('action.request')}
              <Icons name="IconBellRinging" />
            </SC.ButtonActionMainRow> */}
            <PillButton
              type="secondary"
              loading={user?.id === currencyUserExpenses?.userId && isSubRowLoading}
              icon={rowData.getIsExpanded() ? 'IconChevronUp' : 'IconChevronDown'}
              variant="default"
              size="small"
              disabled={isSubRowLoading || user.pendingExpensesCount === 0}
              onClick={() => {
                handleSubRowClick(rowData);
              }}
            />
          </SC.ActionMainRowContainer>
        );
    }
  }

  function getSubRows(accessor: EAccessor, rowData: Row<TDataTable>) {
    const expense: TPendingExpenseByUser = rowData.original;

    switch (accessor) {
      case EAccessor.NAME:
        return (
          <MainRowColumn
            title={{
              description: expense.categoryName || '-',
            }}
            caption={{
              custom: (
                <SC.CaptionExpenseReport>
                  <>{expense?.id && `EXP-${expense?.id}`}</>
                  {expense.establishmentCompanyName && <SC.CircleDivider />}
                  {expense.establishmentCompanyName.length > 12 ? (
                    <Tooltip arrow title={expense.establishmentCompanyName} placement="top-start">
                      <span>{expense.establishmentCompanyName.substring(0, 12)}...</span>
                    </Tooltip>
                  ) : (
                    <>{expense.establishmentCompanyName}</>
                  )}
                </SC.CaptionExpenseReport>
              ),
            }}
            icon={{
              name: 'IconReceipt',
              variant: 'default',
            }}
          />
        );
      case EAccessor.EXPENSE_PENDING_AMOUNT:
        return (
          <MainRowColumn
            title={{
              description: getValueWithCurrency({
                value: expense?.totalValue || 0,
                currencyPrefix: 'R$',
              }),
            }}
            caption={{
              custom: (
                <ExpenseReceipts
                  attachments={expense?.receipt || []}
                  attachmentsNumber={expense.receipt?.length || 0}
                  variant="secondary"
                />
              ),
            }}
          />
        );
      case EAccessor.REPORTS_COUNT:
        return (
          <MainRowColumn
            title={{
              description: expense.report?.name || t('subRows.noReportsFound'),
            }}
            caption={{
              custom: expense.report.id && (
                <SC.CaptionExpenseReport>
                  <>{expense.report?.referenceId != null && expense.report?.referenceId}</>
                  <ReportStatusGrid status={expense.report?.status} />
                </SC.CaptionExpenseReport>
              ),
            }}
            icon={{
              name: !expense.report?.id ? 'IconFolderOff' : 'IconFolder',
              variant: !expense.report?.id ? 'neutral' : 'default',
            }}
          />
        );
      case EAccessor.PAYMENT_METHODS:
        return (
          expense.paymentMethod && (
            <SC.PaymentsContainer>
              <StatusTag variant="neutral">{expense.paymentMethod}</StatusTag>
            </SC.PaymentsContainer>
          )
        );
      default:
        return (
          <Menu
            type="default"
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            options={getActionsSubRows(expense)}>
            <SC.IconDots />
          </Menu>
        );
    }
  }

  function getColumn(accessor): TColumns[0] {
    return {
      header: t(`header.${accessor}`),
      accessorKey: accessor,
      sticky: accessor === EAccessor.ACTION ? 'right' : null,
      minSize: accessor === EAccessor.ACTION ? 80 : 200,
      cell: context => {
        switch (context.row.original._rowType) {
          case ERowType.MainRow:
            return getRows(accessor, context.row as Row<TDataTable>);
          case ERowType.SubRow:
            return getSubRows(accessor, context.row as Row<TDataTable>);
          default:
            return accessor === EAccessor.NAME ? context.row.original : <></>;
        }
      },
    };
  }

  function getBatchActionsOptions(): TBatchActions {
    return Object.keys(EBatchActionOptions)?.map(key => ({
      allowed: EBatchActionOptions[key] === EBatchActionOptions.DELETE,
      tooltip: t(`actionsGrid.tooltip.${EBatchActionOptions[key]}`),
      disabled: disableMultiple,
      label: t(
        `actionsGrid.pluralForm.${
          EBatchActionOptions[key] == EBatchActionOptions.UNLINK && _isManagement ? 'remove' : EBatchActionOptions[key]
        }`,
      ),
      icon: EIconAction[key],
      key: EBatchActionOptions[key],
      onClick: () => setIsBatchAction(true),
    }));
  }

  function onActionClick(key: EActionOptions, expense: TPendingExpenseByUser) {
    switch (key) {
      case EActionOptions.SEE_EXPENSE:
        navigate(Router.getAccountingExpenseDetails(expense?.id));
        break;
      case EActionOptions.SEE_REPORT:
        navigate(Router.getAccountingReportDetails(expense.report?.id));
        break;
    }
  }
  function getActionsSubRows(expense: TPendingExpenseByUser) {
    return Object.keys(EActionOptions)
      .filter(action => !(action === 'SEE_REPORT' && !expense.report?.id))
      .map(action => ({
        children: <DropdownItem description={t(`action.${EActionOptions[action]}`)} />,
        onClick: () => onActionClick(EActionOptions[action], expense),
      }));
  }

  function getOptions(): TOptions {
    return {
      selectable: null,
    };
  }

  useEffect(() => {
    if (currencyUserExpenses?.userId && !isSubRowLoading && response.expenses) {
      setStoreUserExpenses({
        ...currencyUserExpenses,
        expenses: response.expenses || [],
        shouldFetch: false,
        limitPerPage: response.limit,
        totalItems: response.total,
      });
      setCurrencyUserExpenses(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, isSubRowLoading]);

  return {
    tableHelper: table,
    actionsHelper: {
      events: null,
      batchActionsOptions: getBatchActionsOptions(),
    },
    selectedIds: {
      ids: selectedIds,
      countSelectedRows,
      allSelected,
      isBatchAction,
    },
  };
}
