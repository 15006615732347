import { DefaultModal } from '@molecules/Modal/DefaultModal';
import styled from 'styled-components';

export const Modal = styled(DefaultModal)`
  h4.typography--variant-headline6 {
    max-width: 251px !important;
    margin: ${props => props.theme.spacings.xs2} auto;
  }

  svg {
    color: ${props => props.theme.colors.primary} !important;
  }

  .typography--variant-body3 {
    max-width: 421px;
  }
`;
