import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const BorderedWrappingContainer = styled(BorderedWrapping)`
  padding: ${props => props.theme.spacings.xs};
  width: 759px;
`;

export const InfoContainer = styled.div``;

export const Title = styled(Typography)`
  font-size: 24px;
`;

export const Description = styled(Typography)`
  font-size: 14px;
`;

export const LinkReport = styled(LinkButton)`
  display: flex;
  gap: ${props => props.theme.spacings.xs4};
  align-items: end;
  margin-bottom: ${props => props.theme.spacings.xs3};
`;
