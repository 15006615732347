import { gql } from '@apollo/client';

export const getAllReports = gql`
  query GetReports($page: Int!, $limit: Int!) {
    getReports(page: $page, limit: $limit) {
      reports {
        id
        referenceId
        status
        description
        violationNumber
        attachmentsNumber
        commentsNumber
        name
      }
      total
      currentPage
      pages
    }
  }
`;

export const getSummaryReports = gql`
  query GetReports($page: Int!, $limit: Int!, $status: [ReportStatus!], $onlyWithPendencies: Boolean) {
    getReports(page: $page, limit: $limit, status: $status, onlyWithPendencies: $onlyWithPendencies) {
      reports {
        id
        referenceId
        name
      }
      pages
    }
  }
`;
