import { useState } from 'react';
import { IExpense } from '@containers/Expenses/context/types/expenseById';
import { DetachExpensesResponse, IReport } from '@containers/Reports/context/types';
import { useDetachExpensesFromReportMutate } from '@containers/Reports/hooks/useDetachExpensesFromReportMutate';
import { useTranslation } from '@locale/Translator';
import { showToast } from '@shared/helpers/toast';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';

export interface UseUnlinkExpenseProps {
  isReportDetails?: boolean;
  reportId?: IReport['id'];
  unSelectAll?(): void;
}

export interface UseUnlinkExpense {
  unlinkIsLoading: boolean;
  expensesToUnlink: IExpense['id'][];
  unlinkConfirmationIsOpen: boolean;
  removeConfirmationIsOpen: boolean;
  onUnlinkExpense(selectedItems: IExpense['id'][]): void;
  onCancelUnlinkExpenseClick(): void;
  onConfirmUnlinkExpenseClick(description?: string): void;
}

export function useUnlinkExpense({ reportId, unSelectAll }: UseUnlinkExpenseProps): UseUnlinkExpense {
  const { t } = useTranslation();
  const [unlinkConfirmationIsOpen, setUnlinkConfirmationIsOpen] = useState<boolean>(false);
  const [expensesToUnlink, setExpensesToUnlink] = useState<IExpense['id'][]>([]);
  const { detachExpensesFromReportMutate, isLoading } = useDetachExpensesFromReportMutate({
    refetchQueryType: 'report',
  });
  const formOfDescription = expensesToUnlink?.length > 1 ? 'pluralForm' : 'singularForm';
  const currentPath = useCurrentPath();
  const isApproval = currentPath.includes('approval') || currentPath.includes('accounting');

  function onUnlinkExpense(selectedItems: IExpense['id'][]) {
    setExpensesToUnlink(selectedItems);
    setUnlinkConfirmationIsOpen(true);
  }

  function onCancelUnlinkExpenseClick() {
    setExpensesToUnlink([]);
    setUnlinkConfirmationIsOpen(false);
  }

  async function onConfirmUnlinkExpenseClick(commentary?: string) {
    try {
      const response = await detachExpensesFromReportMutate({
        variables: {
          reportId,
          expenseIds: expensesToUnlink,
          commentary,
        },
      });
      const expenses: DetachExpensesResponse[] =
        response?.data?.detachExpensesFromReport.detachExpenseFromReportResponses;
      const successItems: boolean = expenses?.some(expense => !expense?.error);

      Boolean(expenses) &&
        expenses.forEach(expense => {
          expense?.error && showToast({ message: expense?.message, type: 'error' });
        });
      successItems &&
        showToast({
          message: t(`organisms.expenseGrid.actionsGrid.feedbacks.unlink.${formOfDescription}.success`),
          type: 'success',
        });

      setExpensesToUnlink([]);
      setUnlinkConfirmationIsOpen(false);
      unSelectAll?.();
    } catch (error) {
      showToast({
        message: error?.message || t(`organisms.expenseGrid.actionsGrid.feedbacks.unlink.${formOfDescription}.error`),
        type: 'error',
      });

      setExpensesToUnlink([]);
      setUnlinkConfirmationIsOpen(false);
    }
  }

  return {
    unlinkIsLoading: isLoading,
    expensesToUnlink,
    unlinkConfirmationIsOpen,
    removeConfirmationIsOpen: isApproval ? unlinkConfirmationIsOpen : false,
    onUnlinkExpense,
    onCancelUnlinkExpenseClick,
    onConfirmUnlinkExpenseClick,
  };
}
