import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

export const useSubsidiaryUsers = () => {
  const onError = error => showToast({ message: error.message, type: 'error' });

  const { data, isLoading, error } = useFetch<
    {
      id: string;
      name: string;
      subsidiaryId: string;
    }[]
  >(`v1/subsidiaries/get-users-for-selection`, '', undefined, undefined, onError);

  return {
    users: data || [],
    isLoading,
    error,
  };
};
