import { useMutation } from '@shared/hooks/service/apolloClient/useMutation';

import { detachExpensesFromReport } from '../context/queries';
import { IDetachExpensesFromReportRequest, IDetachExpensesFromReportResponse } from '../context/types';

export interface UseDetachExpensesFromReportMutate {
  refetchQueryType: 'report';
}

export function useDetachExpensesFromReportMutate({ refetchQueryType = 'report' }: UseDetachExpensesFromReportMutate) {
  const queries = {
    report: ['GetReportById', 'GetExpenses', 'GetAllExpensesToBind'],
  };

  const [detachExpensesFromReportMutate, { data, loading, error }] = useMutation<
    IDetachExpensesFromReportResponse,
    IDetachExpensesFromReportRequest
  >(detachExpensesFromReport, {
    refetchQueries: queries[refetchQueryType],
  });

  return {
    detachExpensesFromReportMutate,
    expenseCreated: data?.detachExpensesFromReport?.detachExpenseFromReportResponses || [],
    isLoading: loading,
    error,
  };
}
