import { AddCostCentersButton } from '@containers/CostCenters/components/molecules/AddCostCentersButton/AddCostCentersButton';
import { CostCentersPreview } from '@containers/CostCenters/components/organisms/CostCentersPreview/CostCentersPreview';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function CostCentersList() {
  const t = useTranslate('costCenters.page');

  return (
    <BasePage
      headerPage={{
        title: t('title'),
        caption: t('caption'),
        actions: <AddCostCentersButton />,
        breadcrumbItems: [
          {
            to: '/settings',
            label: t('breadcrumb.manageCompany'),
          },
          {
            to: '',
            label: t('title'),
          },
        ],
      }}>
      <CostCentersPreview />
    </BasePage>
  );
}
