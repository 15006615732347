import { showToast } from '@shared/helpers/toast';
import { HttpStatus } from '@shared/hooks/service/status.type';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ContractErrorCodes } from '../context/types/contracts';

export function useSignContract() {
  const t = useTranslate('molecules.acceptSubscriptionCheckbox.feedbacks.error');

  const { mutation } = useMutate(
    '/v1/contracts/sign',
    data => {
      const message: Record<ContractErrorCodes, string> = {
        COMPANY_ACCESS_REVOKED: t('companyRevoked'),
        EMPLOYEE_INACTIVE: t('employeeInactive'),
        CORPORATE_CARD_CONTRACT_NOT_FOUND: t('corporateCardContractNotFound'),
        SAAS_CONTRACT_NOT_FOUND: t('saasContractNotFound'),
      };

      const defaultMessage = !data?.message?.includes(`"host"`) ? data?.message : t('default');

      return showToast({ message: message[data.code] || defaultMessage, type: 'error' });
    },
    undefined,
    {
      disableDefaultErrors: [HttpStatus.FORBIDDEN],
    },
  );

  function signExpenseContracts() {
    return mutation.trigger({
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    });
  }

  return {
    signExpenseContracts,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
