import { DelegationPreview } from '@containers/Customizations/components/organisms/Delegation/DelegationPreview';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function DelegationAction() {
  const t = useTranslate('delegation.configuration');

  return (
    <BasePage
      headerPage={{
        title: t('title'),
      }}>
      <DelegationPreview userId={null} />
    </BasePage>
  );
}
