import { EReportsMainStatus } from '@containers/Approvals/context/types/reports';

import { EReportStatus } from '../context/types/report';

enum EReportStatusDescription {
  OPEN = 'open',
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  REPROVED = 'reproved',
  REIMBURSED = 'reimbursed',
  AWAITING_FINANCIAL_PROCESSING = 'awaitingFinancialProcessing',
  PROCESSING_PAYMENT = 'processingPayment',
  PROCESSING_PAYMENT_ERROR = 'processingPaymentError',
}

export const getReportStatusTranslator = (status: EReportStatus | EReportsMainStatus): string => {
  return `report.status.${EReportStatusDescription[status]}`;
};
