import { approvalFlowsBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useFetch } from '@shared/hooks/service/useFetch';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { IApprovalFlow } from '../../types/approvalFlow';

export const useCustomApprovalFlowById = (id: string) => {
  const t = useTranslate('approvalFlows.feedbacks.byId');
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });
  const path = id ? `v1/approvals/${id}` : null;
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const { data, error, isLoading } = useFetch<IApprovalFlow>(
    path,
    undefined,
    undefined,
    undefined,
    () => !!id && onError,
    !!id,
  );

  if (error?.statusCode === '500') {
    showToast({
      message: t('error'),
      type: 'error',
    });

    navigate(`${approvalFlowsBaseURL}/${currentPath.includes('reports') ? 'reports' : 'advances'}`, {
      replace: true,
    });
  }

  return {
    error,
    isLoading: isLoading,
    approvalFlow: data,
  };
};
