import { useState } from 'react';
import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { useClientToggleActivateFlag } from '@containers/Clients/hooks/rest/useClientToggleActivateFlag';
import { useDeleteClients } from '@containers/Clients/hooks/rest/useDeleteClients';
import { IActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { IBatchActionsActions } from '@organisms/Grid/BatchActions/BatchActions';
import { useSelectionRows } from '@organisms/Grid/BatchActions/hooks/useSelectionRows';
import { clientsBaseURL } from '@shared/constant';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { TClientsConfirmationActions } from '../ClientsGridConfirmationActionsModal/ClientsConfirmationActionsModal';

enum EActions {
  EDIT = 'edit',
  ACTIVE = 'active',
  DELETE = 'delete',
}

enum EIcons {
  EDIT = 'IconPencil',
  ACTIVE = 'IconToggleLeft',
  DELETE = 'IconTrash',
}

export function useClientsActionsGrid(clients: IConfigurationClient[]) {
  const t = useTranslate('clients.grid');
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [confirmationType, setConfirmationType] = useState<TClientsConfirmationActions>();
  const [isBatchAction, setIsBatchAction] = useState<boolean>(false);
  const [clientSelected, setSelected] = useState<IConfigurationClient>();
  const [clientsSelectedIds, setClientsSelectedIds] = useState<IConfigurationClient['id'][]>();
  const [clientSelectedActive, setSelectedActive] = useState<boolean>();
  const { isSelectedAll, onSelectItemClick, selectedItems, toggleSelectAll, unSelectAllClick } =
    useSelectionRows<IConfigurationClient>(clients);

  const { deleteClients, isLoading: isLoadingDelete } = useDeleteClients();
  const { toggle, listIdsLoading, isLoading: activeIsLoading } = useClientToggleActivateFlag();

  function onShowConfirmationActionsModal(): void {
    setShowConfirmationModal(_open => !_open);
  }

  async function onConfirmationActionModal() {
    if (confirmationType === 'deactivation') {
      await toggle(clientsSelectedIds, clientSelectedActive ?? false);
      unSelectAllClick();
    } else {
      await deleteClients(clientsSelectedIds);
      unSelectAllClick();
    }
    onShowConfirmationActionsModal();
  }

  function getSelectedClients(_id: IConfigurationClient['id']): IConfigurationClient {
    return clients?.find(_ => _?.id === _id);
  }

  function onActionClick(_actionKey: EActions, _id?: IConfigurationClient['id']) {
    const _clientsSelectedIds: IConfigurationClient['id'][] = _id ? [_id] : selectedItems;
    const _isBatchAction: boolean = _clientsSelectedIds?.length > 1;
    const _clients: IConfigurationClient = !_isBatchAction ? getSelectedClients(_clientsSelectedIds[0]) : null;

    setSelectedActive(_clients ? !_clients.active : null);
    setClientsSelectedIds(_clientsSelectedIds);
    _clients && setSelected(_clients);
    setIsBatchAction(_clientsSelectedIds?.length > 1);

    switch (_actionKey) {
      case EActions.ACTIVE:
        setConfirmationType('deactivation');
        if ((_clientsSelectedIds?.length === 1 && _clients.active) || _clientsSelectedIds?.length > 1) {
          onShowConfirmationActionsModal();
        } else {
          toggle([_clients.id], !_clients.active);
        }
        break;

      case EActions.DELETE:
        setConfirmationType('exclusion');
        onShowConfirmationActionsModal();
        break;

      default:
        setConfirmationType(null);
        navigate(`${clientsBaseURL}/register/${_id}`);
        break;
    }
  }

  function getActionsRow(_id: IConfigurationClient['id']): IActionsRow[] {
    return (
      clients?.length &&
      Object.keys(EActions).map(key => ({
        id: _id,
        key: EActions[key],
        allowed: true,
        iconName: EIcons[key],
        name: t(
          `body.actions.${
            EActions[key] === EActions.ACTIVE && getSelectedClients(_id)?.active ? 'deactivate' : EActions[key]
          }`,
        ),
        onClick: onActionClick,
      }))
    );
  }

  function getTooltipBatchActions(key: EActions): string {
    if (key === EActions.ACTIVE) {
      const projectsActiveList = selectedItems?.filter(selectedId =>
        clients?.some(project => project.id === selectedId && !project.active),
      );
      return projectsActiveList?.length > 0 ? t('header.batchActions.tooltips.active') : '';
    }
    return '';
  }

  function getBatchActions(): IBatchActionsActions[] {
    const batchActions: IBatchActionsActions[] = [];

    clients?.length &&
      Object.keys(EActions).forEach(key => {
        batchActions.push({
          key: EActions[key],
          allowed: EActions[key] !== EActions.EDIT,
          disabled: Boolean(getTooltipBatchActions(EActions[key])) && EActions[key] !== EActions.DELETE,
          iconName: EIcons[key],
          name: t(`header.batchActions.${EActions[key] === EActions.ACTIVE ? 'deactivate' : EActions[key]}`),
          tooltipMessage: getTooltipBatchActions(EActions[key]),
          onClick: _key => onActionClick(_key as EActions),
        });
      });

    return batchActions;
  }

  return {
    clientSelected,
    showConfirmationModal,
    confirmationType,
    isSelectedAll,
    selectedItems,
    isBatchAction,
    isLoadingAction: activeIsLoading || isLoadingDelete,
    listIdsLoading: listIdsLoading,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    onSelectItemClick,
    unSelectAllClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
  };
}
