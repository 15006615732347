import { EAnalyticsType } from '@containers/Analytics/context/types/filters';
import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

enum ERequestTypeAnalytics {
  EXPENSES_OVERVIEW = 'EXPENSE_ANALYTICS_OVERVIEW',
  EXPENSES_BY_CATEGORY = 'EXPENSE_ANALYTICS_BY_CATEGORY',
  EXPENSES_BY_USER = 'EXPENSE_ANALYTICS_BY_USER',
  CORPORATE_CARD_OVERVIEW = 'CORPORATE_CARD_ANALYTICS_OVERVIEW',
  CORPORATE_CARD_BY_USER = 'CORPORATE_CARD_PENDING_PAYMENT_ANALYTICS_BY_USER',
  REPORT_OVERVIEW = 'REPORT_ANALYTICS_OVERVIEW',
  REPORT_PENDING_APPROVALS = 'REPORT_PENDING_APPROVALS_ANALYTICS_BY_APPROVER',
  REPORT_STATUS_BY_USER = 'REPORT_STATUS_ANALYTICS_BY_USER',
  ADVANCES_OVERVIEW = 'ADVANCE_ANALYTICS_OVERVIEW',
  ADVANCES_STATUS_BY_USER = 'ADVANCE_ANALYTICS_BY_USER',
}

export function useSaveCustomAnalytics() {
  const { mutation } = useMutate('v1/analytics/custom-fields');
  const t = useTranslate('analytics.customizeReport.feedback');

  function getRequestTypeAnalytics(analyticsType: EAnalyticsType) {
    switch (analyticsType) {
      case EAnalyticsType.EXPENSES_PENDING_OVERVIEW:
        return ERequestTypeAnalytics.EXPENSES_OVERVIEW;
      case EAnalyticsType.CORPORATE_CARD_PENDING_OVERVIEW:
        return ERequestTypeAnalytics.CORPORATE_CARD_OVERVIEW;
      default:
        return ERequestTypeAnalytics[analyticsType];
    }
  }

  async function handleSave(analyticsType: EAnalyticsType, selectedFields: string[]) {
    try {
      const response = await mutation.trigger({
        path: '',
        options: {
          method: 'POST',
          body: JSON.stringify({
            selectedFields,
            requestTypeAnalytics: getRequestTypeAnalytics(analyticsType),
          }),
        },
      });

      showToast({
        message: t(response ? 'success' : 'error'),
        type: response ? 'success' : 'error',
      });

      return response;
    } catch (_error) {
      showToast({
        message: _error?.message || _error?.message?.message || t('error'),
        type: 'error',
      });
    }
  }

  return {
    handleSave,
    success: !mutation.error,
    isLoading: mutation.isMutating,
    error: mutation.error,
  };
}
