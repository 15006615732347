import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const BorderedWrappingContainer = styled(BorderedWrapping)`
  max-width: 1094px;
  width: 100%;
`;

export const BorderedWrappingContentContainer = styled.div`
  align-items: center;
  display: flex;
  padding: ${props => props.theme.spacings.xs};
  position: relative;
  width: 100%;
`;

export const OccurrencesContainer = styled.div`
  margin-left: ${props => props.theme.spacings.xs};
`;

export const OccurrenceList = styled.ul`
  margin-bottom: ${props => props.theme.spacings.xs4};
`;

export const OccurrenceItem = styled.li`
  margin-left: ${props => props.theme.spacings.xs};
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${props => props.theme.spacings.xs3};
  top: ${props => props.theme.spacings.xs3};
`;

export const OccurrencesTitle = styled(Typography)`
  font-weight: 600 !important;
  color: ${props => props.theme.colors.feedback.error[10]};
  margin-bottom: ${props => props.theme.spacings.xs5};
`;

export const OccurrencesDescription = styled(Typography)`
  line-height: 18px;
  font-size: 14px;
  font-weight: 400 !important;
  color: ${props => props.theme.colors.feedback.error[10]};
`;

export const OccurrencesLinkContainer = styled.div`
  margin-top: ${props => props.theme.spacings.xs4};
`;
