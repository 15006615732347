import { useContext } from 'react';
import { expensesAdvancesBaseURL, expensesBaseUrl } from '@shared/constant';
import { DelegateContext } from '@shared/contexts/DelegateContext/DelegateContext';
import { useCurrentUrlBase } from '@shared/hooks/useCurrentUrlBase';

export function useDelegationAllowed() {
  const { userDelegateContext } = useContext(DelegateContext);
  const getCurrentUrlBase = useCurrentUrlBase();
  const currentUrlBase = getCurrentUrlBase();

  return {
    delegationAllowed: currentUrlBase === expensesAdvancesBaseURL || currentUrlBase === expensesBaseUrl,
    userDelegateId: userDelegateContext?.id,
    delegationEnabled: Boolean(userDelegateContext?.id),
  };
}
