import { useState } from 'react';
import { BasicInformationApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { LinkButton, SelectField, TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { Grid } from '@mui/material';
import { SectionContainer } from '@organisms/SectionContainer/SectionContainer';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { FieldRegister } from '../../molecules/FieldRegister/FieldRegister';
import { IOptionsSource } from '../ApprovalCriteria/ApprovalCriteriaConditionalRow/hooks/useOptionsListCriteria';
import { ReorderingModal } from '../ReorderingModal/ReorderingModal';
import * as SC from './styled';

export interface IProps {
  isLoading?: boolean;
}

export function BasicInformation({ isLoading }: IProps) {
  const t = useTranslate('approvalFlows.basicInformation');
  const { id } = useParams<{ id: string }>();
  const { spacings } = useTheme();
  const { control, getValues } = useFormContext<BasicInformationApprovalFlowForm>();
  const [priorityOptions] = useState<IOptionsSource[]>(getPriorityOptions());
  const [prioritiesModalOpen, setPrioritiesModalOpen] = useState(false);

  function getPriorityOptions() {
    const totalApprovalFlows = getFromLS('totalApprovalFlows');
    const _options: IOptionsSource[] = [];
    const totalFlows = !Boolean(id) ? totalApprovalFlows + 1 : totalApprovalFlows;

    for (let index = 1; index <= totalFlows; index++) {
      _options.push({
        value: index,
        label: String(index),
      });
    }
    return _options;
  }

  return (
    <SC.Container>
      <SectionContainer title={t('title')}>
        <Grid container spacing={3} width={'100%'} maxWidth={'511px'}>
          <Grid item xs={12}>
            <FieldRegister isLoading={isLoading}>
              <Controller
                control={control}
                name="name"
                rules={{
                  validate: value => value?.length > 3,
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('fields.name.label')}
                    fullWidth
                    error={fieldState.invalid}
                    helperText={fieldState.invalid && t('fields.name.charactersInfo')}
                  />
                )}
              />
            </FieldRegister>
          </Grid>
          <Grid item xs={12}>
            <FieldRegister isLoading={isLoading}>
              <Grid container gap={spacings.xs4} alignItems="center" justifyContent="space-between">
                <Grid item xs={7}>
                  <Controller
                    control={control}
                    name="priority"
                    rules={{
                      required: {
                        value: !getValues('priority'),
                        message: t('fields.mandatoryInfo'),
                      },
                    }}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <SelectField
                        {...field}
                        inputRef={ref}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        label={t('fields.priority.label')}
                        onSelectChange={(_, data) => field.onChange(data.value)}
                        options={priorityOptions}
                        disabled={!Boolean(id)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} alignItems="flex-end">
                  <LinkButton disabled={isLoading} variant="default" onClick={() => setPrioritiesModalOpen(true)}>
                    {t('link')}
                  </LinkButton>
                </Grid>
              </Grid>
            </FieldRegister>
          </Grid>
          <Grid item xs={12}>
            <FieldRegister isLoading={isLoading} height={125}>
              <Controller
                control={control}
                name="description"
                rules={{
                  validate: value => value?.length > 3,
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('fields.description.label')}
                    fullWidth
                    rows={4}
                    multiline
                    error={fieldState.invalid}
                    helperText={fieldState.invalid && t('fields.description.charactersInfo')}
                  />
                )}
              />
            </FieldRegister>
          </Grid>
        </Grid>
      </SectionContainer>
      {prioritiesModalOpen && (
        <ReorderingModal
          id={id ? Number(id) : null}
          open={prioritiesModalOpen}
          onClose={() => setPrioritiesModalOpen(false)}
          setPrioritiesModalOpen={setPrioritiesModalOpen}
        />
      )}
    </SC.Container>
  );
}
