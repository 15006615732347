import { Button, LinkButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import { EAddLimitType } from '../../SelectLimitTypeModal';

interface IProps {
  type: EAddLimitType;
  isEdit: boolean;
  isLoading: boolean;
  actionDisabled: boolean;
  tooltipMessageCustom?: string;
  onCancelClick(): void;
  onActionClick(): void;
}

export function LimitToCategoryModalFooter({
  type,
  isEdit,
  isLoading,
  actionDisabled,
  tooltipMessageCustom,
  onActionClick,
  onCancelClick,
}: IProps) {
  const { t } = useTranslation();

  function getTooltipMessage() {
    let message: string = '';

    if (actionDisabled) {
      message = t(tooltipMessageCustom ?? `molecules.addLimitToCategoryModal.tooltipAction.${type}`);
    }
    return message;
  }

  return (
    <>
      <div>
        <LinkButton variant="default" onClick={onCancelClick}>
          {t('molecules.defaultModal.cancel')}
        </LinkButton>
      </div>
      <div>
        <Tooltip arrow title={getTooltipMessage()} placement="top-start">
          <div>
            <Button
              size="large"
              variant="primary"
              loading={isLoading}
              disabled={actionDisabled}
              onClick={onActionClick}>
              {t(`molecules.addLimitToCategoryModal.${isEdit ? 'edit' : 'add'}`)}
            </Button>
          </div>
        </Tooltip>
      </div>
    </>
  );
}
