export const ID = 'Id (Uso interno)';
export const PROJECT_NAME = 'Nome Projeto';
export const REFERENCE_ID = 'Referência/Código do Projeto';
export const COST_CENTER = 'Centro de custo';
export const CLIENT = 'Nome Cliente';
export const ACTIVE = 'Projeto Ativo';
export const INITIAL_DATE = 'Data Início';
export const END_DATE = 'Data Fim';
export const RESPONSIBLE_EMAIL = 'E-mail Responsável';

export const TEMPLATE_FIELDS = [
  ID,
  PROJECT_NAME,
  REFERENCE_ID,
  COST_CENTER,
  CLIENT,
  ACTIVE,
  INITIAL_DATE,
  END_DATE,
  RESPONSIBLE_EMAIL,
];
