import { useContext, useState } from 'react';
import { useCostCenterFileRegister } from '@containers/CostCenters/hooks/rest/useCostCenterFileRegister';
import { ESizeVariant } from '@containers/Expenses/components/molecules/ReceiptsImages/variants';
import { useTranslation } from '@locale/Translator';
import { BulkImportDropzone } from '@molecules/BulkImportDropzone';
import { ImportContainer } from '@molecules/ImportContainer';
import { Loader } from '@pages/ClientRegister/Loader';
import { ImportingContext } from '@shared/contexts/BulkImportContext';
import { showToast } from '@shared/helpers/toast';
import { Controller, useFormContext } from 'react-hook-form';

import * as SC from './styled';

export const RegisterCostCenterByFileForm = () => {
  const { t } = useTranslation();
  const { isLoading, importCostCenters, download, isLoadingUpload } = useCostCenterFileRegister();
  const { handleSubmit, control } = useFormContext();
  const [hasFile, setHasFile] = useState(false);
  const [fileName, setFileName] = useState('');
  const { state } = useContext(ImportingContext);

  const onDropzoneChange = async (e, onChange) => {
    if (e?.length > 0) {
      onChange(e[0].file);
      setHasFile(true);
      setFileName(e[0]?.file?.name);
    } else {
      setHasFile(false);
      setFileName('');
      showToast({ message: t('organisms.registerClient.bulkImport.fileModel.warning'), type: 'warning' });
    }
  };
  return (
    <SC.Container>
      <ImportContainer
        title={t('organisms.registerCostCenter.bulkImport.fileModel.title')}
        subTitle={t('organisms.registerCostCenter.bulkImport.fileModel.subTitle')}>
        <SC.FileDownload fileName="Modelo-import-centro-de-custo.xlsx" onClick={download} />
      </ImportContainer>
      <ImportContainer
        title={t('organisms.registerCostCenter.bulkImport.sendFile.title')}
        subTitle={t('organisms.registerCostCenter.bulkImport.sendFile.subTitle')}>
        <SC.DropzoneContainer size={ESizeVariant.small} hasFile={hasFile} onSubmit={handleSubmit(importCostCenters)}>
          <Controller
            control={control}
            name={'file'}
            render={({ field }) => (
              <BulkImportDropzone
                fileName={fileName}
                hasFile={hasFile}
                onDropzoneChange={file => onDropzoneChange(file, field.onChange)}
              />
            )}
          />
        </SC.DropzoneContainer>
      </ImportContainer>
      <Loader show={isLoading || isLoadingUpload || state.importingResponseIsLoading} />
    </SC.Container>
  );
};
