import { useMemo, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { AddLimitSuccessModal } from '@containers/Customizations/components/molecules/AddLimitSuccessModal';
import { AddLimitToCategoryModal } from '@containers/Customizations/components/molecules/AddLimitToCategoryModal';
import {
  EAddLimitType,
  SelectLimitTypeModal,
} from '@containers/Customizations/components/molecules/SelectLimitTypeModal';
import { ILimitGrid } from '@containers/Customizations/context/types/limits';
import { useCategoriesLimitByPolicyId } from '@containers/Customizations/hooks/useCategoriesLimitByPolicyId';
import { useCategoryLimitPolicyMutate } from '@containers/Customizations/hooks/useCategoryLimitPolicyMutate';
import { useCategoriesWithPolicies } from '@containers/Expenses/hooks/dataSource/useCategoriesWithPolicies';
import { useCurrencies } from '@containers/Settings/hooks/useCurrency';
import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { useTranslation } from 'react-i18next';

import { LimitsGrid } from '../LimitsGrid';
import { useLimitSelectionRows } from '../LimitsGrid/hooks/useLimitSelectionRows';
import * as SC from './styled';

interface LimitsPreviewProps {
  policyId: number;
  isLoading?: boolean;
}

interface IAddLimitModal {
  open: boolean;
  type: EAddLimitType;
}

export const LimitsPreview = ({ policyId, isLoading }: LimitsPreviewProps) => {
  const { t } = useTranslation();
  const { checkPlatformPermission } = usePlatformPermissions();
  const allowedActions: boolean = checkPlatformPermission(EAuthZPermission.CUSTOMIZATION_LIMIT_ACTION);
  const [openAddLimitModal, setOpenAddLimitModal] = useState<IAddLimitModal>({
    open: false,
    type: EAddLimitType.SINGLE,
  });
  const { categoriesLimitByPolicy, isLoading: isLoadingCategories } = useCategoriesLimitByPolicyId({
    policyId,
  });
  const [openSelectLimitTypeModal, setOpenSelectLimitTypeModal] = useState(false);
  const [openAddLimitSuccessModal, setOpenAddLimitSuccessModal] = useState(false);
  const [categoriesAdded, setCategoriesAdded] = useState([]);
  const { categories: categoriesWithPolicies, isLoading: categoriesWithPoliciesIsLoading } = useCategoriesWithPolicies({
    policyId: policyId ? String(policyId) : null,
  });
  const categoryOptions = useMemo(
    () =>
      !categoriesWithPoliciesIsLoading && categoriesWithPolicies
        ? categoriesWithPolicies
            ?.filter(category => category.active)
            ?.map(category => ({
              label: category.name,
              value: category.id,
            }))
        : [],
    [JSON.stringify(categoriesWithPolicies)],
  );
  const { currencies, selectFieldTypeCurrencies } = useCurrencies();
  const [limitData, setLimitData] = useState(null);
  const { activateDeactivateLimit, deleteLimit, isLoading: isLoadingLimitsMutate } = useCategoryLimitPolicyMutate();
  const { selectedItems, isSelectedAll, isEnableSelectedItems, toggleSelectAll, onSelectItemClick, unSelectAllClick } =
    useLimitSelectionRows({
      limits: categoriesLimitByPolicy,
    });

  const handleShowModals = ({
    openAddLimitModal = false,
    openAddLimitModalSuccess = false,
    addLimitModalType,
    openSelectLimitModal = false,
    categories,
    limit,
  }: {
    openAddLimitModal?: boolean;
    addLimitModalType?: EAddLimitType;
    openAddLimitModalSuccess?: boolean;
    openSelectLimitModal?: boolean;
    categories?: string[];
    limit?: ILimitGrid;
  }) => {
    setOpenAddLimitModal(prevState => {
      const newState = { ...prevState };
      if (addLimitModalType) {
        newState.type = addLimitModalType;
      }
      newState.open = openAddLimitModal;
      return newState;
    });
    setOpenAddLimitSuccessModal(openAddLimitModalSuccess);
    setOpenSelectLimitTypeModal(openSelectLimitModal);
    categories && setCategoriesAdded(categories);
    setLimitData(limit);
  };

  function onSelectLimitType(_type: EAddLimitType) {
    setOpenAddLimitModal({ open: true, type: _type });
    setOpenSelectLimitTypeModal(false);
  }

  function getLimitType(limit: ILimitGrid): EAddLimitType {
    return limit.categoryLimitsPolicy.length > 1 ? EAddLimitType.SHARED : EAddLimitType.SINGLE;
  }

  return (
    <>
      <SC.ContainerAction>
        <SC.ContainerInfo>
          <SC.TitleAction variant="headline9">
            {t('policy.register.categories.actionTitle')}
            <StatusTag size="small" variant="neutral">
              {t('policy.register.categories.optional')}
            </StatusTag>
          </SC.TitleAction>
          <SC.CaptionAction variant="body3">{t('policy.register.categories.actionCaption')}</SC.CaptionAction>
        </SC.ContainerInfo>
        {allowedActions && (
          <Button
            style={{ minWidth: '220px' }}
            size="medium"
            variant="primary"
            onClick={() => setOpenSelectLimitTypeModal(true)}>
            <Icons name="IconPlus" fill="transparent" />
            {t('organisms.limitsPreview.add')}
          </Button>
        )}
      </SC.ContainerAction>

      <LimitsGrid
        isLoading={isLoadingLimitsMutate || isLoadingCategories || isLoading}
        isSelectedAll={isSelectedAll}
        limits={categoriesLimitByPolicy}
        allowedActions={allowedActions}
        onDeleteClick={policyLimitId => {
          deleteLimit(policyLimitId, policyId);
          unSelectAllClick();
        }}
        onDisableClick={(id: number[], active: boolean) => {
          activateDeactivateLimit({ policyId, policyLimitId: id, active });
        }}
        onSelectAllClick={toggleSelectAll}
        onSelectItemClick={(id: number) => onSelectItemClick(id)}
        selectedItems={selectedItems}
        onEditClick={limit =>
          handleShowModals({ openAddLimitModal: true, addLimitModalType: getLimitType(limit), limit })
        }
        isEnableSelectedItems={isEnableSelectedItems}
      />
      <AddLimitToCategoryModal
        open={openAddLimitModal.open}
        type={openAddLimitModal.type}
        currencyOptions={selectFieldTypeCurrencies}
        currencies={currencies}
        categories={categoryOptions}
        policyId={policyId}
        handleSuccessModal={handleShowModals}
        limit={limitData}
        isEdit={Boolean(limitData)}
      />
      <SelectLimitTypeModal
        onSelectLimitType={onSelectLimitType}
        open={openSelectLimitTypeModal}
        onClose={() => setOpenSelectLimitTypeModal(false)}
      />
      <AddLimitSuccessModal
        open={openAddLimitSuccessModal}
        categories={categoriesAdded}
        onClose={() => handleShowModals({})}
        onContinueAdding={() => handleShowModals({ openAddLimitModal: true })}
      />
    </>
  );
};
