import { useState } from 'react';
import { EActionGrid, EActionIcon, IPolicyBase } from '@containers/Policies/context/types/types';
import { usePolicyMutate } from '@containers/Policies/hooks/dataSources/usePolicyMutate';
import { IActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { IBatchActionsActions } from '@organisms/Grid/BatchActions/BatchActions';
import { useSelectionRows } from '@organisms/Grid/BatchActions/hooks/useSelectionRows';
import { expensesCustomizationsBaseURL } from '@shared/constant';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useNavigate } from 'react-router-dom';

import { IFiltersPolicy } from '../componentes/molecules/PolicyGridFilters/PolicyGridFilters';

export function useActions(policies: IPolicyBase[] = [], isGridActions: boolean = false) {
  const t = useTranslate('policy.policyGrid');
  const navigate = useNavigate();
  const [confirmationType, setConfirmationType] = useState<EActionGrid>(null);
  const [openConfirmationAction, setOpenConfirmationAction] = useState<boolean>(false);
  const [selectedPolicyIds, setSelectedPolicyIds] = useState<IPolicyBase['id'][]>([]);
  const [toggleAction, setToggleAction] = useState<boolean>(false);
  const [isBatchAction, setIsBatchAction] = useState<boolean>(false);
  const { isSelectedAll, onSelectItemClick, selectedItems, toggleSelectAll, unSelectAllClick } =
    useSelectionRows<IPolicyBase>(policies);
  const { deletePolicy, tootlePolicy, duplicatePolicy, handleSave, isLoading } = usePolicyMutate();
  const [filters, setFilters] = useState<IFiltersPolicy>();
  const { checkLicenseFeature } = useLicenseFeatures();

  async function onActionConfirmed() {
    if (confirmationType === EActionGrid.DELETE) {
      await deletePolicy(selectedPolicyIds);
    } else {
      await tootlePolicy(selectedPolicyIds, toggleAction);
    }

    unSelectAllClick();
    setConfirmationType(null);
    setOpenConfirmationAction(false);
    setToggleAction(null);
    setSelectedPolicyIds([]);
  }

  async function onActionClick(actionKey: EActionGrid, id?: IPolicyBase['id'], active?: boolean) {
    const selectedIds: IPolicyBase['id'][] = id ? [id] : selectedItems;
    setSelectedPolicyIds([...selectedPolicyIds, ...selectedIds]);
    setIsBatchAction(selectedIds?.length > 1);

    switch (actionKey) {
      case EActionGrid.DELETE:
        if (isGridActions) {
          setConfirmationType(EActionGrid.DELETE);
          setOpenConfirmationAction(true);
        } else {
          await deletePolicy(selectedIds);
          setSelectedPolicyIds([]);
        }
        break;
      case EActionGrid.TOGGLE:
        setToggleAction(active);

        if (isGridActions && !active) {
          setConfirmationType(EActionGrid.TOGGLE);
          setOpenConfirmationAction(true);
        } else {
          await tootlePolicy(selectedIds, active);
          setSelectedPolicyIds([]);
        }
        break;
      case EActionGrid.DUPLICATE:
        await duplicatePolicy(id);
        setSelectedPolicyIds([]);
        break;
      case EActionGrid.EDIT:
        navigate(`${expensesCustomizationsBaseURL}/policy/register/${id}`);
        break;
      case EActionGrid.SEE_DETAILS:
        navigate(`${expensesCustomizationsBaseURL}/policy/register/${id}`);
        break;
    }
  }

  function getActionsRow(_id: IPolicyBase['id']): IActionsRow[] {
    const actions: IActionsRow[] = [];

    policies?.length &&
      Object.keys(EActionGrid).forEach(key => {
        if (
          EActionGrid[key] === EActionGrid.EDIT ||
          (EActionGrid[key] === EActionGrid.DELETE && checkLicenseFeature('ADVANCED_EXPENSE')) ||
          (EActionGrid[key] === EActionGrid.DUPLICATE && checkLicenseFeature('ADVANCED_EXPENSE'))
        )
          actions.push({
            id: _id,
            key: EActionGrid[key],
            allowed: true,
            iconName: EActionIcon[key],
            name: t(`columns.actions.${EActionGrid[key]}`),
            onClick: onActionClick,
          });
      });

    return actions;
  }

  function getBatchActions(): IBatchActionsActions[] {
    const batchActions: IBatchActionsActions[] = [];

    policies?.length &&
      Object.keys(EActionGrid).forEach(key => {
        if (EActionGrid[key] === EActionGrid.DELETE)
          batchActions.push({
            key: EActionGrid[key],
            allowed: EActionGrid[key] !== EActionGrid.EDIT,
            iconName: EActionIcon[key],
            name: t(`columns.actions.${EActionGrid[key]}`),
            onClick: _key => onActionClick(_key as EActionGrid),
          });
      });

    return batchActions;
  }

  function onCloseModal() {
    setSelectedPolicyIds(null);
    setOpenConfirmationAction(false);
    setConfirmationType(null);
  }

  return {
    selectedPolicies: selectedItems,
    confirmationType,
    openConfirmationAction,
    selectedItems,
    isSelectedAll,
    isBatchAction,
    isLoadingAction: isLoading,
    listIdsLoading: selectedPolicyIds,
    plurality: selectedPolicyIds?.length > 1 ? 'plural' : 'singular',
    filters,
    setFilters,
    onActionClick,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    onSelectItemClick,
    unSelectAllClick,
    onActionConfirmed,
    handleSave,
    onCloseModal,
  };
}
