import { IReport } from '@containers/Reports/context/types';
import { Checkbox, IconButton, ShapeIcon, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { useDateFormatter } from '@shared/hooks/formatters/useDateFormatter';
import { useValueFormatter } from '@shared/hooks/formatters/useValueFormatter';
import { useWithdrawPermissions } from '@shared/hooks/permissions/systemPermissions/useWithdrawPermissions';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useTheme } from 'styled-components';

import { WithdrawConfirmationActionsModal } from '../WithdrawLinkedReport/WithdrawConfirmationActionsModal';
import { useWithdrawActionsGrid } from './hooks/useWithdrawActionsGrid';
import * as SC from './styled';
interface IProps {
  report: IReport;
  isLoading: boolean;
  reportByIdUpDate: () => void;
}

enum EAccessor {
  SELECT_ALL = 'selectAll',
  CARD = 'card',
  DATE = 'date',
  VALUE = 'value',
  ACTIONS = 'actions',
}

export function WithdrawGrid({ report, isLoading, reportByIdUpDate }: IProps) {
  const { getDateOnlyFormatter } = useDateFormatter();
  const { getValueWithCurrency } = useValueFormatter();
  const t = useTranslate('withdraw.grid');
  const getWithdrawPermissions = useWithdrawPermissions();
  const { unlink: allowedUnlink } = getWithdrawPermissions(report?.status ?? null);
  const {
    isBatchAction,
    isLoadingAction,
    isSelectedAll,
    selectedItems,
    showConfirmationModal,
    toggleSelectAll,
    onActionClick,
    onSelectItemClick,
    getBatchActions,
    getMessageTooltip,
    onConfirmationAction,
    closeConfirmationModal,
  } = useWithdrawActionsGrid(report, reportByIdUpDate);
  const { colors } = useTheme();

  function getColumns(): IGridColumn[] {
    return Object.values(EAccessor).map(accessor => ({
      accessor,
      disableSortBy: true,
      Header: (
        <ColumnHeader
          isLoading={isLoading}
          isLoadingAction={isLoading || isLoadingAction}
          description={t(`header.columns.${accessor}`)}
          isSelectedAll={accessor === EAccessor.SELECT_ALL}
          onSelectAllClick={toggleSelectAll}
          selectAllChecked={isSelectedAll}
        />
      ),
      sticky: accessor === EAccessor.ACTIONS && 'right',
    }));
  }

  function getRows(): Record<EAccessor, React.ReactNode>[] {
    return report?.withdraw?.map(_withdraw => {
      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(_withdraw?.userIdCard)}
            checked={selectedItems.some(_id => _id === _withdraw?.userIdCard)}
          />
        ),
        card: (
          <SC.ContainerIcon>
            <ShapeIcon name="IconCreditCard" size={40} variant="default" color={colors.primary} />
            <SC.CardNumber>{_withdraw.cardNumber}</SC.CardNumber>
          </SC.ContainerIcon>
        ),
        date: getDateOnlyFormatter(_withdraw.date),
        value: getValueWithCurrency({ value: _withdraw.value }),
        actions: (
          <div>
            <Tooltip arrow title={allowedUnlink ? t('body.actions.unlink') : getMessageTooltip()} placement="top">
              <div style={{ width: 'min-content' }}>
                <IconButton
                  icon="IconUnlink"
                  variant="line"
                  size="small"
                  disabled={!allowedUnlink}
                  onClick={() => onActionClick('unlink', _withdraw.userIdCard)}
                />
              </div>
            </Tooltip>
          </div>
        ),
      };
    });
  }

  return (
    <>
      <Grid
        columns={getColumns()}
        rows={getRows() || []}
        batchActions={getBatchActions()}
        isLoading={isLoading || isLoadingAction}
        totalItems={report?.withdraw?.length || 0}
        numberItemsSelected={selectedItems?.length || 0}
        rowsLoading={Boolean(report?.withdraw?.length) ? report?.withdraw?.length : 4}
        rowsPerPage={20}
        showRowsPerPageOptions={false}
      />
      <WithdrawConfirmationActionsModal
        open={showConfirmationModal}
        onActionClick={onConfirmationAction}
        onClose={closeConfirmationModal}
        isBatchAction={isBatchAction}
        isLoading={isLoadingAction}
      />
    </>
  );
}
