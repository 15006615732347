import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
  white-space: nowrap;

  .shape-icon-table {
    padding: 5px;
  }
`;
