import { Skeleton } from '@mui/material';

import * as SC from './styled';

interface IProps {
  main: boolean;
}

export const CardFilterSkeleton = ({ main }: IProps) => {
  return (
    <SC.Container main={main} data-testid="card-filter-skeleton">
      <div>
        <SC.CardSkeletonTitle>
          <SC.StatusDescriptionContainer isSelected={true}>
            <Skeleton variant="rounded" height={20} width={175} style={{ marginBottom: '4px' }} />
          </SC.StatusDescriptionContainer>

          <SC.IconFolderContainer isSelected={true}>
            <Skeleton variant="circular" height={24} width={24} />
          </SC.IconFolderContainer>
        </SC.CardSkeletonTitle>
        <Skeleton variant="rounded" height={30} width={110} style={{ marginBottom: '14px' }} />
        <Skeleton variant="rounded" height={22} width={110} />
      </div>
    </SC.Container>
  );
};
