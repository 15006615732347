import { IContext } from './types';

const initialState: IContext = {
  currentState: {
    info: 'Expense Management initial info',
  },
  dispatchAction: () => null,
};

export { initialState };
