import { Dispatch, SetStateAction } from 'react';
import { AdvancesGrid } from '@containers/Advances/components/organisms/AdvancesGrid';
import { EAdvanceStatus } from '@containers/Advances/context/types/advance';
import { IAdvanceForApprovalListFilters } from '@containers/Approvals/context/types/advances';
import { useAdvancesFilters } from '@containers/Financial/components/organisms/Advances/Filters/hooks/useAdvancesFilters';

import * as SC from './styled';

interface IProps {
  setAdvancesQuantity: Dispatch<SetStateAction<number>>;
}

export function AdvancesGridPreview({ setAdvancesQuantity }: IProps) {
  const { filters } = useAdvancesFilters<IAdvanceForApprovalListFilters>({
    initialFilters: { status: [EAdvanceStatus.SUBMITTED] },
  });
  return (
    <SC.Container>
      <AdvancesGrid setAmountAdvances={setAdvancesQuantity} showSearch={false} filters={filters} />
    </SC.Container>
  );
}
