import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StatusTag } from '@atoms/StatusTag';
import { IConfigurationClient } from '@containers/Clients/context/types/clientTypes';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import { Search } from '@molecules/GridFilters/Search/Search';
import { ActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';
import { Grid, IGridColumn } from '@organisms/Grid/Grid';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { ClientsConfirmationActionsModal } from './ClientsGridConfirmationActionsModal/ClientsConfirmationActionsModal';
import { useClientsActionsGrid } from './hooks/useClientsActionsGrid';
import * as SC from './styled';

enum EAccessor {
  SELECT_ALL = 'selectAll',
  CLIENT = 'client',
  PHONE = 'phone',
  STATUS = 'status',
  ACTIONS = 'actions',
}

type TProps = {
  clients: IConfigurationClient[];
  total: number;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  searchTerm: string;
};

export function ClientsGrid({ clients, total, isLoading, page, setPage, searchTerm, setSearchTerm }: TProps) {
  const t = useTranslate('clients.grid');
  const [currentClients, setCurrentClients] = useState<IConfigurationClient[]>(clients);
  const {
    showConfirmationModal,
    isSelectedAll,
    selectedItems,
    confirmationType,
    isBatchAction,
    isLoadingAction,
    listIdsLoading,
    getActionsRow,
    getBatchActions,
    toggleSelectAll,
    onSelectItemClick,
    unSelectAllClick,
    onShowConfirmationActionsModal,
    onConfirmationActionModal,
  } = useClientsActionsGrid(clients);

  function getColumns(): IGridColumn[] {
    return Object.values(EAccessor).map(accessor => ({
      accessor,
      disableSortBy: true,
      Header: (
        <ColumnHeader
          isLoading={isLoading && !Boolean(searchTerm) && !Boolean(currentClients.length)}
          isLoadingAction={isLoading}
          description={t(`header.columns.${accessor}`)}
          isSelectedAll={accessor === EAccessor.SELECT_ALL}
          onSelectAllClick={toggleSelectAll}
          selectAllChecked={isSelectedAll}
        />
      ),
      sticky: accessor === EAccessor.ACTIONS && 'right',
    }));
  }

  function getRows(): Record<EAccessor, React.ReactNode>[] {
    return clients?.map(_client => {
      return {
        selectAll: (
          <Checkbox
            size="small"
            onChange={() => onSelectItemClick(_client?.id)}
            checked={selectedItems.some(_id => _id === _client?.id)}
          />
        ),
        client: (
          <SC.TdContent>
            <SC.ClientInfosContainer>
              <SC.Description>{_client.name || t('uninformedClient')}</SC.Description>
              <SC.EmailContainer>
                <SC.MailIcon name="IconMail" />
                <SC.Email variant="body4">{_client.email || t('uninformedEmailClient')}</SC.Email>
              </SC.EmailContainer>
            </SC.ClientInfosContainer>
          </SC.TdContent>
        ),
        phone: <SC.Description>{_client.phoneNumber || '-'}</SC.Description>,
        status: (
          <StatusTag showStatusIcons variant={_client.active ? 'success' : 'error'}>
            {t(`${_client.active ? 'active' : 'inactive'}`)}
          </StatusTag>
        ),
        actions: (
          <ActionsRow
            referenceId={_client.id}
            actions={getActionsRow(_client.id)}
            isLoading={listIdsLoading?.includes(_client.id)}
          />
        ),
      };
    });
  }

  function getFilterItemsList() {
    return clients?.filter(_client => _client?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
  }

  useEffect(() => {
    if (clients && !isLoading) {
      setCurrentClients(Boolean(searchTerm) ? getFilterItemsList() : clients || []);
    }
  }, [clients, searchTerm]);

  return (
    <SC.Container>
      <Search
        label={t('searchFieldLabel')}
        onChange={data => {
          selectedItems.length && unSelectAllClick();
          setSearchTerm(data);
        }}
        isLoading={isLoading && !Boolean(searchTerm) && !Boolean(currentClients.length)}
      />
      <Grid
        columns={getColumns()}
        rows={getRows()}
        batchActions={getBatchActions()}
        isLoading={isLoading}
        numberItemsSelected={selectedItems?.length || 0}
        hasPagination
        totalItems={total || 0}
        rowsLoading={Boolean(currentClients.length) ? currentClients.length : 4}
        rowsPerPage={20}
        currentPage={page}
        paginationIsDisabled={isLoading}
        onPageClick={_page => setPage(_page)}
        showRowsPerPageOptions={false}
        pageCount={20}
      />
      {showConfirmationModal && (
        <ClientsConfirmationActionsModal
          open={showConfirmationModal}
          confirmationType={confirmationType}
          onActionClick={onConfirmationActionModal}
          onClose={onShowConfirmationActionsModal}
          isBatchAction={isBatchAction}
          isLoading={isLoadingAction}
        />
      )}
    </SC.Container>
  );
}
