import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ActionsButtonsFooterContainer = styled.div`
  position: relative;
  display: flex;
`;

export const ButtonContent = styled.div`
  display: grid;
  gap: ${props => props.theme.spacings.xs4};
`;

export const ContainerModals = styled.div`
  position: absolute;
`;
