import { EReportStatus, IReport } from '@containers/Reports/context/types';

import { IEstablishment } from './establishments';
import { IRoute } from './expenseById';
import { IFileResponse } from './files';

export interface IExpenses {
  id: number;
  referenceId: string;
  type?: EExpenseType;
  value: number;
  currencyPrefix: string;
  status: EExpenseStatus;
  category: ICategory;
  violationNumber: number;
  commentsNumber: number;
  attachments?: IExpenseAttachment[];
  attachmentsNumber: number;
  establishment: IEstablishment;
  expenseDate: string;
  cardId?: number;
  card?: IExpenseCard;
  description?: string;
  comments?: IExpenseComment[];
  report?: IReportExpense;
  history?: IExpenseHistory[];
  violations?: IExpenseViolation[];
  client?: IExpenseClient;
  project?: IExpenseProject;
  costCenter?: IExpenseCostCenter;
  customFields?: IExpenseCustomFieldsValues[];
  route?: IRoute;
  odometer?: IOdometer;
  quantity?: number;
}

export interface IOdometerItem {
  value?: number;
  attachment?: IFileResponse;
}

export interface IOdometer {
  start: IOdometerItem;
  end: IOdometerItem;
  ratePerKm: number;
  distance: number;
}
export interface IExpenseCard {
  id: number;
  lastFourDigits: string;
}

export interface IExpenseCustomFieldsValues {
  id: string;
  value: string;
}

export interface IExpenseClient {
  name: string;
}

export interface IExpenseCostCenter {
  name: string;
}

export interface IExpenseProject {
  name: string;
}

export interface IExpenseViolation {
  id?: number;
  description?: string;
}

export interface IReportExpense {
  id?: number;
  name: string;
  status?: EReportStatus;
}

export interface IExpenseUser {
  id?: number;
  name: string;
  cpf?: string;
  email?: string;
}

export interface IExpenseComment {
  id: number;
  message: string;
  user: IExpenseUser;
  createdAt: string;
}

export interface IExpenseAttachment extends IFileResponse {}

export enum EExpenseType {
  GPS = 'GPS',
  ORIGIN_DESTINATION = 'ORIGIN_DESTINATION',
  DISTANCE = 'DISTANCE',
  ODOMETER = 'ODOMETER',
  MANUAL = 'MANUAL',
}

export enum EExpenseStatus {
  NONE = 'NONE',
  CREATED = 'CREATED',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  REPROVED = 'REPROVED',
  REIMBURSED = 'REIMBURSED',
}

export interface ICategory {
  id: number;
  description: string;
}

export enum EExpenseOrderBy {
  PARENT_ID = 'PARENT_ID',
  DESCRIPTION = 'DESCRIPTION',
  CREATED_AT = 'CREATED_AT',
  EXPENSE_DATE = 'EXPENSE_DATE',
  VIOLATION = 'VIOLATION',
  CONVERSION_VALUE = 'CONVERSION_VALUE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
}

export enum EOrderBy {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface IPaginationSettings {
  limit: number;
  page: number;
}
export interface IExpensesFilters {
  reportId?: IReport['id'];
  onlyWithViolations?: boolean;
  hasPending?: boolean;
  orderBy?: EExpenseOrderBy;
  order?: EOrderBy;
  onlyUnattached?: boolean;
}

export interface IExpensesResponse {
  expenses: IExpenses[];
  pages: number;
  total: number;
  currentPage: number;
}

export interface IGetExpensesResponse {
  getExpenses: IExpensesResponse;
}

export interface IExpenseHistory {
  createdAt: string;
  description: string;
  user: IUserExpenseHistory;
}

export interface IUserExpenseHistory {
  name: string;
}

export interface ICreateExpenseResponse {
  id: number;
  type: EExpenseType;
}

export interface IGetCreateExpenseResponse {
  createExpense: ICreateExpenseResponse;
}

export interface IGetUpdateExpenseResponse {
  updateExpenseById: ICreateExpenseResponse;
}
export interface IGetExpenseById {
  getExpenseById: IExpenses;
}

export interface IExpenseCustomFields {
  label: string;
  id: string;
}

export interface IGetExpenseCustomFields {
  getExpenseFormFields: {
    custom: IExpenseCustomFields[];
  };
}

export interface IDeleteExpense {
  id: number;
  message: string;
}

export interface IDeleteExpenseResponse {
  expenses: IDeleteExpense[];
}

export interface IDeleteExpenseRequest {
  expenses: number[];
}

export interface IGetDistanceRequest {
  waypoints: IEstablishment[];
}

export interface IGetDistanceResponse {
  getDistance: {
    distance: number;
    waypoints: IEstablishment[];
  };
}

export interface IGetRateKmRequest {
  date: string;
  projectId?: number;
}

export interface IGetRateKmResponse {
  getKmRate: number;
}
