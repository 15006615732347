import { ReactNode, useMemo, useState } from 'react';
import { FilterFactory, FilterType } from '@atoms/FilterFactory';
import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { FiltersDrawer, IDrawerFilter, IFilterValues } from '@molecules/FiltersDrawer';

import { GridFiltersSkeleton } from './GridFiltersSkeleton';
import { Search } from './Search/Search';
import * as SC from './styled';

export interface GridFiltersProps {
  /**
   * if "true" show GridFiltersSkeleton
   */
  isLoading?: boolean;

  /**
   * grid filter list
   */
  filters: IDrawerFilter[];

  /**
   * grid actions list
   */
  actions?: ReactNode[];

  /**
   * label displayed in the search field
   */
  labelSearchField?: string;

  /**
   * label displayed in filter list
   */
  filterListLabel?: string;

  /**
   * if "true" show the search field
   */
  showSearchField?: boolean;

  /**
   * if "true" show more filters button
   */

  showMoreFiltersButton?: boolean;

  /**
   * maximum number of filters shown outside filters drawer
   */

  maxFiltersShown?: number;

  /**
   * Selected filter values
   */
  selectedFilters?: object | IFilterValues[];

  /**
   * Show skeleton filter items
   */
  showSkeletonFilterItems?: number;

  /**
   * Search field event (Return string value)
   */
  isAllDisabled?: boolean;

  /**
   * Search field value
   */
  searchValue?: string;

  /**
   * Search field disable
   */
  searchDisabled?: boolean;

  /**
   * Tag filter custom
   */
  customTag?: ReactNode;

  /**
   * if "true" disables the tag filter
   */
  onSearchChange?(value: string): void;

  /**
   * Apply all filters on drawer event
   */
  onApplyAllFilters?(value): void;

  /**
   * Clears all selected filters
   */
  onClearAllFilters?(): void;
}

type IFilterChange = 'clear' | 'update';

export const GridFilters = ({
  isLoading,
  filters,
  actions,
  showSearchField,
  labelSearchField,
  filterListLabel,
  showMoreFiltersButton = true,
  onSearchChange,
  maxFiltersShown,
  onApplyAllFilters,
  onClearAllFilters,
  selectedFilters,
  showSkeletonFilterItems,
  isAllDisabled = false,
  searchDisabled = false,
  searchValue = '',
  customTag,
}: GridFiltersProps) => {
  const { t } = useTranslation();
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const tagFilters = useMemo(() => filters?.slice(0, maxFiltersShown), [filters, maxFiltersShown]);
  const numberFiltersAppliedInMoreFilters = useMemo(() => getNumberFiltersAppliedInMoreFilters(), [selectedFilters]);

  function onFilterChange(type: IFilterChange, data?: IFilterValues[]) {
    type === 'update' ? onApplyAllFilters(data) : onClearAllFilters();
    setOpenFiltersDrawer(false);
  }

  function getNumberFiltersAppliedInMoreFilters() {
    let selectedMoreFilters = [];

    if (selectedFilters) {
      const tagFiltersKeys = tagFilters.map(filter => filter.key);

      if (Array.isArray(selectedFilters)) {
        selectedMoreFilters = selectedFilters?.filter(filter => !tagFiltersKeys.includes(filter.key));
      } else {
        selectedMoreFilters = selectedFilters
          ? Object.keys(selectedFilters)?.filter(filter => !tagFiltersKeys.includes(filter) && filter !== 'search')
          : [];
      }
    }

    return selectedMoreFilters?.length;
  }

  if (isLoading) {
    return (
      <GridFiltersSkeleton
        filters={filters}
        actions={actions}
        filterListLabel={filterListLabel}
        showMoreFiltersButton={showMoreFiltersButton}
        showSearchField={showSearchField}
        showSkeletonFilterItems={showSkeletonFilterItems}
      />
    );
  }

  return (
    <SC.Container>
      {showSearchField && (
        <Search
          label={labelSearchField || t('molecules.gridFilters.labelSearchField')}
          onChange={onSearchChange}
          isLoading={isLoading}
          value={searchValue}
          disabled={searchDisabled || isAllDisabled}
        />
      )}
      {filters.length > 0 && (
        <SC.Content>
          <SC.FiltersContainer className="filters-container">
            <SC.FiltersLabel variant="body3">
              {filterListLabel || t('molecules.gridFilters.filterListLabel')}
            </SC.FiltersLabel>
            {tagFilters?.map((filter, key) => {
              if (filter.type !== FilterType.SEARCH)
                return (
                  <SC.FilterContainer className={'filter-container'} key={key}>
                    {
                      <FilterFactory
                        key={filter.key || String(key)}
                        type={filter.type}
                        multiple={filter.multiple}
                        label={t(`molecules.moreFilters.labels.${filter.label}`)}
                        onClick={filter.onClick}
                        options={filter?.options}
                        selectedOptions={filter.selectedOptions}
                        onChange={filter.onChange}
                        isDisabled={isAllDisabled || filter.disabled}
                        isTagFilter
                        {...filter}
                      />
                    }
                  </SC.FilterContainer>
                );
            })}
            {showMoreFiltersButton && (
              <>
                <SC.ContainerButtonTag>
                  <Button
                    disabled={isAllDisabled}
                    variant="secondary"
                    size="small"
                    onClick={() => setOpenFiltersDrawer(!openFiltersDrawer)}
                    className={numberFiltersAppliedInMoreFilters > 0 ? 'active' : ''}>
                    <Icons name="IconPlus" fill="transparent" />
                    <SC.FiltersLabel active={numberFiltersAppliedInMoreFilters > 0} variant="body3">
                      {t(`report.filters.label.moreFilters`)}
                    </SC.FiltersLabel>
                    {numberFiltersAppliedInMoreFilters > 0 && (
                      <SC.FilterBadge variant="caption">{numberFiltersAppliedInMoreFilters}</SC.FilterBadge>
                    )}
                  </Button>
                </SC.ContainerButtonTag>
                <SC.DrawerContainer>
                  <FiltersDrawer
                    isOpen={openFiltersDrawer}
                    filters={filters}
                    onClose={() => setOpenFiltersDrawer(false)}
                    onApplyAllFilters={data => onFilterChange('update', data)}
                    onClearAllFilters={() => onFilterChange('clear')}
                    selectedFilters={selectedFilters}
                  />
                </SC.DrawerContainer>
              </>
            )}
            {customTag && customTag}
          </SC.FiltersContainer>
          {actions && (
            <SC.ActionsContainer>
              {actions.map((action, key) => (
                <SC.ActionContainer key={key}>{action}</SC.ActionContainer>
              ))}
            </SC.ActionsContainer>
          )}
        </SC.Content>
      )}
    </SC.Container>
  );
};
