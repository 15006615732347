import { useTranslation } from '@locale/Translator';
import { IDrawerFilter } from '@molecules/FiltersDrawer';
import { Skeleton } from '@mui/material';

import * as SC from './styled';

export interface GridFiltersSkeletonProps {
  /**
   * grid filter list
   */
  filters: IDrawerFilter[];

  /**
   * grid actions list
   */
  actions?: React.ReactNode[];

  /**
   * label displayed in filter list
   */
  filterListLabel?: string;

  /**
   * if "true" show the search field
   */
  showSearchField?: boolean;

  /**
   * if "true" show more filters button
   */

  showMoreFiltersButton?: boolean;

  /**
   * Show skeleton filter items
   */

  showSkeletonFilterItems?: number;
}

export const GridFiltersSkeleton = ({
  filters,
  actions,
  showSearchField,
  filterListLabel,
  showMoreFiltersButton = true,
  showSkeletonFilterItems,
}: GridFiltersSkeletonProps) => {
  const { t } = useTranslation();
  return (
    <SC.Container>
      {showSearchField && <Skeleton width={'100%'} height={58} variant={'rounded'} />}
      <SC.Content>
        <SC.FiltersContainer className="filters-container">
          <SC.FiltersLabel variant="body3">
            {filterListLabel || t('molecules.gridFilters.filterListLabel')}
          </SC.FiltersLabel>
          {showSkeletonFilterItems
            ? new Array(showSkeletonFilterItems)
                .fill({})
                .map((_, key) => <Skeleton key={key} width={140} height={42} variant={'rounded'} />)
            : filters.map((_, key) => <Skeleton key={key} width={140} height={42} variant={'rounded'} />)}
          {}
          {showMoreFiltersButton && <Skeleton width={140} height={42} variant={'rounded'} />}
        </SC.FiltersContainer>
        {actions && (
          <SC.ActionsContainer>
            {actions.map((_, key) => (
              <Skeleton key={key} width={140} height={42} variant={'rounded'} />
            ))}
          </SC.ActionsContainer>
        )}
      </SC.Content>
    </SC.Container>
  );
};
