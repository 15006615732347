import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const IconDots = styled(IconButton)`
  transform: rotate(90deg);
`;

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[80]};
  width: 42px;
  height: 42px;
`;
