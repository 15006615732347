import { BorderedWrapping } from '@atoms/BorderedWrapping';
import { Skeleton } from '@mui/material';

import * as SC from './styled';

export const ReportCardSkeleton = () => {
  return (
    <BorderedWrapping>
      <SC.ReportCardContainer>
        <SC.ReportCardHeader>
          <Skeleton variant="circular" width={50} height={50} />
          <Skeleton variant="text" width={135} height={22} />
        </SC.ReportCardHeader>
        <SC.ReportCardBody>
          <SC.ReportCodeLabelContainer>
            <Skeleton variant="text" width={75} height={22} />
          </SC.ReportCodeLabelContainer>
          <Skeleton variant="text" width={180} height={26} />
        </SC.ReportCardBody>
        <SC.ReportCardFooter>
          <Skeleton variant="rounded" width={80} height={28} />
        </SC.ReportCardFooter>
      </SC.ReportCardContainer>
    </BorderedWrapping>
  );
};
