import { CustomFieldRegisterForm } from '@containers/Customizations/context/types/customFieldTypes';
import { EFieldType, FieldFactory } from '@organisms/FieldFactory';
import { Control, useWatch } from 'react-hook-form';

type Props = {
  control: Control<CustomFieldRegisterForm>;
};

const CustomFieldPreview = ({ control }: Props) => {
  const values = useWatch({ control });

  return (
    <FieldFactory
      fieldType={values.type?.value || EFieldType.TEXT}
      label={values.label}
      options={values.options.map(option => ({
        label: option.value,
        value: option.value,
      }))}
      allowsEditing
    />
  );
};

export default CustomFieldPreview;
