import React from 'react';
import { EOrderBy } from '@containers/Expenses/context/types/expenses';
import { useTranslation } from '@locale/Translator';
import { ColumnHeader } from '@organisms/Grid/ColumnHeader/ColumnHeader';

export enum EAccessor {
  SELECTION = 'selection',
  NAME = 'name',
  REQUESTER = 'requester',
  VALUE = 'value',
  STATUS = 'status',
  PERIOD = 'period',
  ACTIONS = 'actions',
}

export interface HeaderGridColumn {
  Header: React.ReactNode;
  accessor: string | number;
  disableSortBy?: boolean;
  sticky?: string;
}

interface UseHeaderGridColumnProps {
  onOrderByClick?(accessor: EAccessor, order: EOrderBy): void;
  onSelectAllClick(): void;
  isSelectedAll: boolean;
}

export function useHeaderGridColumns({
  onSelectAllClick,
  isSelectedAll,
}: UseHeaderGridColumnProps): HeaderGridColumn[] {
  const { t } = useTranslation();

  return Object.values(EAccessor).map(accessor => ({
    Header: (
      <ColumnHeader
        isSelectedAll={accessor == EAccessor.SELECTION}
        onSelectAllClick={onSelectAllClick}
        selectAllChecked={isSelectedAll}
        description={t(`organisms.financialReportGrid.${accessor}`)}
      />
    ),
    accessor,
    disableSortBy: true,
    sticky: accessor == EAccessor.ACTIONS && 'right',
  }));
}
