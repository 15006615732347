import { EExpenseType, IExpenses } from '@containers/Expenses/context/types';
import { IReport } from '@containers/Reports/context/types';
import { useExpenseDetailsDriver } from '@shared/hooks/navigation/useExpenseDetailsDriver';
import { useExpenseRegisterDriver } from '@shared/hooks/navigation/useExpenseRegisterDriver';

import { UseDeleteExpense, useDeleteExpense } from './useDeleteExpense';
import { useUnlinkExpense } from './useUnlinkExpense';

export interface UseExpensesGridActionsProps {
  isReportDetails?: boolean;
  reportId?: IReport['id'];
  unSelectAll?(): void;
}

export interface ExpensesGridActions {
  actionsIsLoading: boolean;
  selectedActionItems: IExpenses['id'][];
  unlinkConfirmationIsOpen: boolean;
  removeConfirmationIsOpen: boolean;
  deleteConfirmationIsOpen: boolean;
  onDeleteExpense?(id: IExpenses['id'][]): void;
  onUnlinkExpense?(id: IExpenses['id'][]): void;
  onViewExpense?(id: IExpenses['id'], expenseType: EExpenseType): void;
  onEditExpense?(id: IExpenses['id'], expenseType: EExpenseType): void;
  onConfirmUnlinkExpenseClick(description?: string): void;
  onCancelUnlinkExpenseClick(): void;
  onConfirmDeleteExpenseClick: UseDeleteExpense['onConfirmDeleteExpenseClick'];
  onCancelDeleteExpenseClick(): void;
}

export function useExpensesGridActions({
  isReportDetails,
  reportId,
  unSelectAll,
}: UseExpensesGridActionsProps): ExpensesGridActions {
  const {
    unlinkIsLoading,
    expensesToUnlink,
    unlinkConfirmationIsOpen,
    removeConfirmationIsOpen,
    onUnlinkExpense,
    onCancelUnlinkExpenseClick,
    onConfirmUnlinkExpenseClick,
  } = useUnlinkExpense({ reportId, isReportDetails, unSelectAll });

  const navigateExpenseEdit = useExpenseRegisterDriver();
  const navigateExpenseDetails = useExpenseDetailsDriver();

  const {
    deleteIsLoading,
    expensesToDelete,
    deleteConfirmationIsOpen,
    onDeleteExpense,
    onCancelDeleteExpenseClick,
    onConfirmDeleteExpenseClick,
  } = useDeleteExpense({ unSelectAll });

  const selectedActionItems: IExpenses['id'][] = expensesToUnlink?.length
    ? expensesToUnlink
    : expensesToDelete?.length
    ? expensesToDelete
    : [];

  return {
    actionsIsLoading: deleteIsLoading || unlinkIsLoading,
    selectedActionItems,
    unlinkConfirmationIsOpen,
    removeConfirmationIsOpen,
    deleteConfirmationIsOpen,
    onDeleteExpense,
    onUnlinkExpense,
    onViewExpense: (expenseId, expenseType) => navigateExpenseDetails({ expenseId, expenseType }),
    onEditExpense: (expenseId, expenseType) => navigateExpenseEdit({ expenseId, expenseType }),
    onConfirmUnlinkExpenseClick,
    onCancelUnlinkExpenseClick,
    onCancelDeleteExpenseClick,
    onConfirmDeleteExpenseClick,
  };
}
