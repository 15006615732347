import { Breadcrumbs, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { Link } from 'react-router-dom';

import * as SC from './styled';

export interface IBreadcrumbItem {
  to: string;
  label: string;
}

interface IProps {
  items: IBreadcrumbItem[];
}

export function BreadcrumbPage({ items }: IProps) {
  return (
    <SC.BreadcrumbContainer>
      <Breadcrumbs
        breadcrumbs={items.map((item, key) => (
          <Link key={key + item.to} to={item.to}>
            <SC.BreadcrumbTypography>{item.label}</SC.BreadcrumbTypography>
          </Link>
        ))}
        separator={<Icons name="IconChevronRight" fill="transparent" />}></Breadcrumbs>
    </SC.BreadcrumbContainer>
  );
}
