import { useCallback, useState } from 'react';
import { IAnalyticsLog } from '@containers/Analytics/context/types/analyticsLogs';
import { ActionsRow, IActionsRow } from '@organisms/Grid/ActionsRow/ActionsRow';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { AnalyticsSection, IProps } from '../AnalyticsSection';
import { AnalyticsLogDetailsModal } from './AnalyticsLogDetailsModal/AnalyticsLogDetailsModal';
import DiffColumn from './DiffColumn';

enum ELogActions {
  SEE_DETAILS = 'SEE_DETAILS',
}

type LogSectionProps = IProps & {
  showDiff?: boolean;
};

export function LogSection(props: LogSectionProps) {
  const t = useTranslate('analytics');

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState<IAnalyticsLog | null>();

  const getActions = useCallback((row: IAnalyticsLog): IActionsRow[] => {
    return [
      {
        id: ELogActions.SEE_DETAILS,
        key: ELogActions.SEE_DETAILS,
        allowed: true,
        name: t('actions.seeDetails'),
        iconName: 'IconListDetails',
        onClick: () => {
          setSelectedLog(row);
          setIsDetailsOpen(true);
        },
      },
    ];
  }, []);

  return (
    <>
      <AnalyticsLogDetailsModal
        log={selectedLog}
        open={isDetailsOpen}
        onCloseModal={() => {
          setIsDetailsOpen(false);
        }}
      />

      <AnalyticsSection
        {...props}
        renderActions={(row: IAnalyticsLog) => (
          <ActionsRow isLoading={false} actions={getActions(row)} referenceId={''} />
        )}
        customColumns={
          props.showDiff
            ? [
                {
                  accessor: 'old_value',
                  Header: t('logs.common.previousValue'),
                },
                {
                  accessor: 'new_value',
                  Header: t('logs.common.newValue'),
                },
              ]
            : undefined
        }
        renderRow={
          props.showDiff
            ? (row, data: IAnalyticsLog) => {
                row['old_value'] = <DiffColumn diff={data.extras.diff} type="previous" />;
                row['new_value'] = <DiffColumn diff={data.extras.diff} type="current" />;

                return row;
              }
            : undefined
        }
      />
    </>
  );
}
