import { EImportingActions, IImportingDispatchActions } from '../actions';
import { IImportingContextState } from '../types';

export const importingReducer = (
  state: IImportingContextState,
  action: IImportingDispatchActions,
): IImportingContextState => {
  switch (action.type) {
    case EImportingActions.GET_RESPONSE_INFO:
      return {
        ...state,
        importingResponse: action.payload ?? [],
      };

    case EImportingActions.GET_RESPONSE_INFO_IS_LOADING:
      return {
        ...state,
        importingResponseIsLoading: action.payload.importingResponseIsLoading ?? false,
      };

    case EImportingActions.HAS_TRIED_TO_IMPORT:
      return {
        ...state,
        hasTriedToImport: action.payload.hasTriedToImport ?? false,
      };

    default:
      throw new Error('Error in importing reducer');
  }
};
