import { useEffect, useState } from 'react';
import { IConfigurationCategory } from '@containers/Customizations/context/types/categoryTypes';
import { useCategoryMutate } from '@containers/Customizations/hooks/useCategories';

export interface UseConfigurationCategoriesActions {
  category: IConfigurationCategory;
  isDisableAll: boolean;
  isLoadingMutate: boolean;
  isLoadingAllCategories: boolean;
  isOpenMutateModal: boolean;
  handleEnableDisabledAll(): void;
  onMutateModalShow(): void;
  handleOpenCategory(_category: IConfigurationCategory): void;
  handleSubmitCategory(e: React.FormEvent<HTMLFormElement>): void;
  handleActiveCategory(_category: IConfigurationCategory): void;
  handleCategory(field: keyof Omit<IConfigurationCategory, 'id'>, value: string | boolean | number): void;
  onDeleteCategory(category: Pick<IConfigurationCategory, 'id'>): void;
  handleCancel(): void;
}

export function useCategoriesActions(categories: IConfigurationCategory[]): UseConfigurationCategoriesActions {
  const [isOpenMutateModal, setIsOpenMutateModal] = useState(false);
  const [isDisableAll, setIsDisableAll] = useState(false);

  const {
    isLoading: isLoadingMutate,
    category,
    loadCategory,
    cleanCategory,
    handleCategory,
    mutateCategory,
    disableCategory,
    activeCategory,
    deleteCategory,
    disableAllCategories,
    enableAllCategories,
    isLoadingAllCategories,
  } = useCategoryMutate();

  function onMutateModalShow() {
    setIsOpenMutateModal(isOpen => !isOpen);
  }
  const handleCancel = () => {
    setIsOpenMutateModal(false);
    cleanCategory();
  };

  const handleOpenCategory = (_category: IConfigurationCategory) => {
    loadCategory(_category);
    setIsOpenMutateModal(true);
  };

  const handleSubmitCategory = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await mutateCategory();
    handleCancel();
  };

  const handleActiveCategory = (_category: IConfigurationCategory) => {
    return _category.active ? disableCategory(_category) : activeCategory(_category);
  };

  const handleEnableDisabledAll = () => {
    isDisableAll ? disableAllCategories() : enableAllCategories();
  };

  useEffect(() => {
    if (categories?.length > 0) setIsDisableAll(categories.findIndex(_category => _category.active) !== -1);
  }, [categories]);

  return {
    category,
    isDisableAll,
    isLoadingMutate,
    isLoadingAllCategories,
    isOpenMutateModal,
    handleEnableDisabledAll,
    onMutateModalShow,
    handleOpenCategory,
    handleSubmitCategory,
    handleActiveCategory,
    onDeleteCategory: deleteCategory,
    handleCategory,
    handleCancel,
  };
}
