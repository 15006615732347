import { createContext, useState } from 'react';

import { IAccountingContext, IAccountingContextProviderProps } from './types';

export const AccountingContext = createContext<IAccountingContext>(null);

export const AccountingContextProvider = ({ children }: IAccountingContextProviderProps) => {
  const [reportsQuantity, setReportsQuantity] = useState(0);
  const [advancesQuantity, setAdvancesQuantity] = useState(0);

  return (
    <AccountingContext.Provider
      value={{
        reportsQuantity,
        advancesQuantity,
        setReportsQuantity,
        setAdvancesQuantity,
      }}>
      <>{children}</>
    </AccountingContext.Provider>
  );
};
