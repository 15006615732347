import styled from 'styled-components';

export const LoaderContainer = styled.div(({ theme }) => ({
  width: '42px',
  height: '42px',
  borderRadius: theme.borders.radius.circular,
  padding: theme.spacings.xs5,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
