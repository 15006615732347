import { useState } from 'react';
import { ReportHistoryGrid } from '@containers/Reports/components/molecules/ReportHistoryGrid';
import { IReport } from '@containers/Reports/context/types';
import { useReportHistoryById } from '@containers/Reports/hooks/useReportHistoryById';

interface IProps {
  reportId: IReport['id'];
}

export function HistoryReport({ reportId }: IProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const { reportHistory, reportHistoryIsLoading, reportHistoryTotal } = useReportHistoryById({
    reportId,
    page: currentPage,
  });

  return (
    <ReportHistoryGrid
      historic={reportHistory}
      isLoading={reportHistoryIsLoading}
      reportHistoryTotal={reportHistoryTotal}
      onHistoryPageClick={page => setCurrentPage(page)}
    />
  );
}
