import { IconButton, Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

import { ESizeVariant } from './variants';

interface DropzoneContainerProps extends SizeProps {
  hasReceipts: boolean;
}

interface MiniatureReceiptProps {
  image: string;
}
export interface SizeProps {
  size: ESizeVariant;
}
interface IContainerProps extends SizeProps {
  isDisabled?: boolean;
}

export const Container = styled('div')<IContainerProps>(({ size, isDisabled }) => ({
  position: 'relative',
  maxWidth: getVariantSize(size, 'width'),
  opacity: isDisabled ? 0.5 : 1,
  pointerEvents: isDisabled ? 'none' : 'initial',
}));

export const ReportCardContainer = styled.div`
  margin-right: ${props => props.theme.spacings.xs};
  :last-child {
    margin-right: 0;
  }
`;

export const DropzoneContainer = styled.div<DropzoneContainerProps>`
  .dropzone-main-container {
    position: ${props => (props.hasReceipts ? 'inherit' : 'absolute')};
    top: 0;
    left: 0;
    max-width: ${props => props.size};
    display: ${props => (props.hasReceipts ? 'inherit' : 'flex')};
    flex-flow: wrap-reverse;

    .dropzone {
      height: ${props => (props.hasReceipts ? '0' : getVariantSize(props.size, 'height'))};
      width: ${props => (props.hasReceipts ? '0' : getVariantSize(props.size, 'width'))};
      opacity: ${props => (props.hasReceipts ? 0 : 1)};
      z-index: 1;

      .dropzone-children-container {
        display: ${props => (props.hasReceipts ? 'none' : 'inherit')};
      }
    }

    .dropzone-padding {
      padding: ${props => (props.hasReceipts ? 0 : props.theme.spacings.m)};
    }
  }
`;

export const CurrentReceipt = styled.div<SizeProps>`
  height: ${props => getVariantSize(props.size, 'height')};
  width: 100%;
  margin-bottom: ${props => props.theme.spacings.s};
  background: ${props => props.theme.colors.neutral[90]};
  border-radius: ${props => props.theme.borders.radius.l};
  overflow: hidden;
`;

export const ReceiptsContainer = styled.div<SizeProps>`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacings.xs4};
  justify-content: flex-end;
  width: ${props => getVariantSize(props.size, 'width')};
`;

export const MiniatureReceipt = styled.div<MiniatureReceiptProps>`
  position: relative;
  width: 88px;
  height: 88px;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: transform 0.1s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

export const IconDots = styled.div`
  background-color: ${props => props.theme.colors.secondary.light.light2};
  border-radius: ${props => props.theme.borders.radius.circular};
  height: 8px;
  margin-right: ${props => props.theme.spacings.xs5};
  width: 8px;

  &.active {
    background-color: ${props => props.theme.colors.primary};
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacings.xs3};
`;

export const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${props => props.theme.spacings.s};
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AddIcon = styled(IconButton).attrs({
  variant: 'line',
  size: 'small',
  icon: 'IconPlus',
})`
  position: absolute;
`;

export const HiddenFileInput = styled.input.attrs({
  type: 'file',
})`
  visibility: hidden;
  width: 0;
`;

export const InputContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const getVariantSize = (variant: ESizeVariant, dimension: string) => {
  const size = {
    small: {
      width: '311px',
      height: '345px',
    },
    medium: {
      width: '480px',
      height: '710px',
    },
    large: {
      width: '500px',
      height: '480px',
    },
  };

  return size[variant]?.[dimension];
};

export const SkeletonReceipt = styled(Skeleton)<{ size: ESizeVariant }>`
  border-radius: ${({ theme }) => theme.borders.radius.m};
  height: ${({ size }) => getVariantSize(size, 'height')};
  width: ${({ size }) => getVariantSize(size, 'width')};
`;
