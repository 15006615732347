import { useEffect } from 'react';
import { IMileageRate } from '@containers/Customizations/context/types/policies';
import { useSaveMileageRate } from '@containers/Customizations/hooks/useSaveMileageRate';
import { DatePicker, TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { Grid } from '@mui/material';
import { showToast } from '@shared/helpers/toast';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

interface Props {
  open: boolean;
  onClose: () => void;
  policyId: number;
  mileageRate?: IMileageRate;
}

export const MileageRateRegisterModal = ({ open, onClose, policyId, mileageRate }: Props) => {
  const { t } = useTranslation();

  const { handleSubmit, control, reset } = useForm<IMileageRate>({
    mode: 'all',
  });

  const { saveMileageRate, isLoading } = useSaveMileageRate({ policyId });

  useEffect(() => {
    reset(mileageRate);
  }, [mileageRate]);

  async function onSubmit(data: IMileageRate) {
    try {
      await saveMileageRate(data, mileageRate?.id);
      onClose?.();

      showToast({
        message: t('organisms.mileageRatesPreview.feedbacks.mileageRateSavedSuccessfully'),
        type: 'success',
      });

      reset?.();
    } catch (err) {
      showToast({
        message: err.message || t('organisms.mileageRatesPreview.feedbacks.errorSavingMileageRate'),
        type: 'error',
      });
    }
  }

  return (
    <DefaultModal
      open={open}
      headerTitle={
        !mileageRate
          ? t('molecules.mileageRateRegisterModel.create.title')
          : t('molecules.mileageRateRegisterModel.update.title')
      }
      labelButtonAction={
        !mileageRate
          ? t('molecules.mileageRateRegisterModel.create.submit')
          : t('molecules.mileageRateRegisterModel.update.submit')
      }
      headerSubtitle={t('molecules.mileageRateRegisterModel.description')}
      onActionClick={handleSubmit(onSubmit)}
      onCloseClick={onClose}
      onCancelClick={onClose}
      isLoading={isLoading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="startDate"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <DatePicker
                  {...field}
                  onDateChange={v => onChange(v.toDate())}
                  label={t('molecules.mileageRateRegisterModel.fields.startDate')}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="rate"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <NumericFormat
                  onBlur={field.onBlur}
                  name={field.name}
                  value={field.value}
                  customInput={TextField}
                  label={t('molecules.mileageRateRegisterModel.fields.value')}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$"
                  decimalScale={2}
                  fixedDecimalScale
                  onFocus={event => event.target.select()}
                  onValueChange={({ floatValue }) => onChange(floatValue)}
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  InputProps={{
                    inputProps: { maxLength: 10 },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </DefaultModal>
  );
};
