import { showToast } from '@shared/helpers/toast';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export function useExpenseApprovalSettingsMutate() {
  const t = useTranslate('molecules.expenseSettings.feedbacks.updated');
  const onError = () =>
    showToast({
      message: t('error'),
      type: 'error',
    });
  const mutate = useMutate('v1/settings/expense/approval', onError);

  async function updateApprovalSetting(settingKey: string, value: number | boolean | string | object) {
    const body = { value };
    const response = await mutate.mutation.trigger(
      {
        path: settingKey,
        options: {
          method: 'PATCH',
          body: JSON.stringify(body),
        },
      },
      {
        revalidate: false,
      },
    );
    const success: boolean = response?.key?.length > 0;
    if (success)
      showToast({
        message: t('success'),
        type: 'success',
      });

    return success;
  }

  return {
    updateApprovalSetting,
    success: mutate.mutation.error == undefined,
    isLoading: mutate.mutation.isMutating,
    error: mutate.mutation.error,
  };
}
