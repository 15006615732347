import { useEffect, useMemo, useState } from 'react';
import { ReportDetailsPreview } from '@containers/Reports/components/organisms/ReportDetailsPreview';
import { IReport } from '@containers/Reports/context/types';
import { useReportById } from '@containers/Reports/hooks/useReportById';
import { useTranslation } from '@locale/Translator';
import { BasePage } from '@organisms/BasePage/BasePage';
import { expensesAccountingBaseURL } from '@shared/constant';
import { showToast } from '@shared/helpers/toast';
import { useTrackingScreen } from '@shared/hooks/useTrackingScreen';
import { useParams } from 'react-router-dom';

import { ActionsFooterPage } from './ActionsFooterPage';

export const FinancialReportDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { reportById, report, reportIsLoading } = useReportById();
  const [currentReport, setCurrentReport] = useState<IReport>(null);

  useTrackingScreen('financial_report_detail_screen', { reportId: id });

  async function getReportById() {
    try {
      const response = await reportById({
        variables: {
          id: Number(id),
        },
      });
      setCurrentReport(response.data?.getReportById);
    } catch (error) {
      showToast({ message: error?.message, type: 'error' });
    }
  }

  useEffect(() => {
    getReportById();
  }, [id]);

  useMemo(() => {
    if (report) setCurrentReport(report);
  }, [report]);

  return (
    <BasePage
      headerPage={{
        breadcrumbItems: [
          { to: '', label: t('pages.financialReports.title') },
          { to: `${expensesAccountingBaseURL}/pending`, label: t('pages.financialReports.Accountability.title') },
          { to: '', label: t('report.reportDetails') },
        ],
      }}
      footerPage={<ActionsFooterPage reportStatus={currentReport?.status} report={currentReport} />}>
      <ReportDetailsPreview report={currentReport} isLoading={reportIsLoading} />
    </BasePage>
  );
};
