import { ShapeIcon } from '@atoms/ShapeIcon';
import { getReportStatusColorVariant } from '@containers/Reports/common';
import { EReportStatus, IReport } from '@containers/Reports/context/types';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

interface IProps {
  name: IReport['name'];
  status: IReport['status'];
}

export function ReportNameGrid({ status, name }: IProps) {
  const reportStatusColorVariant = getReportStatusColorVariant({ status });

  const setIconName = (): IconTypes => {
    if (status === EReportStatus.PROCESSING_PAYMENT_ERROR) return 'IconAlertCircle';
    return 'IconFolder';
  };
  return (
    <SC.Container>
      <ShapeIcon icon={setIconName()} size="small" variant={reportStatusColorVariant} />
      <SC.Description>{name}</SC.Description>
    </SC.Container>
  );
}
