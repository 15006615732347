import { RouterErrorBoundary } from '@atoms/RouterErrorBoundary';
import { CustomApprovalFlowRegister } from '@pages/CustomApprovalFlows/CustomApprovalFlowRegister/CustomApprovalFlowRegister';
import { CustomApprovalFlows } from '@pages/CustomApprovalFlows/CustomApprovalFlows';
import { EAuthZPermission } from '@shared/types/authZPermission';
import { RouteObject } from 'react-router-dom';

import { PermissionsGuard } from '../PermissionsGuard';
import { Router } from '../Router';

export function CustomApprovalRoutes(): RouteObject[] {
  return [
    {
      element: (
        <PermissionsGuard
          routeFeatures={['INTERMEDIATE_APPROVALS']}
          routePermissions={[EAuthZPermission.CONFIGURATION_APPROVAL_FLOW]}
        />
      ),
      errorElement: (
        <RouterErrorBoundary
          title="Erro ao carregar o módulo de Fluxo de Aprovações"
          moduleName="Fluxo de Aprovações"
        />
      ),
      children: [
        {
          path: Router.getApprovalFlowsReports(),
          element: <CustomApprovalFlows />,
        },
        {
          path: Router.getApprovalFlowsAdvances(),
          element: <CustomApprovalFlows />,
        },
        {
          path: Router.getApprovalFlowsReportsRegister(),
          element: <CustomApprovalFlowRegister />,
        },
        {
          path: Router.getApprovalFlowsReportsRegister(':id'),
          element: <CustomApprovalFlowRegister />,
        },
        {
          path: Router.getApprovalFlowsAdvancesRegister(),
          element: <CustomApprovalFlowRegister />,
        },
        {
          path: Router.getApprovalFlowsAdvancesRegister(':id'),
          element: <CustomApprovalFlowRegister />,
        },
      ],
    },
  ];
}
