import { useContext, useMemo } from 'react';
import { EAnalyticsType } from '@containers/Analytics/context/types/filters';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { useLicenseFeatures } from '../licenseFeatures/useLicenseFeatures';
import { usePlatformPermissions } from './usePlatformPermissions';

export enum EAnalyticsSection {
  EXPENSE = 'expense',
  REPORTS = 'reports',
  CORPORATE_CARD = 'corporateCard',
}

export interface IAnalyticsPermissionsShowSection {
  expenses: boolean;
  reports: boolean;
  corporateCard: boolean;
  advances: boolean;
  logs: boolean;
}
export interface IAnalyticsAllowedActions {
  filters: boolean;
  export: boolean;
}

export interface IUseAnalyticsPermissions {
  sections: IAnalyticsPermissionsShowSection;
  getAllowedActionsAnalytics(type: EAnalyticsType): IAnalyticsAllowedActions;
}

export function useAnalyticsPermissions(): IUseAnalyticsPermissions {
  const { isAdmin } = useContext(SessionContext);
  const { checkPlatformPermission, permissions } = usePlatformPermissions();
  const { checkLicenseFeature, features } = useLicenseFeatures();

  const sections = useMemo(
    () => ({
      expenses: checkPlatformPermission(EAuthZPermission.ANALYTICS_EXPENSES_VIEW),
      reports: checkPlatformPermission(EAuthZPermission.ANALYTICS_REPORTS_VIEW),
      corporateCard: checkPlatformPermission(EAuthZPermission.ANALYTICS_CARD_ADMINISTRATOR),
      advances:
        checkPlatformPermission(EAuthZPermission.ANALYTICS_ADVANCE_VIEW) && checkLicenseFeature('ADVANCED_ADVANCE'),
      logs: checkLicenseFeature('ADVANCED_LOGGING') && isAdmin,
    }),
    [features?.toString?.(), permissions],
  );

  function getAllowedActionsAnalytics(type: EAnalyticsType): IAnalyticsAllowedActions {
    switch (type) {
      case EAnalyticsType.EXPENSES_OVERVIEW:
      case EAnalyticsType.EXPENSES_BY_USER:
      case EAnalyticsType.EXPENSES_BY_CATEGORY:
      case EAnalyticsType.EXPENSES_PENDING_OVERVIEW:
        return {
          filters: checkPlatformPermission(EAuthZPermission.ANALYTICS_EXPENSES_ACTION) && sections.expenses,
          export: checkPlatformPermission(EAuthZPermission.ANALYTICS_EXPENSES_ACTION) && sections.expenses,
        };

      case EAnalyticsType.CORPORATE_CARD_BY_USER:
      case EAnalyticsType.CORPORATE_CARD_OVERVIEW:
      case EAnalyticsType.CORPORATE_CARD_PENDING_OVERVIEW:
        return {
          filters: checkPlatformPermission(EAuthZPermission.ANALYTICS_CARD_ACTION) && sections.corporateCard,
          export: checkPlatformPermission(EAuthZPermission.ANALYTICS_CARD_ACTION) && sections.corporateCard,
        };

      case EAnalyticsType.REPORT_OVERVIEW:
      case EAnalyticsType.REPORT_PENDING_APPROVALS:
      case EAnalyticsType.REPORT_STATUS_BY_USER:
        return {
          filters: checkPlatformPermission(EAuthZPermission.ANALYTICS_REPORTS_ACTION) && sections.reports,
          export: checkPlatformPermission(EAuthZPermission.ANALYTICS_REPORTS_ACTION) && sections.reports,
        };

      case EAnalyticsType.ADVANCES_OVERVIEW:
      case EAnalyticsType.ADVANCES_STATUS_BY_USER:
        return {
          filters: checkPlatformPermission(EAuthZPermission.ANALYTICS_ADVANCE_ACTION) && sections.reports,
          export: checkPlatformPermission(EAuthZPermission.ANALYTICS_ADVANCE_ACTION) && sections.reports,
        };

      default:
        return {
          export: false,
          filters: true,
        };
    }
  }

  return {
    sections,
    getAllowedActionsAnalytics,
  };
}
