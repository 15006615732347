import { Tab } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const TabContainer = styled.div`
  width: calc(100% - 200px);
  border-radius: ${props => props.theme.borders.radius.m};
  display: grid;
  grid-column: ${props => props.theme.spacings.s};
  grid-row-gap: ${props => props.theme.spacings.s};
  padding-left: ${props => props.theme.spacings.xs3};
`;

export const Tabs = styled(Tab)`
  display: grid;
  grid-template-columns: 200px 100%;

  .MuiAccordion-root {
    display: grid;
    overflow: auto;
  }

  .MuiAccordion-root.Mui-expanded {
    display: block;
  }

  .MuiAccordionSummary-content {
    text-align: left;
  }
`;
