import { IImportingContextState } from './types';

export const initialSettingsState: IImportingContextState = {
  importingResponse: {
    result: {
      total: 0,
      ignored: 0,
      created: 0,
      deleted: 0,
      errors: 0,
    },
  },
  importingResponseIsLoading: false,
  hasTriedToImport: false,
};
