import { EAnalyticsType } from '@containers/Analytics/context/types/filters';
import { useTranslation } from '@locale/Translator';

import { AnalyticsGroup } from '../../AnalyticsGroup/AnalyticsGroup';
import { LogSection } from './LogSection';

export function AnalyticsLogsSection() {
  const { t } = useTranslation();
  const translationBase: string = 'analytics.sections.logs';
  return (
    <AnalyticsGroup
      header={{
        title: t(`${translationBase}.title`),
        caption: t(`${translationBase}.caption`),
        icon: 'IconHistory',
      }}>
      <LogSection
        key={`approval-logs`}
        analyticsType={EAnalyticsType.LOGS_BY_APPROVAL}
        translatePathBase={'analytics.logs.approval'}
      />

      <LogSection
        key={`area-logs`}
        analyticsType={EAnalyticsType.LOGS_BY_AREA}
        translatePathBase={'analytics.logs.area'}
        showDiff
      />

      <LogSection
        key={`cost-center-logs`}
        analyticsType={EAnalyticsType.LOGS_BY_COSTCENTER}
        translatePathBase={'analytics.logs.costCenter'}
        showDiff
      />

      <LogSection
        key={`project-logs`}
        analyticsType={EAnalyticsType.LOGS_BY_PROJECT}
        translatePathBase={'analytics.logs.project'}
        showDiff
      />

      <LogSection
        key={`policy-logs`}
        analyticsType={EAnalyticsType.LOGS_BY_POLICY}
        translatePathBase={'analytics.logs.policy'}
      />

      <LogSection
        key={`user-logs`}
        analyticsType={EAnalyticsType.LOGS_BY_USER}
        translatePathBase={'analytics.logs.user'}
        showDiff
      />
    </AnalyticsGroup>
  );
}
