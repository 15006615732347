import { IWithdraw } from '@containers/Withdraw/context/types/types';
import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

interface IResponse {
  pagination: number;
  pages: number;
  total: number;
  withdrawals: IWithdraw[];
}

export const useWithdrawalsToAttach = () => {
  const onError = (error, key) => showToast({ message: `${key}:${error.message}`, type: 'error' });

  const { data, error, isLoading, mutate } = useFetch<IResponse>(
    `v1/withdrawals/withdraw-for-attach?page=0&user_id=0`,
    '',
    undefined,
    undefined,
    onError,
  );

  return {
    error,
    isLoading: isLoading,
    withdrawals: data?.withdrawals ?? [],
    refetch: mutate,
  };
};
