import { useApprovers } from '@containers/ApprovalFlows/CustomApprovalFlow/hooks/dataSources/useApprovers';
import { useCurrentPath } from '@shared/hooks/navigation/useCurrentPath';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

export interface IOptionsSource {
  value: string;
  caption: string;
  label: string;
  icon?: string;
  disabled?: boolean;
}

enum ECriteriaOptions {
  MANUAL = 'manual',
  COST_CENTER_OWNER = 'costCenterOwner',
  PROJECT_OWNER = 'projectOwner',
  APPROVAL_BY_HIERARCHY = 'approvalByHierarchy',
}

enum EHierarchyOptions {
  IMMEDIATE_MANAGER = 'immediateManager',
  SECOND_LEVEL_MANAGER = 'secondLevelManager',
  THIRD_LEVEL_MANAGER = 'thirdLevelManager',
  FOURTH_LEVEL_MANAGER = 'fourthLevelManager',
  FIFTH_LEVEL_MANAGER = 'fifthLevelManager',
}

export function useOptionsListSources() {
  const t = useTranslate('approvalFlows.approvalSources.optionsCriteriaSource');
  const currentPath = useCurrentPath();
  const hasAdvance = currentPath.includes('advances');
  const { approvers, isLoading } = useApprovers();
  const criteriaOptions: IOptionsSource[] = Object.keys(ECriteriaOptions).map(key => {
    return {
      value: key,
      label: t(`${ECriteriaOptions[key]}.label`),
      caption:
        ECriteriaOptions[key] === ECriteriaOptions.MANUAL
          ? t(`${ECriteriaOptions[key]}.caption`)
          : t(`${ECriteriaOptions[key]}.caption.${hasAdvance ? 'advance' : 'report'}`),
    };
  });
  const hierarchyOptions: IOptionsSource[] = Object.keys(EHierarchyOptions).map(key => ({
    value: key,
    label: t(`${EHierarchyOptions[key]}.label`),
    caption: t(`${EHierarchyOptions[key]}.caption`),
  }));
  const approversOptions: IOptionsSource[] = approvers?.map(approver => ({
    value: approver.id,
    label: approver.name,
    caption: approver.email,
  }));

  return {
    criteriaOptions,
    hierarchyOptions,
    approversOptions,
    isLoadingSourceOptions: isLoading,
  };
}
