import { showToast } from '@shared/helpers/toast';
import { useFetch } from '@shared/hooks/service/useFetch';

import { IReport } from '../context/types';
import { IApproversTarget } from '../context/types/approvers';

export const useApproversReportById = (reportId: IReport['id']) => {
  const path = reportId ? `v1/reports/${reportId}/approvals` : null;

  const { data, error, isLoading } = useFetch<IApproversTarget[]>(path, undefined, undefined, undefined, () =>
    showToast({ message: `${error.message}`, type: 'error' }),
  );

  return {
    error,
    isLoading,
    approvers: data || [],
  };
};
