import { useQuery } from '@shared/hooks/service/apolloClient/useQuery';

import { getUsersForSelectionQuery } from '../context/queries';
import { IGetUsers } from '../context/types/projects';

export const useUsersList = (skip: boolean = false) => {
  const {
    loading: isLoading,
    data: usersListData,
    error,
  } = useQuery<IGetUsers>(getUsersForSelectionQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip,
  });

  return {
    isLoading,
    error,
    users: usersListData?.getUsersForSelection?.userSelections,
    total: usersListData?.getUsersForSelection?.userSelections.length ?? 0,
  };
};
