import { useContext, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { IReport } from '@containers/Reports/context/types';
import { useTranslation } from '@locale/Translator';
import { ExpenseRegisterContext } from '@shared/contexts/ExpenseRegisterContext/ExpenseRegisterContext';
import { showToast } from '@shared/helpers/toast';
import { useLicenseFeatures } from '@shared/hooks/permissions/licenseFeatures/useLicenseFeatures';
import { useCheckUnleashFlag } from '@shared/hooks/permissions/unleash/useCheckUnleashFlag';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useFormContext } from 'react-hook-form';

import { IExpenseInput, ReceiptFile } from '../context/types';
import { EOcrStatus, IExpense } from '../context/types/expenseById';
import { IOcrResponse } from '../context/types/ocr';
import { useExpenseByIdForm } from './useExpenseByIdForm';
import { useExpenseFileUpload } from './useExpenseFileUpload';

export const useOCR = () => {
  const { t } = useTranslation();
  const { setOcrIsLoading } = useContext(ExpenseRegisterContext);
  const fileUpload = useExpenseFileUpload();
  const { checkLicenseFeature } = useLicenseFeatures();
  const { mutation } = useMutate(
    'legacy-expense/create-ocr-expense-gateway',
    (error: Error) => {
      showToast({
        title: t('organisms.expenseRegisterForm.ocr.error.title'),
        message: error.message,
        type: 'error',
      });
    },
    true,
  );
  const [delayLoading, setDelayLoading] = useState(false);
  const methods = useFormContext<IExpenseInput>();
  const { getExpenseById, isLoading: expenseIsLoading } = useExpenseByIdForm(methods);
  const [countExpenseCall, setCountExpenseCall] = useState(0);
  const checkUnleashFlag = useCheckUnleashFlag();

  function setDataFinish() {
    setDelayLoading(false);
    setOcrIsLoading(false);
    setCountExpenseCall(0);
  }

  function showErrorMsg(error?: ApolloError) {
    showToast({ type: 'error', message: error?.message || t('organisms.expenseRegisterForm.ocr.error.title') });
  }

  async function getExpense(id: IExpense['id'], expenseDelayCall = 2000) {
    setCountExpenseCall(data => data + 1);

    //For the OCR creation synchronization process, have time to fill in the newly created expense data.
    const delay = ms => new Promise(res => setTimeout(res, ms));
    setDelayLoading(true);
    await delay(expenseDelayCall);

    const { expense, error } = await getExpenseById(id);

    if (
      expense?.ocrExpenseStatus === EOcrStatus.COMPLETE ||
      (expense?.ocrExpenseStatus === EOcrStatus.PARTIAL && !error)
    ) {
      setDataFinish();
      return expense;
    } else if (expense?.ocrExpenseStatus === EOcrStatus.FAIL || error) {
      setDataFinish();
      showErrorMsg(error);
      return;
    }

    if (countExpenseCall <= 2) return getExpense(id, 1500);
    else showErrorMsg(error);
  }

  async function createOCRExpense(receipts: ReceiptFile[], reportId?: IReport['id']) {
    if (checkLicenseFeature('INTERMEDIATE_EXPENSE') && checkUnleashFlag('FLASHOS_EXPENSE_OCR_FEATURE')) {
      setOcrIsLoading(true);
      const { imageId, imageUrl } = await updateReceipt([receipts[0]]);
      const expenseCreatedOCR: IOcrResponse = imageId && imageUrl && (await mutateOCR(reportId, imageUrl, imageId));
      await getExpense(expenseCreatedOCR?.id_despesa);
    }
  }

  async function updateReceipt(receipts: ReceiptFile[]) {
    const filesToUpload = receipts?.filter(({ file }) => !!file);
    const uploadedFiles =
      filesToUpload && (await Promise.all(filesToUpload?.map(({ file }) => fileUpload.upload(file))));

    return {
      imageUrl: uploadedFiles?.[0]?.url || null,
      imageId: uploadedFiles?.[0]?.externId || null,
    };
  }

  async function mutateOCR(reportId: IReport['id'], imageUrl: string, imageId: string) {
    try {
      await mutation.trigger<IOcrResponse>(
        {
          options: {
            method: 'POST',
            body: JSON.stringify({
              reportId,
              imageUrl,
              imageId,
            }),
          },
        },
        {
          revalidate: false,
        },
      );

      return mutation.data;
    } catch (e) {
      throw e;
    }
  }

  return {
    createOCRExpense,
    data: mutation.data,
    error: mutation.error,
    loading: mutation.isMutating || fileUpload.isLoading || delayLoading || expenseIsLoading,
  };
};
