import { EExpenseType, IExpenses } from '@containers/Expenses/context/types';
import { IReport } from '@containers/Reports/context/types';
import { expensesBaseUrl } from '@shared/constant';
import { NavigateOptions, useNavigate } from 'react-router-dom';

export interface IReportNavigate {
  id: IReport['id'];
  name: string;
  description?: string;
  status?: IReport['status'];
}

interface INavigateExpenseEdit {
  expenseId?: IExpenses['id'];
  expenseType: EExpenseType;
  linkedReport?: IReportNavigate;
  navigateState?: NavigateOptions;
}

export function useExpenseRegisterDriver(isReportDetails: boolean = false) {
  const navigate = useNavigate();

  function navigateExpenseEdit({ expenseId, expenseType, linkedReport, navigateState }: INavigateExpenseEdit) {
    const urlBaseRegister: string = `${expensesBaseUrl}/register`;
    const expenseEditing: string = Boolean(expenseId) ? '/' + expenseId : '';
    const _navigateState: NavigateOptions = {
      state: {
        isReportDetails: navigateState?.state?.isReportDetails || isReportDetails,
        report: navigateState?.state?.report || linkedReport,
      },
    };

    switch (expenseType) {
      case EExpenseType.ODOMETER:
        navigate(`${urlBaseRegister}/odometer${expenseEditing}`, _navigateState);
        break;
      case EExpenseType.ORIGIN_DESTINATION:
        navigate(`${urlBaseRegister}/originDestination${expenseEditing}`, _navigateState);
        break;
      case EExpenseType.GPS:
        navigate(`${urlBaseRegister}/gps${expenseEditing}`, _navigateState);
        break;
      default:
        navigate(`${urlBaseRegister}${expenseEditing}`, _navigateState);
        break;
    }
  }

  return navigateExpenseEdit;
}
