import {
  EApprovalSourceType,
  IApprovalFlowSource,
} from '@containers/ApprovalFlows/CustomApprovalFlow/types/approvalFlow';
import { ApprovalFlowForm } from '@containers/ApprovalFlows/CustomApprovalFlow/validators/ApprovalFlowFormSchema';
import { ToggleSettings } from '@containers/Customizations/components/molecules/ToggleSettings/ToggleSettings';
import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldRegister } from '../../../molecules/FieldRegister/FieldRegister';
import { IOptionsSource } from './hooks/useOptionsListSources';
import * as SC from './styled';

interface IProps {
  isLoading?: boolean;
  index: number;
  criteriaOptions: IOptionsSource[];
  hierarchyOptions: IOptionsSource[];
  approversOptions: IOptionsSource[];
  onChange: (index: number, value: IApprovalFlowSource) => void;
}

export function ApprovalByLevelRow({
  isLoading,
  index,
  approversOptions,
  criteriaOptions,
  hierarchyOptions,
  onChange,
}: IProps) {
  const t = useTranslate('approvalFlows.approvalSources');
  const { control, getValues } = useFormContext<ApprovalFlowForm>();
  const source = getValues(`sources.${index}`) as IApprovalFlowSource;

  function getValueType(type: EApprovalSourceType) {
    switch (type) {
      case EApprovalSourceType.COST_CENTER_OWNER:
        return EApprovalSourceType.COST_CENTER_OWNER;
      case EApprovalSourceType.PROJECT_OWNER:
        return EApprovalSourceType.PROJECT_OWNER;
      default:
        return null;
    }
  }

  function getAvailableSourceOptions() {
    return criteriaOptions?.map(criterion => {
      criterion.disabled = control?._formValues?.sources?.some(
        source => source.configType === criterion.value && source.configType !== EApprovalSourceType.MANUAL,
      );
      return criterion;
    });
  }

  function getAvailableApproversOptions() {
    return approversOptions?.map(approver => {
      approver.disabled = control?._formValues?.sources?.some(source => String(source.approverId) === approver.value);
      return approver;
    });
  }

  return (
    <SC.ConditionalRow>
      <FieldRegister isLoading={isLoading}>
        <Controller
          control={control}
          name={`sources.${index}.configType`}
          rules={{
            required: {
              value: !getValues(`sources.${index}.configType`),
              message: t('approvalByLevels.fields.criterion.mandatoryInfo'),
            },
          }}
          render={({ field, fieldState }) => (
            <SC.ContainerCriterionFiled>
              <SelectField
                {...field}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                label={t('approvalByLevels.fields.criterion.label')}
                onSelectChange={(_, data) =>
                  onChange(index, {
                    ...source,
                    configType: data.value,
                    type: getValueType(data.value),
                  })
                }
                options={getAvailableSourceOptions()}
              />
            </SC.ContainerCriterionFiled>
          )}
        />
      </FieldRegister>
      {getValues(`sources.${index}.configType`) === EApprovalSourceType.COST_CENTER_OWNER && (
        <ToggleSettings
          disabled={false}
          isLoading={isLoading}
          checked={getValues(`sources.${index}.type`) === EApprovalSourceType.COST_CENTER_OWNER_WITHOUT_DEFAULT}
          toggleKey={'mileage'}
          label={t('optionsCriteriaSource.costCenterOwnerWithoutDefault.label')}
          onChange={(_, value) =>
            onChange(index, {
              ...source,
              type: value
                ? EApprovalSourceType.COST_CENTER_OWNER_WITHOUT_DEFAULT
                : EApprovalSourceType.COST_CENTER_OWNER,
            })
          }
        />
      )}
      {getValues(`sources.${index}.configType`) === EApprovalSourceType.APPROVAL_BY_HIERARCHY && (
        <FieldRegister isLoading={isLoading}>
          <Controller
            control={control}
            name={`sources.${index}.type`}
            rules={{
              required: {
                value: !getValues(`sources.${index}.type`),
                message: t('approvalByLevels.fields.criterion.mandatoryInfo'),
              },
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <SelectField
                {...field}
                inputRef={ref}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                label={t('approvalByLevels.fields.hierarchyLevels.label')}
                onSelectChange={(_, data) => onChange(index, { ...source, type: data.value })}
                options={hierarchyOptions}
              />
            )}
          />
        </FieldRegister>
      )}
      {getValues(`sources.${index}.configType`) === EApprovalSourceType.MANUAL && (
        <FieldRegister isLoading={isLoading}>
          <Controller
            control={control}
            name={`sources.${index}.type`}
            rules={{
              required: {
                value: !getValues(`sources.${index}.type`),
                message: t('approvalByLevels.fields.criterion.mandatoryInfo'),
              },
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <SelectField
                {...field}
                inputRef={ref}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                label={t('approvalByLevels.fields.people.label')}
                onSelectChange={(_, data) => {
                  const _data = { ...source };

                  if (getValues(`sources.${index}.configType`) === EApprovalSourceType.MANUAL) {
                    _data.type = EApprovalSourceType.MANUAL;
                    _data.approverId = Number(data.value);
                  } else {
                    _data.type = data.value;
                  }

                  onChange(index, _data);
                }}
                value={
                  getValues(`sources.${index}.configType`) === EApprovalSourceType.MANUAL
                    ? String(source.approverId)
                    : field.value
                }
                options={getAvailableApproversOptions()}
              />
            )}
          />
        </FieldRegister>
      )}
    </SC.ConditionalRow>
  );
}
