import { ReactElement } from 'react';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';

interface IProps {
  isLoading?: boolean;
  children: ReactElement;
  height?: number | string;
}

export function FieldRegister({ isLoading = false, children, height }: IProps) {
  if (isLoading) {
    return <Skeleton width={'100%'} height={height || 56} variant="rounded" />;
  }

  return children;
}
