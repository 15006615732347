import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';

import ProjectRegisterBreadcrumb from '../ProjectRegisterBreadcrumb/ProjectRegisterBreadcrumb';
import * as SC from './styled';

interface ProjectRegisterProgressProps {
  step: number;
  isEditing: boolean;
}

export const ProjectRegisterProgressHeader = ({ step = 0, isEditing }: ProjectRegisterProgressProps) => {
  const { t } = useTranslation();

  return (
    <SC.Header>
      <ProjectRegisterBreadcrumb isEditing={isEditing} />
      <Stepper
        activeStep={step}
        steps={[
          t('projects.registerProject.steps.header.generalData'),
          t('projects.registerProject.steps.header.users'),
        ]}
      />
    </SC.Header>
  );
};
